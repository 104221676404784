import React from 'react';
import { withGoogleMap, GoogleMap } from 'react-google-maps';
import PropTypes from 'prop-types';

const Map = ({ markers, center, onMapMounted }) => {
    const defaultOptions = {
        streetViewControl: false,
        scaleControl: false,
        mapTypeControl: false,
        panControl: false,
        zoomControl: false,
        rotateControl: false,
        fullscreenControl: false
    };
    return (
        <GoogleMap
            defaultZoom={2}
            defaultCenter={center}
            ref={onMapMounted}
            defaultOptions={defaultOptions}>
            {markers.map((marker) => marker)}
        </GoogleMap>
    );
};

Map.propTypes = {
    markers: PropTypes.node,
    center: PropTypes.shape({}),
    onMapMounted: PropTypes.func,
};

export default withGoogleMap(Map);
