import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setChangePaymentCardModalClosed } from '../../../../actions/miscActions';
import { changeCustomerPaymentCard } from '../../../../services/paymentCards';
import ModalDialog from '../../../common/ModalDialog';
import ChangePaymentCardModalDialogForm from './ChangePaymentCardModalDialogForm';

const ChangePaymentCardModalDialog = (props) => {
    const { isChangePaymentCardModalDialog, setChangePaymentCardModalClosed, handleSubmit, cardId } = props;
    return (
        <ModalDialog
            form={
                <ChangePaymentCardModalDialogForm
                    onSubmit={data => {
                        const submitData = data.toJS();
                        if (cardId) {
                            submitData.cardId = cardId;
                        }
                        return handleSubmit(submitData);
                    }}/>
            }
            headerClassName='modal-dialog__header_transparent'
            title='Enter New Payment Option'
            isModalOpened={isChangePaymentCardModalDialog}
            handleModalClosed={setChangePaymentCardModalClosed}
            overflowShown/>
    );
};

ChangePaymentCardModalDialog.propTypes = {
    isChangePaymentCardModalDialog: PropTypes.bool,
    setChangePaymentCardModalClosed: PropTypes.func,
    handleSubmit: PropTypes.func,
    cardId: PropTypes.number
};

const mapStateToProps = state => ({
    cardId: state.getIn(['misc', 'modals', 'changePaymentCard', 'cardId']),
    isChangePaymentCardModalDialog: state.getIn(['misc', 'modals', 'changePaymentCard', 'isOpened'])
});

const mapDispatchToProps = {
    setChangePaymentCardModalClosed,
    handleSubmit: changeCustomerPaymentCard
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePaymentCardModalDialog);
