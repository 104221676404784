import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { ReactComponent as IconChevronDown } from '../../../../../../assets/icons/icon-chevron-right.svg';
import { prettifyPhone } from '../../../../../../helpers/normalize';
import { getLocationString } from '../../../../../../helpers/normalizeScheduleEvent';
import { dataStates } from '../../../../../../helpers/state';

const columns = [
    {
        id: 'className',
        Header: 'Class Name',
        headerClassName: 'custom-reports__th',
        className: 'custom-reports__td',
        accessor: d => d.group.class_name,
    },
    {
        expander: true,
        width: 65,
        Expander: ({ isExpanded, ...rest }) => // eslint-disable-line
            (<span className={`custom-reports__expand-btn ${isExpanded ? 'custom-reports__expand-btn_expanded' : ''}`}>
                <IconChevronDown className='custom-reports__expand-btn__icon'/>
            </span>),
        style: {
            cursor: 'pointer',
            fontSize: 25,
            padding: 0,
            textAlign: 'center',
            userSelect: 'none',
        },
    },
];

const subComponentColumns = [
    {
        id: 'owner',
        Header: 'Owner',
        headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
        className: 'custom-reports__inner-table__td',
        accessor: d => d.dog.owner.full_name,
    },
    {
        id: 'ownerPhone',
        Header: 'Phone number',
        headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
        className: 'custom-reports__inner-table__td',
        accessor: d => d.dog.owner.primary_phone !== null
            ? prettifyPhone(d.dog.owner.primary_phone.phone_number)
            : '-',
    },
    {
        id: 'dog',
        Header: 'Dog (Breed)',
        headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
        className: 'custom-reports__inner-table__td',
        accessor: d => `${d.dog.name} (${d.dog.breed.name})`,
    },
    {
        id: 'pickupLocation',
        Header: 'Pick-up Location',
        headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
        className: 'custom-reports__inner-table__td',
        accessor: d => getLocationString(d.pickup_location),
    },
    {
        id: 'pickupLocationNotes',
        Header: 'Pick-up Notes',
        headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
        className: 'custom-reports__inner-table__td',
        accessor: d => d.pickup_location.location_notes? d.pickup_location.location_notes : <div className='no-info'>—</div>,
    },
    {
        id: 'dropoffLocation',
        Header: 'Drop-off Location',
        headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
        className: 'custom-reports__inner-table__td',
        accessor: d => getLocationString(d.drop_off_location),
    },
    {
        id: 'dropoffLocationNotes',
        Header: 'Drop-off Notes',
        headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
        className: 'custom-reports__inner-table__td',
        accessor: d => d.drop_off_location.location_notes? d.drop_off_location.location_notes : <div className='no-info'>—</div>,
    },
    {
        id: 'dropoffTrainer',
        Header: 'Trainer',
        headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
        className: 'custom-reports__inner-table__td',
        accessor: d => d.class_occurrence.trainer.full_name,
    },
];

const PickupDropoffs = ({ items, dataState }) => {
    const renderTable = () => {
        if (dataState === dataStates.loaded && items && items.length) {
            return (
                <ReactTable
                    showPagination={false}
                    defaultPageSize={items.length}
                    data={items}
                    columns={columns}
                    SubComponent={row => {
                        return (
                            <div className='custom-reports__inner-table'>
                                <ReactTable
                                    showPagination={false}
                                    defaultPageSize={row.original.data.length}
                                    data={row.original.data}
                                    columns={subComponentColumns}/>
                            </div>
                        );
                    }}/>);
        }
        return null;
    };

    return (
        <div className='custom-reports__report'>
            {items !== undefined && renderTable()}
        </div>
    );
};

PickupDropoffs.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    dataState: PropTypes.string.isRequired,
};

export default PickupDropoffs;
