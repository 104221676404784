import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { processInstructorEmployeeInfo } from '../../../../actions/dashboard/instructorEmployeeActions';
import { setInstructorEmployeeModalClosed } from '../../../../actions/miscActions';
import ModalDialog from '../../../common/ModalDialog';
import InstructorEmployeeModalDialogForm from './InstructorEmployeeModalDialogForm';


// eslint-disable-next-line max-len
class InstructorEmployeeModalDialog extends React.PureComponent {
    static propTypes = {
        isAddInstructorEmployeeModalOpened: PropTypes.bool,
        isEditInstructorEmployeeModalOpened: PropTypes.bool,
        handleInstructorEmployeeModalClosed: PropTypes.func,
        handleSubmit: PropTypes.func
    }

    getModalTitle = () => {
        const { isEditInstructorEmployeeModalOpened } = this.props;
        if (isEditInstructorEmployeeModalOpened) {
            return 'Edit instructor or employee';
        }
        return 'Create new instructor or employee';
    }

    render() {
        const { isAddInstructorEmployeeModalOpened, isEditInstructorEmployeeModalOpened, 
            handleInstructorEmployeeModalClosed, handleSubmit } = this.props;
        return (
            <ModalDialog
                form={
                    <InstructorEmployeeModalDialogForm
                        onSubmit={data =>
                            handleSubmit(
                                // employeeId
                                data.get('id'),
                                // new data
                                data,
                                // server data
                                data.get('serverData'),
                                // permissions list
                                data.get('permissionsList')
                            )
                        }
                        edit={isEditInstructorEmployeeModalOpened}
                        isFormOpened={(isAddInstructorEmployeeModalOpened || isEditInstructorEmployeeModalOpened)}/>
                }
                title={this.getModalTitle()}
                isModalOpened={(isAddInstructorEmployeeModalOpened || isEditInstructorEmployeeModalOpened)}
                handleModalClosed={handleInstructorEmployeeModalClosed}/>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAddInstructorEmployeeModalOpened: state.getIn(['misc', 'modals', 'addInstructorEmployee']),
        isEditInstructorEmployeeModalOpened: state.getIn(['misc', 'modals', 'editInstructorEmployee']),
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleInstructorEmployeeModalClosed: bindActionCreators(setInstructorEmployeeModalClosed, dispatch),
    handleSubmit: bindActionCreators(processInstructorEmployeeInfo, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(InstructorEmployeeModalDialog);
