import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import { getWeekIntervalFromDate } from '../helpers/date';
import * as CalendarTypes from '../types/dashboard/calendarTypes';

const INITIAL_STATE = fromJS({
    type: 'Classes',
    view: 'day',
    calendarEventsColors: [
        '#8de2f4', '#a89ac7', '#79d89b', '#ff79af', '#ffba76', '#c8d583', '#2695ad', '#a081e3', '#3aa05f', '#d34482',
        '#f08f30', '#a6b943',
    ],
    isDayPickerShown: false,
    calendarSelectedDays: [
        {
            after: null,
            before: null,
        }
    ],
    rangeStart: null,
    rangeEnd: null,
    trainersColorsList: {},
    trainingInstructorQuery: '',
    trainingProgramQuery: '',
    classOccurrencesLocationQuery: '',
    classOccurrencesTrainingClassQuery: '',
});

const calendarReducer = handleActions({
    [CalendarTypes.CHANGE_CALENDAR_TYPE]: (state, action) => (
        state.set('type', action.payload.value)
    ),

    [CalendarTypes.SET_CALENDAR_VIEW]: (state, action) => (
        state.set('view', action.payload)
    ),

    [CalendarTypes.SET_DAYPICKER_SHOWN]: (state, action) => (
        state.set('isDayPickerShown', true)
    ),
    [CalendarTypes.SET_DAYPICKER_HIDDEN]: (state, action) => (
        state.set('isDayPickerShown', false)
    ),

    [CalendarTypes.SET_TRAINING_INSTRUCTOR_QUERY]: (state, action) => (
        state.set('trainingInstructorQuery', action.payload)
    ),
    [CalendarTypes.CLEAR_TRAINING_INSTRUCTOR_QUERY]: (state, action) => (
        state.set('trainingInstructorQuery', '')
    ),

    [CalendarTypes.SET_TRAINING_PROGRAM_QUERY]: (state, action) => (
        state.set('trainingProgramQuery', action.payload)
    ),
    [CalendarTypes.CLEAR_TRAINING_PROGRAM_QUERY]: (state, action) => (
        state.set('trainingProgramQuery', '')
    ),

    [CalendarTypes.SET_CLASS_OCCURRENCES_LOCATION_QUERY]: (state, action) => (
        state.set('classOccurrencesLocationQuery', action.payload)
    ),
    [CalendarTypes.CLEAR_CLASS_OCCURRENCES_LOCATION_QUERY]: (state, action) => (
        state.set('classOccurrencesLocationQuery', '')
    ),

    [CalendarTypes.SET_CLASS_OCCURRENCES_TRAINING_QUERY]: (state, action) => (
        state.set('classOccurrencesTrainingClassQuery', action.payload)
    ),
    [CalendarTypes.CLEAR_CLASS_OCCURRENCES_TRAINING_QUERY]: (state, action) => (
        state.set('classOccurrencesTrainingClassQuery', '')
    ),

    [CalendarTypes.SET_TRAINERS_COLORS_LIST]: (state, action) => (
        state.set('trainersColorsList', action.payload)
    ),

    [CalendarTypes.GET_CALENDAR_SELECTED_RANGE]: (state, action) => {
        const dateInterval = getWeekIntervalFromDate({ date: action.payload, isAccurate: false });
        const { startDate, endDate } = dateInterval;
        return state.set('calendarSelectedDays', fromJS({ after: startDate, before: endDate }));
    },
    [CalendarTypes.SET_RANGE_START]: (state, action) => (
        state.set('rangeStart', action.payload)
    ),
    [CalendarTypes.SET_RANGE_END]: (state, action) => (
        state.set('rangeEnd', action.payload)
    ),
    [CalendarTypes.SET_RANGE_START_END]: (state, action) => {
        const { start, end } = action.payload;
        return state.set('rangeStart', start).set('rangeEnd', end);
    },
}, INITIAL_STATE);

export default calendarReducer;
