/**
 * store - is full fledged redux store, feel free to dispatch loader animations an so on
 * @param store
 */
import axios from 'axios';
import { API_URL } from '../config';
import { setRequestURLSearchParams, getAuthToken } from './oauth';

const INVALID_TOKEN = 'Invalid token header. No credentials provided.';
const needRefreshToken = (error) => {
    const { status, data } = error.response;
    const detail = data && data.detail;
    if (status === 401 && detail && detail === INVALID_TOKEN) {
        return true;
    }
    return false;
};

export default () => {
    axios.defaults.baseURL = API_URL;

    axios.interceptors.request.use((config) => {
        if (!config.requestAttempts) {
          config.requestAttempts = 1;
        }

        const { data } = config;
        if (data && data instanceof FormData) {
            const email = data.get('email');
            const username = data.get('username');
    
            if (email) {
                data.set('email', email.toLowerCase());
            }
        
            if (username) {
                data.set('username', username.toLowerCase());
            }
        }

        return config;
      });

    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        const statusIn500s = error.response && error.response.status && error.response.status >= 500;
        const gotResponse = !!error.response;
        if (gotResponse && needRefreshToken(error)) {
            getAuthToken();
        } else if ((!gotResponse || statusIn500s) && error.config.requestAttempts < 3) {
            error.config.requestAttempts += 1;
            return axios.request(error.config);
        }
        return Promise.reject(error);
    });
};

export const setRequestType = (grantType) => {
    let requestType = null;

    if (grantType === 'convert_token') {
        requestType = setRequestURLSearchParams();
    } else {
        requestType = new FormData();
    }

    return requestType;
};
