import * as axios from 'axios';
import { setGlobalError } from '../actions/errorHandlerActions';
import * as MessagesActions from '../actions/messagesActions';
import { getUserNameSpace } from '../helpers/userRoles';

export const getMessagesData = ({ id, queryString, isPolling = false }) => {
    return dispatch => {
        if (isPolling) {
            dispatch(MessagesActions.setMessagesPolling());
        } else {
            dispatch(MessagesActions.setMessagesLoading());
        }
        let url = `/v1/${getUserNameSpace('customers.messages')}/customers/${id}/messages/?page_size=5`;

        if (queryString) {
            url = `${url}?${queryString}`;
        }

        return axios.get(url)
                    .then((response) => {
                        if (isPolling) {
                            response.data.results.reverse().forEach((item) => {
                                dispatch(MessagesActions.updateMessagesData(item));
                            });
                        } else {
                            dispatch(MessagesActions.setMessagesData(response.data));
                        }
                        dispatch(MessagesActions.setMessagesLoaded());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(setGlobalError(error));
                        dispatch(MessagesActions.setMessagesFailed());
                    });
    };
};

export const getNextMessagesData = () => {
    return (dispatch, getState) => {
        dispatch(MessagesActions.setMessagesLoadingMore());
        const nextUrl = getState().getIn(['messages', 'nextUrl']);
        return axios.get(nextUrl)
                    .then((response) => {
                        dispatch(MessagesActions.setNextMessagesData(response.data));
                        dispatch(MessagesActions.setMessagesLoaded());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(setGlobalError(error));
                        dispatch(MessagesActions.setMessagesFailed());
                    });
    };
};

export const getMessageDataById = ({ customerId, messageId }) => {
    return dispatch => {
        return axios.get(`/v1/${getUserNameSpace('customers.message')}/customers/${customerId}/messages/${messageId}/`)
                    .then((response) => {
                        dispatch(MessagesActions.updateMessageDataById(response.data));
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(setGlobalError(error));
                    });
    };
};
