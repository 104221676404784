import { baseResetPassword } from '../../services/resetPassword';
import { setResetPasswordModalClosed } from '../miscActions';

export const resetCustomerPassword = () => {
    return (dispatch, getState) => {
        const request = new FormData();
        request.append('email', getState().getIn(['misc', 'modals', 'resetPasswordData', 'email']));
        dispatch(baseResetPassword(request));
        dispatch(setResetPasswordModalClosed());
    };
};
