import { fromJS } from 'immutable';
import { capitalizeEachWord } from './text';

const applyFilter = (classItem, filterKey, filterData) => {
    if (filterKey === 'weekdays') {
        if (classItem.getIn(['days', capitalizeEachWord(filterData)])) {
            return true;
        }
    } else if (filterKey === 'location') {
        return classItem.get('location_array').includes(filterData);
    } else if (classItem.get(filterKey) === filterData) {
        return true;
    }

    return false;
};

export const classScheduleFilter = (classes, filters) => {
    if (Object.keys(filters).length === 0) {
        return {
            classes,
            count: classes.size,
        };
    }

    const newClassesList = classes.reduce((resultArray, classItem) => {
        const allPassed = Object.keys(filters).every((filterKey, filterIndex) => {
            if (Array.isArray(filters[filterKey])) { 
                // Multibox filter.  Include classItem if at least one selected filter in the multibox matches
                return !filters[filterKey].every((filterData, filterDataIndex) => {
                    return !applyFilter(classItem, filterKey, filterData);
                });
            } // else, select filter
            return applyFilter(classItem, filterKey, filters[filterKey]);
        });

        if (allPassed) {
            resultArray.push(classItem);
        }
        return resultArray;
    }, []);

    return {
        classes: fromJS(newClassesList),
        count: newClassesList.length,
    };
};
