import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as IconDots } from '../../../../assets/icons/icon-dots.svg';
import { ReactComponent as IconEdit } from '../../../../assets/icons/icon-edit.svg';
import { ReactComponent as IconTrash } from '../../../../assets/icons/icon-trash.svg';
import { formatDateToSlashes } from '../../../../helpers/date';
import { isMobileDevice } from '../../../../helpers/device';
import Dropdown from '../../../common/Dropdown';
import Table from '../../../common/Table';

const USER_COL_MAX_LINES = 3;

class FormsTable extends Table {
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.shape({})),
        loading: PropTypes.bool.isRequired,
        count: PropTypes.number,
        pages: PropTypes.number,
        getNext: PropTypes.func,
        gotoFormEdit: PropTypes.func,
        openFormDeleteModalDialog: PropTypes.func,
        nextUrl: PropTypes.string,
        readOnly: PropTypes.bool,
    };

    dropdownItemsDesktop = [
        {
            iconClass: 'icon_edit',
            icon: IconEdit,
            text: 'Edit',
            onClickCB: rowData => {
                this.props.gotoFormEdit(rowData.id);
            },
        },
        {
            iconClass: 'icon_trash',
            icon: IconTrash,
            text: 'Delete',
            onClickCB: rowData => {
                this.props.openFormDeleteModalDialog(rowData);
            },
        },
    ];

    dropdownItemsMobile = [

        {
            iconClass: 'icon_trash',
            icon: IconTrash,
            text: 'Delete',
            onClickCB: rowData => {
                this.props.openFormDeleteModalDialog(rowData);
            },
        },
    ];

    getColumns = () => {
        const columns = [
            {
                headerClassName: 'forms-table__td_mobile',
                className: 'forms-table__td_mobile',
                id: 'row',
                accessor: d => d,
                Cell: row => {
                    return (
                        <div className='forms-table__mobile-cell'>
                            <div className='forms-table__mobile-primary'>{row.value.name}</div>
                            <div className='forms-table__mobile-secondary'>
                                Last Modified: {formatDateToSlashes(row.value.modified_at, true)}
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: 'Form',
                headerClassName: 'forms-table__th forms-table_no-mobile forms-table__form-name',
                className: 'forms-table__td forms-table_no-mobile forms-table__form-name',
                minWidth: 300,
                id: 'name',
                accessor: 'name',
                Cell: row => row.value,
            },
            {
                Header: 'User',
                headerClassName: 'forms-table__th forms-table_no-mobile',
                className: 'forms-table__td forms-table_no-mobile',
                minWidth: 165,
                id: 'user',
                sortable: false,
                accessor: 'notify_users_detail',
                Cell: row => {
                    const len = row.value && row.value.length;
                    let lastLine = null;

                    if (!len) {
                        return <span>&mdash;</span>;
                    }

                    if (len > USER_COL_MAX_LINES) {
                        row.value.splice(USER_COL_MAX_LINES);
                        lastLine = (
                            <span className='forms-table__recipient-placeholder' key='last-line'>
                                {`${len - USER_COL_MAX_LINES} more...`}
                            </span>
                        );
                    }

                    const nodes = row.value.map((group, i) => {
                        return <div key={'group-' + i}>{group.name}</div>;
                    });

                    if (lastLine) {
                        nodes.push(lastLine);
                    }

                    return <div>{nodes}</div>;
                },
            },
            {
                Header: 'Status',
                headerClassName: 'forms-table__th forms-table_no-mobile',
                className: 'forms-table__td forms-table_no-mobile',
                minWidth: 150,
                id: 'is_published',
                accessor: 'is_published',
                Cell: row => row.value ? 'Sent' : 'Not Sent',
            },
            {
                Header: 'Published',
                headerClassName: 'forms-table__th forms-table_no-mobile',
                className: 'forms-table__td forms-table_no-mobile',
                minWidth: 150,
                id: 'published_date',
                accessor: 'published_date',
                Cell: row => row.value ? formatDateToSlashes(row.value, true) : 'N/A',
            },
            {
                Header: 'Last Modified',
                headerClassName: 'forms-table__th forms-table_no-mobile',
                className: 'forms-table__td forms-table_no-mobile',
                minWidth: 150,
                id: 'modified_at',
                accessor: 'last_modified',
                Cell: row => formatDateToSlashes(row.value, true),
            },
        ];
        if (!this.props.readOnly) {
            columns.push({
                id: 'formEdit',
                Header: '',
                headerClassName: 'forms-table__th forms-table_no-mobile',
                className: 'forms-table__td table__td_last-of-type',
                width: 50,
                accessor: d => d,
                Cell: row => (
                    <Dropdown
                        classNames='dropdown dropdown_forms-table-employee'
                        icon={IconDots}
                        iconClass='icon_chevron_down'
                        data={row.value}
                        items={(window.innerWidth >= 1024 && !isMobileDevice())
                            ? this.dropdownItemsDesktop
                            : this.dropdownItemsMobile}/>
                ),
            });
        }
        return columns;
    };

    render() {
        const { isScrollable } = this.state;
        return (
            <div className='forms-table-employee'>
                <div className='forms-table__header'>FORMS</div>
                {this.renderTable({ isScrollable, columns: this.getColumns() })}
            </div>
        );
    }
}

export default FormsTable;
