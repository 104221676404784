import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resendForgottenPassword } from '../../../../services/resetPassword';
import SkipStep from '../../../common/SkipStep/SkipStep';
import Step from '../../../common/Step';
import StepDescription from '../../../common/StepDescription';

const EmployeePasswordSentPage = ({ stepName = 'forgot-password', handleResendingForgottenPassword }) => {
    const Disclaimer = 'Please check your e-mail and click on the link to reset your password.';
    return (
        <Step
            logoClassName='forgot-password__logo'
            contentClassName='forgot-password__content'
            contentsContainerClassName='forgot-password__container forgot-password__container_forgotten'
            stepName={stepName}
            componentsImage={<div className='forgot-password__img forgot-password__img_employee'/>}
            descriptionComponent={
                <StepDescription
                    className='forgot-password__step-description forgot-password__step-description_forgotten'
                    text='Email sent!'
                    disclaimer={Disclaimer}/>
            }
            formComponent={
                <div className='forgot-password__retry'>
                    <div className='forgot-password__retry-text'>Don’t see an e-mail from us?</div>
                    <div
                        className='forgot-password__retry-action'
                        onClick={handleResendingForgottenPassword}>
                        Click here to have another one sent.
                    </div>
                </div>
            }
            skipComponent={
                <SkipStep
                    className='forgot-password__skip-step forgot-password__skip-step_forgotten'
                    skipLinkText='Return to Login'
                    skipLinkTo='/employee'/>
            }/>
    );
};

EmployeePasswordSentPage.propTypes = {
    stepName: PropTypes.string,
    handleResendingForgottenPassword: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
    handleResendingForgottenPassword: bindActionCreators(resendForgottenPassword, dispatch)
});

export default connect(null, mapDispatchToProps)(EmployeePasswordSentPage);
