import * as axios from 'axios';
import { push } from 'react-router-redux';
import { SubmissionError } from 'redux-form/immutable';
import {
    finishPackageListLoading,
    initPackagesFilterOptions,
    setNextPackageList,
    setPackageList,
    startPackageListLoading,
    togglePackageStatus,
} from '../actions/dashboard/packagesActions';
import { openSuccessPurchasePackagesModal } from '../actions/modal/openActions';
import { getUserNameSpace, isAdminOrSuperadmin } from '../helpers/userRoles';
import { ADMIN_ONLY_PACKAGE_ID, ADMIN_ONLY_PACKAGE_NAME } from '../config';

const canViewAdminOnlyPackage = isAdminOrSuperadmin();

const convertSelectOptions = (serverResponse) => {
    const { name_choices } = serverResponse;
    const options = [
        {
            name: 'Class',
            options: name_choices.reduce((results, item) => {
                if (!canViewAdminOnlyPackage && item === ADMIN_ONLY_PACKAGE_NAME) return results;

                results.push({
                    value: item,
                    label: item,
                });

                return results;
            }, []),
            type: 'multibox',
            query: 'name',
            array: true,
        },
    ];

    return options;
};

export const getPackageList = (queryString) => {
    return (dispatch) => {
        dispatch(startPackageListLoading());
        let url = `/v1/${getUserNameSpace('packages-trainings')}/packages-trainings/?is_archived=false`;

        if (queryString) {
            url = `${url}&${queryString}`;
        }

        return axios.get(url)
                    .then((response) => {
                        if (!canViewAdminOnlyPackage) {
                            response.data.results = response.data.results.filter((item) => {
                                return item.id !== ADMIN_ONLY_PACKAGE_ID;
                            });
                        }
                        dispatch(setPackageList(response.data));
                        dispatch(initPackagesFilterOptions(convertSelectOptions(response.data)));
                        dispatch(finishPackageListLoading());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(finishPackageListLoading());
                    });
    };
};

export const getNextPackageList = () => {
    return (dispatch, getState) => {
        dispatch(startPackageListLoading());
        const nextUrl = getState().getIn(['packageList', 'nextUrl']);
        return axios.get(nextUrl)
                    .then((response) => {
                        console.log(response.data);
                        dispatch(setNextPackageList(response.data));
                        dispatch(finishPackageListLoading());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(finishPackageListLoading());
                    });
    };
};

export const toggleStatus = (item) => {
    return (dispatch) => {
        const request = new FormData();
        const isPaused = item.get('is_paused');
        const id = item.get('id');

        let context;
        if (item.get('model_type') === 'package') {
            context = 'packages';
        }
        if (item.get('model_type') === 'trainingprogram') {
            context = 'training-programs';
        }

        request.append('is_paused', !isPaused);
        return axios.patch(`/v1/${getUserNameSpace(context)}/${context}/${id}/`, request)
                    .then((response) => {
                        dispatch(togglePackageStatus(response));
                    });
    };
};

export const getPackageData = packageId => {
    return axios.get(`/v1/${getUserNameSpace('packages')}/packages/${packageId}/`);
};

export const buyPackage = (packageId, cardId, packageName) => {
    return dispatch => {
        const request = new FormData();
        request.append('card', cardId);
        return new Promise((resolve, reject) => {
            axios.post(`/v1/customers/packages/${packageId}/buy/`, request)
                 .then(response => {
                    dispatch(push('/packages'));
                    dispatch(openSuccessPurchasePackagesModal(packageName));
                    window._dcq.push(['identify', {
                        current_package: packageName,
                    }]);
                    resolve(response);
                 })
                 .catch(error => {
                     console.log(error);
                     console.log(error.response);
                     reject(new SubmissionError({
                         _error: error.response.data.error_message,
                     }));
                 });
        });
    };
};

// need another returned function if needed in EditUpcomingClassModalDialogForm
export const checkIfPackageWithCurrentClassIsPurchased = async ({ customerId, classId }) => {
    const userNameSpace = getUserNameSpace('customers.purchased-packages');

    try {
        const [
            { data: generalPackagesAvailable },
            { data: freePackageCredit },
        ] = await Promise.all([
            axios.get(`/v1/${userNameSpace}/customers/${customerId}/purchased-packages/?training_classes=${classId}`),
            axios.get(`/v1/${userNameSpace}/customers/${customerId}/purchased-packages/?package_id=${ADMIN_ONLY_PACKAGE_ID}&is_available=true`),
        ]);

        return Promise.resolve({
            results: [
                ...freePackageCredit.results,
                ...generalPackagesAvailable.results,
            ],
        });
    } catch (error) {
        return Promise.reject(error);
    }
};
export const checkIfPackageWithCurrentClassIsPurchasedWithDispatch = ({ customerId, classId }) => {
    return dispatch => {
        return axios
            .get(`/v1/${getUserNameSpace(
                'customers.purchased-packages')}/customers/${customerId}/purchased-packages/?training_classes=${classId}`);
    };
};
