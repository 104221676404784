import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { getCustomersPurchaseHistory, getCustomersNextPurchaseHistory } from '../../../../../actions/dashboard/purchaseHistoryActions';
import PaymentHistoryTable from './PaymentHistoryTable/';
import NoData from '../../../../common/NoData/';
import Button from '../../../../common/Button/';
import AjaxLoader from '../../../../common/AjaxLoader';

class PaymentHistory extends PureComponent {
    componentDidMount() {
        const { getCustomersPurchaseHistory } = this.props;
        getCustomersPurchaseHistory({
            queryString: 'page_size=20'
        });
    }

    renderContent() {
        const {
            purchaseHistory,
            purchaseHistoryLoading,
            purchaseHistoryCount,
            purchaseHistoryNextUrl,
            getCustomersNextPurchaseHistory
        } = this.props;

        return (
            purchaseHistoryCount > 0
                ? <PaymentHistoryTable
                    data={purchaseHistory}
                    loading={purchaseHistoryLoading}
                    nextUrl={purchaseHistoryNextUrl}
                    getNext={getCustomersNextPurchaseHistory}
                    defaultPageSize={1000000}
                    autoHeight />
                : <NoData
                    title='Looks like you haven’t signed up for any classes yet!'
                    subtitle={
                        <div>Sign up for your first class <Link to='/classes'>HERE</Link></div>
                    }
                    iconType='happy'
                    borders={false}
                    action={
                        <Button text='View classes' link='/classes' modifierClassName='no-data__button' />
                    } />
        );
    }

    render() {
        const { purchaseHistoryLoaded } = this.props;
        return (
            <div className='profile__block payment-history'>
                <div className='profile__headline'>
                    <div className='profile__title'>My payment history</div>
                </div>
                <div className='payment-history__content'>
                    {purchaseHistoryLoaded ? this.renderContent() : <AjaxLoader />}
                </div>
            </div>
        );
    }
}

PaymentHistory.propTypes = {
    getCustomersPurchaseHistory: PropTypes.func,
    getCustomersNextPurchaseHistory: PropTypes.func,
    purchaseHistory: PropTypes.shape({}),
    purchaseHistoryCount: PropTypes.number,
    purchaseHistoryLoaded: PropTypes.bool,
    purchaseHistoryLoading: PropTypes.bool,
    purchaseHistoryNextUrl: PropTypes.string,
};

const mapStateToProps = state => ({
    purchaseHistory: state.getIn(['currentCustomer', 'purchaseHistory', 'items']),
    purchaseHistoryCount: state.getIn(['currentCustomer', 'purchaseHistory', 'count']),
    purchaseHistoryLoaded: state.getIn(['currentCustomer', 'purchaseHistory', 'areLoaded']),
    purchaseHistoryLoading: state.getIn(['currentCustomer', 'isLoading']),
    purchaseHistoryNextUrl: state.getIn(['currentCustomer', 'purchaseHistory', 'nextUrl']),
});

const mapDispatchToProps = {
    getCustomersPurchaseHistory,
    getCustomersNextPurchaseHistory
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);
