import PropTypes from 'prop-types';
import React from 'react';

const Loader = (props) => {
    const { isVisible, colorClassName = 'icon_loader-white', modifierClassName = '', loadingText } = props;

    return (
        <div className={'icon ' + modifierClassName}>
            <svg
                viewBox='0 0 38 38'
                xmlns='http://www.w3.org/2000/svg'
                stroke='#fff'
                className={'icon_loader ' + colorClassName + (isVisible ? ' icon_loader-visible' : '')}>
                <g fill='none' fillRule='evenodd'>
                    <g transform='translate(1 1)' strokeWidth='2'>
                        <circle strokeOpacity='.5' cx='18' cy='18' r='18'/>
                        <path d='M36 18c0-9.94-8.06-18-18-18'>
                            {isVisible
                                ? (<animateTransform
                                    attributeName='transform'
                                    type='rotate'
                                    from='0 18 18'
                                    to='360 18 18'
                                    dur='1s'
                                    repeatCount='indefinite'/>)
                                : null
                            }
                        </path>
                    </g>
                </g>
            </svg>
            {loadingText && <div className='loader__text'>{loadingText}</div>}
        </div>
    );
};

Loader.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    colorClassName: PropTypes.string,
    modifierClassName: PropTypes.string,
    loadingText: PropTypes.string,
};

export default Loader;
