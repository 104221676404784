import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import {
    setCurrentCustomerFromDogsList,
    setCurrentDogFromDogsList
} from '../../../../../actions/dashboard/employeeActions';
import { getAccessLevelOptions, setDogCreationModalOpened } from '../../../../../actions/miscActions';
import AddNewItem from '../../../../../components/common/AddNewItem';
import { getDogs, getNextDogs } from '../../../../../services/dogs';
import renderSelectControl from '../../../../common/Form/SelectControl';
import DogsTable from './DogsTable/DogsTable';

class DogsPage extends React.PureComponent {
    static propTypes = {
        handleGettingDogs: PropTypes.func,
        dogsList: PropTypes.shape({}),
        handleSettingCurrentDog: PropTypes.func,
        handleSettingCurrentCustomer: PropTypes.func,
        handleGettingAccessLevelOptions: PropTypes.func,
        openDogCreationModal: PropTypes.func,
        accessLevelOptions: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.shape({})),
            PropTypes.shape({})
        ]),
        handleGettingNextDogs: PropTypes.func,

    };

    componentDidMount() {
        const { dogsList, handleGettingDogs, handleGettingAccessLevelOptions, accessLevelOptions } = this.props;
        if (!accessLevelOptions.length) {
            handleGettingAccessLevelOptions();
        }
        // eslint-disable-next-line react/prop-types
        if (!dogsList.get('dogs').size) {
            handleGettingDogs();
        }
    }

    render() {
        const {
            dogsList, handleSettingCurrentDog, handleGettingDogs, handleSettingCurrentCustomer, accessLevelOptions,
            openDogCreationModal, handleGettingNextDogs
        } = this.props;
        const dogsFilterOptions = [
            { value: '', label: 'All' },
            { value: 'No', label: 'Active' },
            { value: 'Yes', label: 'Inactive' },
            { value: true, label: 'Deceased' },
        ];
        return (
            <div className='dogs'>
                <div className='dogs__filter'>
                    <Field
                        name='dogs-filter'
                        type='text'
                        onChangeCallBack={(value) => handleGettingDogs(
                            (value.label === 'Active' || value.label === 'Inactive' || value.label === 'All')
                                ? '?is_banned='
                                : '?is_deceased=',
                            value.value
                        )}
                        component={renderSelectControl}
                        options={dogsFilterOptions}
                        label='Filter'/>
                </div>
                <AddNewItem
                    className='dogs__add-customer'
                    text='New Dog'
                    onClickHandler={() => { openDogCreationModal({ isOwnerDisabled: false }); }}/>
                <DogsTable
                    isManual
                    isFiltrable
                    accessLevelOptions={accessLevelOptions}
                    data={dogsList.get('dogs')}
                    defaultPageSize={dogsList.get('pageSize')}
                    pages={dogsList.get('pages')}
                    count={dogsList.get('count')}
                    loading={dogsList.get('isLoading')}
                    nextUrl={dogsList.get('nextUrl')}
                    getNext={handleGettingNextDogs}
                    onFiltering={(filter, filterContent) => handleGettingDogs(filter, filterContent)}
                    onSorting={value => handleGettingDogs('?o=', value)}
                    onDogClick={handleSettingCurrentDog}
                    onCustomerClick={handleSettingCurrentCustomer}/>
            </div>
        );
    }
}

const form = reduxForm({
    form: 'dogsForm'
});

const mapStateToProps = state => {
    return {
        dogsList: state.getIn(['dogsList']),
        accessLevelOptions: state.getIn(['misc', 'options', 'accessLevel']).toJS()
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleGettingDogs: bindActionCreators(getDogs, dispatch),
    handleGettingNextDogs: bindActionCreators(getNextDogs, dispatch),
    handleSettingCurrentDog: bindActionCreators(setCurrentDogFromDogsList, dispatch),
    handleSettingCurrentCustomer: bindActionCreators(setCurrentCustomerFromDogsList, dispatch),
    handleGettingAccessLevelOptions: bindActionCreators(getAccessLevelOptions, dispatch),
    openDogCreationModal: bindActionCreators(setDogCreationModalOpened, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(form(DogsPage));
