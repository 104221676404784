import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { ReactComponent as IconTraining } from '../../../../../../assets/icons/icon-all-classes.svg';
import { ReactComponent as IconCar } from '../../../../../../assets/icons/icon-car.svg';
import { ReactComponent as IconLocation } from '../../../../../../assets/icons/icon-location.svg';
import { ReactComponent as IconTrainer } from '../../../../../../assets/icons/icon-trainer-default.svg';
import { ReactComponent as IconClass } from '../../../../../../assets/icons/icon-training.svg';
import AvatarPlaceholder from '../../../../../common/AvatarPlaceholder';
import Icon from '../../../../../common/Icon/';
import ScheduleCardDogs from '../../../Schedule/ScheduleCard/ScheduleCardDogs/';
import { formatMoney } from '../../../../../../helpers/currency';

const renderPayment = (costs, isTraining) => {
    const priceClassName = classNames({
        'schedule-card__price': true,
        'schedule-card__price_multiple': costs.length > 1
    });
    if (costs.length > 0) {
        return (
            <div className={priceClassName}>
                {costs.map((cost, index) => {
                    const isStripePayment = cost.model_type === 'stripepayment';
                    return (
                        <span className='schedule-card__price-item' key={index}>
                            {isStripePayment
                                ? formatMoney(cost.amount)
                                : isTraining ? 'Training Session' : 'Package Credit'}
                        </span>
                    );
                })}
            </div>
        );
    }
    return null;
};

const ClassesHistoryItem = ({ className, data }) => {
    const {
        origin: { costs },
        dogs,
        isTraining,
        date,
        dayOfWeek,
        day,
        event,
        eventName,
        eventPhoto,
        eventLocation,
        trainerFullName,
        start,
        end
    } = data; // eslint-disable-line max-len

    const cardClassName = classNames({
        'schedule-card': true,
        [className]: className
    });
    const accessClassName = classNames({
        'schedule-card__access': true,
        'schedule-card__access_warning': event.access_level === 0
    });
    const photoStyle = {
        backgroundImage: `url(${eventPhoto})`
    };

    return (
        <div className={cardClassName}>

            <div className='schedule-card__inner'>

                <div className='schedule-card__header'>
                    <div className='schedule-card__date'>
                        <div className='schedule-card__day-of-week'>{dayOfWeek}</div>
                        <div className='schedule-card__day'>{day}</div>
                    </div>
                    <ScheduleCardDogs dogs={dogs} />
                </div>
                <div className='schedule-card__body'>
                    <div className='schedule-card__photo' style={photoStyle}>
                        {eventPhoto === null && <AvatarPlaceholder type='dog' size='customer-schedule-card' />}
                        { event.access_level_detail &&
                            <div className={accessClassName}>
                                {event.access_level_detail}
                            </div>
                        }
                    </div>
                    <div className='schedule-card__content'>
                        <div className='schedule-card__content-item'>
                            <h2 className='schedule-card__name'>
                                {isTraining
                                    ? <Icon glyph={IconTraining} className='icon_training' />
                                    : <Icon glyph={IconClass} />
                                }
                                {eventName}
                            </h2>
                            <div className='schedule-card__content-date'>
                                {moment(date).format('dddd')} {moment(date).format('MM/DD/YY')}
                            </div>
                        </div>
                        <div className='schedule-card__content-item'>
                            { eventLocation &&
                                <span className='schedule-card__location'><Icon glyph={IconLocation} />{eventLocation}</span>
                            }
                            <span className='schedule-card__trainer'><Icon
                                glyph={IconTrainer} />{trainerFullName}</span>
                        </div>
                        <div className='schedule-card__content-item'>
                            <span className='schedule-card__time'>
                                <Icon glyph={IconCar} />
                                <span className='schedule-card__time-item'>{start.text}: {start.time}</span>
                                <span className='schedule-card__time-item'>{end.text}: {end.time}</span>
                            </span>
                        </div>
                        <div className='schedule-card__content-item schedule-card__content-item_payment'>
                            <div className='schedule-card__time'>
                                {renderPayment(costs, isTraining)}
                                <Link className='schedule-card__link' to={`/dogs/${dogs[0].id}`}>View Snapshot</Link>
                            </div>
                        </div>
                    </div>
                    <div className='schedule-card__payment'>
                        {renderPayment(costs, isTraining)}
                        {
                            !isTraining && 
                            <Link className='schedule-card__link' to={`/dogs/${dogs[0].id}`}>View Snapshot</Link>
                        }
                    </div>
                </div>
            </div>
            <div className='schedule-card__dogs-slider'>
                { dogs.map((dog, index) => {
                    const style = { backgroundImage: `url(${dog.photo})` };
                    return (
                        <div className='schedule-card__dog' style={style} key={`schedule-card__dog_${index}`} />
                    );
                }) }
            </div>

        </div>
    );
};

ClassesHistoryItem.propTypes = {
    data: PropTypes.shape({}),
    className: PropTypes.string,
};

const mapDispatchToProps = {
};

export default connect(null, mapDispatchToProps)(ClassesHistoryItem);
