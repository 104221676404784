import * as axios from 'axios';
import { fromJS } from 'immutable';
import { SubmissionError } from 'redux-form/immutable';
import { push } from 'react-router-redux';
import * as EmployeeActions from '../actions/dashboard/employeeActions';
import * as ErrorHandlerActions from '../actions/errorHandlerActions';
import * as CustomerActions from '../actions/customer/CustomerActions';
import * as UserActions from '../actions/onboarding/userActions';
import { setDeleteCustomerModalClosed } from '../actions/miscActions';
import { getUserNameSpace } from '../helpers/userRoles';
import { deleteAvailableAddress } from './availableAddresses';
import { loadProfileData } from './profile';
import { generateAlertMeta } from '../components/Alert/actions';

export const getCustomers = (filter = '', filterContent = '') => {
    return (dispatch) => {
        dispatch(EmployeeActions.startLoadingCustomers());
        return axios.get(`/v1/${getUserNameSpace('customers')}/customers/${filter}${filterContent}`)
                    .then((response) => {
                        console.log('getCustomers: ', response.data);
                        dispatch(EmployeeActions.setCustomers(response.data));
                        dispatch(EmployeeActions.finishLoadingCustomers());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(ErrorHandlerActions.setGlobalError(error));
                    });
    };
};

export const getNextCustomers = () => {
    return (dispatch, getState) => {
        dispatch(EmployeeActions.startLoadingCustomers());
        const nextUrl = getState().getIn(['customersList', 'nextUrl']);
        return axios.get(nextUrl)
                    .then((response) => {
                        console.log('getNextCustomers: ', response.data);
                        dispatch(EmployeeActions.setNextCustomers(response.data));
                        dispatch(EmployeeActions.finishLoadingCustomers());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(ErrorHandlerActions.setGlobalError(error));
                    });
    };
};

export const searchCustomersByName = (query) => {
    return dispatch => {
        return axios.get(`/v1/${getUserNameSpace('customers')}/customers/?name=${query}`)
                    .then((response) => {
                        console.log('searchCustomersByName: ', response.data);
                        return response;
                    })
                    .catch((error) => {
                        dispatch(ErrorHandlerActions.setGlobalError(error));
                    });
    };
};

export const getCustomerById = (customerId) => {
    return (dispatch) => {
        return axios.get(`/v1/${getUserNameSpace('customers')}/customers/${customerId}/`)
                    .then((response) => {
                        dispatch(EmployeeActions.clearCurrentCustomerData());
                        dispatch(EmployeeActions.setCurrentCustomer(fromJS(response.data)));
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(ErrorHandlerActions.setGlobalError(error));
                    });
    };
};

export const getCurrentCustomer = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(EmployeeActions.startLoadingCurrentCustomer());
            return axios.get('/v1/customers/me/')
                        .then((response) => {
                            console.log(response.data);
                            dispatch(EmployeeActions.setCurrentCustomerData(fromJS(response.data)));
                            dispatch(EmployeeActions.finishLoadingCurrentCustomer());
                            resolve(response.data);
                        })
                        .catch((error) => {
                            console.log(error);
                            reject(error);
                        });
        });
    };
};

export const deleteCustomer = (customerId) => {
    return (dispatch, getState) => {
        const name = getState().getIn(['currentCustomer', 'instance', 'full_name']);
        return axios.delete(`/v1/${getUserNameSpace('customers')}/customers/${customerId}/`)
                    .then(() => {
                        dispatch(generateAlertMeta({
                            event: 'CUSTOMER_DELETE',
                            responseData: { name }
                        }));
                        dispatch(EmployeeActions.removeCustomerFromList(customerId));
                        dispatch(push('/dashboard/customers'));
                        dispatch(setDeleteCustomerModalClosed());
                    })
                    .catch((error) => {
                        console.log(error);
                        dispatch(ErrorHandlerActions.setFormError(error.response));
                    });
    };
};

export const changeCustomerData = (formData) => {
    return async dispatch => {
        const customerData = await axios.patch('/v1/customers/me/', formData);
        return customerData;
    };
};

export const updateCustomerLocationData = (addressData) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(getCurrentCustomer())
                .then((customerData) => {
                    const actions = [];
                    if (customerData && customerData.address &&
                        (addressData.address && addressData.address !== customerData.address)) {
                        actions.push(dispatch(deleteAvailableAddress(null, { id: customerData.address })));
                    }
                    actions.push(dispatch(changeCustomerData(addressData)));
                    Promise.all(actions)
                           .then(res => resolve(res))
                           .catch(err => reject(err));
                })
                .catch(err => {
                    reject(err);
                });
        });
    };
};

export const addCustomerPhone = (phoneData) => {
    return axios.post(`/v1/${getUserNameSpace('me')}/me/available-phones/`, phoneData);
};

export const addCustomerPrimaryPhone = (phoneData) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            addCustomerPhone(phoneData).then(response => {
                axios.patch('/v1/customers/me/', { primary_phone: response.data.id }).then(customerData => {
                    dispatch(loadProfileData()).then(() => resolve(response));
                }).catch(err => {
                    throw new SubmissionError(err.response.data);
                });
            });
        });
    };
};


export const sendVerificationRequest = (phoneId) => {
    return dispatch => {
        return axios.post(`/v1/customers/me/available-phones/${phoneId}/verification-code/`);
    };
};

export const verifyCustomersPhone = (phoneId, code) => {
    return dispatch => {
        return axios.post(`/v1/customers/me/available-phones/${phoneId}/verify/`, {
                code
            })
            .then(() => {
                dispatch(UserActions.verifyPrimaryPhone(phoneId));
                dispatch(CustomerActions.verifyPrimaryPhone(phoneId));
            })
            .catch(({ response }) => {
                if (response && response.data) {
                    const errorMessage = Array.isArray(response.data) && response.data.length ? response.data[0] : response.data; // eslint-disable-line
                    throw new SubmissionError({
                        code: errorMessage
                    });
                }
                throw new SubmissionError({
                    _error: 'Something went wrong. Please try again'
                });
            });
    };
};

export const updateCustomerDefaultPickupAddress = (pickupId) => {
    return dispatch => {
        return axios.patch(`/v1/${getUserNameSpace('customers')}/me/`, { pickup_location: pickupId });
    };
};

export const updateCustomerDefaultDropoffAddress = (dropoffId) => {
    return dispatch => {
        return axios.patch(`/v1/${getUserNameSpace('customers')}/me/`, { drop_off_location: dropoffId });
    };
};
