import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { finishIntroductionStep } from '../../../../actions/onboarding/dogCreationActions';
import { skipAllStepsAndFinishLater, goToIntroductionStep } from '../../../../actions/onboarding/onBoardingStepsActions';
import SkipStep from '../../../common/SkipStep/SkipStep';
import Step from '../../../common/Step';
import StepDescription from '../../../common/StepDescription';
import StepName from '../../../common/StepName';
import IntroductionFormContainer from './IntroductionForm';

class IntroductionPage extends React.PureComponent { // eslint-disable-line
    constructor() {
        super();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.goToIntroductionStep();
    }

    handleSubmit(values) {
        const { handleIntroductionInfo } = this.props;

        const {
            breed,
            month,
            name,
            year,
        } = values.toJS();

        const dogBirthday = new Date(year.value, month.value - 1).toISOString();

        window._dcq.push(['identify', {
            tags: ['Prospect'],
            remove_tags: ['Lead'],
            dog_access_level: '0',
            dog_birthday: dogBirthday,
            dog_breed: breed.label,
            success: () => {
                window._dcq.push([
                    'track',
                    'Created dog profile',
                    { dog_name: name },
                ]);
            }
        }]);

        window.gtag('event', 'triggered_prospect_criteria', {
            event_category: 'Triggered Prospect Criteria',
        });

        return handleIntroductionInfo(values);
    }

    render() {
        const { stepName = 'introduction', handleAllStepsSkip } = this.props;
        const Disclaimer = 'Don’t worry. If you have more than one dog, you can add additional dogs later.';
        return (
            <Step
                logoClassName='introduction__logo'
                contentClassName='introduction__content'
                contentsContainerClassName='introduction__container'
                stepName={stepName}
                componentsImage={<div className='introduction__img'/>}
                nameAndDescriptionTopped
                nameComponent={
                    <StepName
                        className='introduction__step-name'
                        numberText='Step 1'
                        nameText='Introduction'/>
                }
                descriptionComponent={
                    <StepDescription
                        className='introduction__step-description'
                        text='Tell us about your dog'
                        disclaimer={Disclaimer}/>
                }
                formComponent={
                    <IntroductionFormContainer
                        onSubmit={this.handleSubmit}/>
                }
                skipComponent={
                    <SkipStep
                        className='introduction__skip-step'
                        skipLinkText='Finish later'
                        skipLinkAction={handleAllStepsSkip}
                        skipLinkTo='waiver'/>
                }/>
        );
    }
}

IntroductionPage.propTypes = {
    stepName: PropTypes.string,
    handleAllStepsSkip: PropTypes.func,
    handleIntroductionInfo: PropTypes.func,
    changeLocationOnIntroductionComplete: PropTypes.func,
    goToIntroductionStep: PropTypes.func
};

const mapStateToProps = state => ({
    steps: state.get('steps')
});

const mapDispatchToProps = (dispatch) => ({
    handleAllStepsSkip: bindActionCreators(skipAllStepsAndFinishLater, dispatch),
    handleIntroductionInfo: bindActionCreators(finishIntroductionStep, dispatch),
    goToIntroductionStep: bindActionCreators(goToIntroductionStep, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(IntroductionPage);
