/* eslint-disable quote-props */
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import * as CalendarActions from '../../../../../actions/dashboard/calendarActions';
import * as EventActions from '../../../../../actions/dashboard/eventActions';
import { eventTypes } from '../../../../../helpers/options';
import * as ClassOccurrencesService from '../../../../../services/classOccurrences';
import * as TrainingsService from '../../../../../services/trainings';
import AddNewItem from '../../../../common/AddNewItem/AddNewItem';
import renderSelectControl from '../../../../common/Form/SelectControl/SelectControl';
import FormContainer from '../../../FormContainer';
import CalendarComponent from '../CalendarComponent/CalendarComponent';
import { isTrainer } from '../../../../../helpers/userRoles';

class CalendarPage extends React.PureComponent {
    static propTypes = {
        calendar: PropTypes.shape({
            type: PropTypes.string,
            view: PropTypes.string,
            calendarSelectedDays: PropTypes.oneOfType([
                PropTypes.arrayOf(PropTypes.shape({})),
                PropTypes.shape({})
            ]),
            trainingInstructorQuery: PropTypes.string,
            trainingProgramQuery: PropTypes.string,
            classOccurrencesLocationQuery: PropTypes.string,
            classOccurrencesTrainingClassQuery: PropTypes.string,
            trainersColorsList: PropTypes.shape({}),
            rangeStart: PropTypes.string,
        }),
        changeCalendarType: PropTypes.func.isRequired,
        trainingsList: PropTypes.shape({
            trainingsItems: PropTypes.arrayOf(PropTypes.shape({})),
            trainingsTrainersList: PropTypes.arrayOf(PropTypes.string),
            isTrainingsLoading: PropTypes.bool,
        }),
        getTrainingsList: PropTypes.func.isRequired,
        getNextTrainingsList: PropTypes.func.isRequired,
        classOccurrencesList: PropTypes.shape({
            classOccurrencesItems: PropTypes.arrayOf(PropTypes.shape({})),
            classOccurrencesTrainersList: PropTypes.arrayOf(PropTypes.string),
            isClassOccurrencesLoading: PropTypes.bool
        }),
        getClassOccurrencesList: PropTypes.func.isRequired,
        getNextClassOccurrencesList: PropTypes.func.isRequired,
        handleGoingToEventPage: PropTypes.func,
        setTrainingInstructorQuery: PropTypes.func,
        clearTrainingInstructorQuery: PropTypes.func,
        setTrainingProgramQuery: PropTypes.func,
        clearTrainingProgramQuery: PropTypes.func,
        setClassOccurrencesLocationQuery: PropTypes.func,
        clearClassOccurrencesLocationQuery: PropTypes.func,
        setClassOccurrencesTrainingQuery: PropTypes.func,
        clearClassOccurrencesTrainingQuery: PropTypes.func,
        getTrainersColorsList: PropTypes.func,
        patchTrainingEvent: PropTypes.func,
        patchClassOccurrenceEvent: PropTypes.func,
        setNewTrainingEvent: PropTypes.func,
        setNewClassOccurrenceEvent: PropTypes.func,
        setClassOccurrenceEventInfo: PropTypes.func,
        setTrainingEventInfo: PropTypes.func,
        getCalendarSelectedRange: PropTypes.func,
        setDaypickerShown: PropTypes.func,
        setDaypickerHidden: PropTypes.func,
        setRangeStart: PropTypes.func,
        setRangeEnd: PropTypes.func,
        getDateInterval: PropTypes.func,
        clearCurrentEventProducts: PropTypes.func,
        currentUser: PropTypes.shape({}),
    };

    componentDidMount() {
        const {
            calendar, getTrainingsList, setNewTrainingEvent, getClassOccurrencesList,
            setNewClassOccurrenceEvent, setRangeStart, setRangeEnd, clearCurrentEventProducts
        } = this.props;
        const { rangeStart, rangeEnd } = calendar;

        const formattedToday = moment().format('YYYY-MM-DD');
        if (rangeStart === null && rangeEnd === null) {
            setRangeStart(formattedToday);
            setRangeEnd(formattedToday);
        }
        if (calendar.type === 'Training') {
            getTrainingsList();
            setNewTrainingEvent();
        } else {
            if (rangeStart !== null && rangeEnd !== null) {
                getClassOccurrencesList({ startDate: rangeStart, endDate: rangeEnd });
            } else {
                getClassOccurrencesList({});
            }
            setNewClassOccurrenceEvent();
            clearCurrentEventProducts();
        }
    }

    componentWillReceiveProps(nextProps) {
        const {
            calendar, getTrainingsList, getClassOccurrencesList, clearTrainingInstructorQuery,
            clearTrainingProgramQuery, clearClassOccurrencesLocationQuery, clearClassOccurrencesTrainingQuery,
            trainingsList, classOccurrencesList, getTrainersColorsList, setNewTrainingEvent, setNewClassOccurrenceEvent,
        } = this.props;
        const {
            type, trainingInstructorQuery, trainingProgramQuery, classOccurrencesLocationQuery,
            classOccurrencesTrainingClassQuery
        } = nextProps.calendar;

        // // reset calendar.query if calendar.type changes
        if (calendar.type !== type) {
            if (type === 'Training') {
                clearTrainingInstructorQuery();
                clearTrainingProgramQuery();
                getTrainingsList('');
                setNewTrainingEvent();
            } else {
                clearClassOccurrencesLocationQuery();
                clearClassOccurrencesTrainingQuery();
                getClassOccurrencesList({});
                setNewClassOccurrenceEvent();
            }
        }

        // queries for Training has changed, do new getTrainingsList request
        if (calendar.trainingInstructorQuery !== trainingInstructorQuery) {
            if (trainingProgramQuery.length === 0) {
                getTrainingsList(`${trainingInstructorQuery}`);
            } else if (trainingInstructorQuery.length === 0) {
                if (trainingProgramQuery.length !== 0) {
                    getTrainingsList(`${trainingProgramQuery}`);
                } else {
                    getTrainingsList('');
                }
            } else {
                getTrainingsList(`${trainingInstructorQuery}&${trainingProgramQuery}`);
            }
        }

        if (calendar.trainingProgramQuery !== trainingProgramQuery) {
            if (trainingInstructorQuery.length === 0) {
                getTrainingsList(`${trainingProgramQuery}`);
            } else if (trainingProgramQuery.length === 0) {
                if (trainingInstructorQuery.length !== 0) {
                    getTrainingsList(`${trainingInstructorQuery}`);
                } else {
                    getTrainingsList('');
                }
            } else {
                getTrainingsList(`${trainingInstructorQuery}&${trainingProgramQuery}`);
            }
        }

        // query for Class Occurrences has changed, do new getClassOccurrencesList request
        if (calendar.classOccurrencesLocationQuery !== classOccurrencesLocationQuery) {
            if (classOccurrencesTrainingClassQuery.length === 0) {
                getClassOccurrencesList({ queryString: `${classOccurrencesLocationQuery}` });
            } else if (classOccurrencesLocationQuery.length === 0) {
                if (classOccurrencesTrainingClassQuery.length !== 0) {
                    getClassOccurrencesList({ queryString: `${classOccurrencesTrainingClassQuery}` });
                } else {
                    getClassOccurrencesList({});
                }
            } else {
                getClassOccurrencesList({
                    queryString: `${classOccurrencesTrainingClassQuery}&${classOccurrencesLocationQuery}`
                });
            }
        }

        if (calendar.classOccurrencesTrainingClassQuery !== classOccurrencesTrainingClassQuery) {
            if (classOccurrencesLocationQuery.length === 0) {
                getClassOccurrencesList({ queryString: `${classOccurrencesTrainingClassQuery}` });
            } else if (classOccurrencesTrainingClassQuery.length === 0) {
                if (classOccurrencesLocationQuery.length !== 0) {
                    getClassOccurrencesList({ queryString: `${classOccurrencesLocationQuery}` });
                } else {
                    getClassOccurrencesList({});
                }
            } else {
                getClassOccurrencesList({
                    queryString: `${classOccurrencesLocationQuery}&${classOccurrencesTrainingClassQuery}`
                });
            }
        }

        // get color for each unique trainer
        if (trainingsList.trainingsTrainersList.length < nextProps.trainingsList.trainingsTrainersList.length ||
            // eslint-disable-next-line max-len
            classOccurrencesList.classOccurrencesTrainersList.length < nextProps.classOccurrencesList.classOccurrencesTrainersList.length) {
            getTrainersColorsList();
        }
    }

    componentWillUnmount() {
        const {
            setTrainingInstructorQuery, setTrainingProgramQuery, setClassOccurrencesLocationQuery,
            setClassOccurrencesTrainingQuery
        } = this.props;
        setTrainingInstructorQuery('');
        setTrainingProgramQuery('');
        setClassOccurrencesLocationQuery('');
        setClassOccurrencesTrainingQuery('');
    }

    onFiltersFieldChange = e => {
        const {
            setTrainingInstructorQuery, setTrainingProgramQuery, setClassOccurrencesLocationQuery,
            setClassOccurrencesTrainingQuery
        } = this.props;
        let setQueryCB;
        if (e.query === 'trainer') {
            setQueryCB = setTrainingInstructorQuery;
        }
        if (e.query === 'program') {
            setQueryCB = setTrainingProgramQuery;
        }
        if (e.query === 'location') {
            setQueryCB = setClassOccurrencesLocationQuery;
        }
        if (e.query === 'name') {
            setQueryCB = setClassOccurrencesTrainingQuery;
        }

        // length === 0 is it's an empty string
        if (e.value.length === 0) {
            setQueryCB('');
        } else {
            setQueryCB(`${e.query}=${e.value}`);
        }
    };

    getAddNewItemButton = () => {
        const { type } = this.props.calendar;
        if (type === 'Training') {
            return (
                <AddNewItem
                    className='calendar__add-new'
                    // text={type === 'Training' ? 'New Training' : 'New Class'}
                    text='New Training'
                    onClickHandler={() => this.openEventPage('new')}/>
            );
        }
        return null;
    };

    getEventTypeFilters = () => {
        const { currentUser: { id, full_name }, calendar: { type } } = this.props;
        let initialValues = {};
        if (isTrainer()) {
            initialValues = {
                training_instructor: {
                    value: id,
                    label: full_name,
                    query: 'trainer'
                }
            };
        }
        return (
            type === 'Training'
                ? (<FormContainer
                    form={{
                        form: 'calendarTrainingSearch',
                        className: 'filter-form__defined',
                        initialValues
                    }}
                    onFieldChange={this.onFiltersFieldChange}/>)
                : (<FormContainer
                    form={{ form: 'calendarClassesSearch', className: 'filter-form__defined' }}
                    onFieldChange={this.onFiltersFieldChange}/>)
        );
    };

    openEventPage = (id) => {
        const { handleGoingToEventPage } = this.props;
        const { type } = this.props.calendar;

        return handleGoingToEventPage({
            context: type === 'Training' ? 'trainings' : 'classes',
            id,
        });
    };

    showFakeViewPicker = () => {
        return (
            <div className='rbc-toolbar'>
                <span className='rbc-btn-group'>
                    <button disabled type='button'>day</button>
                    <button disabled type='button'>week</button>
                    <button disabled type='button'>month</button>
                </span>
            </div>
        );
    };

    render() {
        const { type, trainersColorsList, calendarSelectedDays, view, rangeStart } = this.props.calendar;
        const { trainingsItems, isTrainingsLoading } = this.props.trainingsList;
        const { classOccurrencesItems, isClassOccurrencesLoading } = this.props.classOccurrencesList;
        const {
            changeCalendarType, handleGoingToEventPage, getTrainingsList, getClassOccurrencesList,
            patchTrainingEvent, patchClassOccurrenceEvent, setClassOccurrenceEventInfo, setTrainingEventInfo,
            getCalendarSelectedRange, setDaypickerShown, setDaypickerHidden, setRangeStart, setRangeEnd,
            getDateInterval
        } = this.props;

        const {
            trainingInstructorQuery,
            trainingProgramQuery,
            classOccurrencesLocationQuery,
            classOccurrencesTrainingClassQuery
        } = this.props.calendar;

        return (
            <div className='calendar'>
                <div className='calendar__top'>
                    <div className='filter-form'>
                        <Field
                            name='calendar-type-filter'
                            type='text'
                            onChangeCallBack={changeCalendarType}
                            component={renderSelectControl}
                            options={eventTypes}
                            selectedValue={type}
                            label='Calendar'/>
                        {this.getEventTypeFilters()}
                    </div>
                    {this.getAddNewItemButton()}
                </div>
                {(isTrainingsLoading || isClassOccurrencesLoading) && this.showFakeViewPicker()}
                <CalendarComponent
                    filters={{
                        trainingInstructorQuery,
                        trainingProgramQuery,
                        classOccurrencesLocationQuery,
                        classOccurrencesTrainingClassQuery
                    }}
                    events={type === 'Training' ? trainingsItems : classOccurrencesItems}
                    eventsColors={trainersColorsList}
                    isLoading={isTrainingsLoading || isClassOccurrencesLoading}
                    onSelectEvent={event => this.openEventPage(event.id)}
                    handleGoingToEventPage={handleGoingToEventPage}
                    selectedDay={rangeStart}
                    type={type}
                    view={view}
                    getTrainingsList={getTrainingsList}
                    getClassOccurrencesList={getClassOccurrencesList}
                    patchTrainingEvent={patchTrainingEvent}
                    patchClassOccurrenceEvent={patchClassOccurrenceEvent}
                    setClassOccurrenceEventInfo={setClassOccurrenceEventInfo}
                    setTrainingEventInfo={setTrainingEventInfo}
                    calendarSelectedDays={calendarSelectedDays}
                    getCalendarSelectedRange={getCalendarSelectedRange}
                    setDaypickerShown={setDaypickerShown}
                    setDaypickerHidden={setDaypickerHidden}
                    setRangeStart={setRangeStart}
                    setRangeEnd={setRangeEnd}
                    getDateInterval={getDateInterval}/>
            </div>
        );
    }
}

const form = reduxForm({
    form: 'CalendarForm',
    enableReinitialize: true
});

const mapStateToProps = state => {
    return {
        calendar: state.get('calendar').toJS(),
        trainingsList: state.get('trainingsList').toJS(),
        classOccurrencesList: state.get('classOccurrencesList').toJS(),
        currentUser: state.get('user').toJS()
    };
};

const mapDispatchToProps = (dispatch) => ({
    changeCalendarType: bindActionCreators(CalendarActions.changeCalendarType, dispatch),
    getTrainingsList: bindActionCreators(TrainingsService.getTrainingsList, dispatch),
    getNextTrainingsList: bindActionCreators(TrainingsService.getNextTrainingsList, dispatch),
    getClassOccurrencesList: bindActionCreators(ClassOccurrencesService.getClassOccurrencesList, dispatch),
    getNextClassOccurrencesList: bindActionCreators(ClassOccurrencesService.getNextClassOccurrencesList, dispatch),
    handleGoingToEventPage: bindActionCreators(CalendarActions.goToEventPage, dispatch),
    setTrainingInstructorQuery: bindActionCreators(CalendarActions.setTrainingInstructorQuery, dispatch),
    clearTrainingInstructorQuery: bindActionCreators(CalendarActions.clearTrainingInstructorQuery, dispatch),
    setTrainingProgramQuery: bindActionCreators(CalendarActions.setTrainingProgramQuery, dispatch),
    clearTrainingProgramQuery: bindActionCreators(CalendarActions.clearTrainingProgramQuery, dispatch),
    setClassOccurrencesLocationQuery: bindActionCreators(CalendarActions.setClassOccurrencesLocationQuery, dispatch),
    clearClassOccurrencesLocationQuery: bindActionCreators(CalendarActions.clearClassOccurrencesLocationQuery,
        dispatch),
    setClassOccurrencesTrainingQuery: bindActionCreators(CalendarActions.setClassOccurrencesTrainingQuery, dispatch),
    clearClassOccurrencesTrainingQuery: bindActionCreators(CalendarActions.clearClassOccurrencesTrainingQuery,
        dispatch),
    getTrainersColorsList: bindActionCreators(CalendarActions.getTrainersColorsList, dispatch),
    patchTrainingEvent: bindActionCreators(TrainingsService.patchTrainingEvent, dispatch),
    patchClassOccurrenceEvent: bindActionCreators(ClassOccurrencesService.patchClassOccurrenceEvent, dispatch),
    setNewClassOccurrenceEvent: bindActionCreators(EventActions.setNewClassOccurrenceEvent, dispatch),
    setNewTrainingEvent: bindActionCreators(EventActions.setNewTrainingEvent, dispatch),
    setClassOccurrenceEventInfo: bindActionCreators(EventActions.setClassOccurrenceEventInfo, dispatch),
    setTrainingEventInfo: bindActionCreators(EventActions.setTrainingEventInfo, dispatch),
    getCalendarSelectedRange: bindActionCreators(CalendarActions.getCalendarSelectedRange, dispatch),
    setDaypickerShown: bindActionCreators(CalendarActions.setDaypickerShown, dispatch),
    setDaypickerHidden: bindActionCreators(CalendarActions.setDaypickerHidden, dispatch),
    setRangeStart: bindActionCreators(CalendarActions.setRangeStart, dispatch),
    setRangeEnd: bindActionCreators(CalendarActions.setRangeEnd, dispatch),
    getDateInterval: bindActionCreators(CalendarActions.getDateInterval, dispatch),
    clearCurrentEventProducts: bindActionCreators(EventActions.clearCurrentEventProducts, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(CalendarPage));
