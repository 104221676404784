import { fromJS } from 'immutable';
import { actions, change, initialize, reset, startSubmit, stopSubmit, submit } from 'redux-form';
import { setGlobalError } from '../errorHandlerActions';
import * as ErrorActions from '../form/errorActions';
import { onModalGetOptions } from '../modal/modalActions';
import * as SubmitActions from '../modal/submitActions';
import * as UpdateActions from '../modal/updateActions';
import { generateAlertMeta } from '../../components/Alert/actions';

const defaultNormalizer = data => data;

export const dispatchSubmit = formName => {
    return dispatch => {
        dispatch(submit(formName));
    };
};

export const dispatchReset = formName => {
    return dispatch => {
        dispatch(reset(formName));
    };
};

export const dispatchInitialize = ({ form, data, keepDirty = true }) => {
    return dispatch => {
        dispatch(initialize(form, data, keepDirty), { keepDirty });
    };
};

export const dispatchChangeField = (form, field, value) => {
    return dispatch => {
        dispatch(change(form, field, value));
    };
};

export const onGetOptions = onModalGetOptions;

export const setError = ({ form, field, message }) => {
    return dispatch => {
        const errors = {};
        errors[field] = message;
        dispatch(actions.updateSyncErrors(form, errors));
    };
};

const displayAlert = (config) => {
    return generateAlertMeta(config);
};

export const onFormSubmit = ({ formData, submitNormalizer, updateNormalizer, formConfig }) => {
    return (dispatch, getState) => {
        const {
            submitAction,
            updateAction,
            errorAction,
            submitClickHandler,
            shouldInitialise = true,
            alertEvent,
            alertData
        } = formConfig;
        if (submitAction) {
            const submitFn = typeof submitAction === 'function' ? submitAction : SubmitActions[submitAction];
            const updateFn = typeof updateAction === 'function' ? updateAction : UpdateActions[updateAction];
            dispatch(startSubmit(formConfig.form));
            dispatch(submitFn({
                formConfig,
                submitData: submitNormalizer ? submitNormalizer(formData, getState(), formConfig) : formData,
            }))
                .then((response) => {
                    if (alertEvent) {
                        dispatch(displayAlert({
                            event: alertEvent,
                            responseData: alertData || response.data,
                            state: getState() }));
                    }

                    if (updateAction) {
                        dispatch(updateFn({
                            formConfig,
                            formData,
                            responseData: fromJS(response.data),
                            normalizer: updateNormalizer || defaultNormalizer
                        }));
                    }

                    dispatch(stopSubmit(formConfig.form));
                    // Reinitialize the form with the current values to reset isDirty/isPristine flags;
                    if (shouldInitialise) {
                        dispatch(initialize(formConfig.form, formData.toJS()));
                    }
                })
                .catch(err => {
                    console.log(err);
                    console.log(err.response);
                    dispatch(stopSubmit(formConfig.form));

                    if (errorAction) {
                        dispatch(ErrorActions[errorAction]({
                            error: err,
                            formConfig,
                            formData,
                            submitData: submitNormalizer ? submitNormalizer(formData, getState()) : formData
                        }));
                    } else {
                        dispatch(setGlobalError(err));
                    }
                });
        } else {
            dispatch(stopSubmit(formConfig.form));
            if (submitClickHandler) {
                dispatch(SubmitActions[submitClickHandler]({ formConfig, formData }));
            }
        }
    };
};
