import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as ModalTypes from '../types/modalTypes';

const INITIAL_STATE = fromJS({
    isOpened: false,
    isSubmitting: false,
    isCanceling: false,
    modalType: null,
    data: null,
    error: null,
});

const modalReducer = handleActions({
    [ModalTypes.RESET_MODAL_REDUCER]: () => {
        return INITIAL_STATE;
    },
    [ModalTypes.SET_MODAL_OPENED]: (state, action) => {
        return state.set('isOpened', true).set('modalType', action.payload);
    },
    [ModalTypes.SET_MODAL_CLOSED]: (state) => {
        return state.set('isOpened', false);
    },
    [ModalTypes.SET_MODAL_SUBMIT_REQUEST_STARTED]: (state) => {
        return state
            .set('isSubmitting', true)
            .set('error', null);
    },
    [ModalTypes.SET_MODAL_SUBMIT_REQUEST_FINISHED]: (state) => {
        return state.set('isSubmitting', false);
    },
    [ModalTypes.SET_MODAL_CANCEL_REQUEST_STARTED]: (state) => {
        return state.set('isCanceling', true);
    },
    [ModalTypes.SET_MODAL_CANCEL_REQUEST_FINISHED]: (state) => {
        return state.set('isCanceling', false);
    },
    [ModalTypes.SET_MODAL_DATA]: (state, action) => {
        return state.set('data', action.payload);
    },
    [ModalTypes.SET_MODAL_DATA_OPEN]: (state, action) => {
        return fromJS({
            isOpened: true,
            isSubmitting: false,
            isCanceling: false,
            modalType: action.payload.type,
            error: null,
        }).set('data', action.payload);
    },
    [ModalTypes.SET_MODAL_ACTIVE_FORM]: (state, action) => {
        const newData = Object.assign({}, state.get('data'));
        newData.activeForm = action.payload;
        return state.set('data', newData);
    },
    [ModalTypes.SET_MODAL_SUBMIT_ERROR]: (state, action) => state.set('error', action.payload),
    [ModalTypes.CLEAR_MODAL_SUBMIT_ERROR]: (state, action) => state.set('error', null),
}, INITIAL_STATE);

export default modalReducer;
