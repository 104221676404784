import { createAction } from 'redux-actions';
import { reset } from 'redux-form';
import * as MessagesTypes from '../types/messagesTypes';

export const setMessagesWidgetOpened = createAction(MessagesTypes.SET_MESSAGES_WIDGET_OPENED);
export const setMessagesWidgetClosed = createAction(MessagesTypes.SET_MESSAGES_WIDGET_CLOSED);
export const setMessagesWidgetMinimized = createAction(MessagesTypes.SET_MESSAGES_WIDGET_MINIMIZED);
export const setMessagesLoading = createAction(MessagesTypes.SET_MESSAGES_LOADING);
export const setMessagesLoadingMore = createAction(MessagesTypes.SET_MESSAGES_LOADING_MORE);
export const setMessagesLoaded = createAction(MessagesTypes.SET_MESSAGES_LOADED);
export const setMessagesData = createAction(MessagesTypes.SET_MESSAGES_DATA);
export const setMessagesFailed = createAction(MessagesTypes.SET_MESSAGES_FAILED);
export const setMessagesSubmitting = createAction(MessagesTypes.SET_MESSAGES_SUBMITTING);
export const addMessageToMessagesItems = createAction(MessagesTypes.ADD_MESSAGE_TO_MESSAGES_ITEMS);
export const refreshMessagesWidget = (data) => {
    return dispatch => {
        dispatch(reset('messagesWidgetForm'));
        return dispatch(setMessagesWidgetOpened(data));
    };
};
export const setNextMessagesData = createAction(MessagesTypes.SET_NEXT_MESSAGES_DATA);
export const updateMessageDataById = createAction(MessagesTypes.UPDATE_MESSAGE_DATA_BY_ID);
export const updateMessagesData = createAction(MessagesTypes.UPDATE_MESSAGES_DATA);
export const setMessagesPolling = createAction(MessagesTypes.SET_MESSAGES_POLLING);
