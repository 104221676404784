import PropTypes from 'prop-types';
import React from 'react';
import AttendingDogsCard from './AttendingDogsCard';

const AttendingDogsGrid = ({ items, eventData, attendingDogsActions }) => {
    const renderCards = () => {
        if (items === undefined) {
            return (
                <div className='attending-dogs-grid__empty-content'>
                    Loading...
                </div>
            );
        }

        if (!items.length) {
            return (
                <div className='attending-dogs-grid__empty-content'>
                    No dogs signed up for this event.
                </div>
            );
        }

        const cards = [];

        items.forEach((item, i) => {
            cards.push(
                <AttendingDogsCard
                    key={'card_' + i}
                    data={item}
                    eventData={eventData}
                    attendingDogsActions={attendingDogsActions}/>
            );
        });

        return cards;
    };

    return (
        <div className='attending-dogs-grid'>
            {renderCards()}
        </div>
    );
};

AttendingDogsGrid.propTypes = {
    eventData: PropTypes.shape({}),
    items: PropTypes.arrayOf(PropTypes.shape({})),
    attendingDogsActions: PropTypes.shape({}),
};

export default AttendingDogsGrid;
