import * as Options from '../../helpers/options';
import * as MiscActions from '../miscActions';

export const getBreedOptions = () => {
    return (dispatch, getState) => {
        return (value, callback) => {
            const options = getState().getIn(['misc', 'options', 'breed']).toJS();

            if (options.length) {
                callback(null, { options });
                return;
            }

            dispatch(MiscActions.getBreedOptions(callback));
        };
    };
};

export const getWeightOptions = () => {
    return (dispatch, getState) => {
        return (value, callback) => {
            const options = getState().getIn(['misc', 'options', 'weight']).toJS();

            if (options.length) {
                callback(null, { options });
                return;
            }

            dispatch(MiscActions.getWeightOptions(callback));
        };
    };
};

export const getGenderOptions = () => {
    return (dispatch, getState) => {
        return (value, callback) => {
            const options = getState().getIn(['misc', 'options', 'sex']).toJS();

            if (options.length) {
                callback(null, { options });
                return;
            }

            dispatch(MiscActions.getSexOptions(callback));
        };
    };
};

export const getTimeOptions = () => {
    return () => {
        return (value, callback) => {
            callback(null, {
                options: Options.time,
            });
        };
    };
};

export const getMonthOptions = () => {
    return () => {
        return (value, callback) => {
            callback(null, {
                options: Options.month,
            });
        };
    };
};

export const getYearOptions = () => {
    return () => {
        return (value, callback) => {
            callback(null, {
                options: Options.year,
            });
        };
    };
};

export const getAccessLevelOptions = () => {
    return (dispatch, getState) => {
        return (value, callback) => {
            const options = getState().getIn(['misc', 'options', 'accessLevel']).toJS();

            if (options.length) {
                callback(null, { options });
                return;
            }

            dispatch(MiscActions.getAccessLevelOptions(callback));
        };
    };
};

export const getVeterinarianOptions = () => {
    return (dispatch, getState) => {
        return (value, callback) => {
            const options = getState().getIn(['misc', 'options', 'veterinarian']).toJS();

            if (options.length) {
                callback(null, { options });
                return;
            }

            dispatch(MiscActions.getVeterinarianOptions(callback));
        };
    };
};

export const getImmunizationOptions = () => {
    return (dispatch, getState) => {
        return (value, callback) => {
            const options = getState().getIn(['misc', 'options', 'immunization']).toJS();

            if (options.length) {
                callback(null, { options });
                return;
            }

            dispatch(MiscActions.getImmunizationOptions(callback));
        };
    };
};

export const getDogReportIconOptions = ({ iconType }) => {
    return (dispatch, getState) => {
        return (value, callback) => {
            const options = getState().getIn(['misc', 'options', 'dogReportIcons']);

            if (iconType in options) {
                callback(null, { options: options[iconType] });
                return;
            }

            dispatch(MiscActions.getIconOptions(iconType, callback));
        };
    };
};

export const getClassOptions = () => {
    return (dispatch, getState) => {
        return (value, callback) => {
            const options = getState().getIn(['misc', 'options', 'class']);

            if (options.length) {
                callback(null, { options });
                return;
            }

            dispatch(MiscActions.getClassOptions(callback, value));
        };
    };
};

export const getUniqueClassOptions = () => {
    return dispatch => {
        return (value, callback) => {
            dispatch(MiscActions.getUniqueClassOptions(callback, value));
        };
    };
};

export const getCalendarClassOptions = () => {
    return dispatch => {
        return (value, callback) => dispatch(MiscActions.getCalendarClassOptions(callback, value));
    };
};

export const getPaymentCardsOptions = () => {
    return (dispatch, getState) => {
        return (value, callback) => {
            const options = getState().getIn(['currentCustomer', 'payment_cards', 'cards']).toJS();
            const resultOptions = options.map(({ type, last4, id }) => {
                return {
                    label: `${type} ending in ${last4}`,
                    value: id,
                    type,
                };
            });
            resultOptions.push({
                value: null,
                label: 'Add New Card',
            });
            if (resultOptions.length) {
                callback(null, { options: resultOptions });
            }
        };
    };
};

export const getCustomerDogsOptions = (notDeceased = false) => {
    return (dispatch, getState) => {
        return (value, callback) => {
            let options = getState().getIn(['currentCustomer', 'instance', 'dogs']).toJS();
            if (notDeceased) {
                options = options.filter(({ is_deceased }) => !is_deceased); // eslint-disable-line
            }
            const resultOptions = options.map(({ id, name }) => {
                return {
                    label: name,
                    value: id,
                };
            });
            if (resultOptions.length) {
                callback(null, { options: resultOptions });
            }
        };
    };
};
export const getCustomerNotDeceasedDogsOptions = () => {
    return getCustomerDogsOptions(true);
};

export const getPurchasedTrainingProgramOptions = () => {
    return (dispatch, getState) => {
        return (value, callback) => {
            const options = getState().getIn(['misc', 'options', 'training']).toJS();

            if (options.length) {
                callback(null, { options });
                return;
            }

            dispatch(MiscActions.getPurchasedTrainingProgramOptions(callback, value));
        };
    };
};

export const getDiscountOptions = () => {
    return (dispatch, getState) => {
        return (value, callback) => {
            const options = getState().getIn(['misc', 'options', 'discount']).toJS();
            if (options.length) {
                callback(null, { options });
                return;
            }

            dispatch(MiscActions.getDiscountOptions(callback));
        };
    };
};

export const getTrainerOptions = () => {
    return (dispatch, getState) => {
        return (value, callback) => {
            const options = getState().getIn(['misc', 'options', 'trainer']).toJS();
            if (options.length) {
                callback(null, { options });
                return;
            }

            dispatch(MiscActions.getTrainerOptions(callback, value));
        };
    };
};

export const getTrainerByProgramOptions = ({ programId }) => {
    return (dispatch, getState) => {
        return (value, callback) => {
            const options = getState().getIn(['misc', 'options', 'trainer']).toJS();

            if (options.length) {
                callback(null, { options });
                return;
            }

            dispatch(MiscActions.getTrainerByProgramOptions(callback, value, programId));
        };
    };
};

export const getLocationOptions = () => {
    return (dispatch, getState) => {
        return (value, callback) => {
            const options = getState().getIn(['misc', 'options', 'location']).toJS();

            if (options.length) {
                callback(null, { options });
                return;
            }

            dispatch(MiscActions.getLocationOptions(callback, value));
        };
    };
};

export const getClassEventDogOptions = currentEventJS => {
    return dispatch => {
        return (value, callback) => {
            dispatch(MiscActions.getClassEventDogOptions(callback, value, currentEventJS));
        };
    };
};

export const getTrainingEventDogOptions = currentEventWithFormValuesJS => {
    return dispatch => {
        return (value, callback) => {
            dispatch(MiscActions.getTrainingEventDogOptions(callback, value, currentEventWithFormValuesJS));
        };
    };
};

export const getСreateClassLocationOptions = () => {
    return () => {
        return (value, callback) => {
            callback(null, {
                options: Options.locations,
            });
        };
    };
};

export const getTrainingProgramsOptions = () => {
    return dispatch => {
        return (value, callback) => {
            dispatch(MiscActions.getTrainingProgramsOptions(callback, value));
        };
    };
};

export const getTrainingProgramsOptionsWithoutAll = () => {
    return dispatch => {
        return (value, callback) => {
            dispatch(MiscActions.getTrainingProgramsOptions(callback, value, false));
        };
    };
};

export const getCalendarTrainerOptions = () => {
    return dispatch => {
        return (value, callback) => {
            dispatch(MiscActions.getCalendarTrainerOptions(callback, value));
        };
    };
};

export const getCalendarLocationOptions = () => {
    return dispatch => {
        return (value, callback) => {
            dispatch(MiscActions.getCalendarLocationOptions(callback, value));
        };
    };
};

export const getPackageClassesOptions = () => {
    return (dispatch, getState) => {
        const options = getState().getIn(['misc', 'options', 'class']);

        if (!options.length) {
            return dispatch(MiscActions.getPackagesClassOptions());
        }

        return options;
    };
};

export const getAvailableTrainingProgramsOptions = () => {
    return dispatch => {
        return (value, callback) => {
            dispatch(MiscActions.getAvailableTrainingProgramsOptions(callback, value));
        };
    };
};

export const getAvailablePackagesOptions = () => {
    return dispatch => {
        return (value, callback) => {
            dispatch(MiscActions.getAvailablePackagesOptions(callback, value));
        };
    };
};

export const getCustomerPhonesOptions = () => {
    return (dispatch, getState) => {
        const primaryPhoneId = getState().getIn(['user', 'phoneId', 'id']);
        const secondaryPhoneId = getState().getIn(['user', 'secondary_phone']);
        const availablePhones = getState().getIn(['user', 'available_phones']);
        const options = [];
        if (primaryPhoneId) {
            const primaryPhone = availablePhones.find(item => item.id === primaryPhoneId);
            options.push({
                label: primaryPhone.phone_number,
                value: primaryPhone.phone_number,
            });
        }
        if (secondaryPhoneId) {
            const secondaryPhone = availablePhones.find(item => item.id === secondaryPhoneId);
            options.push({
                label: secondaryPhone.phone_number,
                value: secondaryPhone.phone_number,
            });
        }
        return (value, callback) => {
            callback(null, { options });
        };
    };
};

export const getFormRecipientsOptions = formValues => {
    const notifyUsers = formValues ? formValues.toJS()['notify_users'] : undefined; //eslint-disable-line
    return dispatch => {
        return (value, callback) => {
            dispatch(MiscActions.getFormRecipientsByName(callback, value, notifyUsers));

        };
    };
};

export const getDeleteDogOptions = () => {
    return dispatch => {
        return (value, callback) => {
            dispatch(MiscActions.getRemovalReasonOptions(callback, value));
        };
    };
};

export const getMarketingAttendanceByZoneFilterOptions = () => {
    return (dispatch, getState) => {
        return (value, callback) => {
            const options = getState().getIn(['misc', 'options', 'attendance_by_zone_filter']).toJS();
            if (options.length) {
                callback(null, { options });
                return;
            }

            dispatch(MiscActions.getMarketingAttendanceByZoneFilterValues(callback, value));
        };
    };
};

export const getCustomersOptions = () => {
    return (dispatch, getState) => {
        return (value, callback) => {
            const options = getState().getIn(['misc', 'options', 'customers']).toJS();
            if (options.length) {
                callback(null, { options });
                return;
            }

            dispatch(MiscActions.getCustomersOptions(callback, value));
        };
    };
};
export const getDogOptions = (dogs = []) => {
    return (dispatch, getState) => {
        return (value, callback) => {
            const options = dogs;
            if (options.length) {
                callback(null, { options });
                return;
            }

        };
    };
};


