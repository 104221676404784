import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as InstructorEmployeeTypes from '../types/dashboard/instructorEmployeeTypes';

const INITIAL_STATE = fromJS({
  isLoading: false,
  instance: {},
  initialValues: {},
});

const currentCustomerReducer = handleActions(
  {
    [InstructorEmployeeTypes.START_CURRENT_INSTRUCTOR_EMPLOYEE_LOADING]: (state, action) => {
      return state.setIn(['isLoading'], true);
    },
    [InstructorEmployeeTypes.FINISH_CURRENT_INSTRUCTOR_EMPLOYEE_LOADING]: (state, action) => {
      return state.setIn(['isLoading'], false);
    },
    [InstructorEmployeeTypes.SET_CURRENT_INSTRUCTOR_EMPLOYEE_DATA]: (state, action) => {
      const employeeName = action.payload.get('name')
        ? action.payload.get('name')
        : action.payload.get('full_name') ? action.payload.get('full_name') : null;
      const employeePermissions = action.payload.get('permissions')
        ? action.payload.get('permissions')
        : action.payload.get('permission_level') ? action.payload.get('permission_level') : null;
      const employeeQualification = action.payload.get('qualification')
        ? action.payload.get('qualification')
        : action.payload.get('qualified_for') ? action.payload.get('qualified_for') : null;
      const employeePhoto = action.payload.get('photo_processed')
        ? action.payload.get('photo_processed')
        : action.payload.get('photo') ? action.payload.get('photo') : '';

      return state
        .set('initialValues', action.payload)
        .setIn(['instance', 'id'], action.payload.get('id'))
        .setIn(['instance', 'model_type'], action.payload.get('model_type'))
        .setIn(['instance', 'name'], employeeName)
        .setIn(['instance', 'permissions'], employeePermissions)
        .setIn(['instance', 'position'], action.payload.get('position'))
        .setIn(['instance', 'primary_phone'], action.payload.get('primary_phone'))
        .setIn(['instance', 'email'], action.payload.get('email'))
        .setIn(['instance', 'emergency_contact'], action.payload.get('emergency_contact'))
        .setIn(['instance', 'qualification'], employeeQualification)
        .setIn(['instance', 'photo'], employeePhoto);
    },
    [InstructorEmployeeTypes.CLEAR_CURRENT_INSTRUCTOR_EMPLOYEE_DATA]: (state, action) => {
      return state.set('instance', fromJS({})).set('initialValues', fromJS({}));
    },
  },
  INITIAL_STATE,
);

export default currentCustomerReducer;
