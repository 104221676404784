import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const DogRenderer = ({ dogInfo: { id, name, photo }, className, onClick, isSelected }) => {
    const itemClasses = classNames([
        className,
        {
            'dogs-slider__item_active': isSelected
        }
    ]);
    return (
        <div className={itemClasses} onClick={() => onClick(id)}>
            <span className='dogs-slider__photo' style={{ backgroundImage: `url(${photo || 'https://thumb.ibb.co/ivfaQF/icon_default_img_dog.jpg'})` }} />
            <p className='dogs-slider__name'>{name}</p>
        </div>
    );
};

DogRenderer.propTypes = {
    dogInfo: PropTypes.shape({}),
    className: PropTypes.string,
    onClick: PropTypes.func,
    isSelected: PropTypes
};

export default DogRenderer;
