import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

const ACTIVE_DOT_FILL = '#fff';
const ACTIVE_DOT_STROKE = '#00a1c3';

export default class CustomLineChart extends React.PureComponent {
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.shape({})),
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        title: PropTypes.string,
        isAbsolute: PropTypes.bool,
    };

    constructor() {
        super();

        this.area = null;
        this.tooltip = null;
        this.point = null;
    }

    state = {
        isTooltipHidden: true,
    };

    onChartMouseMove = (chart) => {
        const { isTooltipActive, activeTooltipIndex } = chart;
        if (isTooltipActive) {
            const point = this.area.props.points[activeTooltipIndex];

            if (point !== this.point) {
                this.point = point;
                this.updateTooltip();
            }
        }
    };

    onChartMouseLeave = () => {
        this.point = null;
        this.updateTooltip();
    };

    updateTooltip = () => {
        if (this.point) {
            // 37 - magic number to center tooltip by x axis for value with valueLength === 1
            let x = Math.round(this.point.x) - 37;
            if (this.tooltip) {
                x -= this.tooltip.offsetWidth / 2;
            }
            // tooltip is being rendered inside the whole block container (.class-reservations__chart)
            // not in the LineChart itself
            // so all of the magic numbers are calculated from it,
            // 23 - top padding;
            // 17 - title height;
            // 29 - title bottom margin;
            // ^ gives 46 in sum
            // 12 - above the actual point value;
            let y = Math.round(this.point.y) - (this.props.height - 23 + 12);
            if (this.props.title) {
                y += 46;
            }

            this.setState({ isTooltipHidden: false });
            this.tooltip.style.transform = `translate(${x}px, ${y}px)`;
            this.tooltip.innerHTML = this.point.payload.value;
        } else {
            this.setState({ isTooltipHidden: true });
        }
    };

    handleTooltipContent = () => null;

    tickFormatter = (tickItem) => {
        if (tickItem >= 1000) {
            return `${tickItem / 1000} K`;
        }
        return tickItem;
    };

    render() {
        const { data, width, height, title, isAbsolute = false } = this.props;
        const tooltipClassNames = classNames({
            'chart__tooltip': true,
            'chart__tooltip_hidden': this.state.isTooltipHidden,
        });
        const customLineChartClassnames = classNames({
            'custom-line__chart': true,
            'custom-line__chart_absolute': isAbsolute,
        });
        const dataNormalized = data.map((item) => {
            const { date, value } = item;
            return {
                date: moment.utc(date).format('MM/DD/YY'),
                value,
            };
        });
        return (
            <div className={customLineChartClassnames}>
                {title && <div className='chart__title'>{title}</div>}
                <LineChart
                    className='chart__content'
                    width={width - 13}
                    height={height - 82}
                    data={dataNormalized}
                    onMouseMove={this.onChartMouseMove}
                    onMouseLeave={this.onChartMouseLeave}>
                    <XAxis axisLine={false} tickLine={false} dataKey='date'/>
                    <YAxis
                        axisLine={false}
                        tickLine={false}
                        tickCount={6}
                        tickFormatter={this.tickFormatter}/>
                    <CartesianGrid/>
                    <Line
                        ref={elem => { this.area = elem; }}
                        type='linear'
                        dataKey='value'
                        stroke={ACTIVE_DOT_STROKE}
                        strokeWidth={3}
                        dot={false}
                        activeDot={{ fill: ACTIVE_DOT_FILL, stroke: ACTIVE_DOT_STROKE, r: 3 }}/>
                    <Tooltip cursor={false} content={this.handleTooltipContent}/>
                </LineChart>
                <div className={tooltipClassNames} ref={elem => { this.tooltip = elem; }}/>
            </div>
        );
    }
}
