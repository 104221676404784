import classNames from 'classnames';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form/immutable';
import {
    getCustomReportData,
    setFilterReportAndTarget,
    setFilterType,
} from '../../../../../../actions/dashboard/customReportsActions';
import { onModalGetOptions } from '../../../../../../actions/modal/modalActions';
import { isManager, isTrainer, isEmployee, isSuperadmin } from '../../../../../../helpers/userRoles';
import renderCheckboxListControl from '../../../../../common/Form/CheckboxListControl';
import renderDateControl from '../../../../../common/Form/DateControl';
import renderDateIntervalControl from '../../../../../common/Form/DateIntervalControl';
import renderSelectControl from '../../../../../common/Form/SelectControl';
import {
    adminReportList,
    filterFields,
    employeeCategoriesList,
    trainerReportCategoriesList,
    adminCategoriesList,
    superAdminReportList,
    trainerReportList,
} from './constants';
import validate from './validate';

const getReportTypesList = (type) => {
    if (isManager()) {
        return adminReportList[type] || [];
    }
    if (isTrainer()) {
        return trainerReportList[type] || [];
    }
    return superAdminReportList[type] || [];
};

const renderFilterParams = (fieldsList, optionRenderer, filterValue) => {
    if (!fieldsList) return null;
    return (
        <div>
            {fieldsList.includes('date_range') &&
            <div className='reports-filter__row reports-filter__row_form'>
                <p className='reports-filter__head'>Enter date range</p>
                <Field
                    name='date_range'
                    startProps={{
                        label: 'From',
                        name: 'start_date',
                    }}
                    endProps={{
                        label: 'To',
                        name: 'end_date',
                    }}
                    type='text'
                    disableFuture={!fieldsList.includes('enable_future')}
                    component={renderDateIntervalControl}/>
            </div>
            }
            {fieldsList.includes('date') &&
            <div className='reports-filter__row reports-filter__row_form'>
                <p className='reports-filter__head'>Enter date</p>
                <Field
                    name='date'
                    type='text'
                    label='Date'
                    meta={{ initial: '2018-10-22' }}
                    component={renderDateControl}/>
            </div>
            }
            {!isTrainer() && fieldsList.includes('trainer') &&
            <div className='reports-filter__row reports-filter__row_form'>
                <p className='reports-filter__head'>Choose trainer</p>
                <Field
                    type='text'
                    autocomplete
                    autoload
                    name='trainer'
                    label='Instructor'
                    getOptions={debounce(optionRenderer({ options: 'getTrainerOptions' }), 750)}
                    noResultsText={<span className='no-results-message'>Please choose an existing instructor</span>}
                    component={renderSelectControl}/>
            </div>
            }
            {fieldsList.includes('attendance_by_zone_filter') &&
            <div className='reports-filter__row reports-filter__row_form'>
                <p className='reports-filter__head'>Choose report type</p>
                <Field
                    type='text'
                    autocomplete
                    autoload
                    name='attendance_by_zone_filter'
                    label='Report'
                    getOptions={debounce(optionRenderer({ options: 'getMarketingAttendanceByZoneFilterOptions' }), 750)}
                    component={renderSelectControl}/>
                {/* By Location */}
                {filterValue && filterValue.value === 1 &&
                <Field
                    type='text'
                    autocomplete
                    autoload
                    name='attendance_by_zone_filter_extra'
                    label='Neighborhood'
                    getOptions={debounce(optionRenderer({ options: 'getLocationOptions' }), 750)}
                    component={renderSelectControl}/>}
                {/* By Trainer */}
                {filterValue && filterValue.value === 2 &&
                <Field
                    type='text'
                    autocomplete
                    autoload
                    name='attendance_by_zone_filter_extra'
                    label='For'
                    getOptions={debounce(optionRenderer({ options: 'getTrainerOptions' }), 750)}
                    component={renderSelectControl}/>}
            </div>
            }
        </div>
    );
};

const ReportsFilter = ({
    handleSubmit,
    currentType,
    currentReport,
    attendanceByZoneFilterValue,
    invalid,
    submitting,
    dispatch,
    onModalGetOptions,
    getCustomReportData,
}) => {
    const submitClassNames = classNames({
        'button modal-dialog__button modal-dialog__button_report': true,
        'button_disabled': (invalid || submitting),
    });

    let reportsCategories = [];
    if (isTrainer()) {
        reportsCategories = trainerReportCategoriesList;
    } else if (isEmployee()) {
        reportsCategories = employeeCategoriesList;
    } else if (isManager() || isSuperadmin()) {
        reportsCategories = adminCategoriesList;
    }

    return (
        <form
            onSubmit={handleSubmit(getCustomReportData)}
            className='reports-filter'>
            <div className='reports-filter__header'>Custom reports</div>
            <div className='reports-filter__content'>
                <div className='reports-filter__row'>
                    <Field
                        name='type'
                        type='checkbox'
                        isRadio
                        isScrollable
                        onChangeHandler={() => {
                            dispatch(change('customReportFilterForm', 'report', null)); // clear report field
                            dispatch(change('customReportFilterForm', 'date', 'None'));
                            dispatch(change('customReportFilterForm', 'date_range', {
                                start: 'None',
                                end: 'None',
                            }));
                        }}
                        options={reportsCategories}
                        component={renderCheckboxListControl}/>
                </div>
                <div className='reports-filter__row reports-filter__row_reports'>
                    <Field
                        name='report'
                        type='checkbox'
                        isRadio
                        isScrollable
                        description='Select report'
                        onChangeHandler={() => {
                            dispatch(change('customReportFilterForm', 'date', 'None'));
                            dispatch(change('customReportFilterForm', 'date_range', {
                                start: 'None',
                                end: 'None',
                            }));
                        }}
                        options={getReportTypesList(currentType)}
                        component={renderCheckboxListControl}/>
                </div>

                {currentReport === 'attendance-by-zone'
                    ? renderFilterParams(filterFields[currentReport], onModalGetOptions, attendanceByZoneFilterValue)
                    : renderFilterParams(filterFields[currentReport], onModalGetOptions)}

                <div className='reports-filter__bottom'>
                    <button
                        type='submit'
                        disabled={submitting || invalid}
                        className={submitClassNames}>Generate report
                    </button>
                </div>
            </div>
        </form>
    );
};

ReportsFilter.propTypes = {
    handleSubmit: PropTypes.func,
    currentType: PropTypes.string,
    currentReport: PropTypes.string,
    attendanceByZoneFilterValue: PropTypes.shape({}),
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func,
    onModalGetOptions: PropTypes.func,
    getCustomReportData: PropTypes.func,
};

const form = reduxForm({
    form: 'customReportFilterForm',
    enableReinitialize: true,
    validate,
});

const mapStateToProps = state => ({
    currentType: state.getIn(['form', 'customReportFilterForm', 'values', 'type']),
    currentReport: state.getIn(['form', 'customReportFilterForm', 'values', 'report']),
    attendanceByZoneFilterValue: state.getIn(['form', 'customReportFilterForm', 'values', 'attendance_by_zone_filter']),
    initialValues: {
        type: isTrainer() ? 'financial' : 'operational',
        report: isTrainer() ? 'commission' : undefined,
        date_range: {
            start: 'None',
            end: 'None',
        },
        date: 'None',
    },
});

const mapDispatchToProps = {
    setFilterType,
    setFilterReportAndTarget,
    getCustomReportData,
    onModalGetOptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(form(ReportsFilter));
