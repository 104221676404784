import { geocodeByAddress } from 'react-places-autocomplete';
import { change } from 'redux-form';
import { usStates } from '../helpers/options';
import { baseAddressFields } from '../helpers/autocomplete';

export const handleAutocompleteSelectOrEnter = (address, formName, fields = baseAddressFields) => {
    return dispatch => {
        geocodeByAddress(address)
            .then(results => {
                let streetNumber;
                let route;
                let city;
                let state;
                let zipCode;

                results[0].address_components.forEach(component => {
                    const { types } = component;
                    if (types[0] === 'street_number') {
                        streetNumber = component;
                    }
                    if (types[0] === 'route') {
                        route = component;
                    }
                    if (types[0] === 'locality') {
                        city = component;
                    }
                    if (component.types[0] === 'administrative_area_level_1') {
                        state = component;
                    }
                    if (component.types[0] === 'postal_code') {
                        zipCode = component;
                    }
                });

                if (streetNumber !== undefined && route !== undefined) {
                    dispatch(change(formName, fields.address1, `${streetNumber.long_name} ${route.long_name}`));
                }

                if (streetNumber === undefined && route !== undefined) {
                    dispatch(change(formName, fields.address1, route.long_name));
                }

                if (city !== undefined) {
                    dispatch(change(formName, fields.city, city.long_name));
                }

                if (state !== undefined) {
                    dispatch(change(formName, fields.state, usStates.find(usState => {
                        return usState.value === state.short_name;
                    })));
                }

                if (zipCode !== undefined) {
                    dispatch(change(formName, fields.zip_code, zipCode.long_name));
                }
            });
    };
};
