import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { PHONE_MASK, PHONE_MASK_CHAR, phoneNumberNormalizer } from '../../../../../helpers/normalize';
import AvatarUploader from '../../../../common/AvatarUploader';
import renderInputControl from '../../../../common/Form/InputControl';
import validate from './validate';

const ProfileForm = ({ handleSubmit, submitting, invalid, error, photo }) => {
    const SubmitButtonClassNames = classNames({
        'profile-edit-form__submit': true,
        'profile-edit-form__submit--disabled': invalid || submitting,
    });
    return (
        <form onSubmit={handleSubmit} className='profile-edit-form'>
            <div className='profile-edit-form__avatar'>
                <AvatarUploader photo={photo} target='me' subtitle controlled/>
            </div>
            <div className='profile-edit-form__content'>
                <Field
                    name='full_name'
                    type='text'
                    component={renderInputControl}
                    formError={error}
                    label='Full name'/>
                <Field
                    name='email'
                    type='text'
                    component={renderInputControl}
                    formError={error}
                    label='Email address'/>
                <Field
                    name='phone'
                    type='text'
                    component={renderInputControl}
                    mask={PHONE_MASK}
                    maskChar={PHONE_MASK_CHAR}
                    isMasked
                    formError={error}
                    normalize={phoneNumberNormalizer}
                    label='Phone number'/>
                <Field
                    name='emergency_contact'
                    type='text'
                    component={renderInputControl}
                    formError={error}
                    label='Emergency Contact'/>
                <Field
                    name='emergency_phone'
                    type='text'
                    component={renderInputControl}
                    mask={PHONE_MASK}
                    maskChar={PHONE_MASK_CHAR}
                    isMasked
                    formError={error}
                    normalize={phoneNumberNormalizer}
                    label='Emergency phone'/>
                {error && <div className='form__error'>{error}</div>}
            </div>
            <button
                className={SubmitButtonClassNames}
                type='submit'
                disabled={submitting}>
                Save changes
            </button>
        </form>
    );
};

ProfileForm.propTypes = {
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    handleSubmit: PropTypes.func,
    error: PropTypes.string,
    photo: PropTypes.string,
};
const form = reduxForm({
    form: 'profileEditForm',
    enableReinitialize: true,
    validate,
});

const mapStateToProps = (state) => {
    // eslint-disable-next-line camelcase
    const emergency_contact = state.getIn(['user', 'emergency_contact']) === null
        ? ''
        : state.getIn(['user', 'emergency_contact']).name;
    // eslint-disable-next-line camelcase
    const emergency_phone = state.getIn(['user', 'emergency_contact']) === null
        ? ''
        : state.getIn(['user', 'emergency_contact']).phone_number;
    return {
        initialValues: {
            full_name: state.getIn(['user', 'full_name']),
            email: state.getIn(['user', 'email']),
            phone: state.getIn(['user', 'phoneId', 'phone_number']),
            emergency_contact,
            emergency_phone,
        },
        photo: state.getIn(['user', 'photo']) || null,
    };
};
export default connect(mapStateToProps, null)(form(ProfileForm));
