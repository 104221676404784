import { updateInfo } from '../../services/update';
import { setCurrentCustomerData } from './employeeActions';
import { fromJS } from '../../../node_modules/immutable/dist/immutable';
import { setChangeNameModalClosed } from '../miscActions';
import { getUserNameSpace } from '../../helpers/userRoles';

export const updateCustomerName = (customerId, data) => updateInfo(
    {
        url: `/v1/${getUserNameSpace('customers')}/customers/${customerId}/`,
        infoChunks: Object.keys(data.toJS()).map((chunk) => {
            return {
                name: chunk,
                content: data.toJS()[chunk]
            };
        }),
        actionCB: (dispatch, response) => {
            dispatch(setCurrentCustomerData(fromJS(response.data)));
            dispatch(setChangeNameModalClosed());
        }
    }
);
