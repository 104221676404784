import moment from 'moment';
import { createAction } from 'redux-actions';
import * as OverviewService from '../../services/overview';
import * as OverviewTypes from '../../types/dashboard/overviewTypes';
import { setGlobalError } from '../errorHandlerActions';
import { getStartEndDates, setReportsEndDate, setReportsStartDate } from './reportsActions';

export const setOverviewNewObjectsLoading = createAction(OverviewTypes.SET_OVERVIEW_NEW_OBJECTS_LOADING);
export const setOverviewNewObjectsLoaded = createAction(OverviewTypes.SET_OVERVIEW_NEW_OBJECTS_LOADED);
export const setOverviewNewObjectsFailed = createAction(OverviewTypes.SET_OVERVIEW_NEW_OBJECTS_FAILED);
export const setOverviewNewObjectsData = createAction(OverviewTypes.SET_OVERVIEW_NEW_OBJECTS_DATA);

export const setOverviewClassReservationsData = createAction(OverviewTypes.SET_OVERVIEW_CLASS_RESERVATIONS_DATA);
export const setOverviewClassReservationsLoading = createAction(OverviewTypes.SET_OVERVIEW_CLASS_RESERVATIONS_LOADING);
export const setOverviewClassReservationsLoaded = createAction(OverviewTypes.SET_OVERVIEW_CLASS_RESERVATIONS_LOADED);
export const setOverviewClassReservationsFailed = createAction(OverviewTypes.SET_OVERVIEW_CLASS_RESERVATIONS_FAILED);

export const setOverviewReportCardsData = createAction(OverviewTypes.SET_OVERVIEW_REPORT_CARDS_DATA);
export const setOverviewReportCardsLoading = createAction(OverviewTypes.SET_OVERVIEW_REPORT_CARDS_LOADING);
export const setOverviewReportCardsLoaded = createAction(OverviewTypes.SET_OVERVIEW_REPORT_CARDS_LOADED);
export const setOverviewReportCardsFailed = createAction(OverviewTypes.SET_OVERVIEW_REPORT_CARDS_FAILED);

export const setOverviewClassReservationsByZoneData = createAction(
    OverviewTypes.SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_DATA);
export const setOverviewClassReservationsByZoneLoading = createAction(
    OverviewTypes.SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_LOADING);
export const setOverviewClassReservationsByZoneLoaded = createAction(
    OverviewTypes.SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_LOADED);
export const setOverviewClassReservationsByZoneFailed = createAction(
    OverviewTypes.SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_FAILED);
export const setOverviewClassReservationsByZoneColorAndName = createAction(
    OverviewTypes.SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_COLOR_AND_NAME);

export const setOverviewBookingsByTrainerData = createAction(OverviewTypes.SET_OVERVIEW_BOOKINGS_BY_TRAINER_DATA);
export const setOverviewBookingsByTrainerLoading = createAction(OverviewTypes.SET_OVERVIEW_BOOKINGS_BY_TRAINER_LOADING);
export const setOverviewBookingsByTrainerLoaded = createAction(OverviewTypes.SET_OVERVIEW_BOOKINGS_BY_TRAINER_LOADED);
export const setOverviewBookingsByTrainerFailed = createAction(OverviewTypes.SET_OVERVIEW_BOOKINGS_BY_TRAINER_FAILED);
export const setOverviewBookingsByTrainerColor = createAction(OverviewTypes.SET_OVERVIEW_BOOKINGS_BY_TRAINER_COLOR);

export const setOverviewTotalRevenueData = createAction(OverviewTypes.SET_OVERVIEW_TOTAL_REVENUE_DATA);
export const setOverviewTotalRevenueLoading = createAction(OverviewTypes.SET_OVERVIEW_TOTAL_REVENUE_LOADING);
export const setOverviewTotalRevenueLoaded = createAction(OverviewTypes.SET_OVERVIEW_TOTAL_REVENUE_LOADED);
export const setOverviewTotalRevenueFailed = createAction(OverviewTypes.SET_OVERVIEW_TOTAL_REVENUE_FAILED);

// export const getAllOverviewData = (params) => {
//     return dispatch => {
//         dispatch(setOverviewNewObjectsLoading());
//         dispatch(setOverviewClassReservationsLoading());
//         return Promise.all([
//             dispatch(OverviewService.getOverviewNewObjectsData(params)),
//             dispatch(OverviewService.getOverviewClassReservationsData(params))
//         ]).then(([overviewNewObjectsResponse, overviewClassReservationsResponse]) => {
//             dispatch(setOverviewNewObjectsData(overviewNewObjectsResponse));
//             dispatch(setOverviewClassReservationsData(overviewClassReservationsResponse));
//             dispatch(setOverviewNewObjectsLoaded());
//             dispatch(setOverviewClassReservationsLoaded());
//         }).catch(err => {
//             dispatch(setOverviewNewObjectsFailed());
//             dispatch(setOverviewClassReservationsFailed());
//             dispatch(setGlobalError(err));
//         });
//     };
// };

export const getNewObjectsData = ({ start, end }) => {
    return dispatch => {
        dispatch(setOverviewNewObjectsLoading());
        dispatch(OverviewService.getOverviewNewObjectsData({ start, end }))
            .then(response => {
                dispatch(setOverviewNewObjectsLoaded());
                dispatch(setOverviewNewObjectsData(response.data));
            })
            .catch(err => {
                dispatch(setOverviewNewObjectsFailed());
                dispatch(setGlobalError(err));
            });
    };
};

export const getClassReservationsData = ({ start, end }) => {
    return dispatch => {
        dispatch(setOverviewClassReservationsLoading());
        dispatch(OverviewService.getOverviewClassReservationsData({ start, end }))
            .then(response => {
                dispatch(setOverviewClassReservationsLoaded());
                dispatch(setOverviewClassReservationsData(response.data));
            })
            .catch(err => {
                dispatch(setOverviewClassReservationsFailed());
                dispatch(setGlobalError(err));
            });
    };
};

export const getReportCardsData = ({ start, end }) => {
    return dispatch => {
        dispatch(setOverviewReportCardsLoading());
        dispatch(OverviewService.getOverviewReportCardsData({ start, end }))
            .then(response => {
                dispatch(setOverviewReportCardsLoaded());
                dispatch(setOverviewReportCardsData(response.data));
            })
            .catch(err => {
                dispatch(setOverviewReportCardsFailed());
                dispatch(setGlobalError(err));
            });
    };
};

export const getZonesColorsList = () => {
    return (dispatch, getState) => {
        const zonesList = getState().getIn(['overview', 'classReservationsByZone', 'items']).toJS();
        const colors = getState().getIn(['calendar', 'calendarEventsColors']).toJS();

        let colorsCounter = 0;

        zonesList.forEach((zone) => {
            const zonesColorsList = {};
            zonesColorsList.zone = zone.zone;
            zonesColorsList.color = colors[colorsCounter];
            // eslint-disable-next-line no-plusplus
            colorsCounter++;
            if (colorsCounter >= colors.length - 1) {
                colorsCounter = 0;
            }

            dispatch(setOverviewClassReservationsByZoneColorAndName(zonesColorsList));
        });
    };
};

export const getClassReservationsByZoneData = ({ start, end }) => {
    return dispatch => {
        dispatch(setOverviewClassReservationsByZoneLoading());
        dispatch(OverviewService.getOverviewClassReservationsByZoneData({ start, end }))
            .then(response => {
                dispatch(setOverviewClassReservationsByZoneLoaded());
                dispatch(setOverviewClassReservationsByZoneData(response.data));
                dispatch(getZonesColorsList());
            })
            .catch(err => {
                dispatch(setOverviewClassReservationsByZoneFailed());
                dispatch(setGlobalError(err));
            });
    };
};

export const getBookingsByTrainerColorsList = () => {
    return (dispatch, getState) => {
        const trainersList = getState().getIn(['overview', 'bookingsByTrainer', 'items']).toJS();
        const colors = getState().getIn(['calendar', 'calendarEventsColors']).toJS();

        let colorsCounter = 0;

        trainersList.forEach((trainer) => {
            const trainersColorsList = {};
            trainersColorsList.trainer = trainer.trainer;
            trainersColorsList.color = colors[colorsCounter];
            // eslint-disable-next-line no-plusplus
            colorsCounter++;
            if (colorsCounter >= colors.length - 1) {
                colorsCounter = 0;
            }

            dispatch(setOverviewBookingsByTrainerColor(trainersColorsList));
        });
    };
};

export const getBookingsByTrainerData = ({ start, end }) => {
    return dispatch => {
        dispatch(setOverviewBookingsByTrainerLoading());
        dispatch(OverviewService.getOverviewBookingsByTrainerData({ start, end }))
            .then(response => {
                dispatch(setOverviewBookingsByTrainerLoaded());
                dispatch(setOverviewBookingsByTrainerData(response.data));
                dispatch(getBookingsByTrainerColorsList());
            })
            .catch(err => {
                dispatch(setOverviewBookingsByTrainerFailed());
                dispatch(setGlobalError(err));
            });
    };
};

export const getTotalRevenueData = ({ start, end }) => {
    return dispatch => {
        dispatch(setOverviewTotalRevenueLoading());
        dispatch(OverviewService.getOverviewTotalRevenueData({ start, end }))
            .then(response => {
                dispatch(setOverviewTotalRevenueLoaded());
                dispatch(setOverviewTotalRevenueData(response.data));
            })
            .catch(err => {
                dispatch(setOverviewTotalRevenueFailed());
                dispatch(setGlobalError(err));
            });
    };
};

export const getAllOverviewData = (dateParams) => {
    const params = getStartEndDates(dateParams);
    return dispatch => {
        const { start, end } = dateParams;
        dispatch(getNewObjectsData(params));
        dispatch(getClassReservationsData(params));
        dispatch(getReportCardsData(params));
        // dispatch(getClassReservationsByZoneData(params));
        dispatch(getBookingsByTrainerData(params));
        dispatch(getTotalRevenueData(params));
        dispatch(setReportsStartDate(moment.utc(start).format('MM/DD/YY')));
        dispatch(setReportsEndDate(moment.utc(end).format('MM/DD/YY')));
    };
};
