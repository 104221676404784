export const SET_CLASSES = 'SET_CLASSES';
export const SET_CLASSES_OPTIONS = 'SET_CLASSES_OPTIONS';
export const SET_NEXT_CLASSES = 'SET_NEXT_CLASSES';
export const UPDATE_CLASSES_DESCRIPTION_STATE = 'UPDATE_CLASSES_DESCRIPTION_STATE';
export const UPDATE_CLASS_FEATURED_OPTION = 'UPDATE_CLASS_FEATURED_OPTION';
export const START_CLASSES_LOADING = 'START_CLASSES_LOADING';
export const FINISH_CLASSES_LOADING = 'FINISH_CLASSES_LOADING';
export const SET_CLASSES_FILTER_OPTIONS = 'SET_CLASSES_FILTER_OPTIONS';
export const UPDATE_CLASSES_FILTER_TAGS = 'UPDATE_CLASSES_FILTER_TAGS';
export const UPDATE_CLASSES_FILTER_OBJECT = 'UPDATE_CLASSES_FILTER_OBJECT';

// export const ADD_NEW_CLASS_TO_CLASSES = 'ADD_NEW_CLASS_TO_CLASSES';
export const UPDATE_CLASS_TO_CLASSES = 'UPDATE_CLASS_TO_CLASSES';
