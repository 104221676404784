/* eslint-disable max-len */
import { createAction } from 'redux-actions';
import * as vaccinationRecordsTypes from '../../types/dashboard/vaccinationRecordsTypes';
import { getImmunizationDocs, getVaccinationRecords } from '../../services/vaccinations';
import { getUserNameSpace } from '../../helpers/userRoles';
import { DOCUMENT_UPLOAD } from '../../components/Alert/events';

export const markCustomerVaccinationsLoaded = createAction(vaccinationRecordsTypes.MARK_CUSTOMER_VACCINATION_RECORDS_LOADED);
export const unmarkCustomerVaccinationsLoaded = createAction(vaccinationRecordsTypes.UNMARK_CUSTOMER_VACCINATION_RECORDS_LOADED);
export const setCurrentCustomerVaccinations = createAction(vaccinationRecordsTypes.SET_CURRENT_CUSTOMER_VACCINATION_RECORDS);
export const getCustomersVaccinations = (customerId) => getImmunizationDocs({
    role: getUserNameSpace('customers.immunizations-docs'),
    context: 'customers',
    id: customerId
});
export const removeCurrentCustomerVaccination = createAction(vaccinationRecordsTypes.REMOVE_CURRENT_CUSTOMER_VACCINATION);
export const prependCurrentCustomerVaccination = createAction(
    vaccinationRecordsTypes.PREPEND_CURRENT_CUSTOMER_VACCINATION,
    null,
    payload => {
        const fileName = payload.data.document_file_name;
        const id = `DOCUMENT_UPLOAD-${new Date().getTime().toString()}`;
        return { alert: { id, body: DOCUMENT_UPLOAD({ alertData: { fileName } }) } };
    }
);

export const markDogVaccinationsLoaded = createAction(vaccinationRecordsTypes.MARK_DOG_VACCINATION_RECORDS_LOADED);
export const unmarkDogVaccinationsLoaded = createAction(vaccinationRecordsTypes.UNMARK_DOG_VACCINATION_RECORDS_LOADED);
export const setCurrentDogVaccinationRecords = createAction(vaccinationRecordsTypes.SET_CURRENT_DOG_VACCINATION_RECORDS);
export const startDogVaccinationRecordsLoading = createAction(vaccinationRecordsTypes.START_DOG_VACCINATION_RECORDS_LOADING);
export const finishDogVaccinationRecordsLoading = createAction(vaccinationRecordsTypes.FINISH_DOG_VACCINATION_RECORDS_LOADING);
export const setCurrentDogVaccinationRecordsChoices = createAction(vaccinationRecordsTypes.SET_CURRENT_DOG_VACCINATION_RECORDS_CHOICES);
export const getDogsVaccinations = (dogId) => getVaccinationRecords({
    role: 'employees',
    context: 'dogs',
    id: dogId
});

export const updateCurrentDogVaccinationRecords = createAction(vaccinationRecordsTypes.UPDATE_CURRENT_DOG_VACCINATION_RECORDS);

