import React from 'react';
import PropTypes from 'prop-types';

const isMobile = window.innerWidth <= 736;

class Summary extends React.PureComponent {

    componentDidMount() {
        if (isMobile) this.toggleSection();
    }

    contentHeight = null;

    toggleSection = () => {
        if (this.summaryContent) {
            let currHeight = this.contentHeight;
            const isContentShowed = !(this.summaryContent.offsetHeight === 0);

            // get initial height of container
            if (currHeight === null ||
                (currHeight !== this.summaryContent.offsetHeight && this.summaryContent.offsetHeight)) {
                let height = 0;
                if (isContentShowed) {
                    this.summaryContent.style.height = 'auto';
                    height = this.summaryContent.offsetHeight;
                    this.summaryContent.style.height = height + 'px';
                }
                currHeight = height;
            }

            this.summaryContent.style.height = currHeight + 'px';
            this.contentHeight = currHeight;

            setTimeout(() => {
                if (!isContentShowed) {
                    // clear fixed height after animation complete
                    setTimeout(() => {
                        this.summaryContent.removeAttribute('style');
                        this.summaryBtn.innerHTML = 'View less';
                    }, 350);
                } else {
                    setTimeout(() => {
                        this.summaryContent.style.height = 0;
                        this.summaryBtn.innerHTML = 'View more';
                    }, 0);
                }
            }, 100);
        }
    }

    render() {
        const { title, items, itemRenderer, discountRenderer, onRemoveHandler } = this.props;
        const SummaryItem = itemRenderer || null;
        const DiscountItem = discountRenderer || null;
        let totalPrice = 0;
        items.map(item => { // eslint-disable-line
            if (item && 'price' in item) {
                totalPrice += +item.price;
            }
        });
        return (
            <div className='summary'>
                <div className='summary__container' ref={elem => { this.summaryContent = elem; }}>
                    <div className='summary__wrapper'>
                        <h5 className='summary__title'>{title}</h5>
                        {!!items && !!items.length && <div className='summary__items'>
                            {items.map((data, index) =>
                                data.price >= 0
                                    ? <SummaryItem data={data} key={index} onRemove={onRemoveHandler} />
                                    : <DiscountItem data={data} key={index} />)}
                        </div>}
                    </div>
                </div>

                <div className='summary__total'>
                    <p className='summary__total-text'>Total Amount: ${totalPrice}</p>
                    <span className='summary__total-button' onClick={this.toggleSection} ref={elem => { this.summaryBtn = elem; }}>View more</span>
                </div>
            </div>
        );
    }
}

Summary.propTypes = {
    title: PropTypes.string,
    itemRenderer: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    discountRenderer: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    items: PropTypes.arrayOf(PropTypes.shape({})),
    onRemoveHandler: PropTypes.func
};

export default Summary;
