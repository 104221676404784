import { getCustomerById } from '../../services/customers';
import { addNewInfo, updateAvailableInfo, updateInfo } from '../../services/update';
import { setContactInfoModalLoaded, setContactInfoModalLoading, setContactInfoModalClosed } from '../miscActions';
import { getUserNameSpace } from '../../helpers/userRoles';

export const updateCustomerContactInfoData = (customerId, emailData, primaryPhoneData, secondaryPhoneData,
                                              addressData) => {
    return async dispatch => {
        // if email update also goes async, server response with 500 ^^
        // case 1: change data 1 to 1, without id
        if (emailData.get('initialData') !== emailData.get('email')) {
            await dispatch(updateInfo({
                url: `/v1/${getUserNameSpace('customers')}/customers/${customerId}/`,
                infoChunks: Object.keys(emailData.toJS()).map((chunk) => {
                    return {
                        name: chunk,
                        content: emailData.toJS()[chunk]
                    };
                })
            }));
        }

        // case 2: change data 1 to many, with id
        let updatePrimaryPhone;
        if (primaryPhoneData.get('id') === null) {
            const primaryPhoneNumber = primaryPhoneData.getIn(['data', '0', 'phone_number']);
            if (primaryPhoneNumber !== null && primaryPhoneNumber.length) {
                dispatch(addNewInfo({
                    type: 'phone_number',
                    url: `/v1/${getUserNameSpace('customersavailable-phones')}/customers/${customerId}/available-phones/`,
                    infoChunks: primaryPhoneData,
                    actionCB: (dispatch, response) => {
                        dispatch(updateInfo({
                            url: `/v1/${getUserNameSpace('customers')}/customers/${customerId}/`,
                            infoChunks: [
                                {
                                    name: 'primary_phone',
                                    content: response.data.id
                                }
                            ]
                        }));
                    }
                }));
            }
        } else {
            // eslint-disable-next-line no-lonely-if
            if (primaryPhoneData.get('initialData') !== primaryPhoneData.getIn(['data', '0', 'phone_number'])) {
                updatePrimaryPhone = dispatch(updateAvailableInfo({
                    url: `/v1/${getUserNameSpace('customersavailable-phones')}/customers/${customerId}/available-phones/${primaryPhoneData.get('id')}/`,
                    infoChunks: primaryPhoneData.get('data').toJS()
                }));
            }
        }

        // case 2: change data 1 to many, with id
        let updateSecondaryPhone;
        const newSecondaryPhoneNumber = secondaryPhoneData.getIn(['data', '0', 'phone_number']);
        if (secondaryPhoneData.get('id') === null) {
            if (newSecondaryPhoneNumber !== null && newSecondaryPhoneNumber.length) {
                dispatch(addNewInfo({
                    type: 'phone_number',
                    url: `/v1/${getUserNameSpace('customersavailable-phones')}/customers/${customerId}/available-phones/`,
                    infoChunks: secondaryPhoneData,
                    actionCB: (dispatch, response) => {
                        dispatch(updateInfo({
                            url: `/v1/${getUserNameSpace('customers')}/customers/${customerId}/`,
                            infoChunks: [
                                {
                                    name: 'secondary_phone',
                                    content: response.data.id
                                }
                            ]
                        }));
                    }
                }));
            }
        } else {
            // eslint-disable-next-line no-lonely-if
            if (!newSecondaryPhoneNumber.length || newSecondaryPhoneNumber === null) {
                dispatch(updateInfo({
                    url: `/v1/${getUserNameSpace('customers')}/customers/${customerId}/`,
                    infoChunks: [
                        {
                            name: 'secondary_phone',
                            content: null
                        }
                    ]
                }));
            } else if (newSecondaryPhoneNumber.length
                && secondaryPhoneData.get('initialData') !== secondaryPhoneData.getIn(['data', '0', 'phone_number'])) {
                updateSecondaryPhone = dispatch(updateAvailableInfo({
                    url: `/v1/${getUserNameSpace('customersavailable-phones')}/customers/${customerId}/available-phones/${secondaryPhoneData.get('id')}/`,
                    infoChunks: secondaryPhoneData.get('data').toJS()
                }));
            }
        }

        // case 3: change data many to many, with id
        let updateAddress;
        if (addressData.get('id') === null) {
            console.log('creating new address');
            updateAddress = dispatch(addNewInfo({
                type: 'address',
                url: `/v1/${getUserNameSpace('customers.available-addresses')}/customers/${customerId}/available-addresses/`,
                infoChunks: addressData.get('data'),
                actionCB: (dispatch, response) => {
                    dispatch(updateInfo({
                        url: `/v1/${getUserNameSpace('customers')}/customers/${customerId}/`,
                        infoChunks: [
                            {
                                name: 'address',
                                content: response.data.id
                            }
                        ]
                    }));
                }
            }));
        } else {
            console.log('UPDATIONG');
            console.log(addressData.getIn(['data', '2', 'address2']) !== addressData.getIn(
                ['initialData', '1', 'address2']));
            console.log(addressData.getIn(['data', '3', 'city']) !== addressData.getIn(['initialData', '2', 'city']));
            console.log(addressData.getIn(['data', '4', 'state']) !== addressData.getIn(['initialData', '3', 'state']));
            console.log(addressData.getIn(['data', '5', 'zip_code']) !== addressData.getIn(
                ['initialData', '4', 'zip_code']));
            console.log(addressData.getIn(['data', '6', 'location_notes']) !== addressData.getIn(['initialData', '5', 'location_notes']))
            // eslint-disable-next-line no-lonely-if
            if (addressData.getIn(['data', '1', 'address1']) !== addressData.getIn(['initialData', '0', 'address1']) ||
                addressData.getIn(['data', '2', 'address2']) !== addressData.getIn(['initialData', '1', 'address2']) ||
                addressData.getIn(['data', '3', 'city']) !== addressData.getIn(['initialData', '2', 'city']) ||
                addressData.getIn(['data', '4', 'state']) !== addressData.getIn(['initialData', '3', 'state']) ||
                addressData.getIn(['data', '5', 'zip_code']) !== addressData.getIn(['initialData', '4', 'zip_code']) ||
                addressData.getIn(['data', '6', 'location_notes']) !== addressData.getIn(['initialData', '5', 'location_notes'])) {
                updateAddress = dispatch(updateAvailableInfo({
                    url: `/v1/${getUserNameSpace('customers.available-addresses')}/customers/${customerId}/available-addresses/${addressData.get('id')}/`,
                    infoChunks: addressData.get('data').toJS()
                }));
            }
        }

        // await updateEmail;
        await updatePrimaryPhone;
        await updateSecondaryPhone;
        await updateAddress;
    };
};

// eslint-disable-next-line max-len
export const processCustomerContactInfo = (customerId, emailData, primaryPhoneData, secondaryPhoneData,
                                           addressData) => {
    return dispatch => {
        dispatch(setContactInfoModalLoading());
        dispatch(
            updateCustomerContactInfoData(customerId, emailData, primaryPhoneData, secondaryPhoneData, addressData));
        setTimeout(() => {
            dispatch(getCustomerById(customerId));
            dispatch(setContactInfoModalLoaded());
            dispatch(setContactInfoModalClosed());
        }, 2000);
    };
};
