import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import VaccinationsTable from './VaccinationsTable';
import FileControlUploadButton from '../../../../common/Form/FileControl/FileControlUploadButton';
import AddNewItem from '../../../../../components/common/AddNewItem';
import * as ModalOpenActions from '../../../../../actions/modal/openActions';
import { prependCurrentCustomerVaccination } from '../../../../../actions/dashboard/vaccinationRecordsActions';
import { isTrainer, getUserNameSpace } from '../../../../../helpers/userRoles';

const VaccinationsPage = ({ currentCustomer, openVaccinationDeleteModalDialog, prependCurrentCustomerVaccination }) => {
    return (
        <div className='customers-vaccinations'>
            <div className='customers-vaccinations__add-new-container'>
                {!isTrainer() && <FileControlUploadButton
                    uploadButton={
                        <AddNewItem
                            className='customers-vaccinations__add-new'
                            text='Upload New'
                            onClickHandler={() => {}}/> // Needs an empty handler to work.
                    }
                    onCompleteCallback={prependCurrentCustomerVaccination}
                    uploadUrl={`/v1/${getUserNameSpace('customers.immunizations-docs')}/customers/${currentCustomer.getIn(['instance', 'id'])}/immunizations-docs/upload/`}/>}
            </div>
            {currentCustomer.getIn(['instance', 'id']) === undefined
                ? 'Loading...'
                : (<VaccinationsTable
                    data={currentCustomer.getIn(['vaccinations', 'items'])}
                    currentCustomer={currentCustomer.get('instance')}
                    dataName='Vaccination Records'
                    readOnly={isTrainer()}
                    openVaccinationDeleteModalDialog={openVaccinationDeleteModalDialog}
                    isLoading={currentCustomer.get('isLoading')}/>)
            }
        </div>
    );
};

VaccinationsPage.propTypes = {
    currentCustomer: PropTypes.shape({}),
    handleGettingCustomersVaccinationRecords: PropTypes.func,
    openVaccinationDeleteModalDialog: PropTypes.func,
    prependCurrentCustomerVaccination: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
    openVaccinationDeleteModalDialog: bindActionCreators(ModalOpenActions.openVaccinationDeleteModalDialog,
        dispatch),
    prependCurrentCustomerVaccination: bindActionCreators(prependCurrentCustomerVaccination, dispatch)
});

const mapStateToProps = state => {
    return {
        currentCustomer: state.getIn(['currentCustomer'])
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VaccinationsPage);
