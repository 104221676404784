import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { ReactComponent as IconChevronDown } from '../../../../../../assets/icons/icon-chevron-right.svg';
import { dataStates } from '../../../../../../helpers/state';

const TrainersCommission = ({ items, dataState }) => {
    const renderTable = () => {
        if (dataState === dataStates.loaded && items && items.details && items.details.length) {
            const columns = [
                {
                    id: 'trainer',
                    Header: 'Trainer',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.isTotal ? 'Total:' : d.trainer_detail.full_name,
                    Footer: <strong>Total:</strong>,
                },
                {
                    id: 'salesAmmount',
                    Header: 'Sales Amount',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => `$${d.sales}`,
                    Footer: `$${items.sales}`,
                },
                {
                    id: 'programsCount',
                    Header: 'Number of Programs/Classes',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.count,
                    Footer: items.count,
                },
                {
                    id: 'percentageTotal',
                    Header: '% of Total',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => `${d.percent.toFixed(2)}%`,
                    Footer: '100%',
                },
                {
                    expander: true,
                    width: 65,
                    Expander: ({ isExpanded, ...rest }) => { // eslint-disable-line
                        return !rest.original.isTotal ? (<span className={`custom-reports__expand-btn ${isExpanded
                            ? 'custom-reports__expand-btn_expanded'
                            : ''}`}>
                            <IconChevronDown className='custom-reports__expand-btn__icon'/>
                        </span>) : null;
                    },
                    style: {
                        cursor: 'pointer',
                        fontSize: 25,
                        padding: 0,
                        textAlign: 'center',
                        userSelect: 'none',
                    },
                },
            ];

            return (
                <ReactTable
                    showPagination={false}
                    defaultPageSize={items.details.length}
                    data={items.details}
                    columns={columns}
                    SubComponent={({ original: row }) => {
                        const subComponentColumns = [
                            {
                                id: 'programName',
                                Header: 'Program or Class Name',
                                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                                className: 'custom-reports__inner-table__td',
                                accessor: d => d.name,
                                Footer: (<strong>Total {row.trainer_detail.full_name}:</strong>),
                            },
                            {
                                id: 'salesAmmount',
                                Header: 'Sales Amount ($)',
                                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                                className: 'custom-reports__inner-table__td',
                                accessor: d => `$${d.sales.toFixed(2)}`,
                                Footer: `$${row.sales.toFixed(2)}`,
                            },
                            {
                                id: 'programCount',
                                Header: 'Number of Programs/Classes',
                                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                                className: 'custom-reports__inner-table__td',
                                accessor: d => d.count,
                                Footer: row.count,
                            },
                            {
                                id: 'commission',
                                Header: 'Fitdog Commission (%)',
                                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                                className: 'custom-reports__inner-table__td',
                                accessor: d => `${d.fitdog_commission}%`,
                            },
                            {
                                id: 'share',
                                Header: 'Fitdog Share ($)',
                                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                                className: 'custom-reports__inner-table__td',
                                accessor: d => `$${d.fitdog_share}`,
                                Footer: () => {
                                    const fitdogShareTotal = row.details.reduce((acc, item) => acc + item.fitdog_share,
                                        0); // eslint-disable-line
                                    return `$${fitdogShareTotal}`;
                                },
                            },
                            {
                                id: 'trainerShare',
                                Header: 'Trainer Share ($)',
                                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                                className: 'custom-reports__inner-table__td',
                                accessor: d => `$${d.trainer_share}`,
                                Footer: `$${row.trainer_share}`,
                            },
                        ];
                        return (
                            <div className='custom-reports__inner-table'>
                                <ReactTable
                                    showPagination={false}
                                    defaultPageSize={row.details.length}
                                    data={row.details}
                                    columns={subComponentColumns}/>
                            </div>
                        );
                    }}/>);
        }
        return null;
    };

    return (
        <div className='custom-reports__report'>
            {items !== undefined && renderTable()}
        </div>
    );
};

TrainersCommission.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    dataState: PropTypes.string.isRequired,
};

export default TrainersCommission;
