import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { reduxForm } from 'redux-form/immutable';
import * as FormFactory from '../../../../components/common/Form/formFactory';

const EditCreateModalDialog = props => {
    const {
        onSubmit,
        onClose,
        handleSubmit,
        isSubmitting,
        data,
        invalid,
        isOpened,
        modalError,
    } = props;

    const additioanalSubmitClasses = data && 'submitButtonClasses' in data ? data.submitButtonClasses : '';
    const submitButtonClassNames = classNames(
        {
            'button modal-dialog__button': true,
            'button_disabled': isSubmitting || invalid,
        },
        additioanalSubmitClasses,
    );

    const renderForm = () => {
        return FormFactory.getForm({
            formName: data.form,
            formProps: props,
            initialValues: data.initialValues,
            isOpened,
        });
    };

    const normalizeFormData = formData => {
        const { submitNormalizer, updateNormalizer } = FormFactory.getFormDefinition(data.form);
        onSubmit({ formData, submitNormalizer, updateNormalizer });
    };


    return (
        <form className='modal-dialog__form' onSubmit={handleSubmit(normalizeFormData)}>
            {data.text &&
                <p className='modal-dialog__text modal-dialog__text_bold'>
                    {data.text}
                </p>}

            <div className='modal-dialog__form-content'>
                {renderForm()}
            </div>
            {modalError !== null && <div className='form__error'>{modalError}</div>}
            <div className='modal-dialog__form-footer modal-dialog__form-footer_column'>
                <button className={submitButtonClassNames} type='submit' disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting' : data.submitButtonText ? data.submitButtonText : 'Save'}
                </button>
                {data.hasCancel && <div className='modal-dialog__cancel' onClick={onClose}>Cancel</div>}
            </div>
        </form>
    );
};

EditCreateModalDialog.propTypes = {
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    onGetOptions: PropTypes.func,
    isSubmitting: PropTypes.bool,
    isOpened: PropTypes.bool,
    invalid: PropTypes.bool,
    data: PropTypes.shape({}),
    formValues: PropTypes.shape({}),
    modalError: PropTypes.string,
    submitButtonClasses: PropTypes.string
};

const form = reduxForm({
    form: 'EditCreateModalSidebarDialogForm',
    enableReinitialize: true
});

export default form(EditCreateModalDialog);
