const validate = values => {
    const errors = {};
    if (!values.get('full_name')) {
        errors.full_name = 'Full Name is Required.';
    } else if (values.get('full_name').length < 5) {
        errors.full_name = 'Full Name must be 5 characters or more.';
    } else if (values.get('full_name').length > 100) {
        errors.full_name = 'Full Name must be 100 characters or less.';
    }

    if (values.get('new_email')) {
        if (values.get('new_email').length < 5) {
            errors.new_email = 'It\'s not allowed to enter less than 5 symbols into this field';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,9}$/i.test(values.get('new_email'))) {
            errors.new_email = 'Please enter a valid e-mail address.';
        } else if (values.get('new_email').length > 254) {
            errors.new_email = 'It\'s not allowed to enter more than 254 symbols into this field';
        } else if (values.get('new_email') === values.get('email')) {
            errors.new_email = 'New email must be different from current email';
        }

        if (!values.get('current_password')) {
            errors.current_password = 'Password is Required.';
        } else if (values.get('current_password').length < 8) {
            errors.current_password = 'Password is too short. It must contain at least 8 characters.';
        } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*=+.,?<>±§~`[\]{}()_\-/|':;" ]{8,}$/i.test(values.get('current_password'))) {
            errors.current_password = 'Your Password is not strong enough.';
        } else if (values.get('current_password').length > 128) {
            errors.current_password = 'Password can\'t contain more than 128 characters';
        }
    }

    if (values.get('primary_phone')) {
        if (!/^[0-9()+-/,.]*$/i.test(values.get('primary_phone'))) {
            errors.primary_phone = 'Please enter a valid phone number';
        } else if (values.get('primary_phone').replace(/[^\w\s]/gi, '').length < 5) {
            errors.primary_phone = 'Phone Number is too short.';
        } else if (values.get('primary_phone').replace(/[^\w\s]/gi, '').length > 15) {
            errors.primary_phone = 'Phone Number is too large.';
        }
    }

    if (values.get('secondary_phone')) {
        if (!/^[0-9()+-/,.]*$/i.test(values.get('secondary_phone'))) {
            errors.secondary_phone = 'Please enter a valid phone number';
        } else if (values.get('secondary_phone').replace(/[^\w\s]/gi, '').length < 5) {
            errors.secondary_phone = 'Phone Number is too short.';
        } else if (values.get('secondary_phone').replace(/[^\w\s]/gi, '').length > 15) {
            errors.secondary_phone = 'Phone Number is too large.';
        }
    }
    if (values.get('address1') || values.get('city') || values.get('state') || values.get('zip_code')) {
        if (!values.get('address1')) {
            errors.address1 = 'Location is Required.';
        } else if (values.get('address1').length > 254) {
            errors.address1 = 'It\'s not allowed to enter more than 254 symbols into this field';
        }

        if (values.get('address2') && values.get('address2').length > 254) {
            errors.address2 = 'It\'s not allowed to enter more than 254 symbols into this field';
        }

        if (!values.get('city')) {
            errors.city = 'City is Required.';
        } else if (values.get('city').length > 254) {
            errors.city = 'It\'s not allowed to enter more than 254 symbols into this field';
        }

        if (!values.get('state') || Array.isArray(values.get('state'))) {
            errors.state = 'State is Required.';
        }

        if (!values.get('zip_code')) {
            errors.zip_code = 'Zip Code is Required.';
        } else if (values.get('zip_code').length > 10) {
            errors.zip_code = 'Zip Code must be 10 characters or less.';
        }

        if (values.get('location_notes') && values.get('location_notes').length > 254) {
            errors.location_notes = 'It\'s not allowed to enter more than 254 symbols into this field';
        }
    }
    return errors;
};

export default validate;
