import { getRole } from '../services/oauth';

export const getUserRole = () => {
    return getRole();
};

export const isCustomer = () => {
    return getUserRole() === 'customer';
};

export const isTrainer = () => {
    return getUserRole() === 'trainer';
};

export const isEmployee = () => {
    return getUserRole() === 'employee';
};

export const isManager = () => {
    return getUserRole() === 'admin';
};

export const isSuperadmin = () => {
    return getUserRole() === 'super_admin';
};

export const isAdminOrSuperadmin = () => {
    const userRole = getRole();
    return userRole === 'admin' || userRole === 'super_admin'; 
};

const roleNameSpaceMap = {
    super_admin: 'super-admins',
    admin: 'admins',
    trainer: 'trainers',
    employee: 'employees',
    customer: 'customers',
};

const roleContextMap = {
    super_admin: [
        'agreements',
        'campaigns',
        'campaigns.photo',
        'campaigns.targets',
        'campaigns.variables',
        'classes',
        'classes.photo',
        'directory',
        'packages',
        'packages.photo',
        'process-postponed-payments',
        'training-programs',
        'training-programs.photo',
        'agreements',
        'reports.trainers-commission',
        'reports.trainers-invoice',
    ],
    admin: [
        'customers.previous-classes',
        'customers.previous-trainings',
        'customers.upcoming-classes',
        'customers.upcoming-trainings',
        'directory',
        'directory.photo',
        'dogs.previous-classes',
        'dogs.previous-trainings',
        'dogs.upcoming-classes',
        'dogs.upcoming-trainings',
        'overview-reports',
        'custom-reports',
    ],
    trainer: [
        'class-occurrences',
        'class-occurrences.dogs',
        'class-occurrences.products',
        'classes',
        'customers',
        'customers.available-addresses',
        'customers.class-occurrences',
        'customers.class-occurrences.book',
        'customers.immunizations-docs',
        'customers.notes-incidents',
        'customers.packages',
        'customers.packages.sell',
        'customers.payment-cards',
        'customers.previous-classes',
        'customers.previous-trainings',
        'customers.purchased-packages',
        'customers.purchased-packages-trainings',
        'customers.upcoming-classes',
        'customers.upcoming-trainings',
        'directory',
        'dog-reports',
        'dogs',
        'dogs.notes-incidents',
        'dogs.previous-classes',
        'dogs.previous-trainings',
        'dogs.upcoming-classes',
        'dogs.upcoming-trainings',
        'me',
        'me/photo',
        'packages',
        'packages-trainings',
        'purchased-training-programs',
        'todays-notes',
        'training-events-composite',
        'training-events.products',
        'training-programs',
        'reports.commission',
        'reports.operational',
        'reports.trainers-invoice',
        'glance',
        'glance-with-location',
        'glance-classes-products',
        'glance-trainings-products',
        'glance-classes-products',
        'glance-trainings-products',
        'customers.training-programs',
    ],
};

export const getUserNameSpace = context => {
    const role = getRole();

    if (!(role in roleContextMap)) {
        return roleNameSpaceMap[role];
    }

    if (roleContextMap[role].includes(context)) {
        return roleNameSpaceMap[role];
    }

    if (role === 'super_admin' && roleContextMap.admin.includes(context)) {
        return roleNameSpaceMap.admin;
    }

    if (role !== 'customer') {
        return 'employees';
    }

    return 'customers';
};

export const trainingCompositeRole = getUserNameSpace('training-composite');
export const isTrainerByTrainingProgramFieldDisabled = isTrainer();
export const isTrainingInstructorFieldDisabled = isTrainer();
