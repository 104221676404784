export const SET_MODAL_SIDEBAR_OPENED = 'SET_MODAL_SIDEBAR_OPENED';
export const SET_MODAL_SIDEBAR_CLOSED = 'SET_MODAL_SIDEBAR_CLOSED';
export const SET_MODAL_SIDEBAR_SUBMIT_REQUEST_STARTED = 'SET_MODAL_SIDEBAR_SUBMIT_REQUEST_STARTED';
export const SET_MODAL_SIDEBAR_SUBMIT_REQUEST_FINISHED = 'SET_MODAL_SIDEBAR_SUBMIT_REQUEST_FINISHED';
export const SET_MODAL_SIDEBAR_CANCEL_REQUEST_STARTED = 'SET_MODAL_SIDEBAR_CANCEL_REQUEST_STARTED';
export const SET_MODAL_SIDEBAR_CANCEL_REQUEST_FINISHED = 'SET_MODAL_SIDEBAR_CANCEL_REQUEST_FINISHED';
export const SET_MODAL_SIDEBAR_DATA = 'SET_MODAL_SIDEBAR_DATA';
export const SET_MODAL_SIDEBAR_ACTIVE_FORM = 'SET_MODAL_SIDEBAR_ACTIVE_FORM';

export const SET_MODAL_SIDEBAR_SUBMIT_ERROR = 'SET_MODAL_SIDEBAR_SUBMIT_ERROR';
export const CLEAR_MODAL_SIDEBAR_SUBMIT_ERROR = 'CLEAR_MODAL_SIDEBAR_SUBMIT_ERROR';
