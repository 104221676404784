export const SET_VETINFO_MODAL_OPENED = 'SET_VETINFO_MODAL_OPENED';
export const SET_VETINFO_MODAL_CLOSED = 'SET_VETINFO_MODAL_CLOSED';

export const SET_DELETE_CUSTOMER_AGREEMENT_MODAL_OPENED = 'SET_DELETE_CUSTOMER_AGREEMENT_MODAL_OPENED';
export const SET_DELETE_CUSTOMER_AGREEMENT_MODAL_CLOSED = 'SET_DELETE_CUSTOMER_AGREEMENT_MODAL_CLOSED';

export const SET_CHANGE_NAME_MODAL_OPENED = 'SET_CHANGE_NAME_MODAL_OPENED';
export const SET_CHANGE_NAME_MODAL_CLOSED = 'SET_CHANGE_NAME_MODAL_CLOSED';
export const SET_CHANGE_NAME_DEFAULT_DATA = 'SET_CHANGE_NAME_DEFAULT_DATA';

export const SET_RESET_PASSWORD_MODAL_OPENED = 'SET_RESET_PASSWORD_MODAL_OPENED';
export const SET_RESET_PASSWORD_MODAL_CLOSED = 'SET_RESET_PASSWORD_MODAL_CLOSED';

export const SET_DELETE_CUSTOMER_MODAL_OPENED = 'SET_DELETE_CUSTOMER_MODAL_OPENED';
export const SET_DELETE_CUSTOMER_MODAL_CLOSED = 'SET_DELETE_CUSTOMER_MODAL_CLOSED';

export const SET_CONTACT_INFO_MODAL_OPENED = 'SET_CONTACT_INFO_MODAL_OPENED';
export const SET_CONTACT_INFO_MODAL_CLOSED = 'SET_CONTACT_INFO_MODAL_CLOSED';
export const SET_CONTACT_INFO_DEFAULT_DATA = 'SET_CONTACT_INFO_DEFAULT_DATA';
export const SET_CONTACT_INFO_MODAL_LOADING = 'SET_CONTACT_INFO_MODAL_LOADING';
export const SET_CONTACT_INFO_MODAL_LOADED = 'SET_CONTACT_INFO_MODAL_LOADED';

export const SET_ADDITIONAL_OWNERS_MODAL_OPENED = 'SET_ADDITIONAL_OWNERS_MODAL_OPENED';
export const SET_ADDITIONAL_OWNERS_MODAL_CLOSED = 'SET_ADDITIONAL_OWNERS_MODAL_CLOSED';
export const SET_ADDITIONAL_OWNERS_DEFAULT_DATA = 'SET_ADDITIONAL_OWNERS_DEFAULT_DATA';

export const SET_EMERGENCY_CONTACT_MODAL_OPENED = 'SET_EMERGENCY_CONTACT_MODAL_OPENED';
export const SET_EMERGENCY_CONTACT_MODAL_CLOSED = 'SET_EMERGENCY_CONTACT_MODAL_CLOSED';
export const SET_EMERGENCY_CONTACT_DEFAULT_DATA = 'SET_EMERGENCY_CONTACT_DEFAULT_DATA';

export const SET_DOG_CREATION_MODAL_OPENED = 'SET_DOG_CREATION_MODAL_OPENED';
export const SET_DOG_CREATION_MODAL_CLOSED = 'SET_DOG_CREATION_MODAL_CLOSED';
export const SET_DOG_CREATION_DEFAULT_DATA = 'SET_DOG_CREATION_DEFAULT_DATA';
export const SET_DOG_CREATION_LOADING = 'SET_DOG_CREATION_LOADING';
export const SET_DOG_CREATION_LOADED = 'SET_DOG_CREATION_LOADED';

export const SET_PD_LOCATIONS_EDIT_MODAL_OPENED = 'SET_PD_LOCATIONS_EDIT_MODAL_OPENED';
export const SET_PD_LOCATIONS_EDIT_MODAL_CLOSED = 'SET_PD_LOCATIONS_EDIT_MODAL_CLOSED';
export const SET_PD_LOCATIONS_EDIT_DEFAULT_DATA = 'SET_PD_LOCATIONS_EDIT_DEFAULT_DATA';

export const SET_PD_LOCATIONS_ADD_MODAL_OPENED = 'SET_PD_LOCATIONS_ADD_MODAL_OPENED';
export const SET_PD_LOCATIONS_ADD_MODAL_CLOSED = 'SET_PD_LOCATIONS_ADD_MODAL_CLOSED';

export const SET_ADD_CREDIT_CARD_MODAL_OPENED = 'SET_ADD_CREDIT_CARD_MODAL_OPENED';
export const SET_ADD_CREDIT_CARD_MODAL_CLOSED = 'SET_ADD_CREDIT_CARD_MODAL_CLOSED';
export const SET_ADD_CREDIT_CARD_MODAL_LOADING = 'SET_ADD_CREDIT_CARD_MODAL_LOADING';
export const SET_ADD_CREDIT_CARD_MODAL_LOADED = 'SET_ADD_CREDIT_CARD_MODAL_LOADED';

export const SET_EDIT_UPCOMING_CLASS_MODAL_OPENED = 'SET_EDIT_UPCOMING_CLASS_MODAL_OPENED';
export const SET_EDIT_UPCOMING_CLASS_MODAL_CLOSED = 'SET_EDIT_UPCOMING_CLASS_MODAL_CLOSED';
export const SET_EDIT_UPCOMING_CLASS_DEFAULT_DATA = 'SET_EDIT_UPCOMING_CLASS_DEFAULT_DATA';

export const COLLAPSE_CUSTOMERS_UPCOMING_CLASSES = 'COLLAPSE_CUSTOMERS_UPCOMING_CLASSES';
export const COLLAPSE_CUSTOMERS_PREVIOUS_CLASSES = 'COLLAPSE_CUSTOMERS_PREVIOUS_CLASSES';

export const COLLAPSE_DOGS_UPCOMING_CLASSES = 'COLLAPSE_DOGS_UPCOMING_CLASSES';
export const COLLAPSE_DOGS_PREVIOUS_CLASSES = 'COLLAPSE_DOGS_PREVIOUS_CLASSES';

export const COLLAPSE_CUSTOMERS_UPCOMING_TRAININGS = 'COLLAPSE_CUSTOMERS_UPCOMING_TRAININGS';
export const COLLAPSE_CUSTOMERS_PREVIOUS_TRAININGS = 'COLLAPSE_CUSTOMERS_PREVIOUS_TRAININGS';

export const COLLAPSE_DOGS_UPCOMING_TRAININGS = 'COLLAPSE_DOGS_UPCOMING_TRAININGS';
export const COLLAPSE_DOGS_PREVIOUS_TRAININGS = 'COLLAPSE_DOGS_PREVIOUS_TRAININGS';

export const SET_VETERINARIAN_OPTIONS = 'SET_VETERINARIAN_OPTIONS';
export const ADD_NEW_VETERINARIAN_OPTION = 'ADD_NEW_VETERINARIAN_OPTION';
export const SET_ACCESS_LEVEL_OPTIONS = 'SET_ACCESS_LEVEL_OPTIONS';
export const SET_SEX_OPTIONS = 'SET_SEX_OPTIONS';
export const SET_BREED_OPTIONS = 'SET_BREED_OPTIONS';
export const SET_WEIGHT_OPTIONS = 'SET_WEIGHT_OPTIONS';
export const SET_ICON_OPTIONS = 'SET_ICON_OPTIONS';
export const SET_CLASS_OPTIONS = 'SET_CLASS_OPTIONS';
export const SET_LOCATION_OPTIONS = 'SET_LOCATION_OPTIONS';
export const SET_IMMUNIZATION_OPTIONS = 'SET_IMMUNIZATION_OPTIONS';
export const SET_USER_GROUPING_OPTIONS = 'SET_USER_GROUPING_OPTIONS';
export const SET_MARKETING_ATTENDANCE_BY_ZONE_FILTER_VALUES = 'SET_MARKETING_ATTENDANCE_BY_ZONE_FILTER_VALUES';
export const SET_CUSTOMERS_OPTIONS = 'SET_CUSTOMERS_OPTIONS';

export const SET_DOG_EVALUATION_MODAL_OPENED = 'SET_DOG_EVALUATION_MODAL_OPENED';
export const SET_DOG_EVALUATION_MODAL_CLOSED = 'SET_DOG_EVALUATION_MODAL_CLOSED';

export const SET_ADD_CUSTOMER_CARD_MODAL_OPENED = 'SET_ADD_CUSTOMER_CARD_MODAL_OPENED';
export const SET_ADD_CUSTOMER_CARD_MODAL_CLOSED = 'SET_ADD_CUSTOMER_CARD_MODAL_CLOSED';

export const SET_CHANGE_PAYMENT_CARD_MODAL_OPENED = 'SET_CHANGE_PAYMENT_CARD_MODAL_OPENED';
export const SET_CHANGE_PAYMENT_CARD_MODAL_CLOSED = 'SET_CHANGE_PAYMENT_CARD_MODAL_CLOSED';

export const SET_ADD_ADDRESS_MODAL_OPENED = 'SET_ADD_ADDRESS_MODAL_OPENED';
export const SET_ADD_ADDRESS_MODAL_CLOSED = 'SET_ADD_ADDRESS_MODAL_CLOSED';

export const SET_ADD_INSTRUCTOR_EMPLOYEE_MODAL_OPENED = 'SET_ADD_INSTRUCTOR_EMPLOYEE_MODAL_OPENED';
export const SET_EDIT_INSTRUCTOR_EMPLOYEE_MODAL_OPENED = 'SET_EDIT_INSTRUCTOR_EMPLOYEE_MODAL_OPENED';
export const SET_INSTRUCTOR_EMPLOYEE_MODAL_CLOSED = 'SET_INSTRUCTOR_EMPLOYEE_MODAL_CLOSED';
export const SET_INSTRUCTOR_EMPLOYEE_DEFAULT_DATA = 'SET_INSTRUCTOR_EMPLOYEE_DEFAULT_DATA';
export const SET_INSTRUCTOR_EMPLOYEE_MODAL_LOADING = 'SET_INSTRUCTOR_EMPLOYEE_MODAL_LOADING';
export const SET_INSTRUCTOR_EMPLOYEE_MODAL_LOADED = 'SET_INSTRUCTOR_EMPLOYEE_MODAL_LOADED';

export const SET_IMMUNIZATION_EXPIRATION_DATES_MODAL_OPENED = 'SET_IMMUNIZATION_EXPIRATION_DATES_MODAL_OPENED';
export const SET_IMMUNIZATION_EXPIRATION_DATES_MODAL_CLOSED = 'SET_IMMUNIZATION_EXPIRATION_DATES_MODAL_CLOSED';
export const SET_IMMUNIZATION_EXPIRATION_DATES_DEFAULT_DATA = 'SET_IMMUNIZATION_EXPIRATION_DATES_DEFAULT_DATA';
export const SET_IMMUNIZATION_EXPIRATION_DATES_MODAL_LOADING = 'SET_IMMUNIZATION_EXPIRATION_DATES_MODAL_LOADING';
export const SET_IMMUNIZATION_EXPIRATION_DATES_MODAL_LOADED = 'SET_IMMUNIZATION_EXPIRATION_DATES_MODAL_LOADED';

export const SET_UPLOAD_MODAL_OPENED = 'SET_UPLOAD_MODAL_OPENED';
export const SET_UPLOAD_MODAL_CLOSED = 'SET_UPLOAD_MODAL_CLOSED';
export const SET_FILE_PROGRESS = 'SET_FILE_PROGRESS';
export const SET_UPLOAD_FILE_STATUS = 'SET_UPLOAD_FILE_STATUS';

export const SET_MOBILE_SIDEBAR_OPENED = 'SET_MOBILE_SIDEBAR_OPENED';
export const SET_MOBILE_SIDEBAR_CLOSED = 'SET_MOBILE_SIDEBAR_CLOSED';

export const SET_CUSTOMER_CONTACTS_INFO_OPENED = 'SET_CUSTOMER_CONTACTS_INFO_OPENED';
export const SET_CUSTOMER_CONTACTS_INFO_CLOSED = 'SET_CUSTOMER_CONTACTS_INFO_CLOSED';

export const SET_SMS_VERIFICATION_MODAL_OPENED = 'SET_SMS_VERIFICATION_MODAL_OPENED';
export const SET_SMS_VERIFICATION_MODAL_CLOSED = 'SET_SMS_VERIFICATION_MODAL_CLOSED';

export const SET_PAGE_BLOCKED = 'SET_PAGE_BLOCKED';
export const SET_PAGE_UNBLOCKED = 'SET_PAGE_UNBLOCKED';
