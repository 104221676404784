import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field } from 'redux-form/immutable';
import { handleAutocompleteSelectOrEnter } from '../../../../actions/autocompleteActions';
import { usStates } from '../../../../helpers/options';
import * as ValidatorFactory from '../../../../helpers/validate';
import renderInputControl from '../../../common/Form/InputControl';
import renderSelectControl from '../../../common/Form/SelectControl/SelectControl';
import renderPlacesAutocompleteControl from '../PlacesAutocompleteControl/PlacesAutocompleteControl';

// eslint-disable-next-line react/prefer-stateless-function
class NewPDLocation extends React.PureComponent {
    static propTypes = {
        locationType: PropTypes.string.isRequired,
        changeSameAddress: PropTypes.func,
        sameChecked: PropTypes.bool,
        eventZipCodes: PropTypes.shape([]),
        handleAutocompleteSelectOrEnter: PropTypes.func,
        fields: PropTypes.shape({}),
        formName: PropTypes.string,
    };

    render() {
        const {
            locationType, changeSameAddress, sameChecked, eventZipCodes, formName, handleAutocompleteSelectOrEnter,
            fields
        } = this.props;
        console.log('formName: ', formName);
        return (
            <div key={locationType} className='modal-dialog__new-item'>
                <div className='modal-dialog__form-divider'/>
                <Field
                    name={`${locationType}_name`}
                    type='text'
                    component={renderInputControl}
                    label='Nickname (Home, Office, etc)'
                    validate={[ValidatorFactory.requiredValidator('Nickname')]}/>
                <div className='form__aligner form__aligner_50-50'>
                    <Field
                        name={`${locationType}_address1`}
                        type='text'
                        component={renderPlacesAutocompleteControl}
                        handleSelectOrEnter={address => {
                            handleAutocompleteSelectOrEnter(address, 'EditCreateModalDialogForm', fields);
                        }}
                        label='Location'
                        validate={[ValidatorFactory.requiredValidator('Address')]}/>
                    <Field
                        name={`${locationType}_address2`}
                        type='text'
                        component={renderInputControl}
                        label='Location 2 (Apt, #, Unit, etc)'/>
                </div>
                <div className='form__aligner form__aligner_39-28-29'>
                    <Field
                        name={`${locationType}_city`}
                        type='text'
                        component={renderInputControl}
                        label='City'
                        validate={[ValidatorFactory.requiredValidator('Сity')]}/>
                    <Field
                        name={`${locationType}_state`}
                        type='text'
                        component={renderSelectControl}
                        options={usStates}
                        className='select-control__is-state'
                        searchable
                        returnSelectValue
                        label='State'
                        validate={[ValidatorFactory.requiredValidatorSelect('State')]}/>
                    <Field
                        name={`${locationType}_zip_code`}
                        type='text'
                        component={renderInputControl}
                        label='ZIP Code'
                        validate={eventZipCodes !== undefined
                            ? [
                                ValidatorFactory.requiredValidator('Zip Code'),
                                ValidatorFactory.maxZipcodeLengthValidator('Zip Code'),
                                ValidatorFactory.addressZipStringValidator(eventZipCodes),
                            ]
                            : [
                                ValidatorFactory.requiredValidator('Zip Code'),
                                ValidatorFactory.maxZipcodeLengthValidator('Zip Code'),
                            ]}/>
                </div>
                <Field
                    name={`${locationType}_location_notes`}
                    type='text'
                    component={renderInputControl}
                    label='Location Notes'/>
                <div className='checkbox-control'>
                    <label htmlFor={`${locationType}_is_the_same`} className='checkbox-control__label'>
                        <Field
                            name={`${locationType}_is_the_same`}
                            id={`${locationType}_is_the_same`}
                            type='checkbox'
                            className='checkbox-control__input'
                            component={checkboxProps => {
                                return (
                                    <input
                                        type='checkbox'
                                        {...checkboxProps}
                                        checked={sameChecked}
                                        onChange={event => {
                                            checkboxProps.input.onChange(event.target.checked);

                                            if (changeSameAddress) {
                                                changeSameAddress(event.target.checked, locationType);
                                            }
                                        }}/>
                                );
                            }}/>
                        <span className='checkbox-control__text'>
                            {locationType === 'new_pickup_location'
                                ? ('Dropoff Location is the same')
                                : ('Pickup Location is the same')}
                        </span>
                    </label>
                </div>
                <div className='modal-dialog__form-divider'/>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    handleAutocompleteSelectOrEnter: bindActionCreators(handleAutocompleteSelectOrEnter, dispatch),
});

export default connect(null, mapDispatchToProps)(NewPDLocation);
