import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { formatDateToSlashes } from '../../../../../../helpers/date';
import { dataStates } from '../../../../../../helpers/state';

const EmailsDelivery = ({ items, dataState }) => {
    const renderTable = () => {
        if (dataState === dataStates.loaded && items && items.length) {
            const columns = [
                {
                    id: 'name',
                    Header: 'Marketing Campaign Name',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.name,
                },
                {
                    id: 'date ',
                    Header: 'Date Of Sending',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => formatDateToSlashes(d.modified_at),
                },
                {
                    id: 'status',
                    Header: 'Status',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.status_detail,
                },
                {
                    id: 'opened',
                    Header: 'Opened',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.statistics.opened === null ? '-' : d.statistics.opened,
                },
                {
                    id: 'clicked',
                    Header: 'Clicked',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.statistics.clicked === null ? '-' : d.statistics.clicked,
                },
            ];

            return (
                <ReactTable
                    showPagination={false}
                    defaultPageSize={items.length}
                    data={items}
                    columns={columns}/>);
        }
        return null;
    };

    return (
        <div className='custom-reports__report'>
            {items !== undefined && renderTable()}
        </div>
    );
};

EmailsDelivery.propTypes = {
    items: PropTypes.shape({}).isRequired,
    dataState: PropTypes.string.isRequired,
};

export default EmailsDelivery;
