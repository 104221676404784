import React from 'react';
import PropTypes from 'prop-types';

const PackageSummary = ({ data, onRemove }) => {
    const { name, quantity, price, description } = data;
    return (
        <div className='package-summary'>
            <div className='package-summary__header'>
                <p className='package-summary__title'>{name}</p>
                <p className='package-summary__subline'>Includes {quantity} Classes</p>
            </div>
            <div className='package-summary__content'>{description}</div>
            <div className='package-summary__footer'>
                <span className='package-summary__price'>${price}</span>
                <span 
                    className='package-summary__button'
                    onClick={onRemove}>
                    Remove item
                </span>
            </div>
        </div>
    );
};

PackageSummary.propTypes = {
    data: PropTypes.shape({}),
    onRemove: PropTypes.func
};

export default PackageSummary;
