import moment from 'moment';

export const dateValidation = value => {
    let warningMessage = null;
    if (value !== 'None') {
        if (moment(moment().format('MM/DD/YY'), 'MM/DD/YY').isAfter(moment(value, 'MM/DD/YY'))) {
            warningMessage = 'Vaccination date has passed';
        } else if (!moment(value, 'MM/DD/YY').isValid() || value.length < 8) {
            warningMessage = 'Date is invalid';
        }
    }
    return warningMessage;
};

const warn = values => {
    const vaccinations = values.get('vaccinations').toJS();
    const warning = vaccinations.reduce((warningObject, dateType) => {
        const validationResult = dateValidation(values.get(dateType));
        if (validationResult !== null) {
            warningObject[dateType] = validationResult;
        }
        return warningObject;
    }, {});
    
    return warning;
};

export default warn;
