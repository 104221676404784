/* eslint-disable camelcase */
import { createAction } from 'redux-actions';
import { getUserNameSpace } from '../../helpers/userRoles';
import { postAvailableAddressAsync } from '../../services/availableAddresses';
import {
    getPreviousClasses,
    getUpcomingClasses,
    getNextPreviousClasses,
    getNextUpcomingClasses,
    patchPreviousClasses,
    patchPreviousClassesAsync,
    patchUpcomingClasses,
    patchUpcomingClassesAsync
} from '../../services/classes';
import { deleteDogReport } from '../../services/dogReports';
import { createStripeCardToken } from '../../services/paymentCards';
import * as ClassesTypes from '../../types/dashboard/classesTypes';
import { setFormError, setGlobalError } from '../errorHandlerActions';
import { addCustomersAvailableAddress } from './availableAddressesActions';
import * as DogReportsActions from './dogReportsActions';
import * as EventActions from './eventActions';
import { addCustomersPaymentCard } from './paymentCardsAction';
import * as dashboardPageActions from '../../actions/dashboard/dashboardPageActions';

export const markCustomersPreviousClassesLoaded = createAction(ClassesTypes.MARK_CUSTOMER_PREVIOUS_CLASSES_LOADED);
export const unmarkCustomersPreviousClassesLoaded = createAction(ClassesTypes.UNMARK_CUSTOMER_PREVIOUS_CLASSES_LOADED);
export const startIsLoadingCustomerPreviousClasses = createAction(ClassesTypes.START_ISLOADING_CUSTOMER_PREVIOUS_CLASSES);
export const finishIsLoadingCustomerPreviousClasses = createAction(ClassesTypes.FINISH_ISLOADING_CUSTOMER_PREVIOUS_CLASSES);
export const setCustomersPreviousClasses = createAction(ClassesTypes.SET_CUSTOMER_PREVIOUS_CLASSES);
export const setCustomersPreviousClassDetails = createAction(ClassesTypes.SET_CUSTOMER_PREVIOUS_CLASS_DETAILS);
export const getCustomersPreviousClasses = (customerId) => getPreviousClasses({
    role: getUserNameSpace('customers.previous-classes'),
    context: 'customers',
    id: customerId
});
export const getNextCustomersPreviousClasses = () => getNextPreviousClasses({
    context: 'customers'
});
export const updateCustomerPreviousClass = createAction(ClassesTypes.UPDATE_CUSTOMER_PREVIOUS_CLASS);

export const patchCustomersPreviousClassStatus = (classData, status) => patchPreviousClasses({
    role: getUserNameSpace('customers.previous-classes'),
    context: 'customers',
    classData: {
        contextId: classData.dog_detail.owner,
        classId: classData.id,
        status
    }
});

export const markCustomersUpcomingClassesLoaded = createAction(ClassesTypes.MARK_CUSTOMER_UPCOMING_CLASSES_LOADED);
export const unmarkCustomersUpcomingClassesLoaded = createAction(ClassesTypes.UNMARK_CUSTOMER_UPCOMING_CLASSES_LOADED);
export const startIsLoadingCustomerUpcomingClasses = createAction(ClassesTypes.START_ISLOADING_CUSTOMER_UPCOMING_CLASSES);
export const finishIsLoadingCustomerUpcomingClasses = createAction(ClassesTypes.FINISH_ISLOADING_CUSTOMER_UPCOMING_CLASSES);
export const setCustomersUpcomingClasses = createAction(ClassesTypes.SET_CUSTOMER_UPCOMING_CLASSES);
export const setCustomersUpcomingClassDetails = createAction(ClassesTypes.SET_CUSTOMER_UPCOMING_CLASS_DETAILS);
export const getCustomersUpcomingClasses = (customerId) => getUpcomingClasses({
    role: getUserNameSpace('customers.upcoming-classes'),
    context: 'customers',
    id: customerId
});
export const getNextCustomersUpcomingClasses = () => getNextUpcomingClasses({
    context: 'customers'
});

export const patchCustomersUpcomingClassStatus = (classData, status, queryParams) => {
    const { force_cancel_payment } = classData;
    return patchUpcomingClassesAsync({
        role: getUserNameSpace('customers.upcoming-classes'),
        context: 'customers',
        classData: {
            contextId: classData.dog_detail.owner,
            classId: classData.id,
            force_cancel_payment: !!force_cancel_payment,
            status
        },
        queryParams
    });
};

export const patchUpcomingClassAfterEditing = (classData) => {
    // eslint-disable-next-line prefer-const
    const {
        context,
        dogId,
        customerId,
        classId,
        class_occurrence: classOccurrence,
        card,
        pickup_location,
        drop_off_location,
        purchased_package,
        switch_classes: switchClasses,
    } = classData;

    let contextId;
    if (context === 'customers') {
        contextId = customerId;
    } else {
        contextId = dogId;
    }

    return patchUpcomingClasses({
        context,
        contextId,
        classId,
        classData: {
            card,
            pickup_location,
            drop_off_location,
            purchased_package: purchased_package !== undefined ? purchased_package : null,
            class_occurrence: (classOccurrence && switchClasses) ? classOccurrence : undefined,
        }
    });
};

export const editUpcomingClass = ({ classData }) => {
    return dispatch => {
        const {
            context, customerId, new_pickup_location_is_the_same, new_drop_off_location_is_the_same, newPickupAddress,
            newDropoffAddress, newCard,
        } = classData;

        let newPickupAddressRequest;
        if (newPickupAddress !== undefined) {
            newPickupAddressRequest = dispatch(postAvailableAddressAsync(customerId, newPickupAddress))
                .then((postedAddress) => {
                    const { data } = postedAddress;
                    if (context === 'customers') {
                        dispatch(addCustomersAvailableAddress(data));
                    }
                    if (new_pickup_location_is_the_same) {
                        classData.drop_off_location = data.id;
                    }
                    classData.pickup_location = data.id;
                })
                .catch((error) => {
                    dispatch(setFormError(error.response));
                });
        }

        let newDropoffAddressRequest;
        if (newDropoffAddress !== undefined) {
            newDropoffAddressRequest = dispatch(postAvailableAddressAsync(customerId, newDropoffAddress))
                .then((postedAddress) => {
                    const { data } = postedAddress;
                    if (context === 'customers') {
                        dispatch(addCustomersAvailableAddress(data));
                    }
                    if (new_drop_off_location_is_the_same) {
                        classData.pickup_location = data.id;
                    }
                    classData.drop_off_location = data.id;
                })
                .catch((error) => {
                    dispatch(setFormError(error.response));
                });
        }

        let newCardRequest;
        if (newCard !== undefined) {
            newCardRequest = dispatch(createStripeCardToken(customerId, newCard, true))
                .then((postedCard) => {
                    if (context === 'customers') {
                        dispatch(addCustomersPaymentCard(postedCard));
                    }
                    classData.card = postedCard.id;
                })
                .catch((error) => {
                    dispatch(setFormError(error.response));
                });
        }

        console.log('editUpcomingClass classData: ', classData);

        return Promise.all([newPickupAddressRequest, newDropoffAddressRequest, newCardRequest]).then(() => {
            dispatch(patchUpcomingClassAfterEditing(classData));
        });
    };
};
export const removeCustomersUpcomingClass = createAction(ClassesTypes.REMOVE_CUSTOMERS_UPCOMING_CLASS);
export const chargeFeeAfterCancelingClass = createAction(ClassesTypes.CHARGE_FEE_AFTER_CANCELING_CLASS);
export const waiveFeeAfterCancelingClass = createAction(ClassesTypes.WAIVE_FEE_AFTER_CANCELING_CLASS);
export const markCustomersUpcomingClass = (classData, status) => {
    return dispatch => {
        dispatch(patchCustomersUpcomingClassStatus(classData, status))
            .then((response) => {
                dispatch(removeCustomersUpcomingClass(response.data.id));
                dispatch(getCustomersPreviousClasses(response.data.dog_detail.owner));
            })
            .catch((error) => {
                dispatch(setGlobalError(error));
            });
    };
};

export const markDogsPreviousClassesLoaded = createAction(ClassesTypes.MARK_DOG_PREVIOUS_CLASSES_LOADED);
export const unmarkDogsPreviousClassesLoaded = createAction(ClassesTypes.UNMARK_DOG_PREVIOUS_CLASSES_LOADED);
export const startIsLoadingDogPreviousClasses = createAction(ClassesTypes.START_ISLOADING_DOG_PREVIOUS_CLASSES);
export const finishIsLoadingDogPreviousClasses = createAction(ClassesTypes.FINISH_ISLOADING_DOG_PREVIOUS_CLASSES);
export const setDogsPreviousClasses = createAction(ClassesTypes.SET_DOG_PREVIOUS_CLASSES);
export const setDogsPreviousClassDetails = createAction(ClassesTypes.SET_DOG_PREVIOUS_CLASS_DETAILS);
export const getDogsPreviousClasses = (dogsId) => getPreviousClasses({
    role: getUserNameSpace('dogs.previous-classes'),
    context: 'dogs',
    id: dogsId
});
export const getNextDogsPreviousClasses = () => getNextPreviousClasses({
    context: 'dogs'
});
export const updateDogPreviousClass = createAction(ClassesTypes.UPDATE_DOG_PREVIOUS_CLASS);

export const patchDogsPreviousClassStatus = (classData, status) => patchPreviousClasses({
    role: getUserNameSpace('dogs.previous-classes'),
    context: 'dogs',
    classData: {
        contextId: classData.dog,
        classId: classData.id,
        status
    }
});

export const markDogsUpcomingClassesLoaded = createAction(ClassesTypes.MARK_DOG_UPCOMING_CLASSES_LOADED);
export const unmarkDogsUpcomingClassesLoaded = createAction(ClassesTypes.UNMARK_DOG_UPCOMING_CLASSES_LOADED);
export const startIsLoadingDogUpcomingClasses = createAction(ClassesTypes.START_ISLOADING_DOG_UPCOMING_CLASSES);
export const finishIsLoadingDogUpcomingClasses = createAction(ClassesTypes.FINISH_ISLOADING_DOG_UPCOMING_CLASSES);
export const setDogsUpcomingClasses = createAction(ClassesTypes.SET_DOG_UPCOMING_CLASSES);
export const setDogsUpcomingClassDetails = createAction(ClassesTypes.SET_DOG_UPCOMING_CLASS_DETAILS);
export const getDogsUpcomingClasses = (dogsId) => getUpcomingClasses({
    role: getUserNameSpace('dogs.upcoming-classes'),
    context: 'dogs',
    id: dogsId
});
export const getNextDogsUpcomingClasses = () => getNextUpcomingClasses({
    context: 'dogs'
});

export const patchDogsUpcomingClassStatus = (classData, status, queryParams) => {
    const { force_cancel_payment } = classData;
    return patchUpcomingClassesAsync({
        role: getUserNameSpace('dogs.upcoming-classes'),
        context: 'dogs',
        classData: {
            contextId: classData.dog_detail.id,
            classId: classData.id,
            force_cancel_payment: !!force_cancel_payment,
            status,
            no_notification: classData.noNotification,
        },
        queryParams
    });
};

export const patchDogsPreviousClassStatusAsync = (classData, status) => patchPreviousClassesAsync({
    role: getUserNameSpace('dogs.previous-classes'),
    context: 'dogs',
    classData: {
        contextId: classData.dog,
        classId: classData.id,
        status,
        no_notification: classData.noNotification,
    }
});
export const removeDogsUpcomingClass = createAction(ClassesTypes.REMOVE_DOGS_UPCOMING_CLASS);
export const markDogsUpcomingClass = (classData, status) => {
    return (dispatch, getState) => {
        dispatch(patchDogsUpcomingClassStatus(classData, status)).then((response) => {
            const { id, status, status_detail, dog, extended_status } = response.data;
            // if there are products in currentEvent we're changing them
            if (getState().getIn(['currentEvent', 'products']).size) {
                dispatch(EventActions.changeProductStatus({ id, status, status_detail, extended_status }));
            }
            if (getState().getIn(['dashboardPage', 'glanceClassesProducts', 'items'])) {
                dispatch(dashboardPageActions.changeClassProductStatus({ id, status, status_detail }));
            }
            dispatch(removeDogsUpcomingClass(id));
            dispatch(getDogsPreviousClasses(dog));
        });
    };
};
export const markDogsPreviousClass = (classData, status) => {
    return (dispatch, getState) => {
        dispatch(patchDogsPreviousClassStatusAsync(classData, status)).then((response) => {
            const { id, status, status_detail, extended_status } = response.data;
            // if there are products in currentEvent we're changing them
            if (getState().getIn(['currentEvent', 'products']).size) {
                dispatch(EventActions.changeProductStatus({ id, status, status_detail, extended_status }));
            }
            if (getState().getIn(['dashboardPage', 'glanceClassesProducts', 'items'])) {
                dispatch(dashboardPageActions.changeClassProductStatus({ id, status, status_detail }));
            }
        });
    };
};

export const deletePreviousClassReports = createAction(ClassesTypes.DELETE_PREVIOUS_CLASS_REPORTS);
export const setClassEmptyReports = (classData) => {
    const { id, reports } = classData;
    return (dispatch, getState) => {
        const reportRequests = Object.keys(reports).map((reportInfo) => {
            const { id } = reports[reportInfo];
            return dispatch(deleteDogReport({ id }))
                .then(() => {
                    dispatch(DogReportsActions.removeCurrentDogReportCard(id));
                });
        });

        return Promise.all(reportRequests).then(() => {
            if (getState().getIn(['currentEvent', 'products']).size) {
                dispatch(EventActions.deleteProductReports(id));
            }
            dispatch(deletePreviousClassReports(id));
        });
    };
};

export const updatePreviousClassReports = createAction(ClassesTypes.UPDATE_PREVIOUS_CLASS_REPORTS);
export const updateReport = (responseData) => {
    return (dispatch, getState) => {
        if (getState().getIn(['currentEvent', 'products']).size) {
            dispatch(EventActions.updateProductReports(responseData));
        }
        if (getState().getIn(['dashboardPage', 'glanceClassesProducts', 'items'])) {
            dispatch(dashboardPageActions.updateDashboardProductReports(responseData));
        }
        dispatch(updatePreviousClassReports(responseData));
    };
};
