import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { ReactComponent as IconChevronDown } from '../../../../../../assets/icons/icon-chevron-right.svg';
import { dataStates } from '../../../../../../helpers/state';

const mainItems = ['Summary', 'Detail', 'Retention / Attrition'];
const columns = [
    {
        id: 'newCustomerStatistics',
        Header: 'New Customer Statistics',
        headerClassName: 'custom-reports__th',
        className: 'custom-reports__td',
        accessor: d => d,
    },
    {
        expander: true,
        width: 65,
        Expander: ({ isExpanded, ...rest }) => // eslint-disable-line
            (<span className={`custom-reports__expand-btn ${isExpanded ? 'custom-reports__expand-btn_expanded' : ''}`}>
                <IconChevronDown className='custom-reports__expand-btn__icon'/>
            </span>),
        style: {
            cursor: 'pointer',
            fontSize: 25,
            padding: 0,
            textAlign: 'center',
            userSelect: 'none',
        },
    },
];

const getSubComponentColumns = (type) => {
    if (type === mainItems[0]) {
        return [
            {
                Header: '# of Accounts Created',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                columns: [
                    {
                        id: 'accountsNumber',
                        headerClassName: 'custom-reports__inner-table__th',
                        className: 'custom-reports__inner-table__td',
                        accessor: d => d.accounts_created,
                    },
                ],
            }, {
                Header: '# of Customers',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                columns: [
                    {
                        id: 'customersNumber',
                        headerClassName: 'custom-reports__inner-table__th',
                        className: 'custom-reports__inner-table__td',
                        accessor: d => d.customers,
                    },
                ],
            }, {
                Header: '# of Dog Profiles',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                columns: [
                    {
                        id: 'dogsNumber',
                        headerClassName: 'custom-reports__inner-table__th',
                        className: 'custom-reports__inner-table__td',
                        accessor: d => d.dogs_profiles,
                    },
                ],
            }, {
                id: 'dogsCompleted',
                Header: 'Dogs Completed Assessment',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                columns: [
                    {
                        id: 'dogsCompletedNumber',
                        Header: '#',
                        headerClassName: 'custom-reports__inner-table__th',
                        className: 'custom-reports__inner-table__td',
                        accessor: d => d.dogs_completed_assessment.count,
                    },
                    {
                        id: 'dogsCompletedPercent',
                        Header: '%',
                        headerClassName: 'custom-reports__inner-table__th',
                        className: 'custom-reports__inner-table__td',
                        accessor: d => d.dogs_completed_assessment.percent !== null
                            ? `${d.dogs_completed_assessment.percent.toFixed(2)}%`
                            : 0,
                    },
                ],
            }, {
                id: 'customersPurchase',
                Header: 'Customers that made purchases',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                columns: [
                    {
                        id: 'customersPurchasesNumber',
                        Header: '#',
                        headerClassName: 'custom-reports__inner-table__th',
                        className: 'custom-reports__inner-table__td',
                        accessor: d => d.customers_that_made_purchases.count,
                    },
                    {
                        id: 'customersPurchasesPercent',
                        Header: '%',
                        headerClassName: 'custom-reports__inner-table__th',
                        className: 'custom-reports__inner-table__td',
                        accessor: d => d.customers_that_made_purchases.percent !== null
                            ? `${d.customers_that_made_purchases.percent.toFixed(2)}%`
                            : 0,
                    },
                ],
            }, {
                Header: 'Total Spent',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                columns: [
                    {
                        id: 'totalSpent',
                        headerClassName: 'custom-reports__inner-table__th',
                        className: 'custom-reports__inner-table__td',
                        accessor: d => `$${d.total_spent.toFixed(2)}`,
                    },
                ],
            }, {
                Header: 'Conversion Rate',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                columns: [
                    {
                        id: 'conversionRate',
                        headerClassName: 'custom-reports__inner-table__th',
                        className: 'custom-reports__inner-table__td',
                        accessor: d => `${d.retention_rate.toFixed(2)}%`,
                    },
                ],
            },
        ];
    }

    if (type === mainItems[1]) {
        return [
            {
                id: 'registrationDate',
                Header: 'Date of Registration',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                accessor: d => moment(d.date_of_registration).format('YYYY-MM-DD'),
            }, {
                id: 'ownersName',
                Header: 'Owner\'s Name',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                accessor: d => d.full_name,
            }, {
                id: 'dogsName',
                Header: 'Dog\'s Name',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                accessor: d => d.dogs.map(dog => dog.name).join(', '),
            }, {
                id: 'assessmentCompleted',
                Header: '# of Assessments Completed',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                accessor: d => d.number_of_assessments_completed,
            }, {
                id: 'assignedAccessLevel',
                Header: 'Assigned Access Level',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                accessor: d => d.assigned_access_levels.join(', '),
            }, {
                id: 'transactions',
                Header: '# of Transactions',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                accessor: d => d.number_of_transactions,
            }, {
                id: 'dollarsSpent',
                Header: 'Dollars Spent ($)',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                accessor: d => `$${d.dollars_spent}`,
            },
        ];
    }

    if (type === mainItems[2]) {
        return [
            {
                id: 'activeDogsStart',
                Header: 'Active Dogs at Start Date',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                accessor: d => d.active_dogs_at_start,
            }, {
                id: 'activeDogsEnd',
                Header: 'Active Dogs at End Date',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                accessor: d => d.active_dogs_at_end,
            }, {
                id: 'acquiredDogs',
                Header: 'Acquired Dogs',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                accessor: d => d.acquired_dogs,
            }, {
                id: 'retentionRate',
                Header: 'Retention Rate',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                accessor: d => `${d.retention_rate.toFixed(2)}%`,
            }, {
                id: 'attritionRate',
                Header: 'Attrition Rate',
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                accessor: d => `${d.attrition_rate.toFixed(2)}%`,
            },
        ];
    }
    return [];
};

const NewCustomersStatistics = ({ items, dataState }) => {
    const renderTable = () => {
        if (dataState === dataStates.loaded && items) {
            return (
                <ReactTable
                    showPagination={false}
                    defaultPageSize={mainItems.length}
                    data={mainItems}
                    columns={columns}
                    SubComponent={row => {
                        const targetData = row.original === mainItems[1] ? items.detail : [items];
                        return (
                            <div className='custom-reports__inner-table'>
                                <ReactTable
                                    showPagination={false}
                                    defaultPageSize={targetData.length}
                                    data={targetData}
                                    sortable={row.original === mainItems[1]}
                                    columns={getSubComponentColumns(row.original)}/>
                            </div>
                        );
                    }}/>);
        }
        return null;
    };

    return (
        <div className='custom-reports__report'>
            {items !== undefined && renderTable()}
        </div>
    );
};

NewCustomersStatistics.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    dataState: PropTypes.string.isRequired,
};

export default NewCustomersStatistics;
