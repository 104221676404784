export const SET_TRAININGS_LIST = 'SET_TRAININGS_LIST';
export const SET_NEXT_TRAININGS_LIST = 'SET_NEXT_TRAININGS_LIST';
export const START_TRAININGS_LIST_LOADING = 'START_TRAININGS_LIST_LOADING';
export const FINISH_TRAININGS_LIST_LOADING = 'FINISH_TRAININGS_LIST_LOADING';

export const MARK_CUSTOMER_PREVIOUS_TRAININGS_LOADED = 'MARK_CUSTOMER_PREVIOUS_TRAININGS_LOADED';
export const UNMARK_CUSTOMER_PREVIOUS_TRAININGS_LOADED = 'UNMARK_CUSTOMER_PREVIOUS_TRAININGS_LOADED';
export const SET_CUSTOMER_PREVIOUS_TRAININGS = 'SET_CUSTOMER_PREVIOUS_TRAININGS';
export const SET_CUSTOMER_PREVIOUS_TRAINING_DETAILS = 'SET_CUSTOMER_PREVIOUS_TRAINING_DETAILS';

export const MARK_CUSTOMER_UPCOMING_TRAININGS_LOADED = 'MARK_CUSTOMER_UPCOMING_TRAININGS_LOADED';
export const UNMARK_CUSTOMER_UPCOMING_TRAININGS_LOADED = 'UNMARK_CUSTOMER_UPCOMING_TRAININGS_LOADED';
export const SET_CUSTOMER_UPCOMING_TRAININGS = 'SET_CUSTOMER_UPCOMING_TRAININGS';
export const SET_CUSTOMER_UPCOMING_TRAINING_DETAILS = 'SET_CUSTOMER_UPCOMING_TRAINING_DETAILS';
export const REMOVE_CUSTOMERS_UPCOMING_TRAINING = 'REMOVE_CUSTOMERS_UPCOMING_TRAINING';

export const MARK_DOG_PREVIOUS_TRAININGS_LOADED = 'MARK_DOG_PREVIOUS_TRAININGS_LOADED';
export const UNMARK_DOG_PREVIOUS_TRAININGS_LOADED = 'UNMARK_DOG_PREVIOUS_TRAININGS_LOADED';
export const SET_DOG_PREVIOUS_TRAININGS = 'SET_DOG_PREVIOUS_TRAININGS';
export const SET_DOG_PREVIOUS_TRAINING_DETAILS = 'SET_DOG_PREVIOUS_TRAINING_DETAILS';

export const MARK_DOG_UPCOMING_TRAININGS_LOADED = 'MARK_DOG_UPCOMING_TRAININGS_LOADED';
export const UNMARK_DOG_UPCOMING_TRAININGS_LOADED = 'UNMARK_DOG_UPCOMING_TRAININGS_LOADED';
export const SET_DOG_UPCOMING_TRAININGS = 'SET_DOG_UPCOMING_TRAININGS';
export const SET_DOG_UPCOMING_TRAINING_DETAILS = 'SET_DOG_UPCOMING_TRAINING_DETAILS';
export const REMOVE_DOGS_UPCOMING_TRAINING = 'REMOVE_DOGS_UPCOMING_TRAINING';

export const UPDATE_UPCOMING_TRAINING_PRODUCT_LOCATIONS = 'UPDATE_UPCOMING_TRAINING_PRODUCT_LOCATIONS';