import * as axios from 'axios';
import * as EventActions from '../actions/dashboard/eventActions';
import { getUserNameSpace } from '../helpers/userRoles';

const eventContextTypeMap = {
    'class-occurrences': 'class',
    'training-events-composite': 'training'
};

export const getTrainingEvent = ({ context, id }) => {
    return (dispatch, getState) => {
        return axios.get(`/v1/${getUserNameSpace(context)}/${context}/${id}/`)
            .then(response => {
                // if (isTrainer()) {
                //     response.data.trainer = getState().getIn(['user', 'id']);
                //     response.data.trainer_name = getState().getIn(['user', 'name']); // rewrite for trainer !!!
                // }
                response.data.type = 'training';
                dispatch(EventActions.setCurrentEvent(response.data));
                dispatch(EventActions.setCurrentEventProducts(response.data.products));
            })
            .catch(error => {
                console.log(error);
            });
    };
};

export const getClassEvent = ({ context, id }) => {
    return dispatch => {
        return axios.get(`/v1/${getUserNameSpace(context)}/${context}/${id}/`)
            .then(response => {
                response.data.type = 'class';
                dispatch(EventActions.setCurrentEvent(response.data));
            })
            .catch(error => {
                console.log(error);
            });
    };
};

export const getClassEventProducts = ({ id }) => {
    return dispatch => {
        return axios.get(`/v1/${getUserNameSpace('class-occurrences.products')}/class-occurrences/${id}/products/`)
            .then(response => {
                dispatch(EventActions.setCurrentEventProducts(response.data));
            })
            .catch(error => {
                console.log(error);
            });
    };
};

export const getCurrentEventWithProducts = ({ context, id }) => {
    const eventType = eventContextTypeMap[context];
    return dispatch => {
        if (eventType === 'training') {
            dispatch(getTrainingEvent({ context, id }));
        }

        if (eventType === 'class') {
            dispatch(getClassEvent({ context, id }));
            dispatch(getClassEventProducts({ id }));
        }
    };
};

export const getPurchasedTrainingPrograms = ({ data }) => {
    return () => {
        return axios.get(`/v1/${getUserNameSpace('purchased-training-programs')}/purchased-training-programs/`, { params: data });
    };
};

export const getPurchasedPackagesTrainingPrograms = ({ customerId }) => {
    return () => {
        return axios.get(`/v1/${getUserNameSpace('customers.purchased-packages-trainings')}/customers/${customerId}/purchased-packages-trainings/`);
    };
};

export const deleteTrainingProduct = ({ eventId, productId }) => {
    return () => {
        return axios.delete(`/v1/${getUserNameSpace('training-events.products')}/training-events/${eventId}/products/${productId}/`);
    };
};

export const patchTrainingProduct = ({ eventId, productId, eventData }) => {
    return () => {
        return axios.patch(`/v1/${getUserNameSpace('training-events.products')}/training-events/${eventId}/products/${productId}/`, eventData);
    };
};
