import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import moment from 'moment';
import * as ImmunizationExpirationDatesTypes from '../types/dashboard/immunizationExpirationDatesTypes';

const INITIAL_STATE = fromJS({
  isLoading: false,
  instance: {},
  normalizedInstance: {},
  initialValues: {},
  choices: []
});

const currentImmunizationExpirationDatesReducer = handleActions(
  {
    [ImmunizationExpirationDatesTypes.START_CURRENT_IMMUNIZATION_EXPIRATION_DATES_LOADING]: (state, action) => {
      return state.setIn(['isLoading'], true);
    },
    [ImmunizationExpirationDatesTypes.FINISH_CURRENT_IMMUNIZATION_EXPIRATION_DATES_LOADING]: (state, action) => {
      return state.setIn(['isLoading'], false);
    },
    [ImmunizationExpirationDatesTypes.SET_CURRENT_IMMUNIZATION_EXPIRATION_DATES_DATA]: (state, action) => {
      const choices = action.payload.get('choices');

      const normalizedInstance = {};

      const preparedInstance = action.payload.get('choices').reduce((result, choice) => {
        const immunization = action.payload.get('items').find((item) => {
            return item.toJS().immunization_type_detail === choice;
        });
        if (immunization !== undefined) {
            result[choice] = {
                expiration_date: moment(immunization.get('expiration_date'), 'YYYY-MM-DD').format('MM/DD/YY'),
                id: immunization.get('id'),
                immunization_type: immunization.get('immunization_type'),
                immunization_type_detail: immunization.get('immunization_type_detail')
            };
            normalizedInstance[choice] = moment(immunization.get('expiration_date'), 'YYYY-MM-DD').format('MM/DD/YY');
        } else {
            normalizedInstance[choice] = 'None';
        }
        return result;
      }, {});

      return state
        .set('initialValues', action.payload)
        .set('instance', fromJS(preparedInstance))
        .set('normalizedInstance', fromJS(normalizedInstance))
        .set('choices', choices);
    },
    [ImmunizationExpirationDatesTypes.CLEAR_CURRENT_IMMUNIZATION_EXPIRATION_DATES_DATA]: (state, action) => {
      return state
        .set('instance', fromJS({}))
        .set('initialValues', fromJS({}))
        .set('choices', fromJS([]))
        .set('normalizedInstance', fromJS([]));
    },
  },
  INITIAL_STATE,
);

export default currentImmunizationExpirationDatesReducer;
