export const CHANGE_CALENDAR_TYPE = 'CHANGE_CALENDAR_TYPE';

export const SET_CALENDAR_VIEW = 'SET_CALENDAR_VIEW';

export const SET_DAYPICKER_SHOWN = 'SET_DAYPICKER_SHOWN';
export const SET_DAYPICKER_HIDDEN = 'SET_DAYPICKER_HIDDEN';

export const SET_TRAINING_INSTRUCTOR_QUERY = 'SET_TRAINING_INSTRUCTOR_QUERY';
export const CLEAR_TRAINING_INSTRUCTOR_QUERY = 'CLEAR_TRAINING_INSTRUCTOR_QUERY';

export const SET_TRAINING_PROGRAM_QUERY = 'SET_TRAINING_PROGRAM_QUERY';
export const CLEAR_TRAINING_PROGRAM_QUERY = 'CLEAR_TRAINING_PROGRAM_QUERY';

export const SET_CLASS_OCCURRENCES_LOCATION_QUERY = 'SET_CLASS_OCCURRENCES_LOCATION_QUERY';
export const CLEAR_CLASS_OCCURRENCES_LOCATION_QUERY = 'CLEAR_CLASS_OCCURRENCES_LOCATION_QUERY';

export const SET_CLASS_OCCURRENCES_TRAINING_QUERY = 'SET_CLASS_OCCURRENCES_TRAINING_QUERY';
export const CLEAR_CLASS_OCCURRENCES_TRAINING_QUERY = 'CLEAR_CLASS_OCCURRENCES_TRAINING_QUERY';

export const SET_TRAINERS_COLORS_LIST = 'SET_TRAINERS_COLORS_LIST';

export const GET_CALENDAR_SELECTED_RANGE = 'GET_CALENDAR_SELECTED_RANGE';

export const SET_RANGE_START = 'SET_RANGE_START';
export const SET_RANGE_END = 'SET_RANGE_END';
export const SET_RANGE_START_END = 'SET_RANGE_START_END';
