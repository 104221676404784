import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import AvatarPlaceholder from '../../../common/AvatarPlaceholder';
import * as TabActions from '../../../../actions/tabActions';

const DailyNotification = (props) => {
    const {
        data,
        className,
        handleClick,
        handlePageChange
    } = props;
    const {
        photo,
        description,
        created_at,
        isUnread
    } = data;
    let { url } = data;
    let needLoadOpenDailyNotes = false;
    const notificationClassName = classNames(
        'daily-notification',
        className,
        {
            'daily-notification_unread': isUnread,
        }
    );
    const date = moment(created_at);
    const day = date.format('MMMM D');
    const time = date.format('hh:mm A');

    const onCllickHandler = () => {
        handleClick();
        if (needLoadOpenDailyNotes) handlePageChange({ nextPageName: 'notes' });
    };

    if (url === '/dashboard/daily_notes') {
        url = '/dashboard';
        needLoadOpenDailyNotes = true;
    }

    return (
        <Link to={url} className={notificationClassName} onClick={onCllickHandler}>
            <div
                className='daily-notification__image'
                style={photo && { backgroundImage: `url(${photo})` }} >
                {!photo && <AvatarPlaceholder type='dog' size={36} />}
            </div>
            <div className='daily-notification__title'>{description}</div>
            <div className='daily-notification__date'>{day} at {time}</div>
        </Link>
    );
};

DailyNotification.propTypes = {
    data: PropTypes.shape(),
    className: PropTypes.string,
    handleClick: PropTypes.func,
    handlePageChange: PropTypes.func,
};

const mapDispatchToProps = {
    handlePageChange: TabActions.handlePageChange,
};

export default connect(null, mapDispatchToProps)(DailyNotification);
