import * as axios from 'axios';
import { getUserNameSpace } from '../helpers/userRoles';

export const getDailyNotes = ({ page, date }) => {
    return () => {
        return axios.get(`/v1/${getUserNameSpace('todays-notes')}/todays-notes/${date}/`, {
            params: page !== undefined ? { page } : null
        });
    };
};

export const postDailyNotes = ({ data, date }) => {
    return () => {
        return axios.post(`/v1/${getUserNameSpace('todays-notes')}/todays-notes/${date}/`, { body: data.get('notes') });
    };
};
