/* eslint-disable camelcase */
import { createAction } from 'redux-actions';
import { push } from 'react-router-redux';
import * as Types from './types';
import * as Api from './api';
import { setGlobalError } from '../../actions/errorHandlerActions';
import { convertCampaignTargetOptions } from './options';

export const resetReducer = createAction(Types.RESET_REDUCER);
export const selectStep = createAction(Types.SELECT_STEP);
export const selectTemplate = createAction(Types.SELECT_TEMPLATE);


export const updateDetail = createAction(Types.UPDATE_DETAIL);
export const updateUploadProgress = createAction(Types.UPDATE_UPLOAD_PROGRESS);
export const startDetailRequest = createAction(Types.START_DETAIL_REQUEST);
export const finishDetailRequest = createAction(Types.FINISH_DETAIL_REQUEST);

export const initState = (id) => {
    return async (dispatch) => {
        const newState = {};
        dispatch(startDetailRequest());

        try {
            const [variables, targets, detail] = await Promise.all([
                dispatch(Api.getCampaignVariables()),
                dispatch(Api.getCampaignTargets()),
                id !== 'new' && dispatch(Api.getCampaignDetail(id))
            ]);

            newState.variables = variables.data;
            newState.targets = convertCampaignTargetOptions(targets.data);

            if (detail) {
                const { id, content: blocks, ...rest } = detail.data;
                const { template_id, name, subject, target_array: targetArray } = rest;
                const target_array = targetArray.reduce((result, target) => { // eslint-disable-line
                    result.push(targets.data.find(
                        t => t.id === target.id && t.grouping_type === target.grouping_type
                    ));
                    return result;
                }, []);

                newState.initialValues = { template_id, name, subject, blocks, target_array };
                newState.id = id;
                Object.keys(newState.initialValues).forEach((key) => {
                    if (newState.initialValues[key] === null) {
                        delete newState.initialValues[key];
                    }
                });
            }
            dispatch(finishDetailRequest(newState));
        } catch (error) {
            dispatch(finishDetailRequest());
            dispatch(setGlobalError(error));
            throw error;
        }
    };
};

export const editCampaign = ({ formConfig, submitData }) => {
    return async (dispatch) => {
        const { detailID: id } = formConfig;
        const { photos, ...data } = submitData;
        const campaignResponse = await dispatch(Api.patchCampaign({ data, id }));
        let photosResponse;

        if (photos) {
            photosResponse = await dispatch(Api.postCampaignPhotos({
                id,
                photos,
                onProgress: updateUploadProgress
            }));
        }

        return { campaign: campaignResponse, photos: photosResponse };
    };
};

export const createCampaign = ({ submitData }) => {
    return async (dispatch) => {
        const { photos, ...data } = submitData;
        const campaignResponse = await dispatch(Api.postCampaign({ data }));
        const { data: { id } } = campaignResponse;
        let photosResponse;

        if (photos) {
            photosResponse = await dispatch(Api.postCampaignPhotos({
                id,
                photos,
                onProgress: updateUploadProgress
            }));
        }

        return { campaign: campaignResponse, photos: photosResponse };
    };
};

export const sendCampaign = (id) => {
    return (dispatch) => {
        return dispatch(Api.patchCampaign({ id, data: { is_sent: true } }));
    };
};

export const testSendCampaign = (id) => {
    return (dispatch) => {
        return dispatch(Api.patchCampaign({ id, data: { test_send: true } }));
    };
};

export const gotoCampaignList = () => {
    return (dispatch) => {
        dispatch(push('/dashboard/marketing'));
    };
};
