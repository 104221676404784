

// import * as axios from 'axios';
import { push } from 'react-router-redux';
import { setCurrentRegistrationStep } from '../actions/onboarding/onBoardingStepsActions';

export const updateSteps = ({ redirectPath, actionCreator, stepNumber }) => {
    return (dispatch) => {
        console.log('stepNumber: ', stepNumber);
        localStorage.setItem('registration_step', stepNumber);
        dispatch(setCurrentRegistrationStep(stepNumber));
        if (actionCreator) {
            dispatch(actionCreator());
        }

        if (redirectPath) {
            if (redirectPath === '/waiver') {
                setTimeout(() => {
                    dispatch(push(redirectPath));
                }, 0);
            } else {
                dispatch(push(redirectPath));
            }
        }
    };
};

// deprecated /v1/customers/signup/next/
// export const updateSteps = ({ redirectPath, actionCreator }) => {
//     return (dispatch) => {
//         return axios.patch('/v1/customers/signup/next/')
//                     .then(response => {
//                         console.log('Server response: ', response);
//                         localStorage.setItem('registration_step', response.data.registration_step);
//                         dispatch(setCurrentRegistrationStep(response.data.registration_step));
//                         if (actionCreator) {
//                             dispatch(actionCreator());
//                         }
//
//                         if (redirectPath) {
//                             if (redirectPath === '/waiver') {
//                                 setTimeout(() => {
//                                     dispatch(push(redirectPath));
//                                 });
//                             } else {
//                                 dispatch(push(redirectPath));
//                             }
//                         }
//                     })
//                     .catch(err => {
//                         console.log(err);
//                         console.log(err.response);
//                         if (err.response) {
//                             if (err.response.status !== 200) {
//                                 console.log('Unexpected error code from the API server: ', err.response.status);
//                                 // if (err.response.data.error_description) {
//                                 //     throw new SubmissionError({
//                                 //         email: err.response.data.error_description,
//                                 //         password: err.response.data.error_description
//                                 //     });
//                                 // }
//                             }
//                         }
//                     });
//     };
// };
