import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';
import { ReactComponent as IconRemove21 } from '../../../assets/icons/icon-remove-21.svg';
import ModalContent from './ModalContent';
import * as ModalActions from '../../../actions/modal/modalActions';
import * as Modals from '../../containers/Modals/';
import { getModalDialogFormName } from '../Form/formFactory';

class Modal extends PureComponent {
    get modalProps() {
        const {
            onClose,
            onSubmit,
            onCancel,
            onGetOptions,
            onTabChange,
            isSubmitting,
            isCanceling,
            formValues,
            data,
            error,
            isOpened
        } = this.props;

        return {
            get confirmClassCancellation() {
                return {
                    data,
                };
            },
            get yesNoModal() {
                return {
                    onClose,
                    onSubmit,
                    onCancel,
                    isSubmitting,
                    isCanceling,
                    data,
                    modalError: error
                };
            },
            get actionModal() {
                return {
                    onClose,
                    onSubmit,
                    isSubmitting,
                    data,
                    modalError: error
                };
            },
            get editCreateModal() {
                return {
                    isOpened,
                    onClose,
                    onSubmit,
                    isSubmitting,
                    onGetOptions,
                    initialValues: data ? data.initialValues : {},
                    formValues,
                    data,
                    modalError: error
                };
            },
            get tabModal() {
                return {
                    onClose,
                    onSubmit,
                    isSubmitting,
                    onGetOptions,
                    onTabChange,
                    initialValues: data && ('forms' in data) ? data.forms[data.activeForm].initialValues : {},
                    data,
                    modalError: error
                };
            },
            get trainingSuccessModal() {
                return {
                    ...data,
                    onClose
                };
            },
            get locationAddModal() {
                return {
                    ...data,
                    onClose
                };
            }
        };
    }

    get modalDialogClassNames() {
        const { isOpened } = this.props;
        return classNames({
            'modal-one': true,
            'modal-one_active': isOpened,
        });
    }

    get modalDialogContentClassNames() {
        const { data } = this.props;
        return classNames({
            'modal-one__content': true,
            'modal-one__content_overflow-visible': data ? data.overflowShown : false
        });
    }

    renderModalDialog = () => {
        const { modalType, data, resetState, onClose } = this.props;
        if (!modalType) {
            return null;
        }

        const headerClassNames = (data && 'headerClassNames' in data) ? data.headerClassNames : null;
        const hasCloseBtn = (data && 'hasCloseBtn' in data) ? data.hasCloseBtn : true;
        const ActiveModal = Modals[modalType]; // eslint-disable-line;

        return (
            <ModalContent key='modal-content' resetState={resetState}>
                <div className={this.modalDialogContentClassNames} onClick={(e) => { e.stopPropagation(); }}>
                    <div className={`modal-one__header ${headerClassNames}`}>
                        {data && data.title && <div className='modal-one__title'>{data.title}</div>}
                        {hasCloseBtn && <button className='modal-one__close' onClick={onClose}>
                            <IconRemove21 className='icon_remove_21'/>
                        </button>}
                    </div>
                    <ActiveModal
                        {...this.modalProps[modalType]}
                        key={`${modalType}-${data.form}`}
                        onClose={onClose}/>
                </div>
            </ModalContent>
        );
    };

    render() {
        const { onClose } = this.props;
        return (
            <div className={this.modalDialogClassNames} onClick={onClose}>
                <CSSTransitionGroup
                    transitionName='modal-one'
                    transitionEnterTimeout={300}
                    transitionLeaveTimeout={300}>
                    {this.renderModalDialog()}
                </CSSTransitionGroup>
            </div>
        );
    }
}

Modal.propTypes = {
    modalType: PropTypes.string,
    resetState: PropTypes.func,
    isOpened: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onGetOptions: PropTypes.func,
    onTabChange: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    isCanceling: PropTypes.bool.isRequired,
    data: PropTypes.shape({}),
    formValues: PropTypes.shape({}),
    error: PropTypes.string
};

const mapFieldsToProps = state => {
    const data = state.getIn(['modal', 'data']);

    if (!data || !data.shouldPassValues) {
        return undefined;
    }

    const formState = state.getIn(['form', getModalDialogFormName(data.type)]);

    if (!formState) {
        return undefined;
    }
    return formState.get('values');
};

const mapStateToProps = state => {
    return {
        isOpened: state.getIn(['modal', 'isOpened']),
        isSubmitting: state.getIn(['modal', 'isSubmitting']),
        isCanceling: state.getIn(['modal', 'isCanceling']),
        modalType: state.getIn(['modal', 'modalType']),
        data: state.getIn(['modal', 'data']),
        error: state.getIn(['modal', 'error']),
        formValues: mapFieldsToProps(state)
    };
};

const mapDispatchToProps = {
    onClose: ModalActions.onModalClose,
    onSubmit: ModalActions.onModalSubmit,
    onGetOptions: ModalActions.onModalGetOptions,
    onCancel: ModalActions.onModalCancel,
    onTabChange: ModalActions.setModalActiveForm,
    resetState: ModalActions.resetModalReducer
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
