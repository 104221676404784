const validate = values => {
    const errors = {};

    if (!values.get('password')) {
        errors.password = 'Password is Required.';
    } else if (values.get('password').length < 8) {
        errors.password = 'Password is too short. It must contain at least 8 characters.';
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*=+.,?<>±§~`[\]{}()_\-/|':;" ]{8,}$/i.test(values.get('password'))) {
        errors.password = 'Your Password is not strong enough.';
    } else if (values.get('password').length > 100) {
        errors.password = 'Password can\'t contain more than 100 characters';
    }

    return errors;
};

export default validate;
