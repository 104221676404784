import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import { ReactComponent as IconCalendar } from '../../../../assets/icons/icon-calendar.svg';

import Icon from '../../Icon';

import { datePickerRef } from './DateControlCustomizations';

// eslint-disable-next-line max-len
class renderDateControl extends React.PureComponent {
    state = {
        isTouched: false,
    };

    touch = () => {
        this.setState({ isTouched: true });
    };

    untouch = () => {
        this.setState({ isTouched: false });
    };

    datePickerInstance = null;

    render() {
        const { isTouched } = this.state;
        const {
            name,
            input,
            label,
            type,
            meta: { touched, valid, warning, error },
            hint,
            className = '',
            disabled,
            input: { onChange },
            onKeyPress,
            disablePast = false,
            disableFuture = false,
            disabledDays = {},
        } = this.props;
        const InputContainerClassNames = classNames({
            'date-control': true,
            'date-control_disabled': disabled,
        });
        const InputClassNames = classNames({
            'date-control__input': true,
            'date-control__input_touched': (touched || isTouched),
            'date-control__input_failed': (warning && touched) || (error && touched),
        });
        const PlaceholderClassNames = classNames({
            'date-control__placeholder': true,
            'date-control__placeholder_touched': (touched || valid || isTouched || input.value !== ''),
            'date-control__placeholder_failed': (warning && touched) || (error && touched),
        });
        const startMonth = moment(input.value, 'MM/DD/YY').isValid()
            ? moment(moment().format('MM/DD/YY'), 'MM/DD/YY').isAfter(moment(input.value, 'MM/DD/YY'))
                               ? moment().toDate()
                               : moment(input.value, 'MM/DD/YY').toDate()
            : moment().toDate();
        const dayPickerProps = Object.assign(
            {
                month: startMonth,
                enableOutsideDays: false,
            },
            disablePast
                ? {
                    disabledDays: {
                        before: moment().toDate(),
                    },
                    fromMonth: moment().toDate(),
                }
                : disableFuture
                ? {
                    disabledDays: {
                        after: moment().toDate(),
                    },
                    toMonth: moment().toDate(),
                }
                : disabledDays
                      ? {
                        disabledDays: {
                            before: moment(disabledDays.before, 'MM/DD/YY').isValid()
                                ? moment(disabledDays.before, 'MM/DD/YY').toDate()
                                : undefined,
                            after: moment(disabledDays.after, 'MM/DD/YY').isValid()
                                ? moment(disabledDays.after, 'MM/DD/YY').toDate()
                                : undefined,
                        },
                        fromMonth: moment(disabledDays.before, 'MM/DD/YY').isValid()
                            ? moment(disabledDays.before, 'MM/DD/YY').toDate()
                            : undefined,
                    }
                      : {},
        );

        return (
            <div onFocus={() => this.touch()} className={`${InputContainerClassNames} ${className}`}>
                <DayPickerInput
                    {...input}
                    name={name}
                    placeholder='MM/DD/YY'
                    format='MM/DD/YY'
                    type={type}
                    disabled={disabled}
                    className={InputClassNames}
                    value={input.value}
                    dayPickerProps={dayPickerProps}
                    onDayChange={time => {
                        if (time !== undefined) {
                            const selectedTime = moment(time, 'MM/DD/YY');
                            if (selectedTime.isValid()) {
                                onChange(selectedTime.format('MM/DD/YY'), onChange);
                            }
                        }
                    }}
                    onKeyPress={event => {
                        const { value, selectionStart } = event.target;
                        if (event.which >= 48 && event.which <= 57) {
                            if (onKeyPress) {
                                onKeyPress(event);
                            }

                            if (value === 'None') {
                                event.target.value = '';
                                return;
                            }

                            const dateArray = value.split('/');

                            const firstSectionLimit = {
                                min: 0,
                                max: dateArray[0].length + 1,
                            };
                            const secondSelectionLimit = {
                                min: firstSectionLimit.max,
                                max: firstSectionLimit.max + (dateArray[1] ? dateArray[1].length : 0) + 1,
                            };
                            const thirdSectionLimit = {
                                min: secondSelectionLimit.max,
                                max: 9,
                            };

                            const monthBlocked =
                                dateArray[0].length === 2 &&
                                selectionStart >= firstSectionLimit.min &&
                                selectionStart < firstSectionLimit.max;
                            const dayBlocked =
                                (dateArray[1] ? dateArray[1].length : 0) === 2 &&
                                selectionStart >= secondSelectionLimit.min &&
                                selectionStart < secondSelectionLimit.max;
                            const yearBlocked =
                                (dateArray[2] ? dateArray[2].length : 0) === 2 &&
                                selectionStart >= thirdSectionLimit.min &&
                                selectionStart < thirdSectionLimit.max;

                            if (
                                (dateArray.length === 3 && (monthBlocked || dayBlocked || yearBlocked)) ||
                                value.length >= 8
                            ) {
                                // part of the date is selected and should be changed
                                if (window.getSelection().type === 'Caret') {
                                    event.preventDefault();
                                }
                            } else if (
                                (dateArray.length === 1 && dateArray[0].length === 1) ||
                                (dateArray.length === 2 && dateArray[1].length === 1)
                            ) {
                                event.target.value += event.key + '/';
                                event.preventDefault();
                                this.datePickerInstance.handleChange(event);
                            } else if (
                                (dateArray.length === 1 && dateArray[0].length === 2) ||
                                (dateArray.length === 2 && dateArray[1].length === 2)
                            ) {
                                event.target.value += '/';
                            }
                        } else {
                            event.preventDefault();
                        }
                    }}
                    onKeyDown={event => {
                        const { value } = event.target;
                        if (event.which === 8 || event.which === 46) {
                            if (value === 'None') {
                                event.target.value = '';
                                this.datePickerInstance.handleChange(event);
                            }
                        }
                    }}
                    onChange={event => {
                        const { value } = event.target;
                        onChange(value, onChange);
                    }}
                    ref={element => {
                        this.datePickerInstance = datePickerRef(element);
                    }}/>
                <span className={PlaceholderClassNames}>{label}</span>
                <div
                    className='date-control__icon-container'
                    onClick={e => {
                        if (disabled) {
                            return null;
                        }
                        return this.datePickerInstance.toggleDayPicker(e);
                    }}>
                    <Icon
                        className='icon_calendar-date-picker date-control__icon'
                        glyph={IconCalendar}/>
                </div>
                {warning && touched
                    ? (<span className='date-control__error'>{warning}</span>)
                    : error && touched
                     ? (<span className='date-control__error'>{error}</span>)
                     : null}
                {hint && <span className='date-control__hint'>{hint}</span>}
            </div>
        );
    }
}

renderDateControl.propTypes = {
    name: PropTypes.string,
    input: PropTypes.shape({}),
    label: PropTypes.string,
    type: PropTypes.string,
    meta: PropTypes.shape({}),
    hint: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onKeyPress: PropTypes.func,
    disablePast: PropTypes.bool,
    disableFuture: PropTypes.bool,
    disabledDays: PropTypes.shape({}),
};

export default renderDateControl;
