import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { Field, reduxForm } from 'redux-form/immutable';
import renderSelectControl from '../../../../common/Form/SelectControl';
import renderInputControl from '../../../../common/Form/InputControl';
import renderCheckboxControl from '../../../../common/Form/CheckboxControl';
import { usStates } from '../../../../../helpers/options';
import { saveCustomerPickupLocation } from '../../../../../services/availableAddresses';
import validate from './validate';
import renderPlacesAutocompleteControl from '../../../../common/Form/PlacesAutocompleteControl';
import { handleAutocompleteSelectOrEnter } from '../../../../../actions/autocompleteActions';

const formatTime = (dateString, timeString) => {
    const time = moment(`${dateString} ${timeString}`);
    const hours = time.format('h');
    const minutes = time.minutes() ? time.format(':mm') : '';
    const ampm = time.format('a');
    return hours + minutes + ampm;
};

const formatDate = (dateValue) => {
    const date = moment(dateValue, ['YYYY-MM-DD']);
    return date.format('DD/MM/YY');
};

class PickupLocationSelect extends PureComponent {

    componentWillMount() {
        const { formData: { user, addresses }, addSubmitData, addStepData, goToStep } = this.props;
        if (user && user.pickup_location !== null && addresses.length) {
            let currentAddress = null;
            addresses.map(({ id }) => { // eslint-disable-line
                if (id === user.pickup_location) {
                    currentAddress = id;
                }
            });
            if (currentAddress !== null) {
                addStepData({ name: 'pickupSelection', data: {} });
                addSubmitData({ name: 'pickup', data: currentAddress });
                goToStep(2);
            }
        }
    }

    componentWillUpdate(nextProps) {
        const { formData: { user, addresses }, addSubmitData, addStepData, goToStep } = nextProps;
        if (user && user.pickup_location !== null && addresses.length) {
            let currentAddress = null;
            addresses.map(({ id }) => { // eslint-disable-line
                if (id === user.pickup_location) {
                    currentAddress = id;
                }
            });
            if (currentAddress !== null) {
                addStepData({ name: 'pickupSelection', data: {} });
                addSubmitData({ name: 'pickup', data: currentAddress });
                goToStep(2);
            }
        }
    }

    submitStep = (locationData) => {
        const {
            goToStep, saveCustomerPickupLocation, addStepData, addSubmitData,
            formData: { addresses }
        } = this.props;
        locationData.state = locationData.state.value;
        return saveCustomerPickupLocation(locationData)
            .then((response) => {
                const nextStep = (locationData.default_dropoff || locationData.pickup_dropoff_same) ? 3 : 2;
                const pickupSelection = {
                    homeAddress: locationData.home_address === true,
                    defaultPickup: locationData.default_pickup === true,
                };
                if (locationData.default_dropoff || locationData.pickup_dropoff_same) {
                    addSubmitData({ name: 'dropoff', data: response.id });
                }
                addresses.push(response);
                addStepData({ name: 'addresses', data: addresses });
                addStepData({ name: 'pickupSelection', data: pickupSelection });
                addSubmitData({ name: 'pickup', data: response.id });
                goToStep(nextStep);
            });
    };

    render() {
        const {
            invalid, submitting, onCancelHandler,
            submitText, handleSubmit, formData, validationErrors: { zipCodeInvalid },
            handleAutocompleteSelectOrEnter
        } = this.props;
        const submitButtonClassNames = classNames({
            'button modal-dialog__button': true,
            'button_disabled': (invalid || submitting)
        });
        const {
            classInfo: {
                title, date, pickup_start_time, pickup_end_time, valid_zip_codes_array
            }
        } = formData;
        const pickupStart = formatTime(date, pickup_start_time);
        const pickupEnd = formatTime(date, pickup_end_time);
        const classDate = formatDate(date);
        return (
            <form
                className='pickup-location'
                onSubmit={handleSubmit((data) => this.submitStep(data.toJS()))}>
                <div className='form-step__form-content pickup-location__form-content'>
                    <div className='pickup-location__head'>
                        <p className='pickup-location__head-title'>You are booking <span>{title}</span>, scheduled on <span>{classDate}</span> with a pickup time between <span>{pickupStart}</span> and <span>{pickupEnd}</span>.</p>
                        <div className='pickup-location__head-subline'>
                            Please tell us where to pick up and dropoff your dog.
                            <div className='tooltip'>
                                Available Zip Codes for this Class
                                <div className='tooltip__content'>{valid_zip_codes_array.join(', ')}</div>
                            </div>
                        </div>
                    </div>

                    {/* Form Content */}
                    <Field
                        name='name'
                        type='text'
                        component={renderInputControl}
                        label='Nickname'/>
                    {zipCodeInvalid && <div className='input-control__error pickup-location__invalid-zip'>
                        {zipCodeInvalid}
                        <div className='tooltip'>
                            Available Zip Codes for this Class
                            <div className='tooltip__content'>{valid_zip_codes_array.join(', ')}</div>
                        </div>
                    </div>}
                    <Field
                        name='address1'
                        type='text'
                        component={renderPlacesAutocompleteControl}
                        handleSelectOrEnter={address => {
                            handleAutocompleteSelectOrEnter(address, 'PickupLocationSelectForm');
                        }}
                        label='Location'/>
                    <Field
                        name='address2'
                        type='text'
                        component={renderInputControl}
                        label='Location 2 (Apt or Unit number)'/>
                    <div className='form__aligner form__aligner_39-28-29'>
                        <Field
                            name='city'
                            type='text'
                            component={renderInputControl}
                            label='City'/>
                        <Field
                            name='state'
                            type='text'
                            component={renderSelectControl}
                            options={usStates}
                            className='select-control__is-state'
                            searchable
                            returnSelectValue
                            label='State'/>
                        <Field
                            name='zip_code'
                            type='text'
                            component={renderInputControl}
                            formError={zipCodeInvalid}
                            label='ZIP Code'/>
                    </div>

                    <div className='pickup-location__checkbox-group'>
                        <Field
                            name='pickup_dropoff_same'
                            type='checkbox'
                            label='My drop off address is the same.'
                            className='modal-dialog__purchase-checkbox'
                            parse={value => !!value}
                            component={renderCheckboxControl} />

                        <Field
                            name='home_address'
                            type='checkbox'
                            label='This is also my home address.'
                            className='modal-dialog__purchase-checkbox'
                            parse={value => !!value}
                            component={renderCheckboxControl}/>

                        <Field
                            name='default_pickup'
                            type='checkbox'
                            label='Make this my default pick up address.'
                            className='modal-dialog__purchase-checkbox'
                            parse={value => !!value}
                            component={renderCheckboxControl}/>

                        <Field
                            name='default_dropoff'
                            type='checkbox'
                            label='Make this my default drop off address.'
                            className='modal-dialog__purchase-checkbox'
                            parse={value => !!value}
                            component={renderCheckboxControl}/>
                    </div>
                </div>
                <div
                    className='modal-dialog__form-footer modal-dialog__form-footer_column modal-dialog__form-footer_booking'>
                    <button
                        className={submitButtonClassNames}
                        type='submit'
                        disabled={submitting}>
                        {(submitting)
                            ? 'Submitting...'
                            : submitText || 'Next'
                        }
                    </button>
                    <span className='modal-dialog__cancel-button' onClick={onCancelHandler}>Cancel</span>
                </div>
            </form>
        );
    }
}

PickupLocationSelect.propTypes = {
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    onCancelHandler: PropTypes.func,
    submitText: PropTypes.string,
    handleSubmit: PropTypes.func,
    addStepData: PropTypes.func,
    goToStep: PropTypes.func,
    formData: PropTypes.shape({}),
    saveCustomerPickupLocation: PropTypes.func,
    addSubmitData: PropTypes.func,
    validationErrors: PropTypes.shape({}),
    handleAutocompleteSelectOrEnter: PropTypes.func,
};

const form = reduxForm({
    form: 'PickupLocationSelectForm',
    fields: ['zip_code_values'],
    enableReinitialize: true,
    validate
});

const mapStateToProps = (state, ownProps) => {
    const { formData: { classInfo } } = ownProps;
    let validZipCodes = [];
    if (classInfo) {
        validZipCodes = classInfo.valid_zip_codes_array;
    }
    return {
        validationErrors: state.getIn(['form', 'PickupLocationSelectForm', 'syncErrors']) || {},
        initialValues: {
            'default_dropoff': true,
            'default_pickup': true,
            'zip_code_values': validZipCodes.toString()
        }
    };
};

const mapDispatchToProps = {
    saveCustomerPickupLocation,
    handleAutocompleteSelectOrEnter,
};

export default connect(mapStateToProps, mapDispatchToProps)(form(PickupLocationSelect));
