import React from 'react';
import { Field } from 'redux-form/immutable';

import DogReportVideo from '../../DogReportVideo';
import DogReportVideoInput from '../DogReportVideoInput';

const youtubeUrlParser = new RegExp('v=([^&]*)');
const youtubeUrlParserShortUrls = new RegExp('/(?!.*/)(.*)$');

const styles = {
  dogReportVideoButton: {
    margin: '0 auto',
  },
  dogReportVideoButtonWrapper: {
    padding: '5px 0',
  },
  dogReportVideoContainer: {
    background: '#98999D',
    marginBottom: 13,
  },
};

const getYouTubeVideoId = (videoUrl) => {
  let videoId;
  let parsedVideoUrl = youtubeUrlParser.exec(videoUrl);

  if (parsedVideoUrl) {
    videoId = parsedVideoUrl[1];
  } else {
    parsedVideoUrl = youtubeUrlParserShortUrls.exec(videoUrl);
    if (parsedVideoUrl) videoId = parsedVideoUrl[1];
  }
  
  return videoId;
}

const DogReportFormVideo = ({ input, onRemove, videoUrl }) => {
  input.onChange(videoUrl);

  const onClickRemove = () => {
    input.onChange(null);
    onRemove(videoUrl);
  }

  return (
    <div style={styles.dogReportVideoContainer}>
      <DogReportVideo videoUrl={videoUrl} />
      <div style={styles.dogReportVideoButtonWrapper}>
        <button
          className="button"
          style={styles.dogReportVideoButton}
          onClick={onClickRemove}
        >
          Remove Video
        </button>
      </div>
    </div>
  );
};

class DogReportVideos extends React.Component {
  constructor(props) {
    super(props);

    const { initialVideoUrls } = props;
    let videoUrls = [];
    if (initialVideoUrls && initialVideoUrls.length) {
      initialVideoUrls.forEach((videoUrlObj) => {
        videoUrls.push(videoUrlObj.snapshot_video_url);
      });
    }

    this.state = {
      videoError: null,
      videoUrls,
    };
  }

  addVideo = (videoUrl, successCallback, successCallbackArg) => {
    const { videoUrls } = this.state;
    const videoId = getYouTubeVideoId(videoUrl);

    if (!videoId) {
      const videoError = 'Video url is in an incorrect format';
      this.setState({ videoError });
      return;
    }

    const isDuplicate = videoUrls.find((videoUrl) => {
      const videoUrlId = getYouTubeVideoId(videoUrl);
      return videoUrlId === videoId;
    });

    if (isDuplicate) {
      const videoError = 'Video is already included in the report';
      this.setState({ videoError });
    } else {
      const videoUrlForEmbed = `https://www.youtube.com/embed/${videoId}`
      this.setState({
        videoError: null,
        videoUrls: [...videoUrls, videoUrlForEmbed],
      });
      successCallback(successCallbackArg);
    }
  }

  removeVideo = (videoUrlToRemove) => {
    const { videoUrls } = this.state;

    const filteredVideoUrls = videoUrls.filter((videoUrl) => {
      return videoUrl !== videoUrlToRemove;
    });

    this.setState({ videoUrls: filteredVideoUrls });
  }

  render() {
    const { videoError, videoUrls } = this.state;

    const videos = videoUrls.map((videoUrl, index) => (
        <Field
          key={`video_url_${index}`}
          name={`video_url_${index}`}
          component={DogReportFormVideo}
          onRemove={this.removeVideo}
          videoUrl={videoUrl}
        />
    ));

    return (
      <div>
        <Field
          type="input"
          component={DogReportVideoInput}
          label="Add video by entering url..."
          meta={{ error: videoError }}
          name="videoUrl"
          onAdd={this.addVideo}
          style={{ marginBottom: 13, marginTop: 40 }}/>
        {videos}
      </div>
    )
  }
}

export default DogReportVideos;
