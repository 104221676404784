import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as ScheduleTypes from '../types/customer/scheduleTypes';

const INITIAL_STATE = fromJS({
    items: [],
    count: null,
    total_count: null,
    trainings_total_count: null,
    classes_total_count: null,
    pageSize: null,
    nextUrl: null,
    previousUrl: null,
    isLoading: true
});

const scheduleListReducer = handleActions({
    [ScheduleTypes.SET_SCHEDULE]: (state, action) => {
        const {
            results,
            count,
            next,
            previous,
            total_count,
            classes_total_count,
            trainings_total_count
        } = action.payload;

        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);

        return state.update((state) => {
            return state
                .setIn(['items'], fromJS(results))
                .setIn(['count'], count)
                .setIn(['total_count'], total_count)
                .setIn(['trainings_total_count'], trainings_total_count)
                .setIn(['classes_total_count'], classes_total_count)
                .setIn(['pageSize'], results.length)
                .setIn(['nextUrl'], nextUrl)
                .setIn(['previousUrl'], previousUrl);
        });
    },
    [ScheduleTypes.SET_NEXT_SCHEDULE]: (state, action) => {
        const {
            results,
            count,
            next,
            previous,
            total_count,
            classes_total_count,
            trainings_total_count
        } = action.payload;

        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);

        return state.update((state) => {
            return state
                .setIn(['items'], state.get('items').concat(fromJS(results)))
                .setIn(['count'], count)
                .setIn(['total_count'], total_count)
                .setIn(['trainings_total_count'], classes_total_count)
                .setIn(['classes_total_count'], trainings_total_count)
                .setIn(['pageSize'], results.length)
                .setIn(['nextUrl'], nextUrl)
                .setIn(['previousUrl'], previousUrl);
        });
    },
    [ScheduleTypes.START_SCHEDULE_LOADING]: (state, action) => (
        state.setIn(['isLoading'], true)
    ),
    [ScheduleTypes.FINISH_SCHEDULE_LOADING]: (state, action) => (
        state.setIn(['isLoading'], false)
    ),
    [ScheduleTypes.REMOVE_SCHEDULE_EVENT]: (state, action) => {
        return state.update((state) => {
            return state.updateIn(['items'],
                items => items.filter(item => item.get('id') !== action.payload));
        });
    },
    [ScheduleTypes.UPDATE_SCHEDULE_LOCATION]: (state, action) => {
        const currentIndex = state.getIn(['items']).findKey(item => item.get('id') === action.payload.class_occurrence);
        return state.update((state) => {
            return state.setIn(['items', currentIndex, 'pickup_location'], action.payload.pickup_location_detail)
                        .setIn(['items', currentIndex, 'drop_off_location'], action.payload.drop_off_location_detail);
        });
    },
    [ScheduleTypes.REMOVE_DOG_FROM_EVENT]: (state, action) => {
        const currentIndex = state.getIn(['items']).findKey(item => item.get('id') === action.payload.eventId);
        return state.update((state) => {
            return state.updateIn(['items', currentIndex, 'products'],
                products => products.filter(product => product.get('dog') !== action.payload.dogId));
        });
    },
}, INITIAL_STATE);

export default scheduleListReducer;
