import propTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { ReactComponent as IconPaw } from '../../../assets/icons/icon-paw.svg';

const AjaxLoader = ({ modiferClassName = '' }) => {
    const className = classNames({
        'ajax-loader': true,
        [modiferClassName]: modiferClassName
    });
    return (
        <div className={className}>
            <div className='ajax-loader__list'>
                <div className='ajax-loader__paw'><IconPaw className='ajax-loader__icon'/></div>
                <div className='ajax-loader__paw'><IconPaw className='ajax-loader__icon'/></div>
                <div className='ajax-loader__paw'><IconPaw className='ajax-loader__icon'/></div>
                <div className='ajax-loader__paw'><IconPaw className='ajax-loader__icon'/></div>
            </div>
        </div>
    );
};

AjaxLoader.propTypes = {
    modiferClassName: propTypes.string
};

export default AjaxLoader;
