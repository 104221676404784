import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { formatDateToSlashes } from '../../../../../../helpers/date';
import { dataStates } from '../../../../../../helpers/state';

const AttendanceByZone = ({ items, dataState }) => {
    const renderTable = () => {
        const { rows, columns, data } = items;
        if (dataState === dataStates.loaded && items && columns.length) {
            const tableColumns = [
                {   // First column is always date
                    id: 'date',
                    Header: 'Date',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: 'date',
                    Cell: row => (<div>{formatDateToSlashes(row.value, true)}</div>),
                },
            ];

            columns.forEach((column, j) => {
                tableColumns.push({
                    id: `${column}_col`,
                    Header: column,
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.data[j]
                });
            });

            const normalizedData = [];
            rows.forEach((date, i) => {
                normalizedData.push({ date, data: data[i] });
            });

            return (
                <ReactTable
                    showPagination={false}
                    defaultPageSize={normalizedData.length}
                    data={normalizedData}
                    columns={tableColumns}/>);
        }
        return null;
    };

    return (
        <div className='custom-reports__report'>
            {items !== undefined && renderTable()}
        </div>
    );
};

AttendanceByZone.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    dataState: PropTypes.string.isRequired,
};

export default AttendanceByZone;
