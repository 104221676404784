import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionsTable from './TransactionsTable';
import {
    getCustomersNextPurchaseHistory,
    getCustomersPurchaseHistory
} from '../../../../../actions/dashboard/purchaseHistoryActions';

const TransactionsPage = ({ currentCustomer, getPurchaseHistory, getNextPurchaseHistory }) => {
    return (
        <div className='customers-transactions'>
            {currentCustomer.getIn(['instance', 'id']) === undefined
                ? 'Loading...'
                : (<TransactionsTable
                    isManual
                    autoHeight={true}
                    defaultPageSize={currentCustomer.getIn(['purchaseHistory', 'pageSize'])}
                    pages={currentCustomer.getIn(['purchaseHistory', 'pages'])}
                    count={currentCustomer.getIn(['purchaseHistory', 'count'])}
                    data={currentCustomer.getIn(['purchaseHistory', 'items'])}
                    dataName='Transaction History'
                    onSorting={value => getPurchaseHistory({
                        customerId: currentCustomer.getIn(['instance', 'id']),
                        queryString: `o=${value}`
                    })}
                    loading={currentCustomer.getIn(['purchaseHistory', 'isLoading'])}
                    nextUrl={currentCustomer.getIn(['purchaseHistory', 'nextUrl'])}
                    getNext={getNextPurchaseHistory}/>)
            }
        </div>
    );
};

TransactionsPage.propTypes = {
    currentCustomer: PropTypes.shape({}),
    getPurchaseHistory: PropTypes.func,
    getNextPurchaseHistory: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        currentCustomer: state.getIn(['currentCustomer']),
    };
};

const mapDispatchToProps = (dispatch) => ({
    getPurchaseHistory: bindActionCreators(getCustomersPurchaseHistory, dispatch),
    getNextPurchaseHistory: bindActionCreators(getCustomersNextPurchaseHistory, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPage);
