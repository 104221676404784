/* eslint-disable camelcase */

import PropTypes from 'prop-types';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import CampaignCard from './CampaignCard';
import Loader from '../common/Loader/Loader';

const propTypes = {
    isVisible: PropTypes.bool,
    openCampaignDeleteModal: PropTypes.func,
    gotoCampaignEdit: PropTypes.func,
    getNext: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape({})),
    dropdownItems: PropTypes.arrayOf(PropTypes.shape({})),
    nextUrl: PropTypes.string
};

const loader = (
    <Loader
        isVisible
        loadingText='Loading...'
        colorClassName='icon_loader-black'
        modifierClassName='calendar__loader'/>
);

const MarketingGrid = ({ data, dropdownItems, getNext, nextUrl }) => {
    const renderCards = () => {
        if (data.length) {
            return data.map((card, i) => {
                return (
                    <div className='campaign-card-wrapper' key={'card-' + i}>
                        <CampaignCard data={card} dropdownItems={dropdownItems}/>
                    </div>
                );
            });
        }
        return loader;
    };

    return (
        <div className='marketing-grid'>
            <div className='forms-table__header'>Marketing</div>
            <InfiniteScroll
                className='directory__content campaign-grid'
                pageStart={0}
                loadMore={getNext}
                hasMore={!!nextUrl}
                loader={loader}>

                {renderCards()}

            </InfiniteScroll>
        </div>
    );
};

MarketingGrid.propTypes = propTypes;
export default MarketingGrid;
