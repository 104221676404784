/* eslint-disable no-unneeded-ternary */
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const ActionModalDialog = ({ onSubmit, isSubmitting, data, modalError, onClose }) => {
    const { text, extraText, submittingText = 'Submitting', buttonText } = data;
    const buttonClassNames = classNames({
        'button modal-dialog__button': true,
        'modal-dialog__button_fee': buttonText,
        'button_disabled': isSubmitting
    });

    const handleSubmit = () => {
        onSubmit({ formData: data.updateData });
    };

    return (
        <div className='modal-dialog__form'>
            {
                text &&
                <p className='modal-dialog__text modal-dialog__text_bold'>
                    {text}
                </p>
            }
            {
                extraText &&
                <p className='modal-dialog__text'>
                    {extraText}
                </p>
            }
            {
                data.headline &&
                <div className='modal-dialog__headline'>
                    <div className='modal-dialog__headline-title'>{data.headline.title}</div>
                    <div className='modal-dialog__headline-subtitle'>{data.headline.subtitle}</div>
                </div>
            }
            {modalError !== null && <div className='form__error'>{modalError}</div>}
            <div className='modal-dialog__form-footer modal-dialog__form-footer_column'>
                <button
                    className={buttonClassNames}
                    disabled={isSubmitting}
                    onClick={handleSubmit}>
                    {isSubmitting ? submittingText : buttonText}
                </button>
                {data.hasCancel &&
                    <div className='modal-dialog__cancel' onClick={onClose}>
                        {data.cancelText || 'Cancel'}
                    </div>
                }
            </div>
        </div>
    );
};

ActionModalDialog.propTypes = {
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    isSubmitting: PropTypes.bool,
    data: PropTypes.shape({}),
    modalError: PropTypes.string,
};

export default ActionModalDialog;
