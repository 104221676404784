import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as Actions from './actions';

const propTypes = {
    model: PropTypes.shape({}),
    handleAlertEnqueue: PropTypes.func.isRequired,
    handleAlertRemove: PropTypes.func.isRequired,
};

const AlertWrapper = AlertQueue => {
    class AlertContainer extends PureComponent {
        render() {
            const { model, handleAlertRemove } = this.props;
            return (
                <div>
                    <AlertQueue
                        onAlertRemove={handleAlertRemove}
                        items={model.get('alerts')}/>
                    <div className='alert-trigger' onClick={this.dispatchTest}/>
                </div>
            );
        }
    }
    
    const mapStateToProps = state => {
        return {
            model: state.get('alert')
        };
    };
    
    const mapDispatchToProps = {
        handleAlertEnqueue: Actions.enqueueAlert,
        handleAlertRemove: Actions.removeAlert
    };
    
    AlertContainer.propTypes = propTypes;
    
    return connect(mapStateToProps, mapDispatchToProps)(AlertContainer);
};

export default AlertWrapper;
