import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as IconRemove21 } from '../../../assets/icons/icon-remove-21.svg';

const ModalDialog = ({
    isModalOpened,
    handleModalClosed,
    title,
    form,
    overflowShown = false,
    headerClassName,
    className
}) => {
    const modalDialogClassNames = classNames({
        'modal-dialog': true,
        'modal-dialog_active': isModalOpened,
        [className]: className
    });
    const modalDialogContentClassNames = classNames({
        'modal-dialog__content': true,
        'modal-dialog__content_overflow-visible': overflowShown
    });
    const headerClassNames = classNames({
        'modal-dialog__header': true,
        [headerClassName]: headerClassName
    });
    
    const handleCloseButtonClick = e => {
        e.preventDefault();
        handleModalClosed();
    };
    
    return (
        <div className={modalDialogClassNames} onClick={handleCloseButtonClick}>
            <div
                className={modalDialogContentClassNames}
                onClick={(e) => { e.stopPropagation(); }}>
                <div className={headerClassNames}>
                    {title && <div className='modal-dialog__title'>{title}</div>}
                    <button className='modal-dialog__close' onClick={handleCloseButtonClick}>
                        <IconRemove21 className='icon_remove_21'/>
                    </button>
                </div>
                {form}
            </div>
        </div>
    );
};

ModalDialog.propTypes = {
    isModalOpened: PropTypes.bool.isRequired,
    handleModalClosed: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    title: PropTypes.string,
    headerClassName: PropTypes.string,
    className: PropTypes.string,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    form: PropTypes.node.isRequired,
    overflowShown: PropTypes.bool
};

export default ModalDialog;
