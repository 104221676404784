import React, { PureComponent } from 'react';
import { Field } from 'redux-form/immutable';
import creditCardType from 'credit-card-type';
import classNames from 'classnames';
import renderInputControl from '../../../common/Form/InputControl';
import renderSelectControl from '../../../common/Form/SelectControl/SelectControl';
import * as options from '../../../../helpers/options';
import * as ValidatorFactory from '../../../../helpers/validate';
import Icon from '../../../common/Icon/Icon';
import { ReactComponent as IconAmericanExpress } from '../../../../assets/icons/icon-american-express.svg';
import { ReactComponent as IconMasterCard } from '../../../../assets/icons/icon-master-card.svg';
import { ReactComponent as IconNeutralCard } from '../../../../assets/icons/icon-neutral-card.svg';
import { ReactComponent as IconVisa } from '../../../../assets/icons/icon-visa.svg';

class CreditCardControl extends PureComponent {
    state = {
        cardNumberValue: ''
    }

    render() {
        const { cardNumberValue } = this.state;
        // creditCardType returns an array of matches
        const creditCardInfo = creditCardType(cardNumberValue.substr(0, 2))[0];
        let cardType;
        let cardNumberMask;
        let cardCodeSize;

        if (cardNumberValue && creditCardInfo) {
            cardType = creditCardInfo.type;
            cardCodeSize = creditCardInfo.code.size;
        }
        if (cardType === 'american-express') {
            cardNumberMask = '9999 999999 99999';
        } else {
            cardNumberMask = '9999 9999 9999 9999';
        }
        const visaClassNames = classNames({
            'card-detection__item card-detection__item_visa': true,
            'card-detection__item_detected': cardType === 'visa'
        });
        const masterClassNames = classNames({
            'card-detection__item card-detection__item_master': true,
            'card-detection__item_detected': cardType === 'master-card'
        });
        const expressClassNames = classNames({
            'card-detection__item card-detection__item_express': true,
            'card-detection__item_detected': cardType === 'american-express'
        });
        const nonameClassNames = classNames({
            'card-detection__item card-detection__item_noname': true,
            'card-detection__item_detected':
                cardType !== 'american-express' &&
                cardType !== 'master-card' &&
                cardType !== 'visa' &&
                (cardType || (cardNumberValue && cardNumberValue.length >= 2))
        });
        return (
            <div>
                <div className='modal-dialog__card-detection'>
                    <Icon glyph={IconVisa} className={visaClassNames} />
                    <Icon glyph={IconMasterCard} className={masterClassNames} />
                    <Icon glyph={IconAmericanExpress} className={expressClassNames} />
                    <Icon glyph={IconNeutralCard} className={nonameClassNames} />
                </div>
                <Field
                    name='number'
                    type='text'
                    isMasked
                    mask={cardNumberMask}
                    component={renderInputControl}
                    onChange={(e) => {
                        this.setState({
                            cardNumberValue: e.target.value
                        });
                    }}
                    label='Credit Card Number'
                    validate={[
                        ValidatorFactory.requiredValidator('Credit Card Number'),
                        ValidatorFactory.creditCardValidator(),
                    ]}/>
                <div className='form__aligner form__aligner_50-50'>
                    <Field
                        name='expiration_month'
                        type='text'
                        component={renderSelectControl}
                        options={options.expirationMonth}
                        label='Exp Date (MM)'
                        validate={[ValidatorFactory.requiredValidatorSelect('Expiration Date (MM)')]} />
                    <Field
                        name='expiration_year'
                        type='text'
                        component={renderSelectControl}
                        options={options.expirationYear}
                        label='Exp Date (YY)'
                        validate={[ValidatorFactory.requiredValidatorSelect('Expiration Date (YY)')]} />
                </div>
                <Field
                    name='cvc'
                    type='text'
                    isMasked
                    mask={cardCodeSize === 4 ? '9999' : '999'}
                    validate={[
                        ValidatorFactory.requiredValidator('Security Code'),
                        ValidatorFactory.exactTextValueValidator(
                            'Security Code',
                            cardCodeSize !== undefined ? cardCodeSize : 3
                        )
                    ]}
                    component={renderInputControl}
                    label='Security code' />
            </div>
        );
    }
}

export default CreditCardControl;
