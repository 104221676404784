import React from 'react';
import PropTypes from 'prop-types';

const SearchHighlighter = ({ searchResult, searchString }) => {
    const searchStringsArray = searchString.split(' ').reduce((resultArray, searchStringItem) => {
        if (searchStringItem.length > 0) {
            resultArray.push(searchStringItem);
        }
        return resultArray;
    }, []);
    const findsArray = [];
    const highlightedStringArray = [];
    const checkData = new RegExp(searchStringsArray.join('|'), 'gi');
    let match;
    const checkFunction = () => {
        match = checkData.exec(searchResult);
        return match;
    };
    while (checkFunction()) {
        if (findsArray.length === 0) {
            highlightedStringArray.push(searchResult.substring(0, match.index));
        } else if (findsArray.length > 0) {
            highlightedStringArray.push(searchResult.substring(findsArray[findsArray.length - 1].end, match.index));
        }
        highlightedStringArray.push(
            <b key={searchResult + '-' + match[0] + '-' + match.index}>
                {match[0]}
            </b>,
        );
        findsArray.push({
            start: match.index,
            end: checkData.lastIndex,
            string: match[0],
        });
    }

    if (match === null) {
        if (findsArray.length > 0) {
            highlightedStringArray.push(
                searchResult.substring(findsArray[findsArray.length - 1].end, searchResult.length),
            );
        } else {
            highlightedStringArray.push(searchResult);
        }
    }
    return (
        <span>
            {highlightedStringArray.map(item => {
                return item;
            })}
        </span>
    );
};

SearchHighlighter.propTypes = {
    searchResult: PropTypes.string,
    searchString: PropTypes.string,
};

export default SearchHighlighter;
