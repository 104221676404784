import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

import { ReactComponent as IconCancel } from '../../../assets/icons/icon-cancel.svg';
import { ReactComponent as IconCancelFee } from '../../../assets/icons/icon-cancel-fee.svg';
import { ReactComponent as IconCheckMark } from '../../../assets/icons/icon-checkmark.svg';
import { ReactComponent as IconNoShow } from '../../../assets/icons/icon-no-show.svg';

const AttendedIcon = ({ status }) => {
    switch (status) {
        case 0: // No info
            return <div className='no-info'>—</div>;
        case 1: // Attended
            return <Icon glyph={IconCheckMark} className='icon_check-mark'/>;
        case 2: // Cancelled, not used with extended_status
            return <Icon glyph={IconCancel} className='icon_cancel'/>;
        case 3: // No show
            return <Icon glyph={IconNoShow} className='icon_no-show'/>;
        case 4: // Cancelled before penalty window
        case 5: // Cancelled after penalty window, fee waived
            return <Icon glyph={IconCancel} className='icon_cancel'/>;
        case 6: // Cancelled, penalty enforced
            return <Icon glyph={IconCancelFee} className='icon_cancel-fee'/>;
        default:
            return <div>??</div>;
    }
};

AttendedIcon.propTypes = {
    status: PropTypes.number
};

export default AttendedIcon;
