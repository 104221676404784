import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { bindActionCreators } from 'redux';
import { openImmunizationExpirationDatesModal } from '../../../../../actions/miscActions';
import * as ModalOpenActions from '../../../../../actions/modal/openActions';
import { ReactComponent as IconEdit } from '../../../../../assets/icons/icon-edit.svg';
import { formatDateToSlashes, getAgeBirthdayString } from '../../../../../helpers/date';
import { capitalizeEachWord } from '../../../../../helpers/text';
import { isTrainer } from '../../../../../helpers/userRoles';
import { prettifyPhone } from '../../../../../helpers/normalize';
import ContentCard from '../../../../common/ContentCard';

/* eslint-disable no-unneeded-ternary */
class DetailsPage extends React.PureComponent {
    static propTypes = {
        dog: PropTypes.shape({}),
        vaccinationRecords: PropTypes.shape({}),
        isLoading: PropTypes.bool.isRequired,
        openDogInfoEditModalDialog: PropTypes.func,
        openDogMedicalNotesEditModalDialog: PropTypes.func,
        handleImmunizationModalOpened: PropTypes.func.isRequired,
        openDogIntoleranceListEditModalDialog: PropTypes.func,
        openDogVeterinarianEditModalDialog: PropTypes.func
    };

    getOwnerPhone(dogJS) {
        let primaryPhone = null;
        const hasPhones = get(dogJS, 'owner_detail.primary_phone') && get(dogJS, 'owner_detail.available_phones', []).length;
        if (hasPhones) {
            primaryPhone = dogJS.owner_detail.available_phones
            .find((phone) => {
                return phone.id === dogJS.owner_detail.primary_phone;
            }) || null;
        } else if (dogJS.owner_detail.available_phones.length) {
            primaryPhone = dogJS.owner_detail.available_phones[0];
        }
        return primaryPhone;
    }

    getVaccinations(dogJS) {
        const { vaccinationRecords } = this.props;
        const vaccinationRecordsJS = vaccinationRecords.toJS();

        if (vaccinationRecordsJS.isLoading) {
            return [
                {
                    key: 'vaccination_' + dogJS.id,
                    data: 'Loading...'
                }
            ];
        }

        const getMissingItem = (type) => {
            return {
                key: `vaccination_${dogJS.id}_${type}`,
                header: type,
                data: [
                    {
                        fieldName: 'Expiration date',
                        notSetMessage: 'None Entered'
                    },
                    {
                        fieldName: 'Updated by',
                        value: 'N/A'
                    },
                    {
                        fieldName: 'Last updated',
                        value: 'N/A'
                    }
                ]
            };
        };

        return vaccinationRecordsJS.choices.map((type) => {
            const item = vaccinationRecordsJS.items.find((item) => {
                return item.immunization_type_detail === type;
            });

            if (!item) {
                return getMissingItem(type);
            }

            return {
                key: `vaccination_${dogJS.id}_${item.immunization_type_detail}`,
                header: item.immunization_type_detail,
                data: [
                    {
                        fieldName: 'Expiration date',
                        value: item.expiration_date ? formatDateToSlashes(item.expiration_date) : null,
                        notSetMessage: 'None Entered',
                        isValid: !item.expired,
                        invalidMessage: `${formatDateToSlashes(item.expiration_date)} Expired`
                    },
                    {
                        fieldName: 'Updated by',
                        value: item.updated_by_detail,
                        isValid: true
                    },
                    {
                        fieldName: 'Last updated',
                        value: formatDateToSlashes(item.modified_at),
                        isValid: true
                    }
                ]
            };
        });
    }

    getIntolerances(dogJS) {
        return (dogJS && dogJS.intolerance_list && dogJS.intolerance_list.length) ? [
            {
                key: 'intolerances_' + dogJS.id,
                data: capitalizeEachWord(dogJS.intolerance_list.join(', '))
            }
        ] : [
            {
                key: 'vaccination_' + dogJS.id,
                data: 'None'
            }
        ];
    }

    getData() {
        const {
            isLoading, dog, handleImmunizationModalOpened, openDogInfoEditModalDialog,
            openDogMedicalNotesEditModalDialog, vaccinationRecords, openDogIntoleranceListEditModalDialog,
            openDogVeterinarianEditModalDialog
        } = this.props;

        if (isLoading) {
            return <div>Loading...</div>;
        }

        if (!dog) {
            return null;
        }

        const dogJS = dog.toJS();
        if (!dogJS.owner_detail) {
            return null;
        }

        const ownerPhone = this.getOwnerPhone(dogJS);
        return (
            <div className='details'>
                <div className='details__wrapper'>
                    <div className='details__column'>
                        <ContentCard
                            readOnly={isTrainer()}
                            actionIcon={IconEdit} 
                            actionOnClick={() => openDogInfoEditModalDialog(dog)}
                            actionClassName='icon_card-edit'
                            header='Dog Info'
                            items={[
                                {
                                    key: 'breed_' + dogJS.id,
                                    header: 'Breed',
                                    data: dogJS.breed_detail
                                },
                                {
                                    key: 'weight_' + dogJS.id,
                                    header: 'Weight',
                                    data: dogJS.weight_detail
                                },
                                {
                                    key: 'markings_' + dogJS.id,
                                    header: 'Markings',
                                    data: dogJS.markings || 'None'
                                },
                                {
                                    key: 'birthday_' + dogJS.id,
                                    header: 'Age/Birthday Month',
                                    data: getAgeBirthdayString(dogJS.birthday)
                                },
                                {
                                    key: 'gender_' + dogJS.id,
                                    header: 'Gender',
                                    data: dogJS.gender_detail
                                },
                                {
                                    key: 'is_altered' + dogJS.id,
                                    header: 'Spayed/Neutered',
                                    data: dogJS.is_altered ? 'Yes' : 'No'
                                }
                            ]}/>
                        <ContentCard
                            readOnly={isTrainer()}
                            header='Allergies or Intolerances'
                            actionIcon={IconEdit} 
                            actionOnClick={() => openDogIntoleranceListEditModalDialog(dog)}
                            actionClassName='icon_card-edit'
                            items={this.getIntolerances(dogJS)}/>
                        <ContentCard
                            readOnly={isTrainer()}
                            header='Owner'
                            items={[
                                {
                                    key: 'owner_' + dogJS.id,
                                    data: dogJS.owner_detail.full_name,
                                    dataUrl: `/dashboard/customer/${dogJS.owner}/details`,
                                    info: ownerPhone ? prettifyPhone(ownerPhone.phone_number) : 'None',
                                    infoUrl: ownerPhone ? `tel:${ownerPhone.phone_number}` : null
                                }
                            ]}/>
                    </div>
                    <div className='details__column'>
                        <ContentCard
                            readOnly={isTrainer()}
                            actionIcon={IconEdit} 
                            actionOnClick={() => openDogVeterinarianEditModalDialog(dog)}
                            actionClassName='icon_card-edit'
                            header='Veterinarian Information'
                            items={[
                                {
                                    key: 'vet_' + dogJS.id,
                                    data: dogJS.veterinarian_detail ? dogJS.veterinarian_detail.name : 'N/A',
                                    info: (dogJS.veterinarian_detail && dogJS.veterinarian_detail.phone)
                                        ? dogJS.veterinarian_detail.phone.phone_number
                                        : 'None',
                                    infoUrl: (dogJS.veterinarian_detail && dogJS.veterinarian_detail.phone)
                                        ? `tel:${dogJS.veterinarian_detail.phone.phone_number}`
                                        : null
                                }
                            ]}/>
                        <ContentCard
                            readOnly={isTrainer()}
                            actionIcon={IconEdit} 
                            actionOnClick={() => openDogMedicalNotesEditModalDialog(dog)}
                            actionClassName='icon_card-edit'
                            header='Medical Notes'
                            items={[
                                {
                                    key: 'mednotes_' + dogJS.id,
                                    data: dogJS.medical_notes ? dogJS.medical_notes : 'N/A'
                                }
                            ]}/>
                        {!isTrainer() &&
                            <ContentCard
                                readOnly={isTrainer()}
                                actionIcon={IconEdit} 
                                actionOnClick={() => handleImmunizationModalOpened(vaccinationRecords)}
                                actionClassName='icon_card-edit'
                                header='Vaccinations'
                                items={this.getVaccinations(dogJS)}/>
                        }
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return <div>{this.getData()}</div>;
    }
}

const mapStateToProps = state => {
    return {
        dog: state.getIn(['currentDog', 'instance']),
        vaccinationRecords: state.getIn(['currentDog', 'vaccination_records']),
        isLoading: state.getIn(['currentDog', 'isLoading'])
    };
};

const mapDispatchToProps = dispatch => ({
    openDogInfoEditModalDialog: bindActionCreators(ModalOpenActions.openDogInfoEditModalDialog, dispatch),
    openDogMedicalNotesEditModalDialog: bindActionCreators(
        ModalOpenActions.openDogMedicalNotesEditModalDialog, dispatch),
    handleImmunizationModalOpened: bindActionCreators(openImmunizationExpirationDatesModal, dispatch),
    openDogIntoleranceListEditModalDialog: bindActionCreators(
        ModalOpenActions.openDogIntoleranceListEditModalDialog, dispatch),
    openDogVeterinarianEditModalDialog: bindActionCreators(
        ModalOpenActions.openDogVeterinarianEditCreateTabModalDialog, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailsPage);
