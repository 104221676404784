import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { find } from 'lodash';
import { connect } from 'react-redux';
import { setSmsVerificationModalClosed } from '../../../../actions/miscActions';
import ModalDialog from '../../../common/ModalDialog';
import SmsVerificationModalDialogForm from './SmsVerificationModalDialogForm';

class SmsVerificationModalDialog extends PureComponent {
    render() {
        const { isSmsVerificationModalOpened, setSmsVerificationModalClosed, customerPhones,
                customerPrimaryPhoneId } = this.props;
        let customerPrimaryPhone = null;
        if (customerPrimaryPhoneId && customerPhones.length) {
            customerPrimaryPhone = find(customerPhones, (phone) => phone.id === customerPrimaryPhoneId);
        }
        return (
            <ModalDialog
                form={<SmsVerificationModalDialogForm
                    customerPrimaryPhone={customerPrimaryPhone}
                    onClose={setSmsVerificationModalClosed}/>}
                headerClassName='modal-dialog__header_transparent'
                isModalOpened={isSmsVerificationModalOpened}
                handleModalClosed={setSmsVerificationModalClosed}/>
        );
    }
}

SmsVerificationModalDialog.propTypes = {
    isSmsVerificationModalOpened: PropTypes.bool,
    setSmsVerificationModalClosed: PropTypes.func,
    customerPhones: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
    customerPrimaryPhoneId: PropTypes.number
};

const mapStateToProps = state => ({
    isSmsVerificationModalOpened: state.getIn(['misc', 'modals', 'smsVerification']),
    customerPhones: state.getIn(['user', 'available_phones']),
    customerPrimaryPhoneId: state.getIn(['user', 'phoneId', 'id'])
});

const mapDispatchToProps = {
    setSmsVerificationModalClosed,
};

export default connect(mapStateToProps, mapDispatchToProps)(SmsVerificationModalDialog);
