import React from 'react';
import moment from 'moment';
import Table from '../../../../../../common/Table';
import { getDaysInt } from '../../../../../../../helpers/date';

class MyPackagesTable extends Table {

    render() {
        const columns = [
            {
                Header: 'Name',
                headerClassName: 'simple-table__th',
                className: 'simple-table__td',
                id: 'name',
                sortable: false,
                accessor: d => {
                    return d.getIn(['package_detail', 'name']);
                },
                Cell: row => {
                    return (
                        <div className='simple-table__cell'>
                            <div className='simple-table__cell-title'>Name</div>
                            <div className='simple-table__cell-content'>{row.value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Purchased',
                headerClassName: 'simple-table__th',
                className: 'simple-table__td',
                id: 'purchased',
                sortable: false,
                accessor: d => d.getIn(['quantity_purchased']),
                Cell: row => {
                    return (
                        <div className='simple-table__cell'>
                            <div className='simple-table__cell-title'>Purchased</div>
                            <div className='simple-table__cell-content'>{row.value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Remaining',
                headerClassName: 'simple-table__th',
                className: 'simple-table__td',
                id: 'remaining',
                sortable: false,
                accessor: d => d.get('available_quantity'),
                Cell: row => {
                    return (
                        <div className='simple-table__cell'>
                            <div className='simple-table__cell-title'>Remaining</div>
                            <div className='simple-table__cell-content'>{row.value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Expires',
                headerClassName: 'simple-table__th',
                className: 'simple-table__td',
                id: 'expires',
                sortable: false,
                accessor: d => d.getIn(['package_detail', 'expires_after']),
                Cell: row => {
                    return (
                        <div className='simple-table__cell'>
                            <div className='simple-table__cell-title'>Expires</div>
                            <div className='simple-table__cell-content'>
                                {moment().add(row.value ? getDaysInt(row.value) : 0, 'day').format('MM/DD/YY')}
                            </div>
                        </div>
                    );
                },
            },
        ];
        return (
            <div className={'simple-table'}>
                {this.renderTable({ columns })}
            </div>
        );
    }
}

export default MyPackagesTable;
