/* eslint-disable camelcase */

import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Icon from '../../../common/Icon/';
import { ReactComponent as IconCar } from '../../../../assets/icons/icon-car.svg';
import { ReactComponent as IconLocation } from '../../../../assets/icons/icon-location.svg';
import { ReactComponent as IconTrainer } from '../../../../assets/icons/icon-trainer-default.svg';
import { openCancelScheduleEventModalDialog, openRemoveDogFromScheduleModalDialog } from '../../../../actions/modal/openActions';
import Tabs from '../../../common/Tabs/';
import Tab from '../../../common/Tabs/Tab/';
import AvatarList from './AvatarList/';
import PickupDropoffAdresses from './PickupDropoffAdresses/';
import { normalizeScheduleEvent } from '../../../../helpers/normalizeScheduleEvent';

const EditScheduleModalSidebar = (props) => {
    const {
        openCancelScheduleEventModalDialog,
        openRemoveDogFromScheduleModalDialog,
        scheduleList,
        upcomingClassesList,
        upcomingClassesLoaded
    } = props;
    const eventIndex = scheduleList.findIndex((scheduleItem) => {
        return scheduleItem.id === props.data.eventId;
    });
    if (eventIndex === -1) return null;

    const scheduleItem = scheduleList[eventIndex];
    scheduleItem.class_occurrence_detail = null;

    if (upcomingClassesList && upcomingClassesList.length && upcomingClassesList[eventIndex]) {
        scheduleItem.class_occurrence_detail = upcomingClassesList[eventIndex].class_occurrence_detail;
    }

    const data = normalizeScheduleEvent(scheduleItem);
    const { eventName, trainerFullName, start, end, dogs, dayOfWeek, day } = data;
    let { eventLocation } = data;

    if (!eventLocation) {
        if (upcomingClassesLoaded) {
            eventLocation = 'Not Set';
        } else {
            eventLocation = 'Loading...';
        }
    }

    const startTime = moment(data.event.start);

    const cancelClassHandler = () => {
        const cancelText = startTime.diff(moment(), 'hours') >= 24
            ?
            (<span> Are you sure you want to cancel <b>{eventName}</b> on <b>{dayOfWeek}</b> <b>{day}</b>? That will cancel the class for all dogs that are signed up. </span>)
            :
            (<>
                <span> Reservations cancelled <b>within 24 hour</b> are subject to <b>full rates</b>.</span><br />
                <span> Are you sure you want to cancel <b>{eventName}</b> on <b>{dayOfWeek}</b> <b>{day}</b>? That will cancel the class for all dogs that are signed up. </span>
            </>)

        openCancelScheduleEventModalDialog({
            cancelText,
            id: data.id
        });
    };

    const removeDogHandler = (dog) => {
        const titleText = `Cancel ${dog.name} Reservation`;
        const removeText = startTime.diff(moment(), 'hours') >= 24
            ?
            (<span>Are you sure you want to remove <b>{dog.name}</b> from <b>{eventName}</b> on <b>{dayOfWeek}</b> <b>{day}</b>?</span>)
            :
            (<>
                <span> Reservations cancelled <b>within 24 hour</b> are subject to <b>full rates</b>. </span><br />
                <span>Are you sure you want to remove <b>{dog.name}</b> from <b>{eventName}</b> on <b>{dayOfWeek}</b> <b>{day}</b>?</span>
            </>)

        const { productId, id } = dog;
        openRemoveDogFromScheduleModalDialog({
            titleText,
            removeText,
            productId,
            id,
            eventId: data.id
        });
    };

    return (
        <div className='modal-dialog__wrap'>

            <section className='modal-dialog__body'>
                <div className='schedule-card schedule-card_modal'>
                    <div className='schedule-card__date'>
                        <div className='schedule-card__day-of-week'>{dayOfWeek}</div>
                        <div className='schedule-card__day'>{day}</div>
                    </div>
                    <div className='schedule-card__content'>
                        {
                            eventLocation &&
                            <div className='schedule-card__content-item'>
                                <span className='schedule-card__location'><Icon glyph={IconLocation} />{eventLocation}</span>
                            </div>
                        }
                        <div className='schedule-card__content-item'>
                            <span className='schedule-card__trainer'><Icon glyph={IconTrainer} />{trainerFullName}</span>
                        </div>
                        <div className='schedule-card__content-item'>
                            <span className='schedule-card__time'>
                                <Icon glyph={IconCar} />
                                <span className='schedule-card__time-item'>{start.text}: {start.time}</span>
                                <span className='schedule-card__time-item'>{end.text}: {end.time}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </section>

            <section className='modal-dialog__section'>
                <div className='modal-dialog__section-title'>Cancel class</div>
                <div className='modal-dialog__section-text'>If you have more than one dog, cancelling the class here will cancel the class for all of your dogs. Click on dogs to cancel individual dogs from the class.</div>
                <div
                    className='modal-dialog__delete'
                    onClick={cancelClassHandler}>
                    Cancel Class
                </div>
            </section>

            <Tabs>
                <Tab title='Pickup and dropoff'>
                    <PickupDropoffAdresses data={data} />
                </Tab>
                <Tab title='Dogs'>
                    <AvatarList
                        dogs={dogs}
                        handleRemoveDog={removeDogHandler} />
                </Tab>
            </Tabs>
        </div>
    );
};

EditScheduleModalSidebar.propTypes = {
    openCancelScheduleEventModalDialog: PropTypes.func,
    openRemoveDogFromScheduleModalDialog: PropTypes.func,
    data: PropTypes.shape({
        eventId: PropTypes.number,
    }),
    scheduleList: PropTypes.arrayOf(PropTypes.shape({})),
    upcomingClassesList: PropTypes.arrayOf(PropTypes.shape({})),
    upcomingClassesLoaded: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        scheduleList: state.getIn(['schedule', 'items']).toJS(),
        upcomingClassesList: state.getIn(['currentCustomer', 'upcoming_classes', 'classes']).toJS(),
        upcomingClassesLoaded: state.getIn(['currentCustomer', 'upcoming_classes', 'areLoaded']),
    };
};

const mapDispatchToProps = {
    openCancelScheduleEventModalDialog,
    openRemoveDogFromScheduleModalDialog
};

export default connect(mapStateToProps, mapDispatchToProps)(EditScheduleModalSidebar);
