/* eslint-disable react/prop-types */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as FormActions from '../../../../actions/form/formActions';
import * as MessagesActions from '../../../../actions/messagesActions';
import { isMobileDevice } from '../../../../helpers/device';
import * as MessagesServices from '../../../../services/messages';
import { mapFormToState } from '../../FormContainer/index';
import MessagesWidget from './MessagesWidget';
import { dataStates, displayStates } from '../../../../helpers/state';

export const isDesktopWidth = window.innerWidth > 1024;

class MessagesContainer extends PureComponent {
    static propTypes = {
        messages: PropTypes.shape({}).isRequired,
        closeMessagesWidget: PropTypes.func.isRequired,
        minimizeMessagesWidget: PropTypes.func.isRequired,
        openMessagesWidget: PropTypes.func.isRequired,
        getMessagesData: PropTypes.func.isRequired,
        getNextMessagesData: PropTypes.func.isRequired,
        submit: PropTypes.func.isRequired,
    };

    state = {
        bottomPosition: window.pageYOffset,
        initScrollHeight: 0,
    };

    componentDidMount() {
        window.addEventListener('scroll', _.debounce(this.scrollListener, 100));
    }

    componentWillReceiveProps(nextProps) {
        const { messages, getMessagesData } = this.props;
        const { loading, loaded, loadingMore, submitting, polling } = dataStates;
        const messagesBody = document.querySelector('.messages__body');
        const nextCustomersId = nextProps.messages.get('customersId');
        const currentDataState = messages.get('dataState');
        const nextDataState = nextProps.messages.get('dataState');
        const isLoaded = nextDataState === loaded;

        const isInitialLoaded = currentDataState === loading && isLoaded;
        const isMoreLoaded = currentDataState === loadingMore && isLoaded;
        const isSubmitted = currentDataState === submitting && isLoaded;
        const isPolling = currentDataState === polling && isLoaded;
        const isPolledSuccessful = currentDataState === polling &&
            messages.get('items') !== nextProps.messages.get('items');
        const isClosed = nextProps.messages.get('displayState') === displayStates.closed;

        if (nextCustomersId !== null && nextCustomersId !== messages.get('customersId')) {
            getMessagesData({ id: nextCustomersId });
        }

        if (isInitialLoaded || isSubmitted || isMoreLoaded || isPolling) {
            clearTimeout(this.timeout);
            this.pollMessages();
        }

        // Scroll to the bottom of messages body when data is loaded
        if (isInitialLoaded || isSubmitted || isPolledSuccessful) {
            setTimeout(() => {
                messagesBody.scrollTop = messagesBody.scrollHeight;
                this.setState({ initScrollHeight: messagesBody.scrollHeight });
            }, 600);
        }

        if (isMoreLoaded) {
            setTimeout(() => {
                messagesBody.scrollTop = messagesBody.scrollHeight - this.state.initScrollHeight - 220;
                this.setState({ initScrollHeight: messagesBody.scrollHeight });
            }, 600);
        }

        if (isClosed) {
            clearTimeout(this.timeout);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollListener);
        // this one actually doesn't work
        clearTimeout(this.timeout);
    }

    pollMessages = () => {
        const { getMessagesData, messages } = this.props;
        this.timeout = setTimeout(() => getMessagesData({ id: messages.get('customersId'), isPolling: true }), 10000);
    };

    scrollListener = () => {
        this.setState({ bottomPosition: window.pageYOffset });
    };

    render() {
        const messagesJS = this.props.messages.toJS();
        const { displayState } = messagesJS;
        const { closed, minimized } = displayStates;
        const transformValue = displayState !== closed
            ? `translateY(${this.state.bottomPosition}px)`
            : 'translateY(100%)';
        const widgetHeight = displayState !== minimized ? 361 : 50;
        const topValue = displayState !== closed
            ? window.innerHeight - widgetHeight
            : window.innerHeight + window.pageYOffset - widgetHeight;
        const desktopStyle = !isMobileDevice() && isDesktopWidth
            ? {
                top: topValue,
                transform: transformValue,
            }
            : { top: window.pageYOffset };
        return (
            <MessagesWidget
                {...this.props}
                messages={messagesJS}
                style={desktopStyle}/>
        );
    }
}

const mapStateToProps = state => {
    return {
        messages: state.get('messages'),
        formState: mapFormToState(['messagesWidgetForm'], state),
    };
};

const mapDispatchToProps = {
    openMessagesWidget: MessagesActions.setMessagesWidgetOpened,
    closeMessagesWidget: MessagesActions.setMessagesWidgetClosed,
    minimizeMessagesWidget: MessagesActions.setMessagesWidgetMinimized,
    getMessagesData: MessagesServices.getMessagesData,
    getNextMessagesData: MessagesServices.getNextMessagesData,
    submit: FormActions.dispatchSubmit,
    getMessageData: MessagesServices.getMessageDataById,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesContainer);
