import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import StepFormWrapper from '../StepFormWrapper';
import { goToStep, clearFormData, addSubmitData, addStepData, initializeForm } from '../../../actions/steppedFormActions';
import { getSteppedFormValues } from '../../../reducers/steppedFormReducer';

class SteppedForm extends PureComponent {

    componentWillMount() {
        const { initialStep, currentStep, initializeForm, initialData, name } = this.props;
        let nextStep = 0;
        if (initialStep || currentStep) {
            nextStep = initialStep && !currentStep ? initialStep : currentStep;
        }
        initializeForm({
            name,
            currentStep: nextStep,
            data: initialData || {}
        });
    }

    componentWillUnmount() {
        const { name, clearFormData } = this.props;
        clearFormData(name);
    }

    getCurrentStep = () => {
        const { items, currentStep, onCancelHandler, data, submitData,
                goToStep, addSubmitData, addStepData, name, clearFormData } = this.props;
        const currStepProps = items && items.length ? items[currentStep] : null;
        return currStepProps
            ? <StepFormWrapper
                {...currStepProps}
                goToStep={goToStep.bind(this, name)}
                addSubmitData={addSubmitData.bind(this, name)}
                addStepData={addStepData.bind(this, name)}
                onCancelHandler={onCancelHandler}
                clearFormData={clearFormData.bind(this, name)}
                formData={data}
                formName={name}
                submitData={submitData}/>
            : null;
    }

    render() {
        const { className } = this.props;
        return (
            <section className={classNames(['stepped-form', className])}>
                {this.getCurrentStep()}
            </section>
        );
    }
}

SteppedForm.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})),
    initialStep: PropTypes.number,
    currentStep: PropTypes.number,
    goToStep: PropTypes.func,
    onCancelHandler: PropTypes.func,
    clearFormData: PropTypes.func,
    addSubmitData: PropTypes.func,
    addStepData: PropTypes.func,
    className: PropTypes.string,
    data: PropTypes.shape({}),
    submitData: PropTypes.shape({}),
    initializeForm: PropTypes.func,
    isDataLoaded: PropTypes.bool,
    name: PropTypes.string.isRequired,
    initialData: PropTypes.shape({})
};

const mapStateToProps = (state, ownProps) => {
    const formValues = getSteppedFormValues(ownProps.name)(state);
    return {
        currentStep: formValues && formValues.currentStep ? formValues.currentStep : 0,
        data: formValues && formValues.data ? formValues.data : {},
        submitData: formValues && formValues.submitData ? formValues.submitData : {}
    };
};

const mapDispatchToProps = {
    goToStep,
    clearFormData,
    addSubmitData,
    addStepData,
    initializeForm
};
export default connect(mapStateToProps, mapDispatchToProps)(SteppedForm);
