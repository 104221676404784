import React from 'react';
import { ReactComponent as IconFB } from '../../assets/icons/facebook.svg';
import Icon from '../common/Icon';

const ViewOnFacebook = () => {
    return (
        <div className='campaign-weekly-photos'>
            <div className='campaign-weekly-photos__text'>
                Check out our weekly photos!
            </div>
            <a
                href='https://www.facebook.com/pg/FitdogSportsClub/photos/?tab=albums'
                rel='noopener noreferrer'
                target='_blank'
                title='Weekly Photos'>
                <div className='campaign-weekly-photos__fb-button'>
                    <Icon className='campaign-weekly-photos__fb-icon' glyph={IconFB}/> View on Facebook
                </div>
            </a>
        </div>
    );
};

export default ViewOnFacebook;
