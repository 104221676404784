import { fromJS } from 'immutable';
import { createAction } from 'redux-actions';
import { SubmissionError } from 'redux-form/immutable';
import { generateAlertMeta } from '../../components/Alert/actions';
import { isTrainer } from '../../helpers/userRoles';
import { postInstructorEmployeeData, updateInstructorEmployeeData } from '../../services/instructorEmployee';
import * as InstructorEmployeeTypes from '../../types/dashboard/instructorEmployeeTypes';
import {
    setInstructorEmployeeLoaded,
    setInstructorEmployeeLoading,
    setInstructorEmployeeModalClosed,
} from '../miscActions';

export const updateInstructorEmployeeInfoData = (employeeId, newData, oldData, permissionsList) => {
    const sendData = {};
    let profilePhoto = null;

    if (!oldData) oldData = fromJS({});

    if (newData.get('model_type')) {
        if (newData.get('model_type').value !== oldData.get('model_type')) {
            sendData.model_type = newData.get('model_type').value;
        }
    }
    if (newData.get('name') !== oldData.get('full_name')) {
        sendData.full_name = newData.get('name');
    }
    if (newData.get('position') !== oldData.get('position')) {
        sendData.position = newData.get('position');
    }
    if (newData.get('email') !== oldData.get('email')) {
        sendData.email = newData.get('email').toLowerCase();
    }
    if (newData.get('position') !== oldData.get('position')) {
        sendData.position = newData.get('position');
    }
    if (newData.get('qualification') !== oldData.get('qualified_for')) {
        sendData.qualified_for = newData.get('qualification');
    }
    if (oldData.get('primary_phone')) {
        if (oldData.getIn(['primary_phone', 'phone_number']) !== newData.get('primary_phone')) {
            sendData.primary_phone = {
                'phone_number': newData.get('primary_phone').replace(/[^\w\s]/gi, ''),
            };
        }
    } else if (newData.get('primary_phone')) {
        if (newData.get('primary_phone').length > 0) {
            sendData.primary_phone = {
                'phone_number': newData.get('primary_phone').replace(/[^\w\s]/gi, ''),
            };
        }
    }
    if (oldData.get('emergency_contact')) {
        const phoneNumber = oldData.getIn(['emergency_contact', 'phone_number']);
        const name = oldData.getIn(['emergency_contact', 'name']);
        const newPhoneNumber = newData.get('emergency_phone');
        const newName = newData.get('emergency_name');

        if (phoneNumber !== newPhoneNumber) {
            sendData.emergency_contact = Object.assign((sendData.emergency_contact ? sendData.emergency_contact : {}), {
                'phone_number': newPhoneNumber.replace(/[^\w\s]/gi, ''),
            });
        }
        if (name !== newName) {
            sendData.emergency_contact = Object.assign((sendData.emergency_contact ? sendData.emergency_contact : {}), {
                'name': newName,
            });
        }
        if (!newPhoneNumber && !newName) {
            sendData.emergency_contact = null;
        }
    } else {
        if (newData.get('emergency_phone')) {
            if (newData.get('emergency_phone').length > 0) {
                sendData.emergency_contact = Object.assign(
                    (sendData.emergency_contact ? sendData.emergency_contact : {}),
                    {
                        'phone_number': newData.get('emergency_phone').replace(/[^\w\s]/gi, ''),
                        'relationship': 'friend',
                    });
            }
        }
        if (newData.get('emergency_name')) {
            if (newData.get('emergency_name').length > 0) {
                sendData.emergency_contact = Object.assign(
                    (sendData.emergency_contact ? sendData.emergency_contact : {}),
                    {
                        'name': newData.get('emergency_name'),
                    });
            }
        }
    }

    if (sendData.model_type === 'trainer') {
        sendData.permission_level = 'trainer';
    } else if (newData.get('permissions')) {
        if (newData.get('permissions').value !== oldData.get('permission_level')) {
            sendData.permission_level = newData.get('permissions').value;
        }
    }

    if (newData.get('photo')) {
        if (newData.get('photo').length > 0 && typeof (newData.get('photo')) !== 'string') {
            profilePhoto = newData.get('photo')[0];
        }
    }

    return async dispatch => {
        if (!employeeId) {
            await dispatch(postInstructorEmployeeData(fromJS({ sendData, profilePhoto })));
            dispatch(generateAlertMeta({
                event: 'EMPLOYEE_CREATE',
                responseData: { name: sendData.full_name },
            }));
        } else {
            await dispatch(updateInstructorEmployeeData({
                url: `/v1/${isTrainer() ? 'trainers' : 'super-admins'}/directory/${employeeId}/`,
                dataChunks: fromJS({ sendData, profilePhoto, employeeId }),
            }));
            dispatch(generateAlertMeta({
                event: 'EMPLOYEE_EDIT',
                responseData: { name: newData.get('name') },
            }));
        }
    };
};
// eslint-disable-next-line max-len
export const setCurrentInstructorEmployeeData = createAction(
    InstructorEmployeeTypes.SET_CURRENT_INSTRUCTOR_EMPLOYEE_DATA);
// eslint-disable-next-line max-len
export const clearCurrentInstructorEmployeeData = createAction(
    InstructorEmployeeTypes.CLEAR_CURRENT_INSTRUCTOR_EMPLOYEE_DATA);
export const setCurrentInstructorEmployee = (employeeData) => {
    return (dispatch) => {
        if (employeeData === undefined) {
            dispatch(clearCurrentInstructorEmployeeData());
        } else {
            dispatch(setCurrentInstructorEmployeeData(employeeData));
        }
    };
};

// eslint-disable-next-line max-len
export const processInstructorEmployeeInfo = (employeeId, newData, oldData, permissionsList) => {
    return async dispatch => {
        dispatch(setInstructorEmployeeLoading());
        const response = await dispatch(
            updateInstructorEmployeeInfoData(employeeId, newData, oldData, permissionsList)).then(() => {
            dispatch(setInstructorEmployeeLoaded());
            dispatch(setInstructorEmployeeModalClosed());
            return true;
        }).catch((error) => {
            console.error('updateInstructorEmployeeInfoData error', error);
            dispatch(setInstructorEmployeeLoaded());
            if (error) {
                throw new SubmissionError(error.response.data);
            }
        });
        return response;
    };
};
