import React from 'react';
import { Field } from 'redux-form/immutable';
import BaseField from '../';

class TemplateTextInput extends BaseField {
    fieldClassName = 'form-label-field template-title';
    
    renderInput = () => {
        const { input, label } = this.props;
        return (
            <div className={this.getCombinedFieldClassName()}>
                <div className='form-label-field__inner'>
                    <input
                        {...input}
                        type='text'
                        name='text-field'
                        placeholder=' '/>
                    <label
                        htmlFor='text-field'>
                        {label}
                    </label>
                </div>
                {this.renderError()}
            </div>
        );
    };
    
    renderPreview = () => {
        const { input } = this.props;
        return (
            <div className={this.getCombinedFieldClassName()}>
                <div className='preview'>{input.value}</div>
            </div>
        );
    };
    
    render() {
        const { formProps: { isPreview } } = this.props;
        if (isPreview) {
            return this.renderPreview();
        }
        return this.renderInput();
    }
}

const TemplateTextInputField = ({ fieldConfig, formProps }) => {
    return (
        <Field
            {...fieldConfig}
            formProps={formProps}
            component={TemplateTextInput}/>
    );
};

TemplateTextInputField.propTypes = BaseField.defaultFieldProps;

export default TemplateTextInputField;
