import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetCustomerPassword } from '../../../../actions/dashboard/resetPasswordActions';
import { setResetPasswordModalClosed } from '../../../../actions/miscActions';
import ModalDialog from '../../../common/ModalDialog';

// eslint-disable-next-line max-len
const ResetPasswordModalDialog = ({ isModalOpened, handleModalClosed, handleSubmit, modalData }) => {
    return (
        <ModalDialog
            form={
                <div className='modal-dialog__form'>
                    <p className=' modal-dialog__text modal-dialog__text_bold'>
                        Are you sure you would like to reset the password for {modalData.get('fullName')}
                    </p>
                    <p className='modal-dialog__text'>
                        They will need to reset their password before they log in.
                    </p>
                    <div className='modal-dialog__form-footer modal-dialog__form-footer_row'>
                        <button
                            className='button modal-dialog__button'
                            onClick={handleSubmit}>
                            Reset
                        </button>
                    </div>
                </div>
            }
            title={`Reset ${modalData.get('fullName')}'s password`}
            isModalOpened={isModalOpened}
            handleModalClosed={handleModalClosed}/>
    );
};

ResetPasswordModalDialog.propTypes = {
    isModalOpened: PropTypes.bool,
    handleModalClosed: PropTypes.func,
    handleSubmit: PropTypes.func,
    modalData: PropTypes.shape({})
};

const mapStateToProps = state => {
    return {
        modalData: state.getIn(['misc', 'modals', 'resetPasswordData']),
        isModalOpened: state.getIn(['misc', 'modals', 'resetPassword'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleModalClosed: bindActionCreators(setResetPasswordModalClosed, dispatch),
    handleSubmit: bindActionCreators(resetCustomerPassword, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModalDialog);
