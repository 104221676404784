import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field, reduxForm } from 'redux-form/immutable';
import DogsSelectorControl from '../../../../common/Form/DogSelectorControl';
import BookingDogItem from './BookingDogItem';
import validate from './validate';

class DogSelection extends PureComponent {

    componentWillUpdate(nextProps) {
        const { dogsList, formData } = nextProps;
        if (dogsList.length === 1
            && formData
            && this.checkDog(dogsList[0], formData.classInfo, formData.availableDogs)
        ) {
            this.submitStep(dogsList[0].id.toString());
        }
    }

    // the same func as in BookingDogItem component
    checkDog = (dogData, classInfo, availableDogs = []) => {
        const { access_level: dogAccessLevel,
                assessment_status: assessmentStatus,
                survey: { assessment },
                id } = dogData;
        const { access_level: classAccessLevel } = classInfo;
        return !(dogAccessLevel < classAccessLevel
            || (assessmentStatus === 'IMMUNIZATION' && classAccessLevel)
            || !assessment
            || !availableDogs.includes(id));
    }

    submitStep = (data) => {
        const { goToStep, addSubmitData } = this.props;
        addSubmitData({ name: 'dogs', data });
        goToStep(1);
        return true;
    }

    render() {
        const { invalid, submitting, onCancelHandler, submitText, submitButtonClasses, handleSubmit, formData } = this.props; // eslint-disable-line
        const submitButtonClassNames = classNames({
            'button modal-dialog__button modal-dialog__button_booking': true,
            'button_disabled': (invalid || submitting),
            [submitButtonClasses]: true,
        });
        const isMobile = window.innerWidth <= 736;
        const classInfo = formData && formData.classInfo ? formData.classInfo : {};
        const className = classInfo.title || '';
        return (
            <form
                className='dog-selection'
                onSubmit={handleSubmit(data => this.submitStep(data.get('dogs')))}>
                <div className='dog-selection__form-content'>
                    <Field
                        name='dogs'
                        type='hidden'
                        title={`Select which dog(s) you would like to attend ${className}.`}
                        className='dog-selection__control'
                        spaceBetween={40}
                        itemsPerPage={isMobile ? 'auto' : 3}
                        itemRenderer={BookingDogItem}
                        component={DogsSelectorControl}/>
                </div>
                <div className='modal-dialog__form-footer modal-dialog__form-footer_column'>
                    <button
                        className={submitButtonClassNames}
                        type='submit'
                        disabled={submitting}>
                        {(submitting)
                            ? 'Submitting...'
                            : submitText || 'Next'
                        }
                    </button>
                    <span className='modal-dialog__cancel-button' onClick={onCancelHandler}>Cancel</span>
                </div>
            </form>
        );
    }
}

DogSelection.propTypes = {
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    onCancelHandler: PropTypes.func,
    submitText: PropTypes.string,
    handleSubmit: PropTypes.func,
    formData: PropTypes.shape({}),
    goToStep: PropTypes.func,
    dogsList: PropTypes.arrayOf(PropTypes.shape({})),
    addSubmitData: PropTypes.func,
    submitButtonClasses: PropTypes.string,
    availableDogs: PropTypes.arrayOf(PropTypes.number)
};

const form = reduxForm({
    form: 'DogSelectionForm',
    enableReinitialize: true,
    validate
});

const mapStateToProps = state => ({
    dogsList: state.getIn(['dogsList', 'dogs']).toJS(),
    // availableDogs: state.getIn(['steppedForm', 'data', 'availableDogs'])
});

export default connect(mapStateToProps, null)(form(DogSelection));
