import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { setDefaultChangeNameData } from '../../../../actions/miscActions';
import renderInputControl from '../../../common/Form/InputControl';
import validate from './validate';

class ChangeNameModalDialogForm extends React.PureComponent {
    static propTypes = {
        initialValues: PropTypes.shape({}),
        handleSubmit: PropTypes.func,
        handleDefaultValues: PropTypes.func,
        invalid: PropTypes.bool,
        submitting: PropTypes.bool
    };

    componentDidMount() {
        this.props.handleDefaultValues();
    }

    render() {
        const { handleSubmit, invalid, submitting } = this.props;
        const submitButtonClassNames = classNames({
            'button modal-dialog__button': true,
            'button_disabled': invalid
        });
        return (
            <form className='modal-dialog__form' onSubmit={handleSubmit}>
                <div className='modal-dialog__form-content'>
                    <Field
                        name='full_name'
                        type='text'
                        component={renderInputControl}
                        label='Full Name'/>
                </div>
                <div className='modal-dialog__form-footer modal-dialog__form-footer_row'>
                    <button
                        className={submitButtonClassNames}
                        type='submit'
                        disabled={submitting}>
                        Save
                    </button>
                </div>
            </form>
        );
    }
}

const form = reduxForm({
    form: 'ChangeNameModalDialogForm',
    enableReinitialize: true,
    validate
});

const mapStateToProps = state => {
    return {
        initialValues: state.getIn(['currentCustomer', 'initialInstance'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleDefaultValues: bindActionCreators(setDefaultChangeNameData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ChangeNameModalDialogForm));
