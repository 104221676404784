import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { processCustomersEmergencyContact } from '../../../../actions/dashboard/emergencyContactActions';
import { setEmergencyContactModalClosed } from '../../../../actions/miscActions';
import ModalDialog from '../../../common/ModalDialog';
import EmergencyContactModalDialogForm from './EmergencyContactModalDialogForm';

// eslint-disable-next-line max-len
const EmergencyContactModalDialog = ({
                                         isEmergencyContactModalOpened, handleAdditionalOwnersModalClosed, handleSubmit,
                                         customer
                                     }) => {
    return (
        <ModalDialog
            form={
                <EmergencyContactModalDialogForm
                    customersName={customer.get('full_name')}
                    onSubmit={data => handleSubmit(customer.get('id'), data.toJS())}/>
            }
            title={`Edit ${customer.get('full_name')}'s Emergency Contacts`}
            isModalOpened={isEmergencyContactModalOpened}
            handleModalClosed={handleAdditionalOwnersModalClosed}/>
    );
};

EmergencyContactModalDialog.propTypes = {
    isEmergencyContactModalOpened: PropTypes.bool,
    handleAdditionalOwnersModalClosed: PropTypes.func,
    handleSubmit: PropTypes.func,
    customer: PropTypes.shape({})
};

const mapStateToProps = state => {
    return {
        customer: state.getIn(['currentCustomer', 'instance']),
        isEmergencyContactModalOpened: state.getIn(['misc', 'modals', 'emergencyContact'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleAdditionalOwnersModalClosed: bindActionCreators(setEmergencyContactModalClosed, dispatch),
    handleSubmit: bindActionCreators(processCustomersEmergencyContact, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyContactModalDialog);
