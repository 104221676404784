import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendForgottenPassword } from '../../../../services/resetPassword';
import SkipStep from '../../../common/SkipStep/SkipStep';
import Step from '../../../common/Step';
import StepDescription from '../../../common/StepDescription';
import ForgotPasswordFormContainer from './ForgotPasswordForm';

const ForgotPasswordPage = ({ stepName = 'forgot-password', handleSendingForgottenPassword }) => {
    const Disclaimer = 'Enter the email address associated with your Fitdog Sports account, and we’ll send you a link to reset your password.';
    return (
        <Step
            logoClassName='forgot-password__logo'
            contentClassName='forgot-password__content'
            contentsContainerClassName='forgot-password__container'
            stepName={stepName}
            componentsImage={<div className='forgot-password__img forgot-password__img_employee'/>}
            descriptionComponent={
                <StepDescription
                    className='forgot-password__step-description'
                    text='Forgot your password?'
                    disclaimer={Disclaimer}/>
            }
            formComponent={
                <ForgotPasswordFormContainer
                    onSubmit={values => handleSendingForgottenPassword(values)}/>
            }
            skipComponent={
                <SkipStep
                    className='forgot-password__skip-step'
                    skipLinkText='Return to Login'
                    skipLinkTo='/employee'/>
            }/>
    );
};

ForgotPasswordPage.propTypes = {
    stepName: PropTypes.string,
    handleSendingForgottenPassword: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
    handleSendingForgottenPassword: bindActionCreators(sendForgottenPassword, dispatch)
});

export default connect(null, mapDispatchToProps)(ForgotPasswordPage);
