import React from 'react';
import { Field } from 'redux-form/immutable';
import _ from 'lodash';
import { fieldDefaultPropTypes } from '../../Form';
import renderSearchAddTagControl from '../../common/Form/SearchAddTagControl';

const DEBOUNCE_TIME = 750;

const TagSearchAdd = ({ fieldConfig, formProps }) => {
    return (
        <Field
            {...fieldConfig}
            type='text'
            formProps={formProps}
            getOptions={_.debounce(formProps.onGetOptions(
                fieldConfig, formProps.formValues
            ), DEBOUNCE_TIME)}
            component={renderSearchAddTagControl}/>
    );
};

TagSearchAdd.propTypes = fieldDefaultPropTypes;

export default TagSearchAdd;
