import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetPassword } from '../../../../services/resetPassword';
import SkipStep from '../../../common/SkipStep/SkipStep';
import Step from '../../../common/Step';
import StepDescription from '../../../common/StepDescription';
import CreatePasswordFormContainer from './CreatePasswordForm';

const EmployeeCreatePasswordPage = ({ stepName = 'create-password', handleSettingPassword, location }) => {
    const Disclaimer = 'To sign on and get started, create a password to use with your e-mail address.';
    const { code, email } = location.query;
    return (
        <Step
            logoClassName='create-password__logo'
            contentClassName='create-password__content'
            contentsContainerClassName='create-password__container'
            stepName={stepName}
            componentsImage={<div className='create-password__img create-password__img_employee'/>}
            descriptionComponent={
                <StepDescription
                    className='create-password__step-description'
                    text='Welcome to fitdog'
                    disclaimer={Disclaimer}/>
            }
            formComponent={
                <CreatePasswordFormContainer
                    onSubmit={values => handleSettingPassword(values.set('code', code))}/>
            }
            skipComponent={
                <SkipStep
                    className='create-password__skip-step'
                    skipLinkText={`Not ${email}?`}
                    skipLinkTo='/'/>
            }/>
    );
};

EmployeeCreatePasswordPage.propTypes = {
    stepName: PropTypes.string,
    handleSettingPassword: PropTypes.func,
    location: PropTypes.shape({}),
};

const mapDispatchToProps = (dispatch) => ({
    handleSettingPassword: bindActionCreators(resetPassword, dispatch)
});

export default connect(null, mapDispatchToProps)(EmployeeCreatePasswordPage);
