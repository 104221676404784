import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { getWaiverLegalese } from '../../../../../services/agreements';
import { setCurrentRegistrationStep } from '../../../../../actions/onboarding/onBoardingStepsActions';
import validate from './validate';

class WaiverForm extends React.PureComponent {
    static propTypes = {
        registrationStep: PropTypes.number,
        updateRegistrationStep: PropTypes.func,
        handleSubmit: PropTypes.func.isRequired,
        onSubmit: PropTypes.func,
        submitting: PropTypes.bool,
        invalid: PropTypes.bool,
        handleGettingWaiverLegalese: PropTypes.func,
    };

    state = {
        isLoadingLegalese: true,
        legaleseText: ''
    };

    componentWillMount = () => {
        this.props.handleGettingWaiverLegalese().then((legalese) => {
            this.setState({
                legaleseText: legalese,
                isLoadingLegalese: false
            });
        });
    };

    componentDidMount() {
        const { registrationStep, updateRegistrationStep } = this.props;
        const savedRegistrationStep = +localStorage.getItem('registration_step');
        if (savedRegistrationStep && registrationStep < savedRegistrationStep) {
            updateRegistrationStep(savedRegistrationStep);
        }
    }

    render() {
        const { isLoadingLegalese, legaleseText } = this.state;
        const { handleSubmit, submitting, invalid } = this.props;
        const SubmitButtonClassNames = classNames({
            'waiver__submit-button': true,
            'waiver__submit-button_disabled': invalid || submitting
        });
        return (
            <form onSubmit={handleSubmit} className='waiver__form'>
                <div className='waiver__form-content'>
                    <div className='waiver__form-legalese'>
                        {isLoadingLegalese
                            ? (<div className='legalese__text'>
                                <div className='legalese__text-loader'>Loading terms and conditions...</div>
                            </div>)
                            : <div className='legalese__text ql-editor' dangerouslySetInnerHTML={{ __html: legaleseText }}/>
                        }
                    </div>
                    <div className='checkbox-control'>
                        <label htmlFor='accepted' className='checkbox-control__label'>
                            <Field
                                name='accepted'
                                id='accepted'
                                component='input'
                                type='checkbox'
                                className='checkbox-control__input'/>
                            <span className='checkbox-control__text'>
                                I have read and accepted the terms and conditions outlined above.
                            </span>
                        </label>
                    </div>
                </div>
                <button
                    className={SubmitButtonClassNames}
                    type='submit'
                    disabled={submitting || invalid}>
                    {'You\'re done! Submit Profile'}
                </button>
            </form>
        );
    }
}

const form = reduxForm({
    form: 'waiverForm',
    validate
});

const mapStateToProps = state => ({
    registrationStep: state.getIn(['steps', 'registration_step'])
});

const mapDispatchToProps = (dispatch) => ({
    updateRegistrationStep: bindActionCreators(setCurrentRegistrationStep, dispatch),
    handleGettingWaiverLegalese: bindActionCreators(getWaiverLegalese, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(form(WaiverForm));
