export const RESET_FORMS_PAGE_REDUCER = 'RESET_FORMS_PAGE_REDUCER';

export const START_FORMS_DATA_LOADING = 'START_FORMS_DATA_LOADING';
export const FINISH_FORMS_DATA_LOADING = 'FINISH_FORMS_DATA_LOADING';
export const SET_FORMS_ITEMS_FINISH_LOADING = 'SET_FORMS_ITEMS_FINISH_LOADING';
export const APPEND_FORMS_ITEMS_FINISH_LOADING = 'APPEND_FORMS_ITEMS_FINISH_LOADING';
export const REMOVE_FORM_BY_ID = 'REMOVE_FORM_BY_ID';

export const START_CURRENT_FORM_DATA_LOADING = 'START_CURRENT_FORM_DATA_LOADING';
export const FINISH_CURRENT_FORM_DATA_LOADING = 'FINISH_CURRENT_FORM_DATA_LOADING';
export const SET_CURRENT_FORM_FINISH_LOADING = 'SET_CURRENT_FORM_FINISH_LOADING';
export const UPDATE_CURRENT_FORM = 'UPDATE_CURRENT_FORM';
