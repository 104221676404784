import { push } from 'react-router-redux';
import createAction from 'redux-actions/es/createAction';
import { oauthService } from '../../services/oauth';
import * as ProfileServices from '../../services/profile';
import * as ResetPasswordServices from '../../services/resetPassword';
import { updateInfo } from '../../services/update';
import * as UserTypes from '../../types/onboarding/userTypes';
import {
    completeWaiverInfoStep,
    setFinishingLater,
    setHealthStepComplete,
    setIntroductionStepComplete,
    setVetInfoStepComplete,
    setWaiverInfoStepComplete,
} from './onBoardingStepsActions';

export const setUserForgottenInfo = createAction(UserTypes.SET_USER_FORGOTTEN_INFO);
export const setUserInfo = createAction(UserTypes.SET_USER_INFO);
export const setUserFacebookInfo = createAction(UserTypes.SET_USER_FB_INFO);
export const setUserGoogleInfo = createAction(UserTypes.SET_USER_GOOGLE_INFO);
export const setUserProfileInfo = createAction(UserTypes.SET_USER_PROFILE_INFO);
export const setWelcomeScreenShowed = createAction(UserTypes.SET_WELCOME_SHOWED);
export const setUserPhoto = createAction(UserTypes.SET_USER_PHOTO);
export const userLogout = createAction(UserTypes.USER_LOGOUT);
export const verifyPrimaryPhone = createAction(UserTypes.VERIFY_PRIMARY_PHONE);

export const setWelcomeShowed = () => {
    return dispatch => {
        dispatch(setWelcomeScreenShowed());
        dispatch(ProfileServices.setWelcomeScreenShowed());
    };
};

export const signIn = (data) => oauthService(
    {
        grantType: 'password',
        url: '/oauth/token/',
        redirectPath: '/dashboard',
        actionCreator: setUserInfo,
        userData: data.toJS(),
    },
);

export const signInFacebook = (data) => oauthService(
    {
        grantType: 'convert_token',
        url: '/oauth/convert-token/',
        backendType: 'facebook',
        role: 'customer',
        actionCreator: setUserFacebookInfo,
        redirectPath: '/home',
        userData: data,
    },
);

export const signInGoogle = (data) => oauthService(
    {
        grantType: 'convert_token',
        url: '/oauth/convert-token/',
        backendType: 'google-oauth2',
        role: 'customer',
        actionCreator: setUserGoogleInfo,
        redirectPath: '/home',
        userData: data,
    },
);

export const signUp = ({ data, isGingr }) => {
    return (dispatch, getState) => {
        if (!data) data = getState().getIn(['form', 'signUpForm', 'values']);
        const userData = data.toJS();
        if (isGingr) {
            userData.import_from_gingr = true;
            dispatch(setIntroductionStepComplete());
            dispatch(setHealthStepComplete());
            dispatch(setVetInfoStepComplete());
            dispatch(setWaiverInfoStepComplete());
            dispatch(setFinishingLater());
        }

        return dispatch(oauthService({
            grantType: 'password',
            role: 'customer',
            url: '/v1/customers/signup/',
            redirectPath: isGingr ? '/waiver' : '/introduction',
            actionCreator: setUserInfo,
            userData,
        }));
    };
};

export const signUpScratch = ({ data }) => {
    return (dispatch, getState) => {
        if (!data) data = getState().getIn(['form', 'signUpForm', 'values']);
        const userData = data.toJS();
        userData.import_from_gingr = false;

        return dispatch(oauthService({
            grantType: 'password',
            role: 'customer',
            url: '/v1/customers/signup/',
            redirectPath: '/introduction',
            actionCreator: setUserInfo,
            userData,
        }));
    };
};

const createSocialUserData = (getState) => {
    const userData = getState().getIn(['form', 'finishSignUpForm', 'values']).toJS();
    userData.accessToken = getState().getIn(['user', 'signUpData', 'token']);
    return userData;
};

export const signUpFacebook = ({ data, isGingr }) => {
    return (dispatch, getState) => {
        if (!data) data = getState().getIn(['form', 'finishSignUpForm', 'values']);
        const userData = data.toJS ? data.toJS() : data;
        userData.accessToken = getState().getIn(['user', 'signUpData', 'token']);

        if (isGingr) {
            dispatch(setIntroductionStepComplete());
            dispatch(setHealthStepComplete());
            dispatch(setVetInfoStepComplete());
            dispatch(setWaiverInfoStepComplete());
            dispatch(setFinishingLater());
        }

        return dispatch(oauthService({
            grantType: 'convert_token',
            role: 'customer',
            backendType: 'facebook',
            url: isGingr ? '/oauth/convert-token/?import_from_gingr=true' : '/oauth/convert-token/',
            actionCreator: setUserFacebookInfo,
            redirectPath: isGingr ? '/waiver' : '/introduction',
            userData,
        }));
    };
};

export const signUpFacebookScratch = ({ data }) => {
    return (dispatch, getState) => {
        if (!data) data = createSocialUserData(getState);
        dispatch(oauthService({
            grantType: 'convert_token',
            role: 'customer',
            backendType: 'facebook',
            url: '/oauth/convert-token/?import_from_gingr=false',
            actionCreator: setUserFacebookInfo,
            redirectPath: '/introduction',
            userData: data,
        }));
    };
};

export const signUpGoogle = ({ data, isGingr }) => {
    return (dispatch, getState) => {
        if (!data) data = getState().getIn(['form', 'finishSignUpForm', 'values']);
        const userData = data.toJS ? data.toJS() : data;
        userData.accessToken = getState().getIn(['user', 'signUpData', 'token']);

        if (isGingr) {
            dispatch(setIntroductionStepComplete());
            dispatch(setHealthStepComplete());
            dispatch(setVetInfoStepComplete());
            dispatch(setWaiverInfoStepComplete());
            dispatch(setFinishingLater());
        }

        return dispatch(oauthService({
            grantType: 'convert_token',
            role: 'customer',
            backendType: 'google-oauth2',
            url: isGingr ? '/oauth/convert-token/?import_from_gingr=true' : '/oauth/convert-token/',
            actionCreator: setUserGoogleInfo,
            redirectPath: isGingr ? '/waiver' : '/introduction',
            userData,
        }));
    };
};

export const signUpGoogleScratch = ({ data }) => {
    return (dispatch, getState) => {
        if (!data) data = createSocialUserData(getState);

        dispatch(oauthService({
            grantType: 'convert_token',
            role: 'customer',
            backendType: 'google-oauth2',
            url: '/oauth/convert-token/?import_from_gingr=false',
            actionCreator: setUserGoogleInfo,
            redirectPath: '/introduction',
            userData: data,
        }));
    };
};

export const updateCustomerInfo = (data, shouldGoNextStep = true) => updateInfo(
    {
        url: '/v1/customers/me/',
        infoChunks: Object.keys(data.toJS()).map((chunk) => {
            return {
                name: chunk,
                content: data.toJS()[chunk],
            };
        }),
        actionCB: (dispatch) => { dispatch(completeWaiverInfoStep(shouldGoNextStep)); },
    },
);

export const activateEmail = (code) => {
    return dispatch => {
        dispatch(ResetPasswordServices.activateEmail(code))
            .then(() => {
                dispatch(push('/profile'));
            })
            .catch((error) => {
                console.log(error.response);
                if (error.response.status === 400 && error.response.data.includes('User is already active.')) {
                    dispatch(push('/profile'));
                }
            });
    };
};
