import PropTypes from 'prop-types';
import React from 'react';
import Grid from '../../../../../common/Grid';

const SearchGrid = ({ data, getNext }) => {
    return (
        <Grid
            items={data.get('filteredResults')}
            className='search-page__content search-page__grid'
            cardType='search'
            getNext={getNext}
            nextUrl={data.get('nextUrl')}
            isLoading={data.get('isLoading')}
            emptyItemsClassNames='search-result-card-container search-result-card-container_empty'/>
    );
};

SearchGrid.propTypes = {
    data: PropTypes.shape({}).isRequired,
    getNext: PropTypes.func,
};

export default SearchGrid;
