import { fromJS } from 'immutable';
import moment from 'moment';
import { handleActions } from 'redux-actions';
import * as SearchBarTypes from '../types/dashboard/searchBarTypes';

const INITIAL_STATE = fromJS({
    results: [],
    filteredResults: [],
    topResults: [],
    dogs: [],
    customers: [],
    searchString: null,
    searchInputValue: '',
    isSearchPageOpened: false,
    filters: [
        {
            name: 'Filter',
            query: 'type',
            type: 'select',
            options: [
                {
                    label: 'All',
                    value: 'all',
                },
                {
                    label: 'Customers',
                    value: 'customers',
                },
                {
                    label: 'Dogs',
                    value: 'dogs',
                },
            ],
        },
    ],
    filterObject: {},
    count: null,
    pageSize: null,
    pages: null,
    nextUrl: null,
    previousUrl: null,
    isLoading: false,
});

const searchBarReducer = handleActions(
    {
        [SearchBarTypes.SET_SEARCH_RESULTS]: (state, action) => {
            const { results, count, next, previous } = action.payload.searchData;
            const searchString = action.payload.searchString;

            const nextUrl = next === null ? next : next.slice(4);
            const previousUrl = previous === null ? previous : previous.slice(4);

            let searchResults;
            const dogsArray = [];
            const customersArray = [];
            if (results) {
                searchResults = results.reduce((resultArray, searchItem) => {
                    const searchElement = {};
                    searchElement.next_class_start = searchItem.next_class_start === null
                        ? searchItem.next_class_start
                        : moment(searchItem.next_class_start).format('MM/DD/YY');
                    if (searchItem.dog !== null) {
                        searchElement.type = 'dog';
                        searchElement.id = searchItem.dog.id;
                        searchElement.name = searchItem.dog.name;
                        searchElement.dogName = searchItem.dog.name;
                        searchElement.dogId = searchItem.dog.id;
                        searchElement.breed = searchItem.dog.breed_detail;
                        searchElement.ownerName = searchItem.dog.owner_detail.full_name;
                        searchElement.email = searchItem.dog.owner_detail.email;
                        searchElement.photo = searchItem.dog.photo;
                        searchElement.photo_processed = searchItem.dog.photo_processed;
                        searchElement.phone_number =
                            searchItem.dog.owner_detail.available_phones.length > 0
                                ? searchItem.dog.owner_detail.available_phones[0].phone_number
                                : null;
                        searchElement.ownerId = searchItem.dog.owner_detail.id || null;

                        searchElement.ownerData = searchItem.dog.owner_detail;
                        searchElement.dogData = searchItem.dog;

                        dogsArray.push(searchElement);
                    } else if (searchItem.customer !== null) {
                        searchElement.type = 'customer';
                        searchElement.id = searchItem.customer.id;
                        searchElement.name = searchItem.customer.full_name;
                        searchElement.ownerName = searchItem.customer.full_name;
                        searchElement.ownerID = searchItem.customer.id;
                        searchElement.email = searchItem.customer.email;
                        searchElement.photo = searchItem.customer.photo;
                        searchElement.photo_processed = searchItem.customer.photo_processed;
                        const {
                            customer: {
                                available_phones,
                                primary_phone,
                            },
                        } = searchItem;
                        let phoneNumber;
                        if (available_phones.length > 0) {
                            if (primary_phone) {
                                const phoneNumberObj = available_phones.find((availablePhone) => {
                                    return availablePhone.id === primary_phone;
                                })
                                phoneNumber = phoneNumberObj ? phoneNumberObj.phone_number : null;
                            } else {
                                phoneNumber = available_phones[0].phone_number;
                            }
                        }
                        searchElement.phone_number = phoneNumber;
                        if (searchItem.customer.dogs.length > 0) {
                            searchElement.dogName = searchItem.customer.dogs[0].name;
                            searchElement.dogId = searchItem.customer.dogs[0].id;
                            searchElement.breed = searchItem.customer.dogs[0].breed_detail;
                            searchElement.dogData = searchItem.customer.dogs[0];
                            searchElement.dogsArray = searchItem.customer.dogs.reduce((resultArray, dogElement) => {
                                resultArray.push(dogElement);
                                if (searchElement.dogsNamesString === undefined) {
                                    searchElement.dogsNamesString = dogElement.name;
                                } else {
                                    searchElement.dogsNamesString += ', ' + dogElement.name;
                                }
                                return resultArray;
                            }, []);
                            searchElement.dogData.owner_detail = searchItem.customer;
                            delete searchElement.dogData.owner_detail.dogs;
                        }

                        searchElement.ownerData = searchItem.customer;

                        customersArray.push(searchElement);
                    }
                    resultArray.push(searchElement);
                    return resultArray;
                }, []);
            } else {
                searchResults = [];
            }

            const pagesCount = Math.ceil(count / searchResults.length);

            const filterType = state.getIn(['filterObject', 'type']);
            let filteredSearchData = fromJS(searchResults);
            if (filterType === 'customers') {
                filteredSearchData = fromJS(customersArray);
            } else if (filterType === 'dogs') {
                filteredSearchData = fromJS(dogsArray);
            }

            return state.update(state => {
                return state
                    .setIn(['results'], fromJS(searchResults))
                    .setIn(['topResults'], fromJS(searchResults.slice(0, 4)))
                    .setIn(['count'], count)
                    .setIn(['dogs'], fromJS(dogsArray))
                    .setIn(['customers'], fromJS(customersArray))
                    .setIn(['pageSize'], searchResults.length)
                    .setIn(['pages'], pagesCount)
                    .setIn(['nextUrl'], nextUrl)
                    .setIn(['previousUrl'], previousUrl)
                    .setIn(['searchString'], searchString)
                    .setIn(['filteredResults'], filteredSearchData);
            });
        },
        [SearchBarTypes.UPDATE_SEARCH_INPUT_VALUE]: (state, action) =>
            state.setIn(['searchInputValue'], action.payload || ''),
        [SearchBarTypes.OPEN_SEARCH_PAGE]: (state, action) => state.setIn(['isSearchPageOpened'], true),
        [SearchBarTypes.CLOSE_SEARCH_PAGE]: (state, action) => state.setIn(['isSearchPageOpened'], false),
        [SearchBarTypes.SET_NEXT_SEARCH_DATA]: (state, action) => {
            const { results, count, next, previous } = action.payload;

            const nextUrl = next === null ? next : next.slice(4);
            const previousUrl = previous === null ? previous : previous.slice(4);

            let searchResults;
            const dogsArray = [];
            const customersArray = [];

            if (results) {
                searchResults = results.reduce((resultArray, searchItem) => {
                    const searchElement = {};

                    if (searchItem.dog !== null) {
                        searchElement.type = 'dog';
                        searchElement.id = searchItem.dog.id;
                        searchElement.name = searchItem.dog.name;
                        searchElement.dogName = searchItem.dog.name;
                        searchElement.dogId = searchItem.dog.id;
                        searchElement.breed = searchItem.dog.breed_detail;
                        searchElement.ownerName = searchItem.dog.owner_detail.full_name;
                        searchElement.email = searchItem.dog.owner_detail.email;
                        searchElement.photo = searchItem.dog.photo;
                        searchElement.photo_processed = searchItem.dog.photo_processed;
                        searchElement.phone_number =
                            searchItem.dog.owner_detail.available_phones.length > 0
                                ? searchItem.dog.owner_detail.available_phones[0].phone_number
                                : null;
                        searchElement.ownerId = searchItem.dog.owner_detail.id || null;

                        searchElement.ownerData = searchItem.dog.owner_detail;
                        searchElement.dogData = searchItem.dog;

                        dogsArray.push(searchElement);
                    } else if (searchItem.customer !== null) {
                        searchElement.type = 'customer';
                        searchElement.id = searchItem.customer.id;
                        searchElement.name = searchItem.customer.full_name;
                        searchElement.ownerName = searchItem.customer.full_name;
                        searchElement.ownerID = searchItem.customer.id;
                        searchElement.email = searchItem.customer.email;
                        searchElement.photo = searchItem.customer.photo;
                        searchElement.photo_processed = searchItem.customer.photo_processed;
                        searchElement.phone_number =
                            searchItem.customer.available_phones.length > 0
                                ? searchItem.customer.available_phones[0].phone_number
                                : null;

                        if (searchItem.customer.dogs.length > 0) {
                            searchElement.dogName = searchItem.customer.dogs[0].name;
                            searchElement.dogId = searchItem.customer.dogs[0].id;
                            searchElement.breed = searchItem.customer.dogs[0].breed_detail;
                            searchElement.dogData = searchItem.customer.dogs[0];
                            searchElement.dogData.owner_detail = searchItem.customer;
                            delete searchElement.dogData.owner_detail.dogs;
                        }

                        searchElement.ownerData = searchItem.customer;

                        customersArray.push(searchElement);
                    }
                    resultArray.push(searchElement);
                    return resultArray;
                }, []);
            } else {
                searchResults = [];
            }

            const pagesCount = Math.ceil(count / searchResults.length);

            const filterType = state.getIn(['filterObject', 'type']);
            let filteredSearchData = state.get('results').concat(fromJS(searchResults));
            if (filterType === 'customers') {
                filteredSearchData = state.get('customers').concat(fromJS(customersArray));
            } else if (filterType === 'dogs') {
                filteredSearchData = state.get('dogs').concat(fromJS(dogsArray));
            }

            return state.update(state => {
                return state
                    .setIn(['results'], state.get('results').concat(fromJS(searchResults)))
                    .setIn(['count'], count)
                    .setIn(['dogs'], state.get('dogs').concat(fromJS(dogsArray)))
                    .setIn(['customers'], state.get('customers').concat(fromJS(customersArray)))
                    .setIn(['pageSize'], results.length)
                    .setIn(['pages'], pagesCount)
                    .setIn(['nextUrl'], nextUrl)
                    .setIn(['previousUrl'], previousUrl)
                    .setIn(['filteredResults'], filteredSearchData);
            });
        },
        [SearchBarTypes.UPDATE_SEARCH_FILTER_OBJECT]: (state, action) => {
            const filterType = action.payload.get('type');
            let filteredSearchData = state.get('results');
            if (filterType === 'customers') {
                filteredSearchData = state.get('customers');
            } else if (filterType === 'dogs') {
                filteredSearchData = state.get('dogs');
            }
            return state.update(state => {
                return state.setIn(['filterObject'], action.payload).setIn(['filteredResults'], filteredSearchData);
            });
        },
        [SearchBarTypes.START_SEARCH_LOADING]: (state, action) => state.setIn(['isLoading'], true),
        [SearchBarTypes.FINISH_SEARCH_LOADING]: (state, action) => state.setIn(['isLoading'], false),
    },
    INITIAL_STATE,
);

export default searchBarReducer;
