import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const TotalChargeControl = ({ currentPurchase }) => {
  const chargeValue = currentPurchase ? currentPurchase.price : 0;
  return (
    <div
      className='form__middle-text'>
      Total to charge: {'$' + chargeValue}
    </div>
  );
};

TotalChargeControl.propTypes = {
  currentPurchase: PropTypes.shape({})
};

const mapStateToProps = (state, { formName, purchaseType }) => ({
  currentPurchase: state.getIn(['form', formName, 'values', purchaseType]) || null
});

export default connect(mapStateToProps)(TotalChargeControl);
