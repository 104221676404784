import PropTypes from 'prop-types';
import React from 'react';

const SendDocs = ({ emailText, email, faxText, fax }) => {
    return (
        <div className='zone-send'>
            <div className='zone-send__email'>
                {emailText}
                <a href={`mailto:${email}`} className='zone-send__email-link'>{email}</a>
            </div>
            <div className='zone-send__fax'>
                {faxText}
                <a href={`tel:${fax.replace(/\D/g, '')}`} className='zone-send__fax-number'>{fax}</a>
            </div>
        </div>
    );
};

SendDocs.propTypes = {
    emailText: PropTypes.string,
    email: PropTypes.string,
    faxText: PropTypes.string,
    fax: PropTypes.string
};

export default SendDocs;
