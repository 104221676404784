
import { createAction } from 'redux-actions';
import { push } from 'react-router-redux';
import { fromJS } from 'immutable';
import * as SearchBarTypes from '../../types/dashboard/searchBarTypes';

export const setSearchResults = createAction(SearchBarTypes.SET_SEARCH_RESULTS);
export const updateSearchInputValue = createAction(SearchBarTypes.UPDATE_SEARCH_INPUT_VALUE);
export const openSearchPage = createAction(SearchBarTypes.OPEN_SEARCH_PAGE);
export const closeSearchPage = createAction(SearchBarTypes.CLOSE_SEARCH_PAGE);
export const setNextSearchData = createAction(SearchBarTypes.SET_NEXT_SEARCH_DATA);
export const updateSearchFilterObject = createAction(SearchBarTypes.UPDATE_SEARCH_FILTER_OBJECT);
export const startSearchLoading = createAction(SearchBarTypes.START_SEARCH_LOADING);
export const finishSearchLoading = createAction(SearchBarTypes.FINISH_SEARCH_LOADING);

export const updateSearchResults = (filterObject) => {
    return (dispatch) => {
        dispatch(updateSearchFilterObject(filterObject || fromJS({})));
    };
};

export const openSearchResultsPage = () => {
    return dispatch => {
        dispatch(push('/dashboard/search'));
    };
};
