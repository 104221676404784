import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import * as classesActions from '../../../../../actions/dashboard/classesActions';
import * as EventActions from '../../../../../actions/dashboard/eventActions';
import * as FormActions from '../../../../../actions/form/formActions';
import * as ModalOpenActions from '../../../../../actions/modal/openActions';
import * as SubmitActions from '../../../../../actions/modal/submitActions';
import * as EventService from '../../../../../services/event';
import { mapFormToState } from '../../../FormContainer';
import EventDetailPage from '../EventDetailPage/index';

class EventContainer extends React.PureComponent {
    static propTypes = {
        routeParams: PropTypes.shape({}),
        currentEvent: PropTypes.shape({}),
        formState: PropTypes.shape({}),
        resetState: PropTypes.func,
        getCurrentEvent: PropTypes.func,
        submit: PropTypes.func,
        reset: PropTypes.func,
        dispatchPush: PropTypes.func,
        openProgramChangeWarning: PropTypes.func,
        setNewClassOccurrenceEvent: PropTypes.func,
        setNewTrainingEvent: PropTypes.func,
        openEventCancellationModalDialog: PropTypes.func,
        openEventKeepChangesModalDialog: PropTypes.func,
        handleEditingUpcomingClass: PropTypes.func,
        handleCancellingUpcomingClass: PropTypes.func,
        handleOpeningCancelUpcomingClassModal: PropTypes.func,
        handleEditingUpcomingClassStatus: PropTypes.func,
        handleEditingPreviousClassStatus: PropTypes.func,
        handleOpeningCreateDogReportModalDialog: PropTypes.func,
        handleOpeningEditDogReportModalDialog: PropTypes.func,
        handleSettingClassEmptyReports: PropTypes.func,
        cancelTrainingProduct: PropTypes.func,
        handleEditingTraining: PropTypes.func,
        handleSetDogAttendedStatus: PropTypes.func,
        handleSetProductNoShowStatus: PropTypes.func,
        handleSetProductCancelStatus: PropTypes.func,
    };

    componentWillMount() {
        const { routeParams, setNewClassOccurrenceEvent, setNewTrainingEvent } = this.props;

        if (routeParams.id === 'new') {
            if (routeParams.events === 'classes') {
                setNewClassOccurrenceEvent();
            }
            if (routeParams.events === 'trainings') {
                setNewTrainingEvent();
            }
        }
    }

    componentDidMount() {
        const { getCurrentEvent, routeParams } = this.props;
        if (routeParams.id !== 'new') {
            getCurrentEvent({
                context: this.eventContextMap[routeParams.events].resource,
                id: routeParams.id,
            });
        }
    }

    componentWillUnmount() {
        const { resetState } = this.props;
        this.resetForm();
        resetState();
    }

    eventContextMap = {
        classes: {
            resource: 'class-occurrences',
        },
        trainings: {
            resource: 'training-events-composite',
        },
    };

    goBack = () => {
        const { currentEvent, dispatchPush, formState, openEventKeepChangesModalDialog, routeParams, } = this.props;

        if (!formState[currentEvent.getIn(['formConfig', 'form'])].isPristine && routeParams.id !== 'new') {
            openEventKeepChangesModalDialog();
            return;
        }

        dispatchPush('/dashboard/calendar/');
        this.resetForm();
    };

    resetForm = () => {
        const { reset, currentEvent } = this.props;
        reset(currentEvent.getIn(['formConfig', 'form']));
    };

    render() {
        const {
            handleEditingUpcomingClass, handleCancellingUpcomingClass, handleOpeningCancelUpcomingClassModal,
            handleEditingUpcomingClassStatus, handleEditingPreviousClassStatus, handleOpeningCreateDogReportModalDialog,
            handleOpeningEditDogReportModalDialog, handleSettingClassEmptyReports, currentEvent, cancelTrainingProduct,
            handleEditingTraining, handleSetDogAttendedStatus, handleSetProductNoShowStatus,
            handleSetProductCancelStatus,
        } = this.props;

        const type = currentEvent.getIn(['event', 'type']);

        const attendingDogsActions = {
            'edit': type === 'class' ? handleEditingUpcomingClass : handleEditingTraining,
            'attended': type === 'class' ? handleEditingUpcomingClassStatus : handleSetDogAttendedStatus,
            'attendedChangeTo': type === 'class' ? handleEditingPreviousClassStatus : handleSetDogAttendedStatus,
            'cancel': type === 'class' ? handleCancellingUpcomingClass : cancelTrainingProduct,
            'cancelPast': type === 'class' ? handleEditingPreviousClassStatus : handleSetProductCancelStatus,
            'cancelPenaltyChangeTo': type === 'class' ? handleEditingPreviousClassStatus : null,
            'cancelWaiveChangeTo': type === 'class' ? handleEditingPreviousClassStatus : null,
            'cancelLate': type === 'class' ? handleOpeningCancelUpcomingClassModal : null,
            'noShow': type === 'class' ? handleEditingUpcomingClassStatus : handleSetProductNoShowStatus,
            'noShowChangeTo': type === 'class' ? handleEditingPreviousClassStatus : handleSetProductNoShowStatus,
            'reportCreate': handleOpeningCreateDogReportModalDialog,
            'reportEdit': handleOpeningEditDogReportModalDialog,
            'reportDelete': handleSettingClassEmptyReports,
        };
        return (
            <EventDetailPage {...this.props} attendingDogsActions={attendingDogsActions} goBack={this.goBack}/>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentEvent: state.getIn(['currentEvent']),
        formState: mapFormToState(
            [state.getIn(['currentEvent', 'formConfig', 'form']), 'classEventDogSearch', 'trainingEventDogSearch'],
            state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    resetState: bindActionCreators(EventActions.resetCurrentEventReducer, dispatch),
    getCurrentEvent: bindActionCreators(EventService.getCurrentEventWithProducts, dispatch),
    setNewClassOccurrenceEvent: bindActionCreators(EventActions.setNewClassOccurrenceEvent, dispatch),
    setNewTrainingEvent: bindActionCreators(EventActions.setNewTrainingEvent, dispatch),
    submit: bindActionCreators(FormActions.dispatchSubmit, dispatch),
    reset: bindActionCreators(FormActions.dispatchReset, dispatch),
    dispatchPush: bindActionCreators(push, dispatch),
    changeField: bindActionCreators(FormActions.dispatchChangeField, dispatch),
    openProgramChangeWarning: bindActionCreators(ModalOpenActions.openTrainingProgramChangeWarningModalDialog,
        dispatch),
    checkInstructorAvailability: bindActionCreators(SubmitActions.changeNewEventTrainingProgram, dispatch),
    openEventCancellationModalDialog: bindActionCreators(ModalOpenActions.openEventCancellationModalDialog, dispatch),
    openEventKeepChangesModalDialog: bindActionCreators(ModalOpenActions.openEventKeepChangesModalDialog, dispatch),
    handleEditingUpcomingClass: bindActionCreators(ModalOpenActions.openEditUpcomingClassModalDialog, dispatch),
    handleCancellingUpcomingClass: bindActionCreators(classesActions.markDogsUpcomingClass, dispatch),
    handleOpeningCancelUpcomingClassModal: bindActionCreators(
        ModalOpenActions.openCancelDogsUpcomingClassModalDialog, dispatch),
    handleEditingUpcomingClassStatus: bindActionCreators(classesActions.markDogsUpcomingClass, dispatch),
    handleSetDogAttendedStatus: bindActionCreators(EventActions.setProductAttendedStatus, dispatch),
    handleEditingPreviousClassStatus: bindActionCreators(classesActions.markDogsPreviousClass, dispatch),
    handleOpeningCreateDogReportModalDialog: bindActionCreators(ModalOpenActions.openCreateDogReportModalDialog,
        dispatch),
    handleOpeningEditDogReportModalDialog: bindActionCreators(ModalOpenActions.openEditDogReportModalDialog, dispatch),
    handleSettingClassEmptyReports: bindActionCreators(classesActions.setClassEmptyReports, dispatch),
    cancelTrainingProduct: bindActionCreators(EventActions.cancelTrainingProduct, dispatch),
    handleEditingTraining: bindActionCreators(ModalOpenActions.openEditTrainingModalDialog, dispatch),
    handleSetProductNoShowStatus: bindActionCreators(EventActions.setProductNoShowStatus, dispatch),
    handleSetProductCancelStatus: bindActionCreators(EventActions.setProductCancelStatus, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventContainer);
