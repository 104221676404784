import React from 'react';

class ConfirmClassCancellationModalDialog extends React.Component {

  render() {
    const {
      data: {
        modalData: {
          action,
          rowData,
        },
      },
      onClose,
    } = this.props;
    const { noNotification } = rowData;

    const notificationText = noNotification ?
      ' without sending a notification to the owner' :
      ' and send a notification to the owner';
    const text = `Are you sure you would like to cancel the class${notificationText}?`;

    return (
      <div class="modal-dialog__form" style={{ marginTop: 30 }}>
        <div class="modal-dialog__headline">
          <div class="modal-dialog__headline-subtitle">
            {text}
          </div>
        </div>
        <div class="modal-dialog__form-footer modal-dialog__form-footer_column">
          <button
            class="button modal-dialog__button modal-dialog__button_fee"
            onClick={() => {
              action(rowData, 2);
              onClose();
            }}
          >
            Cancel class
          </button>
          <div class="modal-dialog__cancel" onClick={onClose}>
            Cancel
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmClassCancellationModalDialog;
