export const SET_BREEDS = 'SET_BREEDS';

export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const SET_NEXT_CUSTOMERS = 'SET_NEXT_CUSTOMERS';
export const ADD_CUSTOMER_TO_CUSTOMERS_LIST = 'ADD_CUSTOMER_TO_CUSTOMERS_LIST';
export const START_CUSTOMERS_LOADING = 'START_CUSTOMERS_LOADING';
export const FINISH_CUSTOMERS_LOADING = 'FINISH_CUSTOMERS_LOADING';

export const REMOVE_CUSTOMER_FROM_LIST = 'REMOVE_CUSTOMER_FROM_LIST';

export const SET_CURRENT_CUSTOMER_DATA = 'SET_CURRENT_CUSTOMER_DATA';
export const CLEAR_CURRENT_CUSTOMER_DATA = 'CLEAR_CURRENT_CUSTOMER_DATA';
export const START_CURRENT_CUSTOMER_LOADING = 'START_CURRENT_CUSTOMER_LOADING';
export const FINISH_CURRENT_CUSTOMER_LOADING = 'FINISH_CURRENT_CUSTOMER_LOADING';
export const SET_DEFAULT_PICKUP_ADDRESS = 'SET_DEFAULT_PICKUP_ADDRESS';
export const SET_DEFAULT_DROPOFF_ADDRESS = 'SET_DEFAULT_DROPOFF_ADDRESS';

export const SET_DOGS = 'SET_DOGS';
export const SET_NEXT_DOGS = 'SET_NEXT_DOGS';
export const PREPEND_DOGS = 'PREPEND_DOGS';
export const APPEND_DOGS = 'APPEND_DOGS';
export const REMOVE_DOG_FROM_LIST = 'REMOVE_DOG_FROM_LIST';
export const START_DOGS_LOADING = 'START_DOGS_LOADING';
export const FINISH_DOGS_LOADING = 'FINISH_DOGS_LOADING';
export const UPDATE_DOG_PHOTO = 'UPDATE_DOG_PHOTO';

export const SET_CURRENT_DOG_DATA = 'SET_CURRENT_DOG_DATA';
export const UPDATE_CURRENT_DOG_DATA = 'UPDATE_CURRENT_DOG_DATA';
export const CLEAR_CURRENT_DOG_DATA = 'CLEAR_CURRENT_DOG_DATA';
export const SET_ERROR_SUBMITTING_DOG_EVALUATION = 'SET_ERROR_SUBMITTING_DOG_EVALUATION';
export const START_CURRENT_DOG_LOADING = 'START_CURRENT_DOG_LOADING';
export const FINISH_CURRENT_DOG_LOADING = 'FINISH_CURRENT_DOG_LOADING';

export const SET_CUSTOMER_NOTIFICATION = 'SET_CUSTOMER_NOTIFICATION';
export const SET_SUBSCRIBED_TO_EMAIL = 'SET_SUBSCRIBED_TO_EMAIL';
