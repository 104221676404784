const validate = values => {
    const errors = {};
    if (!values.get('name')) {
        errors.name = 'Name is Required.';
    }
    if (!values.get('gender')) {
        errors.gender = 'Sex is Required.';
    }
    if (!values.get('breed')) {
        errors.breed = 'Breed is Required.';
    }
    if (!values.get('weight')) {
        errors.weight = 'Weight is Required.';
    }
    if (!values.get('month')) {
        errors.month = 'Month is Required.';
    }
    if (!values.get('year')) {
        errors.year = 'Year is Required.';
    }
    // if (!values.get('neutered')) {
    //     errors.neutered = 'Field is Required.';
    // }
    // if (!values.get('assessment')) {
    //     errors.assessment = 'Field is Required.';
    // }
    return errors;
};

export default validate;
