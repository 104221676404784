import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import renderSelectControl from '../Form/SelectControl';
import renderInputControl from '../Form/InputControl';
import { weekDays } from '../../../helpers/options';

class FilterForm extends React.PureComponent {
    state = {
        tags: fromJS([]),
        queryObject: fromJS({}),
        queryString: '',
    };

    componentWillMount() {
        const { filtersRef } = this.props;
        if (filtersRef) {
            filtersRef(this);
        }
    }

    clearFilters() {
        const { reset, filterTags, onChangeHandler } = this.props;
        reset();
        const newStateObject = {
            tags: fromJS([]),
            queryObject: fromJS({}),
            queryString: '',
        };

        this.setState(newStateObject);

        if (filterTags) {
            filterTags.setState({
                tags: newStateObject.tags,
            });
        }

        onChangeHandler(newStateObject);
    }

    removeTagByIndex(tagIndex) {
        const { tags } = this.state;
        const { filterTags, change, name, onChangeHandler } = this.props;
        const deletedTag = tags.get(tagIndex);
        const updatedTagsArray = tags.remove(tagIndex);
        const queryData = this.convertToQuery(updatedTagsArray.toJS());

        const fieldName = 'filter-' + (name || 'common') + '-field-' + deletedTag.get('filterName');

        const newFieldValue = updatedTagsArray.toJS().reduce((resultArray, tagItem) => {
            if (deletedTag.get('filterName') === tagItem.filterName) {
                resultArray.push(
                    Object.assign({}, tagItem, {
                        clearableValue: false,
                    }),
                );
            }
            return resultArray;
        }, []);

        change(fieldName, newFieldValue.length > 0 ? newFieldValue : '');

        const newStateObject = Object.assign({}, this.state, {
            tags: updatedTagsArray,
            queryObject: fromJS(queryData.queryObject),
            queryString: queryData.queryString,
        });

        this.setState(newStateObject);

        if (filterTags) {
            filterTags.setState({
                tags: updatedTagsArray,
            });
        }

        onChangeHandler(newStateObject);
    }

    convertObjectToQueryString(obj, prefix) {
        const queryString = Object.keys(obj).reduce((queryArray, objProperty) => {
            if (Object.prototype.hasOwnProperty.call(obj, objProperty)) {
                const propertyPrefix = prefix ? prefix + '[' + objProperty + ']' : objProperty;
                const property = obj[objProperty];
                queryArray.push(
                    property !== null && typeof property === 'object'
                        ? this.convertObjectToQueryString(property, propertyPrefix)
                        : encodeURIComponent(propertyPrefix) + '=' + encodeURIComponent(property),
                );
            }
            return queryArray;
        }, []);
        return queryString.join('&');
    }

    convertToQuery(tags) {
        const recurrenceData = {};
        const queryObject = tags.reduce((resultObject, tagItem) => {
            if (tagItem.query === 'recurrence_rule') {
                if (tagItem.weekday) {
                    if (recurrenceData.weekdays === undefined) {
                        recurrenceData.weekdays = [];
                    }
                    recurrenceData.weekdays.push(
                        weekDays.findIndex(weekDay => {
                            return weekDay.value === tagItem.value;
                        }),
                    );
                }
            } else if (resultObject[tagItem.query] !== undefined) {
                if (tagItem.array) {
                    resultObject[tagItem.query].push(tagItem.value);
                } else {
                    resultObject[tagItem.query] += ',' + tagItem.value;
                }
            } else if (tagItem.array) {
                    resultObject[tagItem.query] = [];
                    resultObject[tagItem.query].push(tagItem.value);
                } else {
                    resultObject[tagItem.query] = tagItem.value;
                }
            return resultObject;
        }, {});

        if (Object.keys(recurrenceData).length !== 0) {
            if (recurrenceData.weekdays !== undefined) {
                if (recurrenceData.weekdays.length === 7) {
                    queryObject.recurrence_rule = JSON.stringify({
                        freq: 3,
                    });
                } else {
                    queryObject.recurrence_rule = JSON.stringify({
                        freq: 2,
                        byweekday: recurrenceData.weekdays.sort((a, b) => {
                            return a - b;
                        }),
                    });
                }
            }
        }

        const queryString = this.convertObjectToQueryString(queryObject);

        return {
            queryObject,
            queryString,
        };
    }

    filterOnChange(filterValue, filterData) {
        const { onChangeHandler, filterTags } = this.props;
        const { tags } = this.state;
        let tagData;
        let changes = false;

        if (typeof filterValue === 'string') {
            tagData = {
                value: filterValue,
                label: filterValue,
                filterName: filterData.get('name'),
                query: filterData.get('query'),
            };
            if (filterValue.length === 0) {
                tagData.delete = true;
            }
        } else if (Array.isArray(filterValue)) {
            tagData = filterValue.reduce((filterValuesArray, filterItem) => {
                const tagItem = {
                    value: filterItem.value,
                    label: filterItem.label,
                    filterName: filterData.get('name'),
                    query: filterData.get('query'),
                };

                if (filterData.get('array')) {
                    tagItem.array = true;
                }
                if (filterItem.weekday) {
                    tagItem.weekday = true;
                }
                filterValuesArray.push(tagItem);
                return filterValuesArray;
            }, []);
        } else if (filterValue instanceof Object) {
            tagData = {
                value: filterValue.value,
                label: filterValue.label,
                filterName: filterData.get('name'),
                query: filterData.get('query'),
            };
        }

        let updatedTagsArray = tags.toJS().reduce((resultArray, tagItem, tagItemIndex, initialArray) => {
            if (tagItem.filterName === filterData.get('name')) {
                if (Array.isArray(tagData)) {
                    let _found = false;
                    tagData.every((newArrayTag, newArrayTagIndex) => {
                        if (newArrayTag.value === tagItem.value) {
                            _found = true;
                            tagData.splice(newArrayTagIndex, 1);
                        }
                        return !_found;
                    });

                    if (_found) {
                        resultArray.push(tagItem);
                    } else {
                        changes = true;
                    }
                } else if (tagData instanceof Object) {
                    if (tagData.value === tagItem.value) {
                        resultArray.push(tagItem);
                        tagData.exist = true;
                    }
                } else if (tagData === undefined) {
                    changes = true;
                }
            } else {
                resultArray.push(tagItem);
            }

            return resultArray;
        }, []);

        if (Array.isArray(tagData)) {
            if (tagData.length !== 0) {
                updatedTagsArray = updatedTagsArray.concat(tagData);
                changes = true;
            }
        } else if (tagData instanceof Object) {
            if (!tagData.delete && !tagData.exist) {
                updatedTagsArray.push(tagData);
                changes = true;
            }
        }

        if (changes) {
            const queryData = this.convertToQuery(updatedTagsArray);

            const newStateObject = {
                tags: fromJS(updatedTagsArray),
                queryObject: fromJS(queryData.queryObject),
                queryString: queryData.queryString,
            };

            this.setState(Object.assign({}, this.state, newStateObject));

            onChangeHandler(newStateObject, filterValue);
            if (filterTags) {
                filterTags.setState({
                    tags: newStateObject.tags,
                });
            }
        }
    }

    renderOldFiltersBlock() {
        const { options, onChangeHandler } = this.props;
        return (
            <div className='filter-form'>
                {options.map((group, i) => {
                    return (
                        <Field
                            key={'filter-field-' + i}
                            name={'filter-field-' + i}
                            type='text'
                            onChangeCallBack={onChangeHandler}
                            component={renderSelectControl}
                            options={group.get('items').toJS()}
                            label={group.get('name')}/>
                    );
                })}
            </div>
        );
    }
    render() {
        const { className, options, name, newVersion = false } = this.props;

        if (!newVersion) {
            return this.renderOldFiltersBlock();
        }

        const filterFormClassNames = {
            'filter-form': true,
        };
        if (className !== undefined) {
            filterFormClassNames[className] = true;
        }

        return (
            <div className={classNames(filterFormClassNames)} key={name || null}>
                {options.map((filter) => {
                    let returnValue = null;
                    const { fieldProps } = filter;
                    const filterId = 'filter-' + (name || 'common') + '-field-' + filter.get('name');

                    switch (filter.get('type')) {
                        case 'input':
                            returnValue = (
                                <Field
                                    {...fieldProps}
                                    key={filterId}
                                    name={filterId}
                                    type='text'
                                    onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                            this.filterOnChange(event.target.value, filter);
                                        }
                                    }}
                                    onBlur={event => {
                                        this.filterOnChange(event.target.value, filter);
                                    }}
                                    component={renderInputControl}
                                    label={filter.get('name')}
                                    ref={element => {
                                        this[filter.get('name')] = element;
                                    }}/>
                            );
                            break;
                        case 'select':
                            returnValue = (
                                <Field
                                    {...fieldProps}
                                    key={filterId}
                                    name={filterId}
                                    type='text'
                                    onChangeCallBack={value => {
                                        this.filterOnChange(value, filter);
                                    }}
                                    component={renderSelectControl}
                                    options={filter.get('options').toJS()}
                                    label={filter.get('name')}/>
                            );
                            break;
                        case 'multibox':
                            returnValue = (
                                <Field
                                    {...fieldProps}
                                    key={filterId}
                                    name={filterId}
                                    type='text'
                                    onChangeCallBack={value => {
                                        this.filterOnChange(value, filter);
                                    }}
                                    component={renderSelectControl}
                                    options={filter.get('weekdays') ? weekDays : filter.get('options').toJS()}
                                    multibox
                                    label={filter.get('name')}/>
                            );
                            break;
                        default:
                    }

                    return returnValue;
                })}
            </div>
        );
    }
}

FilterForm.propTypes = {
    className: PropTypes.string,
    onChangeHandler: PropTypes.func.isRequired,
    options: PropTypes.shape({}).isRequired,
    name: PropTypes.string,
    newVersion: PropTypes.bool,
    filterTags: PropTypes.shape({}),
    filtersRef: PropTypes.func,
    change: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({})
};

const mapStateToProps = (state, ownProps) => {
    return {
        initialValues: ownProps.initialValues
    };
};

const form = reduxForm({
    form: 'filterForm',
}, mapStateToProps);

export default form(FilterForm);
