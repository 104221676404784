import { fromJS, List } from 'immutable';
import { handleActions } from 'redux-actions';
import * as DogCreationTypes from '../types/onboarding/dogCreationTypes';

const INITIAL_STATE = fromJS({
    id: null,
    name: '',
    gender: '',
    breed: '',
    weight: '',
    month: '',
    year: '',
    is_altered: false,
    is_deceased: false,
    intolerance_list: [],
    medical_notes: ''
});

const dogCreationReducer = handleActions({
    [DogCreationTypes.SET_DEFAULT_INTRODUCTION_DATA]: (state, action) => {
        console.log(action);
        return state;
    },
    [DogCreationTypes.SET_DEFAULT_HEALTH_DATA]: (state, action) => {
        console.log(action);
        return state;
    },
    [DogCreationTypes.SET_DOG_INTRODUCTION_INFO]: (state, action) => {
        console.log('INTRODUCTION INFO', action.payload);
        // save form data locally
        if (action.payload) {
            localStorage.setItem('introductionForm', JSON.stringify(action.payload));
            return state
                .set('name', action.payload.get('name'))
                .set('gender', action.payload.get('gender').toJS().value)
                .set('breed', action.payload.get('breed').toJS().value)
                .set('weight', action.payload.get('weight').toJS().value)
                .set('month', action.payload.get('month').toJS().label)
                .set('year', action.payload.get('year').toJS().label)
                .set('is_altered', action.payload.get('is_altered') === 'Yes');
        }
        localStorage.removeItem('introductionForm');
        return state
            .set('name', undefined)
            .set('gender', undefined)
            .set('breed', undefined)
            .set('weight', undefined)
            .set('month', undefined)
            .set('year', undefined)
            .set('is_altered', undefined);
    },
    [DogCreationTypes.SET_DOG_HEALTH_INFO]: (state, action) => {
        let intoleranceList;
        // save form data locally
        localStorage.setItem('healthForm', JSON.stringify(action.payload));
        let payloadIntoleranceList = action.payload.get('intolerance_list');
        if (payloadIntoleranceList) {
            if (payloadIntoleranceList.size) {
                payloadIntoleranceList = payloadIntoleranceList.toJS();
            }
            intoleranceList = payloadIntoleranceList.map((item) => {
                return item.label;
            });
        } else {
            intoleranceList = [];
        }

        console.log(intoleranceList);
        const updatedState = state.update((state) => {
            return state
                .set('intolerance_list', fromJS(intoleranceList))
                .set('medical_notes', action.payload.get('medical_notes') || '');
        });

        return updatedState;
    },
    [DogCreationTypes.CLEAR_DOG_DATA]: (state, action) => (
        state
            .set('id', '')
            .set('name', '')
            .set('gender', '')
            .set('breed', '')
            .set('weight', '')
            .set('month', '')
            .set('year', '')
            .set('is_altered', true)
            .set('is_deceased', false)
            .set('intolerance_list', List())
            .set('medical_notes', '')
    ),
    [DogCreationTypes.SET_DOG_ID]: (state, action) => {
        return state.set('id', action.payload);
    },
    [DogCreationTypes.CLEAR_DOG_ID]: (state, action) => {
        return state.set('id', null);
    }
}, INITIAL_STATE);

export default dogCreationReducer;
