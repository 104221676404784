import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as classesActions from '../../../../../actions/dashboard/classesActions';
import * as miscActions from '../../../../../actions/miscActions';
import * as openActions from '../../../../../actions/modal/openActions';
import ClassesContent from './ClassesContent';

const ClassesPage = (props) => {
    const {
        dog, misc, handleUpcomingCollapse, handlePreviousCollapse, handleEditingUpcomingClass,
        handleEditingPreviousClassStatus, handleEditingUpcomingClassStatus, handleOpeningCancelUpcomingClassModal,
        handleCancellingUpcomingClass, handleSettingClassEmptyReports, handleOpeningCreateDogReportModalDialog,
        handleOpeningEditDogReportModalDialog, handleGetNextDogPreviousClasses, handleGetNextDogUpcomingClasses
    } = props;
    const upcomingClassesActions = {
        'edit': handleEditingUpcomingClass,
        'attended': handleEditingUpcomingClassStatus,
        'cancel': handleCancellingUpcomingClass,
        'cancelWithFee': handleOpeningCancelUpcomingClassModal, // error handling
        'no-show': handleEditingUpcomingClassStatus
    };
    const previousClassesActions = {
        'attended': handleEditingPreviousClassStatus,
        'cancel': handleEditingPreviousClassStatus,
        'no-show': handleEditingPreviousClassStatus,
        'assess-fee': handleEditingPreviousClassStatus,
        'waive-fee': handleEditingPreviousClassStatus,
        'delete-report': handleSettingClassEmptyReports,
        'create-report': handleOpeningCreateDogReportModalDialog,
        'edit-report': handleOpeningEditDogReportModalDialog,
    };
    return (
        <div className='customers-classes'>
            {dog.get('instance', 'id') === null
                ? 'Loading...'
                : (<div>
                    <ClassesContent
                        data={dog.getIn(['upcoming_classes', 'classes'])}
                        classNameModifier='classes-table_upcoming'
                        dataName='Upcoming Classes'
                        isCollapsed={misc.get('upcomingClasses')}
                        isLoading={dog.getIn(['upcoming_classes', 'isLoading'])}
                        collapseAction={handleUpcomingCollapse}
                        classesActions={upcomingClassesActions}
                        pageSize={dog.getIn(['upcoming_classes', 'pageSize'])}
                        pages={dog.getIn(['upcoming_classes', 'pages'])}
                        nextUrl={dog.getIn(['upcoming_classes', 'nextUrl'])}
                        getNext={handleGetNextDogUpcomingClasses}/>
                    <ClassesContent
                        data={dog.getIn(['previous_classes', 'classes'])}
                        dataName='Previous Classes'
                        isPrevious
                        classNameModifier='classes-table_previous'
                        isCollapsed={misc.get('previousClasses')}
                        isLoading={dog.getIn(['previous_classes', 'isLoading'])}
                        collapseAction={handlePreviousCollapse}
                        classesActions={previousClassesActions}
                        pageSize={dog.getIn(['previous_classes', 'pageSize'])}
                        pages={dog.getIn(['previous_classes', 'pages'])}
                        nextUrl={dog.getIn(['previous_classes', 'nextUrl'])}
                        getNext={handleGetNextDogPreviousClasses}/>
                </div>)
            }
        </div>
    );
};

ClassesPage.propTypes = {
    dog: PropTypes.shape({}),
    misc: PropTypes.shape({}),
    handleUpcomingCollapse: PropTypes.func,
    handlePreviousCollapse: PropTypes.func,
    handleEditingUpcomingClass: PropTypes.func,
    handleEditingPreviousClassStatus: PropTypes.func,
    handleEditingUpcomingClassStatus: PropTypes.func,
    handleOpeningCancelUpcomingClassModal: PropTypes.func,
    handleCancellingUpcomingClass: PropTypes.func,
    handleSettingClassEmptyReports: PropTypes.func,
    handleOpeningCreateDogReportModalDialog: PropTypes.func,
    handleOpeningEditDogReportModalDialog: PropTypes.func,
    handleGetNextDogPreviousClasses: PropTypes.func,
    handleGetNextDogUpcomingClasses: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        dog: state.get('currentDog'),
        misc: state.getIn(['misc', 'tablesCollapsed', 'dogs'])
    };
};

const mapDispatchToProps = dispatch => ({
    handleUpcomingCollapse: bindActionCreators(miscActions.collapseDogsUpcomingClasses, dispatch),
    handlePreviousCollapse: bindActionCreators(miscActions.collapseDogsPreviousClasses, dispatch),
    handleEditingUpcomingClass: bindActionCreators(openActions.openEditUpcomingClassModalDialog, dispatch),
    handleEditingPreviousClassStatus: bindActionCreators(classesActions.patchDogsPreviousClassStatus, dispatch),
    handleEditingUpcomingClassStatus: bindActionCreators(classesActions.markDogsUpcomingClass, dispatch),
    handleOpeningCancelUpcomingClassModal: bindActionCreators(openActions.openCancelDogsUpcomingClassModalDialog,
        dispatch),
    handleCancellingUpcomingClass: bindActionCreators(classesActions.markDogsUpcomingClass, dispatch),
    handleSettingClassEmptyReports: bindActionCreators(classesActions.setClassEmptyReports, dispatch),
    handleOpeningCreateDogReportModalDialog: bindActionCreators(openActions.openCreateDogReportModalDialog, dispatch),
    handleOpeningEditDogReportModalDialog: bindActionCreators(openActions.openEditDogReportModalDialog, dispatch),
    handleGetNextDogPreviousClasses: bindActionCreators(classesActions.getNextDogsPreviousClasses, dispatch),
    handleGetNextDogUpcomingClasses: bindActionCreators(classesActions.getNextDogsUpcomingClasses, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassesPage);
