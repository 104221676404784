import { createAction } from 'redux-actions';
import { getPurchasedPackagesTrainingPrograms } from '../../services/event';
import { finishLoadingCurrentCustomer, startLoadingCurrentCustomer } from './employeeActions';
import { setGlobalError } from '../errorHandlerActions';
import * as PackagesTrainingsProductsTypes from '../../types/dashboard/packagesTrainingsProductsTypes';

export const setCustomersPackagesTrainingProducts = createAction(
    PackagesTrainingsProductsTypes.SET_CUSTOMER_PACKAGES_TRAINING_PRODUCTS);
export const updateCustomersPackageOrTrainingProductExpiresAt = createAction(
    PackagesTrainingsProductsTypes.UPDATE_CUSTOMER_PACKAGE_OR_TRAINING_PRODUCT_EXPIRES_AT);
export const updateCustomersPackageOrTrainingProductQuantityRemaining = createAction(
    PackagesTrainingsProductsTypes.UPDATE_CUSTOMER_PACKAGE_OR_TRAINING_PRODUCT_QUANTITY_REMAINING);
export const updateCustomersPackageOrTrainingProductTrainer = createAction(
    PackagesTrainingsProductsTypes.UPDATE_CUSTOMER_PACKAGE_OR_TRAINING_PRODUCT_TRAINER);
export const markCustomersPackagesTrainingProductsLoaded = createAction(
    PackagesTrainingsProductsTypes.MARK_CUSTOMER_PACKAGES_TRAINING_PRODUCTS_LOADED);
export const unmarkCustomersPackagesTrainingProductsLoaded = createAction(
    PackagesTrainingsProductsTypes.UNMARK_CUSTOMER_PACKAGES_TRAINING_PRODUCTS_LOADED);
export const getCustomersPackagesTrainingProducts = customerId => {
    return dispatch => {
        dispatch(getPurchasedPackagesTrainingPrograms({
            role: 'employees',
            customerId,
        })).then(response => {
            dispatch(startLoadingCurrentCustomer());
            dispatch(setCustomersPackagesTrainingProducts(response));
            dispatch(finishLoadingCurrentCustomer());
            dispatch(markCustomersPackagesTrainingProductsLoaded());
        }).catch((error) => {
            console.log(error);
            console.log(error.response);
            dispatch(finishLoadingCurrentCustomer());
            dispatch(setGlobalError(error));
        });
    };
};
export const removeCustomerPackageOrTrainingProduct = createAction(
    PackagesTrainingsProductsTypes.REMOVE_CUSTOMER_PACKAGE_OR_TRAINING_PRODUCT);
export const addCustomerPackageOrTrainingProduct = createAction(
    PackagesTrainingsProductsTypes.ADD_CUSTOMER_PACKAGE_OR_TRAINING_PRODUCT);
