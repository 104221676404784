import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import InfiniteScroll from 'react-infinite-scroller';
import { getClassesHistory, getNextClassesHistory } from '../../../../../actions/customer/classesHistoryActions';
import ClassesHistoryItem from './ClassesHistoryItem/';
import AjaxLoader from '../../../../common/AjaxLoader';
import NoData from '../../../../common/NoData/';
import Button from '../../../../common/Button/';

class ClassesHistory extends PureComponent {
    componentDidMount() {
        const { count, getClassesHistory } = this.props;
        if (!count) {
            getClassesHistory();
        }
    }

    renderContent() {
        const { classesHistory, nextUrl, isNextLoading, getNextClassesHistory } = this.props;
        const items = classesHistory.map(((item, index) => {
            return <ClassesHistoryItem data={item} key={index} className='classes-history__item' />;
        }));
        return (
            <InfiniteScroll
                className='classes-history__list'
                pageStart={0}
                loadMore={() => isNextLoading ? null : getNextClassesHistory()}
                hasMore={nextUrl !== null}
                loader={<AjaxLoader />}>
                {items}
            </InfiniteScroll>
        );
    }

    renderNoData() {
        return (
            <div className='profile__block'>
                <div className='profile__headline'>
                    <div className='profile__title'>Classes history</div>
                </div>
                <div className='payment-history__content'>
                    <NoData
                        title='Looks like you haven’t signed up for any classes yet!'
                        subtitle={
                            <div>Sign up for your first class <Link to='/classes'>HERE</Link></div>
                        }
                        iconType='happy'
                        borders={false}
                        action={<Button text='View classes' link='/classes' modifierClassName='no-data__button' />} />
                </div>
            </div>
        );
    }

    render() {
        const { isLoading, count } = this.props;

        return (
            <div className='classes-history'>
                {isLoading
                    ? <AjaxLoader />
                    : count > 0 ? this.renderContent() : this.renderNoData()
                }
            </div>
        );
    }
}

ClassesHistory.propTypes = {
    getClassesHistory: PropTypes.func,
    getNextClassesHistory: PropTypes.func,
    classesHistory: PropTypes.arrayOf(PropTypes.shape({})),
    isLoading: PropTypes.bool,
    isNextLoading: PropTypes.bool,
    count: PropTypes.number,
    nextUrl: PropTypes.string,
};

const mapStateToProps = state => ({
    classesHistory: state.getIn(['classesHistory', 'items']).toJS(),
    isLoading: state.getIn(['classesHistory', 'isLoading']),
    isNextLoading: state.getIn(['classesHistory', 'isNextLoading']),
    count: state.getIn(['classesHistory', 'count']),
    nextUrl: state.getIn(['classesHistory', 'nextUrl']),
});

const mapDispatchToProps = {
    getClassesHistory,
    getNextClassesHistory
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassesHistory);
