import PropTypes from 'prop-types';
import React from 'react';
import { IndexRoute, Route, Router } from 'react-router';
import { push } from 'react-router-redux';

import App from '../components';
import CustomerContainer from '../components/containers/Customer';
import BookingPageContainer from '../components/containers/Customer/Classes/BookingPage';
import ClassesPageContainer from '../components/containers/Customer/Classes/ClassesPage/';
import CustomerDogDetailsPage from '../components/containers/Customer/Dogs/DogDetailsPage/';
import DogsPageContainer from '../components/containers/Customer/Dogs/DogsPage/';
import MainPageContainer from '../components/containers/Customer/Main/MainPage';
import PackagesContainer from '../components/containers/Customer/Packages';
import ProfileContainer from '../components/containers/Customer/Profile/';
import ChangePasswordPage from '../components/containers/Customer/Profile/ChangePassword/';
import ClassesHistoryPage from '../components/containers/Customer/Profile/ClassesHistory/';
import MyProfilePage from '../components/containers/Customer/Profile/MyProfile/';
import NotificationsPage from '../components/containers/Customer/Profile/Notifications/';
import PaymentHistoryPage from '../components/containers/Customer/Profile/PaymentHistory/';
import PaymentMethodsPage from '../components/containers/Customer/Profile/PaymentMethods/';
import PurchasePackageContainer from '../components/containers/Customer/PurchasePackage';
import ScheduleContainer from '../components/containers/Customer/Schedule/SchedulePage/';
import TrainingProgramsContainer from '../components/containers/Customer/TrainingPrograms';
import WelcomePageContainer from '../components/containers/Customer/WelcomePage';
import DashboardContainer from '../components/containers/Dashboard';
import CalendarPage from '../components/containers/Dashboard/Calendar/CalendarPage';
import EventContainer from '../components/containers/Dashboard/Calendar/EventContainer';
import ClassSchedulePage from '../components/containers/Dashboard/ClassSchedule/ClassSchedulePage';
import CustomerClassesPage from '../components/containers/Dashboard/Customers/ClassesPage';
import CustomerTrainingsPage from '../components/containers/Dashboard/Customers/TrainingsPage';
import CustomersContainer from '../components/containers/Dashboard/Customers/CustomersContainer';
import CustomersPage from '../components/containers/Dashboard/Customers/CustomersPage';
import CustomerDetailsPage from '../components/containers/Dashboard/Customers/DetailsPage';
import CustomerFormsPage from '../components/containers/Dashboard/Customers/FormsPage';
import CustomerNotesPage from '../components/containers/Dashboard/Customers/NotesPage';
import PackagesTrainingsPage from '../components/containers/Dashboard/Customers/PackagesTrainingsPage';
import TransactionsPage from '../components/containers/Dashboard/Customers/TransactionsPage';
import VaccinationsPage from '../components/containers/Dashboard/Customers/VaccinationsPage';
import DailyNotesContainer from '../components/containers/Dashboard/DailyNotesContainer';
import DashboardPageContainer from '../components/containers/Dashboard/DashboardPageContainer';
import DirectoryPage from '../components/containers/Dashboard/Directory/DirectoryPage';
import DogClassesPage from '../components/containers/Dashboard/Dogs/ClassesPage';
import DogTrainingsPage from '../components/containers/Dashboard/Dogs/TrainingsPage';
import DogDetailsPage from '../components/containers/Dashboard/Dogs/DetailsPage';
import DogsContainer from '../components/containers/Dashboard/Dogs/DogsContainer';
import DogsPage from '../components/containers/Dashboard/Dogs/DogsPage';
import EvaluationPage from '../components/containers/Dashboard/Dogs/EvaluationPage';
import DogNotesPage from '../components/containers/Dashboard/Dogs/NotesPage';
import ReportPage from '../components/containers/Dashboard/Dogs/ReportPage';
import MarketingEditorPage from '../components/MarketingEditorPage';
import MarketingPage from '../components/MarketingPage';
import FormEditCreatePageContainer from '../components/containers/Dashboard/FormEditCreatePageContainer';
import FormsPageContainer from '../components/containers/Dashboard/FormsPageContainer';
import PackagesPage from '../components/containers/Dashboard/Packages/PackagesPage';
import ReportsContainer from '../components/containers/Dashboard/Reports/ReportsContainer';
import SearchPage from '../components/containers/Dashboard/Search/SearchPage';
import FormUpdateContainer from '../components/containers/FormUpdate/';
import FormUpdatePage from '../components/containers/FormUpdate/FormUpdatePage/';
import OnBoardingContainer from '../components/containers/OnBoarding';
import ActivateEmailPage from '../components/containers/OnBoarding/ActivateEmailPage/';
import EmployeeCreatePasswordPageContainer from '../components/containers/OnBoarding/EmployeeCreatePasswordPage';
import EmployeeCreatePasswordSuccessPage from '../components/containers/OnBoarding/EmployeeCreatePasswordSuccessPage';
import EmployeeForgotPasswordPageContainer from '../components/containers/OnBoarding/EmployeeForgotPasswordPage';
import EmployeePasswordSentPageContainer from '../components/containers/OnBoarding/EmployeePasswordSentPage';
import EmployeeResetPasswordPageContainer from '../components/containers/OnBoarding/EmployeeResetPasswordPage';
import EmployeeSignInPageContainer from '../components/containers/OnBoarding/EmployeesSignInPage';
import FinishSignUpPageContainer from '../components/containers/OnBoarding/FinishSignUpPage';
import ForgotPasswordPageContainer from '../components/containers/OnBoarding/ForgotPasswordPage';
import ForgotPasswordSentPageContainer from '../components/containers/OnBoarding/ForgotPasswordSentPage';
import HealthPageContainer from '../components/containers/OnBoarding/HealthPage';
import IntroductionPageContainer from '../components/containers/OnBoarding/IntroductionPage';
import ResetPasswordPageContainer from '../components/containers/OnBoarding/ResetPasswordPage';
import SignInPageContainer from '../components/containers/OnBoarding/SignInPage';
import SignUpPageContainer from '../components/containers/OnBoarding/SignUpPage';
import VetInfoPageContainer from '../components/containers/OnBoarding/VetInfoPage';
import WaiverPageContainer from '../components/containers/OnBoarding/WaiverPage';
import WelcomeBackPageContainer from '../components/containers/OnBoarding/WelcomeBackPage';
import ProfileEditPage from '../components/containers/Profile/ProfileEditPage';
import CustomerAgreementPage from "../components/containers/Dashboard/Customers/AgreementsPage";

import { APP_ROOT } from '../config';
import { isCustomer, isTrainer } from '../helpers/userRoles';
import { getAccessToken } from '../services/oauth';
import { setOriginalPath } from '../actions/originalPathActions';



const requireAuth = (store, url, nextState) => {
    const isLoggedIn = getAccessToken();
    if (!isLoggedIn) {
        const { pathname, search } = nextState.location;
        if (pathname) store.dispatch(setOriginalPath(pathname + search));
        store.dispatch(push(url));
    }
};

const requireCustomerAuth = (store, url, nextState) => {
    const isLoggedIn = getAccessToken();
    if (!isLoggedIn || !isCustomer()) {
        const { pathname, search } = nextState.location;
        if (pathname) store.dispatch(setOriginalPath(pathname + search));
        store.dispatch(push(url));
    }
};

const requireEmployeeAuth = (store, url, nextState) => {
    const isLoggedIn = getAccessToken();
    if (!isLoggedIn || isCustomer()) {
        const { pathname, search } = nextState.location;
        if (pathname) store.dispatch(setOriginalPath(pathname + search));
        store.dispatch(push(url));
    }
};

const onEnter = (store, state) => {
    const isLoggedIn = getAccessToken();

    if (isLoggedIn) {
        if (state.location.pathname === APP_ROOT && isCustomer()) {
            store.dispatch(push('/home'));
        } else if (state.location.pathname === '/employee' && !isCustomer()) {
            store.dispatch(push('/dashboard'));
        }
    }
};

export const Routes = ({ store, history }) => (
    <Router key={Math.random()} history={history}>
        <Route path={APP_ROOT} component={App} onEnter={(state) => onEnter(store, state)}>
            <Route component={OnBoardingContainer}>
                <IndexRoute component={SignInPageContainer}/>
                <Route path='/reset-password/:code*' component={ResetPasswordPageContainer}/>
                <Route path='/forgot-password-sent' component={ForgotPasswordSentPageContainer}/>
                <Route path='/forgot-password' component={ForgotPasswordPageContainer}/>
                <Route path='/employee/create-password' component={EmployeeCreatePasswordPageContainer}/>
                <Route path='/employee/create-password-success' component={EmployeeCreatePasswordSuccessPage}/>
                <Route path='/welcome-back' component={WelcomeBackPageContainer}/>
                <Route path='/finish-sign-up' component={FinishSignUpPageContainer}/>
                <Route path='/sign-up' component={SignUpPageContainer}/>
                <Route path='/introduction' component={IntroductionPageContainer}/>
                <Route path='/health' component={HealthPageContainer}/>
                <Route path='/vet-info' component={VetInfoPageContainer}/>
                <Route path='/waiver' component={WaiverPageContainer}/>
                <Route path='/activate' component={ActivateEmailPage}/>
                <Route path='/employee' component={EmployeeSignInPageContainer}/>
                <Route path='/create-password' component={EmployeeCreatePasswordPageContainer}/>
                <Route path='/create-password-success' component={EmployeeCreatePasswordSuccessPage}/>
                <Route path='/employee/forgot-password' component={EmployeeForgotPasswordPageContainer}/>
                <Route path='/employee/forgot-password-sent' component={EmployeePasswordSentPageContainer}/>
                <Route path='/employee/reset-password/:code*' component={EmployeeResetPasswordPageContainer}/>
            </Route>
            <Route component={DashboardContainer} onEnter={(nextState) => requireEmployeeAuth(store, '/employee', nextState)}>
                {<Route path='/dashboard' component={DashboardPageContainer}/>}
                {!isTrainer() && <Route path='/dashboard/customers' component={CustomersPage}/>}
                <Route component={CustomersContainer}>
                    <Route path='/dashboard/customer/:customer/details' component={CustomerDetailsPage}/>
                    <Route path='/dashboard/customer/:customer/classes' component={CustomerClassesPage}/>
                    <Route path='/dashboard/customer/:customer/trainings' component={CustomerTrainingsPage}/>
                    <Route path='/dashboard/customer/:customer/notes' component={CustomerNotesPage}/>
                    <Route path='/dashboard/customer/:customer/forms' component={CustomerFormsPage}/>
                    <Route path='/dashboard/customer/:customer/forms/:agreement/' component={CustomerAgreementPage}/>
                    <Route path='/dashboard/customer/:customer/transactions' component={TransactionsPage}/>
                    <Route path='/dashboard/customer/:customer/vaccinations' component={VaccinationsPage}/>
                    <Route path='/dashboard/customer/:customer/packages-trainings' component={PackagesTrainingsPage}/>
                </Route>
                {!isTrainer() && <Route path='/dashboard/dogs' component={DogsPage}/>}
                <Route component={DogsContainer}>
                    <Route path='/dashboard/dog/:dog/details' component={DogDetailsPage}/>
                    <Route path='/dashboard/dog/:dog/classes' component={DogClassesPage}/>
                    <Route path='/dashboard/dog/:dog/trainings' component={DogTrainingsPage}/>
                    <Route path='/dashboard/dog/:dog/notes' component={DogNotesPage}/>
                    <Route path='/dashboard/dog/:dog/evaluation' component={EvaluationPage}/>
                    <Route path='/dashboard/dog/:dog/reports' component={ReportPage}/>
                </Route>
                <Route path='/dashboard/daily-notes' component={DailyNotesContainer}/>
                <Route path='/dashboard/directory' component={DirectoryPage}/>
                <Route path='/dashboard/class-schedule' component={ClassSchedulePage}/>
                <Route path='/dashboard/packages' component={PackagesPage}/>
                <Route path='/dashboard/search' component={SearchPage}/>
                <Route path='/dashboard/calendar' component={CalendarPage}/>
                <Route path='/dashboard/calendar/:events/:id' component={EventContainer}/>
                <Route path='/dashboard/calendar/classes/new' component={EventContainer}/>
                <Route path='/dashboard/calendar/trainings/new' component={EventContainer}/>
                <Route path='/dashboard/profile/edit' component={ProfileEditPage}/>
                {!isTrainer() && <Route path='/dashboard/forms' component={FormsPageContainer}/>}
                {!isTrainer() && <Route path='/dashboard/forms/:id' component={FormEditCreatePageContainer}/>}
                {<Route path='/dashboard/reports' component={ReportsContainer}/>}
                <Route path='/dashboard/marketing' component={MarketingPage}/>
                <Route path='/dashboard/marketing/:id' component={MarketingEditorPage}/>
            </Route>
            <Route component={CustomerContainer} onEnter={(nextState) => requireCustomerAuth(store, '/', nextState)}>
                <Route path='/home' component={MainPageContainer}/>
                <Route path='/training-programs' component={TrainingProgramsContainer}/>
                <Route path='/classes' component={ClassesPageContainer}/>
                <Route path='/classes/:classId/book' component={BookingPageContainer}/>
                <Route path='/dogs' component={DogsPageContainer}/>
                <Route path='/dogs/:dog' component={CustomerDogDetailsPage}/>
                <Route path='/packages' component={PackagesContainer}/>
                <Route path='/package/:id/purchase' component={PurchasePackageContainer}/>
                <Route path='/schedule' component={ScheduleContainer}/>
                <Route path='/welcome' component={WelcomePageContainer}/>
                <Route component={ProfileContainer}>
                    <Route path='/profile' component={MyProfilePage}/>
                    <Route path='/payment-methods' component={PaymentMethodsPage}/>
                    <Route path='/payment-history' component={PaymentHistoryPage}/>
                    <Route path='/classes-history' component={ClassesHistoryPage}/>
                    <Route path='/notifications' component={NotificationsPage}/>
                    <Route path='/change-password' component={ChangePasswordPage}/>
                </Route>
            </Route>
            <Route component={FormUpdateContainer} onEnter={(nextState) => requireAuth(store, '/', nextState)}>
                <Route path='/form-update/:id' component={FormUpdatePage}/>
            </Route>
        </Route>
    </Router>
);

Routes.propTypes = {
    history: PropTypes.shape({}).isRequired,
    store: PropTypes.shape({}).isRequired,
};
