import { createAction } from 'redux-actions';
import * as ClassOccurrencesTypes from '../../types/dashboard/classOccurrencesTypes';

export const setClassOccurrencesList = createAction(ClassOccurrencesTypes.SET_CLASS_OCCURRENCES_LIST);
export const setNextClassOccurrencesList = createAction(ClassOccurrencesTypes.SET_NEXT_CLASS_OCCURRENCES_LIST);
export const startClassOccurrencesListLoading = createAction(
    ClassOccurrencesTypes.START_CLASS_OCCURRENCES_LIST_LOADING);
export const finishClassOccurrencesListLoading = createAction(
    ClassOccurrencesTypes.FINISH_CLASS_OCCURRENCES_LIST_LOADING);
export const startClassOccurrencesNextListLoading = createAction(
    ClassOccurrencesTypes.START_CLASS_OCCURRENCES_NEXT_LIST_LOADING);
export const finishClassOccurrencesNextListLoading = createAction(
    ClassOccurrencesTypes.FINISH_CLASS_OCCURRENCES_NEXT_LIST_LOADING);
