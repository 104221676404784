import { createAction } from 'redux-actions';
import { generateAlertMeta } from '../../components/Alert/actions';
import * as ScheduleTypes from '../../types/customer/scheduleTypes';
import * as SheduleServices from '../../services/schedule';

export const startLoadingSchedule = createAction(ScheduleTypes.START_SCHEDULE_LOADING);
export const finishLoadingSchedule = createAction(ScheduleTypes.FINISH_SCHEDULE_LOADING);
export const setSchedule = createAction(ScheduleTypes.SET_SCHEDULE);
export const setNextSchedule = createAction(ScheduleTypes.SET_NEXT_SCHEDULE);
export const removeScheduleEvent = createAction(ScheduleTypes.REMOVE_SCHEDULE_EVENT);

export const updateScheduleLocation = createAction(ScheduleTypes.UPDATE_SCHEDULE_LOCATION);
export const removeDogFromScheduleEvent = createAction(ScheduleTypes.REMOVE_DOG_FROM_EVENT);

export const changeScheduleAddress = (data) => {
    return async (dispatch) => {
        const { dogs } = data;
        const sendPromises = dogs.map((dog) => {
            const { productId } = dog;
            const request = {
                [data.locationType]: data.location,
                productId
            };
            return dispatch(SheduleServices.changeScheduleAddress(request))
                .then((responce) => {
                    dispatch(updateScheduleLocation(responce));
                    dispatch(generateAlertMeta({
                        event: 'CHANGE_SCHEDULE_CLASS_ADDRESS',
                        responseData: { addressType: data.locationType },
                    }));
                });
        });
        await Promise.all(sendPromises);
    };
};
