import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { untouch, change } from 'redux-form';
import { Field } from 'redux-form/immutable';

import { ReactComponent as IconRemove21 } from '../../../../assets/icons/icon-remove-21.svg';
import Icon from '../../Icon';
import AddNewItem from '../../AddNewItem';
import renderImageControl from '../ImageControl';
import ReportCardImageUpload from '../../../common/Form/ImageControl/ReportCardImageUpload';
import DogReportVideos from '../DogReportVideos';

class DogReportEditControl extends React.PureComponent {
    static propTypes = {
        commonProps: PropTypes.shape({}),
        fieldDefinition: PropTypes.shape({}), 
        formProps: PropTypes.shape({
            formValues: PropTypes.shape({})
        }),
        isOpened: PropTypes.bool, 
        removeFieldValue: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const { snapshots } = props.formProps.data.initialValues; //eslint-disable-line
        const numPhotos = (snapshots && snapshots.length) ? snapshots.length : 1;

        this.state = { 
            photoIds: [...Array(numPhotos).keys()],
            nextPhotoId: numPhotos 
        };
    }

    onAdditionalPhoto() {
        const { photoIds, nextPhotoId } = this.state;

        this.setState({
            photoIds: [...photoIds, nextPhotoId],
            nextPhotoId: nextPhotoId + 1
        });
    }

    onRemovePhoto(id) {
        const { photoIds } = this.state;
        const { removeFieldValue } = this.props;

        if (photoIds.length > 0) {
            const index = photoIds.indexOf(id);
            if (index >= 0) {
                // Update state
                const newPhotoIds = [...photoIds];
                newPhotoIds.splice(index, 1);
                this.setState({
                    photoIds: newPhotoIds
                });

                // Remove photo from form data submitted to the backend
                const field = this.makePhotoKey(id);
                removeFieldValue(field);
            }
        }
    }

    getIconIndex(icons, iconValue) {
        return icons.findIndex(icon => {
            return icon.type === iconValue;
        });
    }

    getIconLabel(icons, iconValue) {
        return icons[this.getIconIndex(icons, iconValue)].label;
    }

    getIconOptionLabel(formValuesJS, iconValue) {
        if (!(iconValue in formValuesJS)) {
            return null;
        }

        return formValuesJS[iconValue].label;
    }

    makePhotoKey(id) { 
        return 'photo_' + id; 
    }

    renderOverlayItems(formValuesJS, icons) {
        const items = [];
        for (let i = 0; i < 3; i++) { //eslint-disable-line
            if (formValuesJS.icon_select && i in formValuesJS.icon_select) {
                items.push(
                    <div
                        className='dog-report-form__overlay-item'
                        key={this.getIconLabel(icons, formValuesJS.icon_select[i])}>
                        <div className='dog-report-form__overlay-item-container'>
                            <div
                                className={`dog-report-form__icon_${formValuesJS.icon_select[i]}
                                dog-report-form__icon_${formValuesJS.icon_select[i]}_active`}/>
                            <div className='dog-report-form__overlay-item-info'>
                                <div className='dog-report-form__overlay-item-label'>
                                    {this.getIconLabel(icons, formValuesJS.icon_select[i])}
                                </div>
                                <div className='dog-report-form__overlay-item-value'>
                                    {this.getIconOptionLabel(formValuesJS, formValuesJS.icon_select[i]) || 'Select value'}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                items.push(
                    <div className='dog-report-form__overlay-item' key={'report_icon_' + i}>
                        <div className='dog-report-form__overlay-item-label'>
                            Select icon
                        </div>
                    </div>
                );
            }
        }

        return items;
    }

    renderOverlay(formValuesJS, icons) {
        return (
            <div className='dog-report-form__image-upload-overlay'>
                {this.renderOverlayItems(formValuesJS, icons)}
            </div>
        );
    }

    renderContent() {
        const {
            commonProps,
            isOpened,
            formProps,
            fieldDefinition,
        } = this.props;
        const { photoIds } = this.state;
        const { formValues } = formProps;
        const { validate, icons } = fieldDefinition;
        const { snapshots } = formProps.data.initialValues; // eslint-disable-line

        const formValuesJS = formValues ? formValues.toJS() : {};
        const reversedIds = [...photoIds];
        reversedIds.reverse();
        const photos = reversedIds.map((id) => {
            const key = this.makePhotoKey(id);
            const initialImage = (snapshots && (id < snapshots.length)) ? snapshots[id].photo_desktop : null;
            return (
                <div key={key}>
                    <div className='dog-report-form__image-upload-field'>
                        <Field
                            {...commonProps}
                            name={key}
                            type='file'
                            isOpened={isOpened}
                            uploadComponent={ReportCardImageUpload}
                            component={renderImageControl}
                            validate={validate}
                            image={initialImage}/>
                        {(photoIds.length > 1) && <div className='dog-report-form__remove-photo' onClick={() => this.onRemovePhoto(id)}>
                            <Icon glyph={IconRemove21} className='icon_remove_21'/>
                        </div>}
                    </div>
                </div>
            );
        });

        return (
            <div>
                {photos}
                {this.renderVideos()}
                <div style={{ 'marginTop': '1rem' }}>
                    {this.renderOverlay(formValuesJS, icons)}
                    <div className='dog-report-form__image-upload-footer'>
                        <div className='dog-report-form__title'>
                            {formValuesJS.title || '(Title Unset)'}
                        </div>
                        <div className='dog-report-form__date'>
                            {formValuesJS.date || '(Date Unset)'}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderVideos() {
        return (
            <DogReportVideos
                initialVideoUrls={this.props.formProps.data.initialValues.snapshot_video_urls}/>
        );
    }

    render() {
        const { formProps, name } = this.props;
        let dogName;

        if (formProps.initialValues) {
            const initialValues = formProps.initialValues.toJS();
            dogName = initialValues.dogName;
        }

        if (!dogName) dogName = name;

        return (
            <div className='dog-report-form__edit-control'>
                <div className='dog-report__title'>
                    {dogName}
                </div>
                <div className='dog-report-form__add-new-item-wrapper'>
                    <AddNewItem
                        text='Additional Photo'
                        onClickHandler={() => this.onAdditionalPhoto()} />
                </div>
                {this.renderContent()}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
  // This doesnt work from the class view
    return { name: state.getIn(['currentDog', 'instance', 'name']) };
};

const mapDispatchToProps = (dispatch) => ({
    removeFieldValue: (field) => {
        dispatch(change('EditCreateModalDialogForm', field, null));
        dispatch(untouch('EditCreateModalDialogForm', field));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(DogReportEditControl);
