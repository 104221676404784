import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { DEFAULT_TIMEZONE, DEFAULT_TIMEZONE_DATA } from '../config';
import Modal from './common/Modal';
import ModalSidebar from './common/ModalSidebar';
import { isDesktopWidth } from './containers/Dashboard/Messages/MessagesContainer';
import AddAddressModalDialog from './containers/Modals/AddAddressModalDialog';
import AddCreditCardModalDialog from './containers/Modals/AddCreditCardModalDialog';
import AddCustomerCardModalDialog from './containers/Modals/AddCustomerCardModalDialog';
import AdditionalOwnersModalDialog from './containers/Modals/AdditionalOwnersModalDialog';
import ChangeNameModalDialog from './containers/Modals/ChangeNameModalDialog';
import ChangePaymentCardModalDialog from './containers/Modals/ChangePaymentCardModalDialog';
import ContactInfoModalDialog from './containers/Modals/ContactInfoModalDialog';
import DeleteCustomerModalDialog from './containers/Modals/DeleteCustomerModalDialog';
import DogCreationModalDialog from './containers/Modals/DogCreationModalDialog/';
import DogEvaluationModalDialog from './containers/Modals/DogEvaluationModalDialog';
import EditCustomerContactInfo from './containers/Modals/EditCustomerContactInfo';
import EmergencyContactModalDialog from './containers/Modals/EmergencyContactModalDialog';
import ImmunizationExpirationDatesModalDialog from './containers/Modals/ImmunizationExpirationDatesModalDialog';
import InstructorEmployeeModalDialog from './containers/Modals/InstructorEmployeeModalDialog';
import PickupDropoffAddModalDialog from './containers/Modals/PickupDropoffAddModalDialog';
import PickupDropoffEditModalDialog from './containers/Modals/PickupDropoffEditModalDialog';
import ResetPasswordModalDialog from './containers/Modals/ResetPasswordModalDialog';
import SmsVerificationModalDialog from './containers/Modals/SmsVerificationModalDialog';
import UploadModalDialog from './containers/Modals/UploadModalDialog';
import AlertQueue from '../components/Alert';
import { displayStates } from '../helpers/state';

let scrollOffset = 0;

// eslint-disable-next-line react/prefer-stateless-function
class App extends React.PureComponent {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ]),
        isAnyModalOpened: PropTypes.bool,
        isDogCreationOpened: PropTypes.bool,
        messagesDisplayState: PropTypes.string,
    };

    componentDidMount() {
        moment.tz.add(DEFAULT_TIMEZONE_DATA);
        moment.tz.setDefault(DEFAULT_TIMEZONE);
    }

    componentWillReceiveProps(nextProps) {
        const { isAnyModalOpened, messagesDisplayState } = nextProps;
        const isModalOpenedUpdated = isAnyModalOpened !== this.props.isAnyModalOpened;
        const isMessagesDisplayStateUpdated = isAnyModalOpened !== this.props.messagesDisplayState;
        const { closed } = displayStates;

        // eslint-disable-next-line react/prop-types
        if ((isModalOpenedUpdated && isAnyModalOpened)) {
            scrollOffset = window.pageYOffset || document.documentElement.scrollTop || 0;
            document.documentElement.style.top = -scrollOffset + 'px';
            document.documentElement.style.position = 'fixed';
        } else if (isMessagesDisplayStateUpdated && messagesDisplayState !== closed && !isDesktopWidth) {
            scrollOffset = window.pageYOffset || document.documentElement.scrollTop || 0;
            document.documentElement.style.position = 'fixed';
        } else if ((isModalOpenedUpdated && !isAnyModalOpened) ||
            (isMessagesDisplayStateUpdated && messagesDisplayState === closed && !isDesktopWidth)) {
            document.documentElement.style.position = 'relative';
            setTimeout(() => { // because it works
                document.documentElement.style.top = '';
                document.documentElement.scrollTop = scrollOffset;
                document.body.scrollTop = scrollOffset;
            }, 100);
        }
    }

    render() {
        const { children = null, isDogCreationOpened } = this.props;
        return (
            <div className='app'>
                {children}
                <Modal/>
                <ModalSidebar/>
                <ChangeNameModalDialog/>
                <ResetPasswordModalDialog/>
                <DeleteCustomerModalDialog/>
                <ContactInfoModalDialog/>
                <AdditionalOwnersModalDialog/>
                <EmergencyContactModalDialog/>
                {isDogCreationOpened && <DogCreationModalDialog/>}
                <PickupDropoffEditModalDialog/>
                <PickupDropoffAddModalDialog/>
                <AddCreditCardModalDialog/>
                <DogEvaluationModalDialog/>
                <AddCustomerCardModalDialog/>
                <ChangePaymentCardModalDialog/>
                <AddAddressModalDialog/>
                <InstructorEmployeeModalDialog/>
                <ImmunizationExpirationDatesModalDialog/>
                <UploadModalDialog/>
                <EditCustomerContactInfo/>
                <SmsVerificationModalDialog/>
                <AlertQueue/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAnyModalOpened: state.getIn(['misc', 'modals', 'any']),
        isDogCreationOpened: state.getIn(['misc', 'modals', 'dogCreation']),
        messagesDisplayState: state.getIn(['messages', 'displayState']),
    };
};

export default connect(mapStateToProps)(App);

