import {
    maxTextareaValueValidator,
    maxTextValueValidator,
    minTextValueValidator,
    requiredValidatorSelect,
    validateFactory
} from '../../../../helpers/validate';

const fields = [
    { name: 'assessment', validators: [requiredValidatorSelect] },
    { name: 'come_command', validators: [minTextValueValidator, maxTextValueValidator] },
    { name: 'breed_reactive', validators: [minTextValueValidator, maxTextValueValidator] },
    { name: 'onleash_reactive', validators: [minTextValueValidator, maxTextValueValidator] },
    { name: 'triggers', validators: [minTextValueValidator, maxTextValueValidator] },
    { name: 'ball_aggressive', validators: [minTextValueValidator, maxTextValueValidator] },
    { name: 'car_sick', validators: [minTextValueValidator, maxTextValueValidator] },
    { name: 'treats_word', validators: [minTextValueValidator, maxTextValueValidator] },
    { name: 'last_flea', validators: [minTextValueValidator, maxTextValueValidator] },
    { name: 'other', validators: [minTextValueValidator, maxTextValueValidator] },
    { name: 'additional', validators: [minTextValueValidator, maxTextareaValueValidator] }
];

export default validateFactory(fields);
