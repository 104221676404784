const validate = values => {
    const errors = {};

    if (!values.get('old_password')) {
        errors.old_password = 'Current password is Required.';
    } else if (values.get('old_password').length < 8) {
        errors.old_password = 'Password is too short. It must contain at least 8 characters.';
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*=+.,?<>±§~`[\]{}()_\-/|':;" ]{8,}$/i.test(values.get('old_password'))) {
        errors.old_password = 'Your Password is not strong enough.';
    } else if (values.get('old_password').length > 128) {
        errors.old_password = 'Password can\'t contain more than 128 characters';
    }

    if (!values.get('password')) {
        errors.password = 'New Password is Required.';
    } else if (values.get('password').length < 8) {
        errors.password = 'Password is too short. It must contain at least 8 characters.';
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*=+.,?<>±§~`[\]{}()_\-/|':;" ]{8,}$/i.test(values.get('password'))) {
        errors.password = 'Your Password is not strong enough.';
    } else if (values.get('password').length > 128) {
        errors.password = 'Password can\'t contain more than 128 characters';
    }

    if (!values.get('confirm_password')) {
        errors.confirm_password = 'Password Confirm is Required';
    } else if (values.get('confirm_password') !== values.get('password')) {
        errors.confirm_password = 'Password and Password Confirm must be equal';
    } else if (values.get('confirm_password').length > 128) {
        errors.confirm_password = 'Password Confirm can\'t contain more than 128 characters';
    }
    return errors;
};

export default validate;
