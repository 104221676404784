import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { ReactComponent as IconAmericanExpress } from '../../../../assets/icons/icon-american-express.svg';
import { ReactComponent as IconMasterCard } from '../../../../assets/icons/icon-master-card.svg';
import { ReactComponent as IconNeutralCard } from '../../../../assets/icons/icon-neutral-card.svg';
import { ReactComponent as IconVisa } from '../../../../assets/icons/icon-visa.svg';
import * as options from '../../../../helpers/options';
import * as ValidatorFactory from '../../../../helpers/validate';
import renderInputControl from '../../../common/Form/InputControl';
import renderCheckboxControl from '../../../common/Form/CheckboxControl';
import renderSelectControl from '../../../common/Form/SelectControl/SelectControl';
import Icon from '../../../common/Icon/Icon';
import validate from './validate';

const creditCardType = require('credit-card-type');

const AddCustomerCardModalDialogForm = ({ handleSubmit, invalid, submitting, cardNumberValue, onClose, data }) => {
    const submitButtonClassNames = classNames({
        'button modal-dialog__button modal-dialog__button_add-card': true,
        'button_disabled': (invalid || submitting)
    });
    let cardType;
    // let cardCodeName;
    let cardCodeSize;
    let cardNumberMask;
    if (cardNumberValue !== undefined && creditCardType(cardNumberValue.substr(0, 2))[0] !== undefined) {
        const cardInfo = creditCardType(cardNumberValue.substr(0, 2))[0];
        cardType = cardInfo.type;
        // cardCodeName = cardInfo.code.name;
        cardCodeSize = cardInfo.code.size;
    }
    if (cardType === 'american-express') {
        cardNumberMask = '9999 999999 99999';
    } else {
        cardNumberMask = '9999 9999 9999 9999';
    }
    const visaClassNames = classNames({
        'card-detection__item card-detection__item_visa': true,
        'card-detection__item_detected': cardType === 'visa'
    });
    const masterClassNames = classNames({
        'card-detection__item card-detection__item_master': true,
        'card-detection__item_detected': cardType === 'master-card'
    });
    const expressClassNames = classNames({
        'card-detection__item card-detection__item_express': true,
        'card-detection__item_detected': cardType === 'american-express'
    });
    const nonameClassNames = classNames({
        'card-detection__item card-detection__item_noname': true,
        'card-detection__item_detected':
        cardType !== 'american-express' &&
        cardType !== 'master-card' &&
        cardType !== 'visa' &&
        (cardType !== undefined || (cardNumberValue !== undefined && cardNumberValue.length >= 2))
    });
    return (
        <form className='modal-dialog__form' onSubmit={handleSubmit}>
            <div className='modal-dialog__form-content'>
                <div className='modal-dialog__card-detection'>
                    <Icon glyph={IconVisa} className={visaClassNames}/>
                    <Icon glyph={IconMasterCard} className={masterClassNames}/>
                    <Icon glyph={IconAmericanExpress} className={expressClassNames}/>
                    <Icon glyph={IconNeutralCard} className={nonameClassNames}/>
                </div>
                <Field
                    name='number'
                    type='text'
                    isMasked
                    mask={cardNumberMask}
                    component={renderInputControl}
                    label='Credit Card Number'/>
                <div className='form__aligner form__aligner_50-50'>
                    <Field
                        name='expiration_month'
                        type='text'
                        component={renderSelectControl}
                        options={options.expirationMonth}
                        label='Exp Date (MM)'/>
                    <Field
                        name='expiration_year'
                        type='text'
                        component={renderSelectControl}
                        options={options.expirationYear}
                        label='Exp Date (YY)'/>
                </div>
                <Field
                    name='cvc'
                    type='text'
                    isMasked
                    mask={cardCodeSize === 4 ? '9999' : '999'}
                    validate={[
                        ValidatorFactory.requiredValidator('Security Code'),
                        ValidatorFactory.exactTextValueValidator(
                            'Security Code',
                            cardCodeSize !== undefined ? cardCodeSize : 3
                        )
                    ]}
                    component={renderInputControl}
                    // label={cardCodeName === undefined ? 'CVC' : cardCodeName}
                    label='Security code'/>
                <div className='modal-dialog__checkbox-container'>
                    <Field
                        name='save_card'
                        type='checkbox'
                        label='Save card on file for quicker checkout.'
                        className='modal-dialog__purchase-checkbox'
                        parse={value => !!value}
                        component={renderCheckboxControl} />
                </div>
            </div>
            <div className='modal-dialog__form-footer modal-dialog__form-footer_row modal-dialog__form-footer_add-card'>
                <button
                    className={submitButtonClassNames}
                    type='submit'
                    disabled={submitting || invalid}>
                    {(submitting)
                        ? 'Creating...'
                        : data.buttonText || 'Save this card'
                    }
                </button>
                <div className='modal-dialog__cancel' onClick={onClose}>Cancel</div>
            </div>
        </form>
    );
};

AddCustomerCardModalDialogForm.propTypes = {
    handleSubmit: PropTypes.func,
    onClose: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    cardNumberValue: PropTypes.string,
    data: PropTypes.shape({})
};

const form = reduxForm({
    form: 'AddCustomerCardModalDialogForm',
    enableReinitialize: true,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate
});

const mapStateToProps = state => {
    return {
        cardNumberValue: state.getIn(['form', 'AddCustomerCardModalDialogForm', 'values', 'number']),
        data: state.getIn(['misc', 'modals', 'addCustomerCard', 'data'])
    };
};

export default connect(mapStateToProps, null)(form(AddCustomerCardModalDialogForm));
