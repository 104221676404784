import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

const CustomerNavbarItem = ({ to, iconClass, icon: Icon, text, isActive }) => {
    return (
        <Link to={to} className={`customer-navbar__item ${isActive ? 'customer-navbar__item_active' : ''}`}>
            <Icon className={iconClass}/>
            {text}
        </Link>
    );
};

CustomerNavbarItem.propTypes = {
    to: PropTypes.string,
    iconClass: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string,
    isActive: PropTypes.bool
};
export default CustomerNavbarItem;
