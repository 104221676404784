import { createAction } from 'redux-actions';
import * as Api from './api';
import * as Types from './types';
import { setGlobalError } from '../../../../actions/errorHandlerActions';

export const setUnreadCount = createAction(Types.SET_DAILY_NOTIFICATIONS_UNREAD_COUNT);
export const setItems = createAction(Types.SET_DAILY_NOTIFICATIONS_ITEMS);
export const setNextItems = createAction(Types.SET_NEXT_DAILY_NOTIFICATIONS_UNREAD_COUNT);
export const setNotificationsAsReaded = createAction(Types.SET_DAILY_NOTIFICATIONS_AS_READED);
export const startItemsLoading = createAction(Types.START_ITEMS_LOADING);
export const finishItemsLoading = createAction(Types.FINISH_ITEMS_LOADING);
export const startNextItemsLoading = createAction(Types.START_NEXT_ITEMS_LOADING);
export const finishNextItemsLoading = createAction(Types.FINISH_NEXT_ITEMS_LOADING);

export const getDailyNotificationsUnreadCount = () => {
    return dispatch => {
        return dispatch(Api.getActivityStreamUnreadCount())
            .then((response) => {
                dispatch(setUnreadCount(response.data));
                return response.data;
            });
    };
};

export const getDailyNotificationsItems = () => {
    return async dispatch => {
        dispatch(startItemsLoading());
        try {
            const response = await dispatch(Api.getActivityStream());
            console.log(response);
            dispatch(setItems(response.data));
            dispatch(finishItemsLoading());
            setTimeout(() => {
                dispatch(setUnreadCount(0));
                dispatch(setNotificationsAsReaded());
            }, 2000);
        } catch (error) {
            console.log(error.response || error);
            dispatch(setGlobalError(error));
            dispatch(finishItemsLoading());
        }
    };
};

export const getNextDailyNotificationsItems = () => {
    return (dispatch, getState) => {
        const nextUrl = getState().getIn(['dailyNotifications', 'nextUrl']);
        dispatch(startNextItemsLoading());
        dispatch(Api.getActivityStream(nextUrl))
            .then((response) => {
                console.log(response);
                dispatch(setNextItems(response.data));
                dispatch(finishNextItemsLoading());
            })
            .catch((error) => {
                console.log(error.response);
                dispatch(setGlobalError(error));
                dispatch(finishNextItemsLoading());
            });
    };
};
