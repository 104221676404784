export const RESET_CURRENT_EVENT_REDUCER = 'RESET_CURRENT_EVENT_REDUCER';
export const SET_CURRENT_EVENT = 'SET_CURRENT_EVENT';
export const UPDATE_CURRENT_TRAINING_EVENT = 'UPDATE_CURRENT_TRAINING_EVENT';
export const SET_CURRENT_EVENT_PRODUCTS = 'SET_CURRENT_EVENT_PRODUCTS';
export const CLEAR_CURRENT_EVENT_PRODUCTS = 'CLEAR_CURRENT_EVENT_PRODUCTS';
export const ADD_BUSY_FIELD = 'ADD_BUSY_FIELD';
export const REMOVE_BUSY_FIELD = 'REMOVE_BUSY_FIELD';
export const SET_NEW_CLASS_OCCURRENCE_EVENT = 'SET_NEW_CLASS_OCCURRENCE_EVENT';
export const SET_NEW_TRAINING_EVENT = 'SET_NEW_TRAINING_EVENT';
export const SET_CLASS_OCCURRENCE_EVENT_INFO = 'SET_CLASS_OCCURRENCE_EVENT_INFO';
export const SET_TRAINING_EVENT_INFO = 'SET_TRAINING_EVENT_INFO';
export const ADD_DOG_TO_EVENT_PRODUCTS = 'ADD_DOG_TO_EVENT_PRODUCTS';
export const DECREASE_AVAILABLE_SPOTS = 'DECREASE_AVAILABLE_SPOTS';
export const CHANGE_PRODUCT_STATUS = 'CHANGE_PRODUCT_STATUS';
export const UPDATE_PRODUCT_REPORTS = 'UPDATE_PRODUCT_REPORTS';
export const DELETE_PRODUCT_REPORTS = 'DELETE_PRODUCT_REPORTS';
export const DELETE_UNSAVED_TRAINING_EVENT_PRODUCT = 'DELETE_UNSAVED_TRAINING_EVENT_PRODUCT';
export const UPDATE_EVENT_PRODUCT = 'UPDATE_EVENT_PRODUCT';
export const UPDATE_EVENT_PRODUCT_LOCATIONS = 'UPDATE_EVENT_PRODUCT_LOCATIONS';
