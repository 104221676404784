import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import creditCardType from 'credit-card-type';
import { ReactComponent as IconAmericanExpress } from '../../../../../assets/icons/icon-american-express.svg';
import { ReactComponent as IconMasterCard } from '../../../../../assets/icons/icon-master-card.svg';
import { ReactComponent as IconNeutralCard } from '../../../../../assets/icons/icon-neutral-card.svg';
import { ReactComponent as IconVisa } from '../../../../../assets/icons/icon-visa.svg';
import * as options from '../../../../../helpers/options';
import * as ValidatorFactory from '../../../../../helpers/validate';
import renderInputControl from '../../../../common/Form/InputControl';
import renderCheckboxControl from '../../../../common/Form/CheckboxControl';
import renderSelectControl from '../../../../common/Form/SelectControl/SelectControl';
import { addPaymentCard } from '../../../../../services/paymentCards';
import Icon from '../../../../common/Icon/Icon';
import validate from './validate';
import { clearStripeError } from '../../../../../actions/payments';


class PaymentInfoStep extends PureComponent {

    componentWillMount() {
        const { formData: { cards, classInfo }, submitData: { dogs }, addSubmitData, goToStep } = this.props;
        const { packagesAvailable } = classInfo;
        const selectedDogs = typeof dogs === 'string' ? dogs.split(',') : dogs;
        let shouldUseCard = true;
        if (packagesAvailable && packagesAvailable.length) {
            const { available_quantity: availableQuantity, id } = packagesAvailable[0];
            if (availableQuantity) {
                addSubmitData({ name: 'packageCredit', data: id });
            }

            if (cards.length !== 0 && availableQuantity >= selectedDogs.length) {
                shouldUseCard = false;
                goToStep(4);
            }
        }
        let defaultCard = null;
        cards.map(card => { // eslint-disable-line
            if (card.is_default) {
                defaultCard = card.id;
            }
        });
        addSubmitData({ name: 'shouldUseCard', data: shouldUseCard });
        if (defaultCard) {
            addSubmitData({ name: 'card', data: defaultCard });
            goToStep(4);
        }
    }

    componentWillUnmount() {
        const { clearStripeError } = this.props;
        clearStripeError();
    }

    submitStep = (data) => {
        const {
            clearStripeError,
            saveCreditCard,
            goToStep,
            addSubmitData,
            addStepData,
            formData,
        } = this.props;
        clearStripeError();
        return saveCreditCard(data).then((response) => {
            if (!response) return;
            const cards = formData.cards || [];
            response.type = creditCardType(response.first6)[0].niceType;
            cards.push(response);
            addStepData({ name: 'cards', data: cards });
            addSubmitData({ name: 'card', data: response.id });
            goToStep(4);
        });
    }

    render() {
        const {
            handleSubmit,
            invalid,
            submitting,
            cardNumberValue,
            isLoading,
            onCancelHandler,
            stripeError,
        } = this.props;
        const submitButtonClassNames = classNames({
            'button modal-dialog__button modal-dialog__button_booking': true,
            'button_disabled': (invalid || submitting || isLoading)
        });
        let cardType;
        let cardCodeSize;
        let cardNumberMask;
        if (cardNumberValue !== undefined && creditCardType(cardNumberValue.substr(0, 2))[0] !== undefined) {
            const cardInfo = creditCardType(cardNumberValue.substr(0, 2))[0];
            cardType = cardInfo.type;
            cardCodeSize = cardInfo.code.size;
        }
        if (cardType === 'american-express') {
            cardNumberMask = '9999 999999 99999';
        } else {
            cardNumberMask = '9999 9999 9999 9999';
        }
        const visaClassNames = classNames({
            'card-detection__item card-detection__item_visa': true,
            'card-detection__item_detected': cardType === 'visa'
        });
        const masterClassNames = classNames({
            'card-detection__item card-detection__item_master': true,
            'card-detection__item_detected': cardType === 'master-card'
        });
        const expressClassNames = classNames({
            'card-detection__item card-detection__item_express': true,
            'card-detection__item_detected': cardType === 'american-express'
        });
        const nonameClassNames = classNames({
            'card-detection__item card-detection__item_noname': true,
            'card-detection__item_detected':
            cardType !== 'american-express' &&
            cardType !== 'master-card' &&
            cardType !== 'visa' &&
            (cardType !== undefined || (cardNumberValue !== undefined && cardNumberValue.length >= 2))
        });
        return (
            <form
                className='modal-dialog__form'
                onSubmit={handleSubmit((data) => {
                    const cardData = data.toJS();
                    cardData.is_hidden = !cardData.save_card;
                    return this.submitStep(cardData);
                })}>
                <div className='modal-dialog__form-content'>
                    <div className='modal-dialog__card-detection'>
                        <Icon glyph={IconVisa} className={visaClassNames}/>
                        <Icon glyph={IconMasterCard} className={masterClassNames}/>
                        <Icon glyph={IconAmericanExpress} className={expressClassNames}/>
                        <Icon glyph={IconNeutralCard} className={nonameClassNames}/>
                    </div>
                    <Field
                        name='number'
                        type='text'
                        isMasked
                        mask={cardNumberMask}
                        component={renderInputControl}
                        label='Credit Card Number'/>
                    <div className='form__aligner form__aligner_50-50 purchase-package__expiration-block'>
                        <Field
                            name='expiration_month'
                            type='text'
                            component={renderSelectControl}
                            options={options.expirationMonth}
                            label='Exp Date (MM)'/>
                        <Field
                            name='expiration_year'
                            type='text'
                            component={renderSelectControl}
                            options={options.expirationYear}
                            label='Exp Date (YY)'/>
                    </div>
                    <Field
                        name='cvc'
                        type='text'
                        isMasked
                        mask={cardCodeSize === 4 ? '9999' : '999'}
                        validate={[
                            ValidatorFactory.requiredValidator('Security Code'),
                            ValidatorFactory.exactTextValueValidator(
                                'Security Code',
                                cardCodeSize !== undefined ? cardCodeSize : 3
                            )
                        ]}
                        component={renderInputControl}
                        label='Security code'/>
                    <div className='modal-dialog__checkbox-container'>
                        <Field
                            name='save_card'
                            type='checkbox'
                            label='Save card on file for quicker checkout.'
                            className='modal-dialog__purchase-checkbox'
                            parse={value => !!value}
                            component={renderCheckboxControl} />
                    </div>
                </div>
                {stripeError ?
                    <div className='form__error'>{stripeError}</div>
                : null}
                <div className='modal-dialog__form-footer modal-dialog__form-footer_column modal-dialog__form-footer_booking'>
                    <button
                        className={submitButtonClassNames}
                        type='submit'
                        disabled={submitting || isLoading}>
                        {(submitting || isLoading)
                            ? 'Submitting...'
                            : 'Next'
                        }
                    </button>
                    <span className='modal-dialog__cancel-button' onClick={onCancelHandler}>Cancel</span>
                </div>
            </form>
        );
    }
}

PaymentInfoStep.propTypes = {
    handleSubmit: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    cardNumberValue: PropTypes.string,
    isLoading: PropTypes.bool,
    onCancelHandler: PropTypes.func,
    saveCreditCard: PropTypes.func,
    goToStep: PropTypes.func,
    addSubmitData: PropTypes.func,
    addStepData: PropTypes.func,
    formData: PropTypes.shape({}),
    submitData: PropTypes.shape({})
};

const form = reduxForm({
    form: 'AddCreditCardStepForm',
    validate,
    enableReinitialize: true
});

const mapStateToProps = state => {
    return {
        cardNumberValue: state.getIn(['form', 'AddCreditCardStepForm', 'values', 'number']),
        stripeError: state.getIn(['payments', 'error']),
    };
};

const mapDispatchToProps = {
    clearStripeError,
    saveCreditCard: addPaymentCard
};

export default connect(mapStateToProps, mapDispatchToProps)(form(PaymentInfoStep));
