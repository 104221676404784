import classNames from 'classnames';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';
import { Field, reduxForm } from 'redux-form/immutable';
import { handleAutocompleteSelectOrEnter } from '../../../../actions/autocompleteActions';
import { setDefaultContactInfoData } from '../../../../actions/miscActions';
import { usStates } from '../../../../helpers/options';
import renderInputControl from '../../../common/Form/InputControl';
import renderPlacesAutocompleteControl from '../../../common/Form/PlacesAutocompleteControl/PlacesAutocompleteControl';
import renderSelectControl from '../../../common/Form/SelectControl';
import validate from './validate';
import { PHONE_MASK, PHONE_MASK_CHAR, phoneNumberNormalizer } from '../../../../helpers/normalize';

class ContactInfoModalDialogForm extends React.PureComponent {
    static propTypes = {
        initialValues: PropTypes.shape({}),
        handleSubmit: PropTypes.func,
        handleDefaultValues: PropTypes.func,
        invalid: PropTypes.bool,
        submitting: PropTypes.bool,
        isLoading: PropTypes.bool,
        changeFieldValue: PropTypes.func,
        handleAutocompleteSelectOrEnter: PropTypes.func,
    };

    componentDidMount() {
        this.props.handleDefaultValues();
    }

    render() {
        const { handleSubmit, invalid, submitting, isLoading, handleAutocompleteSelectOrEnter } = this.props;
        const submitButtonClassNames = classNames({
            'button modal-dialog__button': true,
            'button_disabled': (invalid || submitting || isLoading)
        });
        return (
            <form className='modal-dialog__form' onSubmit={handleSubmit}>
                <div className='modal-dialog__form-content'>
                    <Field
                        name='primary_phone'
                        type='text'
                        component={renderInputControl}
                        mask={PHONE_MASK}
                        maskChar={PHONE_MASK_CHAR}
                        isMasked
                        normalize={phoneNumberNormalizer}
                        label='Phone Number'
                        hint='Customer will need to verify any new phone number before receiving SMS messages.'/>
                    <Field
                        name='secondary_phone'
                        type='text'
                        component={renderInputControl}
                        mask={PHONE_MASK}
                        maskChar={PHONE_MASK_CHAR}
                        isMasked
                        normalize={phoneNumberNormalizer}
                        label='Phone Number 2'/>
                    <Field
                        name='email'
                        type='text'
                        component={renderInputControl}
                        label='Email Address'/>
                    <div className='modal-dialog__form-divider'/>
                    <div className='modal-dialog__form-title'>Home Location</div>
                    <Field
                        name='address1'
                        type='text'
                        component={renderPlacesAutocompleteControl}
                        handleSelectOrEnter={address => {
                            handleAutocompleteSelectOrEnter(address, 'ContactInfoModalDialogForm');
                        }}
                        label='Location'/>
                    <Field
                        name='address2'
                        type='text'
                        component={renderInputControl}
                        label='Location 2 (Apt or Unit number)'/>
                    <div className='form__aligner form__aligner_39-28-29'>
                        <Field
                            name='city'
                            type='text'
                            component={renderInputControl}
                            label='City'/>
                        <Field
                            name='state'
                            type='text'
                            component={renderSelectControl}
                            options={usStates}
                            className='select-control__is-state'
                            searchable
                            returnSelectValue
                            label='State'/>
                        <Field
                            name='zip_code'
                            type='text'
                            component={renderInputControl}
                            label='ZIP Code'/>
                    </div>
                    <Field
                        name='location_notes'
                        type='text'
                        component={renderInputControl}
                        label='Location Notes'/>
                </div>
                <div className='modal-dialog__form-footer modal-dialog__form-footer_row'>
                    <button
                        className={submitButtonClassNames}
                        type='submit'
                        disabled={submitting || isLoading}>
                        {(submitting || isLoading)
                            ? 'Updating...'
                            : 'Save'
                        }
                    </button>
                </div>
            </form>
        );
    }
}

const form = reduxForm({
    form: 'ContactInfoModalDialogForm',
    enableReinitialize: true,
    validate
});

const mapStateToProps = state => {
    const instance = state.getIn(['currentCustomer', 'instance']);
    const initialInstance = state.getIn(['currentCustomer', 'initialInstance']);
    const addressId = initialInstance.get('address');
    const addressData = addressId !== null
        ? initialInstance.get('available_addresses').find((address) => {
            return address.get('id') === addressId;
        })
        : fromJS({
            name: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            zip_code: null,
            location_notes: null
        });
    const address2 = addressData.get('address2') === null ? '' : addressData.get('address2');
    const secondaryPhone = instance.get('secondary_phone') === 'None' ? null : instance.get('secondary_phone');

    return {
        initialValues: fromJS({
            id: instance.get('id'),
            initialPrimaryPhone: instance.get('primary_phone'),
            primaryPhoneId: instance.get('primaryPhoneId'),
            primary_phone: instance.get('primary_phone'),
            initialSecondaryPhone: instance.get('secondary_phone'),
            secondaryPhoneId: instance.get('secondaryPhoneId'),
            secondary_phone: secondaryPhone,
            initialEmail: initialInstance.get('email'),
            email: instance.get('email'),
            addressId: instance.get('addressId'),
            initialName: addressData.get('name'),
            initialAddress1: addressData.get('address1'),
            address1: addressData.get('address1'),
            initialAddress2: address2,
            address2,
            initialCity: addressData.get('city'),
            city: addressData.get('city'),
            initialState: addressData.get('state'),
            state: addressData.get('state'),
            initialZip_code: addressData.get('zip_code'),
            zip_code: addressData.get('zip_code'),
            initialLocation_notes: addressData.get('location_notes'),
            location_notes: addressData.get('location_notes')
        }),
        isLoading: state.getIn(['misc', 'modals', 'isContactInfoLoading'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleDefaultValues: bindActionCreators(setDefaultContactInfoData, dispatch),
    handleAutocompleteSelectOrEnter: bindActionCreators(handleAutocompleteSelectOrEnter, dispatch),
    // eslint-disable-next-line object-shorthand
    changeFieldValue: function (field, value) {
        dispatch(change('ContactInfoModalDialogForm', field, value));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ContactInfoModalDialogForm));
