import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, getFormSubmitErrors, reduxForm } from 'redux-form/immutable';
import { deleteAccessToken, setAuthorizationHeader } from '../../../../../services/oauth';
import renderInputControl from '../../../../common/Form/InputControl';
import renderPasswordControl from '../../../../common/Form/PasswordControl/PasswordControl';
import { phoneNumberNormalizer, PHONE_MASK, PHONE_MASK_CHAR } from '../../../../../helpers/normalize';
import validate from './validate';

class SignUpForm extends React.PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        change: PropTypes.func,
        userData: PropTypes.shape({
            signUpData: PropTypes.shape({}),
        }),
        onSubmit: PropTypes.func,
        submitting: PropTypes.bool,
        invalid: PropTypes.bool,
        error: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.string
        ])
    };

    componentDidMount() {
        setAuthorizationHeader();
        deleteAccessToken();
    }

    renderErrors = () => {
        const { error } = this.props;
        return error && <div className='form__error'>{error}</div>;
    };

    render() {
        const { handleSubmit, submitting, invalid, error } = this.props;
        const SubmitButtonClassNames = classNames({
            'signup__submit-button': true,
            'signup__submit-button_disabled': (invalid || submitting)
        });
        return (
            <form onSubmit={handleSubmit} className='signup__form'>
                <input
                    type='email'
                    value=''
                    component='input'
                    name='_email'
                    style={{ width: '0', height: '0', border: 'none' }}/>
                <input
                    type='password'
                    value=''
                    component='input'
                    name='_password'
                    style={{ width: '0', height: '0', border: 'none' }}/>
                <Field name='full_name' type='text' component={renderInputControl} formError={error} label='First and last name'/>
                <Field name='user_email' type='email' component={renderInputControl} formError={error} label='Email'/>
                <Field
                    name='user_password'
                    type='password'
                    component={renderPasswordControl}
                    formError={error}
                    label='Password'/>
                <Field
                    name='phone'
                    type='tel'
                    component={renderInputControl}
                    mask={PHONE_MASK}
                    maskChar={PHONE_MASK_CHAR}
                    isMasked
                    formError={error}
                    normalize={phoneNumberNormalizer}
                    label='Phone number'/>
                {this.renderErrors()}
                <button className={SubmitButtonClassNames} type='submit' disabled={submitting}>Sign Up</button>
            </form>
        );
    }
}

const form = reduxForm({
    form: 'signUpForm',
    destroyOnUnmount: false,
    validate
});

const mapStateToProps = state => ({
    submitErrors: getFormSubmitErrors('signUpForm')(state)
});

export default connect(mapStateToProps, null)(form(SignUpForm));
