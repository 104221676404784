import * as axios from 'axios';
import {
    finishDirectoryListLoading,
    initDirectoryFilterOptions,
    setDirectoryList,
    setNextDirectoryList,
    startDirectoryListLoading
} from '../actions/dashboard/directoryActions';
import { capitalizeEachWord } from '../helpers/text';
import { getUserNameSpace } from '../helpers/userRoles';

const convertSelectOptions = (serverResponse) => {
    const { permission_level_choices, position_choices } = serverResponse;
    const options = [
        {
            name: 'position',
            items: position_choices,
            query: 'position'
        },
        {
            name: 'access',
            items: permission_level_choices,
            query: 'permission_level'
        }
    ];

    return options.map((group, i) => {
        return {
            name: capitalizeEachWord(group.name),
            items: group.items.map((item) => {
                return { value: item, label: capitalizeEachWord(item), query: group.query, group: i };
            })
        };
    });
};

export const getDirectoryList = (queryString) => {
    return (dispatch) => {
        dispatch(startDirectoryListLoading());
        let url = `/v1/${getUserNameSpace('directory')}/directory/`;

        if (queryString) {
            url = `${url}?${queryString}`;
        }

        return axios.get(url)
                    .then((response) => {
                        dispatch(setDirectoryList(response.data));
                        dispatch(initDirectoryFilterOptions(convertSelectOptions(response.data)));
                        dispatch(finishDirectoryListLoading());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(finishDirectoryListLoading());
                    });
    };
};

export const getNextDirectoryList = () => {
    return (dispatch, getState) => {
        if (getState().getIn(['directoryList', 'isLoading'])) {
            return;
        }
        dispatch(startDirectoryListLoading());
        const nextUrl = getState().getIn(['directoryList', 'nextUrl']);
        axios.get(nextUrl)
                    .then((response) => {
                        console.log(response.data);
                        dispatch(setNextDirectoryList(response.data));
                        dispatch(finishDirectoryListLoading());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(finishDirectoryListLoading());
                    });
    };
};
