import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const propTypes = {
    isPreview: PropTypes.bool,
    getRef: PropTypes.func,
    className: PropTypes.string,
    text: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
};

class EditableContent extends React.PureComponent {
    static propTypes = propTypes;
    
    componentDidMount() {
        const { text, isPreview } = this.props;
        const { refElement } = this;
        
        if (refElement && text) {
            this.refElement.focus();
            this.pasteAtSelection(text.trim(), isPreview);
            if (isPreview) {
                this.refElement.blur();
            }
        }
    }
    
    pasteAtSelection = (text, isPreview) => {
        if (!isPreview && document.queryCommandSupported('insertText')) {
            document.execCommand('insertText', false, text);  // Show plain HTML in edit screen
        } else if (isPreview && document.queryCommandSupported('insertHTML')) {
            document.execCommand('insertHTML', false, text);  // Show rendered HTML in preview screen
        } else { // MSIE doesn't support insertHTML
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            requestAnimationFrame(() => {
                range.deleteContents();
                if (!isPreview) {
                    range.insertNode((document.createTextNode(text)));
                } else {
                    const newNode = document.createElement('div');
                    newNode.innerHTML = text;
                    range.insertNode(newNode);
                }
                selection.removeAllRanges();
                selection.addRange(range);
            });
        }
    };
    
    handleInput = (e) => {
        const { refElement } = this;
        const { onChange } = this.props;
        
        if (!refElement) {
            return;
        }
    
        onChange(e);
    };
    
    handlePaste = e => {
        e.preventDefault();
        const content = e.clipboardData.getData('text');
        this.pasteAtSelection(content);
    };
    
    render() {
        const { className, onClick, getRef, isPreview, onFocus, onBlur } = this.props;
        const contentClassNames = classNames([
            'editable-content',
            className
        ]);
        
        return (
            <div
                key='content-editable'
                spellCheck={!isPreview}
                onClick={onClick}
                onInput={this.handleInput}
                onPaste={this.handlePaste}
                onFocus={onFocus}
                onBlur={onBlur}
                className={contentClassNames}
                ref={el => {
                    this.refElement = el;
                    getRef(el);
                }}
                contentEditable/>
        );
    }
    
}

export default EditableContent;
