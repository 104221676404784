import React from 'react';
import PropTypes from 'prop-types';

const SmsSuccessStep = ({ onCloseHandler }) => {
    return (
        <div className='sms-modal__verification'>
            <i className='sms-modal__success-icon' />
            <div className='sms-modal__content'>
                <h5 className='sms-modal__title'>You’re Enrolled</h5>
                <p className='sms-modal__text'>You are all set to receive your SMS Updates and keep track of your furry friends as they enjoy their classes!</p>
            </div>
            <div className='sms-modal__form-footer'>
                <span onClick={onCloseHandler} className='button modal-dialog__button_sms sms-modal__button-close'>Close</span>
            </div>
        </div>
    );
};

SmsSuccessStep.propTypes = {
    onCloseHandler: PropTypes.func.isRequired
};

export default SmsSuccessStep;
