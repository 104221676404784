import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as Types from './types';

const INITIAL_STATE = fromJS({
    alerts: []
});

const alertReducer = handleActions({
    [Types.ENQUEUE_ALERT]: (state, action) => {
        const alert = action.payload;
        const queue = state.get('alerts').toJS();
        const existingAlert = queue.find(item => alert.id.split('-')[0] === item.id.split('-')[0]);
        const existingAlertLooksSame = existingAlert &&
            // Poor man's throttling.
            // Both alerts caused by same-type actions (e.g. `CUSTOMER_EDIT`) that occurred under 1000ms apart.
            !((alert.id.split('-')[1] - existingAlert.id.split('-')[1]) > 1000);
        
        if (existingAlertLooksSame) {
            return state;
        }
        
        return state.updateIn(['alerts'], alerts => alerts.push(fromJS(alert)));
    },
    [Types.REMOVE_ALERT]: (state, action) => {
        const id = action.payload.get('id');
        return state.updateIn(['alerts'], alerts => alerts.filter(alert => alert.get('id') !== id));
    }
}, INITIAL_STATE);

export default alertReducer;
