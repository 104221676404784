import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { dataStates } from '../../../../../../helpers/state';

const columns = [

    {
        id: 'rank',
        Header: 'Rank',
        headerClassName: 'custom-reports__th custom-reports__th_wrapped',
        className: 'custom-reports__td',
        accessor: d => d.rank,
    },
    {
        id: 'dogsName',
        Header: 'Dog\'s Name',
        headerClassName: 'custom-reports__th custom-reports__th_wrapped',
        className: 'custom-reports__td',
        accessor: d => d.name,
    },
    {
        id: 'ownerName',
        Header: 'Owner\'s Name',
        headerClassName: 'custom-reports__th custom-reports__th_wrapped',
        className: 'custom-reports__td',
        accessor: d => d.owner_detail.full_name,
    },
    {
        id: 'transactions',
        Header: 'Number of transactions',
        headerClassName: 'custom-reports__th custom-reports__th_wrapped',
        className: 'custom-reports__td',
        accessor: d => d.number_of_transactions,
    },
    {
        id: 'totalSpend',
        Header: 'Total Spend',
        headerClassName: 'custom-reports__th custom-reports__th_wrapped',
        className: 'custom-reports__td',
        accessor: d => d.total_spend,
    },
    {
        id: 'totalSales',
        Header: 'Percentage of Total Sales',
        headerClassName: 'custom-reports__th custom-reports__th_wrapped',
        className: 'custom-reports__td',
        accessor: d => `${d.percent.toFixed(2)}%`,
    },
];

const VipDogs = ({ items, dataState }) => {
    const renderTable = () => {
        if (dataState === dataStates.loaded && items && items.length) {
            items.forEach((item, i) => {
                item.rank = i + 1;
            });
            return (
                <ReactTable
                    showPagination={false}
                    defaultPageSize={items.length}
                    data={items}
                    columns={columns} />);
        }
        return null;
    };

    return (
        <div className='custom-reports__report'>
            {items !== undefined && renderTable()}
        </div>
    );
};

VipDogs.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    dataState: PropTypes.string.isRequired,
};

export default VipDogs;
