import * as axios from 'axios';
import { setCurrentDogReports } from '../actions/dashboard/dogReportsActions';
import { setGlobalError } from '../actions/errorHandlerActions';
import { getUserNameSpace } from '../helpers/userRoles';

export const getDogReports = ({ role, id }, nextUrl) => {
    return dispatch => {
        const url = nextUrl || `/v1/${getUserNameSpace('dog-reports')}/dog-reports/?dog=${id}`;
        return axios.get(url)
                    .then(response => {
                        dispatch(setCurrentDogReports({ data: response.data, shouldAppend: nextUrl !== undefined }));
                    })
                    .catch(err => {
                        console.log('Error: ', err);
                        dispatch(setGlobalError(err));
                    });
    };
};

export const getCustomerDogReports = (id, nextUrl) => {
    return dispatch => {
        const url = nextUrl || `/v1/customers/me/dogs/${id}/reports/?page_size=10`;
        return axios.get(url)
                    .then(response => {
                        dispatch(setCurrentDogReports({ data: response.data, shouldAppend: nextUrl !== undefined }));
                    })
                    .catch(err => {
                        console.log('Error: ', err);
                        dispatch(setGlobalError(err));
                    });
    };
};

export const getDogReportById = async ({ id }) => {
    const result = await axios.get(`/v1/${getUserNameSpace('dog-reports')}/dog-reports/${id}/`);
    return result;
};

export const deleteDogReport = ({ id }) => {
    return async dispatch => {
        const result = await axios.delete(`/v1/${getUserNameSpace('dog-reports')}/dog-reports/${id}`);
        return result;
    };
};
