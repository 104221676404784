import PropTypes from 'prop-types';
import React from 'react';
import LinearDashboardCard from './LinearDashboardCard';

const LinearDashboardGrid = ({ items }) => {
    const renderCards = () => {
        if (items === undefined) {
            return (
                <div className='attending-dogs-grid__empty-content'>
                    Loading...
                </div>
            );
        }

        if (!items.length) {
            return (
                <div className='attending-dogs-grid__empty-content'>
                    No dogs signed up for today.
                </div>
            );
        }

        const cards = [];

        items.forEach((item, i) => {
            cards.push(
                <LinearDashboardCard
                    key={'card_' + i}
                    data={item}/>
            );
        });

        return cards;
    };

    return (
        <div className='attending-dogs-grid'>
            {renderCards()}
        </div>
    );
};

LinearDashboardGrid.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})),
};

export default LinearDashboardGrid;
