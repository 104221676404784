/* eslint-disable react/no-multi-comp */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import * as FormFactory from '../../common/Form/formFactory';
import * as FormActions from '../../../actions/form/formActions';

class FormComponent extends PureComponent {
    renderForm = () => {
        const { data } = this.props;
        return FormFactory.getForm({
            formName: data.form,
            formProps: this.props,
            initialValues: data.initialValues,
        });
    };

    render() {
        const { className } = this.props;
        return (
            <form className={className}>
                {this.renderForm()}
            </form>
        );
    }
}

FormComponent.propTypes = {
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    onGetOptions: PropTypes.func,
    setFieldError: PropTypes.func,
    isSubmitting: PropTypes.bool,
    invalid: PropTypes.bool,
    data: PropTypes.shape({}),
    className: PropTypes.string,
    currentTrainer: PropTypes.shape({})

};

const FormComponentDecorated = reduxForm({ pure: true, enableReinitialize: true })(FormComponent);

class FormContainer extends PureComponent {
    setFieldError = ({ field, message }) => {
        const { setError, form } = this.props;
        setError({ form: form.form, field, message });
    };

    refFormComponent = null;

    doValidation = () => {
        this.refFormComponent.wrappedInstance.props.handleSubmit(() => {})();
    };

    normalizeFormData = (formConfig, formData) => {
        const { onSubmit } = this.props;
        const { submitNormalizer, updateNormalizer } = FormFactory.getFormDefinition(formConfig.form);
        onSubmit({
            formData,
            submitNormalizer,
            updateNormalizer,
            formConfig
        });
    };

    render() {
        const { form } = this.props;

        if (!form) {
            return null;
        }

        return (
            <FormComponentDecorated
                {...this.props}
                ref={ref => {
                    this.refFormComponent = ref;
                }}
                key={form.form}
                form={form.form}
                className={form.className || ''}
                data={form}
                onSubmit={(formData) => { this.normalizeFormData(form, formData); }}
                setFieldError={this.setFieldError}
                initialValues={form.initialValues}/>
        );
    }
}


FormContainer.propTypes = {
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    onGetOptions: PropTypes.func,
    setError: PropTypes.func,
    isSubmitting: PropTypes.bool,
    invalid: PropTypes.bool,
    forms: PropTypes.arrayOf(PropTypes.shape({})),
    form: PropTypes.shape({}),
    formState: PropTypes.shape({}),
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])

};

const getFormValues = (form, state) => {
    const formState = state.getIn(['form', form]);

    if (!formState) {
        return undefined;
    }
    return formState.get('values');
};

const mapStateToProps = (state, props) => {
    return {
        formValues: getFormValues(props.form.form, state)
    };
};

const mapDispatchToProps = {
    onSubmit: FormActions.onFormSubmit,
    onGetOptions: FormActions.onGetOptions,
    setError: FormActions.setError
};

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(FormContainer);
