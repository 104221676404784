/* eslint-disable no-unneeded-ternary */
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import * as HelperComponents from '../helperComponents';

const YesNoModalDialog = ({ onSubmit, onCancel, isSubmitting, isCanceling, data, modalError }) => {
    const {
        text,
        extraText,
        submittingText,
        noButtonText,
        cancelingText,
        yesButtonText,
        textComponent,
        isUppercase = true } = data;

    const noButtonClassNames = classNames({
        'button modal-dialog__button modal-dialog__button_secondary': true,
        'modal-dialog__button_fee': yesButtonText,
        'button_disabled': (isSubmitting || isCanceling)
    });
    const yesButtonClassNames = classNames({
        'button modal-dialog__button': true,
        'modal-dialog__button_fee': noButtonText,
        'button_disabled': (isSubmitting || isCanceling)
    });
    const TextNode = textComponent ? HelperComponents[textComponent] : null;

    const styleNoUppercase = {
        textTransform: 'none'
    };

    return (
        <div className='modal-dialog__form'>
            <div className='modal-dialog__text modal-dialog__text_bold'>
                {TextNode ? <TextNode data={data}/> : text}
            </div>
            {extraText &&
            <div className='modal-dialog__text'>
                {extraText}
            </div>
            }
            {modalError !== null && <div className='form__error'>{modalError}</div>}
            <div className='modal-dialog__form-footer modal-dialog__form-footer_column'>
                <button
                    style={isUppercase ? null : styleNoUppercase}
                    className={yesButtonClassNames}
                    disabled={isSubmitting || isCanceling}
                    onClick={onSubmit}>
                    {isSubmitting
                        ? submittingText
                         ? submittingText
                         : 'Processing...'
                        : yesButtonText
                         ? yesButtonText
                         : 'Yes'
                    }
                </button>
                <button
                    style={isUppercase ? null : styleNoUppercase}
                    className={noButtonClassNames}
                    disabled={isSubmitting || isCanceling}
                    onClick={onCancel}>
                    {isCanceling
                        ? cancelingText
                         ? cancelingText
                         : 'Submitting'
                        : noButtonText
                         ? noButtonText
                         : 'No'}
                </button>
            </div>
        </div>
    );
};

YesNoModalDialog.propTypes = {
    onSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool,
    isCanceling: PropTypes.bool,
    onCancel: PropTypes.func,
    data: PropTypes.shape({}),
    modalError: PropTypes.string,
};

export default YesNoModalDialog;
