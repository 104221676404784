import * as axios from 'axios';
import { getUserNameSpace, getUserRole } from '../helpers/userRoles';

export const getPurchaseHistory = ({ customerId, queryString }) => {
    return () => {
        let url = `/v1/${getUserNameSpace('customers.purchase-history')}/customers/${customerId}/purchase-history/`;
        if (getUserRole() === 'customer') {
            url = '/v1/customers/me/purchase-history/';
        }

        if (queryString !== undefined) {
            url = `${url}?${queryString}`;
        }

        return axios.get(url);
    };
};

export const getNextPurchaseHistory = () => {
    return (dispatch, getState) => {
        const nextUrl = getState().getIn(['currentCustomer', 'purchaseHistory', 'nextUrl']);
        return axios.get(nextUrl);
    };
};
