import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { dataStates } from '../../../../../../helpers/state';

const RebookingPercentage = ({ items, dataState }) => {
    const renderTable = () => {
        if (dataState === dataStates.loaded && items && items.data.length) {
            const columns = [
                {
                    id: 'name',
                    Header: 'Class Name',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.name,
                },
                {
                    id: 'attended',
                    Header: 'Dogs Attended',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.attended,
                },
                {
                    Header: '# of Rebooking',
                    headerClassName: 'custom-reports__th',
                    columns: [
                        {
                            id: 'book_1',
                            Header: '1x',
                            headerClassName: 'custom-reports__th',
                            className: 'custom-reports__td',
                            accessor: d => d.book_1,
                        },
                        {
                            id: 'book_2',
                            Header: '2x',
                            headerClassName: 'custom-reports__th',
                            className: 'custom-reports__td',
                            accessor: d => d.book_2,
                        },
                        {
                            id: 'book 3',
                            Header: '>3x',
                            headerClassName: 'custom-reports__th',
                            className: 'custom-reports__td',
                            accessor: d => d.book_3,
                        },
                    ],
                },
                {
                    Header: '% of rebooking',
                    headerClassName: 'custom-reports__th',
                    columns: [
                        {
                            id: 'book 4',
                            Header: '1x',
                            headerClassName: 'custom-reports__th',
                            className: 'custom-reports__td',
                            accessor: d => d.book_4,
                        },
                        {
                            id: 'book 5',
                            Header: '2x',
                            headerClassName: 'custom-reports__th',
                            className: 'custom-reports__td',
                            accessor: d => d.book_5,
                        },
                        {
                            id: 'book 6',
                            Header: '>3x',
                            headerClassName: 'custom-reports__th',
                            className: 'custom-reports__td',
                            accessor: d => d.book_6,
                        },
                    ],
                },
            ];

            const dataNormalized = [];
            items.data.forEach((item) => {
                // eslint-disable-next-line max-len
                if (!(item[4] === 0 && item[5] === 0 && item[6] === 0 && item[7] === 0 && item[8] === 0 && item[9] === 0)) {
                    dataNormalized.push({
                        name: item[2],
                        attended: item[3],
                        book_1: item[4],
                        book_2: item[5],
                        book_3: item[6],
                        book_4: item[7],
                        book_5: item[8],
                        book_6: item[9],
                    });
                }
            });

            return (
                <ReactTable
                    showPagination={false}
                    defaultPageSize={items.columns.length}
                    data={dataNormalized}
                    columns={columns}/>);
        }
        return null;
    };

    return (
        <div className='custom-reports__report'>
            {items !== undefined && renderTable()}
        </div>
    );
};

RebookingPercentage.propTypes = {
    items: PropTypes.shape({}).isRequired,
    dataState: PropTypes.string.isRequired,
};

export default RebookingPercentage;
