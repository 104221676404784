import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { clearCurrentCustomerData } from '../../../../actions/dashboard/employeeActions';
import {
    toggleBannedCurrentCustomer,
    toggleVipCurrentCustomer
} from '../../../../actions/dashboard/toggleActions';
import {
    getAccessLevelOptions,
    setChangeNameModalOpened,
    setDeleteCustomerModalOpened,
    setResetPasswordModalOpened
} from '../../../../actions/miscActions';
import { ReactComponent as IconEdit } from '../../../../assets/icons/icon-edit.svg';
import { ReactComponent as IconPassword } from '../../../../assets/icons/icon-password.svg';
import { ReactComponent as IconTrash } from '../../../../assets/icons/icon-trash.svg';
import { ReactComponent as IconVip } from '../../../../assets/icons/icon-vip.svg';
import { ReactComponent as IconZzz } from '../../../../assets/icons/icon-zzz.svg';
import { getCustomerById } from '../../../../services/customers';
import ContactCard from '../../../common/ContactCard';
import Container from '../../../common/Container';
import Navbar from '../../../common/Navbar';
import { isTrainer } from '../../../../helpers/userRoles';

class CustomersContainer extends React.PureComponent {
    static propTypes = {
        customer: PropTypes.shape({}),
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ]),
        accessLevelOptions: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.shape({})),
            PropTypes.shape({})
        ]),
        handleClearingCurrentCustomerData: PropTypes.func,
        handleGettingCurrentCustomer: PropTypes.func,
        handleChangeNameModalOpened: PropTypes.func,
        handleResetPasswordModalOpened: PropTypes.func,
        handleDeleteCustomerModalOpened: PropTypes.func,
        toggleVipCurrentCustomer: PropTypes.func,
        toggleBannedCurrentCustomer: PropTypes.func,
        handleGettingAccessLevelOptions: PropTypes.func
    };

    componentWillMount() {
        const {
            handleClearingCurrentCustomerData, handleGettingCurrentCustomer, handleGettingAccessLevelOptions,
            accessLevelOptions, location,
        } = this.props;
        // needed for Details Page
        if (!accessLevelOptions.size) {
            handleGettingAccessLevelOptions();
        }
        handleClearingCurrentCustomerData();
        handleGettingCurrentCustomer(location.pathname.split('/')[3]);
    }

    render() {
        const {
            customer, children, handleChangeNameModalOpened, toggleVipCurrentCustomer,
            handleResetPasswordModalOpened, toggleBannedCurrentCustomer, handleDeleteCustomerModalOpened,
            location,
        } = this.props;
        const contactItems = [
            {
                iconClass: 'icon_edit',
                icon: IconEdit,
                text: 'Edit Customer Name',
                onClickCB: handleChangeNameModalOpened
            },
            {
                iconClass: 'icon_password',
                icon: IconPassword,
                text: 'Reset Customer Password',
                onClickCB: () => {
                    handleResetPasswordModalOpened({
                        fullName: customer.getIn(['instance', 'full_name']),
                        email: customer.getIn(['instance', 'email'])
                    });
                }
            },
            {
                iconClass: 'icon_vip',
                icon: IconVip,
                text: customer.getIn(['instance', 'is_vip']) === 'Yes' ? 'Remove VIP Status' : 'Add VIP Status',
                onClickCB: toggleVipCurrentCustomer
            },
            {
                iconClass: 'icon_zzz',
                icon: IconZzz,
                text: customer.getIn(['instance', 'is_banned']) === 'Yes' ? 'Mark as Active' : 'Mark as Inactive',
                onClickCB: toggleBannedCurrentCustomer
            },
            {
                iconClass: 'icon_trash',
                icon: IconTrash,
                text: 'Delete Customer',
                onClickCB: handleDeleteCustomerModalOpened
            }
        ];
        const navbarItems = {
            context: 'customer',
            items: [
                {
                    page: 'details',
                    title: 'Details'
                },
                {
                    page: 'classes',
                    title: 'Classes'
                },
                {
                    page: 'trainings',
                    title: 'Trainings'
                },
                {
                    page: 'notes',
                    title: 'Notes'
                },
                {
                    page: 'forms',
                    title: 'Forms'
                },
                {
                    page: 'transactions',
                    title: 'Transaction History',
                    hidden: isTrainer()
                },
                {
                    page: 'vaccinations',
                    title: 'Vaccination Records'
                },
                {
                    page: 'packages-trainings',
                    title: 'Packages And Trainings'
                }
            ]
        };
        return (
            <Container className='dashboard__main'>
                <ContactCard functionalItems={contactItems} contact={customer}/>
                <Navbar items={navbarItems} id={location.pathname.split('/')[3]}/>
                {children}
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        accessLevelOptions: state.getIn(['misc', 'options', 'accessLevel']),
        customer: state.getIn(['currentCustomer'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleClearingCurrentCustomerData: bindActionCreators(clearCurrentCustomerData, dispatch),
    handleGettingCurrentCustomer: bindActionCreators(getCustomerById, dispatch),
    handleChangeNameModalOpened: bindActionCreators(setChangeNameModalOpened, dispatch),
    handleResetPasswordModalOpened: bindActionCreators(setResetPasswordModalOpened, dispatch),
    handleDeleteCustomerModalOpened: bindActionCreators(setDeleteCustomerModalOpened, dispatch),
    toggleVipCurrentCustomer: bindActionCreators(toggleVipCurrentCustomer, dispatch),
    toggleBannedCurrentCustomer: bindActionCreators(toggleBannedCurrentCustomer, dispatch),
    handleGettingAccessLevelOptions: bindActionCreators(getAccessLevelOptions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomersContainer));
