const validate = values => {
    const errors = {};
    if (!values.get('veterinarian')) {
        errors.veterinarian = 'Please select your Veterinarian';
    }
    if (values.get('records') === undefined || (values.get('records') !== null && !values.get('records').length)) {
        errors.records = 'Please attach vaccination record to proceed or select "I will fax or e-mail my dogs records"';
    } else if (values.get('records') === null) {
        errors.records = null;
    }
    return errors;
};

export default validate;
