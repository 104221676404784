import {
    isInvalid,
    isSubmitting,
    isPristine,
    getFormValues,
    getFormSyncWarnings,
    getFormSyncErrors } from 'redux-form';
import FormContainer from './FormContainer';

export const mapFormToState = (forms, state) => {
    const formStateMap = {};
    forms.forEach(form => {
        formStateMap[form] = {
            isInvalid: isInvalid(form)(state.toJS()),
            isSubmitting: isSubmitting(form)(state.toJS()),
            isPristine: isPristine(form)(state.toJS()),
            values: getFormValues(form)(state.toJS()),
            errors: getFormSyncErrors(form)(state.toJS()),
            warnings: getFormSyncWarnings(form)(state.toJS())
        };
    });
    return formStateMap;
};

export default FormContainer;
