import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import { Link } from 'react-router';
import { ReactComponent as IconCar } from '../../../../../assets/icons/icon-car.svg';
import { timePrettify } from '../../../../../helpers/date';

class ClassesSlider extends PureComponent {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({})),
    }

    componentDidMount() {
        window.addEventListener('load', () => {
            if (this.swiper) this.swiper.update(true);
        });
    }

    swiper = null;

    renderSlides() {
        const { items } = this.props;
        const defaultImage = '/assets/imgs/featured-class-desktop.jpg';

        return (
            items.map((item, index) => {
                const location_array_detail = item.location_array_detail
                const item_locations = Object.keys(location_array_detail).reduce((resultArray, location_key) => {
                    resultArray.push(location_array_detail[location_key]);
                    return resultArray;
                }, []);
                return (
                    <div className='classes-slider__item' key={`classes-slider__item_${index}`}>
                        <div className='classes-slider__image' style={{ backgroundImage: `url(${item.photo_processed || defaultImage})` }} />
                        <div className='info-block classes-slider__info-block'>
                            <div className='info-block__content'>
                                <div className='info-block__title'>{item.name}</div>
                                <div className='info-block__location'>{item_locations.join(', ')}</div>
                                <div className='info-block__days'>{item.daysNormalized}</div>
                                <div className='info-block__description'>{item.description}</div>
                                <div className='customer-class-card__time info-block__time'>
                                    <IconCar className='customer-class-card__car'/>
                                    <div className='customer-class-card__time-list'>
                                        <div className='customer-class-card__time-item'>Pickup: {timePrettify(item.pickup_start_time)} - {timePrettify(item.pickup_end_time)}</div>
                                        <div className='customer-class-card__time-item'>Dropoff: {timePrettify(item.dropoff_start_time)} - {timePrettify(item.dropoff_end_time)}</div>
                                    </div>
                                </div>
                            </div>
                            <Link to={item.link_to_website} target='_blank' className='info-block__button'>Learn more</Link>
                        </div>
                    </div>
                );
            })
        );
    }

    render() {
        const { items } = this.props;
        const isMobile = window.innerWidth <= 736;
        let showButtons = items.length > 2;
        if (isMobile && items.length > 1) showButtons = true;

        const params = {
            containerClass: 'classes-slider__swiper',
            nextButton: showButtons ? '.swiper-button-next' : null,
            prevButton: showButtons ? '.swiper-button-prev' : null,
            spaceBetween: isMobile ? 0 : 15,
            slidesPerView: isMobile ? 1 : 2,
            initialSlide: 0,
            onSlideChangeEnd: (swiper) => {
                swiper.update(true);
            },
            runCallbackOnInit: true,
            onInit: (swiper) => {
                this.swiper = swiper;
                setTimeout(() => {
                    swiper.update(true);
                }, 100);
                swiper.update(true);
            },
        };

        return (
            <div className='classes-slider'>
                <div className='classes-slider__title'>Featured classes</div>
                <div className='classes-slider__body'>
                    <Swiper {...params}>
                        {this.renderSlides()}
                    </Swiper>
                </div>
            </div>
        );
    }
}

export default ClassesSlider;
