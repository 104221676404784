/* eslint-disable camelcase */
import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import moment from 'moment';
import { getOnlyUniqueItems } from '../helpers/array';
import { formatTo12HourFormat, normalizeTimezoneDateToDateFormat } from '../helpers/date';
import * as classOccurrencesTypes from '../types/dashboard/classOccurrencesTypes';

const INITIAL_STATE = fromJS({
    classOccurrencesItems: [],
    classOccurrencesTrainersList: [],
    count: 0,
    totalCount: 0,
    pageSize: 0,
    pages: 0,
    nextUrl: null,
    previousUrl: null,
    isClassOccurrencesLoading: false,
    isClassOccurrencesNextLoading: false
});

export const classOccurrenceDataNormalizer = data => {
    return data.map(item => {
        const { start, end } = item;
        const { name, full_name } = item.training_class_detail;
        item.start = normalizeTimezoneDateToDateFormat(start);
        item.startOriginal = moment(start).format();
        item.formattedStart = formatTo12HourFormat(start);
        item.formattedEnd = formatTo12HourFormat(end);
        item.end = normalizeTimezoneDateToDateFormat(end);
        item.endOriginal = moment(end).format();
        item.title = name;
        item.trainer_name = full_name;
        item.isClass = true;
        return item;
    });
};

const classOccurrencesListReducer = handleActions({
    [classOccurrencesTypes.SET_CLASS_OCCURRENCES_LIST]: (state, action) => {
        const { results, count_total, count, next, previous } = action.payload;
        const nextUrl = (next === null || next === undefined) ? next : next.slice(4);
        const previousUrl = (previous === null || previous === undefined) ? previous : previous.slice(4);
        const pagesCount = count === undefined ? count : Math.ceil(count / results.length);
        const totalCount = count_total || action.payload.length;
        const data = results === undefined ? action.payload : results;
        const trainersList = data.map(result => {
            return result.trainer;
        });

        return state.update((state) => {
            return state.setIn(['classOccurrencesItems'], fromJS(classOccurrenceDataNormalizer(data)))
                        .setIn(['classOccurrencesTrainersList'], fromJS(getOnlyUniqueItems(trainersList)))
                        .setIn(['count'], count)
                        .setIn(['totalCount'], totalCount)
                        .setIn(['pageSize'], data.length)
                        .setIn(['pages'], pagesCount)
                        .setIn(['nextUrl'], nextUrl)
                        .setIn(['previousUrl'], previousUrl);
        });
    },
    [classOccurrencesTypes.SET_NEXT_CLASS_OCCURRENCES_LIST]: (state, action) => {
        const { results, count_total, count, next, previous } = action.payload;
        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);
        const pagesCount = Math.ceil(count / results.length);
        // eslint-disable-next-line camelcase
        const totalCount = count_total;
        const trainersList = results.map(result => {
            return result.trainer;
        });

        return state.update((state) => {
            return state.setIn(['classOccurrencesItems'],
                state.get('classOccurrencesItems').concat(fromJS(classOccurrenceDataNormalizer(results))))
                        .setIn(['classOccurrencesTrainersList'],
                            state.get('classOccurrencesItems').concat(fromJS(getOnlyUniqueItems(trainersList))))
                        .setIn(['count'], count)
                        .setIn(['totalCount'], totalCount)
                        .setIn(['pageSize'], results.length)
                        .setIn(['pages'], pagesCount)
                        .setIn(['nextUrl'], nextUrl)
                        .setIn(['previousUrl'], previousUrl);
        });
    },
    [classOccurrencesTypes.START_CLASS_OCCURRENCES_LIST_LOADING]: (state, action) => (
        state.setIn(['isClassOccurrencesLoading'], true)
    ),
    [classOccurrencesTypes.FINISH_CLASS_OCCURRENCES_LIST_LOADING]: (state, action) => (
        state.setIn(['isClassOccurrencesLoading'], false)
    ),
    [classOccurrencesTypes.START_CLASS_OCCURRENCES_NEXT_LIST_LOADING]: (state, action) => (
        state.setIn(['isClassOccurrencesNextLoading'], true)
    ),
    [classOccurrencesTypes.FINISH_CLASS_OCCURRENCES_NEXT_LIST_LOADING]: (state, action) => (
        state.setIn(['isClassOccurrencesNextLoading'], false)
    )
}, INITIAL_STATE);

export default classOccurrencesListReducer;
