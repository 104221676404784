import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import * as dashboardPageActions from '../../../../actions/dashboard/dashboardPageActions';
import * as TabActions from '../../../../actions/tabActions';
import DashboardPage from '../DashboardPage';

class DashboardPageContainer extends React.PureComponent {
    static propTypes = {
        locationOptions: PropTypes.shape({}),
        classOptions: PropTypes.shape({}),
        glanceDataAll: PropTypes.shape({}),
        glanceTrainingDataAll: PropTypes.shape({}),
        glanceDataLocation: PropTypes.shape({}),
        glanceTrainingDataLocation: PropTypes.shape({}),
        dailyNotesData: PropTypes.shape({}),
        activePage: PropTypes.string,
        currentDate: PropTypes.string,
        glanceView: PropTypes.string,
        currentNotesDate: PropTypes.string,
        currentFilter: PropTypes.shape({}),
        selectedZoneCard: PropTypes.shape({}),
        selectedTrainingZoneCard: PropTypes.shape({}),
        glanceClassesProductsAll: PropTypes.shape({}),
        glanceTrainingsProductsAll: PropTypes.shape({}),
        resetState: PropTypes.func,
        getGlanceAll: PropTypes.func,
        getGlanceTrainingAll: PropTypes.func,
        getGlanceLocation: PropTypes.func,
        getGlanceTrainingLocation: PropTypes.func,
        getGlanceClassesProductsAllData: PropTypes.func,
        getGlanceTrainingsProductsAllData: PropTypes.func,
        getSetGlanceInitData: PropTypes.func,
        getMoreDailyNotesData: PropTypes.func,
        setCurrentDate: PropTypes.func,
        setGlanceView: PropTypes.func,
        setCurrentNotesDate: PropTypes.func,
        setCurrentFilter: PropTypes.func,
        setSelectedZoneCard: PropTypes.func,
        setSelectedTrainingZoneCard: PropTypes.func,
        handlePageChange: PropTypes.func,
        handleDailyNotesSubmit: PropTypes.func,
        getDailyNotes: PropTypes.func,
        filterLinearDashboard: PropTypes.func,
    };

    componentDidMount() {
        const { handlePageChange, activePage } = this.props;
        handlePageChange({ nextPageName: activePage });
    }

    componentWillUnmount() {
        const { resetState } = this.props;
        resetState();
    }

    render() {
        const {
            locationOptions,
            classOptions,
            glanceDataAll,
            glanceTrainingDataAll,
            glanceDataLocation,
            glanceTrainingDataLocation,
            glanceClassesProductsAll,
            glanceClassesProducts,
            glanceTrainingsProductsAll,
            glanceTrainingsProducts,
            dailyNotesData,
            activePage,
            currentDate,
            glanceView,
            currentNotesDate,
            currentFilter,
            selectedZoneCard,
            selectedTrainingZoneCard,
            setCurrentDate,
            setGlanceView,
            setCurrentNotesDate,
            setCurrentFilter,
            handlePageChange,
            handleDailyNotesSubmit,
            setSelectedZoneCard,
            setSelectedTrainingZoneCard,
            getGlanceAll,
            getGlanceTrainingAll,
            getGlanceLocation,
            getGlanceTrainingLocation,
            getMoreDailyNotesData,
            getGlanceClassesProductsAllData,
            getGlanceTrainingsProductsAllData,
            getDailyNotes,
            filterLinearDashboard
        } = this.props;

        return (
            <DashboardPage
                activePage={activePage}
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
                glanceView={glanceView}
                currentNotesDate={currentNotesDate}
                currentFilter={currentFilter}
                glanceDataAll={glanceDataAll}
                glanceTrainingDataAll={glanceTrainingDataAll}
                glanceDataLocation={glanceDataLocation}
                glanceTrainingDataLocation={glanceTrainingDataLocation}
                glanceClassesProductsAll={glanceClassesProductsAll}
                glanceClassesProducts={glanceClassesProducts}
                glanceTrainingsProductsAll={glanceTrainingsProductsAll}
                glanceTrainingsProducts={glanceTrainingsProducts}
                dailyNotesData={dailyNotesData}
                selectedZoneCard={selectedZoneCard}
                selectedTrainingZoneCard={selectedTrainingZoneCard}
                setGlanceView={setGlanceView}
                setCurrentNotesDate={setCurrentNotesDate}
                setCurrentFilter={setCurrentFilter}
                onPageChange={handlePageChange}
                onDailyNotesSubmit={handleDailyNotesSubmit}
                setSelectedZoneCard={setSelectedZoneCard}
                setSelectedTrainingZoneCard={setSelectedTrainingZoneCard}
                locationOptions={locationOptions}
                classOptions={classOptions}
                getGlanceAll={getGlanceAll}
                getGlanceTrainingAll={getGlanceTrainingAll}
                getGlanceLocation={getGlanceLocation}
                getGlanceTrainingLocation={getGlanceTrainingLocation}
                getMoreDailyNotesData={getMoreDailyNotesData}
                getGlanceClassesProductsAllData={getGlanceClassesProductsAllData}
                getGlanceTrainingsProductsAllData={getGlanceTrainingsProductsAllData}
                getDailyNotes={getDailyNotes}
                filterLinearDashboard={filterLinearDashboard}
                />
        );
    }
}

const mapStateToProps = state => {
    return {
        activePage: state.getIn(['dashboardPage', 'activePage']),
        currentDate: state.getIn(['dashboardPage', 'currentDate']),
        glanceView: state.getIn(['dashboardPage', 'glanceView']),
        currentNotesDate: state.getIn(['dashboardPage', 'currentNotesDate']),
        currentFilter: state.getIn(['dashboardPage', 'currentFilter']),
        selectedZoneCard: state.getIn(['dashboardPage', 'selectedZoneCard']),
        selectedTrainingZoneCard: state.getIn(['dashboardPage', 'selectedTrainingZoneCard']),
        locationOptions: state.getIn(['misc', 'options', 'location']),
        classOptions: state.getIn(['misc', 'options', 'class']),
        glanceDataAll: state.getIn(['dashboardPage', 'glanceDataAll']),
        glanceTrainingDataAll: state.getIn(['dashboardPage', 'glanceTrainingDataAll']),
        glanceDataLocation: state.getIn(['dashboardPage', 'glanceDataLocation']),
        glanceTrainingDataLocation: state.getIn(['dashboardPage', 'glanceTrainingDataLocation']),
        glanceClassesProductsAll: state.getIn(['dashboardPage', 'glanceClassesProductsAll']),
        glanceTrainingsProductsAll: state.getIn(['dashboardPage', 'glanceTrainingsProductsAll']),
        glanceClassesProducts: state.getIn(['dashboardPage', 'glanceClassesProducts']),
        glanceTrainingsProducts: state.getIn(['dashboardPage', 'glanceTrainingsProducts']),
        dailyNotesData: state.getIn(['dashboardPage', 'dailyNotes'])
    };
};

const mapDispatchToProps = {
    resetState: dashboardPageActions.resetDashboardPageReducer,
    getGlanceAll: dashboardPageActions.getGlanceAllData,
    getGlanceTrainingAll: dashboardPageActions.getGlanceTrainingAllData,
    getGlanceLocation: dashboardPageActions.getGlanceLocationData,
    getGlanceTrainingLocation: dashboardPageActions.getGlanceTrainingLocationData,
    getSetGlanceInitData: dashboardPageActions.getSetGlanceInitData,
    getMoreDailyNotesData: dashboardPageActions.getMoreDailyNotesData,
    handlePageChange: TabActions.handlePageChange,
    handleDailyNotesSubmit: dashboardPageActions.submitDailyNotesData,
    setCurrentDate: dashboardPageActions.setCurrentDate,
    setGlanceView: dashboardPageActions.setGlanceView,
    setCurrentNotesDate: dashboardPageActions.setCurrentNotesDate,
    setCurrentFilter: dashboardPageActions.setCurrentFilter,
    setSelectedZoneCard: dashboardPageActions.setSelectedZoneCard,
    setSelectedTrainingZoneCard: dashboardPageActions.setSelectedTrainingZoneCard,
    getGlanceClassesProductsAllData: dashboardPageActions.getGlanceClassesProductsAllData,
    getGlanceTrainingsProductsAllData: dashboardPageActions.getGlanceTrainingsProductsAllData,
    getDailyNotes: dashboardPageActions.getDailyNotes,
    filterLinearDashboard: dashboardPageActions.filterLinearDashboard,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPageContainer);
