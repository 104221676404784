const validate = values => {
    // IMPORTANT: values is an Immutable.Map here!
    const errors = {};
    if (values.get('is_intoleranced') === 'Yes' && !values.get('intolerance_list')) {
        errors.intolerance_list = 'Field is Required.';
    }
    if (values.get('is_injured') === 'Yes' && !values.get('medical_notes')) {
        errors.medical_notes = 'Field is Required.';
    }
    return errors;
};

export default validate;
