import { Link } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { formatDateToSlashes } from '../../../../../../helpers/date';
import { dataStates } from '../../../../../../helpers/state';

const Incident = ({ items, dataState }) => {
    const renderTable = () => {
        if (dataState === dataStates.loaded && items && items.length) {
            const columns = [
                {
                    id: 'date',
                    Header: 'Date',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.created_date,
                    Cell: row => (<div>{formatDateToSlashes(row.value, true)}</div>),
                },
                {
                    id: 'ownersName',
                    Header: 'Owner\'s Name',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.dog.owner.full_name,
                    Cell: (row) => (
                        <Link to={`/dashboard/customer/${row.original.dog.owner.id}/details`}>{row.value}</Link>
                    ),
                },
                {
                    id: 'dogsName',
                    Header: 'Dog\'s Name',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.dog.name,
                    Cell: (row) => <Link to={`/dashboard/dog/${row.original.dog.id}/details`}>{row.value}</Link>,
                },
                {
                    id: 'tag',
                    Header: 'Tag',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.tag_name,
                    Cell: (row) => <Link to={`/dashboard/dog/${row.original.dog.id}/notes`}>{row.value}</Link>,
                },
                {
                    id: 'description',
                    Header: 'Description',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.body,
                    Cell: (row) => <Link to={`/dashboard/dog/${row.original.dog.id}/notes`}>{row.value}</Link>,
                },
            ];

            return (
                <ReactTable
                    showPagination={false}
                    defaultPageSize={items.length}
                    data={items}
                    columns={columns}/>);
        }
        return null;
    };

    return (
        <div className='custom-reports__report'>
            {items !== undefined && renderTable()}
        </div>
    );
};

Incident.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    dataState: PropTypes.string.isRequired,
};

export default Incident;
