import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormNames, reset } from 'redux-form';
import { signInFacebook, signInGoogle, signUp } from '../../../../actions/onboarding/userActions';
import Divider from '../../../common/Divider/Divider';
import SkipStep from '../../../common/SkipStep';
import SocialLogin from '../../../common/SocialLogin';
import Step from '../../../common/Step';
import SignUpFormContainer from './SignUpForm';

class SignUpPage extends React.PureComponent {
    static propTypes = {
        stepName: PropTypes.string,
        handleSignUp: PropTypes.func,
        handleFaceBookSignIn: PropTypes.func,
        handleGoogleSignIn: PropTypes.func,
        resetForm: PropTypes.func,
        formNames: PropTypes.arrayOf(PropTypes.string),
    };

    constructor() {
        super();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
        const { formNames, resetForm } = this.props;
        if (formNames.length) {
            formNames.forEach(form => {
                resetForm(form);
            });
        }
    }

    handleSubmit(data) {
        const { handleSignUp } = this.props;

        window._dcq.push(['identify', {
            email: data.get('user_email'),
            full_name: data.get('full_name'),
            success: () => {
                window._dcq.push(['track', 'Created owner profile']);
            }
        }]);

       return handleSignUp({ data });
    }

    render() {
        const {
            stepName = 'signup',
            handleFaceBookSignIn,
            handleGoogleSignIn,
        } = this.props;

        return (
            <Step
                logoClassName='signup__logo'
                contentClassName='signup__content'
                contentsContainerClassName='signup__container'
                stepName={stepName}
                componentsImage={<div className='signup__img'/>}
                dividerComponent={
                    <Divider
                        dividerLineClassName='signup__divider'
                        dividerTextClassName='signup__divider-text'
                        text='or'/>
                }
                socialLoginComponent={
                    <SocialLogin
                        socialLoginClassName='signup__social-login'
                        faceBookCB={handleFaceBookSignIn}
                        googleCB={handleGoogleSignIn}/>
                }
                formComponent={
                    <SignUpFormContainer
                        onSubmit={this.handleSubmit}/>
                }
                skipComponent={
                    <SkipStep
                        className='signup__skip-step'
                        text='Already have an account?'
                        linkTo='/'
                        linkText='Sign In'/>
                }/>
        );
    }
}

const mapStateToProps = state => {
    return {
        formNames: getFormNames()(state.toJS()),
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleSignUp: bindActionCreators(signUp, dispatch),
    handleFaceBookSignIn: bindActionCreators(signInFacebook, dispatch),
    handleGoogleSignIn: bindActionCreators(signInGoogle, dispatch),
    resetForm: bindActionCreators(reset, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);
