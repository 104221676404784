import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as ReportsTypes from '../types/dashboard/reportsTypes';
import { SET_REPORTS_ACTIVE_PAGE } from '../types/tabTypes';

const INITIAL_STATE = fromJS({
    activePage: 'overview',
    start: undefined,
    end: undefined,
});

const reportsReducer = handleActions({
    [SET_REPORTS_ACTIVE_PAGE]: (state, action) => {
        return state.set('activePage', action.payload);
    },
    [ReportsTypes.RESET_REPORTS]: () => {
        return INITIAL_STATE;
    },
    [ReportsTypes.SET_REPORTS_START_DATE]: (state, action) => {
        return state.set('start', action.payload);
    },
    [ReportsTypes.SET_REPORTS_END_DATE]: (state, action) => {
        return state.set('end', action.payload);
    },
}, INITIAL_STATE);

export default reportsReducer;
