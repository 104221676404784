import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

const Navbar = ({ items, id }) => {
    return (
        <nav className='navbar'>
            <div className='navbar__list'>
                {items.items.map((item) => { // eslint-disable-line
                    const isHidden = item.hidden || false;
                    if (!isHidden) {
                        return (
                            <Link
                                key={item.page}
                                to={`/dashboard/${items.context}/${id}/${item.page}`}
                                className='navbar__item'
                                activeClassName='navbar__item_active'>
                                <span>{item.title}</span>
                            </Link>
                        );
                    }
                })}
            </div>
        </nav>
    );
};

Navbar.propTypes = {
    items: PropTypes.shape({}).isRequired,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default Navbar;
