import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const LinkButton = ({ className, text, onClickHandler, isDisabled }) => {
    const buttonClassNames = classNames(
        {
            'link-button': true,
            'link-button_disabled': isDisabled
        },
        className
    );
    
    const handleClick = () => {};
    
    return (
        <button className={buttonClassNames} onClick={onClickHandler || handleClick}>
            {text}
        </button>
    );
};

LinkButton.propTypes = {
    text: PropTypes.string.isRequired,
    onClickHandler: PropTypes.func,
    className: PropTypes.string,
    isDisabled: PropTypes.bool
};

export default LinkButton;
