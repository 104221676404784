import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PackagesTrainingsTable from './PackagesTrainingsTable';
import AddNewItem from '../../../../common/AddNewItem';
import { openSellPackagesTrainingsDialog, openSellTrainingsDialog } from '../../../../../actions/modal/openActions';
import { isTrainer } from '../../../../../helpers/userRoles';

// eslint-disable-next-line react/prefer-stateless-function
const PackagesTrainingsPage = ({ currentCustomer, currentUser, openSellPackagesTrainingsDialog, openSellTrainingsDialog}) => {
    const customerData = {
        id: currentCustomer.getIn(['instance', 'id']),
        name: currentCustomer.getIn(['instance', 'full_name']),
    };

    return (
        <div className='customers-packages-trainings'>
             {isTrainer() && <div className='customers-packages-trainings__add-new-container'>
                <AddNewItem
                    className='customers-packages-trainings__add-new'
                    text='Sell Training'
                    onClickHandler={() => openSellTrainingsDialog(customerData, currentUser)}/>
            </div>}
            {!isTrainer() && <div className='customers-packages-trainings__add-new-container'>
                <AddNewItem
                    className='customers-packages-trainings__add-new'
                    text='Sell Package/Training'
                    onClickHandler={() => openSellPackagesTrainingsDialog(customerData)}/>
            </div>}
            {customerData.id === undefined
                ? 'Loading...'
                : (<PackagesTrainingsTable
                    data={currentCustomer.getIn(['packagesTrainingProducts', 'items'])}
                    currentCustomerId={currentCustomer.getIn(['instance', 'id'])}
                    dataName='Packages and Trainings'/>)
            }
        </div>
    );
};

PackagesTrainingsPage.propTypes = {
    currentCustomer: PropTypes.shape({}),
    openSellPackagesTrainingsDialog: PropTypes.func,
    openSellTrainingsDialog: PropTypes.func
};

const mapStateToProps = state => {
    return {
        currentCustomer: state.getIn(['currentCustomer']),
        currentUser: {
            value: state.getIn(['user', 'id']),
            label: state.getIn(['user', 'full_name'])
        },
    };
};

const mapDispatchToProps = dispatch => ({
    openSellPackagesTrainingsDialog: bindActionCreators(openSellPackagesTrainingsDialog, dispatch),
    openSellTrainingsDialog: bindActionCreators(openSellTrainingsDialog, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PackagesTrainingsPage);
