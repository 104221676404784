import React from 'react';
import PropTypes from 'prop-types';
import DropZone from 'react-dropzone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import * as AvatarActions from '../../../actions/dashboard/avatarActions';
import Icon from '../Icon';
import { ReactComponent as IconAddPhoto } from '../../../assets/icons/icon-add-photo.svg';
import { ReactComponent as IconCamera } from '../../../assets/icons/icon-camera.svg';
import { ReactComponent as IconDog } from '../../../assets/icons/dog.svg';
import * as AlertActions from '../../../components/Alert/actions';
import { formatBytes } from '../../../helpers/data';
import { FILE_SIZE_MAX, FILE_IMAGE_TYPES, FILE_IMAGE_WIDTH_MAX, FILE_IMAGE_HEIGHT_MAX } from '../../../config';
import { getOrientation, rotateImage } from '../../../helpers/image';

class AvatarUploader extends React.PureComponent {
    static propTypes = {
        target: PropTypes.string,
        photo: PropTypes.string,
        uploadAvatar: PropTypes.func,
        isDisabled: PropTypes.bool,
        subtitle: PropTypes.bool,
        className: PropTypes.string,
        uploadAvatarDog: PropTypes.func,
        generateAlertMeta: PropTypes.func,
        customerDogId: PropTypes.number
    };

    state = {
        file: null,
        uploadProgress: 100,
    };

    componentWillUnmount() {
        this.clearPreview();
    }

    onDrop = (acceptedFiles) => {
        const { uploadAvatar, uploadAvatarDog, target, customerDogId, generateAlertMeta } = this.props;
        const file = acceptedFiles[0];

        if (file.size < FILE_SIZE_MAX) {
            const reader = new FileReader();
            const imagePreview = new Image();
            // const objectURL = URL.createObjectURL(file);
            imagePreview.onload = async () => {
                let { width, height } = imagePreview;
                const { orientation, needsRotation } = await getOrientation(file);

                if (needsRotation) {
                    [width, height] = [height, width];
                }

                if (width <= FILE_IMAGE_WIDTH_MAX && height <= FILE_IMAGE_HEIGHT_MAX) {
                    if (needsRotation) {
                        const rotatedCanvas = await rotateImage({ imageObject: imagePreview, orientation });
                        file.preview = rotatedCanvas.toDataURL();
                    }

                    this.clearPreview();
                    this.setState({
                        file
                    });
                    if (customerDogId) {
                        uploadAvatarDog(customerDogId, file, this.onUploadProgress);
                    } else {
                        uploadAvatar(target, file, this.onUploadProgress);
                    }
                } else {
                    generateAlertMeta({
                        event: 'MAX_RESOLUTION_ERROR',
                        type: 'error',
                        alertData: {
                            maxWidth: FILE_IMAGE_WIDTH_MAX,
                            maxHeight: FILE_IMAGE_HEIGHT_MAX
                        }
                    });
                }
            };
            reader.onload = (e) => {
                imagePreview.src = e.target.result;
            };
            reader.readAsDataURL(file);
        } else {
            generateAlertMeta({
                event: 'MAX_FILE_SIZE_ERROR',
                type: 'error',
                alertData: {
                    maxSize: formatBytes(FILE_SIZE_MAX)
                }
            });
        }
    };

    onUploadProgress = (event) => {
        this.setState({
            uploadProgress: Math.round((event.loaded * 100) / event.total)
        });
    };

    getUploadProgress = () => {
        if (this.state.uploadProgress === 100) {
            return null;
        }

        return <div className='avatar__upload-progress'>{this.state.uploadProgress}%</div>;
    };

    getAvatarPlaceholder = () => {
        const { photo, customerDogId } = this.props;

        if (photo === undefined) {
            return <div className='avatar__text avatar__text-placeholder'>Loading...</div>;
        }
        if (customerDogId) {
            return <Icon className='avatar__icon avatar__icon_dog' glyph={IconDog}/>;
        }
        return <Icon className='avatar__icon icon_camera' glyph={IconCamera}/>;
    };

    getAvatarImage = () => {
        const { photo } = this.props;
        if (!photo && !this.state.file) {
            return this.getAvatarPlaceholder();
        }

        return (
            <div
                style={{
                    background: 'url(' + (this.state.file ? this.state.file.preview : photo) +
                    ') no-repeat center center / cover'
                }}
                className='avatar__img'>
                {this.getUploadProgress()}
            </div>
        );
    };

    getAvatar = () => {
        const { photo, subtitle, isDisabled } = this.props;
        return (
            <div className='avatar'>
                <div className='avatar__container'>
                    {!isDisabled && <div className='avatar__overlay'>
                        <Icon glyph={IconAddPhoto} className='avatar__icon icon_add_photo'/>
                        <div className='avatar__text'>
                            {photo === null
                                ? 'Add Photo'
                                : 'Change Photo'
                            }
                        </div>
                    </div>}
                    {this.getAvatarImage()}
                </div>
                {subtitle && <span className='avatar__upload-btn'>Upload Profile&nbsp;Photo</span>}
            </div>
        );
    };

    clearPreview = () => {
        if (this.state.file) {
            // Avoid memory leaks.
            window.URL.revokeObjectURL(this.state.file.preview);
        }
    };

    render() {
        const dropZoneClassName = classNames({
            'avatar__dropzone': true,
            [this.props.className]: this.props.className
        });
        return (
            <DropZone
                disabled={this.props.isDisabled}
                disableClick={this.props.isDisabled}
                name={'avatar'}
                className={dropZoneClassName}
                accept={FILE_IMAGE_TYPES}
                multiple={false}
                onDrop={this.onDrop}>
                {this.getAvatar()}
            </DropZone>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    uploadAvatar: bindActionCreators(AvatarActions.uploadAvatar, dispatch),
    uploadAvatarDog: bindActionCreators(AvatarActions.uploadAvatarDog, dispatch),
    generateAlertMeta: bindActionCreators(AlertActions.generateAlertMeta, dispatch),
});

export default connect(null, mapDispatchToProps)(AvatarUploader);
