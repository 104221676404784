import React from 'react';
import PropTypes from 'prop-types';
import ContentBlock from '../../../../../common/ContentBlock';
import AddNewItem from '../../../../../common/AddNewItem';

const ContactsBlock = ({ title, emptyText, addItemText, data, action = null, addNewAction = null }) => {
    const getContacts = () => {
        return data.filter(item => item.name).map(item => {
            return {
                header: item.name,
                content: item.name && <span>{item.phone_number} <br />{item.relationship}</span>,
                column: true
            };
        });
    };

    const getAdditioanlBlock = () => {
        let hasContacts = false;
        data.forEach(element => {
            if ('name' in element) hasContacts = true;
        });
        if (hasContacts) return null;
        return (
            <div>
                <p className='content-block__text'>{emptyText}</p>
                <div className='content-block__add-item'>
                    <AddNewItem text={addItemText} onClickHandler={addNewAction}/>
                </div>
            </div>
        );
    };

    return (
        <ContentBlock
            title={title}
            editAction={action ? () => action() : null}
            items={getContacts()}
            additionalBlock={getAdditioanlBlock()} />
    );
};

ContactsBlock.propTypes = {
    title: PropTypes.string,
    emptyText: PropTypes.string,
    addItemText: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
    action: PropTypes.func,
    addNewAction: PropTypes.func,
};

export default ContactsBlock;
