/* eslint-disable camelcase */
import * as axios from 'axios';
import { getUserNameSpace } from '../helpers/userRoles';

export const getOverviewNewObjectsData = ({ start, end }) => {
    return dispatch => {
        return axios.get(
            `/v1/${getUserNameSpace('overview-reports')}/reports/overview-objects-counts/${start}/${end}/`);
    };
};

export const getOverviewClassReservationsData = ({ start, end }) => {
    return dispatch => {
        return axios.get(
            `/v1/${getUserNameSpace('overview-reports')}/reports/overview-class-reservations/${start}/${end}/`);
    };
};

export const getOverviewReportCardsData = ({ start, end }) => {
    return dispatch => {
        return axios.get(
            `/v1/${getUserNameSpace('overview-reports')}/reports/overview-cards-percent/${start}/${end}/`);
    };
};

export const getOverviewClassReservationsByZoneData = ({ start, end }) => {
    return dispatch => {
        return axios.get(
            `/v1/${getUserNameSpace(
                'overview-reports')}/reports/overview-class-reservations-by-zone/${start}/${end}/`);
    };
};

export const getOverviewBookingsByTrainerData = ({ start, end }) => {
    return dispatch => {
        return axios.get(
            `/v1/${getUserNameSpace(
                'overview-reports')}/reports/overview-bookings-by-trainer/${start}/${end}/`);
    };
};

export const getOverviewTotalRevenueData = ({ start, end }) => {
    return dispatch => {
        return axios.get(
            `/v1/${getUserNameSpace('overview-reports')}/reports/overview-total-revenue/${start}/${end}/`);
    };
};
