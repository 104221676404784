import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import renderInputControl from '../../../../../common/Form/InputControl';
import renderSelectControl from '../../../../../common/Form/SelectControl';
import validate from './validate';

const notesTagOptions = [
    { value: 0, label: 'Incident' },
    { value: 1, label: 'Health' },
    { value: 2, label: 'Behavioral' },
    { value: 3, label: 'Employee' },
];

const NotesForm = ({ handleSubmit, invalid, submitting, isLoading }) => {
    const submitButtonClassNames = classNames({
        'notes-incidents__submit-button': true,
        'notes-incidents__submit-button_disabled': invalid || submitting
    });

    const getSubmitButtonText = () => {
        return (!isLoading && !submitting) ? 'Add Note' : (submitting ? 'Submitting...' : 'Loading...');
    };

    return (
        <form onSubmit={handleSubmit} className='notes-incidents__form'>
            <div className='input-control'>
                <Field
                    name='notes'
                    isTextarea
                    value='stuff'
                    component={renderInputControl}
                    className='notes-incidents__textarea'
                    label='Create a new note...'/>
                <Field
                    name='tag'
                    type='text'
                    label='tag'
                    component={renderSelectControl}
                    options={notesTagOptions}/>
                <button className={submitButtonClassNames} disabled={submitting}>
                    {getSubmitButtonText()}
                </button>
            </div>
        </form>
    );
};

NotesForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    isLoading: PropTypes.bool
};

const form = reduxForm({
    form: 'dogNotesForm',
    validate
});

const mapStateToProps = state => {
    return {
        isLoading: state.getIn(['currentDog', 'isLoading'])
    };
};

export default connect(mapStateToProps)(form(NotesForm));
