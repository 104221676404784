import { createAction } from 'redux-actions';
import * as Types from './types';
import * as Events from './events';

export const generateAlertMeta = createAction(
    Types.TEST,
    null,
    ({
        type = 'success',
        event,
        responseData,
        alertData,
        state
    }) => {
    const eventFn = Events[event]; // eslint-disable-line
    const id = `${event}-${new Date().getTime().toString()}`;
    return { alert: { id, type, body: eventFn({ responseData, alertData, state }) } };
});

export const enqueueAlert = createAction(Types.ENQUEUE_ALERT);
export const removeAlert = createAction(Types.REMOVE_ALERT);
