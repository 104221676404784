import React from 'react';
import { Field } from 'redux-form/immutable';
import renderInputControl from '../../common/Form/InputControl';
import { fieldDefaultPropTypes } from '../../Form';

const TextInput = ({ fieldConfig }) => {
    return (
        <Field
            {...fieldConfig}
            type={fieldConfig.fieldType || 'input'}
            component={renderInputControl}/>
    );
};

TextInput.propTypes = fieldDefaultPropTypes;

export default TextInput;
