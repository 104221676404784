import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddNewItem from '../../../../common/AddNewItem/';
import PaymentItem from './PaymentItem';
import { getCustomersPaymentCards } from '../../../../../services/paymentCards';
import AjaxLoader from '../../../../common/AjaxLoader';
import { openAddCustomersCreditCardModalDialog } from '../../../../../actions/modal/openActions';

class PaymentMethods extends PureComponent {
    componentDidMount() {
        const { getCustomersPaymentCards, paymentCards } = this.props;
        if (!paymentCards.length) {
            getCustomersPaymentCards();
        }
    }

    renderContent() {
        const { paymentCards, openAddCustomersCreditCardModalDialog } = this.props;
        return (
            <div className='payment-methods__content'>
                <div className='payment-methods__list'>
                    { paymentCards.map((card, index) => <PaymentItem key={index} data={card} />) }
                </div>
                <div className='payment-methods__add'>
                    <AddNewItem
                        className='payment-methods__add-item'
                        text='Add Payment Method'
                        onClickHandler={openAddCustomersCreditCardModalDialog} />
                </div>
            </div>
        );
    }

    render() {
        const { areCardsLoaded } = this.props;
        return (
            <div className='profile__block payment-methods'>
                <div className='profile__headline'>
                    <div className='profile__title'>Payment methods</div>
                </div>
                {areCardsLoaded ? this.renderContent() : <AjaxLoader />}
            </div>
        );
    }
}

PaymentMethods.propTypes = {
    paymentCards: PropTypes.arrayOf(PropTypes.shape({})),
    areCardsLoaded: PropTypes.bool,
    getCustomersPaymentCards: PropTypes.func,
    openAddCustomersCreditCardModalDialog: PropTypes.func
};

const mapStateToProps = state => ({
    paymentCards: state.getIn(['currentCustomer', 'payment_cards', 'cards']).toJS(),
    areCardsLoaded: state.getIn(['currentCustomer', 'payment_cards', 'areLoaded'])
});

const mapDispatchToProps = {
    getCustomersPaymentCards,
    openAddCustomersCreditCardModalDialog
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);
