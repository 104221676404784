import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { closeAddCustomerCardModal } from '../../../../actions/miscActions';
import { addPaymentCard } from '../../../../services/paymentCards';
import ModalDialog from '../../../common/ModalDialog';
import AddCustomerCardModalDialogForm from './AddCustomerCardModalDialogForm';

const AddCustomerCardModalDialog = (props) => {
    const { isAddCustomerCardModalOpened, closeAddCustomerCardModal, handleSubmit,
        data: { target, title } } = props;

    return (
        <ModalDialog
            form={
                <AddCustomerCardModalDialogForm
                    onSubmit={data => {
                        const cardData = data.toJS();
                        cardData.is_hidden = !cardData.save_card;
                        return handleSubmit(cardData, target);
                    }}
                    onClose={closeAddCustomerCardModal} />
            }
            headerClassName='modal-dialog__header_transparent'
            title={`${title || 'Enter New Payment Option'}`}
            isModalOpened={isAddCustomerCardModalOpened}
            handleModalClosed={closeAddCustomerCardModal}
            overflowShown/>
    );
};

AddCustomerCardModalDialog.propTypes = {
    isAddCustomerCardModalOpened: PropTypes.bool,
    closeAddCustomerCardModal: PropTypes.func,
    handleSubmit: PropTypes.func,
    data: PropTypes.shape({}),
};

const mapStateToProps = state => ({
    isAddCustomerCardModalOpened: state.getIn(['misc', 'modals', 'addCustomerCard', 'isOpened']),
    data: state.getIn(['misc', 'modals', 'addCustomerCard', 'data'])
});

const mapDispatchToProps = {
    closeAddCustomerCardModal,
    handleSubmit: addPaymentCard
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomerCardModalDialog);
