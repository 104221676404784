import PropTypes from 'prop-types';
import React from 'react';
import { fromJS } from 'immutable';
import AvatarPlaceholder from '../../../../common/AvatarPlaceholder';
import { timePrettify } from '../../../../../helpers/date';
import { isTrainer } from '../../../../../helpers/userRoles';

const ClassEventCard = ({ cardData, dispatchPush, openCustomReports, handleSettingCurrentDog }) => {
    const timeFormat = 'h:mmA';
    
    const goToCalendar = classEventId => {
        dispatchPush(`/dashboard/calendar/classes/${classEventId}`);
    };
    
    const renderDogs = dogs => {
        if (!dogs.length) {
            return null;
        }
        
        const renderAvatar = dog => {
            if (!dog.photo_processed) {
                return <AvatarPlaceholder type='dog' size={40}/>;
            }
            
            return (
                <div
                    className='class-event-card__attendance-avatar-image'
                    style={{
                        background: `url(${dog.photo_processed}) center center / cover no-repeat`,
                    }}/>
            );
        };
        
        return dogs.map((dog, i) => {
            return (
                <div className='class-event-card__attendance-avatar-container' key={'dog-' + i} >
                    {renderAvatar(dog)}
                    <span 
                        className='class-event-card__attendance-name' 
                        onClick={() => handleSettingCurrentDog(fromJS(dog))}>
                        {dog.name}
                    </span>
                </div>
            );
        });
    };
    
    return (
        <div className='class-event-card'>
            <div className='class-event-card__header'>
                <div className='class-event-card__header-text'>
                    {cardData.class_detail.name}
                </div>
            </div>
            <div className='class-event-card__item'>
                <div className='class-event-card__item-header'>
                    Available In
                </div>
                <div className='class-event-card__item-content'>
                    {Object.values(cardData.class_occurrence_detail.location_array_detail).join(', ')}
                </div>
            </div>
            <div className='class-event-card__item'>
                <div className='class-event-card__item-header'>
                    Instructor
                </div>
                <div className='class-event-card__item-content class-event-card__instructor-name'>
                    {cardData.trainer_detail.full_name}
                </div>
            </div>
            <div className='class-event-card__item'>
                <div className='class-event-card__item-header'>
                    Access Level
                </div>
                <div className='class-event-card__item-content'>
                    {cardData.class_occurrence_detail.access_level_detail}
                </div>
            </div>
            <div className='class-event-card__item'>
                <div className='class-event-card__item-header'>
                    Pick Up
                </div>
                <div className='class-event-card__item-content'>
                    {timePrettify(cardData.class_occurrence_detail.pickup_start_time, timeFormat)}&nbsp;&mdash;&nbsp;
                    {timePrettify(cardData.class_occurrence_detail.pickup_end_time, timeFormat)}
                </div>
            </div>
            <div className='class-event-card__item'>
                <div className='class-event-card__item-header'>
                    Drop Off
                </div>
                <div className='class-event-card__item-content'>
                    {timePrettify(cardData.class_occurrence_detail.dropoff_start_time, timeFormat)}&nbsp;&mdash;&nbsp;
                    {timePrettify(cardData.class_occurrence_detail.dropoff_end_time, timeFormat)}
                </div>
            </div>
            <div className='class-event-card__item class-event-card__attendance'>
                <div className='class-event-card__attendance-text'>
                    {(cardData.coming && cardData.coming + ' Coming, ') + cardData.spots_available + ' Spots Available'}
                </div>
                {renderDogs(cardData.dogs)}
            </div>
            <div className='class-event-card__footer'>
                <div
                    onClick={() => goToCalendar(cardData.class_occurrence)}
                    className='class-event-card__footer-link'>
                    View in Calendar
                </div>
                {!isTrainer()
                ? <div
                    onClick={() => {
                        openCustomReports([
                            {
                                key: 'type',
                                value: 'marketing'
                            },
                            {
                                key: 'report',
                                value: 'dog_attendance_statistics'
                            }
                        ]);
                    }}
                    className='class-event-card__footer-link'>
                    View Most Attended
                </div>
                : null}
            </div>
        </div>
    );
};

ClassEventCard.propTypes = {
    cardData: PropTypes.shape({}),
    dispatchPush: PropTypes.func,
    openCustomReports: PropTypes.func,
    handleSettingCurrentDog: PropTypes.func,
};

export default ClassEventCard;
