import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { getDays } from '../../../../../helpers/date';
import { ReactComponent as IconChevronLongRight } from '../../../../../assets/icons/icon-chevron-long-right.svg';


const PackagesCard = ({ id, name, quantity, price, description, expires_after, photo }) => {
    return (
        <Link to={`/package/${id}/purchase`} className='packages-card'>
            <i className='packages-card__image' style={{ backgroundImage: `url(${photo || ''})` }} />
            <div className='packages-card__container'>
                <div className='packages-card__head'>
                    <p className='packages-card__head-text'>
                        <strong className='packages-card__title'>{name}</strong>
                        <span className='packages-card__subline'>{`(${quantity} class${quantity > 1 ? 'es' : ''})`}</span>
                    </p>
                    <span className='packages-card__price'>${price}</span>
                </div>

                <p className='packages-card__text'>{description}</p>
                <p className='packages-card__notice'><span>Includes pickup and dropoff and is valid across all Neighborhoods.</span> <span>No blackout dates. |</span> <strong>Expires {getDays(expires_after)} after purchase date.</strong></p>

                <strong className='packages-card__button'>
                    Select
                    <IconChevronLongRight className='icon_chevron-long-right packages-card__button__icon'/>
                </strong>
            </div>
        </Link>
    );
};
PackagesCard.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.string,
    description: PropTypes.string,
    expires_after: PropTypes.string,
    photo: PropTypes.string
};

export default PackagesCard;
