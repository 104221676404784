import { fromJS } from 'immutable';
import { createAction } from 'redux-actions';
import * as ModalTypes from '../../types/modalTypes';
import { setGlobalError } from '../errorHandlerActions';
import * as ErrorActions from '../form/errorActions';
import * as CancelActions from './cancelActions';
import * as GetOptionsActions from './getOptionsActions';
import * as SubmitActions from './submitActions';
import * as UpdateActions from './updateActions';
import { generateAlertMeta } from '../../components/Alert/actions';

export const resetModalReducer = createAction(ModalTypes.RESET_MODAL_REDUCER);
export const setModalOpened = createAction(ModalTypes.SET_MODAL_OPENED);
export const setModalData = createAction(ModalTypes.SET_MODAL_DATA);
export const setModalDataOpen = createAction(ModalTypes.SET_MODAL_DATA_OPEN);
export const setModalActiveForm = createAction(ModalTypes.SET_MODAL_ACTIVE_FORM);
export const setModalSubmitRequestStarted = createAction(ModalTypes.SET_MODAL_SUBMIT_REQUEST_STARTED);
export const setModalSubmitRequestFinished = createAction(ModalTypes.SET_MODAL_SUBMIT_REQUEST_FINISHED);
export const setModalCancelRequestStarted = createAction(ModalTypes.SET_MODAL_CANCEL_REQUEST_STARTED);
export const setModalCancelRequestFinished = createAction(ModalTypes.SET_MODAL_CANCEL_REQUEST_FINISHED);

export const setModalSubmitError = createAction(ModalTypes.SET_MODAL_SUBMIT_ERROR);
const defaultNormalizer = data => data;

let scrollOffset = 0;
export const onModalClose = () => {
    return (dispatch, getState) => {
        const isModalSidebarOpened = getState().getIn(['modalSidebar', 'isOpened']);
        if (!isModalSidebarOpened) {
            document.documentElement.style.position = 'relative';
            setTimeout(() => { // because it works
                document.documentElement.style.top = '';
                document.documentElement.scrollTop = scrollOffset;
                document.body.scrollTop = scrollOffset;
            }, 100);
        }
        dispatch(resetModalReducer());
    };
};

const displayAlert = (config) => {
    return generateAlertMeta(config);
};

export const onModalSubmit = ({ formData, submitNormalizer, updateNormalizer }) => {
    return (dispatch, getState) => {
        let formConfig = getState().getIn(['modal', 'data']);

        if (formConfig.forms !== undefined) {
            formConfig = formConfig.forms[formConfig.activeForm];
        }

        const {
            submitAction,
            updateAction,
            errorAction,
            eventId,
            photoUrl,
            submitClickHandler,
            alertEvent,
            alertData
        } = formConfig;

        const submitData = submitNormalizer ? submitNormalizer(formData, getState()) : formData;
 
        if (submitAction) {
            dispatch(setModalSubmitRequestStarted());
            dispatch(SubmitActions[submitAction]({
                formConfig,
                submitData,
            }))
                .then((response) => {
                    if (submitAction === 'removeDogFromScheduleEvent') {
                        window._dcq.push(["track", "Class cancel"]);
                    }

                    if (submitAction === 'cancelClass') {
                        const schedule = getState().getIn(['schedule', 'items']).toJS();
                        const classOccurrence = schedule.find((classOccurrence) => {
                            return classOccurrence.id === eventId;
                        });
                        for (let i = 0; i < classOccurrence.products.length; i++) {
                            window._dcq.push(["track", "Class cancel"]);
                        }
                    }

                    if (alertEvent) {
                        dispatch(displayAlert({
                            event: alertEvent,
                            responseData: response.data,
                            alertData: alertData || response.data,
                            state: getState() }));
                    }

                    if (photoUrl && submitData.photo && typeof submitData.photo !== 'string') {
                        dispatch(SubmitActions.updatePhoto({
                            url: photoUrl.replace(new RegExp('{id}', 'g'), response.data.id),
                            responseData: fromJS(response.data),
                            photo: submitData.photo,
                        })).then((responsePhoto) => {
                            if (updateAction) {
                                dispatch(UpdateActions[updateAction]({
                                    formConfig,
                                    formData,
                                    responseData: fromJS(Object.assign({}, response.data, {
                                        photo: responsePhoto.data.photo,
                                        photo_processed: responsePhoto.data.photo_processed
                                            ? responsePhoto.data.photo_processed
                                            : responsePhoto.data.photo,
                                    })),
                                    normalizer: updateNormalizer || defaultNormalizer
                                }));
                            }

                            dispatch(onModalClose(formConfig));
                        })
                           .catch(err => {
                               dispatch(setModalSubmitRequestFinished());
                               dispatch(setGlobalError(err));
                           });
                    } else {
                        if (response !== undefined) {
                            if (updateAction) {
                                dispatch(UpdateActions[updateAction]({
                                    formConfig,
                                    formData,
                                    responseData: fromJS(response.data),
                                    normalizer: updateNormalizer || defaultNormalizer
                                }));
                            }
                        }

                        dispatch(onModalClose(formConfig));
                    }
                })
                .catch(err => {
                    dispatch(setModalSubmitRequestFinished());
                    if (errorAction) {
                        dispatch(ErrorActions[errorAction]({
                            error: err,
                            formConfig: formConfig.parentFormData ? formConfig.parentFormData.formConfig : formConfig,
                            formData,
                            submitData: formConfig.parentFormData
                                ? formConfig.parentFormData.submitData
                                : submitNormalizer
                                            ? submitNormalizer(formData, getState())
                                            : formData
                        }));
                    } else {
                        dispatch(setGlobalError(err));
                    }

                    console.log(err);
                    console.log(err.response);
                });
        } else {
            if (updateAction) {
                dispatch(UpdateActions[updateAction]({
                    formConfig,
                    formData,
                }));
            }
            dispatch(onModalClose(formConfig));

            if (submitClickHandler) {
                dispatch(SubmitActions[submitClickHandler]({ formConfig, formData }));
            }
        }
    };
};

export const onModalOpen = (formConfig) => {
    return (dispatch) => {
        scrollOffset = window.pageYOffset || document.documentElement.scrollTop || 0;
        document.documentElement.style.top = -scrollOffset + 'px';
        document.documentElement.style.position = 'fixed';
        dispatch(setModalDataOpen(formConfig));
    };
};

export const onModalGetOptions = (field, extra) => {
    return (dispatch) => {
        let getOptionsFunction;

        if (typeof field.options === 'function') {
            getOptionsFunction = field.options;
        } else {
            // Must be string then.
            getOptionsFunction = GetOptionsActions[field.options];
        }

        return dispatch(getOptionsFunction(extra));
    };
};

export const onModalCancel = ({ updateNormalizer }) => {
    return (dispatch, getState) => {
        const formConfig = getState().getIn(['modal', 'data']);
        const { cancelAction, updateAction, cancelClickHandler } = formConfig;

        if (cancelAction) {
            dispatch(setModalCancelRequestStarted());
            dispatch(CancelActions[cancelAction](formConfig))
                .then(() => {
                    if (updateAction) {
                        dispatch(UpdateActions[updateAction]({
                            formConfig,
                            normalizer: updateNormalizer || defaultNormalizer
                        }));
                    }

                    dispatch(onModalClose(formConfig));
                })
                .catch(err => {
                    dispatch(setModalCancelRequestFinished());
                    console.log(err);
                    console.log(err.response);
                });
        } else {
            dispatch(onModalClose(formConfig));

            if (cancelClickHandler) {
                dispatch(CancelActions[cancelClickHandler](formConfig));
            }
        }
    };
};
