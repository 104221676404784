/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts';

const ColoredBar = ({ x, y, width, height, payload: { color } }) => {
    const getPath = (x, y, width, height) => {
        return `M ${x},${y} h ${width} v ${height} h -${width} Z`;
    };

    return <path d={getPath(x, y, width, height)} stroke='none' fill={color}/>;
};

ColoredBar.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    payload: PropTypes.shape({
        color: PropTypes.string,
    }),
};

const ClassReservationsByZoneCustomLegend = ({ data }) => {
    return (
        <div className='class-reservations-by-zone__legend'>{data.map((item) => {
            const { color, zone, value } = item;
            if (!value) {
                return null;
            }
            return (
                <div key={zone} className='legend__item'>
                    <div className='legend__item-marker' style={{ backgroundColor: color }}/>
                    <div className='legend__item-text'>{zone}</div>
                </div>
            );
        })}</div>
    );
};

const ClassReservationsByZone = ({ data, width, height }) => {
    const isMobile = window.innerWidth <= 736;
    return (
        <div className='class-reservations-by-zone__chart'>
            <div className='chart__title'>Class Reservations By Zone</div>
            <BarChart
                className='chart__content'
                width={width - 13}
                height={height - (isMobile ? 160 : 80)}
                data={data}>
                {!isMobile && <XAxis
                    axisLine={false}
                    tickLine={false}
                    dataKey='zoneFormatted'/>}
                <YAxis axisLine={false} tickLine={false} tickCount={6}/>
                <CartesianGrid/>
                <Bar dataKey='value' shape={<ColoredBar/>}/>
            </BarChart>
            {isMobile && <ClassReservationsByZoneCustomLegend data={data}/>}
        </div>
    );
};

ClassReservationsByZone.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

export default ClassReservationsByZone;
