export const createFieldsObject = (fields, key, isFormMultiple, isDotNotation) => {
    const fieldsObject = {
        address1: 'address1',
        city: 'city',
        state: 'state',
        zip_code: 'zip_code',
    };

    if (isFormMultiple) {
        fields.forEach(field => {
            if (field.aligned) {
                field.items.forEach(item => {
                    const { name } = item;
                    if (isDotNotation) {
                        fieldsObject[name] = `${key}.${name}`;
                    } else {
                        fieldsObject[name] = `${key}_${name}`;
                    }
                });
                return;
            }
            const { name } = field;
            if (isDotNotation) {
                fieldsObject[name] = `${key}.${name}`;
            } else {
                fieldsObject[name] = `${key}_${name}`;
            }
        });
    }

    return fieldsObject;
};

export const baseAddressFields = createFieldsObject(null, null, false);
