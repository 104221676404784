import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { logout } from '../../../services/oauth';
import { setMobileSidebarClosed } from '../../../actions/miscActions';
import { links, trainerLinks } from '../Sidebar/navLinks';
import { isTrainer } from '../../../helpers/userRoles';
import AvatarPlaceholder from '../AvatarPlaceholder';

class Sidebar extends PureComponent {
    state = {
        activeLink: 0
    };

    setActiveLink(activeLink) {
        this.setState({ activeLink });
    }

    renderNav() {
        const { setMobileSidebarClosed } = this.props;
        const sidebarLinks = isTrainer() ? trainerLinks : links;
        return (
            <div className='mobile-sidebar__nav'>
                {
                    sidebarLinks.map((link, index) => {
                        const linkClassName = classNames({
                            'mobile-sidebar__link': true,
                            'mobile-sidebar__link_active': index === this.state.activeLink,
                        });
                        return (
                            <Link
                                className={linkClassName}
                                to={link.to}
                                onClick={() => {
                                    this.setActiveLink(index);
                                    setMobileSidebarClosed();
                                }}
                                key={index}>
                                {link.mobileText || link.text}
                            </Link>
                        );
                    })
                }
            </div>
        );
    }

    render() {
        const { logout, userName, avatar, setMobileSidebarClosed } = this.props;
        const profileImageStyle = {
            backgroundImage: `url(${avatar})`
        };
        return (
            <nav className='mobile-sidebar'>
                <div className='mobile-sidebar__inner'>
                    <Link
                        className='mobile-sidebar__profile'
                        to='/dashboard/profile/edit'
                        onClick={setMobileSidebarClosed}>
                        <div className='mobile-sidebar__profile__image' style={profileImageStyle}>
                            {!avatar && <AvatarPlaceholder/>}
                        </div>
                        <div className='mobile-sidebar__profile__name'>Hello, {userName}!</div>
                    </Link>
                    {this.renderNav()}
                    <div className='mobile-sidebar__footer'>
                        <div className='mobile-sidebar__logout' onClick={logout}> Log out </div>
                    </div>
                </div>
            </nav>
        );
    }
}

Sidebar.propTypes = {
    logout: PropTypes.func,
    setMobileSidebarClosed: PropTypes.func,
    userName: PropTypes.string,
    avatar: PropTypes.string,
};

const mapDispatchToProps = {
    logout,
    setMobileSidebarClosed
};

const mapStateToProps = (state) => ({
    userName: state.getIn(['user', 'full_name']),
    avatar: state.getIn(['user', 'photo']) || '',
    isModalSidebarOpened: state.getIn(['misc', 'mobileSidebar', 'isOpened'])
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
