/* eslint-disable react/prop-types */
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import React from 'react';
import format from 'string-template';
import { time12to24 } from '../helpers/date';
import {
    agreementTextValueNormalizer,
    creatablePhoneNumberNormalizer,
    immutableValueNormalizer,
    phoneNumberNormalizer
} from './normalize';

// Validation messages
export const DEFAULT_FIELD_DISPLAY_NAME = 'This field';
export const DEFAULT_TIME_PATTERN = '^(0[1-9]|1[0-2]):[0-5][0-9](a|p)m$';
export const MESSAGE_REQUIRED = '{displayName} is required.';
export const MESSAGE_MIN_VALUE = '{displayName} must be {minValue} characters long or longer.';
export const MESSAGE_MAX_VALUE = '{displayName} must be {maxValue} characters long or shorter.';
export const MESSAGE_ZIPCODE_MAX_VALUE = '{displayName} must be {maxValue} characters or less.';
export const MESSAGE_EXACT_VALUE = '{displayName} must be {exactValue} characters long.';
export const MESSAGE_PATTERN_VALUE = 'Please enter a valid {displayName}.';
export const MESSAGE_PATTERN_ASCII = '{displayName} cannot contain non-ASCII characters.';
export const MESSAGE_PASTE_TEXT_TOO_LONG = 'The text you are trying to paste is too long.';
export const MESSAGE_AGREEMENT_RECIPIENTS_MAX_VALUE = '{displayName} must not have more than {maxValue} tags.';
export const TEXT_MIN_VALUE = 2;
export const TEXT_MAX_VALUE = 255;
export const AGREEMENT_TEXT_MAX_VALUE = 80000;
export const AGREEMENT_RECIPIENTS_MAX_VALUE = 50;
export const ZIPCODE_MAX_VALUE = 10;
export const TEXTAREA_MAX_VALUE = 2000;
export const INTOLERANCE_LIST_LENGTH_MAX = 100;
export const MESSAGE_INTOLERANCE_LIST_LENGTH_MAX = '{displayName} must not have more than {maxValue} items.';
export const MESSAGE_DOG_REPORT_ICONS = 'Please select all 3 tags for report';
export const EMAIL_MIN_VALUE = 5;
export const EMAIL_MAX_VALUE = TEXT_MAX_VALUE;
// eslint-disable-next-line no-useless-escape
export const EMAIL_PATTERN_VALUE = '^[A-Z0-9._%+-]+\\@[A-Z0-9.-]+\\.[A-Z]{2,4}$';
// eslint-disable-next-line no-useless-escape
export const LINK_PATTERN_VALUE = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi; // eslint-disable-line
export const SCHEME_URL_PATTERN = /^(?:http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+(?:[\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(?::[0-9]{1,5})?(?:\/.*)?$/gi; // eslint-disable-line
export const ASCII_PATTERN = /^[\x20-\x7E]*$/;
export const PHONE_MIN_VALUE = 5;
export const PHONE_MAX_VALUE = 15;
// eslint-disable-next-line quotes
export const PHONE_PATTERN_VALUE = '^[0-9()+-/, .]*$';
export const DATE_MIN_VALUE = 6;
export const MESSAGE_DATE_MIN_VALUE = 'Please enter a correct date.';
export const MESSAGE_INVALID_DATE_VALUE = 'Please enter a valid date in {displayName}.';
export const MESSAGE_PAST_DATE_NOT_ALLOWED = 'Past dates not allowed in {displayName}.';
export const MESSAGE_INVALID_TIME = 'Please enter a valid time in the format like 12:30PM.';
export const MESSAGE_BEFORE_TIME = '{endName} cannot be before {startName}';
export const MESSAGE_AFTER_TIME = '{startName} cannot be after {endName}';
export const MESSAGE_INVALID_ZIP_CODE = 'Zip Code is not available for this class.';
export const MESSAGE_INVALID_ADDRESS_ZIP_CODE = 'Location is not available for this class.';
export const NUMBER_MIN_VALUE = 1;
export const MESSAGE_NUMBER_MIN_VALUE = '{displayName} must be {minValue} or bigger.';
export const MESSAGE_VALID_CARD = 'Please enter valid Credit Card Number';
export const MESSAGE_ADDRESS_ZIP_CODE = ({ placement, validZipCodesString }) => {
    return (
        <div className='error'>
            <div className='error__text'>This Location is not available for this class.</div>
            <Tooltip
                trigger={['hover', 'click']}
                overlayClassName='error__tooltip'
                placement={placement}
                overlay={validZipCodesString}
                arrowContent={<div className='rc-tooltip-arrow-inner'/>}>
                <div className='error__helper'>Available Zip Codes for this Class</div>
            </Tooltip>
        </div>
    );
};

// Base validators
const baseRequiredValidator = ({ message, displayName = DEFAULT_FIELD_DISPLAY_NAME, valueNormalizer }) => {
    const errorMessage = format(message, { displayName });

    return value => {
        if (value === undefined) {
            return errorMessage;
        }

        if (value !== null) {
            value = value.toJS ? value.toJS() : value;
        }

        if (valueNormalizer) {
            value = valueNormalizer(value);
        }

        if (!value || !value.length) {
            return errorMessage;
        }

        return undefined;
    };
};

const baseRequiredValidatorSelect = ({ displayName = DEFAULT_FIELD_DISPLAY_NAME, message }) => {
    const errorMessage = format(message, { displayName });

    return value => {
        // This way we can catch both `undefined` and `null`
        if (value == null || value === '') { //eslint-disable-line
            return errorMessage;
        }

        return undefined;
    };
};

const baseRequiredValidatorCheckboxList = ({ displayName = DEFAULT_FIELD_DISPLAY_NAME, message }) => {
    const errorMessage = format(message, { displayName });

    return value => {
        if (value == undefined || Array.isArray(value) && !value.length) { //eslint-disable-line
            return errorMessage;
        }

        return undefined;
    };
};

const baseRequiredValidatorImageSelector = ({ displayName = DEFAULT_FIELD_DISPLAY_NAME, message }) => {
    const errorMessage = format(message, { displayName });

    return value => {
        // This way we can catch both `undefined` and `null`
        if (value == null) { //eslint-disable-line
            return errorMessage;
        }

        return undefined;
    };
};

// eslint-disable-next-line max-len
const baseMinValueLengthValidator = ({ displayName = DEFAULT_FIELD_DISPLAY_NAME, minValue, message, valueNormalizer }) => {
    const errorMessage = format(message, { displayName, minValue });

    return value => {
        if (valueNormalizer) {
            value = valueNormalizer(value);
        }

        if (value && value.length < minValue) {
            return errorMessage;
        }

        return undefined;
    };
};

// eslint-disable-next-line max-len
const baseMaxValueLengthValidator = ({ displayName = DEFAULT_FIELD_DISPLAY_NAME, maxValue, message, valueNormalizer }) => {
    const errorMessage = format(message, { displayName, maxValue });

    return value => {
        if (valueNormalizer) {
            value = valueNormalizer(value);
        }

        if (value && value.length > maxValue) {
            return errorMessage;
        }

        return undefined;
    };
};

const baseExactValueValidator = ({ displayName = DEFAULT_FIELD_DISPLAY_NAME, exactValue, message }) => {
    const errorMessage = format(message, { displayName, exactValue });

    return value => {
        if (value && value.length !== exactValue) {
            return errorMessage;
        }

        return undefined;
    };
};

const basePatternValueValidator = ({ displayName = DEFAULT_FIELD_DISPLAY_NAME, patternValue, message, valueKey }) => {
    const errorMessage = format(message, { displayName, patternValue });
    const regex = new RegExp(patternValue, 'i');

    return value => {
        if (value.size) {
            value = value.toJS();
        }
        if (value && !regex.test(valueKey ? value[valueKey] : value)) {
            return errorMessage;
        }

        return undefined;
    };
};

const baseMinDateValueValidator = ({
                                       displayName = DEFAULT_FIELD_DISPLAY_NAME,
                                       minValue,
                                       message,
                                       valueNormalizer,
                                   }) => {
    const errorMessage = format(message, { displayName, minValue });

    return value => {
        if (valueNormalizer) {
            value = valueNormalizer(value);
        }

        if (value && value.length < minValue + 2) {
            return errorMessage;
        }

        return undefined;
    };
};

const baseDateIntervalRequiredValidator = ({
                                               startFieldName = DEFAULT_FIELD_DISPLAY_NAME,
                                               endFieldName = DEFAULT_FIELD_DISPLAY_NAME,
                                               message,
                                           }) => {
    return value => {
        const fieldValue = value.size ? value.toJS() : value;

        const validateData = {};

        if (fieldValue.start === undefined || fieldValue.start === 'None' || fieldValue.start.length === 0) {
            validateData.start = format(message, { displayName: startFieldName });
        }

        if (fieldValue.end === undefined || fieldValue.end === 'None' || fieldValue.end.length === 0) {
            validateData.end = format(message, { displayName: endFieldName });
        }

        if (Object.keys(validateData).length !== 0) {
            return validateData;
        }

        return undefined;
    };
};

const baseMinDateIntervalValueValidator = ({
                                               startFieldName = DEFAULT_FIELD_DISPLAY_NAME,
                                               endFieldName = DEFAULT_FIELD_DISPLAY_NAME,
                                               minValue,
                                               message,
                                               valueNormalizer,
                                           }) => {
    return value => {
        let fieldValue = value.size ? value.toJS() : value;

        if (valueNormalizer) {
            fieldValue = valueNormalizer(value);
        }

        const validateData = {};

        if (fieldValue.start) {
            if (fieldValue.start !== 'None') {
                if (fieldValue.start.length < minValue + 2) {
                    validateData.start = format(message, { displayName: startFieldName, minValue });
                }
            }
        }

        if (fieldValue.end) {
            if (fieldValue.end !== 'None' && fieldValue.end !== 'No End Date' && fieldValue.end !== 'disabled') {
                if (fieldValue.end.length < minValue + 2) {
                    validateData.end = format(message, { displayName: endFieldName, minValue });
                }
            }
        }

        if (Object.keys(validateData).length !== 0) {
            return validateData;
        }

        return undefined;
    };
};

const baseDateIntervalValueValidator = ({
                                            startFieldName = DEFAULT_FIELD_DISPLAY_NAME,
                                            endFieldName = DEFAULT_FIELD_DISPLAY_NAME,
                                            message,
                                            valueNormalizer,
                                        }) => {
    return value => {
        let fieldValue = value.size ? value.toJS() : value;

        if (valueNormalizer) {
            fieldValue = valueNormalizer(value);
        }

        const validateData = {};

        if (fieldValue.start) {
            if (fieldValue.start !== 'None') {
                if (!moment(fieldValue.start, 'MM/DD/YY').isValid()) {
                    validateData.start = format(message, { displayName: startFieldName });
                }
            }
        }

        if (fieldValue.end) {
            if (fieldValue.end !== 'None' && fieldValue.end !== 'No End Date' && fieldValue.end !== 'disabled') {
                if (!moment(fieldValue.end, 'MM/DD/YY').isValid()) {
                    validateData.end = format(message, { displayName: endFieldName });
                }
            }
        }

        if (Object.keys(validateData).length !== 0) {
            return validateData;
        }

        return undefined;
    };
};

const baseAddressZipValueValidator = ({ validZipCodes, message }) => {
    return value => {
        const fieldValue = value.size ? value.toJS() : value;
        // console.log('fieldValue: ', fieldValue);

        // initial value might be not validated, because there can't be wrong address preselected
        const isReselected = typeof fieldValue === typeof {};

        if (isReselected && fieldValue.value !== 123456) {
            // Fitdog HQ address have id = 1
            if (!validZipCodes.includes(fieldValue.zip_code) && fieldValue.value !== 1) {
                return message;
            }
        }

        return undefined;
    };
};

const baseAddressZipStringValidator = ({ validZipCodes, message }) => {
    return value => {
        if (!validZipCodes.includes(value)) {
            return message;
        }

        return undefined;
    };
};

const baseZipCodeValueValidator = ({
                                       validZipCodes,
                                       message,
                                   }) => {
    const errorMessage = format(message);

    return value => {
        const fieldValue = value.size ? value.toJS() : value;

        if (!validZipCodes.includes(fieldValue)) {
            return errorMessage;
        }

        return undefined;
    };
};

const baseDateValueValidator = ({
                                    displayName = DEFAULT_FIELD_DISPLAY_NAME,
                                    message,
                                    valueNormalizer,
                                    shouldAllowPast
                                }) => {
    const errorMessage = format(message, { displayName });

    return value => {
        if (valueNormalizer) {
            value = valueNormalizer(value);
        }

        if (!moment(value, 'MM/DD/YY').isValid()) {
            return errorMessage;
        }

        return undefined;
    };
};

const basePastDateValidator = ({ displayName = DEFAULT_FIELD_DISPLAY_NAME, message }) => {
    const errorMessage = format(message, { displayName });

    return value => {
        if (moment().isAfter(moment(value, 'MM/DD/YY'), 'day')) {
            return errorMessage;
        }

        return undefined;
    };
};

const baseStartEndTimeValidator = ({
                                       startField,
                                       endField,
                                       startName = DEFAULT_FIELD_DISPLAY_NAME,
                                       endName = DEFAULT_FIELD_DISPLAY_NAME,
                                       message = startField ? MESSAGE_BEFORE_TIME : MESSAGE_AFTER_TIME
                                   }) => {
    const errorMessage = format(message, { startName, endName });

    return (value, allValues) => {
        value = value.toJS ? value.toJS() : value;
        allValues = allValues.toJS ? allValues.toJS() : allValues;

        if ((startField === undefined && allValues[endField] === undefined) ||
            (endField === undefined && allValues[startField] === undefined)) {
            return undefined;
        }

        const startArray = time12to24(startField ? allValues[startField].value : value.value).split(':');
        const endArray = time12to24(startField ? value.value : allValues[endField].value).split(':');

        const startDate = moment().toDate();
        const endDate = moment().toDate();
        startDate.setHours(startArray[0], startArray[1], 0, 0);
        endDate.setHours(endArray[0], endArray[1], 0, 0);

        if (startField && moment(endDate.toISOString()).isBefore(startDate.toISOString())) {
            return errorMessage;
        }

        if (endField && moment(startDate.toISOString()).isAfter(endDate.toISOString())) {
            return errorMessage;
        }

        return undefined;
    };
};

const baseMinValueValidator = ({ displayName = DEFAULT_FIELD_DISPLAY_NAME, minValue, message, valueNormalizer }) => {
    const errorMessage = format(message, { displayName, minValue });

    return value => {
        if (valueNormalizer) {
            value = valueNormalizer(value);
        }

        if (value && value < minValue) {
            return errorMessage;
        }

        return undefined;
    };
};

// Specific validators
export const requiredValidator = (displayName) => {
    return baseRequiredValidator({ displayName, message: MESSAGE_REQUIRED });
};

export const requiredValidatorSelect = (displayName) => {
    return baseRequiredValidatorSelect({ displayName, message: MESSAGE_REQUIRED });
};

export const requiredValidatorCheckbox = (displayName) => {
    return baseRequiredValidatorCheckboxList({ displayName, message: MESSAGE_REQUIRED });
};

export const requiredValidatorImageSelector = (displayName) => {
    return baseRequiredValidatorImageSelector({ displayName, message: MESSAGE_REQUIRED });
};

export const needsCropValidatorImageSelector = () => {
    return value => {
        const errorMessage = 'Position and crop.';

        if (!value) {
            return undefined;
        }

        value = value.toJS ? value.toJS() : value;

        if ('needsCrop' in value && value.needsCrop) {
            return errorMessage;
        }

        return undefined;
    };
};

export const minTextValueValidator = (displayName, extra = {}) => {
    const { minValue = TEXT_MIN_VALUE, message = MESSAGE_MIN_VALUE } = extra;
    return baseMinValueLengthValidator({ displayName, minValue, message });
};

export const maxTextValueValidator = (displayName, extra = {}) => {
    const { maxValue = TEXT_MAX_VALUE, message = MESSAGE_MAX_VALUE } = extra;
    return baseMaxValueLengthValidator({ displayName, maxValue, message });
};

export const maxZipcodeLengthValidator = (displayName) => {
    return baseMaxValueLengthValidator(
        { displayName, maxValue: ZIPCODE_MAX_VALUE, message: MESSAGE_ZIPCODE_MAX_VALUE });
};

export const maxTextareaValueValidator = (displayName) => {
    return baseMaxValueLengthValidator({ displayName, maxValue: TEXTAREA_MAX_VALUE, message: MESSAGE_MAX_VALUE });
};

export const intoleranceListValidator = (displayName) => {
    const errorMessage = format(MESSAGE_INTOLERANCE_LIST_LENGTH_MAX,
        { displayName: displayName || DEFAULT_FIELD_DISPLAY_NAME, maxValue: INTOLERANCE_LIST_LENGTH_MAX });
    const maxValueValidator = maxTextValueValidator('Each item of the list');
    const minValueValidator = minTextValueValidator('Each item of the list');

    return values => {
        values = values || [];

        if (values.length && values.length > INTOLERANCE_LIST_LENGTH_MAX) {
            return errorMessage;
        }

        for (const value of values) { //eslint-disable-line
            if (value.value) {
                const result = minValueValidator(value.value) || maxValueValidator(value.value);
                if (result !== undefined) {
                    return result;
                }
            }
        }

        return undefined;
    };
};

export const exactTextValueValidator = (displayName, exactValue) => {
    return baseExactValueValidator({ displayName, exactValue, message: MESSAGE_EXACT_VALUE });
};

export const minEmailValueValidator = (displayName = 'Email') => {
    return baseMinValueLengthValidator({ displayName, minValue: EMAIL_MIN_VALUE, message: MESSAGE_MIN_VALUE });
};

export const maxEmailValueValidator = (displayName = 'Email') => {
    return baseMaxValueLengthValidator({ displayName, maxValue: EMAIL_MAX_VALUE, message: MESSAGE_MAX_VALUE });
};

export const patternEmailValueValidator = (displayName = 'e-mail address') => {
    return (
        basePatternValueValidator({ displayName, patternValue: EMAIL_PATTERN_VALUE, message: MESSAGE_PATTERN_VALUE })
    );
};

export const phoneRequiredValidator = (displayName = 'Phone Number') => {
    return baseRequiredValidator({ displayName, message: MESSAGE_REQUIRED, valueNormalizer: phoneNumberNormalizer });
};

export const requiredCreatablePhoneSelect = (displayName = 'Phone Number') => {
    return baseRequiredValidator(
        { displayName, message: MESSAGE_REQUIRED, valueNormalizer: creatablePhoneNumberNormalizer }); // eslint-disable-line
};

export const patternCreatablePhoneValidator = (displayName = 'Phone Number') => {
    return (
        basePatternValueValidator(
            { displayName, patternValue: PHONE_PATTERN_VALUE, message: MESSAGE_PATTERN_VALUE, valueKey: 'value' }) // eslint-disable-line
    );
};

export const minPhoneValueValidator = (displayName = 'Phone Number') => {
    return baseMinValueLengthValidator({
        displayName,
        valueNormalizer: phoneNumberNormalizer,
        minValue: PHONE_MIN_VALUE,
        message: MESSAGE_MIN_VALUE
    });
};

export const maxPhoneValueValidator = (displayName = 'Phone Number') => {
    return baseMaxValueLengthValidator({
        displayName,
        valueNormalizer: phoneNumberNormalizer,
        maxValue: PHONE_MAX_VALUE,
        message: MESSAGE_MAX_VALUE
    });
};

export const patternPhoneValueValidator = (displayName = 'phone number') => {
    return (
        basePatternValueValidator({ displayName, patternValue: PHONE_PATTERN_VALUE, message: MESSAGE_PATTERN_VALUE })
    );
};

export const dogReportIconsValidator = () => {
    return value => {
        if (value) {
            const res = Object.keys(value).indexOf('size') >= 0 ? value.toJS() : value;
            return res && res.length === 3 ? undefined : MESSAGE_DOG_REPORT_ICONS;
        }
        return MESSAGE_DOG_REPORT_ICONS;
    };
};

export const minDateValueValidator = (displayName) => {
    return baseMinDateValueValidator({ displayName, minValue: DATE_MIN_VALUE, message: MESSAGE_DATE_MIN_VALUE });
};

export const dateValueValidator = (displayName) => {
    return baseDateValueValidator({ displayName, message: MESSAGE_INVALID_DATE_VALUE });
};

export const pastDateValidator = (displayName) => {
    return basePastDateValidator({ displayName, message: MESSAGE_PAST_DATE_NOT_ALLOWED });
};

export const minDateIntervalValueValidator = (startFieldName, endFieldName) => {
    return baseMinDateIntervalValueValidator({
        startFieldName,
        endFieldName,
        minValue: DATE_MIN_VALUE,
        message: MESSAGE_DATE_MIN_VALUE,
    });
};

export const requiredDateInteralValidator = (startFieldName, endFieldName) => {
    return baseDateIntervalRequiredValidator({
        startFieldName,
        endFieldName,
        message: MESSAGE_REQUIRED
    });
};

export const dateIntervalValueValidator = (startFieldName, endFieldName) => {
    return baseDateIntervalValueValidator({ startFieldName, endFieldName, message: MESSAGE_INVALID_DATE_VALUE });
};

export const timeValidator = (displayName) => {
    return basePatternValueValidator({
        displayName,
        message: MESSAGE_INVALID_TIME,
        patternValue: DEFAULT_TIME_PATTERN,
        valueKey: 'value'
    });
};

export const startEndTimeValidator = ({ startField, endField, startName, endName }) => {
    return baseStartEndTimeValidator({ startField, endField, startName, endName });
};

const zipCodesArrayToString = (zipCodesArray) => {
    return zipCodesArray.reduce((initialString, code, i) => {
        if (zipCodesArray.length - 1 === i) {
            return initialString + code;
        }
        return initialString + `${code}, `;
    }, '');
};

export const addressZipValueValidator = (validZipCodes) => {
    const validZipCodesString = zipCodesArrayToString(validZipCodes);

    return baseAddressZipValueValidator({
        validZipCodes,
        message: MESSAGE_ADDRESS_ZIP_CODE({ placement: 'bottomRight', validZipCodesString }),
    });
};

export const addressZipStringValidator = (validZipCodes) => {
    const validZipCodesString = zipCodesArrayToString(validZipCodes);

    return baseAddressZipStringValidator({
        validZipCodes,
        message: MESSAGE_ADDRESS_ZIP_CODE({ placement: 'bottom', validZipCodesString }),
    });
};

export const zipCodeValueValidator = validZipCodes => {
    return baseZipCodeValueValidator({
        validZipCodes: validZipCodes.size ? validZipCodes.toJS() : validZipCodes,
        message: MESSAGE_INVALID_ZIP_CODE,
    });
};

export const minNumberValueValidator = (displayName) => {
    return baseMinValueValidator({ displayName, minValue: NUMBER_MIN_VALUE, message: MESSAGE_NUMBER_MIN_VALUE });
};

export const linkValueValidator = (displayName = 'url') => {
    return (
        basePatternValueValidator({ displayName, patternValue: LINK_PATTERN_VALUE, message: MESSAGE_PATTERN_VALUE })
    );
};

export const schemeURLValidator = (displayName = 'link') => {
    return basePatternValueValidator({ displayName, patternValue: SCHEME_URL_PATTERN, message: MESSAGE_PATTERN_VALUE });
};

export const asciiValidator = (displayName = 'Name') => {
    return basePatternValueValidator({ displayName, patternValue: ASCII_PATTERN, message: MESSAGE_PATTERN_ASCII });
};

export const agreementTextMaxValueValidator = displayName => {
    return baseMaxValueLengthValidator({
        displayName,
        valueNormalizer: agreementTextValueNormalizer,
        maxValue: AGREEMENT_TEXT_MAX_VALUE,
        message: MESSAGE_MAX_VALUE
    });
};

export const agreementTextRequiredValidator = displayName => {
    return baseRequiredValidator({
        displayName,
        valueNormalizer: agreementTextValueNormalizer,
        message: MESSAGE_REQUIRED,
    });
};

export const agreementRecipientsMaxValueValidator = displayName => {
    return baseMaxValueLengthValidator({
        displayName,
        valueNormalizer: immutableValueNormalizer,
        maxValue: AGREEMENT_RECIPIENTS_MAX_VALUE,
        message: MESSAGE_AGREEMENT_RECIPIENTS_MAX_VALUE
    });
};

// Factory function for validation
export const validateFactory = (fields) => {
    return values => {
        const errors = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const field of fields) {
            // eslint-disable-next-line no-restricted-syntax
            for (const getValidator of field.validators) {
                const validator = getValidator(field.displayName);
                const result = validator(values.get(field.name));

                if (result !== undefined) {
                    errors[field.name] = result;
                }
            }
        }
        return errors;
    };
};

export const isValidCreditCard = (number) => {
    const numberWithoutSpaces = number.replace(/\s/g, '').length;
    const startsWith34or37 = number.startsWith('34') || number.startsWith('37');
    const startsWith3 = number.startsWith('3');
    let valid = false;
    if (numberWithoutSpaces === 16) valid = true;
    if (numberWithoutSpaces === 15 && startsWith34or37) valid = true;
    if (numberWithoutSpaces === 14 && startsWith3) valid = true;
    return valid;
};

export const creditCardValidator = () => {
    return value => {
        return isValidCreditCard(value) ? undefined : MESSAGE_VALID_CARD;
    };
};
