import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import { dataStates } from '../helpers/state';
import * as OverviewTypes from '../types/dashboard/overviewTypes';

const INITIAL_STATE = fromJS({
    newObjects: {
        dataState: dataStates.notAsked,
        items: undefined,
    },
    classReservations: {
        dataState: dataStates.notAsked,
        items: [],
    },
    reportCards: {
        dataState: dataStates.notAsked,
        items: undefined,
    },
    classReservationsByZone: {
        dataState: dataStates.notAsked,
        items: [],
    },
    bookingsByTrainer: {
        dataState: dataStates.notAsked,
        items: [],
    },
    totalRevenue: {
        dataState: dataStates.notAsked,
        items: [],
    },
});

const overviewReducer = handleActions({
    [OverviewTypes.RESET_OVERVIEW]: () => {
        return INITIAL_STATE;
    },

    [OverviewTypes.SET_OVERVIEW_NEW_OBJECTS_DATA]: (state, action) => {
        return state.setIn(['newObjects', 'items'], fromJS(action.payload));
    },
    [OverviewTypes.SET_OVERVIEW_NEW_OBJECTS_LOADING]: (state) => {
        return state.setIn(['newObjects', 'dataState'], dataStates.loading);
    },
    [OverviewTypes.SET_OVERVIEW_NEW_OBJECTS_LOADED]: (state) => {
        return state.setIn(['newObjects', 'dataState'], dataStates.loaded);
    },
    [OverviewTypes.SET_OVERVIEW_NEW_OBJECTS_FAILED]: (state) => {
        return state.setIn(['newObjects', 'dataState'], dataStates.failed);
    },

    [OverviewTypes.SET_OVERVIEW_CLASS_RESERVATIONS_DATA]: (state, action) => {
        return state.setIn(['classReservations', 'items'], fromJS(action.payload));
    },
    [OverviewTypes.SET_OVERVIEW_CLASS_RESERVATIONS_LOADING]: (state) => {
        return state.setIn(['classReservations', 'dataState'], dataStates.loading);
    },
    [OverviewTypes.SET_OVERVIEW_CLASS_RESERVATIONS_LOADED]: (state) => {
        return state.setIn(['classReservations', 'dataState'], dataStates.loaded);
    },
    [OverviewTypes.SET_OVERVIEW_CLASS_RESERVATIONS_FAILED]: (state) => {
        return state.setIn(['classReservations', 'dataState'], dataStates.failed);
    },

    [OverviewTypes.SET_OVERVIEW_REPORT_CARDS_DATA]: (state, action) => {
        return state.setIn(['reportCards', 'items'], fromJS(action.payload));
    },
    [OverviewTypes.SET_OVERVIEW_REPORT_CARDS_LOADING]: (state) => {
        return state.setIn(['reportCards', 'dataState'], dataStates.loading);
    },
    [OverviewTypes.SET_OVERVIEW_REPORT_CARDS_LOADED]: (state) => {
        return state.setIn(['reportCards', 'dataState'], dataStates.loaded);
    },
    [OverviewTypes.SET_OVERVIEW_REPORT_CARDS_FAILED]: (state) => {
        return state.setIn(['reportCards', 'dataState'], dataStates.failed);
    },

    [OverviewTypes.SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_DATA]: (state, action) => {
        return state.setIn(['classReservationsByZone', 'items'], fromJS(action.payload));
    },
    [OverviewTypes.SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_LOADING]: (state) => {
        return state.setIn(['classReservationsByZone', 'dataState'], dataStates.loading);
    },
    [OverviewTypes.SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_LOADED]: (state) => {
        return state.setIn(['classReservationsByZone', 'dataState'], dataStates.loaded);
    },
    [OverviewTypes.SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_FAILED]: (state) => {
        return state.setIn(['classReservationsByZone', 'dataState'], dataStates.failed);
    },
    [OverviewTypes.SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_COLOR_AND_NAME]: (state, action) => {
        const { zone, color } = action.payload;
        const updateIndex = state.getIn(['classReservationsByZone', 'items'])
                                 .findKey(item => item.get('zone') === zone);

        const zoneNameArray = zone.split(' ');
        let zoneNameFormatted;
        if (zoneNameArray.length) {
            zoneNameFormatted = zone.split(' ')
                                    .reduce((string, item) => `${string.substring(0, 3)}. ${item.substring(0, 3)}.`);
        } else {
            zoneNameFormatted = zone;
        }

        return state.setIn(['classReservationsByZone', 'items', updateIndex, 'color'], color)
                    .setIn(['classReservationsByZone', 'items', updateIndex, 'zoneFormatted'], zoneNameFormatted);
    },

    [OverviewTypes.SET_OVERVIEW_BOOKINGS_BY_TRAINER_DATA]: (state, action) => {
        return state.setIn(['bookingsByTrainer', 'items'], fromJS(action.payload));
    },
    [OverviewTypes.SET_OVERVIEW_BOOKINGS_BY_TRAINER_LOADING]: (state) => {
        return state.setIn(['bookingsByTrainer', 'dataState'], dataStates.loading);
    },
    [OverviewTypes.SET_OVERVIEW_BOOKINGS_BY_TRAINER_LOADED]: (state) => {
        return state.setIn(['bookingsByTrainer', 'dataState'], dataStates.loaded);
    },
    [OverviewTypes.SET_OVERVIEW_BOOKINGS_BY_TRAINER_FAILED]: (state) => {
        return state.setIn(['bookingsByTrainer', 'dataState'], dataStates.failed);
    },
    [OverviewTypes.SET_OVERVIEW_BOOKINGS_BY_TRAINER_COLOR]: (state, action) => {
        const { trainer, color } = action.payload;
        const updateIndex = state.getIn(['bookingsByTrainer', 'items'])
                                 .findKey(item => item.get('trainer') === trainer);
        return state.setIn(['bookingsByTrainer', 'items', updateIndex, 'color'], color);
    },

    [OverviewTypes.SET_OVERVIEW_TOTAL_REVENUE_DATA]: (state, action) => {
        return state.setIn(['totalRevenue', 'items'], fromJS(action.payload));
    },
    [OverviewTypes.SET_OVERVIEW_TOTAL_REVENUE_LOADING]: (state) => {
        return state.setIn(['totalRevenue', 'dataState'], dataStates.loading);
    },
    [OverviewTypes.SET_OVERVIEW_TOTAL_REVENUE_LOADED]: (state) => {
        return state.setIn(['totalRevenue', 'dataState'], dataStates.loaded);
    },
    [OverviewTypes.SET_OVERVIEW_TOTAL_REVENUE_FAILED]: (state) => {
        return state.setIn(['totalRevenue', 'dataState'], dataStates.failed);
    },
}, INITIAL_STATE);

export default overviewReducer;
