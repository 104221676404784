/* eslint-disable consistent-return */
import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { ReactComponent as IconChevronDown } from '../../../../../../assets/icons/icon-chevron-right.svg';
import { dataStates } from '../../../../../../helpers/state';
import { capitalizeEachWord } from '../../../../../../helpers/text';
import { createDataColumns, normalizeData } from './helpers';

const CustomerStatsAndDemographics = ({ items, dataState }) => {
    const renderTable = () => {
        if (dataState === dataStates.loaded && items) {
            const dataNormalized = [
                { 'top_10_breeds': [] },
                { 'top_10_zip_codes': [] },
            ];
            Object.keys(items).forEach(item => {
                const itemObject = { [item]: items[item] };
                if (item.includes('top_10_breeds')) {
                    return dataNormalized.forEach((data, i) => {
                        if (data.top_10_breeds) {
                            return dataNormalized[i].top_10_breeds.push(itemObject);
                        }
                    });
                }
                if (item.includes('top_10_zip_codes')) {
                    return dataNormalized.forEach((data, i) => {
                        if (data.top_10_zip_codes) {
                            return dataNormalized[i].top_10_zip_codes.push(itemObject);
                        }
                    });
                }
                return dataNormalized.push(itemObject);
            });
            const columns = [
                {
                    id: 'reportData',
                    Header: 'Report Data',
                    headerClassName: 'custom-reports__th',
                    className: 'custom-reports__td',
                    accessor: d => capitalizeEachWord(Object.keys(d)[0].replace(/_/g, ' ')),
                },
                {
                    expander: true,
                    width: 65,
                    Expander: ({ isExpanded, ...rest }) => { // eslint-disable-line
                        return (
                            <span
                                className={`custom-reports__expand-btn ${isExpanded
                                    ? 'custom-reports__expand-btn_expanded'
                                    : ''}`}>
                                <IconChevronDown className='custom-reports__expand-btn__icon'/>
                            </span>);
                    },
                    style: {
                        cursor: 'pointer',
                        fontSize: 25,
                        padding: 0,
                        textAlign: 'center',
                        userSelect: 'none',
                    },
                },
            ];
            return (
                <ReactTable
                    showPagination={false}
                    defaultPageSize={dataNormalized.length}
                    data={dataNormalized}
                    columns={columns}
                    SubComponent={({ original: row }) => {
                        const dataName = Object.keys(row)[0];
                        const data = row[dataName];
                        const normalizedData = normalizeData({ dataName, data });

                        if (dataName === 'top_10_breeds' || dataName === 'top_10_zip_codes') {
                            return (
                                <div className='custom-reports__inner-table'>
                                    <ReactTable
                                        showPagination={false}
                                        defaultPageSize={data.length}
                                        data={data}
                                        columns={columns}
                                        SubComponent={({ original: row }) => {
                                            const dataName = Object.keys(row)[0];
                                            const data = row[dataName];
                                            const normalizedData = normalizeData({ dataName, data });

                                            const subComponentColumns = createDataColumns({ dataName, data });

                                            return (
                                                <div className='custom-reports__inner-table'>
                                                    <ReactTable
                                                        showPagination={false}
                                                        defaultPageSize={normalizedData.length}
                                                        data={normalizedData}
                                                        columns={subComponentColumns}/>
                                                </div>
                                            );
                                        }}/>
                                </div>
                            );
                        }

                        const subComponentColumns = createDataColumns({ dataName, data });

                        return (
                            <div className='custom-reports__inner-table'>
                                <ReactTable
                                    showPagination={false}
                                    defaultPageSize={normalizedData.length}
                                    data={normalizedData}
                                    columns={subComponentColumns}/>
                            </div>
                        );
                    }}/>);
        }
        return null;
    };

    return (
        <div className='custom-reports__report'>
            {items !== undefined && renderTable()}
        </div>
    );
};

CustomerStatsAndDemographics.propTypes = {
    items: PropTypes.shape({}).isRequired,
    dataState: PropTypes.string.isRequired,
};

export default CustomerStatsAndDemographics;
