/* eslint-disable consistent-return */
import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { ReactComponent as IconChevronDown } from '../../../../../../assets/icons/icon-chevron-right.svg';
import { sortArrayByPattern } from '../../../../../../helpers/data';
import { dataStates } from '../../../../../../helpers/state';
import { capitalizeEachWord, capitalizeEachWordWithParser, discountTextTransformer } from '../../../../../../helpers/text';

const columns = [
    {
        id: 'className',
        Header: 'Type',
        headerClassName: 'custom-reports__th',
        className: 'custom-reports__td',
        accessor: d => d.type,
    },
    {
        expander: true,
        width: 65,
        Expander: ({ isExpanded, ...rest }) => // eslint-disable-line
        (<span className={`custom-reports__expand-btn ${isExpanded ? 'custom-reports__expand-btn_expanded' : ''}`}>
            <IconChevronDown className='custom-reports__expand-btn__icon' />
        </span>),
        style: {
            cursor: 'pointer',
            fontSize: 25,
            padding: 0,
            textAlign: 'center',
            userSelect: 'none',
        },
    },
];

const subComponentColumns = [
    {
        id: 'type',
        Header: 'Type',
        headerClassName: 'custom-reports__inner-table__th',
        className: 'custom-reports__inner-table__td',
        accessor: d => capitalizeEachWordWithParser(Object.keys(d)[0].substr(8).replace(/_/g, ' ')),
    },
    {
        expander: true,
        width: 65,
        Expander: ({ isExpanded, ...rest }) => // eslint-disable-line
        (<span
            className={`custom-reports__expand-btn custom-reports__expand-btn_sub ${isExpanded
                ? 'custom-reports__expand-btn_expanded'
                : ''}`}>
            <IconChevronDown className='custom-reports__expand-btn__icon' />
        </span>),
        style: {
            cursor: 'pointer',
            fontSize: 25,
            padding: 0,
            textAlign: 'center',
            userSelect: 'none',
        },
    },
];

const subSubComponentColumns = [
    {
        id: 'type',
        Header: 'Type',
        headerClassName: 'custom-reports__th',
        className: 'custom-reports__td',
        accessor: d => d.isTotal ? 'Total:' : capitalizeEachWord(d.key.replace(/_/g, ' ')),
    },
    {
        id: 'sales',
        Header: 'Sales',
        headerClassName: 'custom-reports__th',
        className: 'custom-reports__td',
        accessor: d => d.sales,
        Cell: row => {
            if (row.value === undefined) return null;
            return <div>{`$${row.value}`}</div>;
        },
    },
    {
        id: 'count',
        Header: 'Count',
        headerClassName: 'custom-reports__th',
        className: 'custom-reports__td',
        accessor: d => d.count,
    },
];

const capturedSalesColumns = [
    {
        id: 'sales-captured',
        Header: 'Sales Captured',
        headerClassName: 'custom-reports__th',
        className: 'custom-reports__td',
        accessor: d => `$${d.sales_captured}`,
    }
]

class DetailedSales extends React.PureComponent {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        dataState: PropTypes.string.isRequired,
    };

    state = {
        expanded: {
            0: {},
        },
        subExpandedStatic: {
            0: {},
            1: {},
            2: {},
            3: {}
        },
        subExpanded: {},
    };

    renderTable = () => {
        const { items, dataState } = this.props;
        const { expanded, subExpandedStatic, subExpanded } = this.state;
        if (dataState === dataStates.loaded && items) {
            const summaryData = [];
            const detailsData = [];

            // consolidate duplicate classes into one entry per class type
            const classData = {}
            items.details_by_class.details.forEach((classItem) => {
                const className = classItem.class_detail.name;

                if (!classData[className]) {
                    classData[className] = {
                        class_detail: { name: className },
                        count: classItem.count,
                        percent: classItem.percent,
                        sales: classItem.sales,
                    };
                } else {
                    classData[className].count += classItem.count;
                    classData[className].percent += classItem.percent;
                    classData[className].sales += classItem.sales;
                }
            });
            items.details_by_class.details = Object.values(classData);

            Object.keys(items).forEach((item) => {
                if (item.indexOf('summary_by_value') !== -1) {
                    return;
                }
                if (item.indexOf('details_by_discounts_packages') !== -1) {
                    return;
                }
                if (item.indexOf('summary') !== -1) {
                    return summaryData.push({ [item]: items[item] });
                }
                return detailsData.push({ [item]: items[item] });
            });

            const sortArray = ['summary_by_source', 'summary_by_products', 'summary_by_discounts', 'summary_by_sales_captured'];
            const sortedSummaryData = sortArrayByPattern(summaryData, sortArray);
            const dataNormalized = [{ type: 'Summary', data: sortedSummaryData }, { type: 'Details', data: detailsData }];

            return (
                <ReactTable
                    className='detailed-sales'
                    onExpandedChange={expanded => {
                        const staticExpanded = Object.assign(expanded, { '0': {} });
                        this.setState({ expanded: staticExpanded });
                    }}
                    expanded={expanded}
                    showPagination={false}
                    defaultPageSize={2}
                    data={dataNormalized}
                    columns={columns}
                    SubComponent={row => {
                        return (
                            <div className='custom-reports__inner-table'>
                                <ReactTable
                                    expanded={row.original.type === 'Summary' ? subExpandedStatic : subExpanded}
                                    onExpandedChange={subExpanded => this.setState({ subExpanded })}
                                    showPagination={false}
                                    defaultPageSize={row.original.data.length}
                                    data={row.original.data}
                                    columns={subComponentColumns}
                                    SubComponent={row => {
                                        const rowType = Object.keys(row.original)[0];
                                        const getNameHeader = () => {
                                            if (rowType === 'details_by_class') return 'Class Name';
                                            if (rowType === 'summary_by_sales_captured') return 'Sales Captured';
                                            if (rowType === 'details_by_package' ||
                                                rowType === 'details_by_package_credits') {
                                                return 'Package Name';
                                            }
                                            if (rowType === 'details_by_discounts_packages' ||
                                                rowType === 'details_by_discounts_trainingprogram') {
                                                return 'Name of Discount';
                                            }
                                            if (rowType === 'details_by_program') return 'Program Name';
                                            return 'Name';
                                        };
                                        const getCountHeader = () => {
                                            if (rowType === 'details_by_class') return '# of Dogs';
                                            if (rowType === 'details_by_package') return '# of Packages';
                                            if (rowType === 'details_by_program') return '# of Programs';
                                            if (rowType === 'details_by_discounts_packages' ||
                                                rowType === 'details_by_discounts_trainingprogram' ||
                                                rowType === 'summary_by_discounts') {
                                                return '# of Discounts Applied';
                                            }
                                            if (rowType === 'details_by_package_credits') return 'Credit Used ($)';
                                            return 'Count';
                                        };
                                        const getSalesHeader = () => {
                                            if (rowType === 'details_by_discounts_packages' ||
                                                rowType === 'details_by_discounts_trainingprogram' ||
                                                rowType === 'summary_by_discounts') {
                                                return 'Discounts ($)';
                                            }
                                            return 'Sales ($)';
                                        };
                                        if (rowType === 'summary_by_sales_captured') {
                                            const data = [row.original[rowType]];
                                            return (
                                                <div className='custom-reports__inner-table'>
                                                    <ReactTable
                                                        showPagination={false}
                                                        defaultPageSize={row.original[rowType].length}
                                                        data={data}
                                                        columns={capturedSalesColumns}
                                                        minRows={0} />
                                                </div>
                                            );
                                        }
                                        if (row.original[rowType].details) {
                                            const subSubDetailsComponentColumns = [
                                                {
                                                    id: 'name',
                                                    Header: getNameHeader(),
                                                    headerClassName: 'custom-reports__th',
                                                    className: 'custom-reports__td',
                                                    accessor: d => {
                                                        let type;
                                                        if (d.key) {
                                                            type = d.key;
                                                        }
                                                        if (d.class_detail) {
                                                            type = d.class_detail.name;
                                                        }
                                                        if (d.package_detail) {
                                                            type = d.package_detail.name;
                                                        }
                                                        if (d.program_detail) {
                                                            type = d.program_detail.name;
                                                        }
                                                        if (isNaN(Number(type))) {
                                                            return capitalizeEachWord(type.replace(/_/g, ' '));
                                                        } else {
                                                            return discountTextTransformer(Number(type));
                                                        }
                                                    },
                                                    Footer: (<div>Total:</div>),
                                                },
                                                {
                                                    id: 'sales',
                                                    Header: getSalesHeader(),
                                                    headerClassName: 'custom-reports__th',
                                                    className: 'custom-reports__td',
                                                    accessor: d => d.sales,
                                                    Cell: row => {
                                                        if (row.value === undefined) return null;
                                                        return <div>{`$${row.value}`}</div>;
                                                    },
                                                    Footer: `$${items[rowType].total.sales}`,
                                                },
                                                {
                                                    id: 'percentage',
                                                    Header: 'Percentage (%)',
                                                    headerClassName: 'custom-reports__th',
                                                    className: 'custom-reports__td',
                                                    accessor: d => d.percent,
                                                    Cell: row => {
                                                        if (row.value === undefined) return '100%';
                                                        if (row.value === null) return '0%';
                                                        return <div>{`${row.value.toFixed(2)}%`}</div>;
                                                    },
                                                    Footer: (<div>100%</div>),
                                                },
                                            ];

                                            subSubDetailsComponentColumns.splice(1, 0, {
                                                id: `${rowType}_count`,
                                                Header: getCountHeader(),
                                                headerClassName: 'custom-reports__th',
                                                className: 'custom-reports__td',
                                                accessor: d => d.count,
                                                Footer: `${items[rowType].total.count}`,
                                            });

                                            return (
                                                <div className='custom-reports__inner-table'>
                                                    <ReactTable
                                                        className='detailed-sales__sub-inner-table'
                                                        showPagination={false}
                                                        // +1 because there is total there
                                                        defaultPageSize={row.original[rowType].details.length + 1}
                                                        data={row.original[rowType].details}
                                                        columns={subSubDetailsComponentColumns} />
                                                </div>
                                            );
                                        }
                                        return (
                                            <div className='custom-reports__inner-table'>
                                                <ReactTable
                                                    showPagination={false}
                                                    defaultPageSize={row.original[rowType].length}
                                                    data={row.original[rowType]}
                                                    columns={subSubComponentColumns} />
                                            </div>
                                        );
                                    }} />
                            </div>
                        );
                    }} />);
        }
        return null;
    };

    render() {
        return (
            <div className='custom-reports__report'>
                {this.props.items !== undefined && this.renderTable()}
            </div>
        );
    }
}

export default DetailedSales;
