import * as axios from 'axios';

export const postImmunizationExpirationDate = ({ url, dataChunks }) => {
    const dataChunksJS = dataChunks.toJS();
    const request = new FormData();
    Object.keys(dataChunksJS).forEach((chunk) => {
        request.append(chunk, dataChunksJS[chunk]);
    });
    return new Promise((promiseResponse, promiseReject) => {
        axios.post(url, request)
            .then(response => {
                console.log('Server response: ', response);
                promiseResponse(response);
            })
            .catch(err => {
                console.log(err);
                console.log(err.response);
                if (err.response) {
                    if (err.response.status !== 200) {
                        console.log('Unexpected error code from the API server: ', err.response.status);
                    }
                }
                promiseReject(err);
            });
    });
};

export const updateImmunizationExpirationDate = ({ url, dataChunks }) => {
    const dataChunksJS = dataChunks.toJS();
    const request = new FormData();
    Object.keys(dataChunksJS).forEach((chunk) => {
        request.append(chunk, dataChunksJS[chunk]);
    });
    return new Promise((promiseResponse, promiseReject) => { 
        axios.patch(url, request)
            .then(response => {
                console.log('Server response: ', response);
                promiseResponse(response);
            })
            .catch(err => {
                console.log(err);
                console.log(err.response);
                if (err.response) {
                    if (err.response.status !== 200) {
                        console.log('Unexpected error code from the API server: ', err.response.status);
                    }
                }
                promiseReject(err);
            });
    });
};
