import { capitalizeEachWord } from '../../../../../../helpers/text';

// export const getCre

export const getComponentColumns = (rowData) => {
    const columnsList = [];
    console.log('rowData: ', rowData);
    const rowDataNormalized = rowData[0];
    Object.keys(rowDataNormalized).forEach(rowDataItem => {
        console.log('rowDataItem: ', rowDataItem);
        const isProcessing = rowDataItem === 'detail' || rowDataItem === '0' || rowDataItem === 'under_one_year';
        const isIgnoring =
            rowDataItem === 'total' ||
            rowDataItem === '1' ||
            rowDataItem === '2' ||
            rowDataItem === '3' ||
            rowDataItem === '4' ||
            rowDataItem === '5' ||
            rowDataItem === '6' ||
            rowDataItem === '7' ||
            rowDataItem === '8' ||
            rowDataItem === '9' ||
            rowDataItem === 'one_to_five_years' ||
            rowDataItem === 'six_to_ten_years' ||
            rowDataItem === 'eleven_plus_years';
        if (isProcessing) {
            let namesObject = {};
            if (rowDataItem === 'detail') {
                namesObject = rowDataNormalized[rowDataItem][0];
            }
            if (rowDataItem === 'under_one_year') {
                namesObject.age = undefined;
            }
            if (rowDataItem === '0' || rowDataItem === 'under_one_year') {
                Object.keys(rowDataNormalized[rowDataItem])
                      .forEach((key) => {
                          namesObject[key] = rowDataNormalized[rowDataItem][key];
                      });
            }
            if (namesObject !== undefined) {
                Object.keys(namesObject).forEach(name => {
                    columnsList.push({
                        id: name,
                        Header: capitalizeEachWord(name.replace(/(by)?_/g, ' ')),
                        headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                        className: 'custom-reports__inner-table__td',
                        accessor: d => {
                            if (name === 'percent') {
                                return `${d[name].toFixed(2)}%`;
                            }
                            return d[name];
                        },
                    });
                });
            }
        }

        if (!isProcessing && !isIgnoring) {
            columnsList.push({
                id: rowDataItem,
                Header: capitalizeEachWord(rowDataItem.replace(/(by)?_/g, ' ')),
                headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
                className: 'custom-reports__inner-table__td',
                accessor: d => {
                    const isPercentage =
                        rowDataItem === 'credit_card_entered' ||
                        rowDataItem === 'waiver_signed' ||
                        rowDataItem === 'dog_profile_created' ||
                        rowDataItem === 'email_verified';
                    if (isPercentage) {
                        return `${d[rowDataItem].toFixed(2)}%`;
                    }
                    return d[rowDataItem];
                },
            });
        }
    });
    return columnsList;
};

const dataColumns = {
    'percentage_completion': ['action', 'percent'],
    'customer_summary': ['total_accounts', 'total_customers', 'total_dogs', 'active_dogs'],
    'dogs_by_access_level': ['access_level', 'number_of_dogs', 'percent'],
    'top_10_breeds_active': ['breed', 'number_of_active_dogs'],
    'top_10_breeds_total': ['breed', 'number_of_dogs'],
    'dog_profiles_per_customer': ['quantity_of_dogs', 'number'],
    'by_age': ['age', 'number_of_dogs', 'number_of_active_dogs'],
    'dogs_top_10_zip_codes': ['zip_code', 'number_of_dogs'],
    'customers_top_10_zip_codes': ['zip_code', 'number_of_customers'],
};

const columnHeaders = {
    'action': 'Action',
    'percent': 'Percentage (%)',
    'total_accounts': 'Total Accounts',
    'total_customers': 'Total Customer',
    'total_dogs': 'Total Dogs',
    'active_dogs': 'Active Dogs',
    'access_level': 'Access Level',
    'number_of_dogs': '# of Dogs',
    'top_10_breeds_active': 'Top 10 Breeds Active',
    'top_10_breeds_total': 'Top 10 Breeds Total',
    'quantity_of_dogs': 'Quantity Of Dogs',
    'number': 'Number',
    'age': 'Age',
    'number_of_active_dogs': '# of Active Dogs',
    'dogs_top_10_zip_codes': 'Dogs Top 10 Zip Codes',
    'customers_top_10_zip_codes': 'Customers Top 10 Zip Codes',
    'zone': 'Zone',
    'breed': 'Breed',
    'zip_code': 'Zip Code',
    'number_of_customers': '# of Customers',
};

const getDataValue = ({ column, data }) => {
    if (column === 'action' || column === 'quantity_of_dogs' || column === 'age') {
        return capitalizeEachWord(data.replace(/(by)?_/g, ' '));
    }
    if (column === 'percent') {
        return `${data.toFixed(2)}%`;
    }
    return data;
};

export const createDataColumns = ({ dataName, data }) => {
    return dataColumns[dataName].map(column => {
        const Header = columnHeaders[column];

        if ((dataName === 'dogs_by_access_level' && column === 'number_of_dogs') ||
            ((dataName === 'top_10_breeds_active' || dataName === 'top_10_breeds_total') &&
                (column === 'number_of_active_dogs' || column === 'number_of_dogs')) ||
            ((dataName === 'dogs_top_10_zip_codes' || dataName === 'customers_top_10_zip_codes') &&
                (column === 'number_of_dogs' || column === 'number_of_customers'))) {
            column = 'count';
        }
        if (dataName === 'by_age' && column === 'number_of_dogs') column = 'total';
        if (dataName === 'by_age' && column === 'number_of_active_dogs') column = 'active';
        if ((dataName === 'top_10_breeds_active' || dataName === 'top_10_breeds_total') && column === 'breed') {
            column = 'name';
        }

        const dataColumn = {
            id: column,
            Header,
            headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
            className: 'custom-reports__inner-table__td',
            accessor: d => getDataValue({ column, data: d[column] }),
        };

        if (dataName === 'dogs_by_access_level') {
            if (column === 'access_level' || column === 'zone') {
                dataColumn.Footer = 'Total:';
            }
            if (column === 'count') {
                dataColumn.Footer = data.total.count;
            }
            if (column === 'percent') {
                dataColumn.Footer = `${data.total.percent.toFixed(2)}%`;
            }
        }

        return dataColumn;
    });
};

export const normalizeData = ({ dataName, data }) => {
    if (dataName === 'percentage_completion' || dataName === 'dog_profiles_per_customer' || dataName === 'by_age') {
        return Object.keys(data).map(valueName => {
            const normalizedValue = {};
            dataColumns[dataName].forEach(column => {
                if (column === 'action' || column === 'quantity_of_dogs' || column === 'age' || column === 'breed') {
                    normalizedValue[column] = valueName;
                }
                if (column === 'percent' || column === 'number') {
                    normalizedValue[column] = data[valueName];
                }
                if (column === 'number_of_dogs') {
                    normalizedValue.total = data[valueName].total;
                }
                if (column === 'number_of_active_dogs') {
                    normalizedValue.active = data[valueName].active;
                }
            });
            return normalizedValue;
        });
    }

    if (dataName === 'customer_summary') {
        return [data];
    }

    if (dataName === 'dogs_by_access_level') {
        return data.detail;
    }

    if (dataName === 'top_10_breeds_active' || dataName === 'top_10_breeds_total' ||
        dataName === 'dogs_top_10_zip_codes' || dataName === 'customers_top_10_zip_codes') {
        return data.map(item => {
            return item;
        });
    }

    return [];
};
