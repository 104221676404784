import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { updateCustomerNotification } from '../../../../../../actions/dashboard/employeeActions';

class NotificationsItem extends PureComponent {
    state = {
        isLoading: false
    }

    setNotification = () => {
        const { id, isActive, updateCustomerNotification } = this.props;
        this.setState({
            isLoading: true
        });
        updateCustomerNotification({ id, isActive: !isActive })
            .then(() => {
                this.setState({
                    isLoading: false
                });
            });
    }

    render() {
        const {
            id,
            title,
            subtitle,
            isActive,
            disabled,
        } = this.props;
        const { isLoading } = this.state;
        const toggleClassName = classNames({
            'notifications__toggle': true,
            'notifications__toggle_loading': isLoading,
        });
        const toggleMobileClassName = classNames({
            'mobile-toggle': true,
            'mobile-toggle_loading': isLoading,
        });
        return (
            <li className={classNames({
                'notifications__item': true,
                'notifications__item--disabled': disabled,
            })}>
                <div className='notifications__text'>
                    {title && <b>{title}</b>} {subtitle}
                </div>
                <div className={toggleClassName}>
                    <div className='notifications__toggle__desktop'>
                        <label
                            className='radio-control__controls-label'>
                            <input
                                name={id}
                                type='radio'
                                value='yes'
                                checked={isActive}
                                onChange={this.setNotification}
                                className='radio-control__controls-input'
                                disabled={disabled} />
                            <span className='radio-control__controls-text'>Yes</span>
                        </label>
                        <label
                            className='radio-control__controls-label'>
                            <input
                                name={id}
                                type='radio'
                                value='no'
                                checked={!isActive}
                                onChange={this.setNotification}
                                className='radio-control__controls-input'
                                disabled={disabled} />
                            <span className='radio-control__controls-text'>No</span>
                        </label>
                    </div>
                    <div className='notifications__toggle__mobile'>
                        <label className={toggleMobileClassName}>
                            <input
                                name={id}
                                type='checkbox'
                                checked={isActive}
                                onChange={this.setNotification}
                                className='mobile-toggle__checkbox'
                                disabled={disabled} />
                            <div className='mobile-toggle__toggle' />
                        </label>
                    </div>
                </div>
            </li>
        );
    }
}

NotificationsItem.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    isActive: PropTypes.bool,
    updateCustomerNotification: PropTypes.func,
};

const mapDispatchToProps = {
    updateCustomerNotification
};

export default connect(null, mapDispatchToProps)(NotificationsItem);
