/* eslint-disable no-extra-boolean-cast,react/prop-types */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import { ReactComponent as IconMaximize } from '../../../../../assets/icons/icon-maximize.svg';
import { ReactComponent as IconMinimize } from '../../../../../assets/icons/icon-minimize.svg';
import { ReactComponent as IconRemove } from '../../../../../assets/icons/icon-remove.svg';
import { ReactComponent as IconSend } from '../../../../../assets/icons/icon-send.svg';
import { dataStates, displayStates } from '../../../../../helpers/state';
import { getUserNameSpace } from '../../../../../helpers/userRoles';
import Icon from '../../../../common/Icon';
import Loader from '../../../../common/Loader';
import FormContainer from '../../../FormContainer';
import MessagesItem from '../MessagesItem/MessagesItem';
import MessagesLoadMore from '../MessagesLoadMore';

const MessagesWidgetShowControls = (props) => {
    const { messages, closeMessagesWidget, minimizeMessagesWidget, openMessagesWidget } = props;
    return (
        <div className='messages__header-controls'>
            {messages.displayState === displayStates.minimized
                ? (<div className='messages__maximize' onClick={() => openMessagesWidget()}>
                    <Icon className='icon_message-maximize' glyph={IconMaximize}/>
                </div>)
                : (<div className='messages__minimize' onClick={() => minimizeMessagesWidget()}>
                    <Icon className='icon_message-minimize' glyph={IconMinimize}/>
                </div>)
            }
            <div className='messages__remove' onClick={() => closeMessagesWidget()}>
                <Icon className='icon_message-remove' glyph={IconRemove}/>
            </div>
        </div>
    );
};

const MessagesWidgetHeader = (props) => {
    const { customersName } = props.messages;
    const HeaderTitleText = customersName ? `SMS - ${customersName}` : 'SMS';
    return (
        <div className='messages__header'>
            <div className='messages__header-title'>{HeaderTitleText}</div>
            <MessagesWidgetShowControls {...props}/>
        </div>
    );
};

const MessagesWidgetFooter = (props) => {
    const { displayState, customersId, dataState } = props.messages;
    const { isInvalid, isSubmitting, values } = props.formState.messagesWidgetForm;
    const form = 'messagesWidgetForm';
    const isImagesCleared =
        values !== undefined &&
        values.message_photo &&
        values.message_photo.length === 0;
    const isDisabled = isSubmitting || isInvalid || values === undefined || isImagesCleared;
    const messagesFooterClassNames = classNames({
        'messages__footer': true,
        'messages__footer_minimized': displayState === displayStates.minimized,
    });
    const messagesSendButtonClassNames = classNames({
        'messages__send': true,
        'messages__send_disabled': isDisabled,
    });
    return (
        <div className={messagesFooterClassNames}>
            <FormContainer
                form={{
                    form,
                    formState: props.formState.messagesWidgetForm,
                    dataState,
                    shouldInitialise: false,
                    className: 'messages__form',
                    submitAction: 'sendMessage',
                    updateAction: 'addMessageToMessagesItems',
                    errorAction: 'handleSentMessageError',
                    url: `/v1/${getUserNameSpace('customers.messages')}/customers/${customersId}/messages/`,
                }}/>
            <button
                className={messagesSendButtonClassNames}
                type='submit'
                disabled={isDisabled}
                onClick={() => props.submit(form)}>
                <Icon className='icon_message-send' glyph={IconSend}/>
            </button>
        </div>
    );
};

const MessagesWidgetBody = (props) => {
    const { customersName, customersId, displayState, dataState, items, nextUrl } = props.messages;
    const { notAsked, loading, failed } = dataStates;
    const EmptyMessagesText = customersName
        ? `No Previous SMS Messages Have been Sent to ${customersName}`
        : 'No Previous SMS Messages Have been Sent';
    const ErrorMessagesText = customersName
        ? `Something went wrong and ${customersName}'s SMS Messages didn't load`
        : 'Something went wrong and SMS Messages didn\'t load';
    const messagesBodyClassNames = classNames({
        'messages__body': true,
        'messages__body_loaded': nextUrl === null,
        'messages__body_centered': dataState === loading,
        'messages__body_minimized': displayState === displayStates.minimized,
    });
    const shouldDisplayItems = dataState !== notAsked && dataState !== loading;
    const renderContent = () => {
        if (dataState === notAsked) {
            return null;
        }

        if (dataState === loading) {
            return (<Loader
                isVisible
                loadingText='Loading Messages'
                colorClassName='icon_messages-loader-black'
                modifierClassName='icon_messages-loader'/>);
        }

        if (shouldDisplayItems && items.length) {
            return items.map((item) => {
                return (
                    <MessagesItem
                        key={`messages-item-${item.id}`}
                        item={item}
                        customerId={customersId}
                        getData={props.getMessageData}/>);
            });
        }

        if (dataState === failed) {
            return <div className='messages__empty-text'>{ErrorMessagesText}</div>;
        }

        return <div className='messages__empty-text'>{EmptyMessagesText}</div>;
    };
    return (
        <CSSTransitionGroup
            className={messagesBodyClassNames}
            component='div'
            transitionName='message'
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}>
            {nextUrl !== null &&
            <MessagesLoadMore dataState={dataState} getNext={props.getNextMessagesData}/>
            }
            {renderContent()}
        </CSSTransitionGroup>
    );
};

const MessagesWidget = (props) => {
    const { style, messages } = props;
    const { displayState } = messages;
    const { closed, opened, minimized } = displayStates;
    const messagesClassNames = classNames({
        'messages': true,
        'messages_active': displayState !== closed,
        'messages_open': displayState === opened,
        'messages_minimized': displayState === minimized,
    });
    const renderMessagesContent = displayState === closed
        ? null
        : (<div className='messages__content'>
            <MessagesWidgetHeader {...props}/>
            <MessagesWidgetBody {...props}/>
            <MessagesWidgetFooter {...props}/>
        </div>);
    return (
        <div className={messagesClassNames} style={style}>
            <CSSTransitionGroup
                transitionName='messages'
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}>
                {renderMessagesContent}
            </CSSTransitionGroup>
        </div>
    );
};

MessagesWidget.propTypes = {
    messages: PropTypes.shape({
        displayState: PropTypes.string,
        customersName: PropTypes.string,
        dataState: PropTypes.string,
    }).isRequired,
    style: PropTypes.shape({}).isRequired,
    closeMessagesWidget: PropTypes.func.isRequired,
    minimizeMessagesWidget: PropTypes.func.isRequired,
    openMessagesWidget: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    getMessageData: PropTypes.func.isRequired,
};

export default MessagesWidget;
