import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

import * as originalPathTypes from '../types/originalPath';

const INITIAL_STATE = fromJS({
  originalPath: '',
});

const originalPathsReducer = handleActions({
  [originalPathTypes.SET_ORIGINAL_PATH]: (state, action) => {
      return state.set('originalPath', action.payload);
  },
  [originalPathTypes.DELETE_ORIGINAL_PATH]: (state, action) => {
      return state.set('originalPath', null);
  },
}, INITIAL_STATE);

export default originalPathsReducer;
