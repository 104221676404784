import * as axios from 'axios';
import { push } from 'react-router-redux';
import { finishLoadingCurrentCustomer, startLoadingCurrentCustomer } from '../actions/dashboard/employeeActions';
import { markFormsLoaded, removeCurrentCustomerForm, setCurrentCustomerForms } from '../actions/dashboard/formsActions';
import { setDeleteCustomerAgreementModalDialogClosed } from '../actions/miscActions';
import { updateSteps } from './steps';
import { clearLocalRegistrationInfo } from './oauth';
import { setGlobalError } from '../actions/errorHandlerActions';
import { isCustomer, isTrainer, getUserNameSpace } from '../helpers/userRoles';
import { setWelcomeScreenShowed } from './profile';
import {store} from "../index";

export const signAgreements = (agreementId) => {
    return dispatch => {
        return axios({
            method: 'post',
            url: `/v1/users/me/missing-agreements/${agreementId}/sign/`,
            data: {
                signature: store.getState().get('formUpdate').get('form_update_signature'),
                date_stamp: store.getState().get('formUpdate').get('date_stamp'),
                id: agreementId
            }
        }).then(response => {
                console.log('Server response: ', response);
            })
            .catch(err => {
                console.log(err);
                console.log(err.response);
                dispatch(setGlobalError(err));
            });
    };
};

export const getMissingAgreements = () => {
    return (dispatch, getState) => {
        return axios.get('/v1/users/me/missing-agreements/?type=Customer%20signup')
                    .then(response => {
                        console.log('Server response: ', response.data.results.length);
                        let redirectUrl = '/dashboard';
                        if (isCustomer()) {
                            redirectUrl = '/home';
                            const userFirstLogin = !getState().getIn(['user', 'shouldWelcomeShowed']);
                            if (userFirstLogin) {
                                dispatch(setWelcomeScreenShowed());
                                redirectUrl = '/welcome';
                            }
                        }
                        // remove local data
                        clearLocalRegistrationInfo();

                        console.log('response.data.results: ', response.data.results);

                        let WaiverRelease;
                        if (response.data.results.length) {
                            WaiverRelease = response.data.results.find(agreement => {
                                return agreement.name === 'Waiver & Release';
                            });
                        }

                        if (WaiverRelease !== undefined) {
                            return axios
                                .post(`/v1/users/me/missing-agreements/${WaiverRelease.id}/sign/`)
                                .then(() => {
                                    if (getState().getIn(['steps', 'finishing_later'])) {
                                        dispatch(push(redirectUrl));
                                    } else {
                                        dispatch(updateSteps({ redirectPath: redirectUrl, stepNumber: 3 }));
                                    }
                                });
                        }

                        return dispatch(updateSteps({ redirectPath: redirectUrl, stepNumber: 3 }));
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                        dispatch(setGlobalError(err));
                    });
    };
};

export const getWaiverLegalese = () => {
    return dispatch => {
        return axios.get('/v1/users/me/missing-agreements/')
                    .then(response => {
                        console.log('Server response: ', response);
                        return response.data.results.find(result => result.name === 'Waiver & Release').html;
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                        dispatch(setGlobalError(err));
                    });
    };
};

export const getProfileAgreements = () => {
    return (dispatch) => {
        return axios.get('/v1/users/me/missing-agreements/')
                    .then(response => {
                        return response;
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                        dispatch(setGlobalError(err));
                    });
    };
};

export const getCustomersAgreements = (customerId) => {
    return async dispatch => {
        if (!isTrainer()) {
            dispatch(startLoadingCurrentCustomer());
            const url = isCustomer()
                ? '/v1/users/me/missing-agreements/'
                : `/v1/${getUserNameSpace('users.agreements')}/users/${customerId}/agreements/`;
            const agreements = await axios.get(url)
                                        .then((response) => {
                                            dispatch(setCurrentCustomerForms(response.data));
                                            dispatch(finishLoadingCurrentCustomer());
                                            dispatch(markFormsLoaded());
                                            return response;
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            console.log(error.response);
                                            dispatch(finishLoadingCurrentCustomer());
                                            dispatch(setGlobalError(error));
                                        });
            return agreements;
        }
        return [];
    };
};

export const deleteCustomersAgreement = (customerId, agreementId) => {
    return (dispatch) => {
        return axios.delete(`/v1/${getUserNameSpace('users.agreements')}/users/${customerId}/agreements/${agreementId}/`)
                    .then((response) => {
                        dispatch(removeCurrentCustomerForm(agreementId));
                        dispatch(setDeleteCustomerAgreementModalDialogClosed());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(setGlobalError(error));
                    });
    };
};
