/* eslint-disable no-plusplus */
export const getOnlyUniqueItems = (arr) => {
    const obj = {};

    for (let i = 0; i < arr.length; i++) {
        const str = arr[i];
        obj[str] = true;
    }

    return Object.keys(obj);
};

export const getRandomItem = (arr) => {
    const rand = Math.floor(Math.random() * arr.length);

    return arr[rand];
};
