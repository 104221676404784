import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as OnBoardingStepsTypes from '../types/onboarding/onBoardingStepsTypes';

const INITIAL_STATE = fromJS({
    isIntroductionComplete: true,
    isHealthComplete: false,
    isVetInfoComplete: false,
    isWaiverComplete: false,
    registration_step: 0,
    finishing_later: false
});

const onBoardingStepsReducer = handleActions({
    [OnBoardingStepsTypes.SET_CURRENT_REGISTRATION_STEP]: (state, action) => (
        state.set('registration_step', action.payload)
    ),
    [OnBoardingStepsTypes.SET_INTRODUCTION_REGISTRATION_STEP_COMPLETE]: (state, action) => (
        state.set('registration_step', 1)
    ),
    [OnBoardingStepsTypes.SET_INTRODUCTION_COMPLETE]: (state, action) => (
        state.set('isIntroductionComplete', true)
    ),
    [OnBoardingStepsTypes.SET_INTRODUCTION_INCOMPLETE]: (state, action) => (
        state.set('isIntroductionComplete', false)
    ),
    [OnBoardingStepsTypes.SET_HEALTH_COMPLETE]: (state, action) => (
        state.set('isHealthComplete', true)
    ),
    [OnBoardingStepsTypes.SET_HEALTH_INCOMPLETE]: (state, action) => (
        state.set('isHealthComplete', false)
    ),
    [OnBoardingStepsTypes.SET_VET_INFO_COMPLETE]: (state, action) => (
        state.set('isVetInfoComplete', true)
    ),
    [OnBoardingStepsTypes.SET_VET_INFO_INCOMPLETE]: (state, action) => (
        state.set('isVetInfoComplete', false)
    ),
    [OnBoardingStepsTypes.SET_WAIVER_COMPLETE]: (state, action) => (
        state.set('isWaiverComplete', true)
    ),
    [OnBoardingStepsTypes.SET_WAIVER_INCOMPLETE]: (state, action) => (
        state.set('isWaiverComplete', false)
    ),
    [OnBoardingStepsTypes.SET_FINISHING_LATER]: (state, action) => (
        state.set('finishing_later', true)
    )
}, INITIAL_STATE);

export default onBoardingStepsReducer;
