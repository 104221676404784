import * as axios from 'axios';

export const deleteRequest = (url) => {
    return () => {
        return axios.delete(url);
    };
};

export const editRequest = (url, formData) => {
    return () => {
        return axios.patch(url, formData);
    };
};

export const postRequest = (url, formData) => {
    return () => {
        return axios.post(url, formData);
    };
};

export const putRequest = (url, formData) => {
    return () => {
        return axios.put(url, formData);
    };
};
