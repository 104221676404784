import * as axios from 'axios';

export const getClassesHistory = () => {
    return () => {
        const url = '/v1/customers/me/classes-history/?page_size=20';
        return axios.get(url);
    };
};

export const getNextClassesHistory = () => {
    return (dispatch, getState) => {
        const nextUrl = getState().getIn(['classesHistory', 'nextUrl']);
        return axios.get(nextUrl);
    };
};
