import { isValidCreditCard } from '../../../../../helpers/validate';

const validate = values => {
    const errors = {};

    if (!values.get('number')) {
        errors.number = 'Credit Card Number is Required.';
    } else if (!isValidCreditCard(values.get('number'))) {
        errors.number = 'Please enter valid Credit Card Number';
    }

    if (!values.get('expiration_month') || Array.isArray(values.get('expiration_month'))) {
        errors.expiration_month = 'Expiration Date (MM) is Required.';
    }

    if (!values.get('expiration_year') || Array.isArray(values.get('expiration_year'))) {
        errors.expiration_year = 'Expiration Date (YY) is Required.';
    }

    // if (!values.get('cvc')) {
    //     errors.cvc = 'Security Code is Required.';
    // }

    return errors;
};

export default validate;
