import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line react/prop-types
export const renderError = ({ meta: { touched, error }, shortWarning }) => {
    const ErrorClassNames = classNames({
        'radio-control__description-warning': true,
        'radio-control__description-warning_short': shortWarning
    });
    return (
        error ? <span className={ErrorClassNames}>{error}</span> : null
    );
};

// eslint-disable-next-line react/prop-types
export const renderWarning = ({ meta: { touched, warning }, shortWarning }) => {
    const WarningClassNames = classNames({
        'radio-control__description-warning': true,
        'radio-control__description-warning_short': shortWarning
    });
    return (
        warning ? <span className={WarningClassNames}>{warning}</span> : null
    );
};

// eslint-disable-next-line max-len
const renderRadioControl = ({ name, input, label, type, meta: { touched, error, warning }, description, shortDescription, shortWarning, labelFirst, labelSecond }) => {
    const DescriptionClassNames = classNames({
        'radio-control__description': true,
        'radio-control__description_short': shortDescription
    });
    const WarningClassNames = classNames({
        'radio-control__description-warning': true,
        'radio-control__description-warning_short': shortWarning
    });
    return (
        <div className='radio-control'>
            <div className={DescriptionClassNames}>
                <label className='radio-control__description-label'>{description}</label>
                {error && <span className={WarningClassNames}>{error}</span>}
                {warning && <span className={WarningClassNames}>{warning}</span>}
            </div>
            <div className='radio-control__controls'>
                <label className='radio-control__controls-label'>
                    <input
                        {...input}
                        type='radio'
                        value={labelFirst}
                        className='radio-control__controls-input'/>
                    <span className='radio-control__controls-text'>{labelFirst}</span>
                </label>
                <label className='radio-control__controls-label'>
                    <input
                        {...input}
                        type='radio'
                        value='No'
                        className='radio-control__controls-input'/>
                    <span className='radio-control__controls-text'>{labelSecond}</span>
                </label>
            </div>
        </div>
    );
};

renderRadioControl.propTypes = {
    name: PropTypes.string,
    input: PropTypes.shape({}),
    label: PropTypes.string,
    type: PropTypes.string,
    meta: PropTypes.shape({}),
    description: PropTypes.string.isRequired,
    shortDescription: PropTypes.bool,
    shortWarning: PropTypes.bool,
    warning: PropTypes.string,
    labelFirst: PropTypes.string.isRequired,
    labelSecond: PropTypes.string.isRequired
};

export default renderRadioControl;
