import React from 'react';
import Table from '../../Table';
import SearchHighlighter from '../SearchHighlighter';

class SearchTopTable extends Table {
    state = {
        isScrollable: false,
    };

    render() {
        const { searchString, onOwnerClick, onDogClick, hideTable } = this.props;
        const { isScrollable } = this.state;
        const columns = [
            {
                Header: 'Dog',
                headerClassName: 'search-top-results-table__th',
                className: 'search-top-results-table__td',
                id: 'dog_name',
                maxWidth: 150,
                accessor: d => d,
                Cell: row => {
                    return row.value.get('dogName') === undefined
                        ? (<div>
                            <span>-</span>
                        </div>)
                        : (<div
                            className='search-top-results-table__result-link search-top-results-table__cell-container'
                            onMouseDown={() => {
                                hideTable();
                                onDogClick(row.value.get('dogData'));
                            }}
                            onClick={() => {
                                hideTable();
                                onDogClick(row.value.get('dogData'));
                            }}>
                            <span>
                                <SearchHighlighter
                                    searchResult={row.value.get('dogName')}
                                    searchString={searchString}/>
                            </span>
                        </div>);
                },
            },
            {
                Header: 'Breed',
                headerClassName: 'search-top-results-table__th',
                className: 'search-top-results-table__td',
                id: 'dog_breed',
                maxWidth: 300,
                accessor: d => d.get('breed'),
                Cell: row =>
                    (<div className='search-top-results-table__cell-container'>
                        {row.value === undefined
                            ? <span>-</span>
                            : <span>
                                {row.value}
                            </span>}
                    </div>),
            },
            {
                Header: 'Owner',
                headerClassName: 'search-top-results-table__th',
                className: 'search-top-results-table__td',
                id: 'owner',
                maxWidth: 300,
                accessor: d => d,
                Cell: row => {
                    return row.value.get('ownerName') === undefined
                        ? (<div>
                            <span>-</span>
                        </div>)
                        : (<div
                            className='search-top-results-table__result-link search-top-results-table__cell-container'
                            onMouseDown={() => {
                                hideTable();
                                onOwnerClick(row.value.getIn(['ownerData', 'id']));
                            }}

                            onClick={() => {
                                hideTable();
                                onOwnerClick(row.value.getIn(['ownerData', 'id']));
                            }}>
                            <span>
                                <SearchHighlighter
                                    searchResult={row.value.get('ownerName')}
                                    searchString={searchString}/>
                            </span>
                        </div>);
                },
            },
            {
                Header: 'Phone',
                headerClassName: 'search-top-results-table__th search-top-results-table__th_phone',
                className: 'search-top-results-table__td search-top-results-table__td_phone',
                id: 'phone',
                maxWidth: 300,
                accessor: d => d.get('phone_number'),
                Cell: row =>
                    (<div className='search-top-results-table__cell-container'>
                        {row.value === undefined
                            ? <span>-</span>
                            : (<a href={`tel:${row.value}`} className='search-top-results-table__phone-link'>
                                <SearchHighlighter searchResult={row.value} searchString={searchString}/>
                            </a>)}
                    </div>),
            },
            {
                Header: 'Email',
                headerClassName: 'search-top-results-table__th search-top-results-table__th_email',
                className: 'search-top-results-table__td search-top-results-table__td_email',
                id: 'email',
                accessor: d => d.get('email'),
                Cell: row =>
                    (<div className='search-top-results-table__cell-container'>
                        {row.value === undefined
                            ? <span>-</span>
                            : (<a href={`mailto:${row.value}`} className='search-top-results-table__email-link'>
                                <SearchHighlighter searchResult={row.value} searchString={searchString}/>
                            </a>)}
                    </div>),
            },
        ];
        const { classNameModifier = '' } = this.props;
        return (
            <div className={classNameModifier}>
                {this.renderTable({ isScrollable, columns })}
            </div>
        );
    }
}

export default SearchTopTable;
