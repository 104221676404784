import { createAction } from 'redux-actions';
import { getNextPurchaseHistory, getPurchaseHistory } from '../../services/purchaseHistory';
import * as PurchaseHistoryTypes from '../../types/dashboard/purchaseHistoryTypes';
import { setGlobalError } from '../errorHandlerActions';

export const setCustomerPurchaseHistory = createAction(PurchaseHistoryTypes.SET_CUSTOMER_PURCHASE_HISTORY);
export const setNextCustomerPurchaseHistory = createAction(PurchaseHistoryTypes.SET_NEXT_CUSTOMER_PURCHASE_HISTORY);
export const markCustomerPurchaseHistoryLoaded = createAction(
    PurchaseHistoryTypes.MARK_CUSTOMER_PURCHASE_HISTORY_LOADED);
export const unmarkCustomerPurchaseHistoryLoaded = createAction(
    PurchaseHistoryTypes.UNMARK_CUSTOMER_PURCHASE_HISTORY_LOADED);
export const startIsLoadingCustomerPurchaseHistory = createAction(
    PurchaseHistoryTypes.START_ISLOADING_CUSTOMER_PURCHASE_HISTORY);
export const finishIsLoadingCustomerPurchaseHistory = createAction(
    PurchaseHistoryTypes.FINISH_ISLOADING_CUSTOMER_PURCHASE_HISTORY);
export const getCustomersPurchaseHistory = ({ customerId, queryString }) => {
    return dispatch => {
        dispatch(startIsLoadingCustomerPurchaseHistory());
        dispatch(getPurchaseHistory({
            customerId,
            queryString
        })).then(response => {
            dispatch(setCustomerPurchaseHistory(response.data));
            dispatch(markCustomerPurchaseHistoryLoaded());
            dispatch(finishIsLoadingCustomerPurchaseHistory());
        }).catch((error) => {
            console.log(error);
            console.log(error.response);
            dispatch(finishIsLoadingCustomerPurchaseHistory());
            dispatch(setGlobalError(error));
        });
    };
};

export const getCustomersNextPurchaseHistory = () => {
    return dispatch => {
        dispatch(startIsLoadingCustomerPurchaseHistory());
        dispatch(getNextPurchaseHistory()).then(response => {
            dispatch(setNextCustomerPurchaseHistory(response.data));
            dispatch(markCustomerPurchaseHistoryLoaded());
            dispatch(finishIsLoadingCustomerPurchaseHistory());
        }).catch((error) => {
            console.log(error);
            console.log(error.response);
            dispatch(finishIsLoadingCustomerPurchaseHistory());
            dispatch(setGlobalError(error));
        });
    };
};
