import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { setDefaultImmunizationExpirationDatesData } from '../../../../actions/miscActions';
import renderDateControl from '../../../common/Form/DateControl';

import warn from './warn';

class ImmunizationExpirationDatesModalDialogForm extends React.PureComponent {
    static propTypes = {
        initialValues: PropTypes.shape({}),
        vaccinations: PropTypes.shape([]),
        handleSubmit: PropTypes.func,
        handleDefaultValues: PropTypes.func,
        invalid: PropTypes.bool,
        submitting: PropTypes.bool,
        isLoading: PropTypes.bool,
        isFormOpened: PropTypes.bool,
    };

    componentDidMount() {
        this.props.handleDefaultValues();
    }

    render() {
        const { handleSubmit, submitting, isLoading, vaccinations, isFormOpened } = this.props;
        const submitButtonClassNames = classNames({
            'button modal-dialog__button': true,
            'button_disabled': submitting || isLoading,
        });
        return (
            <form className='modal-dialog__form' onSubmit={handleSubmit}>
                <div className='modal-dialog__form-content'>
                    {isFormOpened &&
                        vaccinations.map(vaccine => {
                            return (
                                <Field
                                    key={`vaccination-key-${vaccine}`}
                                    name={vaccine}
                                    type='text'
                                    component={renderDateControl}
                                    label={vaccine}
                                    disablePast/>
                            );
                        })}
                </div>
                <div className='modal-dialog__form-footer modal-dialog__form-footer_row'>
                    <button className={submitButtonClassNames} type='submit' disabled={submitting || isLoading}>
                        {submitting || isLoading ? 'Updating...' : 'Save'}
                    </button>
                </div>
            </form>
        );
    }
}

const form = reduxForm({
    form: 'ImmunizationExpirationDatesModalDialogForm',
    enableReinitialize: true,
    warn,
});

const mapStateToProps = state => {
    const normalizedInstance = state.getIn(['currentImmunizationExpirationDates', 'normalizedInstance']);
    const instance = state.getIn(['currentImmunizationExpirationDates', 'instance']);
    const vaccinations = state.getIn(['currentImmunizationExpirationDates', 'choices']);
    const currentDogId = state.getIn(['currentDog', 'instance', 'id']);

    return {
        initialValues: normalizedInstance
            .set('originalData', instance)
            .set('dogId', currentDogId)
            .set('vaccinations', vaccinations),
        vaccinations,
        isLoading: state.getIn(['misc', 'modals', 'isImmunizationExpirationDateLoading']),
    };
};

const mapDispatchToProps = dispatch => ({
    handleDefaultValues: bindActionCreators(setDefaultImmunizationExpirationDatesData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ImmunizationExpirationDatesModalDialogForm));
