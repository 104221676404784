import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { isEqual } from 'lodash';
import Immutable from 'immutable';

class renderCheckboxListControl extends PureComponent {
    componentDidUpdate(prevProps) {
        if (this.swiper && !isEqual(this.props.options, prevProps.options)) {
            this.swiper.update(true);
        }
    }
    swiper = null;
    render() {
        const { input, meta, options, label, description = '', maxCheckedNum, className, isRadio, onChangeHandler } = this.props;
        const { name, onChange } = input;
        const { touched, error } = meta;
        let inputValue;

        if (input.value instanceof Immutable.List) {
            inputValue = input.value.toJS();
        } else {
            inputValue = input.value;
        }

        const checkboxes = options.map(({ label, value }, index) => {
            const handleChange = event => {
                const arr = [...inputValue];
                if (event.target.checked) {
                    if (maxCheckedNum && arr.length === maxCheckedNum) {
                        if (onChangeHandler) {
                            onChangeHandler(arr);
                        }
                        return onChange(arr);
                    }
                    if (isRadio) {
                        if (onChangeHandler) {
                            onChangeHandler(value);
                        }
                        return onChange(value);
                    }
                    arr.push(value);
                    if (onChangeHandler) {
                        onChangeHandler(arr);
                    }
                    return onChange(arr);
                }

                arr.splice(arr.indexOf(value), 1);
                if (onChangeHandler) {
                    onChangeHandler(arr);
                }
                return onChange(arr);
            };
            const checked = inputValue.includes(value);
            return (
                <label key={`checkbox-${index}`} className='checkbox-list-control__checkbox-label'>
                    <input
                        className='checkbox-list-control__checkbox-input'
                        type={isRadio ? 'radio' : 'checkbox'}
                        name={isRadio ? name : `${name}[${index}]`}
                        value={value}
                        checked={checked}
                        onChange={handleChange}/>
                    <span className='checkbox-list-control__checkbox-name'>
                        {label}
                    </span>
                </label>
            );
        });

        const CheckboxListControlNames = classNames({
            'checkbox-list-control': true,
            'checkbox-list-control_failed': touched && error,
            [className]: className
        });

        return (
            <div className='checkbox-list-control-wrapper'>
                <div className={CheckboxListControlNames}>
                    <div className='checkbox-list-control__title'>
                        {label}
                    </div>
                    {description.length > 0 &&
                    <div className='checkbox-list-control__description'>
                        {description}
                    </div>}
                    <div className='checkbox-list-control__checkboxes'>{checkboxes}</div>
                </div>
                {(error && touched) && <p className='checkbox-list-control__error'>{error}</p>}
            </div>
        );
    }
}

renderCheckboxListControl.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({})),
    input: PropTypes.shape({}),
    meta: PropTypes.shape({}),
    label: PropTypes.string,
    description: PropTypes.string,
    maxCheckedNum: PropTypes.number,
    className: PropTypes.string,
    isRadio: PropTypes.bool,
    isScrollable: PropTypes.bool,
    onChangeHandler: PropTypes.func
};

export default renderCheckboxListControl;
