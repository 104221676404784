import PropTypes from 'prop-types';
import React from 'react';
import { fromJS } from 'immutable';
import AvatarPlaceholder from '../../../../common/AvatarPlaceholder';
import { timePrettify, formatTrainingDateTimeToAMPM } from '../../../../../helpers/date';
import { isTrainer } from '../../../../../helpers/userRoles';

const TrainingEventCard = ({ cardData, dispatchPush, openCustomReports, handleSettingCurrentDog }) => {
    const timeFormat = 'h:mmA';

    const goToCalendar = trainingEventId => {
        dispatchPush(`/dashboard/calendar/trainings/${trainingEventId}`);
    };

    const renderDogs = dogs => {
        if (!dogs.length) {
            return null;
        }

        const renderAvatar = dog => {
            if (!dog.photo_processed) {
                return <AvatarPlaceholder type='dog' size={40}/>;
            }

            return (
                <div
                    className='class-event-card__attendance-avatar-image'
                    style={{
                        background: `url(${dog.photo_processed}) center center / cover no-repeat`,
                    }}/>
            );
        };

        return dogs.map((dog, i) => {
            return (
                <div className='class-event-card__attendance-avatar-container' key={'dog-' + i} >
                    {renderAvatar(dog)}
                    <span
                        className='class-event-card__attendance-name'
                        onClick={() => handleSettingCurrentDog(fromJS(dog))}>
                        {dog.name}
                    </span>
                </div>
            );
        });
    };

    return (
        <div className='class-event-card'>
            <div className='class-event-card__header'>
                <div className='class-event-card__header-text'>
                    {cardData.training_detail.name}
                </div>
            </div>
            <div className='class-event-card__item'>
                <div className='class-event-card__item-header'>
                    Instructor
                </div>
                <div className='class-event-card__item-content class-event-card__instructor-name'>
                    {cardData.trainer_detail.full_name}
                </div>
            </div>
            <div className='class-event-card__item'>
                <div className='class-event-card__item-header'>
                    Pick Up
                </div>
                <div className='class-event-card__item-content'>
                    {timePrettify(formatTrainingDateTimeToAMPM(cardData.training_event_detail.start), timeFormat)}
                </div>
            </div>
            <div className='class-event-card__item'>
                <div className='class-event-card__item-header'>
                    Drop Off
                </div>
                <div className='class-event-card__item-content'>
                    {timePrettify(formatTrainingDateTimeToAMPM(cardData.training_event_detail.end), timeFormat)}
                </div>
            </div>
            <div className='class-event-card__item class-event-card__attendance'>
                <div className='class-event-card__attendance-text'>
                    {(cardData.coming && cardData.coming + ' Coming')}
                </div>
                {renderDogs(cardData.dogs)}
            </div>
            <div className='class-event-card__footer'>
                <div
                    onClick={() => goToCalendar(cardData.training_event)}
                    className='class-event-card__footer-link'>
                    View in Calendar
                </div>
                {!isTrainer()
                ? <div
                    onClick={() => {
                        openCustomReports([
                            {
                                key: 'type',
                                value: 'marketing'
                            },
                            {
                                key: 'report',
                                value: 'dog_attendance_statistics'
                            }
                        ]);
                    }}
                    className='class-event-card__footer-link'>
                    View Most Attended
                </div>
                : null}
            </div>
        </div>
    );
};

TrainingEventCard.propTypes = {
    cardData: PropTypes.shape({}),
    dispatchPush: PropTypes.func,
    openCustomReports: PropTypes.func,
    handleSettingCurrentDog: PropTypes.func,
};

export default TrainingEventCard;
