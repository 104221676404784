export const dataStates = {
    notAsked: 'notAsked',
    loading: 'loading',
    loadingMore: 'loadingMore',
    loaded: 'loaded',
    submitting: 'submitting',
    failed: 'failed',
    polling: 'polling',
};

export const displayStates = {
    closed: 'closed',
    opened: 'opened',
    minimized: 'minimized',
};
