import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { setCurrentDogData } from '../../../../actions/dashboard/employeeActions';
import { getAccessLevelOptions } from '../../../../actions/miscActions';
import * as ModalOpenActions from '../../../../actions/modal/openActions';
import { ReactComponent as IconDeceased } from '../../../../assets/icons/icon-deceased.svg';
import { ReactComponent as IconEdit } from '../../../../assets/icons/icon-edit.svg';
import { ReactComponent as IconTrash } from '../../../../assets/icons/icon-trash.svg';
import { ReactComponent as IconVip } from '../../../../assets/icons/icon-vip.svg';
import { ReactComponent as IconZzz } from '../../../../assets/icons/icon-zzz.svg';
import { getDogById } from '../../../../services/dogs';
import ContactCard from '../../../common/ContactCard';
import Container from '../../../common/Container';
import Navbar from '../../../common/Navbar';
import { toggleBannedCurrentDog } from '../../../../actions/dashboard/toggleActions';

class DogsContainer extends React.PureComponent {
    static propTypes = {
        dog: PropTypes.shape({}),
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ]),
        handleSettingCurrentDogData: PropTypes.func,
        handleGettingCurrentDog: PropTypes.func,
        handleSettingCurrentVaccinationRecords: PropTypes.func,
        openDogNameEditModalDialog: PropTypes.func,
        handleGettingAccessLevelOptions: PropTypes.func,
        openDogAccessLevelEditModalDialog: PropTypes.func,
        openDogDeleteModalDialog: PropTypes.func,
        openDogMarkDeceasedActionModalDialog: PropTypes.func,
        options: PropTypes.shape({}),
        handleToggleBannedCurrentDog: PropTypes.func
    };

    componentWillMount() {
        const { location } = this.props;
        // eslint-disable-next-line max-len
        const {
            handleSettingCurrentDogData, handleGettingCurrentDog, options, handleGettingAccessLevelOptions
        } = this.props;
        handleSettingCurrentDogData(null);
        handleGettingCurrentDog(location.pathname.split('/')[3]);

        if (!options.get('accessLevel').size) {
            handleGettingAccessLevelOptions();
        }
    }

    render() {
        const {
            dog,
            children,
            openDogNameEditModalDialog,
            openDogAccessLevelEditModalDialog,
            openDogMarkDeceasedActionModalDialog,
            openDogDeleteModalDialog,
            handleToggleBannedCurrentDog,
            location,
        } = this.props;
        const isBanned = dog.getIn(['instance', 'is_banned']);
        const contactItems = [
            {
                iconClass: 'icon_edit',
                icon: IconEdit,
                text: 'Edit Name',
                onClickCB: () => {
                    openDogNameEditModalDialog(dog.get('instance'));
                }
            },
            {
                iconClass: 'icon_vip',
                icon: IconVip,
                text: 'Change Access Level',
                onClickCB: () => {
                    openDogAccessLevelEditModalDialog(dog.get('instance'));
                }
            },
            {
                iconClass: 'icon_deceased',
                icon: IconDeceased,
                text: dog.getIn(['instance', 'is_deceased']) ? 'Unmark as Deceased' : 'Mark as Deceased',
                onClickCB: () => {
                    openDogMarkDeceasedActionModalDialog(dog.get('instance'));
                }
            },
            {
                iconClass: 'icon_zzz',
                icon: IconZzz,
                text: isBanned ? 'Mark as active' : 'Mark as Inactive',
                onClickCB: handleToggleBannedCurrentDog
            },
            {
                iconClass: 'icon_trash',
                icon: IconTrash,
                text: 'Delete Dog',
                onClickCB: () => {
                    openDogDeleteModalDialog(dog.get('instance'));
                }
            }
        ];
        const navbarItems = {
            context: 'dog',
            items: [
                {
                    page: 'details',
                    title: 'Details'
                },
                {
                    page: 'classes',
                    title: 'Classes'
                },
                {
                    page: 'trainings',
                    title: 'Trainings'
                },
                {
                    page: 'notes',
                    title: 'Notes'
                },
                {
                    page: 'reports',
                    title: 'Snapshots'
                },
                {
                    page: 'evaluation',
                    title: 'Evaluation'
                }
            ]
        };
        return (
            <Container className='dashboard__main'>
                <ContactCard isForDog functionalItems={contactItems} contact={dog}/>
                <Navbar items={navbarItems} id={location.pathname.split('/')[3]}/>
                {children}
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        dog: state.getIn(['currentDog']),
        options: state.getIn(['misc', 'options'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleSettingCurrentDogData: bindActionCreators(setCurrentDogData, dispatch),
    handleGettingCurrentDog: bindActionCreators(getDogById, dispatch),
    handleGettingAccessLevelOptions: bindActionCreators(getAccessLevelOptions, dispatch),
    openDogNameEditModalDialog: bindActionCreators(ModalOpenActions.openDogNameEditModalDialog, dispatch),
    openDogAccessLevelEditModalDialog: bindActionCreators(ModalOpenActions.openDogAccessLevelEditModalDialog, dispatch),
    openDogDeleteModalDialog: bindActionCreators(ModalOpenActions.openDogDeleteModalDialog, dispatch),
    openDogMarkDeceasedActionModalDialog: bindActionCreators(ModalOpenActions.openDogMarkDeceasedActionModalDialog,
        dispatch),
    handleToggleBannedCurrentDog: bindActionCreators(toggleBannedCurrentDog, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DogsContainer));
