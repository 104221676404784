import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { setMobileSidebarClosed } from '../../../actions/miscActions';
import { loadProfileData } from '../../../services/profile';
import ErrorHandler from '../../common/ErrorHandler';
import MobileSidebar from '../../common/MobileSidebar';
import PageHeader from '../../common/PageHeader';
import Sidebar from '../../common/Sidebar';
import MessagesContainer from './Messages/MessagesContainer';
import { checkAgreements } from '../../../actions/AgreementsActions';

class DashboardContainer extends PureComponent {
    componentWillMount() {
        const { isProfileLoaded, loadProfileData, checkAgreements } = this.props;
        if (!isProfileLoaded) {
            loadProfileData();
        }
        checkAgreements();
    }

    render() {
        const {
            children,
            isAnyModalOpened,
            isModalOpened,
            isMobileSidebarOpened,
            setMobileSidebarClosed,
            isProfileLoaded,
            isPageBlocked,
            agreementsAreLoaded,
        } = this.props;
        const dashboardClassNames = classNames({
            'dashboard': true,
            'dashboard_blurred': isAnyModalOpened || isModalOpened,
            'dashboard_mobile-sidebar': isMobileSidebarOpened,
            'dashboard_blocked': isPageBlocked
        });
        if (isProfileLoaded && agreementsAreLoaded) {
            return (
                <div className={dashboardClassNames}>
                    <PageHeader/>
                    <ErrorHandler/>
                    <MobileSidebar/>
                    <div className='dashboard__mobile-sidebar-overlay' onClick={setMobileSidebarClosed}/>
                    <div className='dashboard__container'>
                        <Sidebar/>
                        {children}
                    </div>
                    <MessagesContainer/>
                </div>
            );
        }
        return null;
    }
}

DashboardContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    isAnyModalOpened: PropTypes.bool,
    isModalOpened: PropTypes.bool,
    isPageBlocked: PropTypes.bool,
    isMobileSidebarOpened: PropTypes.bool,
    setMobileSidebarClosed: PropTypes.func,
    loadProfileData: PropTypes.func,
    checkAgreements: PropTypes.func,
    agreementsAreLoaded: PropTypes.bool,
    isProfileLoaded: PropTypes.bool,
    messagesDisplayState: PropTypes.string,
};

const mapStateToProps = state => {
    return {
        isAnyModalOpened: state.getIn(['misc', 'modals', 'any']),
        isPageBlocked: state.getIn(['misc', 'ui', 'pageBlocked']),
        isModalOpened: state.getIn(['modal', 'isOpened']),
        isMobileSidebarOpened: state.getIn(['misc', 'mobileSidebar', 'isOpened']),
        isProfileLoaded: state.getIn(['user', 'id']),
        agreementsAreLoaded: state.getIn(['user', 'agreements', 'areLoaded']),
    };
};

const mapDispatchToProps = {
    setMobileSidebarClosed,
    loadProfileData,
    checkAgreements
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
