import * as axios from 'axios';
import {
    finishLoadingCurrentCustomer, finishLoadingCurrentDog, startLoadingCurrentCustomer,
    startLoadingCurrentDog
} from '../actions/dashboard/employeeActions';
import {
    markCustomerVaccinationsLoaded, markDogVaccinationsLoaded,
    setCurrentCustomerVaccinations, setCurrentDogVaccinationRecords, setCurrentDogVaccinationRecordsChoices,
    startDogVaccinationRecordsLoading, finishDogVaccinationRecordsLoading
} from '../actions/dashboard/vaccinationRecordsActions';
import { setGlobalError } from '../actions/errorHandlerActions';
import { getUserNameSpace } from '../helpers/userRoles';


export const getVaccinationRecords = ({ role, context, id }) => {
    return (dispatch) => {
        if (context === 'dogs') {
            dispatch(startDogVaccinationRecordsLoading());
        }
        return axios.get(`/v1/${role}/${context}/${id}/vaccination-records/`)
                    .then(response => {
                        if (context === 'customers') {
                            dispatch(startLoadingCurrentCustomer());
                            dispatch(setCurrentCustomerVaccinations(response.data.results));
                            dispatch(finishLoadingCurrentCustomer());
                            dispatch(markCustomerVaccinationsLoaded());
                        }
                        if (context === 'dogs') {
                            dispatch(startLoadingCurrentDog());
                            dispatch(setCurrentDogVaccinationRecords(response.data.results));
                            dispatch(setCurrentDogVaccinationRecordsChoices(response.data.vaccination_records_choices));
                            dispatch(finishLoadingCurrentDog());
                            dispatch(finishDogVaccinationRecordsLoading());
                            dispatch(markDogVaccinationsLoaded());
                        }
                    })
                    .catch(err => {
                        if (context === 'customers') {
                            dispatch(finishLoadingCurrentCustomer());
                        }
                        if (context === 'dogs') {
                            dispatch(finishLoadingCurrentDog());
                        }
                        console.log(err);
                        console.log(err.response);
                        dispatch(setGlobalError(err));
                    });
    };
};

export const getImmunizationDocs = ({ context, id }) => {
    return (dispatch) => {
        return axios.get(`/v1/${getUserNameSpace(context + '.immunizations-docs')}/${context}/${id}/immunizations-docs/`)
                    .then(response => {
                        if (context === 'customers') {
                            dispatch(startLoadingCurrentCustomer());
                            dispatch(setCurrentCustomerVaccinations(response.data));
                            dispatch(finishLoadingCurrentCustomer());
                            dispatch(markCustomerVaccinationsLoaded());
                        }
                        if (context === 'dogs') {
                            dispatch(startLoadingCurrentDog());
                            dispatch(setCurrentDogVaccinationRecords(response.data.results));
                            dispatch(setCurrentDogVaccinationRecordsChoices(response.data.vaccination_records_choices));
                            dispatch(finishLoadingCurrentDog());
                            dispatch(markDogVaccinationsLoaded());
                        }
                    })
                    .catch(err => {
                        if (context === 'customers') {
                            dispatch(finishLoadingCurrentCustomer());
                        }
                        if (context === 'dogs') {
                            dispatch(finishLoadingCurrentDog());
                        }
                        console.log(err);
                        console.log(err.response);
                        dispatch(setGlobalError(err));
                    });
    };
};
