import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import { ReactComponent as IconCamera } from '../../../assets/icons/icon-camera.svg';
import { ReactComponent as IconDog } from '../../../assets/icons/icon-dog.svg';

const AvatarPlaceholder = ({ type = 'user', size, className = '' }) => {
    if (type === 'dog') {
        if (size === 40) {
            return (
                <div className='avatar__default-placeholder avatar__default-placeholder_dog avatar__default-placeholder_40'>
                    <Icon className='avatar__icon avatar__icon_24 icon_dog' glyph={IconDog}/>
                </div>
            );
        }

        if (size === 36) {
            return (
                <div className='avatar__default-placeholder avatar__default-placeholder_dog avatar__default-placeholder_36'>
                    <Icon className='avatar__icon avatar__icon_18 icon_dog' glyph={IconDog}/>
                </div>
            );
        }

        if (size === 70) {
            return (
                <div className='avatar__default-placeholder avatar__default-placeholder_dog avatar__default-placeholder_62'>
                    <Icon className='avatar__icon avatar__icon_24 icon_dog' glyph={IconDog}/>
                </div>
            );
        }


        if (size === 'card') {
            return (
                <div className='avatar__default-placeholder avatar__default-placeholder_dog avatar__default-placeholder_card'>
                    <Icon className='avatar__icon avatar__icon_60 icon_dog' glyph={IconDog}/>
                </div>
            );
        }

        if (size === 'customer-card') {
            return (
                <div className='avatar__default-placeholder avatar__default-placeholder_dog avatar__default-placeholder_customer-card'>
                    <Icon className='avatar__icon avatar__icon_60 icon_dog' glyph={IconDog}/>
                </div>
            );
        }

        if (size === 'customer-schedule-card') {
            return (
                <div className='avatar__default-placeholder avatar__default-placeholder_dog avatar__default-placeholder_customer-schedule-card'>
                    <Icon className='avatar__icon avatar__icon_40 icon_dog' glyph={IconDog}/>
                </div>
            );
        }

        if (size === 'report-card') {
            return (
                <div className='avatar__default-placeholder avatar__default-placeholder_dog avatar__default-placeholder_report-card'>
                    <Icon className='avatar__icon avatar__icon_40 icon_dog' glyph={IconDog}/>
                </div>
            );
        }

        return (
            <div className='avatar__default-placeholder avatar__default-placeholder_dog'>
                <Icon className='avatar__icon avatar__icon_small icon_dog' glyph={IconDog}/>
            </div>
        );
    }

    if (size === 36) {
        return (
            <div className='avatar__default-placeholder avatar__default-placeholder_36'>
                <div className='avatar__default-placeholder-inner avatar__default-placeholder-inner_28'>
                    <Icon className='avatar__icon avatar__icon_18 icon_camera' glyph={IconCamera}/>
                </div>
            </div>
        );
    }

    return (
        <div className={'avatar__default-placeholder ' + className}>
            <div className='avatar__default-placeholder-inner'>
                <Icon className='avatar__icon avatar__icon_small icon_camera' glyph={IconCamera}/>
            </div>
        </div>
    );
};

AvatarPlaceholder.propTypes = {
    type: PropTypes.string,
    size: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    className: PropTypes.string
};

export default AvatarPlaceholder;
