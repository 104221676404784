import PropTypes from 'prop-types';
import React from 'react';
import DropdownItem from './DropdownItem';

class Dropdown extends React.Component {
    state = {
        isDropdownOpen: false
    };

    componentDidMount() {
        window.addEventListener('click', this.toggle);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.toggle);
    }

    toggle = e => {
        // hack for modals
        if (e.target.className === 'modal-dialog modal-dialog_active'
            || e.target.className === 'link-button filter-tags__link-button') {
            return null;
        }

        if (this.dropdownToggle === e.target.parentNode ||
            (e.target.parentNode !== null && this.dropdownToggle === e.target.parentNode.parentNode) ||
            this.dropdownToggle === e.target) {
            this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
            return null;
        }

        if (this.state.isDropdownOpen) {
            this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
        }

        return null;
    };

    render() {
        const { classNames = '', iconClass, icon: Icon, items, data, ButtonRenderer } = this.props;
        const { isDropdownOpen } = this.state;
        return (
            <div className={'dropdown ' + classNames}>
                {
                    !ButtonRenderer ?
                        <button
                            ref={(dropdown) => { this.dropdownToggle = dropdown; }}
                            className='dropdown__toggle'>
                            <Icon className={iconClass} />
                        </button>
                    :
                        <div
                            ref={(dropdown) => { this.dropdownToggle = dropdown; }}
                            className='dropdown__toggle'>
                            <ButtonRenderer/>
                        </div>
                }

                {isDropdownOpen &&
                <div ref={(dropdown) => { this.dropdownMenu = dropdown; }} className='dropdown__menu'>
                    {items.map((item, index) => {
                        const {
                            itemClass,
                            iconClass,
                            icon,
                            text,
                            isDisabled,
                            onClickCB,
                            link,
                            extraTextUpper,
                            extraTextLower } = item;
                        return (
                            <DropdownItem
                                key={`dropdownItem-${index}`}
                                itemClass={itemClass}
                                iconClass={iconClass}
                                icon={icon}
                                text={text}
                                link={link}
                                extraTextUpper={extraTextUpper}
                                extraTextLower={extraTextLower}
                                isDisabled={isDisabled === undefined ? false : isDisabled}
                                onClickCB={link ? undefined : () => onClickCB(data)}/>
                        );
                    })}
                </div>
                }
            </div>
        );
    }
}

Dropdown.propTypes = {
    classNames: PropTypes.string,
    iconClass: PropTypes.string,
    icon: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.shape({}),
    ButtonRenderer: PropTypes.func
};

export default Dropdown;
