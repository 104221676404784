import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as ErrorHandlerTypes from '../types/errorHandlerTypes';

const INITIAL_STATE = fromJS({
    errorText: null,
    errorType: null,
});

const errorHandlerReducer = handleActions({
    [ErrorHandlerTypes.SET_ERROR_TEXT]: (state, action) => {
        return state.set('errorText', action.payload);
    },
    [ErrorHandlerTypes.SET_ERROR_TYPE]: (state, action) => {
        return state.set('errorType', action.payload);
    },
    [ErrorHandlerTypes.CLEAR_ERROR_TEXT]: (state, action) => {
        return state.set('errorText', null);
    },
    [ErrorHandlerTypes.CLEAR_ERROR_TYPE]: (state, action) => {
        return state.set('errorType', null);
    },
}, INITIAL_STATE);

export default errorHandlerReducer;
