import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { getWeightOptions, getAccessLevelOptions } from '../../../../../../actions/miscActions';
import * as OpenActions from '../../../../../../actions/modal/openActions';
import ContentBlock from '../../../../../common/ContentBlock';

class DogInfo extends React.PureComponent {
    componentWillMount() {
        const { handleGetAccessLevelOptions, handleWeightOptions, accessLevelList, weightList } = this.props;

        if (!accessLevelList.length) {
            handleGetAccessLevelOptions();
        }
        if (!weightList.length) {
            handleWeightOptions();
        }
    }

    render() {
        const { dog, weightList, accessLevelList, openEditInfoModalDialog } = this.props;
        const dgInfo = dog.instance;
        if (!('id' in dgInfo) || dgInfo.id === undefined || !weightList.length) return null;
        const dogWeight = typeof dgInfo.weight !== 'object' ? dgInfo.weight : dgInfo.weight.value;
        const name = dgInfo.name;
        const breed = dgInfo.breed_detail;
        const gender = dgInfo.gender ? 'Female' : 'Male';
        const weight = weightList[dogWeight].label;
        const birthday = moment(dgInfo.birthday);
        const birthdayMonth = birthday.format('MMMM');
        const birthdayYear = birthday.format('YYYY');
        const isAltered = dgInfo.is_altered === true || dgInfo.is_altered === 'true' ? 'Yes' : 'No';
        const markings = dgInfo.markings;
        const accessLevel = accessLevelList.length ? accessLevelList[dgInfo.access_level].label : '';

        const items = [
            {
                header: 'Name',
                content: name,
            },
            {
                header: 'Breed',
                content: breed,
            },
            {
                header: 'Gender',
                content: gender,
            },
            {
                header: 'Weight',
                content: weight,
            },
            {
                header: 'Birthday',
                content: `${birthdayMonth}, ${birthdayYear}`,
            },
            {
                header: `${dgInfo.gender ? 'Spayed' : 'Neutered'}`,
                content: isAltered,
            },
            {
                header: 'Markings',
                content: markings,
            },
            {
                header: 'Access Level',
                headerContent: () => {
                    return (
                        <div className='link tooltip tooltip_mobile-right'>
                            What&apos;s this
                            <div className='tooltip__content'>Assigned based on your dog’s temperament and recall abilities.</div>
                        </div>
                    );
                },
                content: <div className='dog-details__access-level'>{accessLevel}</div>,
            },
        ];

        return (
            <ContentBlock
                title={'Dog Profile'}
                editAction={() => { openEditInfoModalDialog(dog); }}
                items={items}/>
        );
    }
}

DogInfo.propTypes = {
    dog: PropTypes.shape({}),
    weightList: PropTypes.arrayOf(PropTypes.shape({})),
    accessLevelList: PropTypes.arrayOf(PropTypes.shape({})),
    handleGetAccessLevelOptions: PropTypes.func,
    openEditInfoModalDialog: PropTypes.func,
    handleWeightOptions: PropTypes.func
};


const mapStateToProps = state => {
    return {
        weightList: state.getIn(['misc', 'options', 'weight']).toJS(),
        accessLevelList: state.getIn(['misc', 'options', 'accessLevel']).toJS(),
    };
};


const mapDispatchToProps = dispatch => ({
    handleGetAccessLevelOptions: bindActionCreators(getAccessLevelOptions, dispatch),
    openEditInfoModalDialog: bindActionCreators(OpenActions.openCustomerEditDogInfoModalDialog, dispatch),
    handleWeightOptions: bindActionCreators(getWeightOptions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DogInfo);
