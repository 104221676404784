import React from 'react';
import PropTypes from 'prop-types';
import DropZone from 'react-dropzone';
import classNames from 'classnames';
import { sendFile } from '../../../../services/file';

class FileControlUploadButton extends React.PureComponent {
    static propTypes = {
        uploadButton: PropTypes.node,
        onCompleteCallback: PropTypes.func,
        uploadUrl: PropTypes.string
    };

    state = {
        uploadProgress: 100
    };

    onDrop = (acceptedFiles) => {
        const { uploadUrl, onCompleteCallback } = this.props;
        sendFile({
            currentFile: acceptedFiles[0],
            url: uploadUrl,
            progressCallback: this.onUploadProgress,
            completeCallback: onCompleteCallback
        });
    };

    onUploadProgress = (event) => {
        this.setState({
            uploadProgress: Math.round((event.loaded * 100) / event.total)
        });
    };

    getUploadIndicatior = () => {
        if (this.state.uploadProgress < 100) {
            return (
                <div className='file-control-upload-button__upload-indicator-container'>
                    <div className='file-control-upload-button__upload-indicator'>
                        Uploading {this.state.uploadProgress}%
                    </div>
                </div>
            );
        }

        return null;
    };

    render() {
        const { uploadButton = null } = this.props;
        const uploadButtonContainerClassNames = classNames({
            'file-control-upload-button__button-container': true,
            'hidden': this.state.uploadProgress < 100
        });
        return (
            <DropZone
                name={'vaccination'}
                className={'file-control-upload-button'}
                maxSize={25 * 1024 * 1024}
                accept='image/jpeg, image/png, image/jpeg, image/tiff, application/pdf'
                disablePreview
                multiple={false}
                onDrop={this.onDrop}>
                <div className={uploadButtonContainerClassNames} style={{ position: 'relative' }}>
                    {uploadButton}
                    {this.getUploadIndicatior()}
                </div>
            </DropZone>
        );
    }
}

export default FileControlUploadButton;
