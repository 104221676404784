import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as classesActions from '../../../../../actions/dashboard/classesActions';
import * as miscActions from '../../../../../actions/miscActions';
import * as openActions from '../../../../../actions/modal/openActions';
import ClassesContent from './ClassesContent';

const ClassesPage = (props) => {
    const {
        currentCustomer, misc, handleUpcomingCollapse, handlePreviousCollapse, handleEditingUpcomingClass,
        handleEditingPreviousClassStatus, handleEditingUpcomingClassStatus, handleOpeningCancelUpcomingClassModal,
        handleCancellingUpcomingClass, handleSettingClassEmptyReports, handleOpeningCreateDogReportModalDialog,
        handleOpeningEditDogReportModalDialog, handleGetNextPreviousClasses, handleGetNextUpcomingClasses
    } = props;
    const upcomingClassesActions = {
        'edit': handleEditingUpcomingClass,
        'attended': handleEditingUpcomingClassStatus,
        'cancel': handleCancellingUpcomingClass,
        'cancelWithFee': handleOpeningCancelUpcomingClassModal,
        'no-show': handleEditingUpcomingClassStatus
    };
    const previousClassesActions = {
        'attended': handleEditingPreviousClassStatus,
        'cancel': handleEditingPreviousClassStatus,
        'no-show': handleEditingPreviousClassStatus,
        'assess-fee': handleEditingPreviousClassStatus,
        'waive-fee': handleEditingPreviousClassStatus,
        'delete-report': handleSettingClassEmptyReports,
        'create-report': handleOpeningCreateDogReportModalDialog,
        'edit-report': handleOpeningEditDogReportModalDialog,
    };
    return (
        <div className='customers-classes'>
            {currentCustomer.getIn(['instance', 'id']) === undefined
                ? 'Loading...'
                : (<div className='customers-classes__content'>
                    <ClassesContent
                        data={currentCustomer.getIn(['upcoming_classes', 'classes'])}
                        classNameModifier='classes-table_upcoming'
                        dataName='Upcoming Classes'
                        isCollapsed={misc.get('upcomingClasses')}
                        isLoading={currentCustomer.getIn(['upcoming_classes', 'isLoading'])}
                        collapseAction={handleUpcomingCollapse}
                        classesActions={upcomingClassesActions} 
                        pageSize={currentCustomer.getIn(['upcoming_classes', 'pageSize'])}
                        pages={currentCustomer.getIn(['upcoming_classes', 'pages'])}
                        nextUrl={currentCustomer.getIn(['upcoming_classes', 'nextUrl'])}
                        getNext={handleGetNextUpcomingClasses}/>
                    <ClassesContent
                        data={currentCustomer.getIn(['previous_classes', 'classes'])}
                        dataName='Previous Classes'
                        isPrevious
                        classNameModifier='classes-table_previous'
                        isCollapsed={misc.get('previousClasses')}
                        isLoading={currentCustomer.getIn(['previous_classes', 'isLoading'])}
                        collapseAction={handlePreviousCollapse}
                        classesActions={previousClassesActions}
                        pageSize={currentCustomer.getIn(['previous_classes', 'pageSize'])}
                        pages={currentCustomer.getIn(['previous_classes', 'pages'])}
                        nextUrl={currentCustomer.getIn(['previous_classes', 'nextUrl'])}
                        getNext={handleGetNextPreviousClasses}/>
                </div>)
            }
        </div>
    );
};

ClassesPage.propTypes = {
    currentCustomer: PropTypes.shape({}),
    misc: PropTypes.shape({}),
    handleUpcomingCollapse: PropTypes.func,
    handlePreviousCollapse: PropTypes.func,
    handleEditingUpcomingClass: PropTypes.func,
    handleEditingPreviousClassStatus: PropTypes.func,
    handleEditingUpcomingClassStatus: PropTypes.func,
    handleOpeningCancelUpcomingClassModal: PropTypes.func,
    handleCancellingUpcomingClass: PropTypes.func,
    handleSettingClassEmptyReports: PropTypes.func,
    handleOpeningCreateDogReportModalDialog: PropTypes.func,
    handleOpeningEditDogReportModalDialog: PropTypes.func,
    handleGetNextPreviousClasses: PropTypes.func,
    handleGetNextUpcomingClasses: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        currentCustomer: state.get('currentCustomer'),
        misc: state.getIn(['misc', 'tablesCollapsed', 'customers'])
    };
};

const mapDispatchToProps = dispatch => ({
    handleUpcomingCollapse: bindActionCreators(miscActions.collapseCustomersUpcomingClasses, dispatch),
    handlePreviousCollapse: bindActionCreators(miscActions.collapseCustomersPreviousClasses, dispatch),
    handleEditingUpcomingClass: bindActionCreators(openActions.openEditUpcomingClassModalDialog, dispatch),
    handleEditingPreviousClassStatus: bindActionCreators(classesActions.patchCustomersPreviousClassStatus, dispatch),
    handleEditingUpcomingClassStatus: bindActionCreators(classesActions.markCustomersUpcomingClass, dispatch),
    handleOpeningCancelUpcomingClassModal: bindActionCreators(openActions.openCancelCustomersUpcomingClassModalDialog,
        dispatch),
    handleCancellingUpcomingClass: bindActionCreators(classesActions.markCustomersUpcomingClass, dispatch),
    handleSettingClassEmptyReports: bindActionCreators(classesActions.setClassEmptyReports, dispatch),
    handleOpeningCreateDogReportModalDialog: bindActionCreators(openActions.openCreateDogReportModalDialog, dispatch),
    handleOpeningEditDogReportModalDialog: bindActionCreators(openActions.openEditDogReportModalDialog, dispatch),
    handleGetNextPreviousClasses: bindActionCreators(classesActions.getNextCustomersPreviousClasses, dispatch),
    handleGetNextUpcomingClasses: bindActionCreators(classesActions.getNextCustomersUpcomingClasses, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassesPage);
