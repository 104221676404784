import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ViewLink from '../../../../../common/ViewLink/';
import AvatarUploader from '../../../../../common/AvatarUploader/';

const DogsCard = ({ data, accessLevelList }) => {
    DogsCard.propTypes = {
        data: PropTypes.shape({}),
        accessLevelList: PropTypes.arrayOf(PropTypes.shape({})),
    };

    const photo = data.photo_processed || data.photo;
    const name = data.name;
    const id = data.id;
    const breed = data.breed_detail;
    const access = accessLevelList[data.access_level].label;
    const status = data.assessment_status;
    const statusText = data.assessment_status_detail;

    const getStatus = () => {
        const className = classNames({
            'dogs-card__item__content': true,
            'dogs-card__item__content_ready': status === 'AVAILABLE_SCHEDULE',
            'dogs-card__item__content_link': status === 'REQUIRES_EVALUATION',
            'dogs-card__item__content_warning': status === 'VACCINATION_REMINDER',
            'dogs-card__item__content_error': status === 'IMMUNIZATION',
        });
        if (status === 'REQUIRES_EVALUATION') return <Link to='/classes' className={className}>{ statusText }</Link>;
        if (status === 'IMMUNIZATION') return <Link to={`/dogs/${id}`} className={className}>{statusText}</Link>;
        return <div className={className}>{ statusText }</div>;
    };

    return (
        <div className='dogs-card'>

            <div className='dogs-card__header'>
                <AvatarUploader photo={photo} target='dog' customerDogId={id} />
            </div>

            <div className='dogs-card__content'>
                <div className='dogs-card__headline'>
                    <div className='dogs-card__title'>{ name }</div>
                    <div className='dogs-card__subtitle'>{ breed }</div>
                </div>

                <div className='dogs-card__list'>
                    <div className='dogs-card__item'>
                        <div className='dogs-card__item__title'>Access Level</div>
                        <div className='dogs-card__item__content'>{ access }</div>
                    </div>
                    <div className='dogs-card__item'>
                        <div className='dogs-card__item__title'>Status</div>
                        { getStatus() }
                    </div>
                </div>
            </div>

            <Link to={`/dogs/${id}`} className='dogs-card__link'/>

            <div className='dogs-card__view'>
                <ViewLink text='View profile' link={`/dogs/${id}`} />
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        accessLevelList: state.getIn(['misc', 'options', 'accessLevel']).toJS(),
    };
};

export default connect(mapStateToProps, null)(DogsCard);
