import * as axios from 'axios';
import { reset, startSubmit, stopSubmit } from 'redux-form';
import {
    finishLoadingCurrentCustomer,
    finishLoadingCurrentDog,
    startLoadingCurrentCustomer,
    startLoadingCurrentDog
} from '../actions/dashboard/employeeActions';
import {
    addCurrentCustomerNewNotesIncidents,
    addCurrentDogNewNotesIncidents,
    markCustomersNotesIncidentsLoaded,
    markDogsNotesIncidentsLoaded,
    setCurrentCustomerNotesIncidents,
    setCurrentDogNotesIncidents
} from '../actions/dashboard/notesIncidentsActions';
import { setGlobalError } from '../actions/errorHandlerActions';
import { getUserNameSpace } from '../helpers/userRoles';

export const getNotesIncidents = ({ context, id }) => {
    return (dispatch) => {
        return axios.get(`/v1/${getUserNameSpace(context + '.notes-incidents')}/${context}/${id}/notes-incidents/`)
                    .then(response => {
                        if (context === 'customers') {
                            dispatch(startLoadingCurrentCustomer());
                            dispatch(setCurrentCustomerNotesIncidents(response.data.results));
                            dispatch(finishLoadingCurrentCustomer());
                            dispatch(markCustomersNotesIncidentsLoaded());
                        }
                        if (context === 'dogs') {
                            dispatch(startLoadingCurrentDog());
                            dispatch(setCurrentDogNotesIncidents(response.data.results));
                            dispatch(finishLoadingCurrentDog());
                            dispatch(markDogsNotesIncidentsLoaded());
                        }
                    })
                    .catch(err => {
                        if (context === 'customers') {
                            dispatch(finishLoadingCurrentCustomer());
                        }
                        if (context === 'dogs') {
                            dispatch(finishLoadingCurrentDog());
                        }
                        console.log(err);
                        console.log(err.response);
                        dispatch(setGlobalError(err));
                    });
    };
};

export const postNotesIncidents = ({ context, id, data }) => {
    return (dispatch) => {
        const request = new FormData();
        request.append('body', data.get('notes'));
        if (data.get('tag') !== undefined) {
            request.append('tag', data.get('tag').value);
        }
        dispatch(startSubmit('notesForm'));
        return axios.post(`/v1/${getUserNameSpace(context + '.notes-incidents')}/${context}/${id}/notes-incidents/`, request)
                    .then((response) => {
                        if (context === 'customers') {
                            dispatch(addCurrentCustomerNewNotesIncidents(response.data));
                            dispatch(reset('customerNotesForm'));
                        }
                        if (context === 'dogs') {
                            dispatch(addCurrentDogNewNotesIncidents(response.data));
                            dispatch(reset('dogNotesForm'));
                        }
                    })
                    .catch((error) => {
                        if (context === 'customers') {
                            dispatch(stopSubmit('customerNotesForm'));
                        }
                        if (context === 'dogs') {
                            dispatch(stopSubmit('dogNotesForm'));
                        }
                        console.log(error);
                        console.log(error.response);
                        dispatch(setGlobalError(error));
                    });
    };
};
