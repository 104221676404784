import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { ReactComponent as IconCancel } from '../../../../../assets/icons/icon-cancel.svg';
import { ReactComponent as IconCheckMark } from '../../../../../assets/icons/icon-checkmark.svg';
import { ReactComponent as IconDots } from '../../../../../assets/icons/icon-dots.svg';
import {
    createEventAttendingDogsDropdownItems,
    shouldDisplayEventProductDropdown
} from '../../../../../helpers/dropdownItems';
import Dropdown from '../../../../common/Dropdown';
import AttendedIcon from '../../../../common/AttendedIcon';
import Icon from '../../../../common/Icon/Icon';
import Table from '../../../../common/Table';
import { prettifyPhone } from '../../../../../helpers/normalize';

class AttendingDogsTable extends Table {
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.shape({})),
        eventData: PropTypes.shape({}),
        attendingDogsActions: PropTypes.shape({}),
    };

    cellClassName = 'event-detail-table__td';

    formatAddress = row => {
        return `${row.value.address1}${row.value.address2 ? (' ' + row.value.address2) : ''}, ${row.value.city} ${row.value.state} ${row.value.zip_code}`;
    };

    formatLocationNotes = row => {
        return row.value.location_notes ? `${row.value.location_notes}` : <div className='no-info'>—</div>
    };

    renderAttendingDogsTable = columns => {
        const { data, eventData, attendingDogsActions } = this.props;

        if (data === undefined) {
            return (
                <div className='forms-table__empty-content'>
                    Loading...
                </div>
            );
        }

        if (!data.length) {
            return (
                <div className='forms-table__empty-content'>
                    No dogs signed up for this event.
                </div>
            );
        }

        const dropDownColumn = {
            id: 'dropdown',
            Header: '',
            headerClassName: 'classes-table__th',
            className: this.cellClassName,
            width: 64,
            resizable: false,
            sortable: false,
            accessor: d => d,
            Cell: row => {
                if (!shouldDisplayEventProductDropdown(row.value)) {
                    return null;
                }
                
                return (<Dropdown
                    data={row.value}
                    classNames='dropdown_table'
                    icon={IconDots}
                    iconClass='icon_chevron_down'
                    items={createEventAttendingDogsDropdownItems({
                        eventData, rowData: row.value, actions: attendingDogsActions, context: 'dogs'
                    })}/>);
            }
        };

        columns.push(dropDownColumn);

        const { isScrollable } = this.state;
        return this.renderTable({ isScrollable, columns });
    };

    render() {
        const { eventData } = this.props;

        const reportCardColumn = {
            id: 'reportCard',
            Header: 'Snapshot',
            headerClassName: 'forms-table__th',
            className: this.cellClassName,
            accessor: d => d.reports,
            Cell: row => row.value.length
                ? <Icon glyph={IconCheckMark} className='icon_check-mark'/>
                : <Icon glyph={IconCancel} className='icon_cancel'/>
        };

        const columns = [
            {
                id: 'dog',
                Header: 'Dog',
                headerClassName: 'forms-table__th',
                className: this.cellClassName,
                accessor: d => d.dog_detail.name,
                Cell: (row) => <Link to={`/dashboard/dog/${row.original.dog}/details`}>{row.value}</Link>,
            },
            {
                id: 'owner',
                Header: 'Owner',
                headerClassName: 'forms-table__th',
                className: this.cellClassName,
                accessor: d => d.owner_detail.full_name,
                Cell: row => row.value
            },
            {
                id: 'phone',
                Header: 'Phone',
                headerClassName: 'forms-table__th',
                className: this.cellClassName,
                accessor: d => d.owner_detail.primary_phone ? prettifyPhone(d.owner_detail.primary_phone.phone_number) : <div className='no-info'>—</div>,
                Cell: row => row.value
            },
            {
                id: 'pickup',
                Header: 'Pickup',
                headerClassName: 'forms-table__th',
                className: this.cellClassName,
                accessor: d => d.pickup_location_detail,
                Cell: row => this.formatAddress(row)
            },
            {
                id: 'pickup_location_notes',
                Header: 'Pickup Notes',
                headerClassName: 'forms-table__th',
                className: this.cellClassName,
                accessor: d => d.pickup_location_detail,
                Cell: row => this.formatLocationNotes(row)
            },
            {
                id: 'dropoff',
                Header: 'Dropoff',
                headerClassName: 'forms-table__th',
                className: this.cellClassName,
                accessor: d => d.drop_off_location_detail,
                Cell: row => this.formatAddress(row)
            },
            {
                id: 'dropoff_location_notes',
                Header: 'Dropoff Notes',
                headerClassName: 'forms-table__th',
                className: this.cellClassName,
                accessor: d => d.drop_off_location_detail,
                Cell: row => this.formatLocationNotes(row)
            },
            {
                id: 'attending',
                Header: 'Attending/Cancelled',
                headerClassName: 'forms-table__th',
                className: this.cellClassName,
                accessor: d => d.extended_status,
                Cell: row => <AttendedIcon status={row.row.dropdown.status}/>
            }
        ];

        if (eventData.type === 'class') {
            columns.push(reportCardColumn);
        }

        return (
            <div className='attending-dogs-tabe'>
                {this.renderAttendingDogsTable(columns)}
            </div>
        );
    }
}

export default AttendingDogsTable;
