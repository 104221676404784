import React, { PureComponent } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router';
import Select from 'react-select';
import { profileLinks } from './profileLinks';
import { dispatchPush } from '../../../../../actions/miscActions';

class ProfileNav extends PureComponent {
    state = {
        activeLink: this.props.pathname.replace(/\/$/, ''), // '/profile/' => '/profile'
        pinned: false
    };

    componentDidMount() {
        this.scrollListenerThrottled = _.throttle(this.scrollListener, 50);
        window.addEventListener('scroll', this.scrollListenerThrottled);
        this.scrollListener();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollListenerThrottled);
    }

    scrollListener = () => {
        const { pinned } = this.state;
        const navScrollTop = this.nav.getBoundingClientRect().top;
        if (navScrollTop <= 0 && !pinned) {
            this.setState({ pinned: true });
        } else if (navScrollTop > 0 && pinned) {
            this.setState({ pinned: false });
        }
    }

    render() {
        const { dispatchPush } = this.props;
        const { pinned, activeLink } = this.state;
        const profileClassName = classNames({
            'profile-nav': true,
            'profile-nav_pinned': pinned
        });
        const options = profileLinks.map((link) => {
            return {
                label: link.text,
                value: link.to,
            };
        });

        return (
            <div className={profileClassName} ref={nav => { this.nav = nav; }}>
                <div className='profile-nav__inner'>
                    <Select
                        options={options}
                        searchable={false}
                        className='profile-nav__select simple-select'
                        onChange={data => {
                            this.setState({ activeLink: data.value });
                            dispatchPush(data.value);
                        }}
                        value={activeLink} />
                    <ul className='profile-nav__list'>
                        {
                            profileLinks.map((link, index) => {
                                const linkClassName = classNames({
                                    'profile-nav__link': true,
                                    'profile-nav__link_active': this.state.activeLink === link.to,
                                });
                                return (
                                    <li className='profile-nav__item' key={index}>
                                        <Link
                                            to={link.to}
                                            className={linkClassName}
                                            onClick={() => {
                                                this.setState({ activeLink: link.to });
                                            }}>
                                            {link.text}
                                        </Link>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </div>
        );
    }
}

ProfileNav.propTypes = {
    dispatchPush: PropTypes.func,
    pathname: PropTypes.string
};

const mapStateToProps = state => {
    return {
        pathname: state.getIn(['routing', 'locationBeforeTransitions']).pathname,
    };
};

const mapDispatchToProps = {
    dispatchPush
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileNav);
