import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { ReactComponent as IconDots } from '../../../../../../assets/icons/icon-dots.svg';
import { ReactComponent as IconDownload } from '../../../../../../assets/icons/icon-download.svg';
import { ReactComponent as IconDocPdf } from '../../../../../../assets/icons/icon-pdf.svg';
import { ReactComponent as IconDocImg } from '../../../../../../assets/icons/icon-png.svg';
import { ReactComponent as IconTrash } from '../../../../../../assets/icons/icon-trash.svg';
import { ReactComponent as IconView } from '../../../../../../assets/icons/icon-view.svg';
import { getDocumentName } from '../../../../../../helpers/docs';
import Dropdown from '../../../../../common/Dropdown';
import Icon from '../../../../../common/Icon';
import { formatDateToSlashes } from '../../../../../../helpers/date';

const VaccinationsTable = ({ data, dataName, currentCustomer, openVaccinationDeleteModalDialog, readOnly }) => {
    const DOC_TYPES = {
        'JPG': <Icon glyph={IconDocImg} className='icon_doc-img'/>,
        'PNG': <Icon glyph={IconDocImg} className='icon_doc-img'/>,
        'JPEG': <Icon glyph={IconDocImg} className='icon_doc-img'/>,
        'TIFF': <Icon glyph={IconDocImg} className='icon_doc-img'/>,
        'PDF': <Icon glyph={IconDocPdf} className='icon_doc-pdf'/>
    };
    const dropdownItems = [
        {
            iconClass: 'icon_view',
            icon: IconView,
            text: 'Open/View',
            onClickCB: rowData => {
                window.open(rowData.get('doc_file'), '_blank');
            }
        },
        {
            iconClass: 'icon_download',
            icon: IconDownload,
            text: 'Download',
            onClickCB: rowData => {
                const link = document.createElement('a');
                link.href = rowData.get('doc_file');
                link.setAttribute('download', '');
                link.click();
            }
        },
        {
            iconClass: 'icon_trash',
            icon: IconTrash,
            text: 'Delete',
            onClickCB: rowData => { openVaccinationDeleteModalDialog(rowData, currentCustomer); }
        }
    ];
    const columns = [
        {
            id: 'vaccinationsName',
            Header: 'Name',
            headerClassName: 'vaccinations-table__th',
            className: 'vaccinations-table__td',
            sortMethod: (a, b) => {
                a = a.get('document_file_name');
                b = b.get('document_file_name');

                if (a > b) {
                    return 1;
                }

                if (a < b) {
                    return -1;
                }

                return 0;
            },
            accessor: d => d,
            Cell: row => {
                return (
                    <div className='vaccinations-table__doc-name'>
                        {DOC_TYPES[row.value.get('document_type')]}
                        <div className='vaccinations-table__doc-name-text'>
                            {getDocumentName(row.value.get('document_file_name'))}
                        </div>
                    </div>
                );
            }
        },
        {
            id: 'vaccinationsType',
            Header: 'Document Type',
            headerClassName: 'vaccinations-table__th',
            className: 'vaccinations-table__td',
            accessor: d => d.get('document_type')
        },
        {
            id: 'vaccinationsDateUploaded',
            Header: 'Uploaded Date',
            headerClassName: 'vaccinations-table__th',
            className: 'vaccinations-table__td',
            accessor: d => d.get('modified_at'),
            Cell: row => <div>{formatDateToSlashes(row.value, true)}</div>
        }
    ];

    if (!readOnly) {
        columns.push({
            id: 'vaccinationsEdit',
            Header: '',
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            width: 50,
            accessor: d => d,
            Cell: row => (
                <Dropdown
                    data={row.value}
                    classNames='dropdown_table'
                    icon={IconDots}
                    iconClass='icon_chevron_down'
                    items={dropdownItems}/>
            )
        });
    }
    return (
        <div className='vaccinations-table'>
            <div className='vaccinations-table__header'>
                <div className='vaccinations-table__header-content'>
                    {dataName}
                </div>
            </div>
            {data.size
                ? (<ReactTable
                    showPagination={false}
                    defaultPageSize={data.length}
                    data={data}
                    columns={columns}/>)
                : (<div className='vaccinations-table__empty-content'>
                    {`No data for ${dataName}`}
                </div>)
            }
        </div>
    );
};

VaccinationsTable.propTypes = {
    data: PropTypes.shape({}).isRequired,
    dataName: PropTypes.string.isRequired,
    currentCustomer: PropTypes.shape({}),
    openVaccinationDeleteModalDialog: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
};

export default VaccinationsTable;
