/* eslint-disable max-len */
import { push } from 'react-router-redux';
import { createAction } from 'redux-actions';
import { getCustomersAgreements } from '../../services/agreements';
import { getCustomerById, changeCustomerData } from '../../services/customers';
import { getCustomersPaymentCards } from '../../services/paymentCards';
import * as EmployeeTypes from '../../types/dashboard/employeeTypes';
import { unmarkFormsLoaded } from '../dashboard/formsActions';
import * as ClassesActions from './classesActions';
import * as TrainingsActions from './trainingActions';
import * as DogReportsActions from './dogReportsActions';
import * as NotesIncidentsActions from './notesIncidentsActions';
import {
    getCustomersPackagesTrainingProducts,
    unmarkCustomersPackagesTrainingProductsLoaded
} from './packagesTrainingsProductsActions';
import { unmarkPaymentCardsLoaded } from './paymentCardsAction';
import { getCustomersPurchaseHistory, unmarkCustomerPurchaseHistoryLoaded } from './purchaseHistoryActions';
import * as VaccinationRecordsActions from './vaccinationRecordsActions';
import { isTrainer } from '../../helpers/userRoles';

export const setBreeds = createAction(EmployeeTypes.SET_BREEDS);

export const setCustomers = createAction(EmployeeTypes.SET_CUSTOMERS);
export const setNextCustomers = createAction(EmployeeTypes.SET_NEXT_CUSTOMERS);
export const startLoadingCustomers = createAction(EmployeeTypes.START_CUSTOMERS_LOADING);
export const finishLoadingCustomers = createAction(EmployeeTypes.FINISH_CUSTOMERS_LOADING);

export const removeCustomerFromList = createAction(EmployeeTypes.REMOVE_CUSTOMER_FROM_LIST);

export const setCurrentCustomerData = createAction(EmployeeTypes.SET_CURRENT_CUSTOMER_DATA);
export const clearCurrentCustomerData = createAction(EmployeeTypes.CLEAR_CURRENT_CUSTOMER_DATA);
export const startLoadingCurrentCustomer = createAction(EmployeeTypes.START_CURRENT_CUSTOMER_LOADING);
export const finishLoadingCurrentCustomer = createAction(EmployeeTypes.FINISH_CURRENT_CUSTOMER_LOADING);

export const prependDogs = createAction(EmployeeTypes.PREPEND_DOGS);
export const appendDogs = createAction(EmployeeTypes.APPEND_DOGS);
export const removeDogFromList = createAction(EmployeeTypes.REMOVE_DOG_FROM_LIST);

export const unmarkCustomersDataLoaded = () => {
    return async dispatch => {
        const PreviousClasses = dispatch(ClassesActions.unmarkCustomersPreviousClassesLoaded());
        const PreviousTrainings = dispatch(TrainingsActions.unmarkCustomersPreviousTrainingsLoaded());
        const UpcomingClasses = dispatch(ClassesActions.unmarkCustomersUpcomingClassesLoaded());
        const UpcomingTrainings = dispatch(TrainingsActions.unmarkCustomersUpcomingTrainingsLoaded());
        const PaymentCards = dispatch(unmarkPaymentCardsLoaded());
        const NotesIncidents = dispatch(NotesIncidentsActions.unmarkCustomersNotesIncidentsLoaded());
        const Agreements = dispatch(unmarkFormsLoaded());
        const VaccinationRecords = dispatch(VaccinationRecordsActions.unmarkCustomerVaccinationsLoaded());
        const PackagesAndTrainings = dispatch(unmarkCustomersPackagesTrainingProductsLoaded());
        const PurchaseHistory = dispatch(unmarkCustomerPurchaseHistoryLoaded());

        await PaymentCards;
        await PreviousClasses;
        await PreviousTrainings;
        await UpcomingClasses;
        await UpcomingTrainings;
        await NotesIncidents;
        await Agreements;
        await VaccinationRecords;
        await PackagesAndTrainings;
        await PurchaseHistory;
    };
};

export const getCustomersData = (customerId) => {
    return async dispatch => {
        dispatch(ClassesActions.getCustomersPreviousClasses(customerId));
        dispatch(ClassesActions.getCustomersUpcomingClasses(customerId));
        dispatch(TrainingsActions.getCustomersPreviousTrainings(customerId));
        dispatch(TrainingsActions.getCustomersUpcomingTrainings(customerId));
        dispatch(getCustomersPaymentCards(customerId));
        dispatch(NotesIncidentsActions.getCustomersNotesIncidents(customerId));
        dispatch(getCustomersAgreements(customerId));
        dispatch(VaccinationRecordsActions.getCustomersVaccinations(customerId));
        dispatch(getCustomersPackagesTrainingProducts(customerId));
        if (!isTrainer()) {
            dispatch(getCustomersPurchaseHistory({ customerId }));
        }
    };
};

export const setCurrentCustomer = (customerData) => {
    return async (dispatch) => {
        await dispatch(unmarkCustomersDataLoaded());
        await dispatch(getCustomersData(customerData.get('id')));
        await dispatch(setCurrentCustomerData(customerData));
    };
};

export const setCurrentCustomerFromCustomersList = (customerData) => {
    return (dispatch) => {
        dispatch(clearCurrentCustomerData());
        dispatch(setCurrentCustomer(customerData));
        dispatch(push(`/dashboard/customer/${customerData.get('id')}/details`));
    };
};

export const setCurrentCustomerFromDogsList = (customersId) => {
    return (dispatch) => {
        dispatch(getCustomerById(customersId));
        dispatch(push(`/dashboard/customer/${customersId}/details`));
    };
};

export const setDogs = createAction(EmployeeTypes.SET_DOGS);
export const setNextDogs = createAction(EmployeeTypes.SET_NEXT_DOGS);
export const startLoadingDogs = createAction(EmployeeTypes.START_DOGS_LOADING);
export const finishLoadingDogs = createAction(EmployeeTypes.FINISH_DOGS_LOADING);

export const setCurrentDogData = createAction(EmployeeTypes.SET_CURRENT_DOG_DATA);
export const setErrorSubmittingDogEvaluation = createAction(EmployeeTypes.SET_ERROR_SUBMITTING_DOG_EVALUATION);
export const clearCurrentDogData = createAction(EmployeeTypes.CLEAR_CURRENT_DOG_DATA);
export const updateCurrentDogData = createAction(EmployeeTypes.UPDATE_CURRENT_DOG_DATA);
export const startLoadingCurrentDog = createAction(EmployeeTypes.START_CURRENT_DOG_LOADING);
export const finishLoadingCurrentDog = createAction(EmployeeTypes.FINISH_CURRENT_DOG_LOADING);

export const unmarkDogsDataLoaded = () => {
    return async dispatch => {
        const PreviousClasses = dispatch(ClassesActions.unmarkDogsPreviousClassesLoaded());
        const PreviousTrainings = dispatch(TrainingsActions.unmarkDogsPreviousTrainingsLoaded());
        const UpcomingClasses = dispatch(ClassesActions.unmarkDogsUpcomingClassesLoaded());
        const UpcomingTrainings = dispatch(TrainingsActions.unmarkDogsUpcomingTrainingsLoaded());
        const NotesIncidents = dispatch(NotesIncidentsActions.unmarkDogsNotesIncidentsLoaded());
        const VaccinationRecords = dispatch(VaccinationRecordsActions.unmarkDogVaccinationsLoaded());

        await PreviousClasses;
        await PreviousTrainings;
        await UpcomingClasses;
        await UpcomingTrainings;
        await NotesIncidents;
        await VaccinationRecords;
    };
};

export const getDogsData = (dogId) => {
    return async dispatch => {
        dispatch(ClassesActions.getDogsPreviousClasses(dogId));
        dispatch(TrainingsActions.getDogsPreviousTrainings(dogId));
        dispatch(ClassesActions.getDogsUpcomingClasses(dogId));
        dispatch(TrainingsActions.getDogsUpcomingTrainings(dogId));
        dispatch(NotesIncidentsActions.getDogsNotesIncidents(dogId));
        if (!isTrainer()) {
            dispatch(VaccinationRecordsActions.getDogsVaccinations(dogId));
        }
        dispatch(DogReportsActions.getReports(dogId));
    };
};

export const setCurrentDog = (dogData) => {
    return async (dispatch) => {
        await dispatch(unmarkDogsDataLoaded());
        await dispatch(getDogsData(dogData.get('id')));
        await dispatch(setCurrentDogData(dogData));
    };
};

export const customerSetCurrentDog = (dogData) => {
    return async (dispatch) => {
        await dispatch(setCurrentDogData(dogData));
    };
};

export const setCurrentDogFromDogsList = (dogData) => {
    return (dispatch) => {
        dispatch(clearCurrentDogData());
        dispatch(setCurrentDog(dogData));
        dispatch(push(`/dashboard/dog/${dogData.get('id')}/details`));
    };
};

export const addCustomerToCustomersList = createAction(EmployeeTypes.ADD_CUSTOMER_TO_CUSTOMERS_LIST);
export const setCustomerNotification = createAction(EmployeeTypes.SET_CUSTOMER_NOTIFICATION);
export const setSubscribedToEmail = createAction(EmployeeTypes.SET_SUBSCRIBED_TO_EMAIL);

export const updateCustomerNotification = ({ id, isActive }) => {
    return async (dispatch, getState) => {
        const settings = getState().getIn(['currentCustomer', 'instance', 'settings']).toJS();
        settings[id] = isActive;
        await dispatch(changeCustomerData({ settings })).then(() => {
            dispatch(setCustomerNotification({ id, isActive }));
        });
    };
};

export const updateSubscribedToEmail = (subscribedToEmail) => {
    return async (dispatch, getState) => {
        if (!subscribedToEmail) {
            const settings = getState().getIn(['currentCustomer', 'instance', 'settings']).toJS();

            for (const setting in settings) {
                settings[setting] = false;
            }

            await dispatch(changeCustomerData({ settings, subscribed_to_email: subscribedToEmail }))
                .then(() => {
                    for (const setting in settings) {
                        dispatch(setCustomerNotification({ id: setting, isActive: false }));
                    }
                    dispatch(setSubscribedToEmail({ subscribedToEmail }));
                });
        } else {
            await dispatch(changeCustomerData({ subscribed_to_email: subscribedToEmail }))
                .then(() => {
                    dispatch(setSubscribedToEmail({ subscribedToEmail }));
                });
        }
    };
}