export const SET_MESSAGES_WIDGET_OPENED = 'SET_MESSAGES_WIDGET_OPENED';
export const SET_MESSAGES_WIDGET_CLOSED = 'SET_MESSAGES_WIDGET_CLOSED';
export const SET_MESSAGES_WIDGET_MINIMIZED = 'SET_MESSAGES_WIDGET_MINIMIZED';
export const SET_MESSAGES_LOADED = 'SET_MESSAGES_LOADED';
export const SET_MESSAGES_LOADING = 'SET_MESSAGES_LOADING';
export const SET_MESSAGES_LOADING_MORE = 'SET_MESSAGES_LOADING_MORE';
export const SET_MESSAGES_SUBMITTING = 'SET_MESSAGES_SUBMITTING';
export const SET_MESSAGES_FAILED = 'SET_MESSAGES_FAILED';
export const SET_MESSAGES_DATA = 'SET_MESSAGES_DATA';
export const ADD_MESSAGE_TO_MESSAGES_ITEMS = 'ADD_MESSAGE_TO_MESSAGES_ITEMS';
export const SET_NEXT_MESSAGES_DATA = 'SET_NEXT_MESSAGES_DATA';
export const UPDATE_MESSAGE_DATA_BY_ID = 'UPDATE_MESSAGE_DATA_BY_ID';
export const UPDATE_MESSAGES_DATA = 'UPDATE_MESSAGES_DATA';
export const SET_MESSAGES_POLLING = 'SET_MESSAGES_POLLING';
