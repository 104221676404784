import PropTypes from 'prop-types';
import React from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';
import { isLandscapeOrientation } from '../../../../../../helpers/device';

class BookingsByTrainerCustomTooltip extends React.PureComponent {
    static propTypes = {
        payload: PropTypes.arrayOf(PropTypes.shape({
            payload: PropTypes.shape({
                trainer: PropTypes.string,
            }),
        })),
        active: PropTypes.bool,
    };

    render() {
        const { active } = this.props;

        if (active) {
            return <div className='chart__tooltip'>{this.props.payload[0].payload.trainer}</div>;
        }

        return null;
    }
}

const BookingsByTrainer = ({ data, width, height }) => {
    const isTablet = window.innerWidth <= 1024 && window.innerWidth > 736;
    const isMobile = window.innerWidth <= 736;
    // ration is X in - container width / 100 = device chart width due to mock up / X
    const desktopChartRation = 0.4195;
    const tabletChartRatio = 0.2863;
    const mobileChartRation = 0.4175;
    const mobileLandscapeChartRatio = 0.25;
    const chartRatio = width * (isTablet
        ? tabletChartRatio
        : (isMobile
            ? (isLandscapeOrientation()
                ? mobileLandscapeChartRatio
                : mobileChartRation)
            : desktopChartRation));
    const circleRatio = chartRatio / 2;
    const dataNormalized = data.map((item) => {
        const { trainer, value, color } = item;
        return {
            name: trainer,
            trainer,
            value,
            color,
        };
    });
    const renderLegend = () => {
        if (isMobile) {
            return <Legend layout='horizontal' iconType='circle' iconSize={12}/>;
        }
        if (isTablet) {
            return <Legend layout='vertical' verticalAlign='middle' align='left' iconType='circle' iconSize={12}/>;
        }
        return <Legend layout='vertical' verticalAlign='bottom' iconType='circle' iconSize={12}/>;
    };
    // width is reduced by right and left paddings
    // height is reduced by top and bottom paddings and by chart title and its margin
    return (
        <div className='bookings-by-trainer__chart'>
            <div className='bookings-by-trainer__chart-content'>
                <div className='chart__title chart__title_centered'>Bookings By Trainer</div>
                <PieChart width={width - 30 - 30} height={height - 23 - 23 - 34 - 29}>
                    <Pie
                        dataKey='value'
                        data={dataNormalized}
                        cx={isTablet ? '75%' : '50%'}
                        cy={isTablet ? '50%' : circleRatio}
                        outerRadius={circleRatio}>
                        {dataNormalized.map(trainer => <Cell key={trainer.trainer} fill={trainer.color}/>)}
                    </Pie>
                    {renderLegend()}
                    <Tooltip content={<BookingsByTrainerCustomTooltip/>}/>
                </PieChart>
            </div>
        </div>
    );
};

BookingsByTrainer.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

export default BookingsByTrainer;
