import * as axios from 'axios';
import {
    finishClassOccurrencesListLoading,
    setNextClassOccurrencesList,
    setClassOccurrencesList,
    startClassOccurrencesListLoading,
    startClassOccurrencesNextListLoading,
    finishClassOccurrencesNextListLoading
} from '../actions/dashboard/classOccurrencesActions';
import { classOccurrenceDataNormalizer } from '../reducers/classOccurrencesReducer';
import { getUserNameSpace } from '../helpers/userRoles';
import { loadBookingAvailableDogs } from './dogs';
import { getCustomersPaymentCards } from './paymentCards';
import { loadBookingAddresses } from './availableAddresses';
import { loadBookingProfileData } from './profile';
import { ADMIN_ONLY_PACKAGE_ID } from '../config';

export const getClassOccurrencesList = ({ queryString, startDate, endDate, pageSize = 0 }) => {
    return (dispatch, getState) => {
        const rangeStart = getState().getIn(['calendar', 'rangeStart']);
        const rangeEnd = getState().getIn(['calendar', 'rangeEnd']);
        dispatch(startClassOccurrencesListLoading());

        let url;
        if (startDate && endDate) {
            const query = `?page_size=${pageSize}&date__gte=${startDate}&date__lte=${endDate}&with_cancelled=false`;
            url = `/v1/${getUserNameSpace('class-occurrences')}/class-occurrences/${query}`;
        } else {
            const query = `?page_size=${pageSize}&date__gte=${rangeStart}&date__lte=${rangeEnd}&with_cancelled=false`;
            url = `/v1/${getUserNameSpace('class-occurrences')}/class-occurrences/${query}`;
        }

        if (queryString) {
            url = `${url}&${queryString}`;
        }

        return axios.get(url)
                    .then((response) => {
                        dispatch(setClassOccurrencesList(response.data));
                        dispatch(finishClassOccurrencesListLoading());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(finishClassOccurrencesListLoading());
                    });
    };
};

export const getNextClassOccurrencesList = () => {
    return (dispatch, getState) => {
        dispatch(startClassOccurrencesListLoading());
        dispatch(startClassOccurrencesNextListLoading());
        const nextUrl = getState().getIn(['classOccurrencesList', 'nextUrl']);
        return axios.get(nextUrl)
                    .then((response) => {
                        dispatch(setNextClassOccurrencesList(response.data));
                        dispatch(finishClassOccurrencesListLoading());
                        dispatch(finishClassOccurrencesNextListLoading());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(finishClassOccurrencesListLoading());
                    });
    };
};

export const patchClassOccurrenceEvent = (eventData) => {
    return dispatch => {
        return axios.patch(`/v1/${getUserNameSpace('class-occurrences')}/class-occurrences/${eventData.id}/`, eventData)
                    .then()
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                    });
    };
};

export const loadBookingClassData = (classId) => {
    return async dispatch => {
        const classData = await axios.get(`/v1/customers/class-occurrences/${classId}/`)
            .then(({ data }) => {
                const normalizedData = classOccurrenceDataNormalizer([data])[0];
                return normalizedData;
            })
            .catch(err => {
                console.log(err);
                console.log(err.response);
                throw err;
            });

        const [
            { data: generalPackagesAvailable },
            { data: freePackageCredit },
        ] = await Promise.all([
            axios.get(`/v1/customers/me/purchased-packages/?training_classes=${classData.training_class_detail.id}&is_available=true`),
            axios.get(`/v1/customers/me/purchased-packages/?package_id=${ADMIN_ONLY_PACKAGE_ID}&is_available=true`)
        ]);

        let allPackagesAvailable = []
        if (freePackageCredit && freePackageCredit.count) {
            allPackagesAvailable = freePackageCredit.results;
        }
        if (generalPackagesAvailable && generalPackagesAvailable.count) {
            allPackagesAvailable = allPackagesAvailable.concat(generalPackagesAvailable.results);
        }
        
        if (allPackagesAvailable.length) {
            classData.packagesAvailable = allPackagesAvailable;
        }
        return classData;
    };
};

export const loadBookingData = (classId) => {
    return async dispatch => {
        return Promise.all([
            dispatch(loadBookingClassData(classId)),
            dispatch(loadBookingAvailableDogs(classId)),
            dispatch(getCustomersPaymentCards()),
            dispatch(loadBookingAddresses()),
            dispatch(loadBookingProfileData())
        ]).then((response) => {
            return {
                classInfo: response[0],
                availableDogs: response[1],
                cards: response[2],
                addresses: response[3],
                user: response[4]
            };
        });
    };
};

export const bookClass = ({ classId, ...requestData }) => {
    return async dispatch => {
        return axios.post(`/v1/customers/class-occurrences/${classId}/book/`, requestData);
    };
};
