const validate = values => {
    const errors = {};

    if (!values.get('old_password')) {
        errors.old_password = 'Current Password is Required.';
    }

    if (!values.get('password')) {
        errors.password = 'New Password is Required';
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*=+., ]{8,}$/i.test(values.get('password'))) {
        errors.password = 'Your Password is not strong enough.';
    } else if (values.get('password').length > 128) {
        errors.password = 'Password can\'t contain more than 128 characters';
    } else if (values.get('password') === values.get('old_password')) {
        errors.password = 'Current and new passwords can\'t be equal';
    }
    return errors;
};

export default validate;
