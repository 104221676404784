import PropTypes from 'prop-types';
import React from 'react';
import ReactQuill from 'react-quill';
import classNames from 'classnames';
import { AGREEMENT_TEXT_MAX_VALUE, MESSAGE_PASTE_TEXT_TOO_LONG } from '../../../../helpers/validate';

const icons = ReactQuill.Quill.import('ui/icons');
icons.align.center = '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="24"><path fill-rule="evenodd" d="M.91 23.554v-2.932h21.089v2.932H.91zm3-7v-2.932h15.089v2.932H3.91zm-3-9.316h21.089v2.932H.91V7.238zm3-6.314h15.089v2.932H3.91V.924z"/></svg>';
icons.align.right = '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="24"><path fill-rule="evenodd" d="M.91 23.554v-2.932h21.089v2.932H.91zm5-9.932h16.089v2.932H5.91v-2.932zm-5-6.384h21.089v2.932H.91V7.238zm5-6.314h16.089v2.932H5.91V.924z"/></svg>';
icons.list.ordered = '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"><path fill-rule="evenodd" d="M5.91 20.554v-2.932h16.089v2.932H5.91zm0-11.316h16.089v2.932H5.91V9.238zm0-8.314h16.089v2.932H5.91V.924zM1.822 22.003a3.155 3.155 0 0 1-1.784-.616l.485-.932c.384.308.853.491 1.346.527a.953.953 0 0 0 .589-.173.585.585 0 0 0 .231-.499.601.601 0 0 0-.267-.518 1.239 1.239 0 0 0-.736-.193c-.242.015-.48.063-.707.144v-.865l1.017-1.184H.333v-1h3.252v.811L2.499 18.73c.771.09 1.349.741 1.338 1.509.025.494-.185.974-.568 1.295-.409.323-.923.49-1.447.469zM.09 12.5l1.559-1.576c.242-.236.467-.487.672-.755h.001a.944.944 0 0 0 .2-.544.6.6 0 0 0-.623-.616 1.219 1.219 0 0 0-.95.706L0 9.159c.209-.34.487-.634.817-.863a2.019 2.019 0 0 1 1.13-.293 1.84 1.84 0 0 1 1.209.432c.349.29.541.724.522 1.175-.001.272-.073.54-.21.777a5.139 5.139 0 0 1-.771.95l-.956.983h2.096v1.051H.09V12.5zM1.77 1.042H.76V-.001h2.149v5.292H1.77V1.042z"/></svg>';
icons.list.bullet = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="21"><path fill-rule="evenodd" d="M7.91 20.554v-2.932h16.089v2.932H7.91zm0-11.316h16.089v2.932H7.91V9.238zm0-8.314h16.089v2.932H7.91V.924zm-7 16.698h4.089v2.932H.91v-2.932zm0-8.384h4.089v2.932H.91V9.238zm0-8.314h4.089v2.932H.91V.924z"/></svg>';
icons.indent['-1'] = '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24"><path fill-rule="evenodd" d="M.91 23.554v-2.932h24.089v2.932H.91zm9-9.932h15.089v2.932H9.91v-2.932zm0-6.384h15.089v2.932H9.91V7.238zM.994 11.997l7.009-4.005v8.01L.994 11.997zM.91.924h24.089v2.932H.91V.924z"/></svg>';
icons.indent['+1'] = '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24"><path fill-rule="evenodd" d="M.91 23.554v-2.932h24.089v2.932H.91zm9-9.932h15.089v2.932H9.91v-2.932zm0-6.384h15.089v2.932H9.91V7.238zm-8.913.76l7.009 4.005-7.009 4.005v-8.01zM.91.924h24.089v2.932H.91V.924z"/></svg>';

let refQuill = null;

const renderTextEditorControl = ({ input, label, meta: { touched, error }, formProps }) => {
    const toolbarOptions = [
        [
            'bold', 'italic', 'underline',
            { color: ['#373e48', '#98999d', '#ff5d22', '#00a1c3'] },
            { header: [false, 1, 2] }
        ],
        [
            { list: 'ordered' }, { list: 'bullet' },
            { indent: '-1' }, { indent: '+1' }
        ],
        [
            { align: 'center' }, { align: 'right' }
        ]
    ];

    const clipboardOptions = {
        matchVisual: false
    };

    const modules = {
        toolbar: toolbarOptions,
        clipboard: clipboardOptions
    };

    const formats = ['bold', 'color', 'font', 'italic', 'underline', 'header', 'indent', 'list', 'align'];

    const handleChange = (content, delta, source, editor) => {
        if (editor.getLength() < 2) {
            input.onChange('');
            return;
        }

        input.onChange({ html: content, plain: editor.getText().trim() });
    };

    const handleBlur = (range, source, editor) => {
        if (editor.getLength() < 2) {
            input.onBlur('');
            return;
        }

        input.onBlur({ html: editor.getHTML(), plain: editor.getText().trim() });
    };

    const handlePaste = e => {
        const value = e.clipboardData.getData('Text');

        if (value.length > AGREEMENT_TEXT_MAX_VALUE) {
            e.preventDefault();
            e.stopPropagation();
            formProps.setFieldError({ field: input.name, message: MESSAGE_PASTE_TEXT_TOO_LONG });
            refQuill.blur();
        }
    };

    const handleContainerClick = () => {
        if (refQuill) {
            refQuill.focus();
        }
    };

    const getInputValue = () => {
        const inputValue = input.value && input.value.toJS ? input.value.toJS() : input.value;
        return inputValue ? inputValue.html : inputValue;
    };

    const renderError = () => {
        if (error && touched) {
            return <span className='input-control__error'>{error}</span>;
        }

        return null;
    };

    const textEditorContainerClassNames = classNames({
        'input-control__input text-editor-container': true,
        'text-editor-container_invalid': touched && error
    });

    return (
        <div className='input-control' onPaste={handlePaste} onClick={handleContainerClick}>
            <div className={textEditorContainerClassNames}>
                <ReactQuill
                    key='react-quill'
                    ref={ref => {
                        refQuill = ref;
                    }}
                    placeholder={label}
                    modules={modules}
                    formats={formats}
                    value={getInputValue()}
                    onBlur={handleBlur}
                    onChange={handleChange}>
                    <div className='text-editor' key='text-editor'/>
                </ReactQuill>
            </div>
            {renderError()}
        </div>
    );
};

renderTextEditorControl.propTypes = {
    input: PropTypes.shape({}),
    meta: PropTypes.shape({}),
    label: PropTypes.string,
    formProps: PropTypes.shape({})
};

export default renderTextEditorControl;
