import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import { reduxForm } from 'redux-form/immutable';
import validate from './validate';
import AddSignature from "../AddSignature/AddSignature";



const UpdateForm = (props) => {
    const { invalid, handleSubmit, submitting, signatureIsAdded, id, agreements } = props;
    const bittonClassName = classNames({
        'form-update__submit': true,
        'button': true,
        'button_blocked': signatureIsAdded || invalid || submitting,
    });
    const currentAgreement = agreements.find(agreement => agreement.id === id);

    return (
        <form className='form-update__form' onSubmit={handleSubmit}>
            <div className='form-update__content'>
                <div className='form-update__title'>{`${currentAgreement.name}`}</div>
                <div className='form-update__headline'>
                    <div className='form-update__headline-title'>
                        {`To sign the ${currentAgreement.name}`}
                    </div>
                    <div className='form-update__headline-subtitle'>Please put your signature below and press save button.</div>
                </div>
            </div>
            <AddSignature />
            <div className='form-update__footer'>
                <button
                    className={bittonClassName}
                    type='submit'
                    disabled={signatureIsAdded ||submitting}>
                    {(signatureIsAdded || submitting)
                        ? 'Waiting...'
                        : 'I ACCEPT'
                    }
                </button>
            </div>
        </form>

    );
};

UpdateForm.propTypes = {
    submitting: PropTypes.bool,
    signatureIsAdded: PropTypes.bool,
    invalid: PropTypes.bool,
    data: PropTypes.shape({}),
    handleSubmit: PropTypes.func.isRequired,
};

const form = reduxForm({
    form: 'UpdateForm',
    validate
});

const mapStateToProps = state => ({
    agreements: state.getIn(['user', 'agreements', 'items']).toJS(),
    registrationStep: state.getIn(['steps', 'registration_step']),
    signatureIsAdded: state.getIn(['formUpdate', 'form_update_signature_is_added']),
    initialValues: {
        'accepted': true,
    },
});

export default connect(mapStateToProps, null)(form(UpdateForm));
