import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { resendForgottenPassword } from '../../../../services/resetPassword';
import SkipStep from '../../../common/SkipStep/SkipStep';
import Step from '../../../common/Step';
import StepDescription from '../../../common/StepDescription';

const EmployeeCreatePasswordSentPage = ({ stepName = 'create-password', handleResendingForgottenPassword }) => {
    const Disclaimer = 'You are set to get started on Fitdog Sports! You can change your password anytime through your settings.';
    return (
        <Step
            logoClassName='create-password__logo'
            contentClassName='create-password__content'
            contentsContainerClassName='create-password__container create-password__container_success'
            stepName={stepName}
            componentsImage={<div className='create-password__img create-password__img_employee'/>}
            descriptionComponent={
                <StepDescription
                    className='create-password__step-description create-password__step-description_success'
                    text="You're all set!"
                    disclaimer={Disclaimer}/>
            }
            formComponent={
                <Link 
                    to='/dashboard'
                    className='create-password__success-link'
                    onClick={handleResendingForgottenPassword}>
                    Continue to fitdog sports
                </Link>
            }
            skipComponent={
                <SkipStep
                    className='create-password__skip-step create-password__skip-step_success'
                    skipLinkText='Not somenewemail@gmail.com?'
                    skipLinkTo='/'/>
            }/>
    );
};

EmployeeCreatePasswordSentPage.propTypes = {
    stepName: PropTypes.string,
    handleResendingForgottenPassword: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
    handleResendingForgottenPassword: bindActionCreators(resendForgottenPassword, dispatch)
});

export default connect(null, mapDispatchToProps)(EmployeeCreatePasswordSentPage);
