import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Swipeable from 'react-swipeable';
import { Field, reduxForm } from 'redux-form/immutable';
import renderInputControl from '../../../../common/Form/InputControl';
import SkipStep from '../../../../common/SkipStep/SkipStep';
import StepDescription from '../../../../common/StepDescription/StepDescription';
import { phoneNumberNormalizer, PHONE_MASK, PHONE_MASK_CHAR } from '../../../../../helpers/normalize';
import validate from './validate';

// #todo: move this to the VetInfoPage from VetInfoForm
// eslint-disable-next-line react/prefer-stateless-function
class AddVeterinarianModalForm extends React.PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        handleClose: PropTypes.func.isRequired,
        showVeterinarianModal: PropTypes.bool,
        submitting: PropTypes.bool,
        invalid: PropTypes.bool,
    };

    render() {
        // eslint-disable-next-line max-len
        const { showVeterinarianModal, handleSubmit, handleClose, submitting, invalid } = this.props;
        const veterinarianModalClassNames = classNames({
            'vet-info__add-veterinarian': true,
            'vet-info__add-veterinarian_show': showVeterinarianModal
        });
        const SubmitButtonClassNames = classNames({
            'add-veterinarian__submit-button': true,
            'add-veterinarian__submit-button_disabled': (invalid || submitting)
        });
        return (
            <Swipeable className={veterinarianModalClassNames} onSwipingRight={handleClose}>
                <div className='add-veterinarian__content'>
                    <StepDescription
                        className='add-veterinarian__content-description'
                        text='Add Veterinarian'
                        disclaimer='Please use full veterinarian name, no abbreviations.'/>
                    <form onSubmit={handleSubmit} className='add-veterinarian__form'>
                        <Field
                            name='name'
                            type='text'
                            component={renderInputControl}
                            label='Primary veterinarian name'/>
                        <Field
                            name='phone'
                            type='tel'
                            component={renderInputControl}
                            label='Phone number'
                            mask={PHONE_MASK}
                            maskChar={PHONE_MASK_CHAR}
                            isMasked
                            normalize={phoneNumberNormalizer} />
                        <button className={SubmitButtonClassNames} type='submit' disabled={submitting}>
                            Add veterinarian
                        </button>
                    </form>
                    <SkipStep
                        className='add-veterinarian__skip-step'
                        closeText='Cancel'
                        closeAction={handleClose}/>
                </div>
            </Swipeable>
        );
    }
}

const form = reduxForm({
    form: 'addVeterinarianModalForm',
    validate
});

export default form(AddVeterinarianModalForm);
