import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { find } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import Summary from '../../../../common/Summary';
import SummaryDiscount from './SummaryDiscount';
import BookingSummaryItem from './BookingSummaryItem';
import SteppedForm from '../../../../common/SteppedForm';
import { openBookingCancelModal, openBookingDogCancelModal } from '../../../../../actions/modal/openActions';
import { loadBookingData } from '../../../../../services/classOccurrences';
import DogSelection from '../../StepForms/DogSelection';
import PickupLocationSelect from '../../StepForms/PickupLocationSelect';
import DropoffLocationSelect from '../../StepForms/DropoffLocationSelect';
import PaymentInfoStep from '../../StepForms/PaymentInfoStep';
import PaymentChangeStep from '../../StepForms/PaymentChangeStep';
import PreConfirmationStep from '../../StepForms/PreConfirmationStep';
import ConfirmationStep from '../../StepForms/ConfirmationStep';
import DropoffLocationChange from '../../StepForms/DropoffLocationChange';
import PickupLocationChange from '../../StepForms/PickupLocationChange';
import AjaxLoader from '../../../../common/AjaxLoader';
import { getSteppedFormValues } from '../../../../../reducers/steppedFormReducer';

class BookingPage extends PureComponent {
    state = {
        initialData: null,
        isLoading: true
    }
    componentDidMount() {
        const { routeParams: { classId }, loadBookingData, currentClass, isDataLoaded } = this.props;
        if (!currentClass && !isDataLoaded) {
            loadBookingData(classId, 'booking').then(response => {
                this.setState({
                    initialData: response,
                    isLoading: false
                });
            });
        }
    }

    getSummaryItems = () => {
        const result = [];
        const { currentClass, selectedDogsList, dogsList, currentCredit } = this.props;
        if (currentClass && dogsList) {
            const { date, training_class_detail: { name, price }, packagesAvailable } = currentClass;
            const selectedDogs = typeof selectedDogsList === 'string' ? selectedDogsList.split(',') : selectedDogsList;
            const dogs = dogsList && 'size' in dogsList ? dogsList.toJS() : dogsList;
            if (price && selectedDogs) {
                selectedDogs.forEach(dogId => {
                    const dogData = dogs.filter(dog => dog.id.toString() === dogId);
                    const dateValue = moment(date, ['YYYY-MM-DD']);
                    if (dogData.length) {
                        result.push({
                            id: dogId,
                            bookingClassName: name,
                            dogName: dogData[0].name,
                            date: dateValue.format('MM/DD/YY'),
                            price
                        });
                    }
                });
            }
            if (packagesAvailable && packagesAvailable.length && currentCredit) {
                const { available_quantity: availableQuantity } = find(packagesAvailable, ({ id }) => id === currentCredit); // eslint-disable-line
                if (availableQuantity) {
                    const count = availableQuantity < selectedDogs.length ? availableQuantity : selectedDogs.length;
                    result.push({
                        price: -(count * price),
                        count
                    });
                }
            }
        }
        return result;
    }

    hasUserDefaultAddresses = () => {
        const { userData } = this.props;
        return userData && userData.pickup_location && userData.drop_off_location;
    }

    render() {
        const { openBookingCancelModal, openBookingDogCancelModal,
                currentStep, selectedDogsList } = this.props;
        const containerClasses = classNames([
            'inner-page__container',
            'booking__container',
            {
                'booking__container_single': currentStep === 0 || currentStep === 5 || currentStep === 8
            }
        ]);
        const stepItems = [
            {
                name: 'select-dog',
                form: DogSelection,
                title: 'My Dogs',
                submitButtonClasses: 'modal-dialog__button_flexible',
                submitText: 'Next'
            }, {
                name: 'pickup-location',
                form: PickupLocationSelect,
                title: 'Enter the address you would like us to pick up your dog.',
            }, {
                name: 'dropoff-location',
                form: DropoffLocationSelect,
                title: 'Enter the address you would like us to drop off your dog.',
            }, {
                name: 'add-card',
                form: PaymentInfoStep,
                title: 'The money stuff'
            }, {
                name: 'pre-confirmation',
                form: PreConfirmationStep,
                title: 'Review your reservation',
                submitText: 'Reserve & Pay'
            }, {
                name: 'confirmation',
                form: ConfirmationStep,
                submitText: 'Finish and pay'
            }, {
                name: 'pickup-select',
                form: PickupLocationChange,
                title: 'Select a pickup location',
                submitText: 'USE THIS LOCATION',
            }, {
                name: 'dropoff-select',
                form: DropoffLocationChange,
                title: 'Select a dropoff location',
                submitText: 'USE THIS LOCATION',
            }, {
                name: 'payment-select',
                form: PaymentChangeStep,
                title: 'Payment Methods',
                target: 'booking',
                nextStep: 4,
                submitText: 'Confirm payment method',
            }
        ];

        const selectedMultipleDogs = typeof selectedDogsList === 'string' && selectedDogsList.split(',').length > 1;
        return (
            <div className='inner-page booking'>
                {!this.state.initialData
                    ? <AjaxLoader />
                    : (<div className={containerClasses}>
                        <div className='booking__block booking__block_main'>
                            <SteppedForm
                                name='booking'
                                initialData={this.state.initialData}
                                items={stepItems}
                                onCancelHandler={openBookingCancelModal}/>
                        </div>
                        {(currentStep !== 0 && currentStep !== 5 && currentStep !== 8) &&
                            <div className='booking__block booking__block_summary'>
                                <Summary
                                    title='Reservation Summary'
                                    items={this.getSummaryItems()}
                                    onRemoveHandler={
                                        selectedMultipleDogs
                                        ? openBookingDogCancelModal
                                        : openBookingCancelModal
                                    }
                                    itemRenderer={BookingSummaryItem}
                                    discountRenderer={SummaryDiscount}/>
                            </div>
                        }
                    </div>)
                }
            </div>
        );
    }
}

BookingPage.propTypes = {
    openBookingCancelModal: PropTypes.func,
    loadBookingData: PropTypes.func,
    routeParams: PropTypes.shape({}),
    currentStep: PropTypes.number,
    currentClass: PropTypes.shape({}),
    selectedDogsList: PropTypes.string,
    dogsList: PropTypes.shape({}),
    userData: PropTypes.shape({}),
    openBookingDogCancelModal: PropTypes.func,
    isDataLoaded: PropTypes.bool,
    currentCredit: PropTypes.number,
};

const mapStateToProps = state => {
    const { currentStep, data, submitData } = getSteppedFormValues('booking')(state);
    return {
        currentStep,
        currentClass: data && data.classInfo ? data.classInfo : null,
        selectedDogsList: submitData && submitData.dogs ? submitData.dogs : null,
        currentCredit: submitData && submitData.packageCredit ? submitData.packageCredit : null,
        userData: data && data.user ? data.user : null,
        dogsList: state.getIn(['dogsList', 'dogs']),
        isDataLoaded: data && data.isDataLoaded ? data.isDataLoaded : false
    };
};

const mapDispatchToProps = {
    openBookingCancelModal,
    openBookingDogCancelModal,
    loadBookingData
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingPage);
