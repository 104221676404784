import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as IconRemove21 } from '../../../assets/icons/icon-remove-21.svg';
import * as ModalSidebarActions from '../../../actions/modal/modalSidebarActions';
import * as OpenActions from '../../../actions/modal/openActions';
import EditCreateModalSidebarDialog from '../../containers/Modals/EditCreateModalSidebarDialog';
import EditDogInfoModalSidebarDialog from '../../containers/Modals/EditDogInfoModalSidebarDialog';
import EditAdditioanlContacts from '../../containers/Modals/EditAdditioanlContacts';
import EditScheduleModalSidebar from '../../containers/Modals/EditScheduleModalSidebar';
import EditCustomerContactInfo from '../../containers/Modals/EditCustomerContactInfo';
import { getModalDialogFormName } from '../Form/formFactory';

class ModalSidebar extends React.PureComponent {

    get modals() {
        const { isOpened, isSubmitting, onSubmit, onGetOptions, data, formValues, error } = this.props;
        return {
            editCreateModalSidebar: <EditCreateModalSidebarDialog
                isOpened={isOpened}
                onClose={() => this.handleClose()}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                onGetOptions={onGetOptions}
                initialValues={data ? data.initialValues : {}}
                formValues={formValues}
                data={data}
                modalError={error} />,
            editDogInfoModalSidebar: <EditDogInfoModalSidebarDialog
                isOpened={isOpened}
                onClose={() => this.handleClose()}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                onGetOptions={onGetOptions}
                initialValues={data ? data.initialValues : {}}
                formValues={formValues}
                data={data}
                modalError={error} />,
            editScheduleModalSidebar: <EditScheduleModalSidebar
                isOpened={isOpened}
                data={data}
                onClose={() => this.handleClose()} />,
            editCustomerContactInfo: <EditCustomerContactInfo
                isOpened
                onClose={() => this.handleClose()}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                onGetOptions={onGetOptions}
                initialValues={data ? data.initialValues : {}}
                formValues={formValues}
                data={data}
                modalError={error} />,
            editAdditioanlContacts: <EditAdditioanlContacts
                isOpened={isOpened}
                data={data}
                onClose={this.handleClose} />,
        };
    }

    handleClose() {
        const { onClose, formValues, data, openCancelFormActionModalDialog } = this.props;
        const { initialValues, hasCancelModal, cancelModalContent } = data;

        if (hasCancelModal) {
            let isChanged = false;
            formValues.entrySeq().forEach(field => {
                if (!(field[0] in initialValues) && field[1] !== initialValues[field[0]]) {
                    isChanged = true;
                }
            });
            if (isChanged) {
                openCancelFormActionModalDialog(cancelModalContent);
            } else {
                onClose();
            }
        } else {
            onClose();
        }
    }

    render() {
        const { modalType, isOpened, data } = this.props;

        const modalDialogClassNames = classNames({
            'modal-dialog': true,
            'modal-dialog_active': isOpened,
            'modal-dialog_side-bar': true
        });

        const modalDialogContentClassNames = classNames({
            'modal-dialog__content': true,
            'modal-dialog__content_overflow-visible': data ? data.overflowShown : false
        });

        const getModalDialog = () => {
            if (!modalType) {
                return null;
            }
            return this.modals[modalType]; //eslint-disable-line
        };

        const headerClassNames = (data && 'headerClassNames' in data) ? data.headerClassNames : null;
        const hasCloseBtn = (data && 'hasCloseBtn' in data) ? data.hasCloseBtn : true;

        return (
            <div className={modalDialogClassNames} onClick={() => this.handleClose()}>
                <div className={modalDialogContentClassNames} onClick={(e) => { e.stopPropagation(); }}>
                    <div className={`modal-dialog__header ${headerClassNames}`}>
                        <div className='modal-dialog__title'>{data ? data.title : ''}</div>
                        {hasCloseBtn && <button className='modal-dialog__close' onClick={() => this.handleClose()}>
                            <IconRemove21 className='icon_remove_21'/>
                        </button>}
                    </div>
                    {getModalDialog()}
                </div>
            </div>
        );
    }
}

ModalSidebar.propTypes = {
    modalType: PropTypes.string,
    isOpened: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onGetOptions: PropTypes.func,
    openCancelFormActionModalDialog: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    isCanceling: PropTypes.bool.isRequired,
    data: PropTypes.shape({}),
    formValues: PropTypes.shape({}),
    error: PropTypes.string
};

const mapFieldsToProps = state => {
    const data = state.getIn(['modalSidebar', 'data']);

    if (!data || !data.shouldPassValues) {
        return undefined;
    }

    const formState = state.getIn(['form', getModalDialogFormName(data.type)]);

    if (!formState) {
        return undefined;
    }
    return formState.get('values');
};

const mapStateToProps = state => {
    return {
        isOpened: state.getIn(['modalSidebar', 'isOpened']),
        isSubmitting: state.getIn(['modalSidebar', 'isSubmitting']),
        isCanceling: state.getIn(['modalSidebar', 'isCanceling']),
        modalType: state.getIn(['modalSidebar', 'modalType']),
        data: state.getIn(['modalSidebar', 'data']),
        error: state.getIn(['modalSidebar', 'error']),
        formValues: mapFieldsToProps(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    onClose: bindActionCreators(ModalSidebarActions.onModalClose, dispatch),
    onSubmit: bindActionCreators(ModalSidebarActions.onModalSubmit, dispatch),
    onGetOptions: bindActionCreators(ModalSidebarActions.onModalGetOptions, dispatch),
    onCancel: bindActionCreators(ModalSidebarActions.onModalCancel, dispatch),
    onTabChange: bindActionCreators(ModalSidebarActions.setModalActiveForm, dispatch),
    openCancelFormActionModalDialog: bindActionCreators(OpenActions.openCancelFormActionModalDialog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSidebar);
