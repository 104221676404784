import React from 'react';
import Swiper from 'react-id-swiper';
import PropTypes from 'prop-types';

class FeaturedSlider extends React.PureComponent {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({})),
    };

    componentDidMount() {
        window.addEventListener('load', () => {
            if (this.swiper) this.swiper.update(true);
        });
    }

    swiper = null;

    render() {
        const defaultImage = '/assets/imgs/featured-class-desktop.jpg';

        const { items } = this.props;

        const params = {
            containerClass: 'featured-slider',
            pagination: items.length <= 10 && items.length > 1 ? '.swiper-pagination' : {},
            paginationClickable: true,
            autoHeight: true,
            nextButton: items.length > 1 ? '.swiper-button-next' : null,
            prevButton: items.length > 1 ? '.swiper-button-prev' : null,
            onSlideChangeEnd: (swiper) => {
                swiper.update(true);
            },
            runCallbackOnInit: true,
            onInit: (swiper) => {
                this.swiper = swiper;
            },
        };

        if (items.length > 0) {
            return (
                <Swiper {...params}>
                    {
                        items.map((item, index) => {
                            return (
                                <div
                                    className='featured-slider__item'
                                    style={{ backgroundImage: `url("${item.photo_processed_large || defaultImage}")` }}
                                    key={'featured-slide_' + index}>
                                    <div className='featured-slider__inner'>
                                        <div className='featured-slider__content'>
                                            <div className='featured-slider__title'>Featured classes</div>
                                            <div className='featured-slider__name'>{item.name}</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </Swiper>
            );
        }

        return (
            <div className='featured-slider'>
                <div className='featured-slider__item featured-slider__item_empty'>
                    <div className='featured-slider__content'>
                        <div className='featured-slider__name'>fitdog</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FeaturedSlider;
