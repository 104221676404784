import { createAction } from 'redux-actions';
import * as ErrorHandlerTypes from '../types/errorHandlerTypes';

export const setErrorText = createAction(ErrorHandlerTypes.SET_ERROR_TEXT);
export const setErrorType = createAction(ErrorHandlerTypes.SET_ERROR_TYPE);
export const clearErrorText = createAction(ErrorHandlerTypes.CLEAR_ERROR_TEXT);
export const clearErrorType = createAction(ErrorHandlerTypes.CLEAR_ERROR_TYPE);

export const clearError = () => {
    return dispatch => {
        dispatch(clearErrorType());
        setTimeout(() => {
            dispatch(clearErrorText());
        }, 500);
    };
};

const getErrorText = (errorObject) => {
    console.log('errorObject: ', errorObject);
    const errorResponse = (errorObject.response && errorObject.response.data) ? errorObject.response : errorObject;
    const errorField = errorResponse && errorResponse.data ? Object.keys(errorResponse.data)[0] : null;
    console.log('errorResponse: ', errorResponse);

    if (!errorResponse && !errorField) {
        return null;
    }

    // FIT-2116
    // new stripe server errors case
    if (errorResponse.data.error_message) {
        return errorResponse.data.error_message;
    }

    console.log('errorField: ', errorField);

    if (errorResponse.status === 500) {
        return 'Server Error. Something went wrong...';
    }

    // event details page errors case
    if (errorField === '0') {
        return errorResponse.data[errorField][1] === "o" ? errorResponse.data[0] : errorResponse.data[errorField][1];
    }
    if (errorField === 'old_password') {
        return 'Your current password is incorrect';
    }
    if (errorField === 'email' || errorField === 'notes') {
        return `${errorField}: ${errorResponse.data[errorField][0]}`;
    }

    if (errorField === 'detail' || errorField === 'recipient') {
        return errorResponse.data[errorField];
    }

    if (errorResponse.data[errorField]) {
        if (Array.isArray(errorResponse.data[errorField][0])) {
            return errorResponse.data[errorField][0][0];
        }
        return errorResponse.data[errorField][0];
    }
    return null;
};

const setFieldErrorText = (errorText) => {
    if (errorText === null) {
        return 'Something went wrong...';
    }
    return errorText;
};

export const setGlobalErrorText = (errorText, errorStatus) => {
    if (errorStatus === 401) return false;
    return dispatch => {
        dispatch(setErrorText(setFieldErrorText(errorText)));
        dispatch(setErrorType('globalError'));
    };
};

export const setGlobalError = (errorObject) => {
    const errorText = getErrorText(errorObject);
    return setGlobalErrorText(errorText, errorObject.status);
};

const getRestFrameworkErrorDetails = (restFrameworkErrorResponse) => {
    if (Array.isArray(restFrameworkErrorResponse.response.data)) {
        let arrayOfErrorValues = [];
        for (let i of restFrameworkErrorResponse.response.data) {
            if (typeof i === 'object' && !Array.isArray(i) && i !== null) {
                arrayOfErrorValues = [...arrayOfErrorValues, ...Object.values(i)];
            } else {
                arrayOfErrorValues = [...arrayOfErrorValues, i];
            };
        };
        const text = arrayOfErrorValues.join(', ');
        return text;
    };
    return JSON.stringify(restFrameworkErrorResponse.response.data);
};

export const setGlobalRestError = (restFrameworkErrorResponse) => {
    return setGlobalErrorText(
        getRestFrameworkErrorDetails(restFrameworkErrorResponse), restFrameworkErrorResponse.status);
};

export const setFormError = (errorObject) => {
    const errorText = getErrorText(errorObject);
    return dispatch => {
        dispatch(setErrorText(setFieldErrorText(errorText)));
        dispatch(setErrorType('formError'));
    };
};

export const setGlobalSuccess = (text) => {
    return dispatch => {
        dispatch(setErrorText(setFieldErrorText(text)));
        dispatch(setErrorType('globalSuccess'));
    };
};
