import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import renderInputControl from '../../../../common/Form/InputControl';
import validate from './validate';

const DailyNotesForm = ({ handleSubmit, invalid, submitting, isLoading, onSubmit, currentDate }) => {
    const submitButtonClassNames = classNames({
            'notes-incidents__submit-button': true,
            'notes-incidents__submit-button_disabled': invalid || submitting
        });

    const getSubmitButtonText = () => {
        return (!isLoading && !submitting) ? 'Add Note' : (submitting ? 'Submitting...' : 'Loading...');
    };

    return (
        <form onSubmit={handleSubmit(onSubmit.bind(this, currentDate))} className='notes-incidents__form'>
            <div className='input-control'>
                <Field
                    name='notes'
                    isTextarea
                    value='stuff'
                    component={renderInputControl}
                    className='notes-incidents__textarea'
                    label='Create a new note...'/>
                <button className={submitButtonClassNames} disabled={submitting}>
                    { getSubmitButtonText() }
                </button>
            </div>
        </form>
    );
};

DailyNotesForm.propTypes = {
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    isLoading: PropTypes.bool,
    currentDate: PropTypes.string,
};

const form = reduxForm({
    form: 'employeeDailyNotesForm',
    validate
});

export default form(DailyNotesForm);
