import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reset } from 'redux-form';
import { signUpFacebook, signUpGoogle } from '../../../../actions/onboarding/userActions';
import SkipStep from '../../../common/SkipStep';
import Step from '../../../common/Step';
import SignUpFormContainer from './FinishSignUpForm';

// eslint-disable-next-line react/prefer-stateless-function
class FinishSignUpPage extends React.PureComponent {
    static propTypes = {
        stepName: PropTypes.string,
        handleSignUp: PropTypes.func,
        handleFaceBookSignUp: PropTypes.func,
        handleGoogleSignUp: PropTypes.func,
        resetForm: PropTypes.func,
        formNames: PropTypes.arrayOf(PropTypes.string),
        userData: PropTypes.shape({}),
    };

    render() {
        const { stepName = 'signup', handleFaceBookSignUp, handleGoogleSignUp, userData } = this.props;

        return (
            <Step
                logoClassName='signup__logo'
                contentClassName='signup__content'
                contentsContainerClassName='signup__container'
                stepName={stepName}
                componentsImage={<div className='signup__img'/>}
                formComponent={
                    <SignUpFormContainer
                        onSubmit={values => {
                            const userDataJS = userData.toJS();
                            if (Object.keys(userDataJS.signUpData).length) {
                                const socialData = values.set('accessToken', userDataJS.signUpData.token);
                                console.log('socialData: ', socialData);
                                if (userDataJS.signUpData.socialName.length && userDataJS.signUpData.socialName === 'fb') {
                                    console.log('handleFaceBookSignUp: ');
                                    handleFaceBookSignUp({ data: socialData.toJS() });
                                } else {
                                    console.log('handleGoogleSignUp: ');
                                    handleGoogleSignUp({ data: socialData.toJS() });
                                }
                            }
                        }}/>
                }
                skipComponent={
                    <SkipStep
                        className='signup__skip-step'
                        text='Already have an account?'
                        linkTo='/'
                        linkText='Sign In'/>
                }/>
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.get('user'),
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleFaceBookSignUp: bindActionCreators(signUpFacebook, dispatch),
    handleGoogleSignUp: bindActionCreators(signUpGoogle, dispatch),
    resetForm: bindActionCreators(reset, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinishSignUpPage);
