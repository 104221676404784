const validate = data => {
    const errors = {};
    let notes = data.get('notes') || '';
    notes = notes.trim();

    if (!notes) {
        errors.notes = 'Your note is empty.';
    } else if (notes.length > 2000) {
        errors.notes = 'Your note is too long.';
    }

    return errors;
};

export default validate;
