import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { dataStates } from '../../../../../../helpers/state';

const Commission = ({ items, dataState }) => {
    const renderTable = () => {
        if (dataState === dataStates.loaded && items && items.details && items.details.length) {
            const columns = [
                {
                    id: 'programName',
                    Header: 'Program or Class Name',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.name,
                    Footer: (<strong>Total {items.trainer_detail.full_name}:</strong>),
                },
                {
                    id: 'programCount',
                    Header: 'Number of Programs/Classes',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.count,
                    Footer: items.count,
                },
                {
                    id: 'commission',
                    Header: 'Trainer Commission (%)',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => `${d.commission}%`,
                },
                {
                    id: 'trainerShare',
                    Header: 'Trainer Share ($)',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => `$${d.trainer_share}`,
                    Footer: `$${items.trainer_share}`,
                },
            ];

            return (
                <ReactTable
                    showPagination={false}
                    defaultPageSize={items.details.length}
                    data={items.details}
                    columns={columns}/>);
        }
        return null;
    };

    return (
        <div className='custom-reports__report'>
            {items !== undefined && renderTable()}
        </div>
    );
};

Commission.propTypes = {
    items: PropTypes.shape({}).isRequired,
    dataState: PropTypes.string.isRequired,
};

export default Commission;
