import PropTypes from 'prop-types';
import React from 'react';

const Divider = ({ dividerLineClassName, dividerTextClassName, text }) => {
    return (
        <div className={dividerLineClassName}>
            <span className={dividerTextClassName}>{text}</span>
        </div>
    );
};

Divider.propTypes = {
    dividerLineClassName: PropTypes.string.isRequired,
    dividerTextClassName: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
};

export default Divider;
