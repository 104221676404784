import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const propTypes = {
    stepsTotal: PropTypes.number,
    stepsLast: PropTypes.number,
    stepsCurrent: PropTypes.shape({}),
    onStepClick: PropTypes.func
};

const StepIndicator = props => {
    const {
        stepsTotal,
        stepsCurrent,
        stepsLast,
        onStepClick } = props;
    
    const renderSteps = () => {
        return Array.from(Array(stepsTotal)).map((item, index) => {
            const id = index + 1;
            const isDisabled = stepsLast && id > stepsLast;
            const isActive = id === stepsCurrent.id;
            const clickHandler = !isDisabled && !isActive ?
                () => onStepClick(id) :
                undefined;
            const stepClassNames = classNames({
                'step-indicator__step': true,
                'step-indicator__step_active': isActive,
                'step-indicator__step_disabled': isDisabled
            });
            return <div className={stepClassNames} key={'step-' + id} onClick={clickHandler}/>;
        });
    };
    
    return (
        <div className='step-indicator'>
            <div className='step-indicator__steps'>
                {renderSteps()}
            </div>
            <div className='step-indicator__current-name'>
                {stepsCurrent.name}
            </div>
        </div>
    );
};

StepIndicator.propTypes = propTypes;

export default StepIndicator;
