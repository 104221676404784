import * as axios from 'axios';

export const getPurchasePackages = ({ queryString }) => {
    return () => {
        let url = '/v1/customers/me/purchased-packages/';

        if (queryString !== undefined) {
            url = `${url}?${queryString}`;
        }

        return axios.get(url);
    };
};

export const getNextPurchasePackages = () => {
    return (dispatch, getState) => {
        const nextUrl = getState().getIn(['currentCustomer', 'purchaseHistory', 'nextUrl']);
        return axios.get(nextUrl);
    };
};
