/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Wrapper from './MarketingEditorPageContainer';
import Loader from '../common/Loader/Loader';
import LinkButton from '../common/LinkButton/index';
import StepIndicator from '../StepIndicator';
import stepComponents from './steps';
import { getConfig, FORM_NAME, SUBMITTYPE_SAVE, SUBMITTYPE_SEND, SUBMITTYPE_TESTSEND } from './form';
import { getInitialValues } from '../TemplateEditor/form';

const spinner = (
    <Loader
        isVisible
        loadingText='Loading Campaign Data...'
        colorClassName='icon_loader-black'
        modifierClassName='calendar__loader'/>
);

const propTypes = {
    model: PropTypes.shape({}),
    forms: PropTypes.shape({}),
    resetForm: PropTypes.func,
    submitForm: PropTypes.func,
    initForm: PropTypes.func,
    handleStepSelect: PropTypes.func,
    handleTemplateSelect: PropTypes.func,
    gotoCampaignList: PropTypes.func,
    updateDetail: PropTypes.func,
    changeField: PropTypes.func,
    openSendModal: PropTypes.func,
    openSendTestModal: PropTypes.func,
};

class MarketingEditorPage extends React.PureComponent {
    static propTypes = propTypes;

    componentDidUpdate() {
        const { model } = this.props;
        this.headerTitle = 'Create New Campaign';
        const name = model.getIn(['detail', 'initialValues', 'name']);

        if (name) {
            this.headerTitle = name;
        }
    }


    getRefForm = (ref) => {
        this.refForm = ref;
    };

    getActiveTemplateID = () => {
        const { model } = this.props;
        const activeTemplate = model.get('templates').find(template => template.get('isActive'));

        if (!activeTemplate) {
            return undefined;
        }

        return activeTemplate.get('id');
    };

    getStepProps = (stepIndex) => {
        const { model, forms, resetForm } = this.props;
        const {
            getRefForm,
            handleTemplateClick,
            handleArrayOrderUpdate,
            getActiveTemplateID } = this;
        const initialValues = model.getIn(['detail', 'initialValues']);
        const props = {
            get 0() {
                return {
                    key: 'template-editor',
                    enableReinitialize: true,
                    destroyOnUnmount: false,
                    keepDirtyOnReinitialize: true,
                    formConfig: getConfig({
                        detailID: model.getIn(['detail', 'id']),
                        initialValues: initialValues && initialValues.toJS ? initialValues.toJS() : initialValues
                    }),
                    ref: getRefForm
                };
            },
            get 1() {
                return {
                    key: 'step2',
                    onTemplateClick: handleTemplateClick,
                    templates: model.get('templates')
                };
            },
            get 2() {
                return {
                    key: 'step3',
                    detailID: model.getIn(['detail', 'id']),
                    templateID: getActiveTemplateID(),
                    templates: model.get('templates'),
                    variables: model.getIn(['detail', 'variables']),
                    arrayOrder: model.getIn(['detail', 'arrayOrder']),
                    updateArrayOrder: handleArrayOrderUpdate,
                    getRefForm
                };
            },
            get 3() {
                return {
                    key: 'step4',
                    detailID: model.getIn(['detail', 'id']),
                    variables: model.getIn(['detail', 'variables']),
                    isPreview: true,
                    arrayOrder: model.getIn(['detail', 'arrayOrder']),
                    initialValues: forms[FORM_NAME].values,
                    resetForm
                };
            }
        };
        return props[stepIndex];
    };

    getUploadProgress = () => {
        const { model } = this.props;
        return model.getIn(['detail', 'uploadProgress']);
    };

    doValidation = () => {
        this.refForm.getWrappedInstance().doValidation();
    };

    isCurrentStepComplete = () => {
        const { model } = this.props;
        const lastAvailableStep = model.get('stepsLast');
        const currentStep = model.getIn(['stepsCurrent', 'id']);
        return currentStep < lastAvailableStep;
    };

    isLoading = () => {
        const { model } = this.props;
        return model.getIn(['detail', 'isLoading']);
    };

    isSubmitting = () => {
        const { forms } = this.props;
        return forms[FORM_NAME].isSubmitting;
    };

    isPristine = () => {
        const { forms } = this.props;
        return forms[FORM_NAME].isPristine;
    };

    shouldDisableNextButton = () => {
        return !this.isCurrentStepComplete();
    };

    shouldDisableAnyButton = () => {
        return this.isSubmitting() || this.isLoading();
    };

    shouldDisableSaveLaterButton = () => {
        const { values, warnings } = this.props.forms[FORM_NAME];

        if (!values || this.isPristine()) {
            return true;
        }

        if (!warnings) {
            return false;
        }

        const hasInvalidValue = (warning) => {
            if (!warning) {
                return false;
            }

            if (warning.constructor === String) {
                return warning !== 'This field is required.';
            }

            return Object.keys(warning).some((key) => {
                return hasInvalidValue(warning[key]);
            });
        };

        return hasInvalidValue(warnings);
    };

    submitType = (value) => {
        const { model, updateDetail } = this.props;
        const prev = model.getIn(['detail', 'submitType']);

        if (value === undefined) {
            return prev;
        }

        if (prev !== value) {
            updateDetail({ submitType: value });
        }

        return value;
    };

    refForm = null;

    handleArrayOrderUpdate = (arrayOrder) => {
        const { updateDetail } = this.props;
        updateDetail({ arrayOrder });
    };

    handleCancelClick = () => {
        const { gotoCampaignList } = this.props;
        gotoCampaignList();
    };

    handleSaveLaterClick = () => {
        const { shouldDisableAnyButton, shouldDisableSaveLaterButton, doValidation } = this;
        const { submitForm } = this.props;

        if (shouldDisableAnyButton() || shouldDisableSaveLaterButton()) {
            // The button is disabled, but we want to initiate validation of all fields just like before submission.
            doValidation();
            return;
        }

        this.submitType(SUBMITTYPE_SAVE);

        setTimeout(() => {
            submitForm(FORM_NAME);
        }, 0);
    };

    handleNextClick = () => {
        const { shouldDisableAnyButton, shouldDisableNextButton, doValidation } = this;

        if (shouldDisableAnyButton() || shouldDisableNextButton()) {
            // The button is disabled, but we want to initiate validation of all fields just like before submission.
            doValidation();
            return;
        }

        const { model, handleStepSelect } = this.props;
        const { stepsCurrent: { id }, stepsTotal, stepsLast } = model.toJS();

        if (id === stepsTotal || id >= stepsLast) {
            return;
        }

        this.refForm = null;
        handleStepSelect(id + 1);
    };

    handleSendTestClick = () => {
        const { openSendTestModal } = this.props;

        this.submitType(SUBMITTYPE_TESTSEND);

        setTimeout(() => {
            openSendTestModal();
        }, 0);
    };

    handleCreateSendClick = () => {
        const { shouldDisableAnyButton } = this;
        const { openSendModal } = this.props;

        if (shouldDisableAnyButton()) {
            return;
        }

        this.submitType(SUBMITTYPE_SEND);

        setTimeout(() => {
            openSendModal();
        }, 0);
    };

    handleStepClick = (stepID) => {
        const { handleStepSelect } = this.props;
        this.refForm = null;
        handleStepSelect(stepID);
    };

    handleTemplateClick = (templateID) => {
        const { handleTemplateSelect, initForm, forms, updateDetail, changeField } = this.props;
        const initialValues = getInitialValues(templateID);
        const existingValues = forms[FORM_NAME].values;
        const { target_array, name, subject } = existingValues;

        if ((target_array && target_array.length) || name || subject) {
            initialValues.target_array = (target_array && target_array.length) && [...target_array];
            initialValues.name = name;
            initialValues.subject = subject;
        }

        updateDetail({ initialValues, arrayOrder: [] });
        // Remove it for now so we can init the form first, and then set the `template_id` field to make the form dirty.
        delete initialValues.template_id;
        handleTemplateSelect(templateID);
        initForm({ form: FORM_NAME, data: { ...initialValues }, keepDirty: false });
        setTimeout(() => {
            changeField(FORM_NAME, 'template_id', templateID);
        }, 0);
    };

    renderControls = () => {
        const { model } = this.props;
        const { stepsTotal, stepsCurrent: { id } } = model.toJS();
        const isFinalStep = id === stepsTotal;
        const isSubmitting = this.isSubmitting();
        const isSending = this.submitType() === SUBMITTYPE_SEND;
        const uploadProgress = this.getUploadProgress();

        const shouldDisbaleAnyButton = this.shouldDisableAnyButton();
        const saveLaterButtonClassNames = classNames({
            'button form-edit-create-page__control-button button_dark': true,
            'button_disabled': shouldDisbaleAnyButton || this.shouldDisableSaveLaterButton()
        });
        const nextButtonClassNames = classNames({
            'button form-edit-create-page__control-button button_dark': true,
            'button_disabled': shouldDisbaleAnyButton || (!isFinalStep && this.shouldDisableNextButton())
        });
        const getCancelButtonText = () => {
            const {
                model,
                forms,
                openDiscardChangesModal,
                openCampaignDeleteModal,
                gotoCampaignList,
                handleStepSelect } = this.props;

            const stepID = model.getIn(['stepsCurrent', 'id']);
            const detailID = model.getIn(['detail', 'id']);
            const detailName = forms[FORM_NAME].values && forms[FORM_NAME].values.name;

            if (stepID > 1) {
                this.handleCancelClick = () => handleStepSelect(id - 1);
                return 'Back';
            }

            if (model.getIn(['detail', 'id'])) {
                this.handleCancelClick = () => {
                    openCampaignDeleteModal({
                        id: detailID,
                        name: detailName
                    });
                };
                return 'Delete';
            }

            this.handleCancelClick = this.isPristine() ? gotoCampaignList : openDiscardChangesModal;
            return 'Cancel';
        };
        const getSaveLaterButtonText = () => {
            if (!isSubmitting || isSending) {
                return 'Save for Later';
            }

            if (isSubmitting && uploadProgress !== null && uploadProgress < 100) {
                return `Uploading ${uploadProgress}%`;
            }

            if (isSubmitting && uploadProgress === 100) {
                return 'Finishing';
            }

            return 'Submitting';
        };
        const getNextButtonText = () => {
            if (!isFinalStep) {
                return 'Next';
            }

            if (!isSubmitting || !isSending) {
                return 'Create and Send';
            }

            if (isSubmitting && uploadProgress !== null && uploadProgress < 100) {
                return `Uploading ${uploadProgress}%`;
            }

            if (isSubmitting && uploadProgress === 100) {
                return 'Finishing';
            }

            return 'Submitting';
        };

        return (
            <div className='form-edit-create-page__controls'>
                <LinkButton
                    className='form-edit-create-page__cancel-button'
                    text={getCancelButtonText()}
                    onClickHandler={this.handleCancelClick}/>
                <button
                    className={saveLaterButtonClassNames}
                    onClick={this.handleSaveLaterClick}>
                    {getSaveLaterButtonText()}
                </button>
                { isFinalStep && <button
                    className={nextButtonClassNames}
                    onClick={this.handleSendTestClick}>
                    Send Test Email
                </button>}
                <button
                    className={nextButtonClassNames}
                    onClick={isFinalStep ? this.handleCreateSendClick : this.handleNextClick}>
                    {getNextButtonText()}
                </button>
            </div>
        );
    };

    render() {
        const { model } = this.props;
        const stepIndex = model.getIn(['stepsCurrent', 'id']) - 1;
        const CurrentStepComponent = stepComponents[stepIndex];
        const currentStepProps = this.getStepProps(stepIndex);
        const currentStep = <CurrentStepComponent {...currentStepProps}/>;

        return (
            <div className='dashboard__main'>
                <div className='marketing-editor-wrapper'>
                    <div className='marketing-editor-page'>
                        {this.renderControls()}
                        <div className='form-edit-create-page__header'>
                            {this.headerTitle}
                        </div>
                        <div className='marketing-editor-page__content-header'>
                            <StepIndicator
                                onStepClick={this.handleStepClick}
                                {...model.toJS()}/>
                        </div>
                        <div className='marketing-editor-page__content'>
                            {this.isLoading() ? spinner : currentStep}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

MarketingEditorPage.propTypes = propTypes;

export default Wrapper(MarketingEditorPage);
