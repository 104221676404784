const validate = values => {
    const errors = {};
    if (!values.get('full_name')) {
        errors.full_name = 'Full Name is Required.';
    } else if (values.get('full_name').length < 5) {
        errors.full_name = 'Full Name must be 5 characters or more.';
    } else if (values.get('full_name').length > 100) {
        errors.full_name = 'Full Name must be 100 characters or less.';
    }

    if (!values.get('user_email')) {
        errors.user_email = 'Email is Required.';
    } else if (values.get('user_email').length < 5) {
        errors.user_email = 'It\'s not allowed to enter less than 5 symbols into this field';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,9}$/i.test(values.get('user_email'))) {
        errors.user_email = 'Please enter a valid e-mail address.';
    } else if (values.get('user_email').length > 254) {
        errors.user_email = 'It\'s not allowed to enter more than 254 symbols into this field';
    }

    if (!values.get('user_password')) {
        errors.user_password = 'Password is Required.';
    } else if (values.get('user_password').length < 8) {
        errors.user_password = 'Password is too short. It must contain at least 8 characters.';
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*=+.,?<>±§~`[\]{}()_\-/|':;" ]{8,}$/i.test(values.get('user_password'))) {
        errors.user_password = 'Your Password is not strong enough.';
    } else if (values.get('user_password').length > 100) {
        errors.user_password = 'Password can\'t contain more than 100 characters';
    }

    if (!values.get('phone')) {
        errors.phone = 'Phone Number is Required.';
    } else if (!/^[0-9()+-/,.]*$/i.test(values.get('phone'))) {
        errors.phone = 'Please enter a valid phone number';
    } else if (values.get('phone').replace(/[^\w\s]/gi, '').length < 5) {
        errors.phone = 'Phone Number is too short.';
    } else if (values.get('phone').replace(/[^\w\s]/gi, '').length > 15) {
        errors.phone = 'Phone Number is too large.';
    }
    return errors;
};

export default validate;
