import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { dataStates } from '../../../../../../helpers/state';
import { formatDateToSlashes } from '../../../../../../helpers/date';

const PackagesPrograms = ({ items, dataState }) => {
    const renderTable = (type, data) => {
        if (dataState === dataStates.loaded && data && data.length) {
            const columnsSummary = [
                {
                    id: 'name',
                    Header: 'Package/Program Name',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.name,
                },
                {
                    id: 'remaining',
                    Header: '# Uses Remaining',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.remaining,
                },
                {
                    id: 'value',
                    Header: 'Value ($)',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => `$${d.value}`,
                },
            ];

            const columnsDetails = [
                {
                    id: 'date',
                    Header: 'Purchased Date',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.charge_date,
                    Cell: row => (<div>{formatDateToSlashes(row.value, true)}</div>),
                },
                {
                    id: 'owner',
                    Header: 'Owner\'s Name',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.owner_name,
                },
                {
                    id: 'dog',
                    Header: 'Dog\'s Name',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.dog_name === null ? '-' : d.dog_name,
                },
                {
                    id: 'programPackageName',
                    Header: 'Package/Program(Name)',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.source_name,
                },
                {
                    id: 'expiry',
                    Header: 'Expiry',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.expiry_date,
                    Cell: row => (<div>{formatDateToSlashes(row.value, true)}</div>),
                },
                {
                    id: 'remaining',
                    Header: '# Remaining',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.remaining,
                },
            ];

            return (
                <ReactTable
                    showPagination={false}
                    defaultPageSize={data.length}
                    data={data}
                    columns={type === 'summary' ? columnsSummary : columnsDetails}/>);
        }
        return null;
    };

    return (
        <div className='custom-reports__report'>
            {items !== undefined && renderTable('summary', items.summary)}
            {items !== undefined && renderTable('details', items.details)}
        </div>
    );
};

PackagesPrograms.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    dataState: PropTypes.string.isRequired,
};

export default PackagesPrograms;
