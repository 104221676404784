import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { MAP } from 'react-google-maps/lib/constants';
import { Marker } from 'react-google-maps';
import Map from './Map/';

class PointsMap extends PureComponent {
    state = {
        pointsList: [],
    }

    componentDidMount() {
        const { points } = this.props;
        const googleMaps = window.google && window.google.maps;
        this.geocoder = new googleMaps.Geocoder();
        this.geocodeAddress(points);
    }

    componentDidUpdate(nextProps, nextState) {
        const { points } = this.props;
        this.props.points.map((point, index) => { // eslint-disable-line array-callback-return
            if (point.address !== nextProps.points[index].address) {
                this.setState({ pointsList: [] });
                this.geocodeAddress(points);
            }
        });
    }

    getMarkers() {
        const { pointsList } = this.state;

        return pointsList.map((point, index) => {
            return (
                <Marker
                    key={`marker_${index}`}
                    position={{ lat: point.position.lat(), lng: point.position.lng() }}
                    icon={this.getIcon(point.type)} />
            );
        });
    }

    getIcon(type) {
        const googleMaps = window.google && window.google.maps;
        const markerTypes = {
            default: '/assets/imgs/icon-location-default.png',
            pickup: '/assets/imgs/icon-location-orange.png',
            dropoff: '/assets/imgs/icon-location-lightblue.png',
        };
        return {
            url: markerTypes[type] || markerTypes.default,
            size: new googleMaps.Size(60, 60),
            origin: new googleMaps.Point(0, 0),
            anchor: new googleMaps.Point(30, 58)
        };
    }

    getCenter() {
        const { pointsList } = this.state;
        const googleMaps = window.google && window.google.maps;

        const LatLngList = pointsList.map((point) => {
            return new googleMaps.LatLng(point.position.lat(), point.position.lng());
        });
        const latlngbounds = new googleMaps.LatLngBounds();
        LatLngList.forEach((latLng) => {
            latlngbounds.extend(latLng);
        });
        if (this.map && pointsList.length > 0) {
            this.map.fitBounds(latlngbounds);
            const zoom = this.map.getZoom();
            this.map.context[MAP].setZoom(zoom > 10 ? 10 : zoom);
        }
        return latlngbounds.getCenter();
    }

    geocodeAddress(points) {
        points.forEach((item) => {
            this.geocoder.geocode({ 'address': item.address }, (results, status) => {
                const { pointsList } = this.state;
                const newPointsList = pointsList.slice();
                if (status === 'OK') {
                    const point = {
                        position: results[0].geometry.location,
                        type: item.type
                    };
                    newPointsList.push(point);
                    this.setState({ pointsList: newPointsList });
                }
            });
        });
    }

    render() {
        return (
            <Map
                onMapMounted={(ref) => { this.map = ref; }}
                center={this.getCenter()}
                markers={this.getMarkers()}
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '100%' }} />}
                mapElement={<div style={{ height: '100%' }} />} />
        );
    }
}

PointsMap.propTypes = {
    points: PropTypes.arrayOf(PropTypes.shape({
        address: PropTypes.string,
    })),
};

export default PointsMap;
