import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as IconCamera } from '../../../../../assets/icons/icon-camera.svg';
import Icon from '../../../../common/Icon';

const MessagesImageUpload = (props) => {
    const { image, clickHandler, deleteImage, disabled } = props;
    const getImages = () => {
        if (image !== null && (Array.isArray(image) && image.length !== 0)) {
            return (
                <div className='messages__image-preview-container'>
                    {image.map((img) => {
                        const { name, preview } = img;
                        return (
                            <div key={`messages-image-preview-${name}`} className='messages__image-preview'>
                                <div className='messages__image-preview-close' onClick={() => deleteImage(name)}/>
                                <img src={preview} alt={name}/>
                            </div>
                        );
                    })}
                </div>
            );
        }
        return null;
    };
    const messagesAddImageClassNames = classNames({
        'messages__add-image': true,
        'messages__add-image_disabled': disabled,
    });
    return (
        <div className='messages__images'>
            {getImages()}
            <div
                className={messagesAddImageClassNames}
                onClick={() => {
                    if (disabled) {
                        return null;
                    }
                    return clickHandler();
                }}>
                <Icon className='icon_message-add-image' glyph={IconCamera}/>
            </div>
        </div>
    );
};

MessagesImageUpload.propTypes = {
    image: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({})),
        PropTypes.shape({})
    ]),
    clickHandler: PropTypes.func,
    deleteImage: PropTypes.func,
    disabled: PropTypes.bool,
};

export default MessagesImageUpload;
