import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import FormsPage from '../FormsPage';
import * as FormsPageActions from '../../../../actions/dashboard/formsPageActions';
import { openUserAgreementEmployeeDeleteModalDialog } from '../../../../actions/modal/openActions';

class FormsPageContainer extends PureComponent {
    static propTypes = {
        routeParams: PropTypes.shape({}),
        forms: PropTypes.shape({}),
        resetState: PropTypes.func,
        getFormsData: PropTypes.func,
        getMoreFormsData: PropTypes.func,
        gotoFormEdit: PropTypes.func,
        openFormDeleteModalDialog: PropTypes.func
    };
    
    componentDidMount() {
        const { getFormsData } = this.props;
        getFormsData();
    }
    
    componentWillUnmount() {
        const { resetState } = this.props;
        resetState();
    }
    
    render() {
        const { forms, getFormsData, getMoreFormsData, gotoFormEdit, openFormDeleteModalDialog } = this.props;
        return (
            <FormsPage
                getData={getFormsData}
                getNext={getMoreFormsData}
                gotoFormEdit={gotoFormEdit}
                openFormDeleteModalDialog={openFormDeleteModalDialog}
                data={forms}/>
        );
    }
}

const mapStateToProps = state => {
    return {
        forms: state.getIn(['formsPage', 'forms'])
    };
};

const mapDispatchToProps = {
    resetState: FormsPageActions.resetFormsPageReducer,
    getFormsData: FormsPageActions.getFormsData,
    getMoreFormsData: FormsPageActions.getMoreFormsData,
    gotoFormEdit: FormsPageActions.gotoFormEdit,
    openFormDeleteModalDialog: openUserAgreementEmployeeDeleteModalDialog
};

export default connect(mapStateToProps, mapDispatchToProps)(FormsPageContainer);
