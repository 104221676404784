import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handleAutocompleteSelectOrEnter } from '../../../../actions/autocompleteActions';
import { createCustomersAvailableAddress } from '../../../../actions/dashboard/availableAddressesActions';
import { setPDLocationsAddModalClosed } from '../../../../actions/miscActions';
import ModalDialog from '../../../common/ModalDialog';
import PickupDropoffAddModalDialogForm from './PickupDropoffAddModalDialogForm';

// eslint-disable-next-line max-len
const PickupDropoffAddModalDialog = (props) => {
    const {
        isPickupDropoffAddModalOpened, handlePickupDropoffAddModalClosed, handleSubmit, customer,
        handleAutocompleteSelectOrEnter,
    } = props;
    const customerFullName = customer.get('full_name');
    return (
        <ModalDialog
            form={
                <PickupDropoffAddModalDialogForm
                    handleAutocompleteSelectOrEnter={handleAutocompleteSelectOrEnter}
                    onSubmit={data => handleSubmit(customer.get('id'), data.toJS())}/>
            }
            title={`Add New ${customerFullName}'s Pickup/Dropoff Location`}
            isModalOpened={isPickupDropoffAddModalOpened}
            handleModalClosed={handlePickupDropoffAddModalClosed}/>
    );
};

PickupDropoffAddModalDialog.propTypes = {
    isPickupDropoffAddModalOpened: PropTypes.bool,
    handlePickupDropoffAddModalClosed: PropTypes.func,
    handleSubmit: PropTypes.func,
    customer: PropTypes.shape({}),
    handleAutocompleteSelectOrEnter: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        customer: state.getIn(['currentCustomer', 'instance']),
        isPickupDropoffAddModalOpened: state.getIn(['misc', 'modals', 'pDLocationsAdd'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    handlePickupDropoffAddModalClosed: bindActionCreators(setPDLocationsAddModalClosed, dispatch),
    handleSubmit: bindActionCreators(createCustomersAvailableAddress, dispatch),
    handleAutocompleteSelectOrEnter: bindActionCreators(handleAutocompleteSelectOrEnter, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PickupDropoffAddModalDialog);
