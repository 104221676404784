import { createAction } from 'redux-actions';
import * as PaymentCardsTypes from '../../types/dashboard/paymentCardsTypes';
import * as PaymentCardsServices from '../../services/paymentCards';

export const markPaymentCardsLoaded = createAction(PaymentCardsTypes.MARK_PAYMENT_CARDS_LOADED);
export const unmarkPaymentCardsLoaded = createAction(PaymentCardsTypes.UNMARK_PAYMENT_CARDS_LOADED);
export const setCurrentCustomersPaymentCards = createAction(PaymentCardsTypes.SET_CURRENT_CUSTOMER_PAYMENT_CARDS);
export const addCustomersPaymentCard = createAction(PaymentCardsTypes.ADD_CUSTOMERS_PAYMENT_CARD);
export const removeCurrentCustomerPaymentCard = createAction(PaymentCardsTypes.REMOVE_CURRENT_CUSTOMER_PAYMENT_CARD);
export const setDefaultPaymentCard = createAction(PaymentCardsTypes.SET_DEFAULT_CUSTOMER_PAYMENT_CARD);

export const setDefaultCustomerPaymentCard = (id) => {
    return dispatch => {
        dispatch(PaymentCardsServices.setDefaultCustomerPaymentCard(id));
        dispatch(setDefaultPaymentCard(id));
    };
};
