import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import TemplatePreview from '../TemplateSelectorPreview';

const propTypes = {
    onClick: PropTypes.func,
    templateID: PropTypes.number,
    isActive: PropTypes.bool
};

const TemplateCard = props => {
    const { isActive, onClick, templateID } = props;
    
    const cardClassNames = classNames({
        'template-card': true,
        'template-card_active': isActive
    });
    
    return (
        <div
            onClick={onClick}
            className={cardClassNames}>
            <div className='template-card__top-border'/>
            <div className='template-card__content'>
                <TemplatePreview templateID={templateID}/>
            </div>
        </div>
    );
};

TemplateCard.propTypes = propTypes;

export default TemplateCard;
