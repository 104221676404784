const validate = values => {
    const errors = {};

    if (!/^[0-9()+-/,.]*$/i.test(values.get('primary_phone'))) {
        errors.primary_phone = 'Please enter a valid phone number';
    } else if (values.get('primary_phone').replace(/[^\w\s]/gi, '').length < 5
        && values.get('primary_phone').replace(/[^\w\s]/gi, '').length > 0) {
        errors.primary_phone = 'Phone Number is too short.';
    } else if (values.get('primary_phone').replace(/[^\w\s]/gi, '').length > 15) {
        errors.primary_phone = 'Phone Number is too large.';
    } else if (!values.get('primary_phone')) {
        errors.primary_phone = 'Primary Phone is Required.';
    }

    if (!/^[0-9()+-/,.]*$/i.test(values.get('secondary_phone'))) {
        if (values.get('secondary_phone')) {
            errors.secondary_phone = 'Please enter a valid phone number';
        }
    } else if (values.get('secondary_phone').replace(/[^\w\s]/gi, '').length < 5
        && values.get('secondary_phone').replace(/[^\w\s]/gi, '').length > 0) {
        errors.secondary_phone = 'Phone Number is too short.';
    } else if (values.get('secondary_phone').replace(/[^\w\s]/gi, '').length > 15) {
        errors.secondary_phone = 'Phone Number is too large.';
    }

    if (!values.get('email')) {
        errors.email = 'Email is Required.';
    } else if (values.get('email').length < 5) {
        errors.email = 'It\'s not allowed to enter less than 5 symbols into this field';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,9}$/i.test(values.get('email'))) {
        errors.email = 'Please enter a valid e-mail address.';
    } else if (values.get('email').length > 254) {
        errors.email = 'It\'s not allowed to enter more than 254 symbols into this field';
    }

    if (values.get('address1') || values.get('city') || values.get('state') || values.get('zip_code')) {
        if (values.get('address1') !== null) {
            if (!values.get('address1')) {
                errors.address1 = 'Location is Required.';
            } else if (values.get('address1').length > 254) {
                errors.address1 = 'It\'s not allowed to enter more than 254 symbols into this field';
            }
        }

        if (values.get('address2').length > 254) {
            errors.address2 = 'It\'s not allowed to enter more than 254 symbols into this field';
        }

        if (!values.get('city')) {
            errors.city = 'City is Required.';
        } else if (values.get('city').length > 254) {
            errors.city = 'It\'s not allowed to enter more than 254 symbols into this field';
        }

        if (!values.get('state') || Array.isArray(values.get('state'))) {
            errors.state = 'State is Required.';
        }

        if (!values.get('zip_code')) {
            errors.zip_code = 'Zip Code is Required.';
        } else if (values.get('zip_code').length > 10) {
            errors.zip_code = 'Zip Code must be 10 characters or less.';
        }

        if (values.get('location_notes') && values.get('location_notes').length > 254) {
            errors.location_notes = 'It\'s not allowed to enter more than 254 symbols into this field';
        }
    }

    return errors;
};

export default validate;
