import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setAddAddressModalClosed } from '../../../../actions/miscActions';
import { addNewBookingAddress } from '../../../../services/availableAddresses';
import ModalDialog from '../../../common/ModalDialog';
import AddAddressModalDialogForm from './AddAddressModalDialogForm';
import { handleAutocompleteSelectOrEnter } from '../../../../actions/autocompleteActions';

const AddAddressModalDialog = (props) => {
    const {
        isAddAddressModalOpened,
        setAddAddressModalClosed,
        handleSubmit,
        handleAutocompleteSelectOrEnter,
        data
    } = props;

    return (
        <ModalDialog
            form={
                <AddAddressModalDialogForm
                    onSubmit={formData => {
                        const formDataJS = formData.toJS();
                        if (data.submitAction) return data.submitAction(formDataJS);
                        return handleSubmit(formData.toJS(), data.formName);
                    }}
                    onClose={setAddAddressModalClosed}
                    handleAutocompleteSelectOrEnter={handleAutocompleteSelectOrEnter}
                    data={data}/>
            }
            headerClassName='modal-dialog__header_transparent'
            title={data.title || 'Add New Location'}
            isModalOpened={isAddAddressModalOpened}
            handleModalClosed={setAddAddressModalClosed}/>
    );
};

AddAddressModalDialog.propTypes = {
    isAddAddressModalOpened: PropTypes.bool,
    setAddAddressModalClosed: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleAutocompleteSelectOrEnter: PropTypes.func,
    data: PropTypes.shape({}),
};

const mapStateToProps = state => ({
    isAddAddressModalOpened: state.getIn(['misc', 'modals', 'addAddress', 'isOpened']),
    data: state.getIn(['misc', 'modals', 'addAddress', 'data']),
});

const mapDispatchToProps = {
    setAddAddressModalClosed,
    handleAutocompleteSelectOrEnter,
    handleSubmit: addNewBookingAddress
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAddressModalDialog);
