/* eslint-disable react/prop-types */
import classNames from 'classnames';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '../../Select/Select';
import { multiboxSelectRef, selectMultiboxRenderer } from './multiSelectBox/multiSelectBox';

// eslint-disable-next-line max-len
class renderSelectControl extends React.PureComponent {
    onCreatableBlur = () => {
        if (!this.props.value) { // eslint-disable-line
            if (this.selector && this.selector.selector) {
                this.selector.selector.inputValue = '';
            }
        }
    };

    onInputChange = (nextValue, prevValue) => {
        const { formatInput } = this.props;

        // If empty, no need to process.
        if (nextValue === '') {
            return nextValue;
        }

        // If a char was deleted.
        if (nextValue != null && prevValue != null && nextValue.length < prevValue.length) {
            return nextValue;
        }

        if (formatInput) {
            nextValue = formatInput(nextValue);
        }

        // nextValue seems invalid and couldn't be processed.
        if (nextValue === null) {
            return prevValue;
        }

        return nextValue;
    };

    getError = () => {
        const { shouldAlwaysDisplayError, meta: { touched, error } } = this.props;
        if (shouldAlwaysDisplayError || (touched && error)) {
            return <span className='input-control__error'>{error}</span>;
        }

        return null;
    };

    render() {
        const {
            name,
            input,
            label,
            meta: { touched, error },
            options,
            multi = false,
            creatable = false,
            creatableSelect = false,
            async = false,
            getOptions,
            selectedValue,
            onChangeCallBack,
            className = '',
            autocomplete,
            autofocus = false,
            searchable = false,
            optionRenderer,
            disabled = false,
            multibox = false,
            formatInput,
            noResultsText,
            ignoreCase = true,
            isLoading = false,
            autoload = false,
            withInput = false,
        } = this.props;
        const SelectClassNames = classNames({
            'select-control__select': true,
            'select-control__select_touched': touched,
            'select-control__select_failed': touched && error,
            'select-control__select_autocomplete': autocomplete,
            'select-control__select_creatable': creatable || creatableSelect,
        });

        const PlaceholderClassNames = classNames({
            'select-control__placeholder': true,
            'select-control__placeholder_touched': touched,
            'select-control__placeholder_failed': touched && error,
            'select-control__placeholder_empty':
            input.value.length === 0 && selectedValue === undefined && !(touched && error),
        });

        return (
            <div className={`select-control ${className}`}>
                {(() => {
                    if (async) {
                        if (creatable) {
                            return (
                                <Select.AsyncCreatable
                                    {...input}
                                    name={name}
                                    placeholder={false}
                                    loadingPlaceholder=''
                                    searchable
                                    clearable={false}
                                    multi={multi}
                                    ref={elem => { this.selector = elem; }} //eslint-disable-line
                                    value={input.value.toJS ? input.value.toJS() : input.value}
                                    loadOptions={getOptions}
                                    ignoreCase={ignoreCase}
                                    onChange={value => {
                                        input.onChange(value);
                                        this.selector.inputValue = '';
                                    }}
                                    onBlur={this.onCreatableBlur}
                                    onBlurResetsInput={false}
                                    className={SelectClassNames}
                                    disabled={disabled}
                                    optionRenderer={optionRenderer}/>
                            );
                        }

                        if (multibox) {
                            input.value = input.value && 'size' in input.value ? input.value.toJS() : input.value;
                            return (
                                <Select.Async
                                    {...input}
                                    name={name}
                                    optionRenderer={data => {
                                        return selectMultiboxRenderer(data, input.value);
                                    }}
                                    placeholder={false}
                                    loadingPlaceholder=''
                                    cache={{}}
                                    searchable={searchable}
                                    clearable={false}
                                    multi
                                    shouldKeepOptions
                                    value={input.value}
                                    onChange={value => {
                                        let callbackValue = value;

                                        if (Array.isArray(value)) {
                                            if (value.length === 0) {
                                                callbackValue = null;
                                            } else {
                                                value.reduce((optionsArray, optionItem) => {
                                                    optionsArray.push(Object.assign({}, optionItem, {
                                                        clearableValue: false,
                                                    }));
                                                    return optionsArray;
                                                }, []);
                                            }
                                        }
                                        input.onChange(callbackValue);
                                        if (onChangeCallBack) {
                                            onChangeCallBack(callbackValue);
                                        }
                                    }}
                                    ref={multiboxSelectRef}
                                    onBlur={() => input.onBlur(input.value)}
                                    loadOptions={getOptions}
                                    disabled={disabled}
                                    className={SelectClassNames}/>
                            );
                        }

                        const value = input.value.size ? input.value.toJS() : input.value || selectedValue;

                        if (withInput) {
                            return (
                                <Select.Async
                                    {...input}
                                    name={name}
                                    placeholder={false}
                                    loadingPlaceholder=''
                                    searchable
                                    clearable={false}
                                    disabled={disabled}
                                    multi={multi}
                                    value={value}
                                    loadOptions={getOptions}
                                    optionRenderer={optionRenderer}
                                    onChange={value => input.onChange(value)}
                                    onBlur={() => input.onBlur(input.value)}
                                    className={SelectClassNames}
                                    withInput/>
                            );
                        }

                        return (
                            <Select.Async
                                {...input}
                                name={name}
                                placeholder={false}
                                loadingPlaceholder=''
                                searchable={searchable}
                                clearable={false}
                                disabled={disabled}
                                multi={multi}
                                value={value}
                                loadOptions={getOptions}
                                optionRenderer={optionRenderer}
                                onChange={value => input.onChange(value)}
                                onBlur={() => input.onBlur(input.value)}
                                className={SelectClassNames}/>
                        );
                    }
                    if (autocomplete) {
                        const value = input.value && input.value.toJS ? input.value.toJS() : input.value;
                        return (
                            <Select.Async
                                name={name}
                                placeholder={false}
                                loadingPlaceholder=''
                                autoload={autoload}
                                autofocus={autofocus} //eslint-disable-line
                                multi={false}
                                optionRenderer={optionRenderer}
                                noResultsText={noResultsText}
                                loadOptions={getOptions}
                                cache={false}
                                ref={elem => { this.selector = elem; }}
                                disabled={disabled}
                                isLoading={isLoading}
                                value={value}
                                onChange={value => input.onChange(value)}
                                onBlur={() => input.onBlur(input.value)}
                                onBlurResetsInput={false}
                                className={SelectClassNames}/>
                        );
                    }
                    if (creatable) {
                        // Work-around for intolerance_list field. Initial value of the field is passed as an Immutable
                        // List, but on subsequent changes the value is a standard JS Array.
                        let value = [];
                        if (input.value.toJS) {
                            value = input.value.toJS();
                        } else if (input.value.length) {
                            value = input.value.map(item => {
                                if (item.toJS) {
                                    return item.toJS();
                                }
                                return item;
                            });
                        }

                        return (
                            <Select.Creatable
                                {...input}
                                name={name}
                                placeholder={false}
                                searchable
                                clearable={false}
                                disabled={disabled}
                                multi={multi}
                                value={value}
                                options={options}
                                ref={elem => { this.selector = elem; }}
                                optionRenderer={optionRenderer}
                                onChange={value => {
                                    input.onChange(value);
                                    this.selector.inputValue = '';
                                }}
                                onBlur={this.onCreatableBlur}
                                onBlurResetsInput={false}
                                className={SelectClassNames}/>
                        );
                    }
                    if (creatableSelect) {
                        return (
                            <Select.AsyncCreatable
                                {...input}
                                name={name}
                                placeholder={false}
                                searchable
                                clearable={false}
                                disabled={disabled}
                                multi={multi}
                                value={input.value && input.value.toJS ? input.value.toJS() : input.value}
                                shouldKeyDownEventCreateNewOption={({ keyCode }) => keyCode === 13}
                                ref={elem => { this.selector = elem; }}
                                onInputChange={this.onInputChange}
                                formatInput={formatInput}
                                cache={false}
                                ignoreCase={false}
                                onChange={value => { input.onChange(value); }}
                                optionRenderer={optionRenderer}
                                loadOptions={getOptions}
                                onBlur={this.onCreatableBlur}
                                onBlurResetsInput={false}
                                className={SelectClassNames}/>
                        );
                    }
                    if (multibox === true) {
                        input.value = input.value && 'size' in input.value ? input.value.toJS() : input.value;
                        return (
                            <Select
                                {...input}
                                name={name}
                                optionRenderer={data => {
                                    return selectMultiboxRenderer(data, input.value);
                                }}
                                placeholder={false}
                                searchable={searchable}
                                clearable={false}
                                multi
                                value={input.value}
                                onChange={value => {
                                    let callbackValue = value;
                                    if (Array.isArray(value)) {
                                        if (value.length === 0) {
                                            callbackValue = null;
                                        } else {
                                            value.reduce((optionsArray, optionItem) => {
                                                optionsArray.push(Object.assign({}, optionItem, {
                                                    clearableValue: false,
                                                }));
                                                return optionsArray;
                                            }, []);
                                        }
                                    }
                                    input.onChange(callbackValue);
                                    if (onChangeCallBack) {
                                        onChangeCallBack(callbackValue);
                                    }
                                }}
                                ref={multiboxSelectRef}
                                onBlur={() => input.onBlur(input.value)}
                                options={options.reduce((optionsArray, optionItem) => {
                                    optionsArray.push(Object.assign({}, optionItem, {
                                        clearableValue: false,
                                    }));
                                    return optionsArray;
                                }, [])}
                                disabled={disabled}
                                className={SelectClassNames}/>
                        );
                    }

                    return (
                        <Select
                            {...input}
                            name={name}
                            optionRenderer={optionRenderer}
                            placeholder={false}
                            searchable={searchable}
                            clearable={false}
                            multi={multi}
                            value={
                                input.value
                                    ? (Map.isMap(input.value) ? input.value.toJS() : input.value)
                                    : selectedValue}
                            onChange={value => {
                                let callbackValue = value;
                                if (Array.isArray(value)) {
                                    if (value.length === 0) {
                                        callbackValue = null;
                                    }
                                }
                                input.onChange(callbackValue);
                                if (onChangeCallBack) {
                                    onChangeCallBack(callbackValue);
                                }
                            }}
                            onBlur={() => input.onBlur(input.value)}
                            options={options}
                            isLoading={isLoading}
                            disabled={disabled}
                            className={SelectClassNames}/>
                    );
                })()}
                <span className={PlaceholderClassNames}>{label}</span>
                {this.getError()}
                {/*
                {hint && <span className='select-control__hint'>{hint}</span>}
                */}
            </div>
        );
    }
}

renderSelectControl.propTypes = {
    name: PropTypes.string,
    input: PropTypes.shape({}),
    label: PropTypes.string,
    type: PropTypes.string,
    meta: PropTypes.shape({}),
    hint: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({})),
    multi: PropTypes.bool,
    creatable: PropTypes.bool,
    searchable: PropTypes.bool,
    async: PropTypes.bool,
    shouldAlwaysDisplayError: PropTypes.bool,
    autocomplete: PropTypes.bool,
    creatableSelect: PropTypes.bool,
    autofocus: PropTypes.bool,
    getOptions: PropTypes.func,
    noResultsText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    // eslint-disable-next-line react/forbid-prop-types
    selectedValue: PropTypes.any,
    onChangeCallBack: PropTypes.func,
    formatInput: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    optionRenderer: PropTypes.any,
    multibox: PropTypes.bool,
    ignoreCase: PropTypes.bool,
    isLoading: PropTypes.bool,
    autoload: PropTypes.bool,
    withInput: PropTypes.bool,
};

export default renderSelectControl;
