import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Tooltip = ({ content, children, className }) => {
    const tooltipClassName = classNames([
        className,
        {
            'tooltip': true
        }
    ]);
    return (
        <div className={tooltipClassName}>
            {children}
            <div className='tooltip__content'>{content}</div>
        </div>
    );
};

Tooltip.propTypes = {
    content: PropTypes.node,
    children: PropTypes.node,
    className: PropTypes.string,
};

export default Tooltip;
