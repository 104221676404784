import { fromJS } from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    openCustomersChangePackageExpirationDateModalDialog,
    openCustomersManagePackageCreditsModalDialog,
    openCustomersPackageDeleteModalDialog,
    openCustomersChangePackageTrainerModalDialog
} from '../../../../../../actions/modal/openActions';
import { isTrainer, isEmployee } from '../../../../../../helpers/userRoles';
import { ReactComponent as IconDots } from '../../../../../../assets/icons/icon-dots.svg';
import { ReactComponent as IconEdit } from '../../../../../../assets/icons/icon-edit.svg';
import { ReactComponent as IconTimer } from '../../../../../../assets/icons/icon-timer.svg';
import { ReactComponent as IconTrash } from '../../../../../../assets/icons/icon-trash.svg';
import Dropdown from '../../../../../common/Dropdown';
import Table from '../../../../../common/Table';

class PackagesTrainingsTable extends Table {
    static propTypes = {
        data: PropTypes.shape({}).isRequired,
        dataName: PropTypes.string.isRequired,
        openDeletePackage: PropTypes.func,
        openEditExpirationDate: PropTypes.func,
        openEditTrainer: PropTypes.func,
        openManageCredits: PropTypes.func,
        currentCustomerId: PropTypes.number,
    };

    render() {
        const { isScrollable } = this.state;
        const {
            data,
            dataName,
            openDeletePackage,
            openEditExpirationDate,
            openEditTrainer,
            openManageCredits,
            currentCustomerId
        } = this.props;
        const columns = [
            {
                id: 'packagesTrainingsName',
                Header: 'Name',
                headerClassName: 'packages-trainings-table__th',
                className: 'packages-trainings-table__td',
                accessor: d => d.get('name'),
            },
            {
                id: 'packagesTrainingsType',
                Header: 'Type',
                headerClassName: 'packages-trainings-table__th',
                className: 'packages-trainings-table__td',
                accessor: d => d.get('model_type'),
                Cell: row =>
                (<div>
                    {row.value === 'purchasedpackage'
                        ? 'Package'
                        : 'Training Program'
                    }
                </div>),
            },
            {
                id: 'packagesTrainingsDog',
                Header: 'Dog',
                headerClassName: 'packages-trainings-table__th',
                className: 'packages-trainings-table__td',
                accessor: d => d.toJS(),
                Cell: row =>
                (<div>
                    {row.value.dog === null
                        ? 'N/A'
                        : row.value.dog_detail.name
                    }
                </div>),
            },
            {
                id: 'packagesTrainingsTrainer',
                Header: 'Trainer',
                headerClassName: 'packages-trainings-table__th',
                className: 'packages-trainings-table__td',
                accessor: d => d.toJS(),
                Cell: row =>
                (<div>
                    {row.value.trainer === null
                        ? 'N/A'
                        : row.value.trainer_detail.full_name
                    }
                </div>),
            },
            {
                id: 'packagesTrainingsRemainingPurchased',
                Header: 'Purchased/Remaining',
                headerClassName: 'packages-trainings-table__th',
                className: 'packages-trainings-table__td',
                accessor: d => d.toJS(),
                Cell: row => <div>{`${row.value.quantity_purchased}/${row.value.available_quantity}`}</div>,
            },
            {
                id: 'packagesTrainingsExpiration',
                Header: 'Expiration Date',
                headerClassName: 'packages-trainings-table__th',
                className: 'packages-trainings-table__td',
                accessor: d => d.get('expires_at'),
                Cell: row => moment(row.value).format('MM/DD/YY'),
            }
        ];
        if (!isTrainer()) {
            columns.push({
                id: 'packagesTrainingsDropdownMenu',
                sortable: false,
                Header: '',
                headerClassName: 'classes-table__th',
                className: 'classes-table__td',
                width: 50,
                accessor: d => d,
                Cell: row =>
                (
                    <Dropdown
                        classNames='dropdown_table-packages-trainings'
                        icon={IconDots}
                        iconClass='icon_chevron_down'
                        items=
                        {!isEmployee() && row.row.packagesTrainingsType !== 'purchasedpackage' ?
                            [
                                {
                                    iconClass: 'icon_edit',
                                    icon: IconEdit,
                                    text: 'Edit Trainer',
                                    onClickCB: () => {
                                        const valueJS = row.value.toJS();
                                        valueJS.customerId = currentCustomerId;
                                        openEditTrainer(fromJS(valueJS));
                                    },
                                },
                                {
                                    iconClass: 'icon_edit',
                                    icon: IconEdit,
                                    text: 'Manage Credits',
                                    onClickCB: () => {
                                        const valueJS = row.value.toJS();
                                        valueJS.customerId = currentCustomerId;
                                        openManageCredits(fromJS(valueJS));
                                    },
                                },
                                {
                                    iconClass: 'icon_timer',
                                    icon: IconTimer,
                                    text: 'Change Expiration',
                                    onClickCB: () => {
                                        const valueJS = row.value.toJS();
                                        valueJS.customerId = currentCustomerId;
                                        openEditExpirationDate(fromJS(valueJS));
                                    },
                                },
                                {
                                    iconClass: 'icon_trash',
                                    icon: IconTrash,
                                    text: 'Delete',
                                    onClickCB: () => {
                                        const valueJS = row.value.toJS();
                                        valueJS.customerId = currentCustomerId;
                                        openDeletePackage(fromJS(valueJS));
                                    },
                                },
                            ]
                            :
                            [
                                {
                                    iconClass: 'icon_edit',
                                    icon: IconEdit,
                                    text: 'Manage Credits',
                                    onClickCB: () => {
                                        const valueJS = row.value.toJS();
                                        valueJS.customerId = currentCustomerId;
                                        openManageCredits(fromJS(valueJS));
                                    },
                                },
                                {
                                    iconClass: 'icon_timer',
                                    icon: IconTimer,
                                    text: 'Change Expiration',
                                    onClickCB: () => {
                                        const valueJS = row.value.toJS();
                                        valueJS.customerId = currentCustomerId;
                                        openEditExpirationDate(fromJS(valueJS));
                                    },
                                },
                                {
                                    iconClass: 'icon_trash',
                                    icon: IconTrash,
                                    text: 'Delete',
                                    onClickCB: () => {
                                        const valueJS = row.value.toJS();
                                        valueJS.customerId = currentCustomerId;
                                        openDeletePackage(fromJS(valueJS));
                                    },
                                },
                            ]
                        }
                    />),

            });
        }
        return (
            <div className='packages-trainings-table'>
                <div className='packages-trainings-table__header'>
                    <div className='packages-trainings-table__header-content'>
                        {dataName}
                    </div>
                </div>
                {data.size
                    ? (this.renderTable({ isScrollable, columns }))
                    : (<div className='packages-trainings-table__empty-content'>
                        {`No data for ${dataName}`}
                    </div>)}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    openDeletePackage: bindActionCreators(openCustomersPackageDeleteModalDialog, dispatch),
    openEditExpirationDate: bindActionCreators(openCustomersChangePackageExpirationDateModalDialog, dispatch),
    openEditTrainer: bindActionCreators(openCustomersChangePackageTrainerModalDialog, dispatch),
    openManageCredits: bindActionCreators(openCustomersManagePackageCreditsModalDialog, dispatch),
});

export default connect(null, mapDispatchToProps)(PackagesTrainingsTable);
