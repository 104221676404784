import { createAction } from 'redux-actions';
import { push } from 'react-router-redux';
import reducer from './reducer';
import * as Types from './types';
import * as Api from './api';
import { setGlobalError } from '../../actions/errorHandlerActions';

export const resetReducer = createAction(Types.RESET_REDUCER);
export const startListRequest = createAction(Types.START_LIST_REQUEST);
export const finishListRequest = createAction(Types.FINISH_LIST_REQUEST);
export const updateModel = createAction(Types.UPDATE_MODEL);
export const removeItemByID = createAction(Types.REMOVE_ITEM_BY_ID);

export const fetchList = (query, shouldAppend = false) => {
    return async (dispatch) => {
        dispatch(startListRequest());
        try {
            const { data: { count: pageSize, next, results: list } } = await dispatch(Api.fetchCampaignList(query));
            dispatch(finishListRequest({
                pageSize,
                nextQuery: next ? next.split('?')[1] : null,
                list,
                shouldAppend
            }));
        } catch (error) {
            dispatch(finishListRequest());
            setGlobalError(error);
        }
    };
};

export const initState = () => {
    return (dispatch) => {
        return dispatch(fetchList());
    };
};

export const fetchPage = () => {
    return (dispatch, getState) => {
        if (getState().getIn([reducer.__name, 'isLoading'])) {
            return;
        }

        const query = getState().getIn([reducer.__name, 'nextQuery']);
        if (!query) {
            return;
        }
        dispatch(fetchList(query, true));
    };
};

export const gotoCampaignEdit = id => {
    return (dispatch) => {
        dispatch(push(`/dashboard/marketing/${id}`));
    };
};
