import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as VeterinarianTypes from '../types/onboarding/veterinarianTypes';
import { phoneNumberNormalizer } from '../helpers/normalize';

const INITIAL_STATE = fromJS({
    veterinarian: '',
    can_contact_veterinarian: true,
    send_dogs_records_later: true,
    isRecordsLoading: false
});

export const VeterinarianDataNormalizer = formData => {
    formData = formData.toJS();
    const newInstance = {};

    Object.keys(formData).forEach(key => {
        if (key === 'phone_number') {
            newInstance[key] = phoneNumberNormalizer(formData[key]);
            return;
        }

        newInstance[key] = formData[key];
    });

    return newInstance;
};

const veterinarianReducer = handleActions({
    [VeterinarianTypes.SET_DEFAULT_VET_DATA]: (state, action) => (
        state.set('can_contact_veterinarian', true)
    ),
    [VeterinarianTypes.SET_VET_INFO]: (state, action) => (
        state
            .set('veterinarian', action.payload.get('veterinarian').value)
            .set('can_contact_veterinarian', action.payload.get('can_contact_veterinarian') === 'Yes')
            .set('set_dogs_records_later', action.payload.get('records') === null)
    ),
    // #todo: double check this logic
    [VeterinarianTypes.ADD_NEW_VETERINARIAN_OPTION]: (state, action) => (
        state.set('options', state.get('options').insert(0, action.payload))
    ),
    [VeterinarianTypes.START_RECORDS_LOADING]: (state, action) => (
        state.set('isRecordsLoading', true)
    ),
    [VeterinarianTypes.FINISH_RECORDS_LOADING]: (state, action) => (
        state.set('isRecordsLoading', false)
    )
}, INITIAL_STATE);

export default veterinarianReducer;
