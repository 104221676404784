import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form/immutable';
import { ReactComponent as IconAmericanExpress } from '../../../../assets/icons/icon-american-express-gray.svg';
import { ReactComponent as IconLocation } from '../../../../assets/icons/icon-location.svg';
import { ReactComponent as IconMasterCard } from '../../../../assets/icons/icon-master-card-gray.svg';
import { ReactComponent as IconNeutralCard } from '../../../../assets/icons/icon-neutral-card-gray.svg';
import { ReactComponent as IconVisa } from '../../../../assets/icons/icon-visa-gray.svg';
import { createFieldsObject } from '../../../../helpers/autocomplete';
import { PDLocationFields } from '../../../../helpers/options';
import * as ValidatorFactory from '../../../../helpers/validate';
import { getAvailableAddress } from '../../../../services/availableAddresses';
import Icon from '../../../common/Icon';
import renderSelectControl from '../SelectControl';
import NewPDLocation from './NewPDLocation';

const CARD_ICONS = {
    'Visa': <Icon glyph={IconVisa} className='icon_card'/>,
    'MasterCard': <Icon glyph={IconMasterCard} className='icon_card'/>,
    'American Express': <Icon glyph={IconAmericanExpress} className='icon_card'/>,
    'Scheduled': <Icon glyph={IconNeutralCard} className='icon_card'/>,
};

const customEditUpcomingClassesOptionRenderer = data => {
    return (
        <div className='select-control__upcoming-classes-option'>
            {data.extra && <Icon className='icon_location' glyph={data.extra}/>}
            {data.add && data.add}
            {data.type && CARD_ICONS[data.type]}
            <div className='select-control__label'>{data.label}</div>
        </div>
    );
};

class renderPickupDropoffControl extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string,
        input: PropTypes.shape({}),
        label: PropTypes.string,
        meta: PropTypes.shape({}),
        formProps: PropTypes.shape({}),
        helper: PropTypes.string,
        formError: PropTypes.string,
        isOpened: PropTypes.bool,
        ownerId: PropTypes.number,
        eventZipCodes: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.shape({}),
        ]),
        form: PropTypes.string,
    };

    state = {
        pickupAddressOptions: [],
        dropoffAddressOptions: [],
        currentPickupAddress: {},
        currentDropoffAddress: {},
        dropoffLocationIsTheSame: false,
        pickupLocationIsTheSame: false,
    };

    componentWillReceiveProps(nextProps) {
        const { isOpened, ownerId } = this.props;

        if (nextProps.isOpened === true && isOpened !== nextProps.isOpened) {
            this.setState({
                currentPickupAddress: {},
                currentDropoffAddress: {},
                dropoffLocationIsTheSame: false,
                pickupLocationIsTheSame: false,
            });
        }

        if (ownerId !== nextProps.ownerId || this.state.pickupAddressOptions.length === 0) {
            this.getAddresses(nextProps.ownerId);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentPickupAddress, currentDropoffAddress } = this.state;

        if (
            (prevState.currentPickupAddress.value === 123456 &&
                currentPickupAddress.value !== prevState.currentPickupAddress.value) ||
            (prevState.currentDropoffAddress.value === 123456 &&
                currentDropoffAddress.value !== prevState.currentDropoffAddress.value)
        ) {
            this.setState({
                dropoffLocationIsTheSame: false,
                pickupLocationIsTheSame: false,
            });
        }
    }

    getAddresses = (id) => {
        getAvailableAddress(id)
            .then(response => {
                const addressesArray =
                    response.results !== undefined
                        ? response.results
                        : response.data.results !== undefined ? response.data.results : [];
                const availableAddressesOptions = addressesArray.map(address => {
                    const address2 = address.address2 === null ? '' : ` ${address.address2}`;
                    return {
                        value: address.id,
                        label: `${address.address1}${address2}, \
                                    ${address.city} ${address.state} \
                                    ${address.zip_code}`,
                        extra: IconLocation,
                        detail: address,
                        zip_code: address.zip_code,
                    };
                });
                availableAddressesOptions.push({
                    value: 123456,
                    label: 'Add New Location',
                    add: <div className='add-icon'/>,
                });
                this.setState({
                    pickupAddressOptions: availableAddressesOptions,
                    dropoffAddressOptions: availableAddressesOptions,
                });
            })
            .catch(err => {
                console.log(err);
                console.log(err.response);
                if (err.response) {
                    if (err.response.status !== 200) {
                        console.log('Unexpected error code from the API server: ', err.response.status);
                    }
                }
            });
    };

    getValidators = ({ eventZipCodes, fieldName, isSameValue }) => {
        if (isSameValue) {
            return undefined;
        }
        if (eventZipCodes !== undefined) {
            return [
                ValidatorFactory.requiredValidatorSelect(fieldName),
                ValidatorFactory.addressZipValueValidator(eventZipCodes),
            ];
        }
        return [ValidatorFactory.requiredValidatorSelect(fieldName)];
    };

    handleSame = (value, type) => {
        const { formProps } = this.props;

        if (type === 'new_drop_off_location') {
            this.setState({
                pickupLocationIsTheSame: value,
                currentPickupAddress: {},
            });
            formProps.change('pickup_location', undefined);
        }

        if (type === 'new_pickup_location') {
            this.setState({
                dropoffLocationIsTheSame: value,
                currentDropoffAddress: {},
            });
            formProps.change('drop_off_location', undefined);
        }

        setTimeout(() => {
            formProps.handleSubmit(() => {})();
        }, 10);
    };

    render() {
        const {
            pickupAddressOptions,
            dropoffAddressOptions,
            currentPickupAddress,
            currentDropoffAddress,
            dropoffLocationIsTheSame,
            pickupLocationIsTheSame,
        } = this.state;
        const { eventZipCodes, form } = this.props;
        const newPickupLocation = 'new_pickup_location';
        const newDropOffLocation = 'new_drop_off_location';
        return (
            <div className='form__column form__column_block'>
                <Field
                    name='pickup_location'
                    type='text'
                    component={renderSelectControl}
                    optionRenderer={customEditUpcomingClassesOptionRenderer}
                    onChangeCallBack={value => {
                        this.setState({
                            currentPickupAddress: value,
                        });
                    }}
                    options={pickupAddressOptions}
                    label='Pickup Location'
                    disabled={pickupLocationIsTheSame}
                    shouldAlwaysDisplayError={false}
                    validate={this.getValidators({
                        eventZipCodes, fieldName: 'Pickup Location', isSameValue: pickupLocationIsTheSame
                    })}/>
                {currentPickupAddress != undefined && // eslint-disable-line
                currentPickupAddress.value === 123456 &&
                !pickupLocationIsTheSame && (
                    <NewPDLocation
                        locationType={newPickupLocation}
                        changeSameAddress={this.handleSame}
                        sameChecked={dropoffLocationIsTheSame}
                        eventZipCodes={eventZipCodes}
                        formName={form}
                        fields={createFieldsObject(PDLocationFields, newPickupLocation, true, false)}/>
                )}
                <Field
                    name='drop_off_location'
                    type='text'
                    component={renderSelectControl}
                    optionRenderer={customEditUpcomingClassesOptionRenderer}
                    onChangeCallBack={value => {
                        this.setState({
                            currentDropoffAddress: value,
                        });
                    }}
                    options={dropoffAddressOptions}
                    label='Dropoff Location'
                    disabled={dropoffLocationIsTheSame}
                    shouldAlwaysDisplayError={false}
                    validate={this.getValidators({
                        eventZipCodes, fieldName: 'Dropoff Location', isSameValue: dropoffLocationIsTheSame
                    })}/>
                {currentDropoffAddress != undefined && // eslint-disable-line
                currentDropoffAddress.value === 123456 &&
                !dropoffLocationIsTheSame && (
                    <NewPDLocation
                        locationType={newDropOffLocation}
                        changeSameAddress={this.handleSame}
                        sameChecked={pickupLocationIsTheSame}
                        eventZipCodes={eventZipCodes}
                        formName={form}
                        fields={createFieldsObject(PDLocationFields, newDropOffLocation, true, false)}/>
                )}
            </div>
        );
    }
}

export default renderPickupDropoffControl;
