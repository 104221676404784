import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Provider } from 'react-redux';
import Form, { mockFormState } from '../Form';
import Logo from '../common/Logo';
import { getFormConfig } from './form';

const dummyStore = mockFormState();

const propTypes = {
    templateID: PropTypes.number
};

const TemplateSelectorPreview = ({ templateID }) => {
    const previewContainerClassName = classNames(
        'template-selector-preview-container',
        `template${templateID}-selector-preview-container`
    );
    return (
        <Provider store={dummyStore}>
            <div className={previewContainerClassName}>
                <Logo logoClassName='logo template-selector-preview__logo'/>
                <Form
                    isPreview
                    templateID={templateID}
                    formConfig={getFormConfig(templateID)}
                    destroyOnUnmount={false}
                    onGetOptions={() => []}/>
            </div>
        </Provider>
    );
};

TemplateSelectorPreview.propTypes = propTypes;

export default TemplateSelectorPreview;
