import PropTypes from 'prop-types';
import React from 'react';
import ClassEventSlider from '../ClassEventSlider';
import TrainingEventSlider from '../TrainingEventSlider';
import Loader from '../../../../common/Loader/Loader';
import Icon from '../../../../common/Icon';
import { ReactComponent as IconClose } from '../../../../../assets/icons/icon-remove.svg';

const DropdownModal = ({ onClose, selectedZoneCard, glanceDataLocation, type }) => {
    const renderContent = () => {
        if (glanceDataLocation !== undefined && glanceDataLocation.get('isLoading') && type === "class") {
            return (
                <div className='dropdown-modal__empty'>
                    <Loader
                        isVisible
                        loadingText='Loading Classes Data...'
                        colorClassName='icon_loader-black'
                        modifierClassName='dropdown-modal__loader'/>
                </div>
            );
        }

        if (glanceDataLocation !== undefined && glanceDataLocation.get('isLoading') && type === "training") {
            return (
                <div className='dropdown-modal__empty'>
                    <Loader
                        isVisible
                        loadingText='Loading Trainings Data...'
                        colorClassName='icon_loader-black'
                        modifierClassName='dropdown-modal__loader'/>
                </div>
            );
        }
        
        if (glanceDataLocation !== undefined && !glanceDataLocation.get('items').size && type === "class") {
            return (
                <div className='dropdown-modal__empty'>
                    <div className='dropdown-modal__empty-title'>
                        No classes today
                    </div>
                    <div className='dropdown-modal__empty-text'>
                        Sorry, there are no classes today.<br/>
                        Try checking another neighborhood on another<br/>
                        date to see more classes.
                    </div>
                </div>
            );
        }

        if (glanceDataLocation !== undefined && !glanceDataLocation.get('items').size && type === "training") {
            return (
                <div className='dropdown-modal__empty'>
                    <div className='dropdown-modal__empty-title'>
                        No trainings today
                    </div>
                    <div className='dropdown-modal__empty-text'>
                        Sorry, there are no trainings today.<br/>
                        Try checking another date to see more trainings.
                    </div>
                </div>
            );
        }

        if (glanceDataLocation !== undefined && type === "class") {
            return <ClassEventSlider items={glanceDataLocation.get('items')}/>;
        }
        if (glanceDataLocation !== undefined && type === "training") {
            return <TrainingEventSlider items={glanceDataLocation.get('items')}/>;
        }

    };

    const class_or_training = type === "class" ? "Scheduled Classes" : "Scheduled Trainings"

    return (
        <div className='dropdown-modal'>
            <div className='dropdown-modal__title'>
                {class_or_training}
            </div>
            <button className='dropdown-modal__close' onClick={onClose}>
                <Icon glyph={IconClose} className='icon_remove-small' />
            </button>
            {renderContent()}
        </div>
    );
};

DropdownModal.propTypes = {
    selectedZoneCard: PropTypes.shape({}).isRequired,
    glanceDataLocation: PropTypes.shape({}).isRequired,
    type: PropTypes.shape({}).isRequired,
    onClose: PropTypes.func.isRequired,
    push: PropTypes.func,
};

export default DropdownModal;
