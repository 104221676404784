const validate = values => {
    const errors = {};
    // full name
    if (!values.get('full_name')) {
        errors.full_name = 'Name is Required';
    } else if (values.get('full_name').length > 100) {
        errors.full_name = 'It\'s not allowed to enter more than 100 symbols into this field';
    }

    // email
    if (!values.get('email')) {
        errors.email = 'Email is Required.';
    } else if (values.get('email').length < 5) {
        errors.email = 'It\'s not allowed to enter less than 5 symbols into this field';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.get('email'))) {
        errors.email = 'Please enter a valid e-mail address.';
    } else if (values.get('email').length > 254) {
        errors.email = 'It\'s not allowed to enter more than 254 symbols into this field';
    }

    // password
    if (!values.get('password')) {
        errors.password = 'Password is Required.';
    } else if (values.get('password').length < 8) {
        errors.password = 'Password is too short. It must contain at least 8 characters.';
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*=+., ]{8,}$/i.test(values.get('password'))) {
        errors.password = 'Your Password is not strong enough.';
    } else if (values.get('password').length > 100) {
        errors.password = 'Password can\'t contain more than 100 characters';
    }

    // phone
    if (!values.get('phone')) {
        errors.phone = 'Phone is Required.';
    } else if (!/^[0-9()+-/,.]*$/i.test(values.get('phone'))) {
        errors.phone = 'Please enter a valid phone number';
    } else if (values.get('phone').replace(/[^\w\s]/gi, '').length < 5) {
        errors.phone = 'Phone Number is too short.';
    } else if (values.get('phone').replace(/[^\w\s]/gi, '').length > 15) {
        errors.phone = 'Phone Number is too large.';
    }

    // emergency contact
    if (!values.get('emergency_contact')) {
        errors.emergency_contact = 'Emergency Contact is Required.';
    } else if (values.get('emergency_contact').length > 255) {
        errors.emergency_contact = 'It\'s not allowed to enter more than 100 symbols into this field';
    }

    // emergency phone
    if (!values.get('emergency_phone')) {
        errors.emergency_phone = 'Emergency Phone is Required.';
    } else if (!/^[0-9()+-/,.]*$/i.test(values.get('emergency_phone'))) {
        errors.emergency_phone = 'Please enter a valid phone number';
    } else if (values.get('emergency_phone').replace(/[^\w\s]/gi, '').length < 5) {
        errors.emergency_phone = 'Emergency Phone is too short.';
    } else if (values.get('emergency_phone').replace(/[^\w\s]/gi, '').length > 15) {
        errors.emergency_phone = 'Emergency Phone is too large.';
    }

    return errors;
};

export default validate;
