/* eslint-disable camelcase */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as IconNewCustomers } from '../../../../../assets/icons/icon-new-customers.svg';
import { ReactComponent as IconNewDogs } from '../../../../../assets/icons/icon-new-dog.svg';
import { ReactComponent as IconNewPackages } from '../../../../../assets/icons/icon-new-packages.svg';
import { ReactComponent as IconNewTrainings } from '../../../../../assets/icons/icon-new-training.svg';
import { dataStates } from '../../../../../helpers/state';
import { getUserNameSpace } from '../../../../../helpers/userRoles';
import Fader from '../../../../common/Fader';
import * as FormFactory from '../../../../common/Form/formFactory';
import Icon from '../../../../common/Icon';
import Loader from '../../../../common/Loader';
import FormContainer from '../../../FormContainer';
import BookingsByTrainer from '../Charts/BookingsByTrainer';
import ClassReservationsByZone from '../Charts/ClassReservationsByZone';
import CustomLineChart from '../Charts/CustomLineChart';
import ReportCards from '../Charts/ReportCards';

const newCustomersLink = [
    { key: 'type', value: 'marketing' }, 
    { key: 'report', value: 'new_customer_statistics' },
];

const newObjectsMisc = {
    new_customers_count: { text: 'New Customers', icon: IconNewCustomers, className: 'icon_new-customers', reportLink: newCustomersLink },
    new_dogs_count: { text: 'New Dogs', icon: IconNewDogs, className: 'icon_new-dogs', reportLink: newCustomersLink },
    new_sold_packages_count: { text: 'New Packages Sold', icon: IconNewPackages, className: 'icon_new-sold-packages' },
    new_sold_trainings_count: {
        text: 'New Training Programs Sold',
        icon: IconNewTrainings,
        className: 'icon_new-sold-trainings',
    },
};

class OverviewPage extends React.PureComponent {
    static propTypes = {
        reports: PropTypes.shape({}),
        overview: PropTypes.shape({}),
        formState: PropTypes.shape({
            OverviewFilterForm: PropTypes.shape({}),
        }),
        getAllOverviewData: PropTypes.func.isRequired,
        openCustomReports: PropTypes.func.isRequired,
    };

    state = {
        classReservationsChartWidth: 0,
        classReservationsChartHeight: 0,
        reportCardsChartWidth: 0,
        classReservationsByZoneChartHeight: 0,
        bookingsByTrainerChartWidth: 0,
        bookingsByTrainerChartHeight: 0,
        totalRevenueChartWidth: 0,
        totalRevenueChartHeight: 0,
    };

    componentDidMount() {
        // all of the charts must have width/height precalculated since its an svg elements
        setTimeout(() => {
            const classReservationsChartElement = document.getElementsByClassName('class-reservations__loader');
            if (classReservationsChartElement[0] !== undefined) {
                this.setState({
                    classReservationsChartWidth: classReservationsChartElement[0].offsetWidth,
                    classReservationsChartHeight: classReservationsChartElement[0].offsetHeight,
                });
            }

            const reportCardsChartElement = document.getElementsByClassName('report-cards__loader');
            if (reportCardsChartElement[0] !== undefined) {
                this.setState({ reportCardsChartWidth: reportCardsChartElement[0].offsetWidth });
            }

            const classReservationsByZoneChartElement =
                document.getElementsByClassName('class-reservations-by-zone__loader');
            if (classReservationsByZoneChartElement[0] !== undefined) {
                this.setState({
                    classReservationsByZoneChartHeight: classReservationsByZoneChartElement[0].offsetHeight,
                });
            }

            const bookingsByTrainerChartElement = document.getElementsByClassName('bookings-by-trainer__loader');
            if (bookingsByTrainerChartElement[0] !== undefined) {
                this.setState({
                    bookingsByTrainerChartWidth: bookingsByTrainerChartElement[0].offsetWidth,
                    bookingsByTrainerChartHeight: bookingsByTrainerChartElement[0].offsetHeight,
                });
            }

            const totalRevenueChartElement = document.getElementsByClassName('total-revenue__loader');
            if (totalRevenueChartElement[0] !== undefined) {
                this.setState({
                    totalRevenueChartWidth: totalRevenueChartElement[0].offsetWidth,
                    totalRevenueChartHeight: totalRevenueChartElement[0].offsetHeight,
                });
            }
        }, 500);
    }

    normalizeItems = (items, type) => {
        if (!items.length) {
            if (type === 'CustomLineChart') {
                // eslint-disable-next-line react/prop-types
                items.push({ date: this.props.reports.start, value: 0 });
            }
            if (type === 'BookingsByTrainer') {
                items.push({ trainer: 'No Bookings', value: 1 });
            }
        }
    };

    renderLoader = (data, text) => {
        if (data.dataState === dataStates.loading) {
            return (<div className='loader__content'>
                <Loader
                    isVisible
                    loadingText={text}
                    colorClassName='icon_messages-loader-black'
                    modifierClassName='icon_messages-loader'/>
            </div>);
        }

        return null;
    };

    renderNewObjects = (newObjects) => {
        const { reports, openCustomReports } = this.props;
        const { dataState, items } = newObjects;
        const { notAsked, loading, loaded, failed } = dataStates;
        if (dataState === notAsked || dataState === loading) {
            return null;
        }

        if (dataState === loaded && items !== undefined) {
            return Object.keys(items).map((itemName) => {
                const { className, text, icon, reportLink } = newObjectsMisc[itemName];
                let onClick;
                let pointerStyle;
                if (reportLink) {
                    const dateRange = { 'start': reports.get('start'), 'end': reports.get('end') };
                    const reportAttrs = [...reportLink, { key: 'date_range', value: dateRange }];
                    onClick = () => openCustomReports(reportAttrs);
                    pointerStyle = { cursor: 'pointer' };
                }
                return (
                    <div className='new-objects__item' style={pointerStyle} key={itemName} onClick={onClick}>
                        <div className='new-objects__item-content'>
                            <Icon glyph={icon} className={className}/>
                            <div className='new-objects__item-text'>{text}</div>
                            <div className='new-objects__item-amount'>{items[itemName]}</div>
                        </div>
                    </div>
                );
            });
        }

        if (items !== undefined) {
            if (dataState === loaded && !items.length) {
                return <div>No New Object</div>;
            }
        }

        if (dataState === failed) {
            return <div>Something Went Wrong</div>;
        }

        return null;
    };

    renderClassReservations = (data) => {
        const { dataState, items } = data;
        this.normalizeItems(items, 'CustomLineChart');
        const { notAsked, loading, loaded, failed } = dataStates;
        if (dataState === notAsked || dataState === loading) {
            return null;
        }

        if (dataState === loaded && items.length) {
            const { classReservationsChartWidth, classReservationsChartHeight } = this.state;
            return (
                <CustomLineChart
                    title='Dog Attendance'
                    isAbsolute
                    width={classReservationsChartWidth}
                    height={classReservationsChartHeight}
                    data={items}/>);
        }

        if (dataState === failed) {
            return <div>Something Went Wrongs</div>;
        }

        return <div>No Class Reservations</div>;
    };

    renderReportCards = (data) => {
        const { dataState, items } = data;
        const { notAsked, loading, loaded, failed } = dataStates;

        if (dataState === notAsked || dataState === loading) {
            return null;
        }

        if (dataState === loaded && items !== undefined) {
            return <ReportCards data={items} width={this.state.reportCardsChartWidth}/>;
        }

        if (items !== undefined) {
            if (dataState === loaded && !items.length) {
                return <div>No Report Cards Data</div>;
            }
        }

        if (dataState === failed) {
            return <div>Something Went Wrong</div>;
        }

        return null;
    };

    renderClassReservationsByZone = (data) => {
        const { dataState, items } = data;
        const { notAsked, loading, loaded, failed } = dataStates;
        if (dataState === notAsked || dataState === loading) {
            return null;
        }

        if (dataState === loaded && items.length) {
            const { classReservationsChartWidth, classReservationsByZoneChartHeight } = this.state;
            return (
                <ClassReservationsByZone
                    width={classReservationsChartWidth}
                    height={classReservationsByZoneChartHeight}
                    data={items}/>);
        }

        if (dataState === failed) {
            return <div>Something Went Wrongs</div>;
        }

        return <div>No Class Reservations By Zone</div>;
    };

    renderBookingsByTrainer = (data) => {
        const { dataState, items } = data;
        this.normalizeItems(items, 'BookingsByTrainer');
        const { notAsked, loading, loaded, failed } = dataStates;
        if (dataState === notAsked || dataState === loading) {
            return null;
        }

        const { bookingsByTrainerChartWidth, bookingsByTrainerChartHeight } = this.state;
        if (dataState === loaded && items.length) {
            return (
                <BookingsByTrainer
                    width={bookingsByTrainerChartWidth}
                    height={bookingsByTrainerChartHeight}
                    data={items}/>);
        }

        if (dataState === failed) {
            return <div>Something Went Wrongs</div>;
        }

        return <div>No Bookings By Trainer</div>;
    };

    renderTotalRevenue = (data) => {
        const { dataState, items } = data;
        this.normalizeItems(items, 'CustomLineChart');
        const { notAsked, loading, loaded, failed } = dataStates;
        if (dataState === notAsked || dataState === loading) {
            return null;
        }

        if (dataState === loaded && items.length) {
            const { totalRevenueChartWidth, totalRevenueChartHeight } = this.state;
            return (
                <CustomLineChart
                    title='Total Revenue'
                    width={totalRevenueChartWidth}
                    height={totalRevenueChartHeight}
                    data={items}/>);
        }

        if (dataState === failed) {
            return <div>Something Went Wrongs</div>;
        }

        return <div>No Total Revenue</div>;
    };

    renderNewObjectsLayer = (newObjects) => {
        const newObjectsClassNames = classNames({
            'overview__new-objects': true,
            'overview__new-objects_loading': newObjects.dataState === dataStates.loading,
        });
        return (
            <div className={newObjectsClassNames}>
                <Fader className='new-objects__loader'>
                    {this.renderLoader(newObjects, 'Loading New Objects Data')}
                </Fader>
                <Fader className='new-objects__content'>
                    {this.renderNewObjects(newObjects)}
                </Fader>
            </div>
        );
    };

    renderClassReservationsLayer = (classReservations) => {
        const classReservationsClassNames = classNames({
            'charts__class-reservations': true,
            'charts__class-reservations_loading': classReservations.dataState === dataStates.loading,
        });
        return (
            <div className={classReservationsClassNames}>
                <Fader className='class-reservations__loader'>
                    {this.renderLoader(classReservations, 'Loading Class Reservations Data')}
                </Fader>
                <Fader className='class-reservations__content'>
                    {this.renderClassReservations(classReservations)}
                </Fader>
            </div>
        );
    };

    renderReportCardsLayer = (reportCards) => {
        const reportCardsClassNames = classNames({
            'charts__report-cards': true,
            'charts__report-cards_loading': reportCards.dataState === dataStates.loading,
        });
        return (
            <div className={reportCardsClassNames}>
                <Fader className='report-cards__loader'>
                    {this.renderLoader(reportCards, 'Loading Report Cards Data')}
                </Fader>
                <Fader className='report-cards__content'>
                    {this.renderReportCards(reportCards)}
                </Fader>
            </div>
        );
    };

    renderClassReservationsByZoneLayer = (classReservationsByZone) => {
        const classReservationsByZoneClassNames = classNames({
            'charts__class-reservations-by-zone': true,
            'charts__class-reservations-by-zone_loading': classReservationsByZone.dataState === dataStates.loading,
        });
        return (
            <div className={classReservationsByZoneClassNames}>
                <Fader className='class-reservations-by-zone__loader'>
                    {this.renderLoader(classReservationsByZone, 'Loading Class Reservations By Zone')}
                </Fader>
                <Fader className='class-reservations-by-zone__content'>
                    {this.renderClassReservationsByZone(classReservationsByZone)}
                </Fader>
            </div>
        );
    };

    renderBookingsByTrainerLayer = (bookingsByTrainer) => {
        const bookingsByTrainerClassNames = classNames({
            'charts__bookings-by-trainer': true,
            'charts__bookings-by-trainer_loading': bookingsByTrainer.dataState === dataStates.loading,
        });
        return (
            <div className={bookingsByTrainerClassNames}>
                <Fader className='bookings-by-trainer__loader'>
                    {this.renderLoader(bookingsByTrainer, 'Loading Bookings By Trainer')}
                </Fader>
                <Fader className='bookings-by-trainer__content'>
                    {this.renderBookingsByTrainer(bookingsByTrainer)}
                </Fader>
            </div>
        );
    };

    renderTotalRevenueLayer = (totalRevenue) => {
        const totalRevenueClassNames = classNames({
            'charts__class-reservations-by-zone': true,
            'charts__class-reservations-by-zone_loading': totalRevenue.dataState === dataStates.loading,
        });
        return (
            <div className={totalRevenueClassNames}>
                <Fader className='total-revenue__loader'>
                    {this.renderLoader(totalRevenue, 'Loading Total Revenue Data')}
                </Fader>
                <Fader className='total-revenue__content'>
                    {this.renderTotalRevenue(totalRevenue)}
                </Fader>
            </div>
        );
    };

    render() {
        const form = 'OverviewFilterForm';
        const { formState: { OverviewFilterForm }, reports, overview, getAllOverviewData } = this.props;
        const {
            newObjects, classReservations, reportCards, classReservationsByZone, bookingsByTrainer, totalRevenue,
        } = overview.toJS();
        const { start, end } = reports.toJS();
        const { loading } = dataStates;

        const isAnyChartLoading =
            newObjects.dataState === loading ||
            classReservations.dataState === loading ||
            reportCards.dataState === loading ||
            classReservationsByZone.dataState === loading ||
            bookingsByTrainer.dataState === loading ||
            totalRevenue.dataState === loading;

        const overviewClassNames = classNames({
            'overview': true,
            'overview_loading': isAnyChartLoading,
        });
        const firstLayerChartsClassNames = classNames({
            'overview__charts': true,
            'overview__charts_loading': classReservations.dataState === loading || reportCards.dataState === loading,
        });
        const secondLayerChartsClassNames = classNames({
            'overview__charts': true,
            'overview__charts_second-layer': true,
            'overview__charts_loading': classReservationsByZone.dataState === loading || bookingsByTrainer.dataState === loading,
        });
        return (
            <div className={overviewClassNames}>
                <FormContainer
                    form={{
                        form,
                        onChange: getAllOverviewData,
                        formState: OverviewFilterForm,
                        className: 'overview__filter',
                        url: `GET /v1/${getUserNameSpace('overview')}/reports/overview/${start}/${end}/`,
                        initialValues: FormFactory.getFormDefinition(form).getInitialValues(start, end),
                    }}/>
                <div className='overview__header'>Report Overview</div>
                {this.renderNewObjectsLayer(newObjects)}
                <div className={firstLayerChartsClassNames}>
                    {this.renderClassReservationsLayer(classReservations)}
                    {this.renderReportCardsLayer(reportCards)}
                </div>
                <div className={secondLayerChartsClassNames}>
                    {this.renderTotalRevenueLayer(totalRevenue)}
                    {this.renderBookingsByTrainerLayer(bookingsByTrainer)}
                </div>
            </div>
        );
    }
}

export default OverviewPage;
