import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
    openEditAdditionalContactModalDialog,
    openCreateAdditionalContactModalDialog,
    openDeleteAdditionalContactModalDialog,
} from '../../../../actions/modal/openActions';
import AddNewItem from '../../../common/AddNewItem/';
import { ReactComponent as IconEdit } from '../../../../assets/icons/icon-edit-customer.svg';
import { ReactComponent as IconTrash } from '../../../../assets/icons/icon-delete.svg';
import Icon from '../../../common/Icon';
import { prettifyPhone } from '../../../../helpers/normalize';

const EditAdditioanlContacts = (props) => {
    const {
        currentCustomer,
        openEditAdditionalContactModalDialog,
        openCreateAdditionalContactModalDialog,
        openDeleteAdditionalContactModalDialog,
        data
    } = props;

    const renderItems = () => {
        return currentCustomer[data.contactsType].map((item, index) => {
            if (!item.name) return null;
            return (
                <div className='items-list__item' key={index}>
                    <div className='items-list__content'>
                        <div className='items-list__title'>{item.name}</div>
                        {!!item.phone_number && <div className='items-list__text'>{prettifyPhone(item.phone_number)}</div>}
                        {!!item.relationship && <div className='items-list__text'>{item.relationship}</div>}
                    </div>
                    <div className='items-list__actions'>
                        <div className='items-list__actions change-actions'>
                            <div
                                className='change-actions__item'
                                onClick={() => {
                                    openEditAdditionalContactModalDialog({
                                        contactsType: data.contactsType,
                                        data: item
                                    });
                                }}>
                                <Icon glyph={IconEdit} className='change-actions__icon-edit' /> Edit
                            </div>
                            <div
                                className='change-actions__item'
                                onClick={() => {
                                    openDeleteAdditionalContactModalDialog({
                                        contactsType: data.contactsType,
                                        data: item
                                    });
                                }}>
                                <Icon glyph={IconTrash} className='change-actions__icon-delete' /> Delete
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className='modal-dialog__wrap'>
            <section className='modal-dialog__body'>
                <div className='items-list'>
                    <div className='items-list__list'>
                        { renderItems() }
                    </div>
                    <div className='items-list__add'>
                        <AddNewItem
                            className='items-list__add-item'
                            text={data.addButtonText || 'Add'}
                            onClickHandler={() => {
                                openCreateAdditionalContactModalDialog({
                                    contactsType: data.contactsType,
                                });
                            }} />
                    </div>
                </div>
            </section>
        </div>
    );
};

EditAdditioanlContacts.propTypes = {
    openEditAdditionalContactModalDialog: PropTypes.func,
    openCreateAdditionalContactModalDialog: PropTypes.func,
    openDeleteAdditionalContactModalDialog: PropTypes.func,
    data: PropTypes.shape({}),
    currentCustomer: PropTypes.shape({}),
};

const mapStateToProps = state => {
    return {
        currentCustomer: state.getIn(['currentCustomer', 'instance']).toJS(),
    };
};

const mapDispatchToProps = {
    openEditAdditionalContactModalDialog,
    openCreateAdditionalContactModalDialog,
    openDeleteAdditionalContactModalDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAdditioanlContacts);
