import axios from 'axios';
import { getUserNameSpace } from '../../helpers/userRoles';

export const getCampaignVariables = () => {
    return () => {
        return axios.get(`/v1/${getUserNameSpace('campaigns.variables')}/campaigns/variables/`);
    };
};

export const getCampaignTargets = () => {
    return () => {
        return axios.get(`/v1/${getUserNameSpace('campaigns.targets')}/campaigns/targets/`);
    };
};

export const getCampaignDetail = (id) => {
    return () => {
        return axios.get(`/v1/${getUserNameSpace('campaigns.targets')}/campaigns/${id}/`);
    };
};

export const postCampaign = ({ data }) => {
    return () => {
        return axios.post(`/v1/${getUserNameSpace('campaigns')}/campaigns/`, data);
    };
};

export const patchCampaign = ({ data, id }) => {
    return () => {
        const url = `/v1/${getUserNameSpace('campaigns')}/campaigns/${id}/`;
        console.log('patchCampaign, url=' + url + ', data=' + JSON.stringify(data));

        return axios.patch(`/v1/${getUserNameSpace('campaigns')}/campaigns/${id}/`, data);
    };
};

export const postCampaignPhotos = ({ id, photos, onProgress }) => {
    const len = photos.length;
    const inTotals = [];
    const totals = [];
    const uploads = [];
    let sumTotals = 0;
    let allTotalsCounted = false;

    const sumAmounts = arr => arr.reduce((sum, amount) => {
        if (amount) {
            sum += amount;
        }
        return sum;
    }, 0);

    const handleProgress = (i, dispatch) => {
        const index = i;
        return e => {
            const { total, loaded } = e;

            if (!inTotals.includes(index)) {
                inTotals.push(index);
                totals[index] = total;
            }

            if (!allTotalsCounted) {
                sumTotals = sumAmounts(totals);
                const inTotalsCount = inTotals.reduce((count, item) => {
                    if (item !== undefined) {
                        count += 1;
                    }
                    return count;
                }, 0);
                allTotalsCounted = inTotalsCount === len;
            }

            uploads[index] = loaded;
            const percent = Math.round((sumAmounts(uploads) * 100) / sumTotals);
            if (onProgress) {
                dispatch(onProgress(percent));
            }
        };
    };

    const getURL = ({ contentID, attribute }) => `/v1/${getUserNameSpace('campaigns.photo')}/campaigns/photo/${id}/blocks/${contentID}/${attribute}/`;

    return async (dispatch) => {
        const requests = photos.map(({ photo, filename, ...contentIDAttribute }, index) => {
            const request = new FormData();
            let name = `camp${id}_content${contentIDAttribute.contentID}_${contentIDAttribute.attribute}`;
            const arr = filename.split('.');

            if (arr.length > 1) {
                name += `.${arr[arr.length - 1]}`;
            }

            request.append('photo', photo, name);
            totals[index] = photo.size;
            return () => {
                return axios.post(getURL(contentIDAttribute), request, {
                    onUploadProgress: handleProgress(index, dispatch),
                });
            };
        });
        const [...results] = await Promise.all(requests.map(req => req()));
        return results;
    };
};
