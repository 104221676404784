/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Field } from 'redux-form/immutable';
import { isPristine } from 'redux-form';
import * as Validators from '../../helpers/validate';
import { getCampaignTargetOptions } from './options';
import TagSearchAdd from '../Form/Fields/TagSearchAdd';
import TextInput from '../Form/Fields/TextInput';
import * as Actions from './actions';
import { dispatchInitialize } from '../../actions/form/formActions';
import reducer from './reducer';
import { generateAlertMeta } from '../Alert/actions';

export const FORM_NAME = 'CampaignEditorForm';
const CAMPAIGN_PHOTO_URL_REGEX = /^.+\/([0-9]+)\/(photo[1-4]?)\/$/;

export const SUBMITTYPE_SAVE = 0;
export const SUBMITTYPE_SEND = 1;
export const SUBMITTYPE_TESTSEND = 2;

const targetArrayField = {
    component: TagSearchAdd,
    name: 'target_array',
    label: 'Select Campaign Target',
    emptyValue: '',
    options: getCampaignTargetOptions,
    autoload: true,
    shouldUseWarning: true,
    get warn() {
        return [
            Validators.requiredValidator()
        ];
    }
};

const campaignNameField = {
    component: TextInput,
    name: 'name',
    label: 'Campaign Name',
    shouldUseWarning: true,
    get warn() {
        return [
            Validators.requiredValidator(),
            Validators.asciiValidator(),
            Validators.maxTextValueValidator(undefined, { maxValue: 128 })
        ];
    }
};

const emailSubjectField = {
    component: TextInput,
    name: 'subject',
    label: 'Email Subject Line',
    shouldUseWarning: true,
    get warn() {
        return [
            Validators.requiredValidator(),
            Validators.maxTextValueValidator()
        ];
    }
};

const fields = [
    targetArrayField,
    campaignNameField,
    emailSubjectField
];

const mergeCampaignPhotosResponse = ({ campaign, photos }) => {
    const { data: { content } } = campaign;

    if (!photos || !photos.length) {
        return;
    }

    photos.forEach(resp => {
        const { photo, photo_processed } = resp.data;
        const [, contentID, attr] = resp.config.url.match(CAMPAIGN_PHOTO_URL_REGEX);
        const index = content.findIndex(block => block.content_id === +contentID);
        content[index][attr] = { photo, photo_processed };
    });
};

export const submitAction = ({ formConfig, submitData: data }) => {
    return async (dispatch, getState) => {
        const { submitType, ...submitData } = data;
        let response;

        if (!isPristine(FORM_NAME)(getState().toJS())) {
            if (formConfig.detailID) {
                response = await dispatch(Actions.editCampaign({ formConfig, submitData }));
            } else {
                response = await dispatch(Actions.createCampaign({ submitData }));
                formConfig.detailID = response.campaign.data.id;
            }
        }

        if (submitType === SUBMITTYPE_TESTSEND) {
            response = await dispatch(Actions.testSendCampaign(formConfig.detailID));
            return response;
        } else if (submitType === SUBMITTYPE_SEND) {
            response = await dispatch(Actions.sendCampaign(formConfig.detailID));
            dispatch(generateAlertMeta({
                event: 'CAMPAIGN_CREATE_PUBLISH',
                responseData: response.data
            }));

            return response;
        }

        const { campaign, photos } = response;
        mergeCampaignPhotosResponse({ campaign, photos });

        dispatch(generateAlertMeta({
            event: 'CAMPAIGN_CREATE_SAVE',
            responseData: campaign.data
        }));

        return campaign;
    };
};

export const updateAction = ({ responseData, formData }) => {
    return (dispatch) => {
        if (responseData.get('is_sent')) {
            dispatch(Actions.gotoCampaignList());
            return;
        }

        const { id } = responseData.toJS();
        const newState = {};
        newState.initialValues = { ...formData.toJS() };
        newState.id = id;
        dispatch(dispatchInitialize({ form: FORM_NAME, data: formData, keepDirty: false }));
        dispatch(Actions.updateDetail(newState));
    };
};

export const submitNormalizer = (formData, state, formConfig) => {
    const {
        name,
        subject,
        target_array: targetArray,
        template_id,
        blocks } = formData.toJS();
    const photos = [];
    const submitType = state.getIn([reducer.__name, 'detail', 'submitType']);
    const { arrayOrder } = formConfig;

    const shouldUseOrderArray = !!(arrayOrder && arrayOrder.length > 1);

    let target_array;
    let content;

    if (blocks && blocks.length) {
        content = blocks.reduce((result, block, i) => {
            if (block) {
                const orderIndex = shouldUseOrderArray ?
                    arrayOrder.findIndex((blockIndex) => blockIndex === i) :
                    result.length;

                Object.keys(block).forEach((attribute) => {
                    if (attribute.startsWith('photo') && block[attribute]) {
                        const { content_id: contentID } = block;
                        const { file, filename } = block[attribute];
                        if (file) {
                            photos.push({ contentID, attribute, photo: file, filename });
                        }
                        delete block[attribute];
                    }
                });
                result[orderIndex] = block;
            }
            return result;
        }, []);
    }


    if (targetArray && targetArray.length) {
        target_array = targetArray.map(({ id, grouping_type }) => { // eslint-disable-line camelcase
            return { id, grouping_type };
        });
    }

    return {
        name,
        subject,
        target_array,
        template_id,
        content,
        submitType,
        photos: photos.length ? photos : undefined
    };
};

export const getConfig = ({ detailID, initialValues }) => {
    return {
        form: FORM_NAME,
        initialValues,
        shouldInitialise: false,
        className: 'marketing-editor-page__form',
        url: '',
        detailID,
        fields,
        submitAction,
        submitNormalizer,
        updateAction
    };
};
