import React from 'react';
import PropTypes from 'prop-types';

const SmsCancelStep = ({ onCloseHandler, clearFormData }) => {
    return (
        <div className='sms-modal__verification'>
            <h5 className='sms-modal__title'>No Problem</h5>
            <p className='sms-modal__text'>You can always change your preference in your profile and settings, but we won’t send you any SMS Updates until then.</p>
            <div className='sms-modal__form-footer'>
                <span
                    onClick={() => {
                        onCloseHandler();
                        clearFormData();
                    }}
                    className='button modal-dialog__button_sms sms-modal__button-close'>Close</span>
            </div>
        </div>
    );
};

SmsCancelStep.propTypes = {
    onCloseHandler: PropTypes.func.isRequired,
    clearFormData: PropTypes.func.isRequired
};

export default SmsCancelStep;
