import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { ReactComponent as IconChevronDown } from '../../../assets/icons/icon-chevron-down.svg';
import AvatarUploader from '../AvatarUploader';
import Dropdown from '../Dropdown';
import { isTrainer } from '../../../helpers/userRoles';
import { Link } from 'react-router';

const ContactCard = ({ contact, functionalItems, isForDog }) => {
    const photo = contact.getIn(['instance', 'photo']);
    const name = contact.getIn(['instance', 'name']);
    const fullName = contact.getIn(['instance', 'full_name']);
    const dogOwnerFullName = contact.getIn(['instance', 'owner_detail', 'full_name'])
    const dogOwnerId = contact.getIn(['instance', 'owner_detail', 'id'])
    const arePreviousClassesLoaded = contact.getIn(['previous_classes', 'areLoaded']);
    const areUpcomingClassesLoaded = contact.getIn(['upcoming_classes', 'areLoaded']);
    const previousClasses = contact.getIn(['previous_classes', 'classes']);
    const upcomingClasses = contact.getIn(['upcoming_classes', 'classes']);
    const previousClassesCount = contact.getIn(['previous_classes', 'count']);
    const incidentsCount = contact.getIn(['instance', 'incident_count']);
    const accessLevel = contact.getIn(['instance', 'access_level']);
    const isVip = contact.getIn(['instance', 'is_vip']);
    const isBanned = contact.getIn(['instance', 'is_banned']);
    const isDeceased = contact.getIn(['instance', 'is_deceased']);
    const lastAppointment = previousClasses.getIn(['0', 'class_occurrence_detail', 'date']);
    const nextAppointment = upcomingClasses.getIn([upcomingClasses.size-1, 'class_occurrence_detail', 'date']);
    const signupDate = contact.getIn(['instance', 'signup_date']);
    const toRoman = {0:'0', 1: 'I', 2: 'II', 3: 'III', 4: 'IV'}

    return (
        <div className='contact-card'>
            <AvatarUploader photo={photo} target={isForDog ? 'dog' : 'customer'} isDisabled={isTrainer()}/>
            {isForDog
                ? (<div className='contact-card__info'>
                    <div>
                        <div className='contact-card__title'>
                            {name === undefined
                                ? ' And The Name is...'
                                : ` ${name}`
                            }
                        </div>
                        {isDeceased && <div className='contact-card__status'>(Deceased)</div>}
                        {isBanned && <div className='contact-card__status'>(Inactive)</div>}
                    </div>
                    <p className='contact-card__text'>
                        Owner:
                        {dogOwnerFullName === undefined
                            ? ' Getting Data...'
                            : <Link to={`/dashboard/customer/${dogOwnerId}/details`}> {dogOwnerFullName}</Link>
                        }
                    </p>
                    <p className='contact-card__text'>
                        Next Appt:
                        {!areUpcomingClassesLoaded
                            ? ' Loading...'
                            : nextAppointment
                             ? ` ${moment(nextAppointment).format('M/D/YYYY')}`
                             : ' N/A'
                        }
                    </p>
                    <p className='contact-card__text'>
                        Last Appt:
                        {!arePreviousClassesLoaded
                            ? ' Loading...'
                            : lastAppointment
                             ? ` ${moment(lastAppointment).format('M/D/YYYY')}`
                             : ' N/A'
                        }
                    </p>
                    <p className='contact-card__text'>
                        Visits:
                        {!arePreviousClassesLoaded
                            ? ' Calculating...'
                            : ` ${previousClassesCount}`
                        }
                    </p>
                    <p className='contact-card__text'>
                        Incidents:
                        {incidentsCount === undefined
                            ? ' Calculating...'
                            : incidentsCount === 0
                             ? ' None'
                             : ` ${incidentsCount}`
                        }
                    </p>
                    <p className='contact-card__text'>
                        Access Level:
                        {accessLevel === undefined
                            ? ' Getting Data...'
                            : ` ${toRoman[accessLevel]}`
                        }
                    </p>
                </div>)
                : (<div className='contact-card__info'>
                    <h2 className='contact-card__title'>
                        {fullName === undefined
                            ? ' And The Name is...'
                            : ` ${fullName}`
                        }
                    </h2>
                    {isBanned === 'Yes' && <p className='contact-card__status'>(Inactive)</p>}
                    <p className='contact-card__text'>Customer Since: {moment(signupDate).format('LL')}</p>
                    <p className='contact-card__text'>
                        Last Appointment:
                        {!arePreviousClassesLoaded
                            ? ' Calculating...'
                            : previousClasses.size
                                ? ` ${moment(lastAppointment).format('LL')}`
                             : ' N/A'
                        }
                    </p>
                    <p className='contact-card__text'>
                        Visits:
                        {!arePreviousClassesLoaded
                            ? ' Loading...'
                            : ` ${previousClassesCount}`
                        }
                    </p>
                    <p className='contact-card__text'>
                        VIP:
                        {isVip === undefined
                            ? ' Getting Data...'
                            : ` ${isVip}`
                        }
                    </p>
                </div>)
            }
            {!isTrainer() && <Dropdown
                classNames='dropdown-card'
                icon={IconChevronDown}
                iconClass='icon_chevron_down'
                items={functionalItems}/>}
        </div>
    );
};

ContactCard.propTypes = {
    contact: PropTypes.shape({}),
    functionalItems: PropTypes.arrayOf(PropTypes.shape({})),
    isForDog: PropTypes.bool
};

export default ContactCard;
