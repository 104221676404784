import PropTypes from 'prop-types';
import React from 'react';
import Grid from '../../../../../common/Grid';

const ClassScheduleGrid = ({ data, getNext, readOnly, isLoading }) => {
    return (
        <Grid
            items={data.get('filteredClasses')}
            className='class-schedule__content class-schedule__grid'
            cardType='classSchedule'
            getNext={getNext}
            nextUrl={data.get('nextUrl')}
            isLoading={isLoading}
            readOnly={readOnly}
            emptyItemsClassNames='classes-schedule-card-container classes-schedule-card-container_empty'/>
    );
};

ClassScheduleGrid.propTypes = {
    data: PropTypes.shape({}).isRequired,
    readOnly: PropTypes.bool,
    getNext: PropTypes.func,
    isLoading: PropTypes.bool,
};

export default ClassScheduleGrid;
