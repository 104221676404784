/* eslint-disable */

import classNames from 'classnames';
import React from 'react';

function menuRenderer({
    focusedOption,
    instancePrefix,
    labelKey,
    onFocus,
    onSelect,
    optionClassName,
    optionComponent,
    optionRenderer,
    options,
    valueArray,
    valueKey,
    onOptionRef
}) {
    const Option = optionComponent;

    return options.map((option, i) => {
        const isSelected = valueArray && valueArray.indexOf(option) > -1;
        const isFocused = option === focusedOption;
        const isDisabled = option === undefined ? false : option.disabled;
        const optionClass = classNames(optionClassName, {
            'Select-option': true,
            'is-selected': isSelected,
            'is-focused': isFocused,
            'is-disabled': isDisabled,
        });

        return (
            <Option
                className={optionClass}
                instancePrefix={instancePrefix}
                isDisabled={isDisabled}
                isFocused={isFocused}
                isSelected={isSelected}
                key={`option-${i}-${valueKey}`}
                onFocus={onFocus}
                onSelect={onSelect}
                option={option}
                optionIndex={i}
                ref={ref => {
                    onOptionRef(ref, isFocused);
                }}>
                {optionRenderer(option, i)}
            </Option>
        );
    });
}

export default menuRenderer;
