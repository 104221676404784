/* eslint-disable camelcase */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateClassFeaturedOption } from '../../../actions/dashboard/classesScheduleActions';
import { openEditClassModalDialog, openClassDeleteModalDialog } from '../../../actions/modal/openActions';
import { ReactComponent as IconDots } from '../../../assets/icons/icon-dots.svg';
import { ReactComponent as IconEdit } from '../../../assets/icons/icon-edit.svg';
import { ReactComponent as IconStarFilled } from '../../../assets/icons/icon-star-filled.svg';
import { ReactComponent as IconStar } from '../../../assets/icons/icon-star.svg';
import { ReactComponent as IconTrash } from '../../../assets/icons/icon-trash.svg';
import { timePrettify } from '../../../helpers/date';
import { capitalizeEachWord } from '../../../helpers/text';
import Icon from '../../common/Icon';
import AvatarPlaceholder from '../AvatarPlaceholder';
import Dropdown from '../Dropdown';
import { isEmployee, isManager } from '../../../helpers/userRoles';

const ClassesScheduleCard = ({ data, index, handleFeaturedToggle, handleOpenEditClassModal, openClassDeleteModalDialog, readOnly }) => { // eslint-disable-line max-len
    const headerBackground = data.get('photo_processed') !== null
        ? ({
            backgroundColor: '#98999d',
            background: `url(${data.get('photo_processed')}) center center / cover no-repeat`,
        })
        : ({
            backgroundColor: '#98999d',
        });

    const getDropdownItems = () => {
        const itemsList = [];
        itemsList.push({
            iconClass: 'icon_star',
            icon: IconStar,
            text: data.get('featured') ? 'Unmark Featured' : 'Mark as Featured',
            onClickCB: () => {
                handleFeaturedToggle(index, data);
            },
        });
        if (!isEmployee() && !isManager()) {
            itemsList.push({
                iconClass: 'icon_edit',
                icon: IconEdit,
                text: 'Edit',
                onClickCB: () => {
                    handleOpenEditClassModal(data);
                },
            });
        }
        if (!isEmployee() && !isManager()) {
            itemsList.push({
                iconClass: 'icon_trash',
                icon: IconTrash,
                text: 'Delete',
                onClickCB: () => {
                    openClassDeleteModalDialog(data);
                },
            });
        }
        return itemsList;
    };

    const getLocation = (d) => {
        const location_detail = d.get('location_detail');
        const location_array = d.get('location_array');
        const location_array_detail = d.get('location_array_detail');

        if (location_array && location_array.size) {
            const detail = location_array_detail.toJS();
            return Object.keys(detail).map((key) => detail[key]).join(', ');
        }

        return location_detail;
    };

    return (
        <div className='classes-schedule-card-container'>
            <div className='classes-schedule-card'>
                {!readOnly && <Dropdown
                    classNames='dropdown_classes-schedule-card'
                    icon={IconDots}
                    iconClass='icon_chevron_down'
                    items={getDropdownItems()}/>}
                <div
                    className='classes-schedule-card__header'
                    style={headerBackground}>
                    {data.get('photo_processed') === null && <AvatarPlaceholder type='dog' size='card'/>}
                    <div className='classes-schedule-card__header-title'>
                        <div className='classes-schedule-card__header-location'>
                            {getLocation(data) || 'Location not set'}
                        </div>
                        <div className='classes-schedule-card__header-price'>
                            {data.get('price') ? '$' + data.get('price') : 'Price not set'}
                        </div>
                    </div>
                </div>
                <div className='classes-schedule-card__name-info'>
                    <div className='classes-schedule-card__class-name'>
                        {data.get('name') || 'Name not set'}
                    </div>
                    <div className='classes-schedule-card__weekdays'>
                        {data.get('daysNormalized') || '-'}
                    </div>
                    {data.get('featured')
                        ? (<Icon
                            glyph={IconStarFilled}
                            className='icon-star-filled classes-schedule-card_featured-class-icon'/>)
                        : null}
                </div>
                <div className='classes-schedule-card__content'>
                    <div className='classes-schedule-card__item'>
                        <div className='classes-schedule-card__item-header'>Pick Up</div>
                        <div className='classes-schedule-card__item-content'>
                            {timePrettify(data.get('pickup_start_time'))}
                            -
                            {timePrettify(data.get('pickup_end_time'))}
                        </div>
                    </div>
                    <div className='classes-schedule-card__item'>
                        <div className='classes-schedule-card__item-header'>Drop Off</div>
                        <div className='classes-schedule-card__item-content'>
                            {timePrettify(data.get('dropoff_start_time'))}
                            -
                            {timePrettify(data.get('dropoff_end_time'))}
                        </div>
                    </div>
                    <div className='classes-schedule-card__item'>
                        <div className='classes-schedule-card__item-header'>Access Level</div>
                        <div className='classes-schedule-card__item-content'>
                            {data.get('access_level_detail') || 'Access Level not set'}
                        </div>
                    </div>
                    <div className='classes-schedule-card__item'>
                        <div className='classes-schedule-card__item-header'>Instructor</div>
                        <div className='classes-schedule-card__item-content'>
                            {capitalizeEachWord(data.getIn(['trainer_detail', 'full_name'])) || 'Instructor not set'}
                        </div>
                    </div>
                    <div className='classes-schedule-card__item'>
                        <div className='classes-schedule-card__item-header'>Spaces</div>
                        <div className='classes-schedule-card__item-content'>
                            {data.get('spots') || 'No spaces'}
                        </div>
                    </div>
                    {!readOnly && <div className='classes-schedule-card__item'>
                        <div className='classes-schedule-card__item-header'>Commission</div>
                        <div className='classes-schedule-card__item-content'>
                            {data.get('commission') ? data.get('commission') + '%' : 'Not set'}
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
};

ClassesScheduleCard.propTypes = {
    data: PropTypes.shape({}).isRequired,
    index: PropTypes.number.isRequired,
    handleFeaturedToggle: PropTypes.func.isRequired,
    handleOpenEditClassModal: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    openClassDeleteModalDialog: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    handleFeaturedToggle: bindActionCreators(updateClassFeaturedOption, dispatch),
    handleOpenEditClassModal: bindActionCreators(openEditClassModalDialog, dispatch),
    openClassDeleteModalDialog: bindActionCreators(openClassDeleteModalDialog, dispatch)
});

export default connect(null, mapDispatchToProps)(ClassesScheduleCard);
