import * as axios from 'axios';
import * as ClassesScheduleActions from '../actions/dashboard/classesScheduleActions';
import { getUserNameSpace } from '../helpers/userRoles';
import { setGlobalError } from '../actions/errorHandlerActions';

export const getClasses = queryString => {
    return dispatch => {
        dispatch(ClassesScheduleActions.startLoadingClasses());
        let url = `/v1/${getUserNameSpace('classes')}/classes/?is_archived=false`;

        if (queryString) {
            url = `${url}&${queryString}`;
        }
        return axios
            .get(url)
            .then(response => {
                dispatch(ClassesScheduleActions.setClasses(response.data));
                dispatch(ClassesScheduleActions.finishLoadingClasses());
            })
            .catch(error => {
                console.log(error);
                console.log(error.response);
                dispatch(ClassesScheduleActions.finishLoadingClasses());
                dispatch(setGlobalError(error));
            });
    };
};

export const getClassesOptions = () => {
    return dispatch => {
        dispatch(ClassesScheduleActions.startLoadingClasses());
        const url = `/v1/${getUserNameSpace('classes')}/classes/?is_archived=false&page_size=0`;
        return axios
            .get(url)
            .then(response => {
                dispatch(ClassesScheduleActions.setClassesOptions(response.data));
                dispatch(ClassesScheduleActions.finishLoadingClasses());
            })
            .catch(error => {
                console.log(error);
                console.log(error.response);
                dispatch(ClassesScheduleActions.finishLoadingClasses());
                dispatch(setGlobalError(error));
            });
    };
};

export const getNextClasses = () => {
    return (dispatch, getState) => {
        dispatch(ClassesScheduleActions.startLoadingClasses());
        const nextUrl = getState().getIn(['classesList', 'nextUrl']);
        return axios
            .get(nextUrl)
            .then(response => {
                dispatch(ClassesScheduleActions.setNextClasses(response.data));
                dispatch(ClassesScheduleActions.finishLoadingClasses());
            })
            .catch(error => {
                console.log(error);
                console.log(error.response);
                dispatch(ClassesScheduleActions.finishLoadingClasses());
                dispatch(setGlobalError(error));
            });
    };
};

export const updateClassFeatured = ({ id, featured }) => {
    const url = `/v1/${getUserNameSpace('classes')}/classes/${id}/`;

    return () => {
        return axios.patch(url, {
            is_featured: !featured,
        });
    };
};

export const searchTrainersByName = trainerName => {
    let url = `/v1/${getUserNameSpace('directory')}/directory/?type=trainer`;

    if (trainerName) {
        url = `${url}&name=${trainerName}`;
    }

    return axios.get(url);
};

export const searchClassesByName = className => {
    let url = `/v1/${getUserNameSpace('classes')}/classes/?is_archived=false`;

    if (className) {
        url = `${url}&name=${className}`;
    }

    return axios.get(url);
};
