import React from 'react';
import PropTypes from 'prop-types';
import SteppedForm from '../../../common/SteppedForm';
import SmsIntroductionStep from '../../Customer/StepForms/SmsIntroductionStep';
import SmsVerificationStep from '../../Customer/StepForms/SmsVerificationStep';
import SmsAddPhoneStep from '../../Customer/StepForms/SmsAddPhoneStep';
import SmsCancelStep from '../../Customer/StepForms/SmsCancelStep';
import SmsSuccessStep from '../../Customer/StepForms/SmsSuccessStep';

const SmsVerificationModalDialogForm = ({ onClose, customerPrimaryPhone }) => {
    const steps = [
        {
            name: 'introduction',
            form: SmsIntroductionStep
        }, {
            name: 'verification-form',
            form: SmsVerificationStep,
            customerPrimaryPhone
        }, {
            name: 'phone-add-form',
            form: SmsAddPhoneStep
        }, {
            name: 'cancel-step',
            form: SmsCancelStep,
            onCloseHandler: onClose
        }, {
            name: 'finish-step',
            form: SmsSuccessStep,
            onCloseHandler: onClose
        }
    ];
    return (
        <div className='sms-modal'>
            <SteppedForm
                name='smsVerification'
                className='sms-modal__form-wrapper'
                items={steps} />
        </div>
    );
};

SmsVerificationModalDialogForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    customerPrimaryPhone: PropTypes.shape({})
};

export default SmsVerificationModalDialogForm;
