/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import TemplateCard from './TemplateCard';
import Form from '../Form';
import { getFormConfig } from '../TemplateEditor/form';

const propTypes = {
    onTemplateClick: PropTypes.func,
    templates: PropTypes.PropTypes.shape({})
};

class TemplateSelector extends React.PureComponent {
    static propTypes = propTypes;

    componentDidMount() {
        // Scroll to top after pasting content to template previews.
        window.scrollTo(0, 0);
    }

    renderPreviews = () => {
        const { templates, onTemplateClick } = this.props;
        return templates.toJS().map((template) => {
            const { id, name, isActive = false } = template;
            const clickHandler = !isActive ?
                () => {
                    onTemplateClick(id);
                } :
                undefined;
            return (
                <div
                    key={`template-${id}`}
                    className='template-selector__template-wrapper'>
                    <div className='template-selector__template-header'>
                        {name}
                    </div>
                    <TemplateCard
                        onClick={clickHandler}
                        templateID={id}
                        isActive={isActive}/>
                </div>
            );
        });
    };

    render() {
        const { templates } = this.props;
        const formConfig = getFormConfig({});
        formConfig.fields = undefined;
        return (
            <div className='template-selector'>
                {this.renderPreviews()}
                <div style={{ display: 'none' }}>
                    <Form
                        // Need it here to be able to trigger submit.
                        key='template-editor-selector'
                        destroyOnUnmount={false}
                        keepDirtyOnReinitialize
                        formConfig={formConfig}
                        isPreview
                        templates={templates}
                        onGetOptions={() => []}/>
                </div>
            </div>
        );
    }
}

TemplateSelector.propTypes = propTypes;

export default TemplateSelector;
