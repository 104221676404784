import * as axios from 'axios';
import { getUserNameSpace } from '../helpers/userRoles';

export const getFormsData = query => {
    return () => {
        return axios.get(`/v1/${getUserNameSpace('agreements')}/agreements/${query ? ('?' + query) : ''}`);
    };
};

export const getCurrentFormData = id => {
    return () => {
        return axios.get(`/v1/${getUserNameSpace('agreements')}/agreements/${id}/`);
    };
};

export const getFormRecipientsByName = query => {
    return () => {
        return axios.post(`/v1/${getUserNameSpace('users-groupings')}/users-groupings/`, { name: query });
    };
};

export const getUserGroupingOptions = () => {
    return () => {
        return axios.get(`/v1/${getUserNameSpace('users-groupings-types')}/users-groupings-types/`);
    };
};
