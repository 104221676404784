import React, { PureComponent, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

export default class Accordion extends PureComponent {
    state = {
        selectedSectionIndex: -1,
    };

    getAdditionalProps = (index, props) => ({
        handleClick: this.toggle,
        index,
        selected: index === this.state.selectedSectionIndex,
        ...props,
    });

    getChildrenSectionsWithProps = () => {
        return Children.map(this.props.children, (child, index) =>
            cloneElement(child, this.getAdditionalProps(index, child.props)),
        );
    };

    toggle = sectionIndex => {
        if (this.state.selectedSectionIndex === sectionIndex) {
            this.setState({ selectedSectionIndex: -1 });
        } else {
            this.setState({ selectedSectionIndex: sectionIndex });
        }
    };

    render() {
        const childrenSectionsWithProps = this.getChildrenSectionsWithProps();

        return <div className='accordion'>{childrenSectionsWithProps}</div>;
    }
}

Accordion.propTypes = {
    children: PropTypes.node
};
