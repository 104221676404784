import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProfileAddresses from './ProfileAddresses/';
import ContentBlock from '../../../../common/ContentBlock/';
import AjaxLoader from '../../../../common/AjaxLoader/';
import ContactsBlock from './ContactsBlock/';
import { getLocationString } from '../../../../../helpers/normalizeScheduleEvent';
import MyPackages from './MyPackages/';
import { openEditCustomerInfoModalDialog } from '../../../../../actions/miscActions';
import { openAdditionalContactsModalDialog, openCreateAdditionalContactModalDialog } from '../../../../../actions/modal/openActions';
import { prettifyPhone } from '../../../../../helpers/normalize';

const modifyPhoneNumbers = items => items.map(item => {
    if (item.phone_number) {
        item.phone_number = prettifyPhone(item.phone_number);
    }
    return item;
});
const MyProfile = ({
    customerData,
    openAdditionalContactsModalDialog,
    openCreateAdditionalContactModalDialog,
    openEditCustomerInfoModalDialog
}) => {
    const renderContactInfo = () => {
        let homeAddress = {};
        customerData.available_addresses.find(address => {
            if (address.id === customerData.addressId) {
                homeAddress = address;
                homeAddress.address2 = homeAddress.address2 === 'null' ? null : homeAddress.address2;
                return true;
            }
            return false;
        });

        const items = [
            {
                header: 'Email',
                content: customerData.email,
                column: true
            },
            {
                header: 'Phone',
                content: prettifyPhone(customerData.primary_phone),
                column: true
            },
            {
                header: 'Home Address',
                content: customerData.addressId ? getLocationString(homeAddress) : 'None',
                column: true
            },
        ];

        return (
            <ContentBlock
                title='Contact Info'
                editAction={openEditCustomerInfoModalDialog}
                items={items} />
        );
    };

    if (customerData.id) {
        return (
            <div className='profile__body'>
                <div className='profile__sidebar'>
                    {renderContactInfo()}
                    <ContactsBlock
                        title='Additional Owners'
                        emptyText='Additional Owners have FULL access to your pups. Owners can pickup/ dropoff or make schedule changes without involving you.'
                        addItemText='Add Add’l Owners'
                        data={modifyPhoneNumbers(customerData.additional_owners)}
                        action={() => {
                            openAdditionalContactsModalDialog({
                                contactsType: 'additional_owners',
                                title: 'Additional Owners',
                                addButtonText: 'Add Add’l Owners'
                            });
                        }}
                        addNewAction={() => {
                            openCreateAdditionalContactModalDialog({
                                contactsType: 'additional_owners',
                            });
                        }} />
                    <ContactsBlock
                        title='Emergency contacts'
                        emptyText='Emergency Contacts help in case you or your dog have an emergency. Add a contact below to make sure your furry friend always has a contact available.'
                        addItemText='Add Emergency Contact'
                        data={modifyPhoneNumbers(customerData.emergency_contacts)}
                        action={() => {
                            openAdditionalContactsModalDialog({
                                contactsType: 'emergency_contacts',
                                title: 'Emergency Contacts',
                                addButtonText: 'Add Emergency Contact'
                            });
                        }}
                        addNewAction={() => {
                            openCreateAdditionalContactModalDialog({
                                contactsType: 'emergency_contacts',
                            });
                        }} />
                </div>
                <div className='profile__content'>
                    <ProfileAddresses />
                    <MyPackages />
                </div>
            </div>
        );
    }
    return <AjaxLoader />;
};

MyProfile.propTypes = {
    openAdditionalContactsModalDialog: PropTypes.func,
    openCreateAdditionalContactModalDialog: PropTypes.func,
    customerData: PropTypes.shape({}),
    openEditCustomerInfoModalDialog: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        customerData: state.getIn(['currentCustomer', 'instance']).toJS(),
    };
};

const mapDispatchToProps = {
    openEditCustomerInfoModalDialog,
    openAdditionalContactsModalDialog,
    openCreateAdditionalContactModalDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
