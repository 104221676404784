import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { getSchedule, getNextSchedule } from '../../../../../services/schedule';
import ScheduleCard from '../ScheduleCard/';
import AjaxLoader from '../../../../common/AjaxLoader/';
import NoData from '../../../../common/NoData/';
import { getClasses } from '../../../../../services/classesSchedule';
import { getCustomerUpcomingClasses } from '../../../../../services/classes';
import ClassesSlider from '../ClassesSlider/';

class SchedulePage extends PureComponent {

    componentDidMount() {
        const { getSchedule, getClasses, classesList, getCustomerUpcomingClasses } = this.props;

        const isTablet = window.innerWidth <= 1024;
        getSchedule(`page_size=${isTablet ? 5 : 20}`);

        if (!classesList.length) {
            getClasses('is_featured=true');
        }

        getCustomerUpcomingClasses();
    }

    renderList() {
        const {
            scheduleList,
            scheduleIsLoading,
            schedule,
            getNextSchedule,
            totalCount,
            trainingsCount,
            classesCount,
        } = this.props;
        const cards = scheduleList.map((scheduleItem, index) => {
            return (
                <ScheduleCard
                    className='schedule__item'
                    data={scheduleItem}
                    key={index}
                    zIndex={totalCount - index} />
            );
        });
        return (
            <div className='schedule'>
                <div className='schedule__count'>
                    You have
                    {!!classesCount && ` ${classesCount} class${classesCount !== 1 ? 'es' : ''} `}
                    {!!classesCount && !!trainingsCount && 'and'}
                    {!!trainingsCount && ` ${trainingsCount} training${trainingsCount !== 1 ? 's' : ''} `}
                    in your schedule.
                </div>
                <InfiniteScroll
                    className='schedule__list'
                    pageStart={0}
                    loadMore={() => !scheduleIsLoading ? getNextSchedule() : null}
                    hasMore={schedule.nextUrl !== null}
                    loader={<AjaxLoader />}>
                    {cards}
                </InfiniteScroll>
            </div>
        );
    }

    renderNoData() {
        const { classesList } = this.props;
        const title = 'You don’t have any classes scheduled.';

        return (
            <div className='schedule'>
                <NoData title={title} iconType='thinking'/>
                {!!classesList.length && <ClassesSlider items={classesList}/>}
            </div>
        );
    }

    render() {
        const { scheduleIsLoading, scheduleList } = this.props;
        const count = scheduleList.length;

        return (
            <div className='inner-page'>
                <div className='inner-page__container'>
                    <div className='inner-page__title'>My Schedule</div>
                    {
                        scheduleIsLoading && count === 0
                            ? <AjaxLoader />
                            : count > 0 ? this.renderList() : this.renderNoData()
                    }
                </div>
            </div>
        );
    }
}

SchedulePage.propTypes = {
    getSchedule: PropTypes.func,
    getNextSchedule: PropTypes.func,
    getClasses: PropTypes.func,
    getCustomerUpcomingClasses: PropTypes.func,
    scheduleList: PropTypes.arrayOf(PropTypes.shape({})),
    classesList: PropTypes.arrayOf(PropTypes.shape({})),
    upcomingClassesList: PropTypes.arrayOf(PropTypes.shape({})),
    schedule: PropTypes.shape({}),
    scheduleIsLoading: PropTypes.bool,
    totalCount: PropTypes.number,
    classesCount: PropTypes.number,
    trainingsCount: PropTypes.number,
};

const mapStateToProps = state => {
    return {
        classesList: state.getIn(['classesList', 'classes']).toJS(),
        upcomingClassesList: state.getIn(['currentCustomer', 'upcoming_classes', 'classes']).toJS(),
        schedule: state.getIn(['schedule']).toJS(),
        totalCount: state.getIn(['schedule', 'total_count']),
        classesCount: state.getIn(['schedule', 'classes_total_count']),
        trainingsCount: state.getIn(['schedule', 'trainings_total_count']),
        scheduleList: state.getIn(['schedule', 'items']).toJS(),
        scheduleIsLoading: state.getIn(['schedule', 'isLoading']),
        dogs: state.getIn(['dogsList', 'dogs'])
    };
};

const mapDispatchToProps = {
    getClasses,
    getSchedule,
    getNextSchedule,
    getCustomerUpcomingClasses
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulePage);
