const validate = values => {
    const errors = {};

    if (!values.get('report')) {
        errors.report = 'Select report';
    }

    if (values.get('date') === 'None') {
        errors.date = 'Select date';
    }

    const dateRange = values.get('date_range') && values.get('date_range').size ? values.get('date_range').toJS() : values.get('date_range');
    if (!dateRange) {
        errors.date_range = 'Select date range';
    }

    if (dateRange && dateRange.start === 'None') {
        errors.date_range = 'Select start date';
    }

    if (dateRange && dateRange.end === 'None') {
        errors.date_range = 'Select end date';
    }

    if (values.get('report') === 'attendance-by-zone') {
        if (!values.get('attendance_by_zone_filter') || 
            (!values.get('attendance_by_zone_filter_extra') && (values.get('attendance_by_zone_filter').value !== 0))) {
            errors.date_range = 'Select report types';
        }
    }

    if (values.get('report') === 'trainers-invoice') {
        if (!values.get('trainer')) {
            errors.trainer = 'Please select instructor';
        }
    }

    return errors;
};

export default validate;
