import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CustomerNavbarItem from './CustomerNavbarItem';
import { ReactComponent as IconPackages } from '../../../assets/icons/icon-customer-packages.svg';
import { ReactComponent as IconDog } from '../../../assets/icons/icon-customer-dog.svg';
import { ReactComponent as IconTraining } from '../../../assets/icons/icon-customer-training.svg';
import { ReactComponent as IconClasses } from '../../../assets/icons/icon-customer-classes.svg';
import { ReactComponent as IconSchedule } from '../../../assets/icons/icon-customer-schedule.svg';


class CustomerNavbar extends React.PureComponent {
    links = [
        { to: '/dogs', iconClass: 'icon_customer-dog', icon: IconDog, text: 'My Dogs' },
        { to: '/training-programs', iconClass: 'icon_customer-training', icon: IconClasses, text: 'Training' },
        { to: '/classes', iconClass: 'icon_customer-classes', icon: IconTraining, text: 'Classes' },
        { to: '/schedule', iconClass: 'icon_customer-schedule', icon: IconSchedule, text: 'My Schedule' },
        { to: '/packages', iconClass: 'icon_customer-packages', icon: IconPackages, text: 'Packages' }
    ];

    isLinkActive = (url) => {
        return url === this.props.currUrl;
    }

    renderNavbarItems = () => {
        return this.links.map((linkProps, index) =>
            <CustomerNavbarItem {...linkProps} key={index} isActive={this.isLinkActive(linkProps.to)}/>
        );
    }

    render() {
        return (
            <nav className='customer-navbar'>
                {this.renderNavbarItems()}
            </nav>
        );
    }
}

CustomerNavbar.propTypes = {
    currUrl: PropTypes.string
};

const mapStateToProps = (state) => ({
    currUrl: state.getIn(['routing', 'locationBeforeTransitions']).pathname || null
});

export default connect(mapStateToProps, null)(CustomerNavbar);
