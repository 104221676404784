import axios from 'axios';
import { setGlobalError } from '../actions/errorHandlerActions';
import { setUserProfileInfo } from '../actions/onboarding/userActions';
import { getUserNameSpace } from '../helpers/userRoles';
import { generateAlertMeta } from '../components/Alert/actions';

export const loadProfileData = () => {
    return (dispatch) => {
        return axios.get(`/v1/${getUserNameSpace('me')}/me/`)
                    .then(response => {
                        dispatch(setUserProfileInfo(response.data));
                        return response;
                    })
                    .catch((error) => {
                        console.log('Error while loading user\'s data', error);
                        dispatch(setGlobalError(error));
                    });
    };
};

export const setProfileData = (profileData) => {
    return (dispatch) => {
        const requestData = {
            full_name: profileData.get('full_name'),
            email: profileData.get('email'),
            primary_phone: {
                phone_number: profileData.get('phone').replace(/[^\w\s]/gi, '')
            },
            emergency_contact: {
                name: profileData.get('emergency_contact'),
                phone_number: profileData.get('emergency_phone').replace(/[^\w\s]/gi, '')
            }
        };

        if (profileData.get('emergency_contact') || profileData.get('emergency_phone')) {
            requestData.emergency_contact = {
                name: profileData.get('emergency_contact'),
                phone_number: profileData.get('emergency_phone').replace(/[^\w\s]/gi, '')
            };
        }
        return axios.patch(`/v1/${getUserNameSpace('me')}/me/`, requestData, {
            headers: {
                'content-type': 'application/json'
            }
        })
                    .then(response => {
                        dispatch(generateAlertMeta({ event: 'PROFILE_EDIT' }));
                        console.log('RESPONSE DATA', response);
                        dispatch(setUserProfileInfo(response.data));
                    })
                    .catch((error) => {
                        console.log('Error while updating user\'s data', error.response);
                        dispatch(setGlobalError(error));
                    });
    };
};

export const changePassword = (values) => {
    return (dispatch) => {
        const request = new FormData();
        request.append('old_password', values.get('old_password'));
        request.append('password', values.get('password'));

        console.log('PASSWORD VALUES: ', values);
        return axios.put('/v1/users/change-password/', request)
                    .then(response => {
                        dispatch(generateAlertMeta({ event: 'PROFILE_PASSWORD_CHANGE' }));
                        console.log('response >>> ', response);
                    })
                    .catch(error => {
                        console.log('Error while changing user password', error.response);
                        dispatch(setGlobalError(error));
                        throw error;
                    });
    };
};

export const loadBookingProfileData = () => {
    return dispatch => {
        return dispatch(loadProfileData())
        .then(({ data }) => data)
        .catch(err => console.log(err));
    };
};

export const setWelcomeScreenShowed = () => {
    return dispatch => {
        return axios.patch('/v1/customers/me/', {
            is_welcome_showed: true
        });
    };
};
