import { createAction } from 'redux-actions';
import { getDogReports } from '../../services/dogReports';
import * as DogReportsTypes from '../../types/dashboard/dogReportsTypes';

export const setCurrentDogReports = createAction(DogReportsTypes.SET_CURRENT_DOG_REPORTS);
export const prependCurrentDogReports = createAction(DogReportsTypes.PREPEND_CURRENT_DOG_REPORTS);
export const updateCurrentDogReportData = createAction(DogReportsTypes.UPDATE_CURRENT_DOG_REPORT_DATA);
export const removeCurrentDogReportCard = createAction(DogReportsTypes.REMOVE_CURRENT_DOG_REPORT_CARD);
export const deleteCurrentDogReportCard = createAction(DogReportsTypes.DELETE_CURRENT_DOG_REPORT_CARD);

export const getReports = (dogId, url) => {
    return getDogReports({
        role: 'employees',
        id: dogId
    }, url);
};
