/* eslint-disable react/prop-types */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';
import { reduxForm } from 'redux-form/immutable';
import { setAdditionalOwnersDefaultData } from '../../../../actions/miscActions';
import AddButton from '../../../common/AddButton';
import ModalDialogFormBlock from '../../../common/ModalDialogFormBlock';
import validate from './validate';

class AdditionalOwnersModalDialogForm extends React.PureComponent {
    static propTypes = {
        initialValues: PropTypes.shape({}),
        handleSubmit: PropTypes.func,
        handleDefaultValues: PropTypes.func,
        invalid: PropTypes.bool,
        submitting: PropTypes.bool,
        additionalOwners: PropTypes.arrayOf(PropTypes.shape({})),
        customersName: PropTypes.string,
        changeFieldValue: PropTypes.func
    };

    state = {
        additionalOwners: {},
        additionalOwnersBlockCount: 0,
        areExternalAdditionalOwnersLoaded: false
    };

    componentDidMount() {
        this.props.handleDefaultValues();
    }

    componentWillReceiveProps(nextProps) {
        const { areExternalAdditionalOwnersLoaded } = this.state;
        if (nextProps.additionalOwners.length && !areExternalAdditionalOwnersLoaded) {
            const additionalOwners = { ...this.state.additionalOwners };
            const additionalOwnersAmount = nextProps.additionalOwners.length;

            nextProps.additionalOwners.forEach((owner, i) => {
                additionalOwners[i] = owner;
            });

            this.setState({
                additionalOwners,
                additionalOwnersBlockCount: additionalOwnersAmount,
                areExternalAdditionalOwnersLoaded: true
            });
        }

        // setting areExternalAdditionalOwnersLoaded flag to false and empty additionalOwners
        // to reinitiate the form with the actual additional owner from external store
        if (areExternalAdditionalOwnersLoaded && !nextProps.isAdditionalOwnersModalOpen) {
            this.setState({
                additionalOwners: {},
                additionalOwnersBlockCount: 0,
                areExternalAdditionalOwnersLoaded: false
            });
        }
    }

    addAnotherOwner = (owner) => {
        const { additionalOwnersBlockCount } = this.state;
        const additionalOwners = { ...this.state.additionalOwners };
        additionalOwners[additionalOwnersBlockCount] = owner;
        this.setState({
            additionalOwners,
            additionalOwnersBlockCount: this.state.additionalOwnersBlockCount + 1
        });
    };

    deleteOwner = (ownerKey) => {
        const additionalOwners = { ...this.state.additionalOwners };
        this.props.changeFieldValue(`${ownerKey}.name`, 'deleted');
        this.props.changeFieldValue(`${ownerKey}.relationship`, '');
        this.props.changeFieldValue(`${ownerKey}.phone_number`, '');
        delete additionalOwners[ownerKey];
        this.setState({ additionalOwners });
    };

    render() {
        const { handleSubmit, invalid, submitting, customersName } = this.props;
        const { additionalOwners } = this.state;
        const submitButtonClassNames = classNames({
            'button modal-dialog__button': true,
            'button_disabled': (invalid || submitting)
        });
        const AdditionalOwnerFields = [
            { name: 'name', label: 'Name' },
            { name: 'relationship', label: 'Relationship' },
            { name: 'phone_number', label: 'Phone Number' }
        ];
        const additionalOwnersCount = Object.keys(additionalOwners).length;
        return (
            <form className='modal-dialog__form' onSubmit={handleSubmit}>
                <div className='modal-dialog__form-content'>
                    {additionalOwnersCount
                        ? (Object.keys(additionalOwners).map((ownerKey) => {
                            return (
                                <ModalDialogFormBlock
                                    key={`form-block_owner_${ownerKey}`}
                                    index={+ownerKey}
                                    entityName='Owner'
                                    blockName='Additional Owner'
                                    deleteBlockHandler={this.deleteOwner}
                                    blockFields={AdditionalOwnerFields}/>
                            );
                        }))
                        : (<div className='modal-dialog__form-title modal-dialog__form-title_empty'>
                            No Additional Owners for {customersName}
                        </div>)
                    }
                </div>
                <div className='modal-dialog__form-footer modal-dialog__form-footer_row'>
                    <AddButton
                        text={additionalOwnersCount ? 'Add Another Owner' : 'Add An Owner'}
                        className='modal-dialog__add-button'
                        onClickHandler={() => this.addAnotherOwner({ name: '', relationship: '', phone_number: '' })}
                        disabled={submitting}/>
                    <button
                        className={submitButtonClassNames}
                        type='submit'
                        disabled={submitting}>
                        {submitting
                            ? 'Updating...'
                            : 'Save'
                        }
                    </button>
                </div>
            </form>
        );
    }
}

const form = reduxForm({
    form: 'AdditionalOwnersModalDialogForm',
    enableReinitialize: true,
    validate
});

const mapStateToProps = state => {
    const additionalOwners = state.getIn(['currentCustomer', 'initialInstance', 'additional_owners']).toJS();
    const initialValues = additionalOwners.reduce((initialValues, owner, i) => {
        Object.keys(owner).map((key) => {
            if (key === 'phone') {
                owner[`${key}_number`] = owner[key];
                delete owner.phone;
            }
            return key;
        });
        initialValues[i] = owner;
        return initialValues;
    }, {});

    return {
        isAdditionalOwnersModalOpen: state.getIn(['misc', 'modals', 'additionalOwners']),
        additionalOwners,
        initialValues
    };
};

const mapDispatchToProps = (dispatch) => ({
    // handleAnotherAdditionalOwner: bindActionCreators(setAnotherAdditionalOwner, dispatch),
    handleDefaultValues: bindActionCreators(setAdditionalOwnersDefaultData, dispatch),
    // eslint-disable-next-line object-shorthand
    changeFieldValue: function (field, value) {
        dispatch(change('AdditionalOwnersModalDialogForm', field, value));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(form(AdditionalOwnersModalDialogForm));
