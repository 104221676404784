import PropTypes from 'prop-types';
import React from 'react';

const Container = ({ className, children }) => {
    return (
        <div className={className}>{children}</div>
    );
};

Container.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default Container;
