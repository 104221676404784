import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as Actions from './actions';
import * as FormActions from '../../actions/form/formActions';
import { mapFormToState } from '../containers/FormContainer';
import { FORM_NAME } from './form';
import * as ModalActions from './modal';
import { lastStepSelector } from './steps';

const propTypes = {
    initState: PropTypes.func,
    resetState: PropTypes.func,
    initForm: PropTypes.func,
    fetchVariables: PropTypes.func,
    routeParams: PropTypes.shape({})
};

const Wrapper = MarketingEditorPage => {
    class MarketingEditorPageContainer extends PureComponent {
        componentDidMount() {
            const { initState, routeParams: { id } } = this.props;
            initState(id);
        }

        componentWillUnmount() {
            const { resetState, initForm } = this.props;
            resetState();
            initForm({ form: FORM_NAME, data: {}, keepDirty: false });
        }

        render() {
            const { resetState, ...other } = this.props;
            return (
                <MarketingEditorPage
                    {...other}/>
            );
        }
    }

    const mapStateToProps = state => {
        const model = state.get('marketingEditorPage');
        const forms = mapFormToState([FORM_NAME], state);
        const stepsLast = lastStepSelector({ forms, model });
        return {
            forms,
            model: model.set('stepsLast', stepsLast)
        };
    };

    const mapDispatchToProps = {
        initState: Actions.initState,
        resetState: Actions.resetReducer,
        resetForm: FormActions.dispatchReset,
        submitForm: FormActions.dispatchSubmit,
        changeField: FormActions.dispatchChangeField,
        initForm: FormActions.dispatchInitialize,
        handleStepSelect: Actions.selectStep,
        handleTemplateSelect: Actions.selectTemplate,
        updateDetail: Actions.updateDetail,
        gotoCampaignList: Actions.gotoCampaignList,
        openSendModal: ModalActions.openCampaignSendModal,
        openSendTestModal: ModalActions.openCampaignSendTestModal,
        openDiscardChangesModal: ModalActions.openCampaignDiscardChangesModal,
        openCampaignDeleteModal: ModalActions.openCampaignDeleteModal
    };

    MarketingEditorPageContainer.propTypes = propTypes;

    return connect(mapStateToProps, mapDispatchToProps)(MarketingEditorPageContainer);
};

export default Wrapper;
