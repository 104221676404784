import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { setAddAddressModalOpened } from '../../../../../actions/miscActions';
import renderCheckboxControl from '../../../../common/Form/CheckboxControl';
import AddNewItem from '../../../../common/AddNewItem';
import validate from './validate';

class LocationChangeStep extends PureComponent {

    renderAddresses = () => {
        const { formData: { addresses } } = this.props;
        return addresses.map(({ id, name, address2, address1, city, state, ...otherParams }, index) => (
            <label className='radio-control__controls-label location-change__radio' key={index}>
                <Field
                    name='location'
                    component='input'
                    type='radio'
                    value={id.toString()}
                    className='radio-control__controls-input'/>
                <div className='radio-control__controls-text location-change__radio__text'>
                    <p className='location-change__radio__content'>
                        <strong className='location-change__radio__title'>{name}</strong>
                        <span className='location-change__radio__address'>
                            {`${address2 && address2 !== 'null' ? address2 : ''} ${address1}, ${city}, ${state} ${otherParams.zip_code}`}
                        </span>
                    </p>
                </div>
            </label>
        ));
    }

    render() {
        const { handleSubmit, invalid, submitting, formData: { addresses }, onSubmit,
                isLoading, onCancelHandler, submitText, setAddAddressModalOpened, target } = this.props;
        const submitButtonClassNames = classNames({
            'button modal-dialog__button modal-dialog__button_customer-order': true,
            'button_disabled': (invalid || submitting || isLoading)
        });
        return (
            <form
                className='modal-dialog__form location-change'
                onSubmit={handleSubmit(onSubmit)}>
                <div className='modal-dialog__form-content location-change__content'>
                    <p className='location-change__head'>Select an location from one of your previously used locations or add a new one.</p>

                    {/* Form Content */}
                    { !!addresses.length &&
                        <div>
                            <div className='location-change__locations-list'>
                                {this.renderAddresses()}
                            </div>
                            <div className='modal-dialog__checkbox-container'>
                                <Field
                                    name={`default_${target.toLowerCase()}`}
                                    type='checkbox'
                                    label={`Make this my default ${target} location`}
                                    className='modal-dialog__purchase-checkbox'
                                    component={renderCheckboxControl} />
                            </div>
                        </div>
                    }
                    <AddNewItem
                        className='location-change__add-btn'
                        text='Add New Location'
                        onClickHandler={() => setAddAddressModalOpened({
                            formName: 'booking'
                        })}/>
                </div>
                <div className='modal-dialog__form-footer modal-dialog__form-footer_column'>
                    <button
                        className={submitButtonClassNames}
                        type='submit'
                        disabled={submitting || isLoading}>
                        {(submitting || isLoading)
                            ? 'Submitting...'
                            : submitText || 'Next'
                        }
                    </button>
                    <span className='modal-dialog__cancel-button' onClick={onCancelHandler}>Cancel</span>
                </div>
            </form>
        );
    }
}

LocationChangeStep.propTypes = {
    handleSubmit: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    isLoading: PropTypes.bool,
    onCancelHandler: PropTypes.func,
    submitText: PropTypes.string,
    setAddAddressModalOpened: PropTypes.func,
    formData: PropTypes.shape({}),
    onSubmit: PropTypes.func,
    target: PropTypes.string.isRequired
};

const form = reduxForm({
    validate,
    pure: false,
    enableReinitialize: true
});

const mapStateToProps = (state, { initialLocation, target }) => ({
    form: target + 'ChangeForm',
    initialValues: {
        location: initialLocation,
        [`default_${target.toLowerCase()}`]: true
    }
});

const mapDispatchToProps = {
    setAddAddressModalOpened
};

export default connect(mapStateToProps, mapDispatchToProps)(form(LocationChangeStep));
