import { createAction } from 'redux-actions';
import * as ClassesHistoryServices from '../../services/classesHistory';
import * as ClassesHistoryTypes from '../../types/customer/classesHistoryTypes';

export const setClassesHistory = createAction(ClassesHistoryTypes.SET_CLASSES_HISTORY);
export const setNextClassesHistory = createAction(ClassesHistoryTypes.SET_NEXT_CLASSES_HISTORY);
export const startClassesHistoryLoading = createAction(ClassesHistoryTypes.START_CLASSES_HISTORY_LOADING);
export const finishClassesHistoryLoading = createAction(ClassesHistoryTypes.FINISH_CLASSES_HISTORY_LOADING);
export const startClassesHistoryNextLoading = createAction(ClassesHistoryTypes.START_CLASSES_HISTORY_NEXT_LOADING);
export const finishClassesHistoryNextLoading = createAction(ClassesHistoryTypes.FINISH_CLASSES_HISTORY_NEXT_LOADING);

export const getClassesHistory = () => {
    return dispatch => {
        dispatch(startClassesHistoryLoading());
        dispatch(ClassesHistoryServices.getClassesHistory())
            .then((response) => {
                console.log(response);
                dispatch(setClassesHistory(response.data));
                dispatch(finishClassesHistoryLoading());
            })
            .catch((error) => {
                console.log(error);
                console.log(error.response);
                dispatch(finishClassesHistoryLoading());
            });
    };
};

export const getNextClassesHistory = () => {
    return dispatch => {
        dispatch(startClassesHistoryNextLoading());
        dispatch(ClassesHistoryServices.getNextClassesHistory())
            .then((response) => {
                console.log(response);
                dispatch(setNextClassesHistory(response.data));
                dispatch(finishClassesHistoryNextLoading());
            })
            .catch((error) => {
                console.log(error);
                console.log(error.response);
                dispatch(finishClassesHistoryNextLoading());
            });
    };
};
