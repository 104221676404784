import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Hamburger = ({ className, handleClick }) => {
    const hamburgerClassName = classNames([className, 'hamburger']);
    return (
        <div className={hamburgerClassName} onClick={handleClick}>
            <div className='hamburger-box'>
                <div className='hamburger-inner' />
            </div>
        </div>
    );
};

Hamburger.propTypes = {
    className: PropTypes.string,
    handleClick: PropTypes.func,
};

export default Hamburger;
