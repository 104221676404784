import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import MyPackagesTable from './MyPackagesTable/';
import TabSwitcher from '../../../../Dashboard/DashboardPage/TabSwitcher/';
import { getCustomerPurchasePackages, getCustomerNextPurchasePackages } from '../../../../../../actions/customer/purchasePackagesActions';
import NoData from '../../../../../common/NoData/';
import Button from '../../../../../common/Button/';

class MyPackages extends PureComponent {
    state = {
        activePage: 'available'
    }

    componentDidMount() {
        const { getCustomerPurchasePackages } = this.props;
        getCustomerPurchasePackages(`expires_at__gte=${this.getDate()}&quantity_remaining__gte=1`);
    }

    getDate(count = 0) {
        return moment().utc().subtract(count, 'day').format('YYYY-MM-DD');
    }

    tabSwitcherItems = [
        {
            title: 'Available to Use',
            page: 'available',
        },
        {
            title: 'Expired or Used',
            page: 'expired',
        }
    ];

    handleNavbarClick = (pageName) => {
        const { getCustomerPurchasePackages } = this.props;
        if (pageName === this.state.activePage) return;
        if (pageName === 'available') {
            getCustomerPurchasePackages(`expires_at__gte=${this.getDate()}&quantity_remaining__gte=1`);
        }
        if (pageName === 'expired') {
            getCustomerPurchasePackages(`expires_at__lte=${this.getDate(1)}&quantity_remaining__lte=0`);
        }
        this.setState({
            activePage: pageName
        });
    }

    render() {
        const { packagesList, getCustomerNextPurchasePackages, nextUrl, areLoaded } = this.props;

        return (
            <div className='content-block profile-addresses'>
                <div className='content-block__header'>
                    <div className='content-block__header-text'>My packages</div>
                    <div className='content-block__header-actions'>
                        <TabSwitcher
                            blockClassName='link-switcher'
                            items={this.tabSwitcherItems}
                            activePage={this.state.activePage}
                            onNavbarClick={this.handleNavbarClick} />
                    </div>
                </div>
                <div className='content-block__body'>
                    { packagesList.size > 0
                        ? <MyPackagesTable
                            data={packagesList}
                            loading={!areLoaded}
                            nextUrl={nextUrl}
                            getNext={getCustomerNextPurchasePackages}
                            autoHeight/>
                        : <NoData
                            title='Oh no! You don’t have any packages!'
                            iconType='happy'
                            borders={false}
                            action={
                                <Button text='View Packages' link='/packages' modifierClassName='no-data__button'/>
                            }/>
                    }
                </div>
            </div>
        );
    }
}

MyPackages.propTypes = {
    getCustomerPurchasePackages: PropTypes.func,
    packagesList: PropTypes.shape({}),
    nextUrl: PropTypes.string,
    getCustomerNextPurchasePackages: PropTypes.func,
    areLoaded: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    packagesList: state.getIn(['currentCustomer', 'purchaseHistory', 'items']),
    nextUrl: state.getIn(['currentCustomer', 'purchaseHistory', 'nextUrl']),
    areLoaded: state.getIn(['currentCustomer', 'purchaseHistory', 'areLoaded'])
});

const mapDispatchToProps = {
    getCustomerPurchasePackages,
    getCustomerNextPurchasePackages,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPackages);
