import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { signIn, signInFacebook, signInGoogle } from '../../../../actions/onboarding/userActions';
import Divider from '../../../common/Divider/Divider';
import SkipStep from '../../../common/SkipStep/SkipStep';
import SocialLogin from '../../../common/SocialLogin/SocialLogin';
import Step from '../../../common/Step';
import SignInFormContainer from './SignInForm';

const SignInPage = ({ stepName = 'signin', handleSignIn, handleFaceBookSignIn, handleGoogleSignIn }) => {
    return (
        <Step
            logoClassName='signin__logo'
            contentClassName='signin__content'
            contentsContainerClassName='signin__container'
            stepName={stepName}
            componentsImage={<div className='signin__img'/>}
            dividerComponent={
                <Divider
                    dividerLineClassName='signin__divider'
                    dividerTextClassName='signin__divider-text'
                    text='or'/>
            }
            socialLoginComponent={
                <SocialLogin
                    socialLoginClassName='signin__social-login'
                    faceBookCB={handleFaceBookSignIn}
                    googleCB={handleGoogleSignIn}/>
            }
            formComponent={
                <SignInFormContainer
                    onSubmit={values => {
                        const updatedValues = values.set('authFor', 'customer');
                        return handleSignIn(updatedValues);
                    }}/>
            }
            skipComponent={
                <SkipStep
                    className='signin__skip-step'
                    text={'Don\'t have an account?'}
                    linkTo='sign-up'
                    linkText='Sign Up'/>
            }/>
    );
};

SignInPage.propTypes = {
    stepName: PropTypes.string,
    handleSignIn: PropTypes.func,
    handleFaceBookSignIn: PropTypes.func,
    handleGoogleSignIn: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
    handleSignIn: bindActionCreators(signIn, dispatch),
    handleFaceBookSignIn: bindActionCreators(signInFacebook, dispatch),
    handleGoogleSignIn: bindActionCreators(signInGoogle, dispatch)
});

export default connect(null, mapDispatchToProps)(SignInPage);
