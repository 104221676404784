import React from 'react';
import Table from '../../../../../common/Table';
import * as columns from './columns';

class CustomersTable extends Table {
    state = {
        vipValue: '',
        isScrollable: false,
    };

    updateSelectFilter = (e, onFiltering, filterType) => {
        this.setState({ vipValue: e });
        onFiltering(`${filterType}${e.value}`);
    };

    render() {
        const { isScrollable } = this.state;
        const { classNameModifier = '', count, onCustomerClick } = this.props;
        const valueClickers = {
            'Name': onCustomerClick,
        };
        return (
            <div className={`customers-table ${classNameModifier}`}>
                <div className='customers-table__header'>
                    <div className='customers-table__header-content'>
                        Customers {count !== null && `(${count})`}
                    </div>
                </div>
                {this.renderTable({ isScrollable, columns, valueClickers })}
            </div>
        );
    }
}

export default CustomersTable;
