import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { activateEmail } from '../../../../actions/onboarding/userActions';
import AjaxLoader from '../../../../components/common/AjaxLoader';

class ActivateEmailPage extends PureComponent {
    componentDidMount() {
        const { code, activateEmail } = this.props;
        activateEmail(code);
    }

    render() {
        return (
            <AjaxLoader />
        );
    }
}

ActivateEmailPage.propTypes = {
    code: PropTypes.string,
    activateEmail: PropTypes.func,
};

const mapStateToProps = (state) => ({
    code: state.getIn(['routing', 'locationBeforeTransitions']).query.code,
});

const mapDispatchToProps = {
    activateEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateEmailPage);
