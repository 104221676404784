import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ProfileForm from './ProfileForm';
import ChangePasswordForm from './ChangePasswordForm';
import { loadProfileData, setProfileData, changePassword } from '../../../../services/profile';
import { ReactComponent as IconArrowDown } from '../../../../assets/icons/icon-toggle.svg';

class ProfileEditPage extends React.PureComponent {
    static propTypes = {
        getProfileData: PropTypes.func,
        handleUpdateProfile: PropTypes.func,
        isProfileDataLoaded: PropTypes.bool,
        handleChangePassword: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.sectionsHeight = {
            profile: null,
            changePass: null
        };
        this.isProfileSectionOpened = true;
        this.isChangePassSectionOpened = false;
    }

    componentDidMount() {
        const { isProfileDataLoaded, getProfileData } = this.props;
        if (!isProfileDataLoaded) {
            getProfileData();
        }

        if (!this.isProfileSectionOpened) {
            this.toggleSection('profile');
        }

        if (!this.isChangePassSectionOpened) {
            this.toggleSection('changePass');
        }
    }

    // 'profile' or 'changePass'
    toggleSection = (section = 'profile') => {
        const currSection = section === 'profile' ? this.profileSection : this.changePassSection;
        const toggleButton = section === 'profile' ? this.profileToggle : this.changePassToggle;
        let currHeight = this.sectionsHeight[section];
        const isSectionOpened = !(currSection.offsetHeight === 0);

        if (currHeight === null ||
            (currHeight !== currSection.offsetHeight && currSection.offsetHeight)) {
            let height = 0;
            if (isSectionOpened) {
                currSection.style.height = 'auto';
                height = currSection.offsetHeight;
                currSection.style.height = height + 'px';
            }
            currHeight = height;
        }

        currSection.style.height = currHeight + 'px';
        this.sectionsHeight[section] = currHeight;

        if (!isSectionOpened) {
            toggleButton.classList.remove('profile-edit__section-toggle--closed');
            // clear fixed height after animation complete
            setTimeout(() => {
                currSection.removeAttribute('style');
            }, 300);
        } else {
            setTimeout(() => {
                toggleButton.classList.add('profile-edit__section-toggle--closed');
                currSection.style.height = 0;
            }, 0);
        }
    }

    render() {
        const { handleUpdateProfile, handleChangePassword } = this.props;
        return (
            <section className='profile-edit'>
                <div className='profile-edit__container'>
                    <div className='profile-edit__section'>
                        <div className='profile-edit__section-head'>
                            <span className='profile-edit__section-head__text'>My profile information</span>
                            <button
                                ref={(dropdown) => { this.profileToggle = dropdown; }}
                                className='profile-edit__section-toggle'
                                onClick={() => { this.toggleSection('profile'); }}>
                                <IconArrowDown className='profile-edit__section-toggle__icon'/>
                            </button>
                        </div>
                        <div
                            ref={section => { this.profileSection = section; }}
                            className='profile-edit__section-content'>
                            <div className='profile-edit__section-content__wrapper'>
                                <ProfileForm
                                    onSubmit={values => handleUpdateProfile(values)} />
                            </div>
                        </div>
                    </div>
                    <div className='profile-edit__section'>
                        <div className='profile-edit__section-head profile-edit__section-head--dark'>
                            <span className='profile-edit__section-head__text'>Change password</span>
                            <button
                                ref={(dropdown) => { this.changePassToggle = dropdown; }}
                                className='profile-edit__section-toggle'
                                onClick={() => { this.toggleSection('change-pass'); }}>
                                <IconArrowDown className='profile-edit__section-toggle__icon'/>
                            </button>
                        </div>
                        <div
                            ref={section => { this.changePassSection = section; }}
                            className='profile-edit__section-content'>
                            <div className='profile-edit__section-content__wrapper'>
                                <ChangePasswordForm
                                    onSubmit={values => handleChangePassword(values)} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    getProfileData: bindActionCreators(loadProfileData, dispatch),
    handleUpdateProfile: bindActionCreators(setProfileData, dispatch),
    handleChangePassword: bindActionCreators(changePassword, dispatch)
});

const mapStateToProps = (state) => ({
    isProfileDataLoaded: !!state.getIn(['user', 'id'])
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditPage);
