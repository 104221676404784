export const RESET_MODAL_REDUCER = 'RESET_MODAL_REDUCER';
export const SET_MODAL_OPENED = 'SET_MODAL_OPENED';
export const SET_MODAL_CLOSED = 'SET_MODAL_CLOSED';
export const SET_MODAL_SUBMIT_REQUEST_STARTED = 'SET_MODAL_SUBMIT_REQUEST_STARTED';
export const SET_MODAL_SUBMIT_REQUEST_FINISHED = 'SET_MODAL_SUBMIT_REQUEST_FINISHED';
export const SET_MODAL_CANCEL_REQUEST_STARTED = 'SET_MODAL_CANCEL_REQUEST_STARTED';
export const SET_MODAL_CANCEL_REQUEST_FINISHED = 'SET_MODAL_CANCEL_REQUEST_FINISHED';
export const SET_MODAL_DATA = 'SET_MODAL_DATA';
export const SET_MODAL_DATA_OPEN = 'SET_MODAL_DATA_OPEN';
export const SET_MODAL_ACTIVE_FORM = 'SET_MODAL_ACTIVE_FORM';

export const SET_MODAL_SUBMIT_ERROR = 'SET_MODAL_SUBMIT_ERROR';
export const CLEAR_MODAL_SUBMIT_ERROR = 'CLEAR_MODAL_SUBMIT_ERROR';
