import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import LocationChangeStep from '../LocationChangeStep';
import { updateCustomerLocationData } from '../../../../../services/customers';

const PickupLocationsChange = ({
    addSubmitData, goToStep, currentPickup,
    updateCustomerLocationData, ...formProps
}) => {
    return (
        <LocationChangeStep
            {...formProps}
            initialLocation={currentPickup}
            target='Pickup'
            onSubmit={(data) => {
                const { location, default_pickup: defaultPickup } = data.toJS();
                if (defaultPickup === true) {
                    updateCustomerLocationData({
                        pickup_location: +location
                    });
                }
                addSubmitData({ name: 'pickup', data: +location });
                goToStep(4);
        }} />
    );
};

PickupLocationsChange.propTypes = {
    addSubmitData: PropTypes.func,
    updateCustomerLocationData: PropTypes.func,
    goToStep: PropTypes.func,
    currentPickup: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
    const { formData, submitData } = ownProps;
    let currentPickup = formData.addresses[0];
    if (!isEmpty(submitData) && 'pickup' in submitData) {
        currentPickup = submitData.pickup.toString();
    }
    return {
        currentPickup
    };
};

const mapDispatchToProps = {
    updateCustomerLocationData
};

export default connect(mapStateToProps, mapDispatchToProps)(PickupLocationsChange);
