import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as DirectoryTypes from '../types/dashboard/directoryTypes';

const INITIAL_STATE = fromJS({
    items: [],
    choices: [],
    count: 0,
    totalCount: 0,
    pageSize: 0,
    pages: 0,
    nextUrl: null,
    previousUrl: null,
    isLoading: false
});

const customersListReducer = handleActions({
    [DirectoryTypes.INIT_DIRECTORY_FILTER_OPTIONS]: (state, action) => {
        return state.update((state) => {
            if (!state.getIn(['choices']).size) {
                return state.setIn(['choices'], fromJS(action.payload));
            }
            return state;
        });
    },
    [DirectoryTypes.SET_DIRECTORY_FILTER_OPTIONS]: (state, action) => {
        return state.update((state) => {
            return state.setIn(['choices'], fromJS(action.payload));
        });
    },
    [DirectoryTypes.SET_DIRECTORY_LIST]: (state, action) => {
        const { results, count_total, count, next, previous } = action.payload;
        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);
        const pagesCount = Math.ceil(count / results.length);
        // eslint-disable-next-line camelcase
        const totalCount = count_total;

        return state.update((state) => {
            return state.setIn(['items'], fromJS(results))
                        .setIn(['count'], count)
                        .setIn(['totalCount'], totalCount)
                        .setIn(['pageSize'], results.length)
                        .setIn(['pages'], pagesCount)
                        .setIn(['nextUrl'], nextUrl)
                        .setIn(['previousUrl'], previousUrl);
        });
    },
    [DirectoryTypes.SET_NEXT_DIRECTORY_LIST]: (state, action) => {
        const { results, count_total, count, next, previous } = action.payload;
        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);
        const pagesCount = Math.ceil(count / results.length);
        // eslint-disable-next-line camelcase
        const totalCount = count_total;

        return state.update((state) => {
            return state.setIn(['items'], state.get('items').concat(fromJS(results)))
                        .setIn(['count'], count)
                        .setIn(['totalCount'], totalCount)
                        .setIn(['pageSize'], results.length)
                        .setIn(['pages'], pagesCount)
                        .setIn(['nextUrl'], nextUrl)
                        .setIn(['previousUrl'], previousUrl);
        });
    },
    [DirectoryTypes.PREPEND_DIRECTORIES]: (state, action) => {
        return state.update((state) => {
            return state.setIn(['items'], state.get('items').insert(0, action.payload)).setIn(['count'],
                state.getIn(['count']) + 1);
        });
    },
    [DirectoryTypes.START_DIRECTORY_LIST_LOADING]: (state, action) => (
        state.setIn(['isLoading'], true)
    ),
    [DirectoryTypes.FINISH_DIRECTORY_LIST_LOADING]: (state, action) => (
        state.setIn(['isLoading'], false)
    ),
    [DirectoryTypes.REMOVE_DIRECTORY_EMPLOYEE_BY_ID]: (state, action) => {
        return state.update((state) => {
            return state.updateIn(['items'], items => items.filter(item => item.get('id') !== action.payload))
                        .set('count', state.get('count') - 1);
        });
    }
}, INITIAL_STATE);

export default customersListReducer;
