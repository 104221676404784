import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import AlertWrapper from './AlertWrapper';
import Alert from './Alert';

class AlertQueue extends PureComponent {
    static propTypes = {
        items: PropTypes.shape({}),
        onAlertRemove: PropTypes.func.isRequired
    };
    
    renderAlerts = () => {
        const { items, onAlertRemove } = this.props;
        
        if (!items || !items.size) {
            return null;
        }
        
        return (
            items.map((alert, index) => {
                return (
                    <Alert
                        onAlertRemove={onAlertRemove}
                        alert={alert}
                        key={alert.get('id')}/>
                );
            })
        );
    };
    
    render() {
        return (
            <div className='alert-wrapper'>
                <TransitionGroup>
                    {this.renderAlerts()}
                </TransitionGroup>
            </div>
        );
    }
}

export default AlertWrapper(AlertQueue);
