import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openEditPackageModalDialog, openPackageDeleteModalDialog } from '../../../../../../actions/modal/openActions';
import { ReactComponent as IconDots } from '../../../../../../assets/icons/icon-dots.svg';
import { ReactComponent as IconEdit } from '../../../../../../assets/icons/icon-edit.svg';
import { ReactComponent as IconPause } from '../../../../../../assets/icons/icon-pause.svg';
import { ReactComponent as IconPlay } from '../../../../../../assets/icons/icon-play.svg';
import { ReactComponent as IconTrash } from '../../../../../../assets/icons/icon-trash.svg';
import { formatMoney } from '../../../../../../helpers/currency';
import { getDays } from '../../../../../../helpers/date';
import { getTypeName } from '../../../../../../helpers/text';
import { toggleStatus } from '../../../../../../services/packages';
import Dropdown from '../../../../../common/Dropdown';
import Table from '../../../../../common/Table';

class PackagesTable extends Table {
    static propTypes = {
        data: PropTypes.shape({}).isRequired,
        loading: PropTypes.bool.isRequired,
        count: PropTypes.number,
        pages: PropTypes.number,
        getNext: PropTypes.func,
        nextUrl: PropTypes.string,
        handleOpenEditPackageModal: PropTypes.func.isRequired,
        handleDeletePackageModalOpened: PropTypes.func.isRequired,
    };

    state = {
        isScrollable: false,
    };

    render() {
        const { isScrollable } = this.state;
        const { handleOpenEditPackageModal,
            handleDeletePackageModalOpened,
            handleTogglePackagePause,
            readOnly
        } = this.props;
        const columns = [
            {
                Header: 'Type',
                headerClassName: 'packages-table__th',
                className: 'packages-table__td',
                id: 'model_type',
                // sortable: false,
                accessor: d => d.get('model_type'),
                Cell: row => (
                    <div>
                        {row.value ? getTypeName(row.value) : '-'}
                    </div>
                )
            },
            {
                Header: 'Class',
                headerClassName: 'packages-table__th',
                className: 'packages-table__td',
                id: 'name',
                accessor: d => d.get('name'),
                Cell: row => (
                    <div>
                        {row.value || '-'}
                    </div>
                )
            },
            {
                Header: 'Quantity',
                headerClassName: 'packages-table__th',
                className: 'packages-table__td',
                id: 'quantity',
                accessor: d => d.get('quantity'),
                Cell: row => (
                    <div>
                        {row.value || '-'}
                    </div>
                )
            },
            {
                Header: 'Expires After',
                headerClassName: 'packages-table__th',
                className: 'packages-table__td',
                id: 'expires_after',
                accessor: d => d.get('expires_after'),
                Cell: row => (
                    <div>
                        {row.value ? getDays(row.value) : '-'}
                    </div>
                )
            },
            {
                Header: 'Price',
                headerClassName: 'packages-table__th',
                className: 'packages-table__td',
                id: 'price',
                accessor: d => d.get('price'),
                Cell: row => (
                    <div>
                        {row.value ? formatMoney(row.value) : '-'}
                    </div>
                )
            },
            {
                Header: 'Status',
                headerClassName: 'packages-table__th',
                className: 'packages-table__td',
                id: 'is_paused',
                accessor: d => d.get('is_paused'),
                Cell: row => (
                    <div>
                        {row.value ? 'Paused' : 'Active'}
                    </div>
                )
            },
        ];
        if (!readOnly) {
            columns.push({
                Header: '',
                id: 'options',
                headerClassName: 'packages-table__th',
                className: 'packages-table__td packages-table__td_last-of-type',
                width: 50,
                accessor: d => d,
                Cell: row => (
                    <Dropdown
                        classNames='dropdown dropdown_table-packages'
                        icon={IconDots}
                        iconClass='icon_chevron_down'
                        items={[
                            {
                                iconClass: row.original.get('is_paused') ? 'icon_play' : 'icon_pause',
                                icon: row.original.get('is_paused') ? IconPlay : IconPause,
                                text: row.original.get('is_paused') ? 'Play' : 'Pause',
                                onClickCB: () => {
                                    handleTogglePackagePause(row.original);
                                },
                            },
                            {
                                iconClass: 'icon_edit',
                                icon: IconEdit,
                                text: 'Edit',
                                onClickCB: () => {
                                    handleOpenEditPackageModal(row.original);
                                },
                            },
                            {
                                iconClass: 'icon_trash',
                                icon: IconTrash,
                                text: 'Delete',
                                onClickCB: () => {
                                    handleDeletePackageModalOpened(row.original);
                                },
                            },
                        ]}/>
                )
            });
        }
        return (
            <div className='packages-table'>
                {this.renderTable({ isScrollable, columns })}
            </div>
        );
    }
}

PackagesTable.propTypes = {
    readOnly: PropTypes.bool,
    data: PropTypes.shape({}).isRequired,
    handleOpenEditPackageModal: PropTypes.func.isRequired,
    handleDeletePackageModalOpened: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    handleOpenEditPackageModal: bindActionCreators(openEditPackageModalDialog, dispatch),
    handleDeletePackageModalOpened: bindActionCreators(openPackageDeleteModalDialog, dispatch),
    handleTogglePackagePause: bindActionCreators(toggleStatus, dispatch),
});

export default connect(null, mapDispatchToProps)(PackagesTable);
