import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';
import SearchGrid from './SearchGrid';
import {
    setCurrentCustomerFromDogsList,
    setCurrentDogFromDogsList,
} from '../../../../../actions/dashboard/employeeActions';
import { openSearchPage, closeSearchPage, updateSearchResults } from '../../../../../actions/dashboard/searchBarActions';
import { getNextSearchData } from '../../../../../services/searchBar';
import FilterForm from '../../../../common/FiterForm';

class SearchPage extends React.PureComponent {
    static propTypes = {
        searchData: PropTypes.shape({}),
        handleSettingCurrentDog: PropTypes.func,
        handleSettingCurrentCustomer: PropTypes.func,
        handleOpenSearchPage: PropTypes.func,
        handleCloseSearchPage: PropTypes.func,
        handleNextSearchResults: PropTypes.func,
        handleUpdateFilterSearchResults: PropTypes.func,
    };

    componentWillMount() {
        const { handleOpenSearchPage } = this.props;
        handleOpenSearchPage();
    }

    componentWillUnmount() {
        const { handleCloseSearchPage, handleUpdateFilterSearchResults } = this.props;
        handleCloseSearchPage();
        handleUpdateFilterSearchResults();
    }

    getView() {
        const { searchData, handleNextSearchResults } = this.props;

        if (searchData.get('isLoading') && !searchData.get('results').size) {
            return <div className='search-page-results__empty-content'>Loading...</div>;
        } else if (
            (!searchData.get('results').size && !searchData.get('isLoading')) ||
            searchData.get('searchInputValue') !== searchData.get('searchString')
        ) {
            return <div className='search-page-results__empty-content'>No data</div>;
        }

        return <SearchGrid data={searchData} getNext={handleNextSearchResults}/>;

        // return null;
    }

    renderFilterForm = () => {
        const { searchData, handleUpdateFilterSearchResults } = this.props;

        if (searchData.get('isLoading') && !searchData.get('filters').size) {
            return <div>Loading filter options...</div>;
        }

        return (
            <FilterForm
                name='class-schedule-filters'
                options={searchData.get('filters')}
                filtersRef={element => {
                    this.filterForm = element;
                }}
                onChangeHandler={value => {
                    handleUpdateFilterSearchResults(value.queryObject);
                }}
                newVersion/>
        );
    };

    render() {
        // eslint-disable-next-line max-len
        const { searchData } = this.props;
        return (
            <div className='search-page'>
                <div className='search-page__top'>
                    {this.renderFilterForm()}
                </div>
                <div className='search-page__header'>
                    {searchData.get('searchInputValue').length > 0
                        ? 'All results for ' + searchData.get('searchInputValue')
                        : 'No search data'}
                </div>
                {this.getView()}
            </div>
        );
    }
}

const form = reduxForm({
    form: 'searchForm',
});

const mapStateToProps = state => {
    return {
        searchData: state.getIn(['search']),
    };
};

const mapDispatchToProps = dispatch => ({
    handleSettingCurrentDog: bindActionCreators(setCurrentDogFromDogsList, dispatch),
    handleSettingCurrentCustomer: bindActionCreators(setCurrentCustomerFromDogsList, dispatch),
    handleOpenSearchPage: bindActionCreators(openSearchPage, dispatch),
    handleCloseSearchPage: bindActionCreators(closeSearchPage, dispatch),
    handleNextSearchResults: bindActionCreators(getNextSearchData, dispatch),
    handleUpdateFilterSearchResults: bindActionCreators(updateSearchResults, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(SearchPage));
