import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { openEditCustomerSchedule } from '../../../../../actions/modal/openActions';
import { ReactComponent as IconTraining } from '../../../../../assets/icons/icon-all-classes.svg';
import { ReactComponent as IconCar } from '../../../../../assets/icons/icon-car.svg';
import { ReactComponent as IconEdit } from '../../../../../assets/icons/icon-edit-customer.svg';
import { ReactComponent as IconLocation } from '../../../../../assets/icons/icon-location.svg';
import { ReactComponent as IconTrainer } from '../../../../../assets/icons/icon-trainer-default.svg';
import { ReactComponent as IconClass } from '../../../../../assets/icons/icon-training.svg';
import { normalizeScheduleEvent } from '../../../../../helpers/normalizeScheduleEvent';
import AvatarPlaceholder from '../../../../common/AvatarPlaceholder';
import Icon from '../../../../common/Icon/';
import PointsMap from '../../../../common/PointsMap';
import ScheduleCardDogs from './ScheduleCardDogs/';

class ScheduleCard extends PureComponent {
    state = {
        mapIsOpened: false
    };

    handleClickEditSchedule(data) {
        const { openEditCustomerSchedule } = this.props;
        openEditCustomerSchedule(data);
    }

    toggleMap = () => {
        this.setState({
            mapIsOpened: !this.state.mapIsOpened
        });
    };

    render() {
        const { mapIsOpened } = this.state;
        const { className, zIndex } = this.props;
        const data = normalizeScheduleEvent(this.props.data);
        const { dogs, isTraining, date, dayOfWeek, day, event, eventName, eventPhoto, eventLocation, trainerFullName, start, end, pickupLocation, dropoffLocation } = data; // eslint-disable-line max-len
        const points = [
            {
                address: pickupLocation.string,
                type: 'pickup'
            },
            {
                address: dropoffLocation.string,
                type: 'dropoff'
            },
        ];

        const cardClassName = classNames({
            'schedule-card': true,
            [className]: className
        });
        const timeClass = classNames({
            'schedule-card__time': true,
            'schedule-card__dropdown': true,
            'schedule-card__dropdown_opened': mapIsOpened,
        });
        const mapWrapperClass = classNames({
            'schedule-card__map-wrapper': true,
            'schedule-card__map-wrapper_opened': mapIsOpened,
        });
        const accessClassName = classNames({
            'schedule-card__access': true,
            'schedule-card__access_warning': event.access_level === 0
        });
        const photoStyle = {
            backgroundImage: `url(${eventPhoto})`
        };

        return (
            <div className={cardClassName} style={{ zIndex }}>

                <div className='schedule-card__inner'>

                    <div className='schedule-card__header'>
                        <div className='schedule-card__date'>
                            <div className='schedule-card__day-of-week'>{dayOfWeek}</div>
                            <div className='schedule-card__day'>{day}</div>
                        </div>
                        <ScheduleCardDogs dogs={dogs} />
                    </div>
                    <div className='schedule-card__body'>
                        <div className='schedule-card__photo' style={photoStyle}>
                            {
                                event.access_level_detail &&
                                <div className={accessClassName}>
                                    {event.access_level_detail}
                                </div>
                            }
                            {eventPhoto === null && <AvatarPlaceholder type='dog' size='customer-schedule-card' />}
                        </div>
                        <div className='schedule-card__content'>
                            <div className='schedule-card__content-item'>
                                <h2 className='schedule-card__name'>
                                    {isTraining
                                        ? <Icon glyph={IconTraining} className='icon_training' />
                                        : <Icon glyph={IconClass} />
                                    }
                                    {eventName}
                                </h2>
                                <div className='schedule-card__content-date'>
                                    {moment(date).format('dddd')} {moment(date).format('MM/DD/YY')}
                                </div>
                            </div>
                            <div className='schedule-card__content-item'>
                                {
                                    eventLocation &&
                                    <span className='schedule-card__location'><Icon
                                        glyph={IconLocation} />{eventLocation}</span>
                                }
                                <span className='schedule-card__trainer'><Icon
                                    glyph={IconTrainer} />{trainerFullName}</span>
                            </div>
                            <div className='schedule-card__content-item'>
                                <span className={timeClass} onClick={this.toggleMap}>
                                    <Icon glyph={IconCar} />
                                    <span className='schedule-card__time-item'>{start.text}: {start.time}</span>
                                    <span className='schedule-card__time-item'>{end.text}: {end.time}</span>
                                </span>
                            </div>
                        </div>
                        {
                            !isTraining &&
                            <div className='schedule-card__edit-section'>
                                <div
                                    className='schedule-card__edit'
                                    onClick={this.handleClickEditSchedule.bind(this, data)}>
                                    <Icon glyph={IconEdit} className='icon_edit' />Edit
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className={mapWrapperClass}>
                    <div className='schedule-card__map'>
                        {<PointsMap points={points} />}
                    </div>
                    <div className='schedule-card__points'>
                        <div className='schedule-card__points-item'>
                            <div className='schedule-card__points-time schedule-card__points-time_pickup'>
                                {start.text}: {start.time}
                            </div>
                            <div className='schedule-card__points-info'>
                                <span className='schedule-card__points-place'>{pickupLocation.name}</span>
                                {pickupLocation.string}
                            </div>
                        </div>
                        <div className='schedule-card__points-item'>
                            <div className='schedule-card__points-time schedule-card__points-time_dropoff'>
                                {end.text}: {end.time}
                            </div>
                            <div className='schedule-card__points-info'>
                                <span className='schedule-card__points-place'>{dropoffLocation.name}</span>
                                {dropoffLocation.string}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='schedule-card__dogs-slider'>
                    {
                        dogs.map((dog, index) => {
                            const style = {
                                backgroundImage: `url(${dog.photo})`
                            };
                            return (
                                <div className='schedule-card__dog' style={style} key={`schedule-card__dog_${index}`} />
                            );
                        })
                    }
                </div>

            </div>
        );
    }
}

ScheduleCard.propTypes = {
    data: PropTypes.shape({}),
    className: PropTypes.string,
    zIndex: PropTypes.number,
    openEditCustomerSchedule: PropTypes.func,
};

const mapDispatchToProps = {
    openEditCustomerSchedule,
};

export default connect(null, mapDispatchToProps)(ScheduleCard);
