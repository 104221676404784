export const MARK_CUSTOMER_VACCINATION_RECORDS_LOADED = 'MARK_CUSTOMER_VACCINATION_RECORDS_LOADED';
export const UNMARK_CUSTOMER_VACCINATION_RECORDS_LOADED = 'UNMARK_CUSTOMER_VACCINATION_RECORDS_LOADED';
export const SET_CURRENT_CUSTOMER_VACCINATION_RECORDS = 'SET_CURRENT_CUSTOMER_VACCINATION_RECORDS';
export const REMOVE_CURRENT_CUSTOMER_VACCINATION = 'REMOVE_CURRENT_CUSTOMER_VACCINATION';
export const PREPEND_CURRENT_CUSTOMER_VACCINATION = 'PREPEND_CURRENT_CUSTOMER_VACCINATION';
export const UPLOAD_CURRENT_CUSTOMER_VACCINATION = 'UPLOAD_CURRENT_CUSTOMER_VACCINATION';

export const MARK_DOG_VACCINATION_RECORDS_LOADED = 'MARK_DOG_VACCINATION_RECORDS_LOADED';
export const UNMARK_DOG_VACCINATION_RECORDS_LOADED = 'UNMARK_DOG_VACCINATION_RECORDS_LOADED';
export const SET_CURRENT_DOG_VACCINATION_RECORDS = 'SET_CURRENT_DOG_VACCINATION_RECORDS';
export const SET_CURRENT_DOG_VACCINATION_RECORDS_CHOICES = 'SET_CURRENT_DOG_VACCINATION_RECORDS_CHOICES';
export const START_DOG_VACCINATION_RECORDS_LOADING = 'START_DOG_VACCINATION_RECORDS_LOADING';
export const FINISH_DOG_VACCINATION_RECORDS_LOADING = 'FINISH_DOG_VACCINATION_RECORDS_LOADING';

export const UPDATE_CURRENT_DOG_VACCINATION_RECORDS = 'UPDATE_CURRENT_DOG_VACCINATION_RECORDS';
