import * as ClassesActions from '../dashboard/classesActions';

export const cancelChargingCustomersUpcomingClass = data => {
    return dispatch => {
        const { classData } = data;
        classData.force_cancel_payment = true;
        return dispatch(ClassesActions.patchCustomersUpcomingClassStatus(
            classData,
            2
        ));
    };
};

export const cancelChargingDogsUpcomingClass = data => {
    return dispatch => {
        const { classData } = data;
        classData.force_cancel_payment = true;
        return dispatch(ClassesActions.patchDogsUpcomingClassStatus(
            classData,
            2
        ));
    };
};

