// Extract orientation data from JPEG images.
//
// Usage:
//
// const input = document.getElementById('input');
// input.onchange = function(e) {
//     getOrientation(input.files[0], function(orientation) {
//         alert('orientation: ' + orientation);
//     });
// };
//
// Returns:
//
// -2: not jpeg
// -1: not defined

import { FILE_IMAGE_SIZE_MAX, FILE_IMAGE_HEIGHT_MAX, FILE_IMAGE_WIDTH_MAX } from '../config';

export const getOrientation = (file) => new Promise(resolve => {
    let orientation;
    const needsRotation = (orientation) => orientation > 4 && orientation < 9;
    const reader = new FileReader();
    reader.onload = (e) => {
        const view = new DataView(e.target.result);
        if (view.getUint16(0, false) !== 0xFFD8) {
            orientation = -2;
            return resolve({ orientation, needsRotation: needsRotation(orientation) });
        }
        const length = view.byteLength;
        let offset = 2;
        while (offset < length) {
            if (view.getUint16(offset + 2, false) <= 8) {
                orientation = -1;
                return resolve({ orientation, needsRotation: needsRotation(orientation) });
            }
            const marker = view.getUint16(offset, false);
            offset += 2;
            if (marker === 0xFFE1) {
                // eslint-disable-next-line no-cond-assign
                if (view.getUint32(offset += 2, false) !== 0x45786966) {
                    orientation = -1;
                    return resolve({ orientation, needsRotation: needsRotation(orientation) });
                }
                const little = view.getUint16(offset += 6, false) === 0x4949;
                offset += view.getUint32(offset + 4, little);
                const tags = view.getUint16(offset, little);
                offset += 2;
                for (let i = 0; i < tags; i += 1) {
                    if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                        orientation = view.getUint16(offset + (i * 12) + 8, little);
                        return resolve({ orientation, needsRotation: needsRotation(orientation) });
                    }
                }
                // eslint-disable-next-line no-bitwise
            } else if ((marker & 0xFF00) !== 0xFF00) {
                break;
            } else {
                offset += view.getUint16(offset, false);
            }
        }
        orientation = -1;
        return resolve({ orientation, needsRotation: needsRotation(orientation) });
    };
    reader.readAsArrayBuffer(file);
});

export const rotateImage = ({ imageObject, orientation }) => new Promise((resolve) => {
    const width = imageObject.naturalWidth || imageObject.width;
    const height = imageObject.naturalHeight || imageObject.height;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // set proper canvas dimensions before transform & export
    if (orientation > 4 && orientation < 9) {
        canvas.width = height;
        canvas.height = width;
    } else {
        canvas.width = width;
        canvas.height = height;
    }

    // transform context before drawing image
    switch (orientation) {
        case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
        case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
        case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
        case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
        case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
        case 7: ctx.transform(0, -1, -1, 0, height, width); break;
        case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
        default: break;
    }

    // draw image
    ctx.drawImage(imageObject, 0, 0);

    // export base64
    return resolve(canvas);
});

export const getBlobPromise = (canvas) => new Promise((resolve) => {
    canvas.toBlob((blob) => {
        return resolve(blob);
    });
});

/* eslint-disable max-len */
/* eslint-disable consistent-return */
export const validateImage = (file) => new Promise((resolve, reject) => {
    if (file.size >= FILE_IMAGE_SIZE_MAX) {
        const imageLimitMB = FILE_IMAGE_SIZE_MAX / (1024 * 1024);
        return reject(`Image file size is too big, maximum permitted is ${imageLimitMB}MB`);
    }

    let needsRotation = false;

    getOrientation(file)
    .then(result => {
        needsRotation = result.needsRotation;
    })
    .catch(error => console.log('getOrientation failed, err=', error));

    const reader = new FileReader();
    const image = new Image();

    image.onload = () => {
        let w = image.naturalWidth || image.width;
        let h = image.naturalHeight || image.height;

        if (needsRotation) {
            [w, h] = [h, w];
        }

        if (h > FILE_IMAGE_HEIGHT_MAX || w > FILE_IMAGE_WIDTH_MAX) {
            return reject(`Image resolution exceeds maximum permitted resolution of ${FILE_IMAGE_WIDTH_MAX}x${FILE_IMAGE_HEIGHT_MAX}`);
        }

        return resolve({ file, image, needsRotation });
    };

    reader.onload = e => {
        image.src = e.target.result;
    };

    reader.readAsDataURL(file);
});
