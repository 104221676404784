import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form/immutable';
import { renderError, renderWarning } from '../RadioControl/RadioControl';
import renderInputControl from '../InputControl';

class MedicalNotes extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string,
        label: PropTypes.string,
        warn: PropTypes.string,
        title: PropTypes.string,
        isOpened: PropTypes.bool,
        additional: PropTypes.shape({}),
        options: PropTypes.arrayOf(PropTypes.shape({})),
        validate: PropTypes.func,
    };

    state = {
        selectedOption: undefined
    }

    componentDidMount() {

    }


    componentWillUpdate() {
        const { isOpened } = this.props;
        const { selectedOption } = this.state;
        if (selectedOption && !isOpened) {
            this.resetSelectedOption();
        }
    }

    resetSelectedOption() {
        this.setState({
            selectedOption: undefined
        });
    }

    renderAdditionalControl() {
        const { additional } = this.props;

        return (
            <Field
                {...additional}
                type={'text'}
                isTextarea
                key={additional.name}
                component={renderInputControl}/>
        );
    }

    render() {
        const { name, label, validate, warn, options, title } = this.props;
        const { selectedOption } = this.state;

        return (
            <div className='form__aligner_margin-top'>
                <div className='form__section-title'>{title}</div>
                <div className='radio-control'>
                    <div className='radio-control__description'>
                        <label className='radio-control__description-label'>
                            {label}
                        </label>
                        <Field name='is_altered' component={renderError}/>
                        <Field name='is_altered' component={renderWarning}/>
                    </div>
                    <div className='radio-control__controls'>
                        {options.map((option, i) => {
                            return (
                                <label
                                    className='radio-control__controls-label'
                                    key={`${name}_${i}`}>
                                    <Field
                                        name={name}
                                        component='input'
                                        type='radio'
                                        onChange={(e) => {
                                            this.setState({
                                                selectedOption: e.currentTarget.value
                                            });
                                        }}
                                        value={option.value}
                                        warn={warn || undefined}
                                        validate={validate}
                                        className='radio-control__controls-input'/>
                                    <span className='radio-control__controls-text'>{option.label}</span>
                                </label>
                            );
                        })}
                    </div>
                </div>
                {selectedOption === 'Yes' && this.renderAdditionalControl()}
            </div>
        );
    }
}

export default MedicalNotes;
