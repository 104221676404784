const validate = values => {
    const errors = {};
    if (!values.get('full_name')) {
        errors.full_name = 'Full name is Required.';
    } else if (values.get('full_name').length < 2) {
        errors.full_name = 'Ful name must be 2 characters or more.';
    } else if (values.get('full_name').length > 100) {
        errors.full_name = 'Full name must be 100 characters or less.';
    }
    return errors;
};

export default validate;
