export const profileLinks = [
    {
        text: 'My Profile',
        to: '/profile'
    },
    {
        text: 'Payment Methods',
        to: '/payment-methods'
    },
    {
        text: 'Payment History',
        to: '/payment-history'
    },
    {
        text: 'Classes History',
        to: '/classes-history'
    },
    {
        text: 'Notifications',
        to: '/notifications'
    },
    {
        text: 'Change Password',
        to: '/change-password'
    },
];
