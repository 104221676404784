import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { dataStates } from '../../../../../helpers/state';
import Loader from '../../../../common/Loader';

export default class MessagesLoadMore extends React.Component {
    static propTypes = {
        getNext: PropTypes.func.isRequired,
        dataState: PropTypes.string.isRequired,
    };

    state = {
        hidden: true,
    };

    componentDidMount() {
        this.debounced = true;
        document.querySelector('.messages__body').addEventListener('scroll', _.debounce(this.handleScroll, 50));
    }

    componentWillUnmount() {
        this.debounced = false;
        document.querySelector('.messages__body').removeEventListener('scroll', this.handleScroll);
    }

    getText = () => {
        if (this.props.dataState === dataStates.loadingMore) {
            return (
                <div className='messages__load-more-text messages__load-more-text_loading'>
                    <span>Loading</span>
                    <Loader
                        isVisible
                        colorClassName='icon_messages-loader-more-dark-gray-small'
                        modifierClassName='icon_messages-loader-more'/>
                </div>
            );
        }

        return <div className='messages__load-more-text'>Load More Messages</div>;
    };

    handleScroll = () => {
        const messagesBodyElement = document.querySelector('.messages__body');
        // px to top of the element
        const scrollTop = messagesBodyElement.scrollTop;

        if (this.debounced) {
            if (scrollTop <= 100) {
                this.setState({ hidden: false });
            } else {
                this.setState({ hidden: true });
            }
        }
    };

    render() {
        const { dataState, getNext } = this.props;
        const { loadingMore } = dataStates;
        const MessagesLoadMoreClassNames = classNames({
            'messages__load-more': true,
            'messages__load-more_loading': dataState === loadingMore,
            'messages__load-more_hidden': this.state.hidden,
        });
        return (
            <div
                className={MessagesLoadMoreClassNames}
                onClick={() => {
                    if (dataState === loadingMore) {
                        return null;
                    }
                    return getNext();
                }}>
                {this.getText()}
            </div>
        );
    }
}
