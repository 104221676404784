import PropTypes from 'prop-types';
import React from 'react';
import PackagesCard from '../../containers/Dashboard/Packages/PackagesPage/PackagesCard';
import ClassesScheduleCard from '../ClassesScheduleCard';
import EmployeeCard from '../EmployeeCard';
import Loader from '../Loader';
import SearchCard from '../SearchCard';
import CustomerClassesCard from '../../containers/Customer/Classes/CustomerClassesCard';

export default class Grid extends React.PureComponent {
    static propTypes = {
        items: PropTypes.shape({}).isRequired,
        className: PropTypes.string,
        loader: PropTypes.node,
        cardType: PropTypes.string.isRequired,
        cardComponent: PropTypes.shape({}),
        getNext: PropTypes.func.isRequired,
        nextUrl: PropTypes.string,
        isLoading: PropTypes.bool.isRequired,
        readOnly: PropTypes.bool,
        emptyItemsClassNames: PropTypes.string,
        cardData: PropTypes.shape({}),
    };

    componentDidMount() {
        document.addEventListener('scroll', this.pageScrollCheck);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.pageScrollCheck);
    }

    getCardRenderer = cardType => {
        const cardsList = {
            'package': PackagesCard,
            'classSchedule': ClassesScheduleCard,
            'customerClasses': CustomerClassesCard,
            'employee': EmployeeCard,
            'search': SearchCard,
        };
        return cardsList[cardType] || null;
    };

    pageScrollCheck = () => {
        const { getNext, nextUrl, isLoading, className } = this.props;
        const tableBodyElement = document.getElementsByClassName(className);
        const windowScrollHeight = window.pageYOffset + window.innerHeight;
        const tableScrollTop = tableBodyElement[0].getBoundingClientRect().top + window.pageYOffset;
        const tableOffsetHeight = tableBodyElement[0].offsetHeight;

        if (windowScrollHeight >= tableScrollTop + tableOffsetHeight && nextUrl !== null && !isLoading) {
            getNext();
        }
    };

    renderCards = () => {
        const {
            items, cardType, emptyItemsClassNames = 'grid-card-container grid-card-container_empty', cardData,
            readOnly = false, cardComponent = null
        } = this.props;
        const CardRenderer = cardType ? this.getCardRenderer(cardType) : cardComponent;
        const cards = [];
        items.map((item, i) => {
            if (cardType === 'customerClasses') {
                return cards.push(
                    <CardRenderer
                        data={item}
                        dogs={cardData}
                        index={i}
                        readOnly={readOnly}
                        key={'card_' + i}/>);
            }
            return cards.push(<CardRenderer data={item} index={i} readOnly={readOnly} key={'card_' + i}/>);
        });
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < 6; i++) cards.push(<div key={'card_empty' + i} className={emptyItemsClassNames}/>);
        return cards;
    };

    render() {
        const {
            isLoading, className, items,
            loader = (
                <Loader
                    isVisible
                    loadingText='Loading...'
                    colorClassName='icon_messages-loader-black'
                    modifierClassName='grid-loader'/>),
        } = this.props;
        return (
            <div className={className}>
                {!!items.size && this.renderCards()}
                {isLoading && loader}
            </div>
        );
    }
}
