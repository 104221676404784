import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as IconCheckmark } from '../../../../assets/icons/icon-checkmark.svg';

import Icon from '../../Icon';

import DateControl from '../DateControl';

// eslint-disable-next-line max-len
class renderDateIntervalControl extends React.PureComponent {
    state = {
        disableEnd: false,
    };

    componentWillMount() {
        this.updateState(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.updateState(nextProps);
    }

    updateState(props) {
        const { input } = props;
        const newData = input.value.size ? input.value.toJS() : input.value;

        if (newData.end === 'No End Date' || newData.end === 'disabled') {
            newData.end = 'No End Date';
            newData.disableEnd = true;
        }

        this.setState(newData);
    }

    updateFieldValue() {
        const { input } = this.props;
        const { start, end, disableEnd } = this.state;

        const dateIntervalData = {
            start,
            end: !disableEnd ? end : 'No End Date',
        };

        const momentStartDate = moment(start, 'MM/DD/YY');
        const momentEndDate = moment(end, 'MM/DD/YY');

        const startDateParts = start.split('/');
        const endDateParts = end.split('/');

        if (momentStartDate.isValid() && momentEndDate.isValid()) {
            if (startDateParts.length === 3 && endDateParts.length === 3) {
                const startDatePartsValidLength = startDateParts.reduce((valid, dateItem) => {
                    if (valid) {
                        if (dateItem.length !== 2) {
                            valid = false;
                        }
                    }
                    return valid;
                }, true);
                const endDatePartsValidLength = endDateParts.reduce((valid, dateItem) => {
                    if (valid) {
                        if (dateItem.length !== 2) {
                            valid = false;
                        }
                    }
                    return valid;
                }, true);
                if (startDatePartsValidLength && endDatePartsValidLength) {
                    if (momentStartDate.isAfter(momentEndDate)) {
                        dateIntervalData.end = start;
                    }
                }
            }
        }
        input.onChange(dateIntervalData);
    }

    handleOnChange = (type, value) => {
        const datesObject = {};
        datesObject[type] = value;
        this.setState(datesObject, () => {
            this.updateFieldValue();
        });
    };

    handleNoEndDate = () => {
        const { disableEnd, start } = this.state;

        const datesObject = {
            disableEnd: !disableEnd,
        };

        if (datesObject.disableEnd === true) {
            datesObject.end = 'No End Date';
        } else {
            datesObject.end = start
                ? moment(start, 'MM/DD/YY').isValid()
                                  ? moment(start, 'MM/DD/YY').format('MM/DD/YY')
                                  : moment().format('MM/DD/YY')
                : moment().format('MM/DD/YY');
        }
        this.setState(datesObject, () => {
            this.updateFieldValue();
        });
    };

    render() {
        const {
            input,
            label,
            meta,
            className = '',
            startProps,
            endProps,
            disablePast = false,
            disableFuture = false,
            noEndDateCheckbox = false,
        } = this.props;
        const { start, end, disableEnd } = this.state;
        const InputContainerClassNames = classNames({
            'date-interval-control': true,
            'form__aligner': true,
            'form__aligner_50-50': true,
        });
        return (
            <div className={`${InputContainerClassNames} ${className}`}>
                <div className='date-interval-control__start-date'>
                    <DateControl
                        {...Object.assign({}, startProps, {
                            meta: Object.assign({}, meta, {
                                error: meta.error ? meta.error.start : undefined,
                                warn: meta.warn ? meta.warn.start : undefined,
                            }),
                            input: {
                                value: start,
                                onChange: startDate => {
                                    this.handleOnChange('start', startDate);
                                },
                                onBlur: event => {
                                    input.onBlur();
                                },
                            },
                        })}
                        disableFuture={disableFuture}
                        disablePast={disablePast}/>
                </div>
                <div className='date-interval-control__end-date'>
                    <DateControl
                        {...Object.assign({}, endProps, {
                            meta: Object.assign({}, meta, {
                                error: meta.error ? meta.error.end : undefined,
                                warn: meta.warn ? meta.warn.end : undefined,
                            }),
                            input: {
                                value: end,
                                onChange: endDate => {
                                    this.handleOnChange('end', endDate);
                                },
                                onBlur: event => {
                                    input.onBlur();
                                },
                            },
                        })}
                        disabled={disableEnd}
                        disableFuture={disableFuture}
                        disabledDays={{
                            before: start,
                        }}/>
                    {noEndDateCheckbox && (
                        <label className='date-interval-control__no-end-date-checkbox-label'>
                            <input
                                className='date-interval-control__no-end-date-checkbox'
                                type='checkbox'
                                checked={disableEnd}
                                onChange={this.handleNoEndDate}/>
                            {disableEnd
                                ? (<div
                                    className='date-interval-control__multibox-checkbox date-interval-control__multibox-checkbox_checked'>
                                    <Icon glyph={IconCheckmark} className='icon_check-mark-small'/>
                                </div>)
                                : (<div className='date-interval-control__multibox-checkbox'/>)}
                            <span className='date-interval-control__checkbox-name'>Continue with No End Date</span>
                        </label>
                    )}
                </div>
            </div>
        );
    }
}

renderDateIntervalControl.propTypes = {
    name: PropTypes.string,
    input: PropTypes.shape({}),
    label: PropTypes.string,
    type: PropTypes.string,
    meta: PropTypes.shape({}),
    hint: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onKeyPress: PropTypes.func,
    startProps: PropTypes.shape({}),
    endProps: PropTypes.shape({}),
    disablePast: PropTypes.bool,
    disableFuture: PropTypes.bool,
    noEndDateCheckbox: PropTypes.bool,
    onChange: PropTypes.func,
};

export default renderDateIntervalControl;
