import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const AddButton = ({ className = '', textClassName = '', text, onClickHandler, disabled }) => {
    const addButtonClassNames = classNames({
        'button add-button ': true,
        ' button_disabled ': disabled
    });
    return (
        <button
            type='button'
            className={addButtonClassNames + className}
            disabled={disabled}
            onClick={onClickHandler}>
            <div className='add-button__icon'/>
            <div className={'add-button__text' + textClassName}>{text}</div>
        </button>
    );
};

AddButton.propTypes = {
    className: PropTypes.string,
    textClassName: PropTypes.string,
    text: PropTypes.string.isRequired,
    onClickHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

export default AddButton;
