import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetPassword } from '../../../../services/resetPassword';
import SkipStep from '../../../common/SkipStep/SkipStep';
import Step from '../../../common/Step';
import StepDescription from '../../../common/StepDescription';
import ResetPasswordFormContainer from './ResetPasswordForm';

const ResetPasswordPage = ({ stepName = 'forgot-password', handleResetPassword, location }) => {
    const code = location.pathname.slice(21); // '/reset-password/code=reset_code' => 'reset_code'
    return (
        <Step
            logoClassName='forgot-password__logo'
            contentClassName='forgot-password__content'
            contentsContainerClassName='forgot-password__container'
            stepName={stepName}
            componentsImage={<div className='forgot-password__img'/>}
            descriptionComponent={
                <StepDescription
                    className='forgot-password__step-description forgot-password__step-description_reset'
                    text='Create a new password'/>
            }
            formComponent={
                <ResetPasswordFormContainer
                    onSubmit={values => handleResetPassword(values.set('code', code))}/>
            }
            skipComponent={
                <SkipStep
                    className='forgot-password__skip-step forgot-password__skip-step_reset'
                    skipLinkText='Cancel'
                    skipLinkTo='/'/>
            }/>
    );
};

ResetPasswordPage.propTypes = {
    stepName: PropTypes.string,
    handleResetPassword: PropTypes.func,
    location: PropTypes.shape({})
};

const mapDispatchToProps = (dispatch) => ({
    handleResetPassword: bindActionCreators(resetPassword, dispatch)
});

export default connect(null, mapDispatchToProps)(ResetPasswordPage);
