/* eslint-disable max-len */
import { createAction } from 'redux-actions';
import { getNotesIncidents, postNotesIncidents } from '../../services/notes';
import * as NotesIncidentsTypes from '../../types/dashboard/notesIncidentsTypes';
import { getUserNameSpace } from '../../helpers/userRoles';

export const markCustomersNotesIncidentsLoaded = createAction(NotesIncidentsTypes.MARK_CUSTOMER_NOTES_INCIDENTS_LOADED);
export const unmarkCustomersNotesIncidentsLoaded = createAction(
    NotesIncidentsTypes.UNMARK_CUSTOMER_NOTES_INCIDENTS_LOADED);
export const setCurrentCustomerNotesIncidents = createAction(NotesIncidentsTypes.SET_CURRENT_CUSTOMER_NOTES_INCIDENTS);
export const addCurrentCustomerNewNotesIncidents = createAction(
    NotesIncidentsTypes.ADD_CURRENT_CUSTOMER_NOTES_INCIDENTS_ITEM);
export const getCustomersNotesIncidents = (customerId) => getNotesIncidents({
    role: getUserNameSpace('customers.notes-incidents'),
    context: 'customers',
    id: customerId
});

export const handleCurrentCustomerNotesIncidentsSubmit = (customerId, data) => postNotesIncidents({
    role: getUserNameSpace('customers.notes-incidents'),
    context: 'customers',
    id: customerId,
    data
});

export const markDogsNotesIncidentsLoaded = createAction(NotesIncidentsTypes.MARK_DOG_NOTES_INCIDENTS_LOADED);
export const unmarkDogsNotesIncidentsLoaded = createAction(NotesIncidentsTypes.UNMARK_DOG_NOTES_INCIDENTS_LOADED);
export const setCurrentDogNotesIncidents = createAction(NotesIncidentsTypes.SET_CURRENT_DOG_NOTES_INCIDENTS);
export const addCurrentDogNewNotesIncidents = createAction(NotesIncidentsTypes.ADD_CURRENT_DOG_NOTES_INCIDENTS_ITEM);
export const getDogsNotesIncidents = (customerId) => getNotesIncidents({
    role: getUserNameSpace('dogs.notes-incidents'),
    context: 'dogs',
    id: customerId
});

export const handleCurrentDogNotesIncidentsSubmit = (dogId, data) => postNotesIncidents({
    role: getUserNameSpace('dogs.notes-incidents'),
    context: 'dogs',
    id: dogId,
    data
});
