import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../../../common/Icon';
import { ReactComponent as IconAdd } from '../../../../assets/icons/icon-add.svg';

const DogReportVideoInput = props => {
    const {
        input,
        label,
        type,
        meta: { touched, error: err, valid, warning },
        hint,
        isTextarea = false,
        formError,
        className = '',
        disabled,
        onKeyPress,
        handleRef,
        shouldUseWarning,
        formProps,
        onAdd,
        style,
    } = props;

    const error = shouldUseWarning ? warning : err;
    const isFormDisabled = (formProps && formProps.disabled) || false;
    const isButtonDisabled = !input.value;

    const InputContainerClassNames = classNames({
        'input-control': true,
        'input-control__textarea': isTextarea,
        'input-control__textarea_failed': isTextarea && error && touched,
        'select-control__select': true,
    });

    const InputClassNames = classNames({
        'input-control__input': true,
        'input-control__input_touched': touched || input.value.length,
        'input-control__input_failed': (touched && error) || formError,
        'input-control__input_empty': input.value.length === 0 && !(touched && error),
    });

    const PlaceholderClassNames = classNames({
        'input-control__placeholder': true,
        'input-control__placeholder_touched': touched || valid,
        'input-control__placeholder_failed': touched && error,
        'input-control__placeholder_empty': input.value.length === 0 && !(touched && error),
    });

    const addButtonClassNames = classNames({
        'Select-add': true,
        'Select-add_disabled': isFormDisabled || isButtonDisabled
    });

    const addIconClassNames = classNames({
        'icon_add-search': true,
        'icon_disabled': isFormDisabled || isButtonDisabled
    });

    const handleAddVideo = () => {
      onAdd(input.value, input.onChange, '');
    }

    return (
        <div className={`${InputContainerClassNames} ${className}`} style={style}>
            <input
                {...input}
                ref={handleRef}
                type={type}
                disabled={disabled}
                className={InputClassNames}
                onKeyPress={onKeyPress}/>
            <span className={PlaceholderClassNames}>{label}</span>
            <div onClick={handleAddVideo} className={addButtonClassNames}>
                <Icon glyph={IconAdd} className={addIconClassNames}/>
            </div>
            {error && <span className='input-control__error'>{error}</span>}
            {hint && <span className='input-control__hint'>{hint}</span>}
        </div>
    );
};

DogReportVideoInput.propTypes = {
    name: PropTypes.string,
    input: PropTypes.shape({}),
    label: PropTypes.string,
    type: PropTypes.string,
    meta: PropTypes.shape({}),
    hint: PropTypes.string,
    isTextarea: PropTypes.bool,
    formError: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.string
    ]),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    isCardInput: PropTypes.bool,
    isDateInput: PropTypes.bool,
    isMasked: PropTypes.bool,
    mask: PropTypes.string,
    maskChar: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    onKeyPress: PropTypes.func,
    onChangeCallback: PropTypes.func,
    handleRef: PropTypes.func,
    shouldUseWarning: PropTypes.bool
};

export default DogReportVideoInput;
