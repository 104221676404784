import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as CustomerTypes from '../types/customer/customerTypes';

const INITIAL_STATE = fromJS({
    form_update_signature: '',
    date_stamp: '',
    form_update_signature_is_added: true,
});

const formUpdateReducer = handleActions(
    {
        [CustomerTypes.SAVE_SIGNATURE_OWNER]: (state, action) => (
            state.set('form_update_signature', action.payload)
        ),
        [CustomerTypes.SAVE_DATA_STAMP]: (state, action) => (
            state.set('date_stamp', action.payload)
        ),
        [CustomerTypes.SIGNATURE_ADDING_STATUS]: (state, action) => (
            state.set('form_update_signature_is_added', action.payload)
        ),
    },
    INITIAL_STATE
);

export default formUpdateReducer;
