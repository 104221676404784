import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteCustomer } from '../../../../actions/dashboard/deleteActions';
import { setDeleteCustomerModalClosed } from '../../../../actions/miscActions';
import ModalDialog from '../../../common/ModalDialog';

const DeleteCustomerModalDialog = ({ isModalOpened, handleModalClosed, handleSubmit, customersName }) => {
    return (
        <ModalDialog
            form={
                <div className='modal-dialog__form'>
                    <p className='modal-dialog__text modal-dialog__text_bold'>
                        Are you sure you would like to delete {customersName}
                    </p>
                    <p className='modal-dialog__text'>
                        Once deleted, all profile and customer data will be removed forever.
                    </p>
                    <div className='modal-dialog__form-footer modal-dialog__form-footer_row'>
                        <button
                            className='button modal-dialog__button'
                            onClick={handleSubmit}>
                            Delete
                        </button>
                    </div>
                </div>
            }
            title={'Delete customer'}
            isModalOpened={isModalOpened}
            handleModalClosed={handleModalClosed}/>
    );
};

DeleteCustomerModalDialog.propTypes = {
    isModalOpened: PropTypes.bool,
    handleModalClosed: PropTypes.func,
    handleSubmit: PropTypes.func,
    customersName: PropTypes.string,
};

const mapStateToProps = state => {
    return {
        customersName: state.getIn(['currentCustomer', 'instance', 'full_name']),
        isModalOpened: state.getIn(['misc', 'modals', 'deleteCustomer'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleModalClosed: bindActionCreators(setDeleteCustomerModalClosed, dispatch),
    handleSubmit: bindActionCreators(deleteCustomer, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(DeleteCustomerModalDialog);
