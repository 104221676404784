import { capitalizeEachWord } from '../../../../../../helpers/text';

export const getNormalizedData = (rowData) => {
    // Example input: { 'by_class_name' : { 'Canyon Hike' : { 'attendance' : .... }, ... } }
    const reportName = Object.keys(rowData)[0];
    const reportData = rowData[reportName];
    let result = Object.keys(reportData).map((key) => {
        return { key, ...reportData[key] };
    });

    if (reportName === 'by_weekday') {
        // Sort by day of the week number first
        result.sort((a, b) => a.key - b.key);

        // Substitute day of the week numbers for days.  1=Sunday, 7=Saturday.  
        const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        result = result.map((d) => {
            const { key, ...data } = d;
            return { key: weekDays[key - 1], ...data };
        });
    } else {
        result.sort((a, b) => a.key.localeCompare(b.key));
    }
    return result;
};

export const getComponentColumns = (rowData) => {
// Example input: { 'by_class_name' : { 'Canyon Hike' : { 'attendance' : .... }, ... } }
    const filterParam = Object.keys(rowData)[0];
    const paramName = capitalizeEachWord(filterParam.replace(/(by)?_/g, ' '));
    return [
        {
            id: 'header',
            Header: paramName,
            headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
            className: 'custom-reports__inner-table__td',
            accessor: d => d.key,
        },
        {
            id: 'avg_attendance',
            Header: 'Average Attendance Per Class (empty classes excluded)',
            headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
            className: 'custom-reports__inner-table__td',
            accessor: d => d.avg_attendance
        },
        {
            id: 'attendance',
            Header: 'Total Dogs Attended',
            headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
            className: 'custom-reports__inner-table__td',
            accessor: d => d.attendance
        },
        {
            id: 'attended_occurrences',
            Header: 'Total Class Occurrences (empty classes excluded)',
            headerClassName: 'custom-reports__inner-table__th custom-reports__inner-table__th_wrapped',
            className: 'custom-reports__inner-table__td',
            accessor: d => d.attended_occurrences
        }
    ];
};
