import { fromJS } from 'immutable';

const initialValues = {
    get 1() {
        return {
            blocks: [
                {
                    block_id: 3,
                    content_id: 1,
                    photo: {
                        photo_processed: '/assets/imgs/900_645_01.jpg'
                    },
                    title: 'Tristique Lacus ',
                    subtitle: 'August 2, 2017',
                    title2: '1712 21st Street,\n' +
                    'Santa Monica, CA 90404',
                    body: 'Nam pulvinar libero eu erat tincidunt congue. Maecenas commodo lorem id nisl.'
                },
                {
                    block_id: 3,
                    content_id: 2,
                    photo: {
                        photo_processed: '/assets/imgs/900_645_02.jpg'
                    },
                    title: 'Wellness Wednesdays',
                    subtitle: 'August 2, 2017',
                    title2: '1712 21st Street,\n' +
                    'Santa Monica, CA 90404',
                    body: 'Nam pulvinar libero eu erat tincidunt congue. Maecenas commodo lorem id nisl.'
                }
            ]
        };
    },
    get 2() {
        return {
            blocks: [
                {
                    block_id: 1,
                    content_id: 3,
                    photo: {
                        photo_processed: '/assets/imgs/1830_960_01.jpg'
                    },
                    title: 'Vestibulum nec Condimentum Elit nec Fermentum Erat',
                    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rutrum dui tellus, et suscipit purus egestas ut. Phasellus fringilla lectus eu justo lobortis eleifend. Curabitur efficitur odio sed est dictum posuere. Nunc molestie nisi risus. Morbi sollicitudin efficitur turpis non consectetur.'
                }
            ]
        };
    },
    get 3() {
        return {
            blocks: [
                {
                    block_id: 1,
                    content_id: 4,
                    photo: {
                        photo_processed: '/assets/imgs/1830_960_02.jpg'
                    },
                    title: 'Vestibulum nec Condimentum Elit nec Fermentum Erat',
                    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rutrum dui tellus, et suscipit purus egestas ut. Phasellus fringilla lectus eu justo lobortis eleifend. Curabitur efficitur odio sed est dictum posuere. Nunc molestie nisi risus. Morbi sollicitudin efficitur turpis non consectetur.'
                },
                {
                    block_id: 4,
                    content_id: 5,
                    photo1: {
                        photo_processed: '/assets/imgs/290-1.jpg'
                    },
                    photo2: {
                        photo_processed: '/assets/imgs/290-2.jpg'
                    },
                    photo3: {
                        photo_processed: '/assets/imgs/290-3.png'
                    },
                    photo4: {
                        photo_processed: '/assets/imgs/290-4.jpg'
                    }
                }
            ]
        };
    },
    get 4() {
        return {
            blocks: [
                {
                    block_id: 2,
                    content_id: 6,
                    photo: {
                        photo_processed: '/assets/imgs/1830_960_03.jpg'
                    },
                    title: 'Vestibulum nec Condimentum Elit nec Fermentum Erat',
                    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rutrum dui tellus, et suscipit purus egestas ut. Phasellus fringilla lectus eu justo lobortis eleifend. Curabitur efficitur odio sed est dictum posuere. Nunc molestie nisi risus. Morbi sollicitudin efficitur turpis non consectetur.'
                }
            ]
        };
    }
};

const getInitialValuesForTemplate = id => {
    return fromJS(initialValues[id]);
};

export default getInitialValuesForTemplate;
