import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import * as SteppedFormTypes from '../types/steppedFormTypes';

const baseFormConfig = {
    currentStep: 0,
    submitData: {},
    data: {}
};

const INITIAL_STATE = fromJS({});

export const getSteppedFormValues = (formName) => {
    return (state) => {
        const formValues = state.getIn(['steppedForm', formName]) || {};
        return formValues && formValues.size ? formValues.toJS() : formValues;
    };
};

const steppedFormReducer = handleActions({
    [SteppedFormTypes.SET_CURRENT_STEP]: (state, { payload }) => {
        const { formName, step } = payload;
        return state.setIn([formName, 'currentStep'], step);
    },
    [SteppedFormTypes.ADD_STEP_DATA]: (state, action) => {
        const { formName, name, data } = action.payload;
        return state.setIn([formName, 'data', name], data);
    },
    [SteppedFormTypes.ADD_SUBMIT_DATA]: (state, action) => {
        const { formName, name, data } = action.payload;
        return state.setIn([formName, 'submitData', name], data);
    },
    [SteppedFormTypes.INITIALIZE_FORM]: (state, { payload }) => {
        const { name, ...formParams } = payload;
        const baseParams = Object.assign({}, baseFormConfig);
        return state.setIn([name], fromJS(Object.assign(baseParams, formParams)));
    },
    [SteppedFormTypes.CLEAR_FORM_DATA]: (state, { payload }) => {
        return state.setIn([payload], fromJS(baseFormConfig));
    }
},
INITIAL_STATE
);

export default steppedFormReducer;
