import { getCustomerById } from '../../services/customers';
import { deleteEmergencyContact, patchEmergencyContact, postEmergencyContact } from '../../services/emergencyContact';
import { setEmergencyContactModalClosed } from '../miscActions';

export const updateCustomersEmergencyContactData = (customerId, data) => {
    return dispatch => {
        return Object.keys(data).map((owner) => {
            console.log(data[owner]);
            if (data[owner].name === 'deleted') {
                return dispatch(deleteEmergencyContact(customerId, data[owner]));
            }
            if ('id' in data[owner]) {
                return dispatch(patchEmergencyContact(customerId, data[owner]));
            }
            return dispatch(postEmergencyContact(customerId, data[owner]));
        });
    };
};

export const processCustomersEmergencyContact = (customerId, data) => {
    return dispatch => {
        return Promise.all(dispatch(updateCustomersEmergencyContactData(customerId, data))).then(() => {
            dispatch(getCustomerById(customerId)).then(() => {
                dispatch(setEmergencyContactModalClosed());
            });
        });
    };
};
