import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import { Field } from 'redux-form/immutable';
import BaseField from '../';
import Icon from '../../../common/Icon';
import { ReactComponent as IconLink } from '../../../../assets/icons/icon-external-link.svg';
import InputControl from '../../../common/Form/InputControl/InputControl';

class LinkEditor extends BaseField {
    state = {
        isInputHidden: true
    };
    
    fieldClassName = 'link-editor';
    
    handleIconClick = () => {
        this.setState(prevState => {
            if (!prevState.isInputHidden) {
                return undefined;
            }
            
            return {
                isInputHidden: false
            };
        });
    };
    
    handleBlur = e => {
        const { input: { onBlur } } = this.props;
        const error = this.getError();
        e.persist();
        onBlur(e);
        
        if (!error) {
            this.setState({
                isInputHidden: true
            });
        }
    };
    
    handleRef = el => {
        this.refInput = el;
        
        if (this.refInput) {
            window.requestAnimationFrame(() => {
                this.refInput.focus();
            });
        }
    };
    
    renderInputField = () => {
        const { isInputHidden } = this.state;
        const { input, meta, label, shouldUseWarning } = this.props;
        const { touched } = meta;
        const error = this.getError();
        const shouldDisplay = !isInputHidden || (touched && error);
        
        if (!shouldDisplay) {
            return null;
        }
        
        return (
            <div className='link-editor__input-wrapper'>
                <InputControl
                    input={{
                        onChange: input.onChange,
                        value: input.value,
                        onBlur: input.onBlur,
                        onFocus: input.onFocus
                    }}
                    shouldUseWarning={shouldUseWarning}
                    meta={meta}
                    label={label}
                    handleRef={this.handleRef}/>
            </div>
        );
    };
    
    render() {
        const { formProps: { isPreview }, input: { onFocus } } = this.props;
        
        if (isPreview) {
            return null;
        }
        
        const linkEditorClassName = classNames(
            this.getCombinedFieldClassName(),
            {
                'link-editor_active': !this.state.isInputHidden
            }
        );
        
        return (
            <div
                className={linkEditorClassName}
                onBlur={this.handleBlur}
                onFocus={onFocus}>
                <CSSTransitionGroup
                    transitionName='link-editor__input-wrapper'
                    transitionEnterTimeout={300}
                    transitionLeaveTimeout={300}>
                    {this.renderInputField()}
                </CSSTransitionGroup>
                <div className='link-editor__icon' onMouseDown={this.handleIconClick}>
                    <Icon className='icon_external-link' glyph={IconLink}/>
                </div>
            </div>
        );
    }
}

const LinkEditorField = ({ fieldConfig, formProps }) => {
    return (
        <Field
            {...fieldConfig}
            formProps={formProps}
            component={LinkEditor}/>
    );
};

LinkEditorField.propTypes = BaseField.defaultFieldProps;

export default LinkEditorField;
