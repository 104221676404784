import PropTypes from 'prop-types';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getReports } from '../../../../../actions/dashboard/dogReportsActions';
import { openCreateDogReportModalDialog, openEditDogReportModalDialog, openDeleteDogReportModalDialog } from '../../../../../actions/modal/openActions';
import AddNewItem from '../../../../../components/common/AddNewItem';
import ReportCard from './ReportCard';

class ReportPage extends React.PureComponent {
    static propTypes = {
        openCreateDogReportModalDialog: PropTypes.func,
        openEditDogReportModalDialog: PropTypes.func,
        openDeleteDogReportModalDialog: PropTypes.func,
        getReports: PropTypes.func,
        currentDog: PropTypes.shape({
            instance: PropTypes.shape({
                id: PropTypes.number,
            }),
        }),
    };

    getEmptyContent = message => {
        return (
            <div className='dog-report__empty-content'>{message}</div>
        );
    };

    // eslint-disable-next-line consistent-return
    getReports = () => {
        const { currentDog, openEditDogReportModalDialog, openDeleteDogReportModalDialog } = this.props;

        if (currentDog.reports.items !== null) {
            if (!currentDog.reports.items.length) {
                return null;
            }

            return currentDog.reports.items.map(item => {
                return <ReportCard key={'report_' + item.id} data={item} editHandler={openEditDogReportModalDialog} deleteHandler={openDeleteDogReportModalDialog}/>;
            });
        }
    };

    getPageContent = () => {
        const { openCreateDogReportModalDialog, currentDog } = this.props;
        return (
            <div className='dog-report__content'>
                {<AddNewItem
                    className='dog-report__add-new-report'
                    text='Create New Report'
                    onClickHandler={() => {
                        openCreateDogReportModalDialog({ isEventBased: false });
                    }}/>
                }
                <div className='dog-report__header'>Photos and Snapshots</div>
                {currentDog.reports.items === null && this.getEmptyContent('Loading...')}
                {(currentDog.reports.items !== null && !currentDog.reports.items.length) &&
                this.getEmptyContent('No reports')}
                {this.getReports()}
            </div>
        );
    };

    render() {
        const { currentDog, getReports } = this.props;
        const { nextUrl } = currentDog.reports;
        const loader = <div>Loading...</div>;
        return (
            <InfiniteScroll
                className='dog-report'
                loadMore={() => { getReports(null, nextUrl); }}
                hasMore={nextUrl !== null}
                loader={loader}
                pageStart={0}>
                {this.getPageContent()}
            </InfiniteScroll>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentDog: state.get('currentDog').toJS(),
    };
};

const mapDispatchToProps = dispatch => ({
    openCreateDogReportModalDialog: bindActionCreators(openCreateDogReportModalDialog, dispatch),
    openEditDogReportModalDialog: bindActionCreators(openEditDogReportModalDialog, dispatch),
    openDeleteDogReportModalDialog: bindActionCreators(openDeleteDogReportModalDialog, dispatch),
    getReports: bindActionCreators(getReports, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);
