import React from 'react';
import PropTypes from 'prop-types';

export const trainingEventEditWarning = data => {
    return (
        <div>
            The changes you made to this training will cause the following conflicts:
            <ul className='event-detail__training-conflict-list'>
                {data.data.parentFormData.errorData.map((error, i) => <li key={'error_' + i}>{error[1]}</li>)}
            </ul>
        </div>
    );
};

export const trainingEventCreateWarning = data => {
    const errorData = data.data.parentFormData.errorData;
    return (
        <div>
            The changes you made to this training will cause the following conflicts:
            <ul className='event-detail__training-conflict-list'>
                {Object.keys(errorData).map((key, i) =>
                errorData[key].map((error, i) => <li key={'error_' + i}>{error}</li>))}
            </ul>
        </div>
    );
};
trainingEventEditWarning.propTypes = {
    data: PropTypes.shape({})
};
