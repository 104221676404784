export const UPDATE_ADDITIONAL_OWNER = 'UPDATE_ADDITIONAL_OWNER';
export const ADD_ADDITIONAL_OWNER = 'ADD_ADDITIONAL_OWNER';
export const DELETE_ADDITIONAL_OWNER = 'DELETE_ADDITIONAL_OWNER';

export const UPDATE_EMERGENCY_CONTACT = 'UPDATE_EMERGENCY_CONTACT';
export const ADD_EMERGENCY_CONTACT = 'ADD_EMERGENCY_CONTACT';
export const DELETE_EMERGENCY_CONTACT = 'DELETE_EMERGENCY_CONTACT';

export const VERIFY_PRIMARY_PHONE = 'VERIFY_PRIMARY_PHONE';
export const SAVE_SIGNATURE_OWNER = 'SAVE_SIGNATURE_OWNER';
export const SAVE_DATA_STAMP = 'SAVE_DATA_STAMP';
export const SIGNATURE_ADDING_STATUS = 'SIGNATURE_ADDING_STATUS';
