import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import renderInputControl from '../../../../common/Form/InputControl';
import renderPasswordControl from '../../../../common/Form/PasswordControl/PasswordControl';
import validate from './validate';

const CreatePasswordForm = ({ handleSubmit, submitting, invalid }) => {
    const SubmitButtonClassNames = classNames({
        'create-password__submit-button': true,
        'create-password__submit-button_disabled': (invalid || submitting)
    });
    return (
        <form onSubmit={handleSubmit} className='create-password__form'>
            <Field name='email' type='text' component={renderInputControl} label={'Email address'} disabled/>
            <Field name='password' type='password' component={renderPasswordControl} label={'Create your password'}/>
            <Field
                name='password_confirm'
                type='password'
                component={renderPasswordControl}
                label={'Confirm your password'}/>
            <button className={SubmitButtonClassNames} type='submit' disabled={invalid || submitting}>
                Create password and sign in
            </button>
        </form>
    );
};

CreatePasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    location: PropTypes.shape({}),
    pathname: PropTypes.shape({}),
};

const form = reduxForm({
    form: 'CreatePasswordForm',
    validate
});

const mapStateToProps = (state) => ({
    initialValues: fromJS({
        email: state.getIn(['routing', 'locationBeforeTransitions']).query.email,
    }),
});
export default connect(mapStateToProps, null)(form(CreatePasswordForm));
