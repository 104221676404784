import React from 'react';
import PropTypes from 'prop-types';

const SummaryDiscount = ({ data: { price, count } }) => (
    <div className='booking-summary'>
        <div className='booking-summary__header booking-summary__header_discount'>
            <p className='booking-summary__title'>{count} package credit{count > 1 && 's'} applied</p>
        </div>
        <div className='booking-summary__footer'>
            <span className='booking-summary__price booking-summary__price_discount'>-${Math.abs(price)}</span>
        </div>
    </div>
);

SummaryDiscount.propTypes = {
    data: PropTypes.shape({})
};

export default SummaryDiscount;
