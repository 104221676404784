import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    meta: PropTypes.shape({}),
    input: PropTypes.shape({}),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    formProps: PropTypes.shape({}),
    shouldUseWarning: PropTypes.bool
};

class BaseField extends React.PureComponent {
    static propTypes = propTypes;
    static defaultFieldProps = {
        fieldConfig: PropTypes.shape({}),
        formProps: PropTypes.shape({}),
        isOpened: PropTypes.bool
    };
    
    componentDidMount() {
    }
    
    getError = () => {
        const { shouldUseWarning, meta: { error, warning } } = this.props;
        return shouldUseWarning ? warning : error;
    };
    
    getCombinedFieldClassName = () => {
        const { className, meta: { touched } } = this.props;
        const error = this.getError();
        
        return classNames(
            this.fieldClassName,
            className,
            (touched && error) && this.fieldInvalidClassName
        );
    };
    
    fieldInvalidClassName = 'form-field_invalid';
    formFieldErrorClassName = 'form-field-error';
    
    renderError = () => {
        const { meta: { touched } } = this.props;
        const error = this.getError();
        
        if (!(touched && error)) {
            return null;
        }
        
        return <div className={this.formFieldErrorClassName}>{error}</div>;
    };
    
    render() {
        return (
            <div
                className={this.getCombinedFieldClassName()}>
                FORM FIELD
            </div>
        );
    }
}

export default BaseField;
