import { fromJS } from 'immutable';
import { push } from 'react-router-redux';
import { createAction } from 'redux-actions';
import { getCustomerById } from '../../services/customers';
import { postOnboardingDogData, sendDashboardDogData, updateDogVeterinarian } from '../../services/dogs';
import * as DogCreationTypes from '../../types/onboarding/dogCreationTypes';
import { prependDogs } from '../dashboard/employeeActions';
import { setDogCreationLoaded, setDogCreationLoading, setDogCreationModalClosed } from '../miscActions';
import { setHealthStepComplete, setIntroductionRegistrationStepComplete } from './onBoardingStepsActions';

export const setDefaultIntroductionData = createAction(DogCreationTypes.SET_DEFAULT_INTRODUCTION_DATA);
export const setDefaultHealthData = createAction(DogCreationTypes.SET_DEFAULT_HEALTH_DATA);
export const setIntroductionInfo = createAction(DogCreationTypes.SET_DOG_INTRODUCTION_INFO);
export const setHealthInfo = createAction(DogCreationTypes.SET_DOG_HEALTH_INFO);
export const clearDogData = createAction(DogCreationTypes.CLEAR_DOG_DATA);
export const setDogId = createAction(DogCreationTypes.SET_DOG_ID);
export const clearDogId = createAction(DogCreationTypes.CLEAR_DOG_ID);

export const finishIntroductionStep = (introductionData) => {
    return (dispatch) => {
        dispatch(setIntroductionInfo(fromJS(introductionData.toJS())));
        dispatch(setHealthStepComplete());
        console.log('is registration_step in local storage:', !localStorage.getItem('registration_step'));
        console.log('is STEP 1 registration_step complete: ', +localStorage.getItem('registration_step') === 0);
        if (!localStorage.getItem('registration_step') || +localStorage.getItem('registration_step') === 0) {
            dispatch(setIntroductionRegistrationStepComplete());
            localStorage.setItem('registration_step', 1);
        }
        dispatch(push('/health'));
    };
};

export const finishHealthStep = (healthData) => {
    return (dispatch, getState) => {
        // check if dog data from introductionForm exists in store
        if (!getState().getIn(['dog', 'name']) && localStorage.getItem('introductionForm')) {
            const introductionData = fromJS(JSON.parse(localStorage.getItem('introductionForm')));
            dispatch(setIntroductionInfo(introductionData));
        }
        dispatch(setHealthInfo(healthData));
        dispatch(postOnboardingDogData({
            actionCreator: healthData.get('actionCreator'),
            redirectPath: healthData.get('redirectPath')
        }));
        // dispatch(clearDogData());
    };
};

export const processCustomerDogCreation = (dogData) => {
    return (dispatch, getState) => {
        dispatch(setDogCreationLoading());
        const isDogCreationOwnerPreselected = getState().getIn(['misc', 'modals', 'isDogCreationOwnerPreselected']);

        if (isDogCreationOwnerPreselected) {
            return dispatch(sendDashboardDogData(dogData)).then(() => {
                dispatch(getCustomerById(dogData.get('owner'))).then(() => {
                    dispatch(setDogCreationModalClosed());
                    dispatch(setDogCreationLoaded());
                });
            }).catch(err => {
                console.log(err);
                console.log(err.response);
                dispatch(setDogCreationLoaded());
                if (err.response) {
                    if (err.response.status !== 200) {
                        console.log('Unexpected error code from the API server: ', err.response.status);
                    }
                }
            });
        }

        return dispatch(sendDashboardDogData(dogData)).then(newDog => {
            dispatch(prependDogs(fromJS(newDog.data)));
            dispatch(setDogCreationModalClosed());
            dispatch(setDogCreationLoaded());
        }).catch(err => {
            console.log(err);
            console.log(err.response);
            dispatch(setDogCreationLoaded());
            if (err.response) {
                if (err.response.status !== 200) {
                    console.log('Unexpected error code from the API server: ', err.response.status);
                }
            }
        });
    };
};

export const updateDogsVeterinarian = (vetInfo) => {
    return (dispatch, getState) => {
        const dogs = getState().getIn(['dogsList', 'dogs']).toJS();
        const updateDogs = dogs.map((dog) => {
            dog.veterinarian = vetInfo.id;
            return dispatch(updateDogVeterinarian({
                id: dog.id,
                veterinarian: vetInfo.veterinarian.value,
                can_contact_veterinarian: vetInfo.can_contact_veterinarian === 'Yes' ? 'true' : 'false'
            }));
        });
        return Promise.all(updateDogs);
    };
};
