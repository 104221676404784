import { fromJS } from 'immutable';
import { createAction } from 'redux-actions';
import { reset } from 'redux-form';
import { getModalDialogFormName } from '../../components/common/Form/formFactory';
import * as ModalSidebarTypes from '../../types/modalSidebarTypes';
import * as ErrorActions from '../form/errorActions';
import * as CancelActions from './cancelActions';
import * as GetOptionsActions from './getOptionsActions';
import * as SubmitActions from './submitActions';
import * as UpdateActions from './updateActions';

export const setModalOpened = createAction(ModalSidebarTypes.SET_MODAL_SIDEBAR_OPENED);
export const setModalClosed = createAction(ModalSidebarTypes.SET_MODAL_SIDEBAR_CLOSED);
export const setModalData = createAction(ModalSidebarTypes.SET_MODAL_SIDEBAR_DATA);
export const setModalActiveForm = createAction(ModalSidebarTypes.SET_MODAL_SIDEBAR_ACTIVE_FORM);
export const setModalSubmitRequestStarted = createAction(ModalSidebarTypes.SET_MODAL_SIDEBAR_SUBMIT_REQUEST_STARTED);
export const setModalSubmitRequestFinished = createAction(ModalSidebarTypes.SET_MODAL_SIDEBAR_SUBMIT_REQUEST_FINISHED);
export const setModalCancelRequestStarted = createAction(ModalSidebarTypes.SET_MODAL_SIDEBAR_CANCEL_REQUEST_STARTED);
export const setModalCancelRequestFinished = createAction(ModalSidebarTypes.SET_MODAL_SIDEBAR_CANCEL_REQUEST_FINISHED);

export const setModalSubmitError = createAction(ModalSidebarTypes.SET_MODAL_SIDEBAR_SUBMIT_ERROR);
export const clearModalSubmitError = createAction(ModalSidebarTypes.CLEAR_MODAL_SIDEBAR_SUBMIT_ERROR);

const defaultNormalizer = data => data;
let scrollOffset = 0;
export const onModalClose = () => {
    return (dispatch) => {
        document.documentElement.style.position = 'relative';
        setTimeout(() => { // because it works
            document.documentElement.style.top = '';
            document.documentElement.scrollTop = scrollOffset;
            document.body.scrollTop = scrollOffset;
        }, 100);
        dispatch(clearModalSubmitError());
        dispatch(setModalClosed());
    };
};

export const onModalSubmit = ({ formData, submitNormalizer, updateNormalizer }) => {
    return (dispatch, getState) => {
        let formConfig = getState().getIn(['modalSidebar', 'data']);

        if (formConfig.forms !== undefined) {
            formConfig = formConfig.forms[formConfig.activeForm];
        }

        const {
            submitAction,
            updateAction,
            errorAction,
            photoUrl,
            submitClickHandler,
            form,
        } = formConfig;

        const submitData = submitNormalizer ? submitNormalizer(formData, getState()) : formData;

        if (submitAction) {
            dispatch(setModalSubmitRequestStarted());
            dispatch(SubmitActions[submitAction]({
                formConfig,
                submitData,
            }))
                .then((response) => {
                    if (form === 'customerAddNewDogModalDialog') {
                        const {
                            breed,
                            dog_name,
                            month,
                            year,
                        } = formData.toJS();

                        const dogBirthday = new Date(year.value, month.value - 1).toISOString();
                        
                        window._dcq.push(['identify', {
                            tags: ['Prospect'],
                            remove_tags: ['Lead'],
                            dog_access_level: '0',
                            dog_birthday: dogBirthday,
                            dog_breed: breed.label,
                            success: () => {
                                window._dcq.push([
                                    'track',
                                    'Created dog profile',
                                    { dog_name },
                                ]);
                            }
                        }]);

                        window.gtag('event', 'triggered_prospect_criteria', {
                            event_category: 'Triggered Prospect Criteria',
                        });
                    }

                    if (photoUrl && submitData.photo && typeof submitData.photo !== 'string') {
                        dispatch(SubmitActions.updatePhoto({
                            url: photoUrl.replace(new RegExp('{id}', 'g'), response.data.id),
                            responseData: fromJS(response.data),
                            photo: submitData.photo,
                        })).then((responsePhoto) => {
                            if (updateAction) {
                                dispatch(UpdateActions[updateAction]({
                                    formConfig,
                                    formData,
                                    responseData: fromJS(Object.assign({}, response.data, {
                                        photo: responsePhoto.data.photo,
                                        photo_processed: responsePhoto.data.photo_processed
                                            ? responsePhoto.data.photo_processed
                                            : responsePhoto.data.photo,
                                    })),
                                    normalizer: updateNormalizer || defaultNormalizer
                                }));
                            }

                            dispatch(onModalClose());
                        })
                           .catch(err => {
                               dispatch(setModalSubmitRequestFinished());
                               console.log(err);
                               console.log(err.response);
                           });
                    } else {
                        if (updateAction) {
                            dispatch(UpdateActions[updateAction]({
                                formConfig,
                                formData,
                                responseData: fromJS(response.data),
                                normalizer: updateNormalizer || defaultNormalizer
                            }));
                        }
                        if (formConfig.form === 'customerEditDogMedicalInfoModalDialog') {
                            const initialVetId = getState().getIn(['form', 'EditCreateModalSidebarDialogForm', 'initial', 'veterinarian_with_create']);
                            const vet = getState().getIn(['form', 'EditCreateModalSidebarDialogForm', 'values', 'veterinarian_with_create']);
                            if (typeof vet === 'object' && vet.value !== initialVetId) {
                                window._dcq.push(['identify', {
                                    veterinarian: vet.label,
                                }]);
                            }
                        }
                        dispatch(onModalClose());
                    }
                })
                .catch(err => {
                    dispatch(setModalSubmitRequestFinished());

                    if (errorAction) {
                        dispatch(ErrorActions[errorAction]({
                            error: err,
                            formConfig: formConfig.parentFormData ? formConfig.parentFormData.formConfig : formConfig,
                            submitData: formConfig.parentFormData
                                ? formConfig.parentFormData.submitData
                                : submitNormalizer
                                            ? submitNormalizer(formData, getState())
                                            : formData
                        }));
                    }

                    console.log(err);
                    console.log(err.response);
                });
        } else {
            dispatch(onModalClose());

            if (submitClickHandler) {
                dispatch(SubmitActions[submitClickHandler](formConfig));
            }
        }
    };
};

export const onModalOpen = (formConfig) => {
    return (dispatch) => {
        scrollOffset = window.pageYOffset || document.documentElement.scrollTop || 0;
        document.documentElement.style.top = -scrollOffset + 'px';
        document.documentElement.style.position = 'fixed';
        const formName = getModalDialogFormName(formConfig.type);

        if (formName) {
            dispatch(reset(formName));
        }

        dispatch(setModalSubmitRequestFinished());
        dispatch(setModalCancelRequestFinished());
        dispatch(setModalData(formConfig));
        dispatch(setModalOpened(formConfig.type));
    };
};

export const onModalGetOptions = (field, extra) => {
    return (dispatch) => {
        const actionName = field.options;
        return dispatch(GetOptionsActions[actionName](extra));
    };
};

export const onModalCancel = ({ updateNormalizer }) => {
    return (dispatch, getState) => {
        const formConfig = getState().getIn(['modalSidebar', 'data']);
        const { cancelAction, updateAction, cancelClickHandler } = formConfig;

        if (cancelAction) {
            dispatch(setModalCancelRequestStarted());
            dispatch(CancelActions[cancelAction](formConfig))
                .then(() => {
                    if (updateAction) {
                        dispatch(UpdateActions[updateAction]({
                            formConfig,
                            normalizer: updateNormalizer || defaultNormalizer
                        }));
                    }

                    dispatch(onModalClose());
                })
                .catch(err => {
                    dispatch(setModalCancelRequestFinished());
                    console.log(err);
                    console.log(err.response);
                });
        } else {
            dispatch(onModalClose());

            if (cancelClickHandler) {
                dispatch(CancelActions[cancelClickHandler](formConfig));
            }
        }
    };
};
