import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { formatDateToSlashes } from '../../../../../../helpers/date';
import { Link } from 'react-router';

const FormsTable = ({ data, classNameModifier = '', dataName, isLoading, currentCustomer,
                        openUserAgreementDeleteModalDialog }) => {
    const columns = [
        {
            id: 'formName',
            Header: 'Form',
            headerClassName: 'forms-table__th',
            className: 'forms-table__td',
            accessor: d => d.get('name')
        },
        {
            id: 'formAgreed',
            Header: 'Agreed (Yes or No)',
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            accessor: d => d.get('signed_at'),
            Cell: row => (
                <div>
                    {row.value === null ? 'No' : 'Yes'}
                </div>
            )
        },
        {
            id: 'formDate',
            Header: 'Date Agreed',
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            accessor: d => d.get('signed_at'),
            Cell: row => (
                <div>
                    {row.value ? formatDateToSlashes(row.value, true) : '-'}
                </div>
            )
        },
        {
            id: 'formEdit',
            Header: 'Agreements List',
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            accessor: d => d,
            Cell: row => {
                let customer = row.value.get('notify_users').toJS()
                let customerId = customer[0]['id']
                return (
                    <Link to={{ pathname: `/dashboard/customer/${customerId}/forms/${row.value.get('id')}/` }}>View Agreements</Link>
                )
            }
        }
    ];

    return (
        <div className={`forms-table ${classNameModifier}`}>
            <div className='forms-table__header'>
                <div className='forms-table__header-content'>
                    {dataName}
                </div>
            </div>
            {data.size
                ? (<ReactTable
                    showPagination={false}
                    defaultPageSize={data.length}
                    isLoading={isLoading}
                    data={data}
                    columns={columns}
                />)
                : (<div className='forms-table__empty-content'>
                    {`No data for ${dataName}`}
                </div>)
            }
        </div>
    );
};

FormsTable.propTypes = {
    data: PropTypes.shape({}).isRequired,
    currentCustomer: PropTypes.shape({}),
    classNameModifier: PropTypes.string,
    openUserAgreementDeleteModalDialog: PropTypes.func.isRequired,
    dataName: PropTypes.string.isRequired,
    isLoading: PropTypes.bool
};

export default FormsTable;
