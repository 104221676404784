import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Tabs extends PureComponent {
    state = {
        selectedTabIndex: 0,
    };

    getAdditionalProps = (index, props) => ({
        handleClick: this.handleTabClick,
        tabIndex: index,
        selected: index === this.state.selectedTabIndex,
        ...props,
    });

    getChildrenTabsWithProps = () => {
        return React.Children.map(this.props.children, (child, index) =>
            React.cloneElement(
                child,
                this.getAdditionalProps(index, child.props)
            ),
        );
    };

    getActiveTabContent = () => {
        const { children } = this.props;
        const { selectedTabIndex } = this.state;
        const currentChildren = children[selectedTabIndex];

        if (currentChildren) {
            return currentChildren.props.children;
        }

        return false;
    };

    handleTabClick = tabIndex => {
        this.setState({ selectedTabIndex: tabIndex });
    };

    render() {
        const childrenTabsWithProps = this.getChildrenTabsWithProps();
        const tabContent = this.getActiveTabContent();

        return (
            <div className='tabs'>
                <ul className='tabs__list'>{childrenTabsWithProps}</ul>
                <div className='tabs__content'>{tabContent}</div>
            </div>
        );
    }
}

Tabs.propTypes = {
    children: PropTypes.node
};

export default Tabs;
