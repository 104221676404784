import PropTypes from 'prop-types';
import React from 'react';
import Grid from '../../../../../common/Grid';

const DirectoryGrid = ({ data, getNext, readOnly, isLoading }) => {
    return (
        <Grid
            items={data.get('items')}
            className='directory__content directory__grid'
            cardType='employee'
            getNext={getNext}
            nextUrl={data.get('nextUrl')}
            isLoading={isLoading}
            readOnly={readOnly}
            emptyItemsClassNames='employee-card-container employee-card-container_empty'/>
    );
};

DirectoryGrid.propTypes = {
    data: PropTypes.shape({}).isRequired,
    isLoading: PropTypes.bool.isRequired,
    getNext: PropTypes.func,
    readOnly: PropTypes.bool,
};

export default DirectoryGrid;
