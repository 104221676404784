import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const LocationField = ({ 
    data: { name, address1, address2, city, state, ...dataProps }, 
    handleChange, label, meta: { error } 
}) => {
    const blockClasses = classNames({
        'pre-confirmation__block': true,
        'pre-confirmation__block_error': error
    });
    return (
        <div className={blockClasses}>
            <strong className='pre-confirmation__block__title'>{label}</strong>
            <div className='pre-confirmation__block__content'>
                <div className='pre-confirmation__address'>
                    <p>{name}</p>
                    <p>{address2 || ''} {address1}, {city},</p>
                    <p>{state} {dataProps.zip_code}</p>
                </div>
                <span className='pre-confirmation__address-change' onClick={handleChange}>Change</span>
            </div>
        </div>
    );
};

LocationField.propTypes = {
    data: PropTypes.shape({}),
    error: PropTypes.shape({}),
    label: PropTypes.string,
    meta: PropTypes.shape({}),
    handleChange: PropTypes.func
};

export default LocationField;
