import { createAction } from 'redux-actions';
import { capitalizeEachWord } from '../../helpers/text';
import { sendPhoto } from '../../services/file';
import * as EmployeeTypes from '../../types/dashboard/employeeTypes';
import { getUserNameSpace } from '../../helpers/userRoles';
import { setUserPhoto } from '../../actions/onboarding/userActions';

export const uploadAvatar = (currentInstanceType, file, uploadProgressCallback, successCallback = null) => {
    return (dispatch, getState) => {
        const instanceId = getState().getIn(['current' + capitalizeEachWord(currentInstanceType), 'instance', 'id']);
        let uploadUrl = '';
        if (currentInstanceType === 'me') {
            uploadUrl = `/v1/${getUserNameSpace('me')}/me/photo/`;
            successCallback = (response) => {
                dispatch(setUserPhoto({ photo: response.data.photo }));
            };
        } else {
            uploadUrl = `/v1/${getUserNameSpace(currentInstanceType + 's.photo')}/${currentInstanceType}s/${instanceId}/photo/`;
        }
        dispatch(sendPhoto({
            photo: file,
            url: uploadUrl,
            progressCallback: uploadProgressCallback,
            successCallback
        }));
    };
};

const setDogAvatar = createAction(EmployeeTypes.UPDATE_DOG_PHOTO);

export const uploadAvatarDog = (id, file, uploadProgressCallback) => {
    return (dispatch) => {
        const uploadUrl = `/v1/customers/me/dogs/${id}/photo/`;
        dispatch(sendPhoto({ photo: file, url: uploadUrl, progressCallback: uploadProgressCallback }))
            .then((response) => {
                dispatch(setDogAvatar({ id, photo: response.data.photo }));
            });
    };
};
