import classNames from 'classnames';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import renderInputControl from '../../../common/Form/InputControl';
import renderSelectControl from '../../../common/Form/SelectControl';
import validate from './validate';

// eslint-disable-next-line react/prefer-stateless-function
class DogEvaluationModalDialogForm extends React.PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func,
        invalid: PropTypes.bool,
        submitting: PropTypes.bool,
        initialValues: PropTypes.shape({}),
        errorSubmittingDogEvaluation: PropTypes.bool,
    };

    render() {
        const {
            handleSubmit,
            invalid,
            submitting,
            errorSubmittingDogEvaluation,
        } = this.props;
        const submitButtonClassNames = classNames({
            'button modal-dialog__button': true,
            'button_disabled': (invalid || submitting)
        });
        return (
            <form className='modal-dialog__form modal-dialog__form_dogs-evaluation' onSubmit={handleSubmit}>
                <div className='modal-dialog__form-content'>
                    <Field
                        name='assessment'
                        type='text'
                        component={renderSelectControl}
                        options={[{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }]}
                        label='Did the dog pass the Fitdog assessment?'/>
                    <Field
                        name='come_command'
                        type='text'
                        component={renderInputControl}
                        label='What’s your dog’s command for “come”?'/>
                    <Field
                        name='breed_reactive'
                        type='text'
                        component={renderInputControl}
                        label='Is your dog reactive to certain breeds?'/>
                    <Field
                        name='onleash_reactive'
                        type='text'
                        component={renderInputControl}
                        label='Is your dog reactive on-leash?'/>
                    <Field
                        name='triggers'
                        type='text'
                        component={renderInputControl}
                        label='Does your dog have any triggers such as skateboarders?'/>
                    <Field
                        name='ball_aggressive'
                        type='text'
                        component={renderInputControl}
                        label='Is your dog ball aggressive?'/>
                    <Field
                        name='car_sick'
                        type='text'
                        component={renderInputControl}
                        label='Does your dog experience car sickness?'/>
                    <Field
                        name='treats_word'
                        type='text'
                        component={renderInputControl}
                        label='What is your dog’s word for treats?'/>
                    <Field
                        name='last_flea'
                        type='text'
                        component={renderInputControl}
                        label='When was the last time your dog was given flea/tick medication?'/>
                    <Field
                        name='other'
                        type='text'
                        component={renderInputControl}
                        label='Other: exchange keys (if necessary); check tags or put paper tag on dog'/>
                    <Field
                        name='additional'
                        type='text'
                        component={renderInputControl}
                        label='General notes'/>
                </div>
                <div className='modal-dialog__form-footer modal-dialog__form-footer_row'>
                    {errorSubmittingDogEvaluation ?
                        <div className='form__error' style={{ marginBottom: 30 }}>Error with submission</div>
                    :
                        null
                    }
                    <button className={submitButtonClassNames}>
                        Save
                    </button>
                </div>
            </form>
        );
    }
}

const form = reduxForm({
    form: 'DogEvaluationModalDialogForm',
    enableReinitialize: true,
    validate
});

const mapStateToProps = state => {
    const currentDog = state.getIn(['currentDog']);
    return {
        initialValues: fromJS({
            assessment: currentDog.getIn(['instance', 'survey', 'assessment']) ? 'Yes' : 'No',
            come_command: currentDog.getIn(['instance', 'survey', 'come_command']),
            breed_reactive: currentDog.getIn(['instance', 'survey', 'breed_reactive']),
            onleash_reactive: currentDog.getIn(['instance', 'survey', 'onleash_reactive']),
            triggers: currentDog.getIn(['instance', 'survey', 'triggers']),
            ball_aggressive: currentDog.getIn(['instance', 'survey', 'ball_aggressive']),
            car_sick: currentDog.getIn(['instance', 'survey', 'car_sick']),
            treats_word: currentDog.getIn(['instance', 'survey', 'treats_word']),
            last_flea: currentDog.getIn(['instance', 'survey', 'last_flea']),
            other: currentDog.getIn(['instance', 'survey', 'other']),
            additional: currentDog.getIn(['instance', 'survey', 'additional'])
        }),
        errorSubmittingDogEvaluation: currentDog.get('errorSubmittingDogEvaluation'),
    };
};

export default connect(mapStateToProps, null)(form(DogEvaluationModalDialogForm));
