import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as FormFactory from '../components/common/Form/formFactory';
import { getUserNameSpace } from '../helpers/userRoles';
import * as FormsPageTypes from '../types/dashboard/formsPageTypes';

const FORM_NAME = 'editCreateFormForm';

const generateFormEditFormEmployeeConfig = formData => {
    return {
        form: FORM_NAME,
        url: `/v1/${getUserNameSpace('agreements')}/agreements/${formData.id}/`,
        itemId: formData.id,
        initialValues: FormFactory.getFormDefinition(FORM_NAME).getInitialValues(formData),
        shouldPublish: false,
        submitAction: 'editFormEditCreateFormEmployee',
        updateAction: 'updateFormEditCreateFormEmployee',
        alertEvent: 'FORM_EDIT'
    };
};

export const generateFormCreateFormEmployeeConfig = () => {
    return {
        form: FORM_NAME,
        url: `/v1/${getUserNameSpace('agreements')}/agreements/`,
        itemId: undefined,
        initialValues: undefined,
        shouldPublish: false,
        submitAction: 'createFormEditCreateFormEmployee',
        updateAction: 'updateFormEditCreateFormEmployee',
        alertEvent: 'FORM_CREATE'
    };
};

const INITIAL_STATE = fromJS({
    forms: {
        isLoading: false,
        count: 0,
        pageSize: 0,
        pages: 0,
        nextUrlQuery: null,
        items: undefined
    },
    currentForm: {
        isLoading: true,
        isPublished: false,
        formConfig: undefined
    }
});

export const formEditCreateDataNormalizer = (formData, state) => {

    const { notify_users, customer_dog, training_program, trainer, date, discount_price, preferred_date, name } = formData.toJS();
    const normalizeNotifyUsers = notifyUsers => { //eslint-disable-line
        const normalizedValue = [];

        if (notifyUsers && typeof notifyUsers != "undefined") {
                const id = notifyUsers.value;
                const grouping_type = notifyUsers.extra.groupingType;
                normalizedValue.push({ id, grouping_type });
        }

        return normalizedValue;
    };
    return {
        type: 0,
        notify_users: normalizeNotifyUsers(notify_users),
        owner_dogs: customer_dog[0].value,
        training_program_for_detail: training_program.value,
        trainer_for_detail: trainer.value,
        date_for_detail: date,
        discount_price: discount_price,
        preferred_date_for_detail: preferred_date,
        name,
        is_published: state.getIn(['formsPage', 'currentForm', 'formConfig', 'shouldPublish'])
    };
};

const formsPageReducer = handleActions({
    [FormsPageTypes.RESET_FORMS_PAGE_REDUCER]: () => {
        return INITIAL_STATE;
    },
    [FormsPageTypes.START_FORMS_DATA_LOADING]: state => {
        return state.setIn(['forms', 'isLoading'], true);
    },
    [FormsPageTypes.FINISH_FORMS_DATA_LOADING]: state => {
        return state.setIn(['forms', 'isLoading'], false);
    },
    [FormsPageTypes.SET_FORMS_ITEMS_FINISH_LOADING]: (state, action) => {
        const { results, count, next } = action.payload;
        return state.set('forms', fromJS({
            pageSize: count,
            nextUrlQuery: next ? next.split('?')[1] : null,
            items: results,
            isLoading: false
        }));
    },
    [FormsPageTypes.APPEND_FORMS_ITEMS_FINISH_LOADING]: (state, action) => {
        const { count, next, results } = action.payload;
        return state.update(state => {
            return state.set('forms', fromJS({
                pageSize: count,
                nextUrlQuery: next ? next.split('?')[1] : null,
                items: state.getIn(['forms', 'items']).concat(fromJS(results)),
                isLoading: false
            }));
        });
    },
    [FormsPageTypes.REMOVE_FORM_BY_ID]: (state, action) => {
        return state.update((state) => {
            return state.updateIn(['forms', 'items'], items => items.filter(item => item.get('id') !== action.payload));
        });
    },
    [FormsPageTypes.START_CURRENT_FORM_DATA_LOADING]: state => {
        return state.setIn(['currentForm', 'isLoading'], true);
    },
    [FormsPageTypes.FINISH_CURRENT_FORM_DATA_LOADING]: state => {
        return state.updateIn(['currentForm'], currentForm => currentForm.mergeDeep(
            fromJS({
                formConfig: generateFormCreateFormEmployeeConfig(),
                isLoading: false
            })
        ));
    },
    [FormsPageTypes.SET_CURRENT_FORM_FINISH_LOADING]: (state, action) => {
        return state.updateIn(['currentForm'], currentForm => currentForm.mergeDeep(
            fromJS({
                formConfig: generateFormEditFormEmployeeConfig(action.payload),
                isPublished: action.payload.is_published,
                isLoading: false
            })
        ));
    },
    [FormsPageTypes.UPDATE_CURRENT_FORM]: (state, action) => {
        const newCurrentForm = state.get('currentForm').mergeDeep(fromJS(action.payload));
        return state.set('currentForm', newCurrentForm);
    },
}, INITIAL_STATE);

export default formsPageReducer;
