import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as trainingsActions from '../../../../../actions/dashboard/trainingActions';
import * as miscActions from '../../../../../actions/miscActions';
import * as openActions from '../../../../../actions/modal/openActions';
import TrainingsContent from './TrainingsContent';

const TrainingsPage = (props) => {
    const {
        currentCustomer, misc, handleUpcomingCollapse, handlePreviousCollapse, handleEditingUpcomingTraining,
        handleEditingUpcomingTrainingStatus, handleCancellingUpcomingTraining, handleEditingPreviousTrainingStatus,
    } = props;

    const upcomingTrainingsActions = {
        'edit': handleEditingUpcomingTraining,
        'attended': handleEditingUpcomingTrainingStatus,
        'cancel': handleCancellingUpcomingTraining,
        'no-show': handleEditingUpcomingTrainingStatus
    };

    const previousTrainingsActions = {
        'attended': handleEditingPreviousTrainingStatus,
        'cancel': handleEditingPreviousTrainingStatus,
        'no-show': handleEditingPreviousTrainingStatus,
    };

    return (
            <div className='customers-classes'>
                {currentCustomer.get('instance', 'id') === undefined
                    ? 'Loading...'
                    : (<div className='customers-classes__content'>
                        <TrainingsContent
                            data={currentCustomer.getIn(['upcoming_trainings', 'trainings'])}
                            dataName='Upcoming Trainings'
                            isCollapsed={misc.get('upcomingTrainings')}
                            isLoading={currentCustomer.get('isLoading')}
                            collapseAction={handleUpcomingCollapse}
                            trainingsActions={upcomingTrainingsActions}/>
                        <TrainingsContent
                            data={currentCustomer.getIn(['previous_trainings', 'trainings'])}
                            dataName='Previous Trainings'
                            isPrevious
                            trainingNameModifier='classes-table_previous'
                            isCollapsed={misc.get('previousTrainings')}
                            isLoading={currentCustomer.get('isLoading')}
                            collapseAction={handlePreviousCollapse}
                            trainingsActions={previousTrainingsActions}/>
                    </div>)
                }
            </div>
        );

};

TrainingsPage.propTypes = {
    currentCustomer: PropTypes.shape({}),
    misc: PropTypes.shape({}),
    handleUpcomingCollapse: PropTypes.func,
    handlePreviousCollapse: PropTypes.func,
    handleEditingUpcomingTraining: PropTypes.func,
    handleEditingPreviousTrainingStatus: PropTypes.func,
    handleEditingUpcomingTrainingStatus: PropTypes.func,
    handleCancellingUpcomingTraining: PropTypes.func,

};

const mapStateToProps = state => {
    return {
        currentCustomer: state.get('currentCustomer'),
        misc: state.getIn(['misc', 'tablesCollapsed', 'customers'])
    };
};

const mapDispatchToProps = dispatch => ({
    handleUpcomingCollapse: bindActionCreators(miscActions.collapseCustomersUpcomingTrainings, dispatch),
    handlePreviousCollapse: bindActionCreators(miscActions.collapseCustomersPreviousTrainings, dispatch),
    handleEditingUpcomingTraining: bindActionCreators(openActions.openEditUpcomingTrainingModalDialog, dispatch),
    handleEditingUpcomingTrainingStatus: bindActionCreators(trainingsActions.markCustomersUpcomingTraining, dispatch),
    handleCancellingUpcomingTraining: bindActionCreators(trainingsActions.markCustomersUpcomingTraining, dispatch),
    handleEditingPreviousTrainingStatus: bindActionCreators(trainingsActions.patchCustomersPreviousTrainingStatus, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingsPage);
