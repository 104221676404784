import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDogCreationModalClosed } from '../../../../actions/miscActions';
import { processCustomerDogCreation } from '../../../../actions/onboarding/dogCreationActions';
import ModalDialog from '../../../common/ModalDialog';
import DogCreationModalDialogForm from './DogCreationModalDialogForm';

// eslint-disable-next-line max-len
const DogCreationModalDialog = (props) => {
    const {
        isDogCreationModalOpened, handleDogCreationModalClosed, handleSubmit, customer,
        isDogCreationOwnerPreselected
    } = props;
    const customersName = customer.get('full_name');
    const titlePrefix = customersName === undefined ? '' : ` ${customersName}'s`;
    
    const normalizeData = data => {
        if (isDogCreationOwnerPreselected) {
            data = data.set('owner', customer.get('id'));
        } else {
            data = data.set('owner', data.get('owner').value);
        }
        
        handleSubmit(data);
    };
    
    return (
        <ModalDialog
            form={
                <DogCreationModalDialogForm
                    customerName={customersName}
                    isOwnerDisabled={isDogCreationOwnerPreselected}
                    onSubmit={normalizeData}/>
            }
            title={`Create New${titlePrefix} Dog`}
            isModalOpened={isDogCreationModalOpened}
            handleModalClosed={handleDogCreationModalClosed}/>
    );
};

DogCreationModalDialog.propTypes = {
    isDogCreationModalOpened: PropTypes.bool,
    handleDogCreationModalClosed: PropTypes.func,
    handleSubmit: PropTypes.func,
    customer: PropTypes.shape({}),
    isDogCreationOwnerPreselected: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        customer: state.getIn(['currentCustomer', 'instance']),
        isDogCreationModalOpened: state.getIn(['misc', 'modals', 'dogCreation']),
        isDogCreationOwnerPreselected: state.getIn(['misc', 'modals', 'isDogCreationOwnerPreselected'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleDogCreationModalClosed: bindActionCreators(setDogCreationModalClosed, dispatch),
    handleSubmit: bindActionCreators(processCustomerDogCreation, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DogCreationModalDialog);
