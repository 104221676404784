/* eslint-disable camelcase */

import moment from 'moment';

const getDogs = (dogs, type) => {
    return dogs.map((dog) => {
        const dogDetail = type === 'trainingevent' ? dog.purchased_detail.dog_detail : dog.dog_detail;
        return {
            productId: dog.id,
            id: dogDetail.id,
            name: dogDetail.name,
            photo: dogDetail.photo_processed,
        };
    });
};

const formatTime = (dateString, timeString) => {
    const time = moment(`${dateString} ${timeString}`);
    const hours = time.format('h');
    const minutes = time.minutes() ? time.format(':mm') : '';
    const ampm = time.format('a');
    return hours + minutes + ampm;
};

const getClassLocation = (data) => Object.values(data.event.location_array_detail).join(', ');

export const getLocationString = (location) => {
    const { city, state, zip_code: zipCode } = location;
    let { address1, address2, location_notes } = location;
    address1 = (address1 === null || address1 === 'null') ? '' : address1;
    address2 = (address2 === null || address2 === 'null') ? '' : address2;
    location_notes = (location_notes === null || location_notes === 'null') ? '' : location_notes;
    return `${address1}${address2 ? ` ${address2}` : ''}, ${city}, ${state} ${zipCode}${location_notes ? `, ${location_notes}` : ''}`;
};

export const normalizeScheduleEvent = (data) => {
    const { event, products } = data;
    const isTraining = data.model_type === 'trainingevent';
    const eventDetail = isTraining ? event.program_detail : event.training_class_detail;
    event.location_detail = null;

    if (!isTraining) {
        event.location_detail = getClassLocation(data);
    }

    const eventName = eventDetail.name;
    const date = isTraining ? moment(event.start.slice(0, 10)).format('YYYY-MM-DD') : event.date;
    const dayOfWeek = moment.utc(date).format('dddd');
    const day = moment.utc(date).format('MM/DD/YY');

    const pickUpStart = formatTime(date, event.pickup_start_time);
    const pickUpEnd = formatTime(date, event.pickup_end_time);
    const dropOffStart = formatTime(date, event.dropoff_start_time);
    const dropOffEnd = formatTime(date, event.dropoff_end_time);
    const startTime = formatTime('', event.start);
    const endTime = formatTime('', event.end);

    const start = {
        text: isTraining ? 'Start' : 'Pickup',
        time: isTraining ? startTime : `${pickUpStart} - ${pickUpEnd}`
    };

    const end = {
        text: isTraining ? 'End' : 'Dropoff',
        time: isTraining ? endTime : `${dropOffStart} - ${dropOffEnd}`
    };

    return {
        origin: data,
        id: data.id,
        dogs: getDogs(products, data.model_type),
        isTraining,
        event,
        eventName,
        eventPhoto: eventDetail.photo_processed,
        eventLocation: event.location_detail,
        trainerFullName: event.trainer_detail.full_name,
        date,
        dayOfWeek,
        day,
        start,
        end,
        pickupLocation: {
            origin: data.pickup_location,
            name: data.pickup_location.name,
            string: getLocationString(data.pickup_location)
        },
        dropoffLocation: {
            origin: data.drop_off_location,
            name: data.drop_off_location.name,
            string: getLocationString(data.drop_off_location)
        },
    };
};
