import { createAction } from 'redux-actions';
import * as MiscTypes from '../../types/miscTypes';

export const setFileProgress = createAction(MiscTypes.SET_FILE_PROGRESS);
export const updateFileProgress = (fileIndex, progress) => {
    return (dispatch) => {
        dispatch(setFileProgress({ fileIndex, progress }));
    };
};

export const setUploadFileStatus = createAction(MiscTypes.SET_UPLOAD_FILE_STATUS);
export const setUploadModalOpened = createAction(MiscTypes.SET_UPLOAD_MODAL_OPENED);
export const setUploadModalClosed = createAction(MiscTypes.SET_UPLOAD_MODAL_CLOSED);
