const validate = values => {
    const errors = {};
    if (!values.get('owner')) {
        errors.owner = 'Owner is Required.';
    }

    if (!values.get('name')) {
        errors.name = 'Name is Required.';
    } else if (values.get('name').length > 254) {
        errors.email = 'It\'s not allowed to enter more than 254 symbols into this field';
    }

    if (!values.get('gender')) {
        errors.gender = 'Sex is Required.';
    }

    if (!values.get('breed')) {
        errors.breed = 'Breed is Required.';
    }

    if (!values.get('weight')) {
        errors.weight = 'Weight is Required.';
    }

    if (!values.get('month')) {
        errors.month = 'Month is Required.';
    }

    if (!values.get('year')) {
        errors.year = 'Year is Required.';
    }

    if (values.get('is_intoleranced') === 'Yes') {
        if (!values.get('intolerance_list')) {
            errors.intolerance_list = 'Intolerances are Required.';
        } else if (values.get('intolerance_list').length > 1000) {
            errors.intolerance_list = 'It\'s not allowed to enter more than 1000 symbols into this field';
        }
    }

    if (values.get('is_injured') === 'Yes') {
        if (!values.get('medical_notes')) {
            errors.medical_notes = 'Medical Conditions are Required.';
        } else if (values.get('medical_notes').length > 1000) {
            errors.medical_notes = 'It\'s not allowed to enter more than 1000 symbols into this field';
        }
    }

    // if (!values.get('neutered')) {
    //     errors.neutered = 'Field is Required.';
    // }
    // if (!values.get('assessment')) {
    //     errors.assessment = 'Field is Required.';
    // }
    return errors;
};

export default validate;
