import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openClassBookingModalDialog, openTrainingBookingModalDialog } from '../../../../actions/modal/openActions';
import Async from '../../Select/Async';
import Icon from '../../../common/Icon';
import { ReactComponent as IconAdd } from '../../../../assets/icons/icon-add.svg';

const renderSearchAddControl = props => {
    const {
        input,
        label,
        getOptions,
        autoload = false,
        optionRenderer,
        meta: { touched, error },
        header,
        noResultsText,
        formProps
    } = props;

    const isFormDisabled = (formProps && formProps.disabled) || false;
    const isButtonDisabled = !input.value;
    const SelectClassNames = classNames({
        'select-control__select': true,
        'select-control__select_touched': touched,
        'select-control__select_failed': touched && error,
    });

    const PlaceholderClassNames = classNames({
        'select-control__placeholder': true,
        'select-control__placeholder_touched': touched,
        'select-control__placeholder_failed': touched && error,
        'select-control__placeholder_empty': input.value.length === 0 && !(touched && error),
    });

    const addButtonClassNames = classNames({
        'Select-add': true,
        'Select-add_disabled': isFormDisabled || isButtonDisabled
    });

    const addIconClassNames = classNames({
        'icon_add-search': true,
        'icon_disabled': isFormDisabled || isButtonDisabled
    });

    const getError = () => {
        if (touched && error) {
            return <span className='input-control__error'>{error}</span>;
        }

        return null;
    };

    const submitHandler = e => {
        e.stopPropagation();
        e.preventDefault();

        if (isFormDisabled || isButtonDisabled) {
            return;
        }

        formProps.handleSubmit();
    };

    return (
        <div className='select-control'>
            <Async
                placeholder={false}
                loadingPlaceholder=''
                autoload={autoload}
                clearable={false}
                disabled={isFormDisabled}
                searchable
                cache={false}
                optionRenderer={optionRenderer}
                header={header}
                loadOptions={getOptions}
                noResultsText={noResultsText}
                className={SelectClassNames}
                addButton={<div
                    onMouseDown={submitHandler}
                    onTouchEnd={submitHandler}
                    className={addButtonClassNames}
                >
                    <Icon glyph={IconAdd} className={addIconClassNames} />
                </div>}
                value={input.value && input.value.toJS ? input.value.toJS() : input.value}
                onChange={value => input.onChange(value)} />
            <span className={PlaceholderClassNames}>{label}</span>
            {getError()}
        </div>
    );
};

renderSearchAddControl.propTypes = {
    input: PropTypes.shape({}),
    meta: PropTypes.shape({}),
    label: PropTypes.string,
    getOptions: PropTypes.func,
    autoload: PropTypes.bool,
    optionRenderer: PropTypes.func,
    header: PropTypes.arrayOf(PropTypes.string),
    noResultsText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    formProps: PropTypes.shape({}),
    handleClassBookingModalDialog: PropTypes.func,
    handleTrainingBookingModalDialog: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    handleClassBookingModalDialog: bindActionCreators(openClassBookingModalDialog, dispatch),
    handleTrainingBookingModalDialog: bindActionCreators(openTrainingBookingModalDialog, dispatch),
});

export default connect(null, mapDispatchToProps)(renderSearchAddControl);
