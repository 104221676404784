import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as Types from './types';

const INITIAL_STATE = fromJS({
    unreadCount: 0,
    lastSeenCount: 0,
    count: 0,
    items: [],
    itemsAreLoading: false,
    nextItemsAreLoading: false,
    nextUrl: null
});

const alertReducer = handleActions({
    [Types.SET_DAILY_NOTIFICATIONS_UNREAD_COUNT]: (state, action) => {
        return state.setIn(['unreadCount'], action.payload);
    },
    [Types.SET_DAILY_NOTIFICATIONS_ITEMS]: (state, action) => {
        const {
            results,
            last_seen_activity_stream_event,
            count,
            next
        } = action.payload;

        const unreadCount = state.get('unreadCount');
        const nextUrl = next === null ? next : next.slice(4);

        results.forEach((item, i) => (item.isUnread = i < unreadCount)); // eslint-disable-line

        return state.setIn(['items'], fromJS(results))
            .setIn(['lastSeenCount'], last_seen_activity_stream_event)
            .setIn(['nextUrl'], nextUrl)
            .setIn(['count'], count);
    },
    [Types.SET_DAILY_NOTIFICATIONS_AS_READED]: (state) => {
        return state.updateIn(['items'],
            items => items.map((item) => {
                return item.setIn(['isUnread'], false);
            })
        );
    },
    [Types.SET_NEXT_DAILY_NOTIFICATIONS_UNREAD_COUNT]: (state, action) => {
        const { results, count, next, previous } = action.payload;
        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);

        return state.update((state) => {
            return state.setIn(['items'], state.get('items').concat(fromJS(results)))
                .setIn(['count'], count)
                .setIn(['pageSize'], results.length)
                .setIn(['nextUrl'], nextUrl)
                .setIn(['previousUrl'], previousUrl);
        });
    },
    [Types.START_ITEMS_LOADING]: (state) => {
        return state.setIn(['itemsAreLoading'], true);
    },
    [Types.FINISH_ITEMS_LOADING]: (state) => {
        return state.setIn(['itemsAreLoading'], false);
    },
    [Types.START_NEXT_ITEMS_LOADING]: (state) => {
        return state.setIn(['nextItemsAreLoading'], true);
    },
    [Types.FINISH_NEXT_ITEMS_LOADING]: (state) => {
        return state.setIn(['nextItemsAreLoading'], false);
    },
}, INITIAL_STATE);

export default alertReducer;
