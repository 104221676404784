export const getTransactionDisplayStatus = (transactionHistoryRecord) => {
    const status = transactionHistoryRecord.status_detail;
    const modelType = transactionHistoryRecord.model_type;
    let displayStatus = 'Processing';
    if (status === 'Error') {
        displayStatus = 'Error';
    } else if (modelType === 'striperefund') {
        displayStatus = 'Charge Reversed';
    } else if (modelType === 'packagerefund' || modelType === 'trainingrefund') {
        displayStatus = 'Credit Refunded';
    } else if (modelType === 'packagepayment' || modelType === 'trainingpayment') {
        displayStatus = 'Credit Used';
    } else if (status === 'Authorized') {
        displayStatus = 'Authorized';
    } else if (status === 'Paid') {
        displayStatus = 'Paid';
    } 

    return displayStatus;
};
