export const MARK_CUSTOMER_NOTES_INCIDENTS_LOADED = 'MARK_CUSTOMER_NOTES_INCIDENTS_LOADED';
export const UNMARK_CUSTOMER_NOTES_INCIDENTS_LOADED = 'UNMARK_CUSTOMER_NOTES_INCIDENTS_LOADED';
export const SET_CURRENT_CUSTOMER_NOTES_INCIDENTS = 'SET_CURRENT_CUSTOMER_NOTES_INCIDENTS';
export const ADD_CURRENT_CUSTOMER_NOTES_INCIDENTS_ITEM = 'ADD_CURRENT_CUSTOMER_NOTES_INCIDENTS_ITEM';

export const MARK_DOG_NOTES_INCIDENTS_LOADED = 'MARK_DOG_NOTES_INCIDENTS_LOADED';
export const UNMARK_DOG_NOTES_INCIDENTS_LOADED = 'UNMARK_DOG_NOTES_INCIDENTS_LOADED';
export const SET_CURRENT_DOG_NOTES_INCIDENTS = 'SET_CURRENT_DOG_NOTES_INCIDENTS';
export const ADD_CURRENT_DOG_NOTES_INCIDENTS_ITEM = 'ADD_CURRENT_DOG_NOTES_INCIDENTS_ITEM';

