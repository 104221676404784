/* eslint-disable camelcase */

import PropTypes from 'prop-types';
import React from 'react';
import { formatDateToSlashes } from '../../helpers/date';
import { ReactComponent as IconDots } from '../../assets/icons/icon-dots.svg';
import Dropdown from '../common/Dropdown';

const EMPTY_VALUE = 'n/a';

const propTypes = {
    data: PropTypes.shape({}),
    dropdownItems: PropTypes.arrayOf(PropTypes.shape({}))
};

const CampaignCard = ({ data, dropdownItems }) => {
    const { name,
        is_sent,
        recipient_count,
        created_at,
        created_by_detail: { full_name },
        content } = data;

    const getImageURL = () => {
        let url = null;
        if (!content.length) {
            return url;
        }

        const block = content[0];
        if (!block) {
            return url;
        }

        Object.keys(block).some((key) => {
            if (key.startsWith('photo')) {
                url = block[key].photo_processed;
                return true;
            }
            return false;
        });

        return url;
    };

    const renderImage = () => {
        const url = getImageURL();

        if (url) {
            return (
                <div
                    className='campaign-card__image'
                    style={{ background: `url(${url}) center center / cover no-repeat` }}/>
            );
        }

        return <div className='campaign-card__image'>No Photo</div>;
    };

    return (
        <div className='campaign-card'>
            <Dropdown
                classNames='dropdown_employee-card'
                icon={IconDots}
                iconClass='icon_chevron_down'
                data={data}
                items={data.is_sent ? [dropdownItems[1]] : dropdownItems}/>
            {renderImage()}
            <div className='campaign-card__title'>
                {name || EMPTY_VALUE}
            </div>
            <div className='campaign-card__content'>
                <div className='campaign-card__item'>
                    <div className='campaign-card__item-header'>
                        Status
                    </div>
                    <div className='campaign-card__item-content'>
                        {is_sent ? 'Sent' : 'Not Sent'}
                    </div>
                </div>
                <div className='campaign-card__item'>
                    <div className='campaign-card__item-header'>
                        Recipients
                    </div>
                    <div className='campaign-card__item-content'>
                        {recipient_count || EMPTY_VALUE}
                    </div>
                </div>
                <div className='campaign-card__item'>
                    <div className='campaign-card__item-header'>
                        Created Date
                    </div>
                    <div className='campaign-card__item-content'>
                        {formatDateToSlashes(created_at)}
                    </div>
                </div>
                <div className='campaign-card__item'>
                    <div className='campaign-card__item-header'>
                        Created By
                    </div>
                    <div className='campaign-card__item-content'>
                        {full_name || EMPTY_VALUE}
                    </div>
                </div>
            </div>
        </div>
    );
};

CampaignCard.propTypes = propTypes;
export default CampaignCard;
