import React from 'react';
import SidebarIconLink from './SidebarIconLink';
import SidebarTextLink from './SidebarTextLink';
import { isTrainer, isEmployee } from '../../../helpers/userRoles';
import { links, trainerLinks, employeeLinks } from './navLinks';

const getSidebarLinks = () => {
    if (isTrainer()) {
        return trainerLinks;
    }
    if (isEmployee()) {
        return employeeLinks;
    }
    return links;
};
class Sidebar extends React.Component {
    state = {
        activeLink: 0,
        isHoverLeft: false,
        isHoverRight: false
    };

    setActiveLinkLeft(activeLink) {
        this.setState({ activeLink, isHoverLeft: true });
    }

    setActiveLinkRight(activeLink) {
        this.setState({ activeLink, isHoverRight: true });
    }

    activeClassName = 'sidebar-active';

    isActiveLinkClass(currentLink) {
        return (currentLink === this.state.activeLink &&
        (this.state.isHoverLeft === true || this.state.isHoverRight === true))
            ? this.activeClassName : '';
    }

    unsetLeft() {
        this.setState({ isHoverLeft: false });
    }

    unsetRight() {
        this.setState({ isHoverRight: false });
    }

    render() {
        const sidebarLinks = getSidebarLinks();
        return (
            <nav className='sidebar'>
                <div className='sidebar__left'>
                    {sidebarLinks.map((item, index) =>
                            (
                                <SidebarIconLink
                                    key={`icon-${index}`}
                                    onMouseEnterFunction={this.setActiveLinkLeft.bind(this, index + 1)}
                                    onMouseLeaveFunction={this.unsetLeft.bind(this)}
                                    className={this.isActiveLinkClass(index + 1)}
                                    activeClassName={this.activeClassName}
                                    item={item}/>
                            ) 
                        )
                    }
                </div>
                <div className='sidebar__right'>
                    {sidebarLinks.map((item, index) =>
                        (
                            <SidebarTextLink
                                key={`icon-${index}`}
                                onMouseEnterFunction={this.setActiveLinkLeft.bind(this, index + 1)}
                                onMouseLeaveFunction={this.unsetLeft.bind(this)}
                                className={this.isActiveLinkClass(index + 1)}
                                activeClassName={this.activeClassName}
                                item={item}/>
                            )
                        )
                    }
                </div>
            </nav>
        );
    }
}

export default Sidebar;
