import { enqueueAlert } from './actions';

const AlertMiddleware = () => next => action => {
    next(action);
    
    if (action.meta && action.meta.alert) {
        next(enqueueAlert(action.meta.alert));
    }
};

export default AlertMiddleware;
