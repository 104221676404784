import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import AvatarUploader from '../../../common/AvatarUploader/';
import ProfileNav from './ProfileNav/';

const ProfileContainer = ({ user: { photo, id, full_name: fullName }, children }) => {
    const renderHeader = () => {
        return (
            <div className='profile__header'>
                <div className='profile__head'>
                    <div className='profile__avatar'>
                        <AvatarUploader
                            photo={photo}
                            target='me'
                            customerId={id} />
                    </div>
                    <div className='profile__name'>{fullName}</div>
                </div>
                <ProfileNav />
            </div>
        );
    };

    return (
        <div className='inner-page'>
            <div className='inner-page__container'>
                <div className='profile'>
                    {renderHeader()}
                    {!!fullName && children /* if user is loaded */}
                </div>
            </div>
        </div>
    );
};

ProfileContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    user: PropTypes.shape({}),
};

const mapStateToProps = state => {
    return {
        user: state.getIn(['user']).toJS(),
    };
};

export default connect(mapStateToProps, null)(ProfileContainer);
