import * as axios from 'axios';
import * as ScheduleActions from '../actions/customer/scheduleActions';

export const getSchedule = (query = '') => {
    return dispatch => {
        dispatch(ScheduleActions.startLoadingSchedule());
        const url = `/v1/customers/me/schedule/${query ? '?' + query : ''}`;
        return axios
            .get(url)
            .then(response => {
                console.log(response.data);
                dispatch(ScheduleActions.setSchedule(response.data));
                dispatch(ScheduleActions.finishLoadingSchedule());
            })
            .catch(error => {
                console.log(error);
                console.log(error.response);
                dispatch(ScheduleActions.finishLoadingSchedule());
            });
    };
};

export const getNextSchedule = () => {
    return (dispatch, getState) => {
        dispatch(ScheduleActions.startLoadingSchedule());
        const nextUrl = getState().getIn(['schedule', 'nextUrl']);
        return axios.get(nextUrl)
            .then((response) => {
                console.log(response.data);
                dispatch(ScheduleActions.setNextSchedule(response.data));
                dispatch(ScheduleActions.finishLoadingSchedule());
            })
            .catch((error) => {
                console.log(error);
                console.log(error.response);
                dispatch(ScheduleActions.finishLoadingSchedule());
            });
    };
};

export const changeScheduleAddress = (data) => {
    return async dispatch => {
        const request = new FormData();
        Object.keys(data).map((item) => {
            return request.append(item, data[item]);
        });
        const url = `/v1/customers/me/upcoming-classes/${data.productId}/`;
        const classes = await axios
            .patch(url, request)
            .then(response => {
                console.log(response.data);
                return response.data;
            })
            .catch(error => {
                console.log(error);
                console.log(error.response);
            });
        return classes;
    };
};
