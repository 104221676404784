/* eslint-disable camelcase */
import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import { getOnlyUniqueItems } from '../helpers/array';
import { formatTo12HourFormat, normalizeTimezoneDateToDateFormat } from '../helpers/date';
import * as TrainingsTypes from '../types/dashboard/trainingsTypes';

const INITIAL_STATE = fromJS({
    trainingsItems: [],
    trainingsTrainersList: [],
    count: 0,
    totalCount: 0,
    pageSize: 0,
    pages: 0,
    nextUrl: null,
    previousUrl: null,
    isTrainingsLoading: false
});

export const TrainingsDataNormalizer = data => {
    return data.map(item => {
        const { start, end, program_name } = item;
        item.start = normalizeTimezoneDateToDateFormat(start);
        item.formattedStart = formatTo12HourFormat(start);
        item.formattedEnd = formatTo12HourFormat(end);
        item.end = normalizeTimezoneDateToDateFormat(end);
        item.title = program_name;
        return item;
    });
};

const trainingsListReducer = handleActions({
    [TrainingsTypes.SET_TRAININGS_LIST]: (state, action) => {
        const { results, count_total, count, next, previous } = action.payload;
        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);
        const pagesCount = Math.ceil(count / results.length);
        // eslint-disable-next-line camelcase
        const totalCount = count_total;
        const trainersList = results.map(result => {
            return result.trainer;
        });

        return state.update((state) => {
            return state.setIn(['trainingsItems'], fromJS(TrainingsDataNormalizer(results)))
                        .setIn(['trainingsTrainersList'], fromJS(getOnlyUniqueItems(trainersList)))
                        .setIn(['count'], count)
                        .setIn(['totalCount'], totalCount)
                        .setIn(['pageSize'], results.length)
                        .setIn(['pages'], pagesCount)
                        .setIn(['nextUrl'], nextUrl)
                        .setIn(['previousUrl'], previousUrl);
        });
    },
    [TrainingsTypes.SET_NEXT_TRAININGS_LIST]: (state, action) => {
        const { results, count_total, count, next, previous } = action.payload;
        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);
        const pagesCount = Math.ceil(count / results.length);
        // eslint-disable-next-line camelcase
        const totalCount = count_total;
        const trainersList = results.map(result => {
            return result.trainer;
        });

        return state.update((state) => {
            return state.setIn(['trainingsItems'],
                state.get('trainingsItems').concat(fromJS(TrainingsDataNormalizer(results))))
                        .setIn(['trainingsTrainersList'],
                            state.get('trainingsTrainersList').concat(fromJS(getOnlyUniqueItems(trainersList))))
                        .setIn(['count'], count)
                        .setIn(['totalCount'], totalCount)
                        .setIn(['pageSize'], results.length)
                        .setIn(['pages'], pagesCount)
                        .setIn(['nextUrl'], nextUrl)
                        .setIn(['previousUrl'], previousUrl);
        });
    },
    [TrainingsTypes.START_TRAININGS_LIST_LOADING]: (state, action) => (
        state.setIn(['isTrainingsLoading'], true)
    ),
    [TrainingsTypes.FINISH_TRAININGS_LIST_LOADING]: (state, action) => (
        state.setIn(['isTrainingsLoading'], false)
    )
}, INITIAL_STATE);

export default trainingsListReducer;
