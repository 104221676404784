import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ChangePasswordForm from './ChangePasswordForm';
import { changeCustomersPassword, resetCustomerPassword } from '../../../../../actions/customer/CustomerActions';
import Button from '../../../../common/Button';

class ChangePassword extends PureComponent {
    state = {
        isEmailSent: false
    }

    renderContent() {
        const { email, changeCustomersPassword, hasUsablePassword, resetCustomerPassword } = this.props;
        const { isEmailSent } = this.state;

        if (hasUsablePassword) {
            return <ChangePasswordForm onSubmit={values => changeCustomersPassword(values)} />;
        }
        if (isEmailSent) {
            return (
                <div className='change-password__sent'>
                    <div className='change-password__sent-title'>Email sent!</div>
                    <div className='change-password__sent-subtitle'>Check your email and click on the link to reset your password.</div>
                </div>
            );
        }
        return (
            <Button
                text='Create new password'
                modifierClassName='change-password__create-button'
                onClickHandler={() => {
                    resetCustomerPassword({ email }).then(() => {
                        this.setState({ isEmailSent: true });
                    });
                }} />
        );
    }

    render() {
        const { email } = this.props;
        return (
            <div className='profile__block change-password'>
                <div className='profile__headline'>
                    <div className='profile__title'>change password</div>
                </div>
                <div className='change-password__content'>
                    <div className='change-password__email'>
                        <span className='change-password__email-title'>Current Email: </span>{email}
                    </div>
                    {this.renderContent()}
                </div>
            </div>
        );
    }
}

ChangePassword.propTypes = {
    email: PropTypes.string,
    changeCustomersPassword: PropTypes.func,
    resetCustomerPassword: PropTypes.func,
    hasUsablePassword: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        email: state.getIn(['currentCustomer', 'instance', 'email']),
        hasUsablePassword: state.getIn(['currentCustomer', 'instance', 'has_usable_password']),
    };
};

const mapDispatchToProps = {
    changeCustomersPassword,
    resetCustomerPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
