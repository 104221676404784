import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { ReactComponent as IconArrow } from '../../../assets/icons/icon-chevron-long-right.svg';

const ViewLink = ({ text, link, targetBlank = null, className = '' }) => {
    return (
        <Link to={link} target={targetBlank && '_blank'} className={'view-link ' + className}>
            {text}
            <IconArrow className='view-link__arrow'/>
        </Link>
    );
};

ViewLink.propTypes = {
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    className: PropTypes.string,
    targetBlank: PropTypes.bool,
};

export default ViewLink;
