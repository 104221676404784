import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import { customReportTargetValues } from '../components/containers/Dashboard/Reports/CustomReportsPage/ReportsFilter/constants';
import { dataStates } from '../helpers/state';
import * as CustomReportsTypes from '../types/dashboard/customReportsTypes';

const INITIAL_STATE = fromJS({
    filter: fromJS({
        type: 'operational',
        target: null,
        report: null,
        date: {},
    }),
    dataState: dataStates.notAsked,
    items: undefined,
});

const customReportsReducer = handleActions({
    [CustomReportsTypes.SET_REPORT_DATA]: (state, action) => {
        return state.set('items', fromJS(action.payload));
    },
    [CustomReportsTypes.SET_REPORT_DATA_LOADING]: (state) => {
        return state.set('dataState', dataStates.loading)
                    .set('items', undefined);
    },
    [CustomReportsTypes.SET_REPORT_DATA_LOADED]: (state) => {
        return state.set('dataState', dataStates.loaded);
    },
    [CustomReportsTypes.SET_REPORT_DATA_FAILED]: (state) => {
        return state.setIn('dataState', dataStates.failed);
    },
    [CustomReportsTypes.SET_FILTER_TYPE]: (state, action) => {
        return state.setIn(['filter', 'type'], action.payload);
    },
    [CustomReportsTypes.SET_FILTER_REPORT_AND_TARGET]: (state, action) => {
        const reportTargetValue = customReportTargetValues[action.payload];
        return state.setIn(['filter', 'report'], action.payload)
                    .setIn(['filter', 'target'], reportTargetValue);
    },
    [CustomReportsTypes.SET_REPORT_DATE]: (state, action) => {
        return state.setIn(['filter', 'date'], action.payload);
    },
}, INITIAL_STATE);

export default customReportsReducer;
