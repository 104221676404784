export const capitalizeEachWord = (str) => {
    return str.replace(/\b\w/g, l => l.toUpperCase());
};

export const capitalizeEachWordWithParser = (str) => {
    let newStr = str.replace(/\b\w/g, l => l.toUpperCase());
    if (newStr === 'By Discounts') {
        newStr = 'Discounts';
    }
    return newStr;
};

export const getTypeName = (type) => {
    return type === 'trainingprogram' ? 'Training Program' : 'Package';
};

export const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

// Insert `substr` in `str` starting at `index'.
export const insertSubstr = (str, substr, index) => {
    return str.substr(0, index) + substr + str.substr(index);
};

export const discountTextTransformer = (disc) => {
    if (disc === 0) {
        return 'No Discount - 0%'
    }
    if (disc === 10) {
        return 'Rescue Discount - 10%'
    }
    if (disc === 25) {
        return 'Manager Discount - 25%'
    }
    if (disc === 50) {
        return 'Manager Discount - 50%'
    }
    if (disc === 100) {
        return 'Employee Discount - 100%'
    }
    return disc;
};