import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as IllustrationDogHappy } from '../../../assets/icons/illustration-dog-happy.svg';
import { ReactComponent as IllustrationDogThinking } from '../../../assets/icons/illustration-dog-thinking.svg';

const NoData = ({ title, subtitle, iconType, borders = true, className, action, titleHtml }) => {
    const noDataClassName = classNames({
        'no-data': true,
        'no-data_no-borders': !borders,
        [className]: className
    });
    const icons = {
        happy: IllustrationDogHappy,
        thinking: IllustrationDogThinking,
    };
    const Icon = icons[iconType];
    return (
        <div className={noDataClassName}>
            {Icon &&
                <IllustrationDogHappy className='no-data__icon'/>
            }
            <div className='no-data__headline'>
                {title && <div className='no-data__title'>{title}</div>}
                {titleHtml && <div className='no-data__title' dangerouslySetInnerHTML={{ __html: titleHtml }}/>}
                {subtitle && <div className='no-data__subtitle'>{subtitle}</div>}
            </div>
            {action}
        </div>
    );
};

NoData.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    iconType: PropTypes.string,
    className: PropTypes.string,
    borders: PropTypes.bool,
    action: PropTypes.node,
    titleHtml: PropTypes.string
};

export default NoData;
