import React from 'react';
import { Field } from 'redux-form/immutable';
import BaseField from '../';
import EditableContent from '../../../EditableContent';

const varClassName = 'template-var';
let varPatternString = null;
let htmlVarReg = null;
let plainVarReg = null;

const getVarPatternString = (variables) => {
    return variables.map((item) => {
        return item.get('name');
    }).join('|');
};

const getHTMLVarPattern = (variables) => {
    if (!varPatternString) {
        varPatternString = getVarPatternString(variables);
    }
    return `({(${varPatternString})})(?!</span>)`;
};

const getPlainVarPattern = (variables) => {
    if (!varPatternString) {
        varPatternString = getVarPatternString(variables);
    }
    return `{(${varPatternString})}`;
};

const highlightVar = (matchTotal, matchGroup1) => {
    return `<span class="${varClassName}">${matchGroup1}</span>`;
};

const getInsertExamplesFn = (variables) => {
    return (matchTotal, matchGroup1) => {
        const variable = variables.find((v) => v.get('name') === matchGroup1);

        if (variable) {
            return variable.get('example');
        }

        return matchGroup1;
    };
};

class TemplateTextArea extends BaseField {
    fieldClassName = 'form-label-field template-body';

    componentDidMount() {
        const { formProps: { variables } } = this.props;

        if (!variables) {
            return;
        }

        if (!htmlVarReg) {
            htmlVarReg = new RegExp(getHTMLVarPattern(variables), 'gi');
        }

        if (!plainVarReg) {
            plainVarReg = new RegExp(getPlainVarPattern(variables), 'gi');
        }
    }

    getRef = (ref) => {
        this.refContent = ref;
    };

    handleChange = (e) => {
        const { input: { onChange } } = this.props;
        if (this.refUnderlay) {
            let content = this.refContent.innerHTML;
            if (htmlVarReg && htmlVarReg.test(content)) {
                content = content.replace(htmlVarReg, highlightVar);
            }
            this.refUnderlay.innerHTML = content;
        }
        e.target = {
            value: this.removeMultipleNewlines(this.refContent.innerText)
        };
        onChange(e);
    };

    removeMultipleNewlines = (text) => text.replace(/\n\n+/g, '\n');

    renderUnderlay = () => {
        // This is a `shadow` text area which is actually visible to the user
        // and where all substitutions and coloring takes place.
        // Its inner html is an exact copy of the next div, except some portions surrounded by
        // coloring spans,
        return (
            <div
                className='template-text-area text-area-underlay'
                ref={(ref) => {
                    this.refUnderlay = ref;
                }}/>
        );
    };

    renderEditableContent = () => {
        // This is our `frontend` text area where the actual user typing happens, but the text is
        // transparent and only the cursor is visible.
        const { input: { onFocus, onBlur }, formProps: { isPreview }, formProps: { variables } } = this.props;
        let { input: { value } } = this.props;

        if (isPreview && plainVarReg && plainVarReg.test(value)) {
            value = value.replace(plainVarReg, getInsertExamplesFn(variables));
        }

        return (
            <EditableContent
                isPreview={isPreview}
                getRef={this.getRef}
                className='template-text-area'
                text={value}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={this.handleChange}/>
        );
    };

    renderPreview = () => {
        return (
            <div className={this.getCombinedFieldClassName()}>
                <div className='form-label-field__inner preview'>
                    {this.renderEditableContent()}
                </div>
            </div>
        );
    };

    renderEditor = () => {
        const { label } = this.props;
        return (
            <div className={this.getCombinedFieldClassName()}>
                <div className='form-label-field__inner' onClick={this.handleBrowseClick}>
                    {this.renderUnderlay()}
                    {this.renderEditableContent()}
                    <label>
                        {label}
                    </label>
                </div>
                {this.renderError()}
            </div>
        );
    };

    render() {
        const { formProps: { isPreview } } = this.props;
        if (isPreview) {
            return this.renderPreview();
        }
        return this.renderEditor();
    }
}

const TemplateTextAreaField = ({ fieldConfig, formProps }) => {
   return (
       <Field
           {...fieldConfig}
           formProps={formProps}
           component={TemplateTextArea}/>
   );
};

TemplateTextAreaField.propTypes = BaseField.defaultFieldProps;

export default TemplateTextAreaField;
