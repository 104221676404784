import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { processCustomerContactInfo } from '../../../../actions/dashboard/contactInfoActions';
import { setContactInfoModalClosed } from '../../../../actions/miscActions';
import ModalDialog from '../../../common/ModalDialog';
import ContactInfoModalDialogForm from './ContactInfoModalDialogForm';

// eslint-disable-next-line max-len
const ContactInfoModalDialog = ({ isContactInfoModalOpened, handleContactInfoModalClosed, handleSubmit, customersName }) => {
    return (
        <ModalDialog
            form={
                <ContactInfoModalDialogForm
                    onSubmit={data =>
                        handleSubmit(
                            // customerId
                            data.get('id'),
                            // emailData
                            fromJS({
                                initialData: data.get('initialEmail'),
                                email: data.get('email')
                            }),
                            // primaryPhoneData
                            fromJS({
                                id: data.get('primaryPhoneId'),
                                initialData: data.get('initialPrimaryPhone'),
                                data: [
                                    {
                                        phone_number: data.get('primary_phone')
                                    }
                                ]
                            }),
                            // secondaryPhoneData
                            fromJS({
                                id: data.get('secondaryPhoneId'),
                                initialData: data.get('initialSecondaryPhone'),
                                data: [
                                    {
                                        phone_number: data.get('secondary_phone')
                                    }
                                ]
                            }),
                            // addressData
                            fromJS({
                                id: data.get('addressId'),
                                initialData: [
                                    { address1: data.get('initialAddress1') },
                                    { address2: data.get('initialAddress2') },
                                    { city: data.get('initialCity') },
                                    { state: data.get('initialState') },
                                    { zip_code: data.get('initialZip_code') },
                                    { location_notes: data.get('initialLocation_notes') }
                                ],
                                data: [
                                    { name: 'Home' },
                                    { address1: data.get('address1') },
                                    { address2: data.get('address2') },
                                    { city: data.get('city') },
                                    { state: (data.get('state') && data.get('state').value ? data.get('state').value : data.get('state')) },
                                    { zip_code: data.get('zip_code') },
                                    { location_notes: data.get('location_notes') }
                                ]
                            })
                        )
                    }/>
            }
            title={`Edit ${customersName}'s Contact Info`}
            isModalOpened={isContactInfoModalOpened}
            handleModalClosed={handleContactInfoModalClosed}/>
    );
};

ContactInfoModalDialog.propTypes = {
    isContactInfoModalOpened: PropTypes.bool,
    handleContactInfoModalClosed: PropTypes.func,
    handleSubmit: PropTypes.func,
    customersName: PropTypes.string
};

const mapStateToProps = state => {
    return {
        customersName: state.getIn(['currentCustomer', 'instance', 'full_name']),
        isContactInfoModalOpened: state.getIn(['misc', 'modals', 'contactInfo'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleContactInfoModalClosed: bindActionCreators(setContactInfoModalClosed, dispatch),
    handleSubmit: bindActionCreators(processCustomerContactInfo, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfoModalDialog);
