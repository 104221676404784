const validate = values => {
    const errors = {};

    if (!values.get('dogs')) {
        errors.dogs = 'Select at least one dog';
    }
    return errors;
};

export default validate;
