import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import renderInputControl from '../../../../common/Form/InputControl';
import validate from './validate';

const ForgotPasswordForm = ({ handleSubmit, submitting, invalid }) => {
    const SubmitButtonClassNames = classNames({
        'forgot-password__submit-button': true,
        'forgot-password__submit-button_disabled': (invalid || submitting)
    });
    return (
        <form onSubmit={handleSubmit} className='forgot-password__form'>
            <Field name='email' type='text' component={renderInputControl} label={'Email'}/>
            <button className={SubmitButtonClassNames} type='submit' disabled={submitting}>
                Reset password
            </button>
        </form>
    );
};

ForgotPasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool
};

export default reduxForm({
    form: 'forgotPasswordForm',
    validate
})(ForgotPasswordForm);
