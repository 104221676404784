import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { processImmunizationExpirationDatesInfo } from '../../../../actions/dashboard/immunizationExpirationDatesActions';
import { setImmunizationExpirationDatesModalClosed } from '../../../../actions/miscActions';
import ModalDialog from '../../../common/ModalDialog';
import ImmunizationExpirationDatesModalDialogForm from './ImmunizationExpirationDatesModalDialogForm';


// eslint-disable-next-line max-len
class ImmunizationExpirationDatesModalDialog extends React.PureComponent {
    static propTypes = {
        isImmunizationExpirationDatesModalOpened: PropTypes.bool,
        handleImmunizationExpirationDatesModalClosed: PropTypes.func,
        handleSubmit: PropTypes.func
    }

    render() {
        const { isImmunizationExpirationDatesModalOpened,
            handleImmunizationExpirationDatesModalClosed, handleSubmit } = this.props;
            
        return (
            <ModalDialog
                form={
                    <ImmunizationExpirationDatesModalDialogForm
                        ref={(form) => { this.form = form; }}
                        onSubmit={data => {
                            handleSubmit(
                                // dog id
                                data.get('dogId'),
                                // new values
                                data.delete('originalData').delete('dogId').delete('vaccinations'),
                                // originalData
                                data.get('originalData'),
                                // vaccinations
                                data.get('vaccinations')
                            );
                        }}
                        isFormOpened={(isImmunizationExpirationDatesModalOpened)}/>
                }
                title={'Edit Vaccination Expiration Dates'}
                isModalOpened={(isImmunizationExpirationDatesModalOpened)}
                handleModalClosed={handleImmunizationExpirationDatesModalClosed}
                overflowShown/>
        );
    }
}

const mapStateToProps = state => {
    return {
        isImmunizationExpirationDatesModalOpened: state.getIn(['misc', 'modals', 'immunizationExpirationDates']),
    };
};

const mapDispatchToProps = (dispatch) => ({
    // eslint-disable-next-line max-len
    handleImmunizationExpirationDatesModalClosed: bindActionCreators(setImmunizationExpirationDatesModalClosed, dispatch),
    handleSubmit: bindActionCreators(processImmunizationExpirationDatesInfo, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ImmunizationExpirationDatesModalDialog);
