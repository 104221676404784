import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateDogSurvey } from '../../../../actions/dashboard/dogActions';
import { setErrorSubmittingDogEvaluation } from '../../../../actions/dashboard/employeeActions';
import { setDogEvaluationModalClosed } from '../../../../actions/miscActions';
import ModalDialog from '../../../common/ModalDialog';
import DogEvaluationModalDialogForm from './DogEvaluationModalDialogForm';

const DogEvaluationModalDialog = ({
    isModalOpened,
    handleModalClosed,
    dogName,
    dogId,
    handleSubmit,
    setErrorSubmittingDogEvaluation,
}) => {
    return (
        <ModalDialog
            form={
                <DogEvaluationModalDialogForm
                    onSubmit={(data) => {
                        setErrorSubmittingDogEvaluation(false);
                        return handleSubmit(dogId, fromJS(data));
                    }}/>
            }
            title={`Edit ${dogName}'s evaluation questions`}
            isModalOpened={isModalOpened}
            handleModalClosed={handleModalClosed}
            className='modal-dialog__ios-scroll-fix'/>
    );
};

DogEvaluationModalDialog.propTypes = {
    isModalOpened: PropTypes.bool,
    handleModalClosed: PropTypes.func,
    handleSubmit: PropTypes.func,
    dogName: PropTypes.string,
    dogId: PropTypes.number,
    setErrorSubmittingDogEvaluation: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        dogName: state.getIn(['currentDog', 'instance', 'name']),
        dogId: state.getIn(['currentDog', 'instance', 'id']),
        isModalOpened: state.getIn(['misc', 'modals', 'dogEvaluation'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleModalClosed: bindActionCreators(setDogEvaluationModalClosed, dispatch),
    handleSubmit: bindActionCreators(updateDogSurvey, dispatch),
    setErrorSubmittingDogEvaluation: bindActionCreators(setErrorSubmittingDogEvaluation, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DogEvaluationModalDialog);
