import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import DailyNotesPage from '../DailyNotesPage';
import * as dashboardPageActions from '../../../../actions/dashboard/dashboardPageActions';

class DailyNotesContainer extends React.PureComponent {
    static propTypes = {
        dailyNotesData: PropTypes.shape({}),
        currentNotesDate: PropTypes.string,
        getDailyNotesData: PropTypes.func,
        getMoreDailyNotesData: PropTypes.func,
        handleDailyNotesSubmit: PropTypes.func,
        setCurrentNotesDate: PropTypes.func,
        getDailyNotes: PropTypes.func,
    };

    handleNotesDateChange = newDate => {
        const { setCurrentNotesDate, getDailyNotes } = this.props;
        setCurrentNotesDate(newDate);
        getDailyNotes({ date: newDate });

    };

    componentDidMount() {
        this.props.getDailyNotesData();
    }

    render() {
        const {
            dailyNotesData,
            handleDailyNotesSubmit,
            getMoreDailyNotesData,
            currentNotesDate,
        } = this.props;

        return (
            <div className='dashboard__main'>
                <div className='dashboard-page'>
                    <DailyNotesPage
                        data={dailyNotesData}
                        onSubmit={handleDailyNotesSubmit}
                        onDateChange={this.handleNotesDateChange}
                        currentDate={currentNotesDate}
                        onLoadMore={getMoreDailyNotesData}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        dailyNotesData: state.getIn(['dashboardPage', 'dailyNotes']),
        currentNotesDate: state.getIn(['dashboardPage', 'currentNotesDate']),
    };
};

const mapDispatchToProps = {
    getDailyNotesData: dashboardPageActions.getDailyNotesData,
    getMoreDailyNotesData: dashboardPageActions.getMoreDailyNotesData,
    handleDailyNotesSubmit: dashboardPageActions.submitDailyNotesData,
    setCurrentNotesDate: dashboardPageActions.setCurrentNotesDate,
    getDailyNotes: dashboardPageActions.getDailyNotes,
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyNotesContainer);
