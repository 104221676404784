import * as axios from 'axios';
import * as SearchBarActions from '../actions/dashboard/searchBarActions';
import { getUserNameSpace } from '../helpers/userRoles';

export const globalSearch = (searchString) => {
    return (dispatch) => {
        dispatch(SearchBarActions.startSearchLoading());
        const url = `/v1/${getUserNameSpace('search')}/search/?q=${searchString}&type=customer`;
        return axios.get(url)
                    .then((response) => {
                        console.log(response.data);
                        dispatch(SearchBarActions.setSearchResults({
                            searchData: response.data,
                            searchString,
                        }));
                        dispatch(SearchBarActions.finishSearchLoading());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(SearchBarActions.finishSearchLoading());
                    });
    };
};

export const getNextSearchData = () => {
    return (dispatch, getState) => {
        const nextUrl = getState().getIn(['search', 'nextUrl']);
        dispatch(SearchBarActions.startSearchLoading());
        return axios.get(nextUrl)
                    .then((response) => {
                        console.log(response.data);
                        dispatch(SearchBarActions.setNextSearchData(response.data));
                        dispatch(SearchBarActions.finishSearchLoading());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                    });
    };
};
