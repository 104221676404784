import PropTypes from 'prop-types';
import React from 'react';
import { Field, FormSection } from 'redux-form/immutable';
import { usStates } from '../../../helpers/options';
import renderSelectControl from '../../common/Form/SelectControl';
import DeleteItem from '../DeleteItem';
import renderInputControl from '../Form/InputControl';
import renderPlacesAutocompleteControl from '../Form/PlacesAutocompleteControl';
import { phoneNumberNormalizer, PHONE_MASK, PHONE_MASK_CHAR } from '../../../helpers/normalize';

// eslint-disable-next-line max-len
const ModalDialogFormBlock = (props) => {
    const {
        entityName, blockName, index, deleteBlockHandler, blockFields, isDeletable = true,
        handleAutocompleteSelectOrEnter, formName, fields,
    } = props;
    return (
        <FormSection className={`modal-dialog__form-block modal-dialog__form-block_${index}`} name={index.toString()}>
            <div className='modal-dialog__form-header'>
                <div className='modal-dialog__form-title'>{blockName} {index + 1}</div>
                {isDeletable &&
                <DeleteItem text={`Delete ${entityName}`} onClickHandler={() => deleteBlockHandler(index)}/>
                }
            </div>
            {blockFields.map((field) => {
                if (field.aligned) {
                    return (
                        <div
                            key={`form__aligner_${field.type}_${index}`}
                            className={`form__aligner form__aligner_${field.type}`}>
                            {field.items.map((item) => {
                                const key = `${item.name}_${index}`;
                                if (item.name === 'state') {
                                    return (<Field
                                        key={key}
                                        name={item.name}
                                        type='text'
                                        component={renderSelectControl}
                                        options={usStates}
                                        className='select-control__is-state'
                                        searchable
                                        returnSelectValue
                                        label='State'/>);
                                }
                                if (item.name === 'address1') {
                                    return (<Field
                                        key={key}
                                        name={item.name}
                                        type='text'
                                        component={renderPlacesAutocompleteControl}
                                        handleSelectOrEnter={address => {
                                            handleAutocompleteSelectOrEnter(address, formName, fields);
                                        }}
                                        label='Location'/>);
                                }

                                if (item.name === 'phone_number') {
                                    return (<Field
                                        key={key}
                                        name={item.name}
                                        type='text'
                                        component={renderInputControl}
                                        mask={PHONE_MASK}
                                        maskChar={PHONE_MASK_CHAR}
                                        isMasked
                                        normalize={phoneNumberNormalizer}
                                        label={item.label}/>);
                                }
                                return (<Field
                                    key={key}
                                    name={item.name}
                                    type='text'
                                    component={renderInputControl}
                                    label={item.label}/>);
                            })}
                        </div>
                    );
                }
                const key = `${field.name}_${index}`;
                if (field.name === 'state') {
                    return (<Field
                        key={key}
                        name={field.name}
                        type='text'
                        component={renderSelectControl}
                        options={usStates}
                        className='select-control__is-state'
                        searchable
                        returnSelectValue
                        label='State'/>);
                }
                if (field.name === 'address1') {
                    return (<Field
                        key={key}
                        name={field.name}
                        type='text'
                        component={renderPlacesAutocompleteControl}
                        handleSelectOrEnter={address => {
                            handleAutocompleteSelectOrEnter(address, formName, fields);
                        }}
                        label='Location'/>);
                }
                if (field.name === 'phone_number') {
                    return (<Field
                        key={key}
                        name={field.name}
                        type='text'
                        component={renderInputControl}
                        label={field.label}
                        mask={PHONE_MASK}
                        maskChar={PHONE_MASK_CHAR}
                        isMasked
                        normalize={phoneNumberNormalizer} />);
                }
                return (<Field
                    key={key}
                    name={field.name}
                    type='text'
                    component={renderInputControl}
                    label={field.label}/>);
            })}
            <div className='modal-dialog__form-divider'/>
        </FormSection>
    );
};

ModalDialogFormBlock.propTypes = {
    entityName: PropTypes.string.isRequired,
    blockName: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    deleteBlockHandler: PropTypes.func,
    blockFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    validateRules: PropTypes.arrayOf(PropTypes.func),
    isDeletable: PropTypes.bool,
    handleAutocompleteSelectOrEnter: PropTypes.func,
    formName: PropTypes.string,
    fields: PropTypes.shape({}),
};

export default ModalDialogFormBlock;
