import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { processCustomersAvailableAddresses } from '../../../../actions/dashboard/availableAddressesActions';
import { setPDLocationsEditModalClosed } from '../../../../actions/miscActions';
import ModalDialog from '../../../common/ModalDialog';
import PickupDropoffEditModalDialogForm from './PickupDropoffEditModalDialogForm';

// eslint-disable-next-line max-len
const PickupDropoffEditModalDialog = (props) => {
    const { isPickupDropoffEditModalOpened, handlePickupDropoffEditModalClosed, handleSubmit, customer } = props;
    const customerFullName = customer.get('full_name');
    return (
        <ModalDialog
            form={
                <PickupDropoffEditModalDialogForm
                    customersName={customerFullName}
                    onSubmit={data => handleSubmit(customer.get('id'), data.toJS())}/>
            }
            title={`Edit ${customerFullName}'s Pickup/Dropoff Locations`}
            isModalOpened={isPickupDropoffEditModalOpened}
            handleModalClosed={handlePickupDropoffEditModalClosed}/>
    );
};

PickupDropoffEditModalDialog.propTypes = {
    isPickupDropoffEditModalOpened: PropTypes.bool,
    handlePickupDropoffEditModalClosed: PropTypes.func,
    handleSubmit: PropTypes.func,
    customer: PropTypes.shape({})
};

const mapStateToProps = state => {
    return {
        customer: state.getIn(['currentCustomer', 'instance']),
        isPickupDropoffEditModalOpened: state.getIn(['misc', 'modals', 'pDLocationsEdit'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    handlePickupDropoffEditModalClosed: bindActionCreators(setPDLocationsEditModalClosed, dispatch),
    handleSubmit: bindActionCreators(processCustomersAvailableAddresses, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PickupDropoffEditModalDialog);
