import PropTypes from 'prop-types';
import React from 'react';
import AjaxLoader from '../../../../common/AjaxLoader/';
import Grid from '../../../../common/Grid';
import NoData from '../../../../common/NoData/';

const CustomerClassesGrid = ({ data, getNext, isLoading, isNextLoading, dogs }) => {
    const count = data.get('count');
    if (isLoading && !isNextLoading) return <AjaxLoader/>;
    if (!count) {
        return (
            <NoData
                titleHtml='<span>Can&#39;t find the class that you&#39;re looking for?  Call us at <a href="tel:3108283647">(310) 828-3647</a>, or email us at <a href="mailto:contact@fitdog.com">contact@fitdog.com</a></span>'
                iconType='happy'
                className='customer-classes__no-data'/>);
    }
    return (
        <Grid
            items={data.get('classOccurrencesItems')}
            className='customer-classes__grid'
            cardType='customerClasses'
            getNext={getNext}
            nextUrl={data.get('nextUrl')}
            isLoading={isNextLoading}
            loader={<AjaxLoader/>}
            cardData={dogs}
            emptyItemsClassNames='customer-class-card customer-class-card_empty'/>
    );
};

CustomerClassesGrid.propTypes = {
    data: PropTypes.shape({}).isRequired,
    dogs: PropTypes.shape({}).isRequired,
    getNext: PropTypes.func,
    isLoading: PropTypes.bool,
    isNextLoading: PropTypes.bool,
};

export default CustomerClassesGrid;
