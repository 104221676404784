import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as Actions from './actions';
import * as ModalActions from './modal';

const propTypes = {
    initState: PropTypes.func,
    resetState: PropTypes.func
};

const Wrapper = MarketingPage => {
    class MarketingPageContainer extends PureComponent {
        componentDidMount() {
            const { initState } = this.props;
            initState();
        }

        componentWillUnmount() {
            const { resetState } = this.props;
            resetState();
        }

        render() {
            return (
                <MarketingPage {...this.props}/>
            );
        }
    }

    const mapStateToProps = state => {
        return {
            model: state.get('marketingPage')
        };
    };

    const mapDispatchToProps = {
        resetState: Actions.resetReducer,
        initState: Actions.initState,
        gotoCampaignEdit: Actions.gotoCampaignEdit,
        fetchList: Actions.fetchList,
        fetchPage: Actions.fetchPage,
        updateModel: Actions.updateModel,
        openCampaignDeleteModal: ModalActions.openCampaignDeleteModal
    };

    MarketingPageContainer.propTypes = propTypes;

    return connect(mapStateToProps, mapDispatchToProps)(MarketingPageContainer);
};

export default Wrapper;
