import React from 'react';
import PropTypes from 'prop-types';

const BookingSummaryItem = ({ data: { id, bookingClassName, dogName, date, price }, onRemove }) => (
    <div className='booking-summary'>
        <div className='booking-summary__header'>
            <p className='booking-summary__title'>{bookingClassName}</p>
            <p className='booking-summary__subline'>for <strong>{dogName}</strong> on <strong>{date}</strong></p>
        </div>
        <div className='booking-summary__footer'>
            <span className='booking-summary__price'>${price}</span>
            <span
                className='booking-summary__button'
                onClick={() => onRemove({ id, dogName })}>
                Remove item
            </span>
        </div>
    </div>
);

BookingSummaryItem.propTypes = {
    data: PropTypes.shape({}),
    onRemove: PropTypes.func
};

export default BookingSummaryItem;
