/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { ReactComponent as IconClose } from '../../assets/icons/icon-remove.svg';
import { ReactComponent as IconCheck } from '../../assets/icons/icon-checkmark.svg';
import Icon from '../common/Icon';

const ALERT_TIMEOUT = 4000;

const icons = {
    'success': IconCheck,
    'error': IconClose,
};

class Alert extends PureComponent {
    static propTypes = {
        alert: PropTypes.shape({}).isRequired,
        onAlertRemove: PropTypes.func.isRequired
    };
    
    state = {
        lifeStage: null
    };
    
    componentWillAppear(done) {
        done();
    }
    
    componentWillEnter(done) {
        const { onAlertRemove, alert } = this.props;
        this.setState({ lifeStage: 'enter' }, () => {
            setTimeout(onAlertRemove, ALERT_TIMEOUT, alert);
            done();
        });
    }
    
    componentWillLeave(done) {
        this.setState({ lifeStage: 'leave' }, () => {
            setTimeout(done, 350);
        });
    }
    
    handleCloseButtonClick = () => {
        const { onAlertRemove, alert } = this.props;
        onAlertRemove(alert);
    };
    
    render() {
        const { lifeStage } = this.state;
        const { alert } = this.props;
        const alertClass = classNames({
            'alert-message': true,
            'alert-enter': lifeStage === 'enter',
            'alert-leave': lifeStage === 'leave',
        });
        return (
            <div className={alertClass}>
                <div className='error-handler_content'>
                    <div className='error-handler__text'>
                        <div className='alert-message__icon-check'>
                            <Icon glyph={icons[alert.get('type')]} className='icon_error-small'/>
                        </div>
                        {alert.get('body')}
                    </div>
                    <div className='error-handler__close' onClick={this.handleCloseButtonClick}>
                        <Icon glyph={IconClose} className='icon_remove-small'/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Alert;
