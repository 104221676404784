/* eslint-disable */

import React from 'react';
import createClass from 'create-react-class';
import Select from './Select';

function reduce(obj, props = {}) {
    return Object.keys(obj)
                 .reduce((props, key) => {
                     const value = obj[key];
                     if (value !== undefined) props[key] = value;
                     return props;
                 }, props);
}

const AsyncCreatable = createClass({
    displayName: 'AsyncCreatableSelect',

    focus() {
        this.select.focus();
    },

    onCreatableBlur(e) {
        const { inputValue, createNewOption } = this.selector;
        createNewOption(inputValue);
        this.props.onBlur(e);
    },

    render() {
        return (
            <Select.Async {...this.props} onBlur={this.onCreatableBlur}>
                {(asyncProps) => (
                    <Select.Creatable {...this.props} ref={elem => { this.selector = elem; }}>
                        {(creatableProps) => (
                            <Select
                                {...reduce(asyncProps, reduce(creatableProps, {}))}
                                onInputChange={(nextValue, prevValue, event) => {
                                    creatableProps.onInputChange(nextValue, prevValue, event);
                                    return asyncProps.onInputChange(nextValue, prevValue, event);
                                }}
                                ref={(ref) => {
                                    this.select = ref;
                                    creatableProps.ref(ref);
                                    asyncProps.ref(ref);
                                }}/>
                        )}
                    </Select.Creatable>
                )}
            </Select.Async>
        );
    }
});

export default AsyncCreatable;
