import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { reduxForm } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as FormFactory from '../../../../components/common/Form/formFactory';
import { openCustomerDogDeleteModalDialog } from '../../../../actions/modal/openActions';

const EditCreateModalDialog = props => {
    const {
        onSubmit,
        onClose,
        handleSubmit,
        isSubmitting,
        data,
        invalid,
        isOpened,
        modalError,
        handleOpenDogDeleteModalDialog,
        dog
    } = props;

    const additioanalSubmitClasses = data && 'submitButtonClasses' in data ? data.submitButtonClasses : '';
    const submitButtonClassNames = classNames(
        {
            'button modal-dialog__button': true,
            'button_disabled': isSubmitting || invalid,
        },
        additioanalSubmitClasses,
    );

    const renderForm = () => {
        return FormFactory.getForm({
            formName: data.form,
            formProps: props,
            initialValues: data.initialValues,
            isOpened,
        });
    };

    const normalizeFormData = formData => {
        const { submitNormalizer, updateNormalizer } = FormFactory.forms[data.form];
        onSubmit({ formData, submitNormalizer, updateNormalizer });
    };

    return (
        <div className='modal-dialog__wrap'>
            <form className='modal-dialog__form' onSubmit={handleSubmit(normalizeFormData)}>
                {data.text &&
                    <p className='modal-dialog__text modal-dialog__text_bold'>
                        {data.text}
                    </p>}

                <div className='modal-dialog__form-content'>
                    {renderForm()}
                </div>
                {modalError !== null && <div className='form__error'>{modalError}</div>}
                <div className='modal-dialog__form-footer modal-dialog__form-footer_column'>
                    <button className={submitButtonClassNames} type='submit' disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting' : data.submitButtonText ? data.submitButtonText : 'Save'}
                    </button>
                    {data.hasCancel && <div className='modal-dialog__cancel' onClick={onClose}>Cancel</div>}
                </div>
            </form>
            <div className='modal-dialog__section'>
                <div className='modal-dialog__section-title'>Delete dog profile</div>
                <div className='modal-dialog__delete' onClick={() => { handleOpenDogDeleteModalDialog(dog); }}>Delete Dog Profile</div>
            </div>
        </div>
    );
};

EditCreateModalDialog.propTypes = {
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleOpenDogDeleteModalDialog: PropTypes.func,
    isSubmitting: PropTypes.bool,
    isOpened: PropTypes.bool,
    invalid: PropTypes.bool,
    data: PropTypes.shape({}),
    modalError: PropTypes.string,
    submitButtonClasses: PropTypes.string,
    dog: PropTypes.shape({}),
};

const form = reduxForm({
    form: 'EditCreateModalSidebarDialogForm',
    enableReinitialize: true
});

const mapStateToProps = state => {
    return {
        dog: state.getIn(['currentDog', 'instance']),
    };
};

const mapDispatchToProps = dispatch => ({
    handleOpenDogDeleteModalDialog: bindActionCreators(openCustomerDogDeleteModalDialog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(EditCreateModalDialog));
