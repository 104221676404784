import { fromJS } from 'immutable';
import moment from 'moment';
import { handleActions } from 'redux-actions';
import { uniqBy } from 'lodash';
import { dateToServerFormat, formatDateToSlashes, timeToServerFormat } from '../helpers/date';
import { classScheduleFilter } from '../helpers/filters';
import { locations, weekDays } from '../helpers/options';
import * as ClassesScheduleTypes from '../types/dashboard/classesScheduleTypes';

const INITIAL_STATE = fromJS({
    initialClasses: [],
    classes: [],
    filteredClasses: [],
    count: null,
    filteredCount: null,
    pageSize: null,
    pages: null,
    nextUrl: null,
    previous: null,
    choices: [],
    filters: [],
    filterObject: {},
    filterTags: [],
    filterQuery: '',
    isDescriptionFull: {},
    isLoading: true,
});

export const classNormalizer = (formData, state) => {
    console.log('NORMALIZER', formData);
    formData = formData.toJS();
    const weekdays =
        formData.class_weekdays === undefined
            ? {}
            : formData.class_weekdays.length === 7
            ? {
                freq: 3,
            }
            : formData.class_weekdays.reduce(
                (resultArray, weekday) => {
                    let _found = false;

                    weekDays.every((weekdaysArrayItem, weekdaysArrayItemIndex) => {
                        if (weekday === weekdaysArrayItem.value) {
                            _found = true;
                            resultArray.byweekday.push(weekdaysArrayItemIndex);
                        }
                        return !_found;
                    });

                    return resultArray;
                },
                {
                    freq: 2,
                    byweekday: [],
                },
            );
    const startEndClass = Object.assign(
        {},
        formData.date_interval !== undefined && formData.date_interval.start !== undefined
            ? moment(formData.date_interval.start, 'MM/DD/YY').isValid()
            ? {
                dtstart: dateToServerFormat(formData.date_interval.start)
            }
            : {}
            : {},
        !formData.date_interval.disableEnd &&
        formData.date_interval !== undefined &&
        formData.date_interval.end !== undefined
            ? moment(formData.date_interval.end, 'MM/DD/YY').isValid()
            ? {
                until: dateToServerFormat(formData.date_interval.end),
            }
            : {}
            : {},
    );
    const linkToWebsite = formData.link_to_website.match(/^[a-zA-Z]+:\/\//)
        ? formData.link_to_website
        : 'http://' + formData.link_to_website;
    const recurrenceRule = Object.assign({}, weekdays, startEndClass);
    const request = {
        name: formData.class_name_autocomplete !== undefined ? formData.class_name_autocomplete.label : undefined,
        photo: formData.class_image !== undefined ? formData.class_image[0] : undefined,
        description: formData.description,
        location_array: formData.location_array ? formData.location_array.map((loc => loc.value)) : null,
        link_to_website: linkToWebsite,
        pickup_start_time:
            formData.pickup_start_time !== undefined
                ? timeToServerFormat(formData.pickup_start_time.value)
                : undefined,
        pickup_end_time:
            formData.pickup_end_time !== undefined
                ? timeToServerFormat(formData.pickup_end_time.value)
                : undefined,
        dropoff_start_time:
            formData.dropoff_start_time !== undefined
                ? timeToServerFormat(formData.dropoff_start_time.value)
                : undefined,
        dropoff_end_time:
            formData.dropoff_end_time !== undefined
                ? timeToServerFormat(formData.dropoff_end_time.value)
                : undefined,
        price: parseFloat(formData.class_price.replace(new RegExp('\\$', 'gi'), '')),
        trainer: formData.trainer_autocomplete !== undefined ? formData.trainer_autocomplete.value : undefined,
        spots: parseInt(formData.class_spots, 10),
        commission: parseFloat(formData.class_commission.replace(new RegExp('\\%', 'gi'), '')),
        access_level: formData.access_level !== undefined ? formData.access_level.value : undefined,
        recurrence_rule: recurrenceRule,
    };

    console.log('R E Q U E S T', request);
    return request;
};

const classParser = classElement => {
    const days = {
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
    };
    let daysNormalized = '';
    if (classElement.recurrence_rule.freq === 3) {
        days.Monday = true;
        days.Tuesday = true;
        days.Wednesday = true;
        days.Thursday = true;
        days.Friday = true;
        days.Sunday = true;
        days.Saturday = true;
        daysNormalized = 'Mon, Tue, Wed, Thu, Fri, Sat, Sun';
    } else if (classElement.recurrence_rule.freq === 2) {
        if (classElement.recurrence_rule.byweekday !== undefined) {
            if (Array.isArray(classElement.recurrence_rule.byweekday)) {
                const weekdaysArray = Object.keys(days);
                classElement.recurrence_rule.byweekday.forEach(classWeekday => {
                    days[weekdaysArray[classWeekday]] = true;
                    if (daysNormalized.length === 0) {
                        daysNormalized = weekdaysArray[classWeekday].substring(0, 3);
                    } else {
                        daysNormalized += ', ' + weekdaysArray[classWeekday].substring(0, 3);
                    }
                });
            }
        }
    }
    const weekdaysArray = Object.keys(days).reduce((resultArray, weekdayKey) => {
        if (days[weekdayKey]) {
            resultArray.push(weekdayKey.toLowerCase());
        }
        return resultArray;
    }, []);
    const classDates = {
        start: classElement.recurrence_rule.dtstart
            ? formatDateToSlashes(classElement.recurrence_rule.dtstart, true)
            : 'None',
        end: classElement.recurrence_rule.until
            ? formatDateToSlashes(classElement.recurrence_rule.until, true)
            : 'disabled',
    };
    let locationName = classElement.location_detail;
    let _found = false;
    locations.every(locationItem => {
        if (locationItem.value === classElement.location) {
            _found = true;
            locationName = locationItem.label;
        }
        return !_found;
    });
    return Object.assign({}, classElement, {
        days,
        daysNormalized,
        weekdaysArray,
        classDates,
        location_detail: locationName,
        featured: classElement.is_featured,
    });
};

const getDescriptionController = (classesList, descriptionList) => {
    const classesListJS = classesList.size ? classesList.toJS() : classesList;
    const descriptionListJS =
        descriptionList === undefined ? {} : descriptionList.size ? descriptionList.toJS() : descriptionList;
    const classesNamesList = classesListJS.reduce((resultObject, classElement) => {
        if (descriptionListJS[classElement.id] !== undefined) {
            resultObject[classElement.id] = descriptionListJS[classElement.id];
        } else {
            resultObject[classElement.id] = false;
        }
        return resultObject;
    }, {});

    return classesNamesList;
};

const getClassesNamesList = classesList => {
    const classesListJS = classesList.size ? classesList.toJS() : classesList;
    const classesNamesList = classesListJS.reduce((resultArray, classElement, classElementIndex) => {
        resultArray.push({
            value: classElement.name,
            label: classElement.name,
        });
        return resultArray;
    }, []);

    return uniqBy(classesNamesList, 'label');
};

const getClassesFilteredData = classesList => {
    const classesNamesList = getClassesNamesList(classesList);

    return [
        {
            name: 'Class',
            query: 'name',
            type: 'select',
            options: classesNamesList,
            array: true,
        },
        {
            name: 'Neighborhood',
            query: 'location',
            type: 'multibox',
            array: true,
            options: locations,
        },
        {
            name: 'Days',
            query: 'weekdays',
            type: 'multibox',
            weekdays: true,
            array: true,
        },
    ];
};

const classesListReducer = handleActions({
    [ClassesScheduleTypes.SET_CLASSES]: (state, action) => {
        const { results, count, next, previous } = action.payload;

        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);

        let classesList;

        if (results) {
            classesList = results.reduce((resultArray, classElement, classElementIndex) => {
                classElement.nameIndexed = classElement.name + '-' + classElement.id;
                resultArray.push(classParser(classElement));
                return resultArray;
            }, []);
        } else {
            classesList = [];
        }

        const filteredClasses = classScheduleFilter(fromJS(classesList), state.get('filterObject').toJS());

        const descriptionController = getDescriptionController(classesList);

        const pagesCount = Math.ceil(count / classesList.length);

        return state.update(state => {
            return (
                state
                    .setIn(['initialClasses'], results)
                    .setIn(['classes'], fromJS(classesList))
                    .setIn(['filteredClasses'], filteredClasses.classes)
                    .setIn(['count'], count)
                    .setIn(['filteredCount'], filteredClasses.count)
                    .setIn(['pageSize'], classesList.length)
                    // eslint-disable-next-line camelcase
                    .setIn(['pages'], pagesCount)
                    .setIn(['nextUrl'], nextUrl)
                    .setIn(['previousUrl'], previousUrl)
                    .setIn(['isDescriptionFull'], fromJS(descriptionController))
            );
        });
    },
    [ClassesScheduleTypes.SET_CLASSES_OPTIONS]: (state, action) => {
        let classesList;

        if (action.payload) {
            classesList = action.payload.reduce((resultArray, classElement, classElementIndex) => {
                classElement.nameIndexed = classElement.name + '-' + classElement.id;
                resultArray.push(classParser(classElement));
                return resultArray;
            }, []);
        } else {
            classesList = [];
        }

        const filters = getClassesFilteredData(classesList);

        return state.update(state => {
            return state.setIn(['filters'], fromJS(filters));
        });
    },
    [ClassesScheduleTypes.SET_NEXT_CLASSES]: (state, action) => {
        const { results, count, next, previous } = action.payload;

        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);

        let classesList;

        if (results) {
            classesList = state.get('classes')
                               .toJS()
                               .concat(results)
                               .reduce((resultArray, classElement, classElementIndex) => {
                                   classElement.nameIndexed = classElement.name + '-' + classElementIndex;
                                   resultArray.push(classParser(classElement));
                                   return resultArray;
                               }, []);
        } else {
            classesList = state.get('filteredClasses').toJS();
        }

        const filteredClasses = classScheduleFilter(fromJS(classesList), state.get('filterObject').toJS());

        const descriptionController = getDescriptionController(classesList);

        const pagesCount = Math.ceil(count / classesList.length);

        return state.update(state => {
            return (
                state
                    .setIn(['classes'], fromJS(classesList))
                    .setIn(['filteredClasses'], filteredClasses.classes)
                    .setIn(['count'], count)
                    .setIn(['filteredCount'], filteredClasses.count)
                    .setIn(['pageSize'], classesList.length)
                    // eslint-disable-next-line camelcase
                    .setIn(['pages'], pagesCount)
                    .setIn(['nextUrl'], nextUrl)
                    .setIn(['previousUrl'], previousUrl)
                    .setIn(['isDescriptionFull'], fromJS(descriptionController))
            );
        });
    },
    [ClassesScheduleTypes.UPDATE_CLASSES_DESCRIPTION_STATE]: (state, action) => {
        return state.update(state => {
            return state.setIn(
                ['isDescriptionFull', action.payload],
                !state.getIn(['isDescriptionFull', action.payload]),
            );
        });
    },
    [ClassesScheduleTypes.UPDATE_CLASS_FEATURED_OPTION]: (state, action) => {
        return state.update(state => {
            const { index, featured } = action.payload;
            const classElementId = state.getIn(['filteredClasses', index, 'id']);

            let classElementIndex;
            let _found = false;
            state.get('classes').every((classItem, classIndex) => {
                if (classItem.get('id') === classElementId) {
                    _found = true;
                    classElementIndex = classIndex;
                }

                return !_found;
            });

            return state
                .setIn(['classes', classElementIndex, 'featured'], featured)
                .setIn(['filteredClasses', index, 'featured'], featured);
        });
    },
    [ClassesScheduleTypes.UPDATE_CLASSES_FILTER_TAGS]: (state, action) => {
        return state.update(state => {
            return state.setIn(['filterTags'], action.payload);
        });
    },
    [ClassesScheduleTypes.UPDATE_CLASSES_FILTER_OBJECT]: (state, action) => {
        const filteredClasses = classScheduleFilter(state.get('classes'), action.payload.toJS());
        return state.update(state => {
            return state
                .setIn(['filterObject'], action.payload)
                .setIn(['filteredClasses'], filteredClasses.classes)
                .setIn(['filteredCount'], filteredClasses.count);
        });
    },
    [ClassesScheduleTypes.SET_CLASSES_FILTER_OPTIONS]: (state, action) => {
        return state.update(state => {
            return state.setIn(['choices'], fromJS(action.payload));
        });
    },
    // WON'T USE ADD_NEW_CLASS_TO_CLASSES DUE TO HARD PARSING FOR CLASSES
    // AFTER THE NEW ONE WAS ADDED. INSTEAD GETTING ALL THE CLASSES
    // [ClassesScheduleTypes.ADD_NEW_CLASS_TO_CLASSES]: (state, action) => {
    //     const parsedNewClass = classParser(action.payload.size ? action.payload.toJS() : action.payload);
    //     // in filters object it always gets '-0' because it's inserted as a first item
    //     parsedNewClass.nameIndexed = `${parsedNewClass.name}-0`;
    //     const newClassesList = state.get('classes').insert(0, fromJS(parsedNewClass));
    //     const filteredClasses = classScheduleFilter(newClassesList, state.get('filterObject').toJS());
    //
    //     const descriptionController = getDescriptionController(newClassesList, state.get('isDescriptionFull'));
    //
    //     const pagesCount = Math.ceil(state.get('count') / newClassesList.size);
    //
    //     const filters = getClassesFilteredData(newClassesList);
    //
    //     return state.update(state => {
    //         return state
    //             .setIn(['classes'], newClassesList)
    //             .setIn(['filteredClasses'], filteredClasses.classes)
    //             .setIn(['count'], newClassesList.size)
    //             .setIn(['filteredCount'], filteredClasses.count)
    //             .setIn(['pageSize'], newClassesList.size)
    //             .setIn(['pages'], pagesCount)
    //             .setIn(['isDescriptionFull'], fromJS(descriptionController))
    //             .setIn(['filters'], fromJS(filters));
    //     });
    // },
    [ClassesScheduleTypes.UPDATE_CLASS_TO_CLASSES]: (state, action) => {
        const classJS = action.payload.size ? action.payload.toJS() : action.payload;
        classJS.nameIndexed = classJS.name + '-' + classJS.id;
        const parsedUpdateClass = classParser(classJS);

        let updatedClassElementIndex;
        let _found = false;
        state.get('classes').every((classItem, classIndex) => {
            if (classItem.get('id') === parsedUpdateClass.id) {
                _found = true;
                updatedClassElementIndex = classIndex;
            }

            return !_found;
        });

        const newClassesList = state
            .setIn(['classes', updatedClassElementIndex], fromJS(parsedUpdateClass))
            .get('classes');

        const filteredClasses = classScheduleFilter(fromJS(newClassesList), state.get('filterObject').toJS());

        return state.update(state => {
            return state
                .setIn(['classes'], newClassesList)
                .setIn(['filteredClasses'], filteredClasses.classes)
                .setIn(['count'], newClassesList.size)
                .setIn(['filteredCount'], filteredClasses.count)
                .setIn(['pageSize'], newClassesList.length);
        });
    },
    [ClassesScheduleTypes.START_CLASSES_LOADING]: (state, action) => state.setIn(['isLoading'], true),
    [ClassesScheduleTypes.FINISH_CLASSES_LOADING]: (state, action) => state.setIn(['isLoading'], false),
}, INITIAL_STATE);

export default classesListReducer;
