import classNames from 'classnames';
import React from 'react';

import Icon from '../../../Icon';
import { ReactComponent as IconCheckmark } from '../../../../../assets/icons/icon-checkmark.svg';

function customSelectAddValue(value) {
    const valueArray = this.getValueArray(this.props.value);
    this.setValue(valueArray.concat(value));
}

function multiboxOverflowUpdate() {
    if (this.miltiboxOverflowWrapper !== undefined && this.miltiboxWrapper) {
        const { multiboxOverflow } = this.state;
        if (multiboxOverflow !== (this.miltiboxOverflowWrapper.clientWidth < this.miltiboxWrapper.clientWidth)) {
            this.setState({
                multiboxOverflow: this.miltiboxOverflowWrapper.clientWidth < this.miltiboxWrapper.clientWidth,
            });
        }
    }
}

function customSelectRender() {
    const valueArray = this.getValueArray(this.props.value);
    this._visibleOptions = this.filterOptions(null);
    const options = this._visibleOptions;
    const { isOpen, multiboxOverflow } = this.state;
    // const { multiboxOverflow } = this.state;

    // const isOpen = true;

    const focusedOptionIndex = this.getFocusableOptionIndex(valueArray[0]);

    let focusedOption = null;
    if (focusedOptionIndex !== null) {
        this._focusedOption = options[focusedOptionIndex];
        focusedOption = this._focusedOption;
    } else {
        this._focusedOption = null;
        focusedOption = this._focusedOption;
    }
    const className = classNames('Select', this.props.className, {
        'Select--multi': this.props.multi,
        'Select--single': !this.props.multi,
        'is-clearable': this.props.clearable,
        'is-disabled': this.props.disabled,
        'is-focused': this.state.isFocused,
        'is-loading': this.props.isLoading,
        'is-open': isOpen,
        'is-pseudo-focused': this.state.isPseudoFocused,
        'is-searchable': this.props.searchable,
        'has-value': valueArray.length,
        'select-control__multibox': true,
        'is-miltibox-overflow': multiboxOverflow,
    });

    let removeMessage = null;
    if (
        this.props.multi &&
        !this.props.disabled &&
        valueArray.length &&
        !this.state.inputValue &&
        this.state.isFocused &&
        this.props.backspaceRemoves
    ) {
        removeMessage = (
            <span
                id={this._instancePrefix + '-backspace-remove-message'}
                className='Select-aria-only'
                aria-live='assertive'>
                {this.props.backspaceToRemoveMessage.replace(
                    '{label}',
                    valueArray[valueArray.length - 1][this.props.labelKey],
                )}
            </span>
        );
    }

    return (
        <div
            ref={ref => {
                this.wrapper = ref;
            }}
            className={className}
            style={this.props.wrapperStyle}>
            {this.renderHiddenField(valueArray)}
            <div
                ref={ref => {
                    this.control = ref;
                }}
                className='Select-control'
                style={this.props.style}
                onKeyDown={this.handleKeyDown}
                onMouseDown={this.handleMouseDown}
                onTouchEnd={this.handleTouchEnd}
                onTouchStart={this.handleTouchStart}
                onTouchMove={this.handleTouchMove}>
                <div
                    className='Select-multibox-overflow-wrapper'
                    ref={ref => {
                        this.miltiboxOverflowWrapper = ref;
                    }}>
                    <span
                        className='Select-multi-value-wrapper'
                        id={this._instancePrefix + '-value'}
                        ref={ref => {
                            this.miltiboxWrapper = ref;
                        }}>
                        {this.renderValue(valueArray, isOpen)}
                        {this.renderInput(valueArray, focusedOptionIndex)}
                    </span>
                </div>
                {removeMessage}
                {this.renderLoading()}
                {this.renderClear()}
                {this.renderArrow()}
            </div>
            {isOpen ? this.renderOuter(options, valueArray, focusedOption) : null}
        </div>
    );
}

export const multiboxSelectRef = selectInstance => {
    if (selectInstance) {
        if (!selectInstance.getValueArray && selectInstance.select && selectInstance.select.getValueArray) {
            selectInstance = selectInstance.select;
        }

        const originalSetValue = selectInstance.setValue;
        const originalRenderOuter = selectInstance.renderOuter;
        /* const originalRemoveValue = selectInstance.removeValue;
        const originalRender = selectInstance.render; */
        const originalComponentDidMount = selectInstance.componentDidMount;
        const originalComponentDidUpdate = selectInstance.componentDidUpdate;
        selectInstance.state = Object.assign({}, selectInstance.state, {
            multiboxOverflow: false,
        });
        selectInstance.multiboxOverflowUpdate = (...args) => {
            multiboxOverflowUpdate.apply(selectInstance, args);
        };
        selectInstance.componentDidMount = (...args) => {
            selectInstance.multiboxOverflowUpdate();
            originalComponentDidMount.apply(selectInstance, args);
        };
        selectInstance.componentDidUpdate = (...args) => {
            selectInstance.multiboxOverflowUpdate();
            selectInstance.hasScrolledToOption = true;
            originalComponentDidUpdate.apply(selectInstance, args);
        };
        selectInstance.removeValue = value => {
            const valueArray = selectInstance.getValueArray(selectInstance.props.value);
            selectInstance.setValue(
                valueArray.filter(i => {
                    if (value instanceof Object) {
                        return i.value !== value.value;
                    }
                    return i !== value;
                }),
            );
            selectInstance.focus();
        };
        selectInstance.renderOuter = (...args) => {
            const valueArray = selectInstance.getValueArray(selectInstance.props.value);
            const optionsArray = selectInstance.filterOptions(null);
            return originalRenderOuter.apply(selectInstance, [optionsArray, valueArray, args[2]]);
        };
        selectInstance.setValue = value => {
            let remove = false;
            const newValue = value.reduce((resultArray, valueItem) => {
                let _found = false;
                resultArray.every((resultItem, resultItemIndex) => {
                    if (resultItem.value === valueItem.value) {
                        _found = true;
                        selectInstance.removeValue(valueItem);
                    }
                    return !_found;
                });

                if (!_found) {
                    resultArray.push(valueItem);
                } else {
                    remove = true;
                }
                return resultArray;
            }, []);

            if (!remove) {
                originalSetValue.call(selectInstance, newValue);
                selectInstance.setState({
                    isOpen: true,
                });
            }
        };
        selectInstance.addValue = (...args) => {
            customSelectAddValue.apply(selectInstance, args);
        };
        selectInstance.render = (...args) => {
            return customSelectRender.apply(selectInstance, args);
        };
    }
};

export const selectMultiboxRenderer = (data, value) => {
    const selected =
        typeof value === 'string'
            ? value === data.value
            : value.reduce((selectedValue, valueItem) => {
                if (valueItem.value === data.value) {
                    selectedValue = true;
                }
                return selectedValue;
              }, false);
    return (
        <div className='select-control__multibox-option'>
            {selected
                ? <div className='select-control__multibox-checkbox select-control__multibox-checkbox_checked'>
                    <Icon glyph={IconCheckmark} className='icon_check-mark-small' />
                </div>
                : <div className='select-control__multibox-checkbox' />}
            <div className='select-control__label'>
                {data.label}
            </div>
        </div>
    );
};

const MultiSelectBox = {
    multiboxSelectRef,
    selectMultiboxRenderer,
};

export default MultiSelectBox;
