import { ReactComponent as iconCalendar } from '../../../assets/icons/icon-calendar.svg';
import { ReactComponent as iconClock } from '../../../assets/icons/icon-clock.svg';
import { ReactComponent as iconCustomers } from '../../../assets/icons/icon-customers.svg';
import { ReactComponent as iconDashboard } from '../../../assets/icons/icon-dashboard.svg';
import { ReactComponent as IconDirectory } from '../../../assets/icons/icon-directory.svg';
import { ReactComponent as IconDog } from '../../../assets/icons/icon-dog.svg';
import { ReactComponent as IconForms } from '../../../assets/icons/icon-forms.svg';
import { ReactComponent as IconMarketing } from '../../../assets/icons/icon-marketing.svg';
import { ReactComponent as IconPackages } from '../../../assets/icons/icon-packages.svg';
import { ReactComponent as IconReports } from '../../../assets/icons/icon-reports.svg';

export const links = [
    { to: '/dashboard', iconClass: 'icon_dashboard', icon: iconDashboard, text: 'Dashboard' },
    { to: '/dashboard/customers', iconClass: 'icon_customers', icon: iconCustomers, text: 'Customers' },
    { to: '/dashboard/dogs', iconClass: 'icon_dog', icon: IconDog, text: 'Dogs' },
    { to: '/dashboard/directory', iconClass: 'icon_directory', icon: IconDirectory, text: 'Directory' },
    { to: '/dashboard/class-schedule', iconClass: 'icon_clock', icon: iconClock, text: 'Class Schedule' },
    { to: '/dashboard/packages', iconClass: 'icon_packages', icon: IconPackages, text: 'Packages', mobileText: 'Packages and training' },
    { to: '/dashboard/calendar', iconClass: 'icon_calendar', icon: iconCalendar, text: 'Calendar' },
    { to: '/dashboard/reports', iconClass: 'icon_reports', icon: IconReports, text: 'Reports' },
    { to: '/dashboard/marketing', iconClass: 'icon_marketing', icon: IconMarketing, text: 'Marketing' },
    { to: '/dashboard/forms', iconClass: 'icon_forms', icon: IconForms, text: 'Forms' }
];

export const employeeLinks = [
    { to: '/dashboard', iconClass: 'icon_dashboard', icon: iconDashboard, text: 'Dashboard' },
    { to: '/dashboard/customers', iconClass: 'icon_customers', icon: iconCustomers, text: 'Customers' },
    { to: '/dashboard/dogs', iconClass: 'icon_dog', icon: IconDog, text: 'Dogs' },
    { to: '/dashboard/directory', iconClass: 'icon_directory', icon: IconDirectory, text: 'Directory' },
    { to: '/dashboard/class-schedule', iconClass: 'icon_clock', icon: iconClock, text: 'Class Schedule' },
    { to: '/dashboard/packages', iconClass: 'icon_packages', icon: IconPackages, text: 'Packages', mobileText: 'Packages and training' },
    { to: '/dashboard/calendar', iconClass: 'icon_calendar', icon: iconCalendar, text: 'Calendar' },
    { to: '/dashboard/reports', iconClass: 'icon_reports', icon: IconReports, text: 'Reports' },
    { to: '/dashboard/forms', iconClass: 'icon_forms', icon: IconForms, text: 'Forms' }
];

export const trainerLinks = [
    { to: '/dashboard', iconClass: 'icon_dashboard', icon: iconDashboard, text: 'Dashboard' },
    { to: '/dashboard/calendar', iconClass: 'icon_calendar', icon: iconCalendar, text: 'Calendar' },
    { to: '/dashboard/directory', iconClass: 'icon_directory', icon: IconDirectory, text: 'Directory' },
    { to: '/dashboard/class-schedule', iconClass: 'icon_clock', icon: iconClock, text: 'Class Schedule' },
    { to: '/dashboard/packages', iconClass: 'icon_packages', icon: IconPackages, text: 'Packages', mobileText: 'Packages and training' },
    { to: '/dashboard/reports', iconClass: 'icon_reports', icon: IconReports, text: 'Reports' },
];

