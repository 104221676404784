import React, { useRef } from "react";
import SignaturePad from "react-signature-canvas";
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
    changeSignatureStatus,
    saveDataStamp,
    saveSignatureOwner
} from "../../../../../actions/customer/CustomerActions";
import "./sigCanvas.css";
import "./main.css"
import moment from "moment";




function AddSignature(props) {
    const {
        saveSignatureOwner,
        changeSignatureStatus,
        saveDataStamp,
    } = props;

    const clearClassName = classNames({
        'button_clear':true,
    });

    const saveClassName = classNames({
        'button_save':true,
    });

    const sigCanvas = useRef({});

    const clear = (e) => {
        e.preventDefault();
        saveSignatureOwner('');
        changeSignatureStatus(true);
        sigCanvas.current.clear();
    };

    const save = (e) => {
        e.preventDefault();
        let date = new Date().toString();
        let timeStamp = date.slice(0, 24);
        let StartDate = moment(timeStamp).format('MM/DD/YY');
        saveSignatureOwner(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
        saveDataStamp(StartDate);
        changeSignatureStatus(false)
    };

    return (
        <div className="App">
            <SignaturePad
                ref={sigCanvas}
                canvasProps={{width: 500, height: 200, className: 'signatureCanvas'}}
            />
            <button className={saveClassName} onClick={(event)=> save(event)}>Save</button>
            <button className={clearClassName} onClick={(event)=> clear(event)}>Clear</button>
            <div className='form-update__headline'>
                <p>To navigate the homepage press I ACCEPT</p>
            </div>

        </div>
    );
}

const mapStateToProps = state => ({
    form_update_signature: state.get('form_update_signature'),
    is_added: state.get('form_update_signature_is_added'),
    date_stamp: state.get('date_stamp'),
});

const mapDispatchToProps = {
    saveSignatureOwner,
    changeSignatureStatus,
    saveDataStamp,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSignature);
