import moment from 'moment';
import { push } from 'react-router-redux';
import { change } from 'redux-form/immutable';
import { createAction } from 'redux-actions';
import { DEFAULT_DATE_FORMAT } from '../../reducers/dashboardPageReducer';
import * as ReportsTypes from '../../types/dashboard/reportsTypes';
import { handlePageChange } from '../../actions/tabActions';

export const setReportsStartDate = createAction(ReportsTypes.SET_REPORTS_START_DATE);
export const setReportsEndDate = createAction(ReportsTypes.SET_REPORTS_END_DATE);

export const getStartEndDates = ({ start, end }) => {
    if (start && end) {
        start = moment.utc(start).format(DEFAULT_DATE_FORMAT);
        end = moment.utc(end).format(DEFAULT_DATE_FORMAT);
    } else {
        start = moment().format(DEFAULT_DATE_FORMAT);
        end = moment().format(DEFAULT_DATE_FORMAT);
    }
    return { start, end };
};

export const openCustomReports = (fields) => {
    return dispatch => {
        dispatch(push('/dashboard/reports'));
        dispatch(handlePageChange({
            nextPageName: 'customReports',
        }));
        setTimeout(() => {
            fields.forEach((field) => {
                dispatch(change('customReportFilterForm', field.key, field.value));
            });
        }, 10);
    };
};
