import * as ModalActions from '../../actions/modal/modalActions';
import { FORM_NAME as form } from './form';
import { getUserNameSpace } from '../../helpers/userRoles';

export const openCampaignSendTestModal = () => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                title: 'Send Test Email',
                text: 'Send a test email to the site administrators?',
                submitClickHandler: 'submitForm',
                form,
                yesButtonText: 'Send Test Email',
                noButtonText: 'Cancel',
                isUppercase: false
            })
        );
    };
};

export const openCampaignSendModal = () => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                title: 'Create and Send',
                text: 'Are you sure you want to send the campaign? Once you send it, you cannot recall the message.',
                submitClickHandler: 'submitForm',
                form,
                yesButtonText: 'Yes, let\'s send it',
                noButtonText: 'No, let me check one more time',
                isUppercase: false
            })
        );
    };
};

export const openCampaignDiscardChangesModal = () => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                title: 'Discard Changes?',
                text: 'You\'ve made changes to this campaign.',
                extraText: 'Cancelling will discard any changes that you have made. Would you like to continue?',
                submitClickHandler: 'goToUrl',
                yesButtonText: 'Continue and discard changes',
                noButtonText: 'Stay and save changes',
                url: '/dashboard/marketing/',
            }),
        );
    };
};

export const openCampaignDeleteModal = campaignData => {
    return dispatch => {
        const name = 'the ' + (campaignData.name || 'campaign');
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                submitAction: 'deleteItem',
                errorAction: 'handleModalSubmitError',
                updateAction: 'goToMarketing',
                alertEvent: 'CAMPAIGN_DELETE',
                alertData: { name: campaignData.name || 'The campaign' },
                itemId: campaignData.id,
                url: `/v1/${getUserNameSpace('campaigns')}/campaigns/${campaignData.id}/`,
                title: 'Delete Campaign',
                text: `Are you sure you would like to delete the ${name} campaign?`,
            }),
        );
    };
};
