import PropTypes from 'prop-types';
import React from 'react';
import ClassNames from 'classnames';
import { Provider } from 'react-redux';
import Form, { mockFormState } from '../Form';
import { getFormConfig } from './form';
import Logo from '../common/Logo';

const propTypes = {
    detailID: PropTypes.number,
    templateID: PropTypes.number,
    templates: PropTypes.shape({}),
    variables: PropTypes.shape({}),
    initialValues: PropTypes.shape({}),
    arrayOrder: PropTypes.shape({}),
    isPreview: PropTypes.bool,
    shouldSendOnSubmit: PropTypes.bool,
    getRefForm: PropTypes.func,
    updateArrayOrder: PropTypes.func,
};

class TemplateEditor extends React.PureComponent {
    static propTypes = propTypes;

    componentDidMount() {
        // Scroll to top after pasting content to template previews.
        window.scrollTo(0, 0);
    }

    shouldComponentUpdate() {
        return !this.props.isPreview;
    }

    renderEditor = () => {
        const {
            detailID,
            templateID = 1,
            templates,
            variables,
            initialValues,
            isPreview = false,
            updateArrayOrder,
            arrayOrder,
            getRefForm } = this.props;

        const editorClassNames = ClassNames({
            'template-editor': true,
            'template-editor_preview': isPreview
        });

        const formConfig = getFormConfig({ isPreview });
        formConfig.detailID = detailID;
        formConfig.initialValues = initialValues;
        formConfig.arrayOrder = arrayOrder.toJS();

        return (
            <div className={editorClassNames}>
                <Logo logoClassName='logo template-editor__logo'/>
                <Form
                    key={'template-editor' + (isPreview ? '-preview' : '')}
                    ref={getRefForm}
                    enableReinitialize={!isPreview}
                    destroyOnUnmount={isPreview}
                    forceUnregisterOnUnmount
                    keepDirtyOnReinitialize={!isPreview}
                    formConfig={formConfig}
                    isPreview={isPreview}
                    templateID={templateID}
                    templates={templates}
                    variables={variables}
                    updateArrayOrder={updateArrayOrder}
                    onGetOptions={() => []}/>
            </div>
        );
    };

    renderPreview = () => {
        const { templates, templateID, detailID, arrayOrder } = this.props;
        const { fields, ...formConfig } = getFormConfig({});
        formConfig.templateID = templateID;
        formConfig.detailID = detailID;
        formConfig.arrayOrder = arrayOrder.toJS();
        return (
            <div>
                <Provider store={mockFormState()} key={Math.random()}>
                    {this.renderEditor()}
                </Provider>
                <div style={{ display: 'none' }}>
                    <Form
                        // Need it here to be able to trigger submit.
                        key='template-editor-preview'
                        destroyOnUnmount={false}
                        keepDirtyOnReinitialize
                        formConfig={formConfig}
                        isPreview
                        templates={templates}
                        onGetOptions={() => []}/>
                </div>
            </div>
        );
    };

    render() {
        const { isPreview } = this.props;
        return isPreview ? this.renderPreview() : this.renderEditor();
    }
}

export default TemplateEditor;
