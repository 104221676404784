import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';

const Fader = ({ className, children }) => {
    return (
        <CSSTransitionGroup
            component='div'
            className={className}
            transitionName='fade'
            transitionEnterTimeout={500}
            transitionLeaveTimeout={0}>
            {children}
        </CSSTransitionGroup>
    );
};

Fader.propTypes = {
    className: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.any,
};

export default Fader;
