import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCurrentInstructorEmployee } from '../../../../../../actions/dashboard/instructorEmployeeActions';
import { setEditInstructorEmployeeModalOpened, setResetPasswordModalOpened } from '../../../../../../actions/miscActions';
import { ReactComponent as IconDots } from '../../../../../../assets/icons/icon-dots.svg';
import { ReactComponent as IconEdit } from '../../../../../../assets/icons/icon-edit.svg';
import { ReactComponent as IconPassword } from '../../../../../../assets/icons/icon-password.svg';
import { ReactComponent as IconTrash } from '../../../../../../assets/icons/icon-trash.svg';
import { capitalizeEachWord } from '../../../../../../helpers/text';
import Dropdown from '../../../../../common/Dropdown';
import Table from '../../../../../common/Table';
import { isEmployee, isManager } from '../../../../../../helpers/userRoles';
import { openEmployeeDeleteModalDialog } from '../../../../../../actions/modal/openActions';
import { prettifyPhone } from '../../../../../../helpers/normalize';

class DirectoryTable extends Table {
    static propTypes = {
        readOnly: PropTypes.bool,
        data: PropTypes.shape({}).isRequired,
        loading: PropTypes.bool.isRequired,
        count: PropTypes.number,
        pages: PropTypes.number,
        handleInstructorEmployeeModalOpened: PropTypes.func.isRequired,
        setInstructorEmployeeData: PropTypes.func.isRequired,
        handleResetPasswordModalOpened: PropTypes.func.isRequired,
        getNext: PropTypes.func,
        nextUrl: PropTypes.string,
        handleDeleteEmployeeModalOpened: PropTypes.func,
    };

    getDropdownItems = (row) => {
        const itemsList = [];
        const {
            handleInstructorEmployeeModalOpened,
            setInstructorEmployeeData,
            handleResetPasswordModalOpened,
            handleDeleteEmployeeModalOpened
        } = this.props;
        if (!isEmployee() && !isManager()) {
            itemsList.push({
                iconClass: 'icon_edit',
                icon: IconEdit,
                text: 'Edit',
                onClickCB: () => {
                    handleInstructorEmployeeModalOpened();
                    setInstructorEmployeeData(row.original);
                }
            });
        }
        itemsList.push({
            iconClass: 'icon_password',
            icon: IconPassword,
            text: 'Reset Password',
            onClickCB: () => {
                handleResetPasswordModalOpened({
                    fullName: row.value.get('full_name'),
                    email: row.value.get('email')
                });
            }
        });
        if (!isEmployee() && !isManager()) {
            itemsList.push({
                iconClass: 'icon_trash',
                icon: IconTrash,
                text: 'Delete',
                onClickCB: () => {
                    handleDeleteEmployeeModalOpened(row.value.toJS());
                }
            });
        }
        return itemsList;
    };

    render() {
        const { isScrollable } = this.state;
        const {
            readOnly,
        } = this.props;
        const columns = [
            {
                Header: 'Name',
                headerClassName: 'directory-table__th',
                className: 'directory-table__td directory-table__td_name',
                id: 'name',
                accessor: d => d.get('full_name'),
                Cell: row => (
                    <div>
                        {row.value || '-'}
                    </div>
                ),
            },
            {
                Header: 'Email',
                headerClassName: 'directory-table__th',
                className: 'directory-table__td directory-table__td_email',
                id: 'email',
                accessor: d => d.get('email'),
                Cell: row => (
                    <div>
                        {row.value || '-'}
                    </div>
                ),
            },
            {
                Header: 'Phone Number',
                headerClassName: 'directory-table__th',
                className: 'directory-table__td',
                id: 'phone',
                accessor: d => d.getIn(['available_phones', '0', 'phone_number']),
                Cell: row => (
                    <div>
                        {prettifyPhone(row.value) || '-'}
                    </div>
                ),
            },
            {
                Header: 'Emergency Contact',
                headerClassName: 'directory-table__th directory-table__th_select',
                className: 'directory-table__td',
                id: 'emergency_contact',
                accessor: (d) => {
                    return d.get('emergency_contact') ? d.getIn(['emergency_contact', 'name']) : null;
                },
                Cell: row => (
                    <div>
                        {row.value || '-'}
                    </div>
                ),
            },
            {
                Header: 'Position',
                headerClassName: 'directory-table__th',
                className: 'directory-table__td',
                id: 'position',
                accessor: d => d.get('position'),
                Cell: row => (
                    <div>
                        {row.value ? capitalizeEachWord(row.value)
                            : '-'}
                    </div>
                ),
            },
            {
                Header: 'Permissions',
                headerClassName: 'directory-table__th',
                className: 'directory-table__td',
                id: 'permission',
                accessor: d => d.get('permission_level_detail'),
                Cell: row => (
                    <div>
                        {row.value ? row.value : '-'}
                    </div>
                ),
            },
            {
                Header: 'Qualified For',
                headerClassName: 'directory-table__th',
                className: 'directory-table__td',
                id: 'qualified_for',
                accessor: d => d.get('qualified_for'),
                Cell: row => (
                    <div>
                        {row.value || '-'}
                    </div>
                ),
            },
        ];
        if (!readOnly) {
            columns.push({
                Header: '',
                id: 'dropdown',
                headerClassName: 'directory-table__th directory-table__th_last-of-type',
                className: 'directory-table__td',
                width: 50,
                accessor: d => d,
                Cell: row => (
                    <Dropdown
                        classNames='dropdown_table-directory'
                        icon={IconDots}
                        iconClass='icon_chevron_down'
                        items={this.getDropdownItems(row)}/>
                )
            });
        }
        return (
            <div className='directory-table'>
                {this.renderTable({ isScrollable, columns })}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    handleInstructorEmployeeModalOpened: bindActionCreators(setEditInstructorEmployeeModalOpened, dispatch),
    setInstructorEmployeeData: bindActionCreators(setCurrentInstructorEmployee, dispatch),
    handleResetPasswordModalOpened: bindActionCreators(setResetPasswordModalOpened, dispatch),
    handleDeleteEmployeeModalOpened: bindActionCreators(openEmployeeDeleteModalDialog, dispatch),
});

export default connect(null, mapDispatchToProps)(DirectoryTable);
