import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formatMoney } from '../../../../../../helpers/currency';
import { getTypeName } from '../../../../../../helpers/text';
import { getDays } from '../../../../../../helpers/date';
import Dropdown from '../../../../../common/Dropdown';
import { ReactComponent as IconDots } from '../../../../../../assets/icons/icon-dots.svg';
import { ReactComponent as IconTrash } from '../../../../../../assets/icons/icon-trash.svg';
import { ReactComponent as IconEdit } from '../../../../../../assets/icons/icon-edit.svg';
import { openEditPackageModalDialog, openPackageDeleteModalDialog } from '../../../../../../actions/modal/openActions';
import { ReactComponent as IconPlay } from '../../../../../../assets/icons/icon-play.svg';
import { ReactComponent as IconPause } from '../../../../../../assets/icons/icon-pause.svg';
import { toggleStatus } from '../../../../../../services/packages';
import AvatarPlaceholder from '../../../../../common/AvatarPlaceholder/AvatarPlaceholder';

const PackagesCard = ({
    data,
    handleOpenEditPackageModal,
    handleDeletePackageModalOpened,
    handleTogglePackagePause,
    readOnly
}) => {
    const photo = data.get('photo_processed') || data.get('photo');
    const price = data.get('price');
    const name = data.get('name');
    const type = data.get('model_type');
    const quantity = data.get('quantity');
    const expiresAfter = data.get('expires_after');
    const status = data.get('is_paused');

    const headerStyle = {
        background: `#98999d url(${photo}) center center / cover no-repeat`
    };

    return (
        <div className='grid-card-container'>
            <div className='grid-card'>
                {!readOnly && <Dropdown
                    classNames='dropdown_packages-card'
                    icon={IconDots}
                    iconClass='icon_chevron_down'
                    items={[
                        {
                            iconClass: status ? 'icon_play' : 'icon_pause',
                            icon: status ? IconPlay : IconPause,
                            text: status ? 'Play' : 'Pause',
                            onClickCB: () => {
                                handleTogglePackagePause(data);
                            },
                        },
                        {
                            iconClass: 'icon_edit',
                            icon: IconEdit,
                            text: 'Edit',
                            onClickCB: () => {
                                handleOpenEditPackageModal(data);
                            },
                        },
                        {
                            iconClass: 'icon_trash',
                            icon: IconTrash,
                            text: 'Delete',
                            onClickCB: () => {
                                handleDeletePackageModalOpened(data);
                            },
                        },
                    ]}/>}

                <div className='grid-card__header' style={headerStyle}>
                    {data.get('photo_processed') === null && <AvatarPlaceholder type='dog' size='card'/>}
                    {price && <div className='grid-card__price'>{formatMoney(price)}</div>}
                </div>
                {name && <div className='grid-card__name'>{name}</div>}
                <div className='grid-card__items'>
                    {
                        type &&
                        <div className='grid-card__item'>
                            <div className='grid-card__item-header'>Type</div>
                            <div className='grid-card__item-content'>{getTypeName(type)}</div>
                        </div>
                    }
                    {
                        quantity &&
                        <div className='grid-card__item'>
                            <div className='grid-card__item-header'>Quantity</div>
                            <div className='grid-card__item-content'>{quantity}</div>
                        </div>
                    }
                    {
                        expiresAfter &&
                        <div className='grid-card__item'>
                            <div className='grid-card__item-header'>Expires After</div>
                            <div className='grid-card__item-content'>{getDays(expiresAfter)}</div>
                        </div>
                    }
                    {
                        typeof status !== 'undefined' &&
                        <div className='grid-card__item'>
                            <div className='grid-card__item-header'>Status</div>
                            <div className='grid-card__item-content'>{status ? 'Paused' : 'Active'}</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

PackagesCard.propTypes = {
    readOnly: PropTypes.bool,
    data: PropTypes.shape({}).isRequired,
    handleOpenEditPackageModal: PropTypes.func.isRequired,
    handleDeletePackageModalOpened: PropTypes.func.isRequired,
    handleTogglePackagePause: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    handleOpenEditPackageModal: bindActionCreators(openEditPackageModalDialog, dispatch),
    handleDeletePackageModalOpened: bindActionCreators(openPackageDeleteModalDialog, dispatch),
    handleTogglePackagePause: bindActionCreators(toggleStatus, dispatch),
});

export default connect(null, mapDispatchToProps)(PackagesCard);
