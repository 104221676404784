/* eslint-disable camelcase */
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import AvatarPlaceholder from '../../../../common/AvatarPlaceholder/AvatarPlaceholder';

class MessagesItem extends React.PureComponent {
    componentWillReceiveProps(nextProps) {
        if (this.props.item !== nextProps.item) {
            clearTimeout(this.timeout);

            if (nextProps.item.public_status === 0) {
                this.pollMessage();
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    getPhotos = (photos, id) => {
        if (photos.length) {
            return photos.map((photo) => {
                const { photo_processed_3x, pk } = photo;
                const key = `message_${id}_photo_${pk}`;
                return (
                    <img
                        key={key}
                        className='messages__item-image'
                        src={photo_processed_3x}
                        alt={`message_${id}_photo_${pk}`}/>);
            });
        }
        return null;
    };

    pollMessage = () => {
        const { customerId, item: { id } } = this.props;
        this.timeout = setTimeout(() => this.props.getData({ customerId, messageId: id }), 5000);
    }

    render() {
        const {
            id, body, photos, public_status, public_status_detail, modified_at,
            sender_detail: { full_name, photo_processed }, status,
        } = this.props.item;
        const itemsStatusClassNames = classNames({
            'messages__item-status': true,
            'messages__item-status_success': status !== 1,
            'messages__item-status_fail': status === 1
        });

        return (
            <div className='messages__item'>
                {photo_processed === null
                    ? <AvatarPlaceholder size={36}/>
                    : (<img
                        src={photo_processed}
                        alt={full_name}
                        className='messages__item-photo'/>)
                }
                <div className='messages__item-content'>
                    <div className='messages__item-author'>{full_name}</div>
                    <div className='messages__item-body'>
                        {body}
                        {this.getPhotos(photos, id)}
                    </div>
                    <div className={itemsStatusClassNames}>
                        {public_status !== 1
                            ? public_status_detail
                            : `${moment(modified_at).format('MM/DD/YY')} at ${moment(modified_at).format('hh:mmA')}`
                        }
                    </div>
                </div>
            </div>
        );
    }
}

MessagesItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        photos: PropTypes.arrayOf(PropTypes.shape({})),
        public_status: PropTypes.number.isRequired,
        public_status_detail: PropTypes.string.isRequired,
        body: PropTypes.string.isRequired,
        status: PropTypes.number.isRequired,
        status_detail: PropTypes.string.isRequired,
        modified_at: PropTypes.string.isRequired,
        sender_detail: PropTypes.shape({}),
    }).isRequired,
    getData: PropTypes.func.isRequired,
    customerId: PropTypes.number.isRequired,
};

export default MessagesItem;
