/* eslint-disable max-len */
import { push } from 'react-router-redux';
import { createAction } from 'redux-actions';
import { getMissingAgreements } from '../../services/agreements';
import { updateSteps } from '../../services/steps';
import * as OnBoardingStepsTypes from '../../types/onboarding/onBoardingStepsTypes';

export const setCurrentRegistrationStep = createAction(OnBoardingStepsTypes.SET_CURRENT_REGISTRATION_STEP);
export const setIntroductionRegistrationStepComplete = createAction(
    OnBoardingStepsTypes.SET_INTRODUCTION_REGISTRATION_STEP_COMPLETE);
export const setIntroductionStepComplete = createAction(OnBoardingStepsTypes.SET_INTRODUCTION_COMPLETE);
export const setIntroductionStepInComplete = createAction(OnBoardingStepsTypes.SET_INTRODUCTION_INCOMPLETE);
export const setHealthStepComplete = createAction(OnBoardingStepsTypes.SET_HEALTH_COMPLETE);
export const setHealthStepInComplete = createAction(OnBoardingStepsTypes.SET_HEALTH_INCOMPLETE);
export const setVetInfoStepComplete = createAction(OnBoardingStepsTypes.SET_VET_INFO_COMPLETE);
export const setVetInfoStepInComplete = createAction(OnBoardingStepsTypes.SET_VET_INFO_INCOMPLETE);
export const setWaiverInfoStepComplete = createAction(OnBoardingStepsTypes.SET_WAIVER_COMPLETE);
export const setWaiverInfoStepInComplete = createAction(OnBoardingStepsTypes.SET_WAIVER_INCOMPLETE);
export const setFinishingLater = createAction(OnBoardingStepsTypes.SET_FINISHING_LATER);

export const goToIntroductionStep = () => {
    return (dispatch, getState) => {
        if (getState().getIn(['steps', 'registration_step']) === 0
            || getState().getIn(['steps', 'registration_step']) === 1) {
            dispatch(setHealthStepInComplete());
            dispatch(setVetInfoStepInComplete());
            dispatch(setWaiverInfoStepInComplete());
        }
        if (getState().getIn(['steps', 'registration_step']) === 2) {
            dispatch(setHealthStepComplete());
            dispatch(setVetInfoStepInComplete());
            dispatch(setWaiverInfoStepInComplete());
        }
        if (getState().getIn(['steps', 'registration_step']) === 3) {
            dispatch(setHealthStepComplete());
            dispatch(setVetInfoStepComplete());
            dispatch(setWaiverInfoStepInComplete());
        }
        dispatch(setIntroductionStepComplete());
        dispatch(push('/introduction'));
    };
};

export const goToHealthStep = () => {
    return (dispatch, getState) => {
        if (getState().getIn(['steps', 'registration_step']) === 0) {
            dispatch(setIntroductionStepInComplete());
            dispatch(setVetInfoStepInComplete());
        }
        if (getState().getIn(['steps', 'registration_step']) === 1) {
            dispatch(setIntroductionStepComplete());
            dispatch(setVetInfoStepInComplete());
        }
        if (getState().getIn(['steps', 'registration_step']) === 2) {
            dispatch(setIntroductionStepComplete());
            dispatch(setVetInfoStepInComplete());
        }
        if (getState().getIn(['steps', 'registration_step']) === 3) {
            dispatch(setIntroductionStepComplete());
            dispatch(setVetInfoStepComplete());
        }
        dispatch(setWaiverInfoStepInComplete());
        dispatch(setHealthStepComplete());
        dispatch(push('/health'));
    };
};

export const goToVetInfoStep = () => {
    return (dispatch, getState) => {
        if (getState().getIn(['steps', 'registration_step']) === 0) {
            dispatch(setIntroductionStepInComplete());
            dispatch(setHealthStepInComplete());
            dispatch(setWaiverInfoStepInComplete());
        }
        if (getState().getIn(['steps', 'registration_step']) === 1) {
            dispatch(setIntroductionStepComplete());
            dispatch(setHealthStepInComplete());
            dispatch(setWaiverInfoStepInComplete());
        }
        if (getState().getIn(['steps', 'registration_step']) === 2) {
            dispatch(setIntroductionStepComplete());
            dispatch(setHealthStepComplete());
            dispatch(setWaiverInfoStepInComplete());
        }
        if (getState().getIn(['steps', 'registration_step']) === 3) {
            dispatch(setIntroductionStepComplete());
            dispatch(setHealthStepComplete());
            dispatch(setWaiverInfoStepInComplete());
        }
        dispatch(setVetInfoStepComplete());
        dispatch(push('/vet-info'));
    };
};

export const goToWaiverStep = () => {
    return (dispatch, getState) => {
        if (getState().getIn(['steps', 'registration_step']) === 0) {
            dispatch(setIntroductionStepInComplete());
            dispatch(setHealthStepInComplete());
            dispatch(setVetInfoStepInComplete());
        }
        if (getState().getIn(['steps', 'registration_step']) === 1) {
            dispatch(setIntroductionStepComplete());
            dispatch(setHealthStepInComplete());
            dispatch(setVetInfoStepInComplete());
        }
        if (getState().getIn(['steps', 'registration_step']) === 2) {
            dispatch(setIntroductionStepComplete());
            dispatch(setHealthStepComplete());
            dispatch(setVetInfoStepInComplete());
        }
        if (getState().getIn(['steps', 'registration_step']) === 3) {
            dispatch(setIntroductionStepComplete());
            dispatch(setHealthStepComplete());
            dispatch(setVetInfoStepComplete());
        }
        dispatch(setWaiverInfoStepComplete());
        dispatch(push('/waiver'));
    };
};

export const skipAllStepsAndFinishLater = () => {
    return (dispatch) => {
        dispatch(setIntroductionStepInComplete());
        dispatch(setHealthStepInComplete());
        dispatch(setVetInfoStepInComplete());
        dispatch(setWaiverInfoStepComplete());
        dispatch(setFinishingLater());
    };
};

export const skipAllButFirstSteps = () => {
    return (dispatch) => {
        dispatch(setIntroductionStepComplete());
        dispatch(setHealthStepInComplete());
        dispatch(setVetInfoStepInComplete());
        dispatch(setWaiverInfoStepComplete());
    };
};

export const skipOneStepAndFinishLater = () => {
    return (dispatch) => {
        dispatch(setIntroductionStepComplete());
        dispatch(setHealthStepComplete());
        dispatch(setVetInfoStepInComplete());
        dispatch(setWaiverInfoStepComplete());
        dispatch(setFinishingLater());
    };
};

export const completeHealthStep = (shouldGoNextStep = true) => {
    return (dispatch) => {
        if (shouldGoNextStep) {
            dispatch(updateSteps({
                actionCreator: setVetInfoStepComplete,
                stepNumber: 2,
            }));
        } else {
            setTimeout(() => {
                dispatch(setHealthStepComplete());
                dispatch(setVetInfoStepComplete());
                dispatch(push('/vet-info'));
            }, 0);
        }
    };
};

export const completeWaiverInfoStep = (shouldGoNextStep = true) => {
    return (dispatch) => { // eslint-disable-line consistent-return
        if (shouldGoNextStep) {
            dispatch(updateSteps({
                redirectPath: '/waiver',
                actionCreator: shouldGoNextStep ? setWaiverInfoStepComplete : null,
                stepNumber: 4,
            }));
        } else {
            setTimeout(() => {
                dispatch(setHealthStepComplete());
                dispatch(setVetInfoStepComplete());
                dispatch(setWaiverInfoStepComplete());
                dispatch(push('/waiver'));
            }, 0);
        }
    };
};

export const completeSignUp = () => getMissingAgreements();
