import { deleteAdditionalOwner, patchAdditionalOwner, postAdditionalOwner } from '../../services/additionalOwners';
import { getCustomerById } from '../../services/customers';
import { setAdditionalOwnersModalClosed } from '../miscActions';

// tried to set errors in redux-form manually
// export const setAnotherAdditionalOwner = (index) => {
//     return (dispatch, getState) => {
//         const currentErrors = getState().getIn(['form', 'AdditionalOwnersModalDialogForm', 'syncErrors']);
//         const currentValues = getState().getIn(['form', 'AdditionalOwnersModalDialogForm', 'values']);
//         console.log(currentValues);
//         // eslint-disable-next-line no-return-assign
//         return getState()
//             .setIn(['form', 'AdditionalOwnersModalDialogForm', 'syncErrors'], currentErrors[index] = {})
//             .setIn(['form', 'AdditionalOwnersModalDialogForm', 'values', index], {});
//     };
// };

export const updateCustomersAdditionalOwnersData = (customerId, data) => {
    return dispatch => {
        return Object.keys(data).map((owner) => {
            console.log(data[owner]);
            if (data[owner].name === 'deleted') {
                return dispatch(deleteAdditionalOwner(customerId, data[owner]));
            }
            if ('id' in data[owner]) {
                return dispatch(patchAdditionalOwner(customerId, data[owner]));
            }
            return dispatch(postAdditionalOwner(customerId, data[owner]));
        });
    };
};

export const processCustomersAdditionalOwners = (customerId, data) => {
    return dispatch => {
        return Promise.all(dispatch(updateCustomersAdditionalOwnersData(customerId, data))).then(() => {
            dispatch(getCustomerById(customerId)).then(() => {
                dispatch(setAdditionalOwnersModalClosed());
            });
        });
    };
};
