/* eslint-disable react/prop-types */
import React from 'react';
import { FieldArray } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { generateFields as renderForm } from '../../.';
import AddNewItem from '../../../common/AddNewItem';
import RemoveItem from '../../../RemoveItem';

const propTypes = {
    meta: PropTypes.shape({}),
    fields: PropTypes.shape({}),
    getArrayFields: PropTypes.func,
    formProps: PropTypes.shape({}),
    isOpened: PropTypes.bool
};

class TemplateBlockArray extends React.PureComponent {
    static propsTypes = propTypes;

    componentWillMount() {
        const { formProps: { templates } } = this.props;

        if (!templates) {
            return;
        }

        this.multipleAllowed = {};
        templates.forEach(t => {
            if (t.get('multipleBlocks')) {
                const tJS = t.toJS();
                const { id: templateID, multipleBlocks } = tJS;
                this.multipleAllowed[templateID] = {};
                multipleBlocks.forEach(b => {
                    const { id, max } = b;
                    this.multipleAllowed[templateID][id] = max;
                });
            }
        });
    }

    getInitialArrayOrder = () => {
        const { fields } = this.props;
        const arrayOrder = fields.map((block, i) => i);
        return arrayOrder.toJS ? arrayOrder.toJS() : arrayOrder;
    };

    allBlocksContentID = [];

    handleAddNewItemClick = ({ index, blockID }) => {
        const { fields, formProps } = this.props;
        let { arrayOrder } = formProps.formConfig;
        const contendID = Math.max.apply(null, this.allBlocksContentID) + 1;

        if (!arrayOrder.length) {
            arrayOrder = this.getInitialArrayOrder();
        }

        const orderIndex = arrayOrder.findIndex((item) => item === index);
        arrayOrder.splice(orderIndex + 1, 0, fields.length);

        this.updateArrayOrder(arrayOrder);
        fields.push(fromJS({
            block_id: blockID,
            content_id: contendID
        }));
    };

    handleRemoveItemClick = (index) => {
        const { formProps } = this.props;
        let { arrayOrder } = formProps.formConfig;
        const orderIndex = arrayOrder.findIndex((item) => item === index);

        if (!arrayOrder.length) {
            arrayOrder = this.getInitialArrayOrder();
        }

        arrayOrder.splice(orderIndex, 1);
        this.updateArrayOrder(arrayOrder);
        formProps.change(`blocks[${index}]`, null);
    };

    updateArrayOrder = (arrayOrder) => {
        const { formProps } = this.props;
        formProps.updateArrayOrder(arrayOrder);
    };

    isMultipleAllowed = (templateID, blockID) => {
        return templateID in this.multipleAllowed && blockID in this.multipleAllowed[templateID];
    };

    countBlocksByID = (blockID) => {
        const { fields } = this.props;
        return fields.getAll().reduce((count, block) => {
            if (block && block.get('block_id') === blockID) {
                count += 1;
                return count;
            }
            return count;
        }, 0);
    };

    renderRemoveButton = ({ index, blockID }) => {
        const { formProps: { isPreview } } = this.props;

        if (isPreview || this.countBlocksByID(blockID) === 1) {
            return null;
        }

        return (
            <RemoveItem
                text='Remove Block'
                className='template-block__remove'
                onClickHandler={() => {
                    this.handleRemoveItemClick(index);
                }}/>
        );
    };

    renderAddButton = ({ blockID, index }) => {
        const { formProps: { isPreview, templateID } } = this.props;

        if (isPreview || this.countBlocksByID(blockID) >= this.multipleAllowed[templateID][blockID]) {
            return null;
        }

        return (
            <div className='template-block__footer-add'>
                <AddNewItem
                    text='Add Block'
                    className='template-block__add'
                    onClickHandler={() => {
                        this.handleAddNewItemClick({ index, blockID });
                    }}/>
            </div>
        );
    };

    renderHeader = ({ index, blockID, isMultiple }) => {
        return (
            <div className='template-block__header'>
                {
                    isMultiple &&
                    this.renderRemoveButton({ index, blockID })
                }
            </div>
        );
    };

    renderFooter = ({ index, blockID, isMultiple }) => {
        return (
            <div className='template-block__footer'>
                {
                    isMultiple &&
                    this.renderAddButton({ index, blockID })
                }
            </div>
        );
    };

    renderBlocks = () => {
        const { formProps, fields, isOpened, getArrayFields } = this.props;
        let { arrayOrder } = formProps.formConfig;

        if (!arrayOrder || !arrayOrder.length) {
            arrayOrder = this.getInitialArrayOrder();
        }

        return arrayOrder.map((index) => {
            const block = fields.get(index);

            if (!block) {
                return null;
            }

            const arrayFieldName = `blocks[${index}]`;
            const blockJS = block.toJS ? block.toJS() : block;
            const { block_id: blockID, content_id: contenID } = blockJS;
            const isMultiple = formProps.isPreview ? false : this.isMultipleAllowed(formProps.templateID, blockID);
            const arrayFields = getArrayFields({ blockID, arrayFieldName });
            this.allBlocksContentID.push(contenID);
            return (
                <div
                    className='template-block'
                    key={contenID}>
                    {this.renderHeader({
                        index,
                        blockID,
                        isMultiple
                    })}
                    {renderForm({
                        formConfig: { fields: arrayFields },
                        initialValues: {},
                        formProps,
                        isOpened
                    })}
                    {this.renderFooter({
                        index,
                        blockID,
                        isMultiple
                    })}
                </div>
            );
        });
    };

    render() {
        return (
            <div className='template-content'>
                {this.renderBlocks()}
            </div>
        );
    }
}

TemplateBlockArray.propTypes = propTypes;

const TemplateBlockArrayField = ({ fieldConfig, formProps, isOpened }) => {
    return (
        <FieldArray
            {...fieldConfig}
            formProps={formProps}
            isOpened={isOpened}
            component={TemplateBlockArray}/>
    );
};

TemplateBlockArrayField.propTypes = {
    fieldConfig: PropTypes.shape({}),
    formProps: PropTypes.shape({}),
    isOpened: PropTypes.bool
};

export default TemplateBlockArrayField;
