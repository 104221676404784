import creditCardType from 'credit-card-type';
import moment from 'moment';
import React from 'react';
import { ReactComponent as IconAmericanExpress } from '../../../../../../assets/icons/icon-american-express.svg';
import { ReactComponent as IconMasterCard } from '../../../../../../assets/icons/icon-master-card.svg';
import { ReactComponent as IconNeutralCard } from '../../../../../../assets/icons/icon-neutral-card.svg';
import { ReactComponent as IconVisa } from '../../../../../../assets/icons/icon-visa.svg';
import { formatMoney } from '../../../../../../helpers/currency';
import Icon from '../../../../../common/Icon';
import Table from '../../../../../common/Table';

import { getTransactionDisplayStatus } from '../../../../../../helpers/transactions';

const CARD_ICONS = {
    'Visa': IconVisa,
    'MasterCard': IconMasterCard,
    'American Express': IconAmericanExpress,
};

const columns = [
    {
        Header: props => (
            <div>
                <div>Transaction</div>
                <div>Date</div>
            </div>
        ),
        headerClassName: 'simple-table__th',
        className: 'simple-table__td',
        id: 'purchase date',
        minWidth: 120,
        sortable: false,
        accessor: d => d.getIn(['created_at']),
        Cell: row => {
            return (
                <div className='simple-table__cell'>
                    <div className='simple-table__cell-title'>Purchase Date</div>
                    <div className='simple-table__cell-content'>
                        {row.value === null ? '—' : moment(row.value).format('MM/DD/Y')}
                    </div>
                </div>
            );
        },
    },
    {
        Header: props => (
            <div>
                <div>Purchase</div>
                <div>Price</div>
            </div>
        ),
        headerClassName: 'simple-table__th',
        className: 'simple-table__td',
        id: 'price',
        sortable: false,
        minWidth: 110,
        accessor: d => d.getIn(['price']),
        Cell: row => {
            return (
                <div className='simple-table__cell'>
                    <div className='simple-table__cell-title'>Price</div>
                    <div className='simple-table__cell-content'>
                        {(row.value === '0.00') ? <div>&#8211;</div> : formatMoney(row.value)}
                    </div>
                </div>
            );
        },
    },
    {
        Header: 'Purchase',
        headerClassName: 'simple-table__th',
        className: 'simple-table__td',
        id: 'purchased',
        sortable: false,
        minWidth: 170,
        accessor: d => d.getIn(['type']),
        Cell: row => {
            return (
                <div className='simple-table__cell'>
                    <div className='simple-table__cell-title'>Purchased</div>
                    <div className='simple-table__cell-content'>{row.value}</div>
                </div>
            );
        },
    },
    {
        Header: 'Dog(s)',
        headerClassName: 'simple-table__th',
        className: 'simple-table__td',
        id: 'dogs',
        sortable: false,
        width: 100,
        accessor: d => d.getIn(['dogs']),
        Cell: row => {
            return (
                <div className='simple-table__cell'>
                    <div className='simple-table__cell-title'>Dogs</div>
                    <div className='simple-table__cell-content'>{(row.value && (row.value.size > 0)) ? row.value.join(', ') : <div>&#8211;</div>}</div>
                </div>
            );
        },
    },
    {
        Header: props => (
            <div>
                <div>Payment</div>
                <div>Method</div>
            </div>
        ),
        headerClassName: 'simple-table__th',
        className: 'simple-table__td',
        id: 'payment-method',
        sortable: false,
        minWidth: 170,
        accessor: d => d.toJS(),
        Cell: row => {
            // eslint-disable-next-line consistent-return
            const getPaymentTypeText = model => {
                if (model === 'packagepayment') return 'Package Credit';
                if (model === 'trainingpayment') return 'Training Program Credit';
                if (model === 'packagerefund') return 'Package Refund';
                if (model === 'trainingrefund') return 'Training Program Refund';
            };
            const getPayment = () => {
                const { card, model_type } = row.value;
                if (card) {
                    const cardType = creditCardType(card.first6)[0].niceType;
                    return (
                        <div className='payment-history__card'>
                            <Icon
                                glyph={CARD_ICONS[cardType] || IconNeutralCard}
                                className='payment-history__icon-card'/>
                            {card.last4}
                        </div>
                    );
                }
                return getPaymentTypeText(model_type);
            };
            return (
                <div className='simple-table__cell'>
                    <div className='simple-table__cell-title'>Payment Method</div>
                    <div className='simple-table__cell-content'>{getPayment()}</div>
                </div>
            );
        },
    },
    {
        Header: props => (
            <div>
                <div>Charge or</div>
                <div>Reversal</div>
                <div>Amount</div>
            </div>
        ),
        headerClassName: 'simple-table__th',
        className: 'simple-table__td',
        id: 'total-paid',
        sortable: false,
        minWidth: 110,
        accessor: d => d.toJS(),
        Cell: row => {
            const paymentData = row.value;
            const status = paymentData.status_detail;
            const modelType = paymentData.model_type;
            let totalPaid;
            if (status === 'Authorized') totalPaid = paymentData.amount_authorized;
            if (status === 'Paid') totalPaid = paymentData.amount_captured;
            if (modelType === 'striperefund' ||
                modelType === 'packagerefund' ||
                modelType === 'trainingrefund') {
                totalPaid = paymentData.amount_refunded;
            }
            return (
                <div className='simple-table__cell'>
                    <div className='simple-table__cell-title'>Total Paid</div>
                    <div className='simple-table__cell-content'>
                        { (totalPaid && paymentData.card) ? formatMoney(totalPaid) : <div>&#8211;</div> }
                    </div>
                </div>
            );
        },
    },
    {
        Header: 'Status',
        headerClassName: 'simple-table__th',
        className: 'simple-table__td',
        id: 'status',
        sortable: false,
        width: 100,
        accessor: d => d.toJS(),
        Cell: row => {
            return (
                <div className='simple-table__cell'>
                    <div className='simple-table__cell-title'>Total Paid</div>
                    <div className='simple-table__cell-content'>
                        {getTransactionDisplayStatus(row.value)}
                    </div>
                </div>
            );
        },
    },
];

class PaymentHistoryTable extends Table {
    render() {
        return (
            <div className={'simple-table'}>
                {this.renderTable({ columns })}
            </div>
        );
    }
}

export default PaymentHistoryTable;
