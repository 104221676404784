const validate = values => {
    const errors = {};
    Object.keys(values.toJS()).forEach((value) => {
        errors[value] = {};

        if (!values.getIn([`${value}`, 'name'])) {
            errors[value].name = 'Name is Required.';
        }

        if (!values.getIn([`${value}`, 'address1'])) {
            errors[value].address1 = 'Location is Required.';
        } else if (values.getIn([`${value}`, 'address1']).length > 254) {
            errors[value].address1 = 'It\'s not allowed to enter more than 254 symbols into this field';
        }

        if (values.getIn([`${value}`, 'address2']) &&
            values.getIn([`${value}`, 'address2']) !== null &&
            values.getIn([`${value}`, 'address2']).length > 254) {
            errors[value].address2 = 'It\'s not allowed to enter more than 254 symbols into this field';
        }

        if (!values.getIn([`${value}`, 'city'])) {
            errors[value].city = 'City is Required.';
        } else if (values.getIn([`${value}`, 'city']).length > 254) {
            errors[value].city = 'It\'s not allowed to enter more than 254 symbols into this field';
        }

        if (!values.getIn([`${value}`, 'state'])) {
            errors[value].state = 'State is Required.';
        } else if (values.getIn([`${value}`, 'state']).length > 2) {
            errors[value].state = 'State must be 2 characters or less.';
        }

        if (!values.getIn([`${value}`, 'zip_code'])) {
            errors[value].zip_code = 'Zip Code is Required.';
        } else if (values.getIn([`${value}`, 'zip_code']).length > 10) {
            errors[value].zip_code = 'Zip Code must be 10 characters or less.';
        }

        if (values.getIn([`${value}`, 'location_notes']) && values.getIn([`${value}`, 'location_notes']).length > 254) {
            errors[value].location_notes = 'It\'s not allowed to enter more than 254 symbols into this field';
        }
    });
    return errors;
};

export default validate;
