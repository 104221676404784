import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCurrentCustomerFromDogsList, setCurrentDogFromDogsList } from '../../../actions/dashboard/employeeActions';
import { openSearchResultsPage, updateSearchInputValue } from '../../../actions/dashboard/searchBarActions';
import { ReactComponent as IconClose } from '../../../assets/icons/icon-remove.svg';
import { ReactComponent as IconSearch } from '../../../assets/icons/icon-search.svg';
import { globalSearch } from '../../../services/searchBar';
import Icon from '../../common/Icon';
import Loader from '../Loader/Loader';
import SearchTopTable from './SearchTopTable';
import { prettifyPhone } from '../../../helpers/normalize';

const normalizePhones = data => {
    const dataJS = data.toJS();
    dataJS.map(item => {
        item.phone_number = prettifyPhone(item.phone_number);
        return item;
    });
    return fromJS(dataJS);
};

class SearchBar extends React.PureComponent {
    static propTypes = {
        handleSearch: PropTypes.func,
        searchData: PropTypes.shape([]),
        handleSettingCurrentDog: PropTypes.func,
        handleSettingCurrentCustomer: PropTypes.func,
        handleSearchPage: PropTypes.func,
        handleClose: PropTypes.func,
        handleSearchBarValue: PropTypes.func,
    };

    state = {
        delayed: false,
        focused: false,
        mouseOver: false,
    };

    componentDidUpdate() {
        const { handleSearch, searchData } = this.props;

        if (!searchData.get('isLoading') && this.delayedRequest !== null && this.delayedRequest !== undefined) {
            handleSearch(this.delayedRequest);
            this.delayedRequest = null;
            this.setState({
                delayed: false,
            });
        }
    }

    clearSearchInput = () => {
        const { handleSearchBarValue } = this.props;
        if (this.searchInput) {
            this.searchInput.value = '';
            this.setState({
                delayed: false,
                focused: false,
                mouseOver: false,
            });
            handleSearchBarValue();
        }
    };

    closeTopTable = () => {
        this.setState({
            mouseOver: false,
        });
    };

    handleSearchChange = event => {
        const { handleSearch, handleSearchBarValue, searchData } = this.props;
        const value = event.target.value.trim();
        if (value.length > 0 && searchData.get('searchString') !== value) {
            this.setState({
                delayed: true,
            });
            if (this.searchTimeout !== null && this.searchTimeout !== undefined) {
                clearTimeout(this.searchTimeout);
            }
            if (searchData.get('isLoading')) {
                this.delayedRequest = value;
            } else {
                this.searchTimeout = setTimeout(() => {
                    this.setState({
                        delayed: false,
                    });
                    handleSearch(value);
                }, 500);
            }
        } else if (this.searchTimeout !== null && this.searchTimeout !== undefined) {
            this.setState({
                delayed: false,
            });
            clearTimeout(this.searchTimeout);
        }
        handleSearchBarValue(value);
    };

    handleSearchPage = () => {
        const { handleSearchPage } = this.props;
        console.log('Open search page!');
        handleSearchPage();
        this.closeTopTable();
    };

    renderTopResults() {
        const { searchData, handleSettingCurrentDog, handleSettingCurrentCustomer } = this.props;
        const { delayed, focused, mouseOver } = this.state;

        const tableVisible = searchData.get('topResults').size > 0 && searchData.get('searchString') !== null;
        const noMatchesVisible = !searchData.get('isLoading') && !delayed;

        const topResultsContainerClassNames = classNames({
            'search-bar__top-results': true,
            'search-bar__top-results_empty': !tableVisible && noMatchesVisible,
        });

        if ((focused || mouseOver) && searchData.get('searchInputValue').length > 0 && !searchData.get('isSearchPageOpened')) {
            return (
                <div
                    className={topResultsContainerClassNames}
                    onMouseEnter={() => {
                        this.setState({
                            mouseOver: true,
                        });
                    }}
                    onMouseLeave={event => {
                        this.setState({
                            mouseOver: false,
                        });
                    }}>
                    {tableVisible
                        ? <SearchTopTable
                            classNameModifier='search-bar__top-results-table'
                            isManual
                            isSortable={false}
                            data={normalizePhones(searchData.get('topResults'))}
                            searchString={searchData.get('searchInputValue')}
                            onOwnerClick={handleSettingCurrentCustomer}
                            onDogClick={handleSettingCurrentDog}
                            hideTable={this.closeTopTable}
                            loading={searchData.get('isLoading') || delayed}/>
                        : noMatchesVisible
                          ? <div className='search-bar__top-results-no-matches'>No matches found</div>
                          : null}
                </div>
            );
        }

        return null;
    }

    render() {
        const { searchData, handleClose } = this.props;
        return (
            <div className='search-bar'>
                <Icon glyph={IconSearch} className='search-bar__search-icon_mobile icon_search-small' />
                <input
                    ref={element => {
                        this.searchInput = element;
                    }}
                    className='search-bar__input'
                    type='text'
                    placeholder='Search by Dog, Owner, Phone Number, or Email...'
                    onChange={this.handleSearchChange}
                    onKeyPress={event => {
                        const { value } = event.target;
                        if (event.key === 'Enter' && value.length > 0) {
                            this.handleSearchPage();
                        }
                    }}
                    onFocus={() => {
                        this.setState({
                            focused: true,
                        });
                    }}
                    onBlur={() => {
                        this.setState({
                            focused: false,
                        });
                    }}/>
                <Loader
                    isVisible={searchData.get('isLoading')}
                    modifierClassName='search-bar__icon-loading'/>
                <button className='search-bar__search-icon' onClick={this.handleSearchPage}>
                    <Icon glyph={IconSearch} className='icon_search' />
                </button>
                <button
                    className='search-bar__remove-icon_mobile'
                    onClick={() => {
                        this.clearSearchInput();
                        handleClose();
                    }
                }>
                    <Icon glyph={IconClose} className='icon_remove-small' />
                </button>
                {this.renderTopResults()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        searchData: state.getIn(['search']),
    };
};

const mapDispatchToProps = dispatch => ({
    handleSearch: bindActionCreators(globalSearch, dispatch),
    handleSettingCurrentDog: bindActionCreators(setCurrentDogFromDogsList, dispatch),
    handleSettingCurrentCustomer: bindActionCreators(setCurrentCustomerFromDogsList, dispatch),
    handleSearchBarValue: bindActionCreators(updateSearchInputValue, dispatch),
    handleSearchPage: bindActionCreators(openSearchResultsPage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
