import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../common/Icon';
import { ReactComponent as IconBack } from '../../../assets/icons/icon-chevron-back.svg';

const GoBackButton = ({ text, onClickHandler, className = '' }) => {
    return (
        <div className={'go-back–button ' + className} onClick={onClickHandler}>
            <div className='go-back–button__icon'>
                <Icon glyph={IconBack} className='icon_back'/>
            </div>
            <div className='go-back-button__text'>{text}</div>
        </div>
    );
};

GoBackButton.propTypes = {
    text: PropTypes.string.isRequired,
    onClickHandler: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default GoBackButton;
