export const employeeCategoriesList = [
    {
        label: 'Operational',
        value: 'operational',
    },
    {
        label: 'Marketing',
        value: 'marketing',
    },
];

export const trainerReportCategoriesList = [
    {
        label: 'Operational',
        value: 'operational',
    },
    {
        label: 'Financial',
        value: 'financial',
    },
];

export const adminCategoriesList = [
    {
        label: 'Operational',
        value: 'operational',
    },
    {
        label: 'Marketing',
        value: 'marketing',
    },
    {
        label: 'Financial',
        value: 'financial',
    },
];

export const superAdminReportList = {
    'operational': [
        {
            label: 'Pick up / Drop offs',
            value: 'pickup-drop-off',
        },
        {
            label: 'Notes',
            value: 'incident',
        },
        {
            label: 'Expired Vaccination',
            value: 'expired-vaccination',
        },
        {
            label: 'Packages & Programs',
            value: 'packages-programs',
        },
    ],
    'marketing': [
        {
            label: 'Dog Attendance Statistics',
            value: 'dog_attendance_statistics',
        },
        {
            label: 'New Customer Statistics',
            value: 'new_customer_statistics',
        },
        {
            label: 'Attendance By Class',
            value: 'attendance-by-zone',
        },
        {
            label: 'Rebooking Percentage',
            value: 'rebooking-percentage',
        },
        {
            label: 'Customer Stats And Demographics',
            value: 'customer-stats-and-demographics',
        },
        {
            label: 'Emails Delivery',
            value: 'emails-report',
        },
    ],
    'financial': [
        {
            label: 'Detailed Sales',
            value: 'detailed-sales',
        },
        {
            label: 'Trainer\'s Commission',
            value: 'trainers-commission',
        },
        {
            label: 'Trainer Invoice',
            value: 'trainers-invoice',
        },
        {
            label: 'VIPs Customers',
            value: 'vip-customers',
        },
        {
            label: 'VIPs Dogs',
            value: 'vip-dogs',
        },
    ],
};

export const adminReportList = {
    'operational': [
        {
            label: 'Pick up / Drop offs',
            value: 'pickup-drop-off',
        },
        {
            label: 'Notes',
            value: 'incident',
        },
        {
            label: 'Expired Vaccination',
            value: 'expired-vaccination',
        },
        {
            label: 'Packages & Programs',
            value: 'packages-programs',
        },
    ],
    'marketing': [
        {
            label: 'Dog Attendance Statistics',
            value: 'dog_attendance_statistics',
        },
        {
            label: 'New Customer Statistics',
            value: 'new_customer_statistics',
        },
        {
            label: 'Attendance By Zone',
            value: 'attendance-by-zone',
        },
        {
            label: 'Rebooking Percentage',
            value: 'rebooking-percentage',
        },
        {
            label: 'Customer Stats And Demographics',
            value: 'customer-stats-and-demographics',
        },
        {
            label: 'Emails Delivery',
            value: 'emails-report',
        },
    ],
    'financial': [
        {
            label: 'Detailed Sales',
            value: 'detailed-sales',
        },
        {
            label: 'VIPs Customers',
            value: 'vip-customers',
        },
        {
            label: 'VIPs Dogs',
            value: 'vip-dogs',
        },
    ],
};

export const trainerReportList = {
    'operational': [
        {
            label: 'Pick up / Drop offs',
            value: 'pickup-drop-off',
        },
    ],
    'financial': [
        {
            label: 'Trainer\'s Commission',
            value: 'commission',
        },
        {
            label: 'Trainer Invoice',
            value: 'trainers-invoice',
        },
    ],
};

export const filterFields = {
    'pickup-drop-off': ['date'],
    'dog-attendance': ['date_range'],
    'vip-dogs': ['date_range'],
    'vip-customers': ['date_range'],
    'trainers-commission': ['date_range'],
    'trainers-invoice': ['date_range', 'trainer'],
    'detailed-sales': ['date_range'],
    'new_customer_statistics': ['date_range'],
    'attendance-by-zone': ['date_range', 'attendance_by_zone_filter'],
    'dog_attendance_statistics': ['date_range'],
    'incident': ['date_range'],
    'expired-vaccination': ['date_range', 'enable_future'],
    'packages-programs': ['date_range'],
    'emails-report': ['date_range'],
    'commission': ['date_range'],
};

export const customReportTargetValues = {
    'vip-customers': 0,
    'vip-dogs': 2,
    'new_customer_statistics': 3,
    'packages-programs': 4,
    'incident': 5,
    'expired-vaccination': 6,
    'pickup-drop-off': 7,
    'rebooking-percentage': 8,
};
