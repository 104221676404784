import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ErrorHandlerActions from '../../../actions/errorHandlerActions';
import { ReactComponent as IconClose } from '../../../assets/icons/icon-remove.svg';
import { ReactComponent as IconCheck } from '../../../assets/icons/icon-checkmark.svg';
import Icon from '../Icon';

// eslint-disable-next-line react/prefer-stateless-function
class ErrorHandler extends React.PureComponent {
    static propTypes = {
        errorType: PropTypes.string,
        errorText: PropTypes.string,
        onClose: PropTypes.func,
    };

    render() {
        const { errorText, errorType, onClose } = this.props;
        const errorHandlerClassNames = classNames({
            'error-handler': true,
            'error-handler_show': errorType === 'globalError' || errorType === 'globalSuccess'
        });
        const errorHandlerOverlayClassNames = classNames({
            'error-handler__icon-overlay': true,
            'error-handler__icon-overlay_success': errorType === 'globalSuccess'
        });

        if (errorHandlerClassNames.includes('error-handler_show')) {
            setTimeout(() => {
                onClose();
            }, 5000);
        }

        return ReactDOM.createPortal(
            <div className={errorHandlerClassNames}>
                <div className='error-handler_content'>
                    <div className='error-handler__text'>
                        <div className={errorHandlerOverlayClassNames}>
                            {
                                errorType === 'globalSuccess'
                                    ? <Icon glyph={IconCheck} className='icon_error-small' />
                                    : <Icon glyph={IconClose} className='icon_error-small' />
                            }
                        </div>
                        {errorText}
                    </div>
                    <div className='error-handler__close' onClick={onClose}>
                        <Icon glyph={IconClose} className='icon_remove-small' />
                    </div>
                </div>
            </div>, document.getElementsByClassName('app')[0]
        );
    }
}

const mapStateToProps = state => {
    return {
        errorText: state.getIn(['errorHandler', 'errorText']),
        errorType: state.getIn(['errorHandler', 'errorType']),
    };
};

const mapDispatchToProps = (dispatch) => ({
    onClose: bindActionCreators(ErrorHandlerActions.clearError, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler);
