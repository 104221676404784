import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default function Icon({ className, glyph: Glyph, size = 'm' }) {
    const IconClassNames = classNames({
        [className]: className,
        'icon': true,
        'icon_xs': size === 'xs',
        'icon_sm': size === 'sm',
        'icon_md': size === 'md',
        'icon_lg': size === 'lg',
        'icon_xl': size === 'xl'
    });

    return (
        <Glyph className={IconClassNames} />
    );
}

Icon.propTypes = {
    className: PropTypes.string,
    glyph: PropTypes.string.isRequired,
    size: PropTypes.oneOf([
        'xs', 'sm', 'md', 'lg', 'xl'
    ])
};
