import * as axios from 'axios';
import { fromJS } from 'immutable';
import { sendPhoto } from './file';
import { prependDirectories } from '../actions/dashboard/directoryActions';
import { getDirectoryList } from './directory';

export const setInstructorEmployeePhoto = ({ photo, id, successCallback }) => {
    return (dispatch) => {
        dispatch(sendPhoto({
            photo,
            url: `/v1/super-admins/directory/${id}/photo/`,
            successCallback
        }));
    };
};

export const postInstructorEmployeeData = (dataChunks) => {
    return (dispatch) => {
        const dataChunksJS = dataChunks.get('sendData');
        const photoFile = dataChunks.get('profilePhoto');
        console.log(dataChunksJS);
        return new Promise((resolve, reject) => {
            axios.post('/v1/super-admins/directory/', dataChunksJS)
                    .then(response => {
                        console.log('Server response: ', response);
                        if (photoFile !== null) {
                            dispatch(setInstructorEmployeePhoto({
                                photo: photoFile,
                                id: response.data.id,
                                successCallback: (photoResponse) => {
                                    const combinedData = Object.assign({}, response.data, photoResponse.data);
                                    dispatch(prependDirectories(fromJS(combinedData)));
                                    resolve([response, photoResponse]);
                                }
                            }));
                        } else {
                            resolve(response);
                            dispatch(prependDirectories(fromJS(response.data)));
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                        if (err.response) {
                            if (err.response.status !== 200) {
                                console.log('Unexpected error code from the API server: ', err.response.status);
                            }
                        }
                        reject(err);
                    });
        });
    };
};

export const updateInstructorEmployeeData = ({ url, dataChunks }) => {
    return async (dispatch) => {
        const dataChunksJS = dataChunks.get('sendData');
        const photoFile = dataChunks.get('profilePhoto');
        const employeeId = dataChunks.get('employeeId');
        const dataUpdatePromise = await axios.patch(url, dataChunksJS)
                    .then(response => {
                        console.log('Server response: ', response);
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                        if (err.response) {
                            if (err.response.status !== 200) {
                                console.log('Unexpected error code from the API server: ', err.response.status);
                            }
                        }
                    });

        let photoUpdatePromise;
        if (photoFile !== null) {
            photoUpdatePromise = new Promise((resolve) => {
                dispatch(setInstructorEmployeePhoto({
                    photo: photoFile,
                    id: employeeId,
                    successCallback: (response) => {
                        resolve(response);
                    }
                }));
            });
        }

        return Promise.all([dataUpdatePromise, photoUpdatePromise]).then((response) => {
            console.log(response);
            dispatch(getDirectoryList());
        });
    };
};
