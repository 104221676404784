import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { ReactComponent as LogoSVG } from '../../../assets/imgs/logo.svg';

const Logo = ({ logoTo, logoClassName }) => {
    return (
        <Link to={logoTo} className={logoClassName}>
            <LogoSVG className='logo__img' />
        </Link>
    );
};

Logo.propTypes = {
    logoClassName: PropTypes.string.isRequired,
    logoTo: PropTypes.string
};

export default Logo;
