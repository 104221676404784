const validate = data => {
    const errors = {};
    const tag = data.get('tag');
    let notes = data.get('notes') || '';
    notes = notes.trim();

    if (!notes) {
        errors.notes = 'Your note is empty.';
    } else if (notes.length > 2000) {
        errors.notes = 'Your note is too long.';
    }

    if (!tag) {
        errors.tag = 'Please, select a tag.';
    }

    return errors;
};

export default validate;
