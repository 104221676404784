import { fromJS, Map } from 'immutable';
import moment from 'moment';
import { push } from 'react-router-redux';
import { createAction } from 'redux-actions';
import { change, reset } from 'redux-form';
import { getUserNameSpace, isTrainer } from '../helpers/userRoles';
import { DEFAULT_DATE_FORMAT } from '../reducers/dashboardPageReducer';
import { getCustomerById } from '../services/customers';
import { getRole } from '../services/oauth';
import { getOptions } from '../services/options';
import * as MiscTypes from '../types/miscTypes';
import { setCurrentImmunizationExpirationDates } from './dashboard/immunizationExpirationDatesActions';
import { setCurrentInstructorEmployee } from './dashboard/instructorEmployeeActions';

export const setVetInfoModalOpened = createAction(MiscTypes.SET_VETINFO_MODAL_OPENED);
export const setVetInfoModalClosed = createAction(MiscTypes.SET_VETINFO_MODAL_CLOSED);

export const setDeleteCustomerAgreementModalDialogOpened =
    createAction(MiscTypes.SET_DELETE_CUSTOMER_AGREEMENT_MODAL_OPENED);
export const setDeleteCustomerAgreementModalDialogClosed =
    createAction(MiscTypes.SET_DELETE_CUSTOMER_AGREEMENT_MODAL_CLOSED);

export const setChangeNameModalOpened = createAction(MiscTypes.SET_CHANGE_NAME_MODAL_OPENED);
export const closeChangeNameModal = createAction(MiscTypes.SET_CHANGE_NAME_MODAL_CLOSED);
export const setChangeNameModalClosed = () => {
    return dispatch => {
        dispatch(closeChangeNameModal());
        dispatch(reset('ChangeNameModalDialogForm'));
    };
};
export const setDefaultChangeNameData = createAction(MiscTypes.SET_CHANGE_NAME_DEFAULT_DATA);

export const setResetPasswordModalOpened = createAction(MiscTypes.SET_RESET_PASSWORD_MODAL_OPENED);
export const setResetPasswordModalClosed = createAction(MiscTypes.SET_RESET_PASSWORD_MODAL_CLOSED);

export const setDeleteCustomerModalOpened = createAction(MiscTypes.SET_DELETE_CUSTOMER_MODAL_OPENED);
export const setDeleteCustomerModalClosed = createAction(MiscTypes.SET_DELETE_CUSTOMER_MODAL_CLOSED);

export const setContactInfoModalOpened = createAction(MiscTypes.SET_CONTACT_INFO_MODAL_OPENED);
export const closeContactInfoModal = createAction(MiscTypes.SET_CONTACT_INFO_MODAL_CLOSED);
export const setContactInfoModalClosed = () => {
    return dispatch => {
        dispatch(closeContactInfoModal());
        dispatch(reset('ContactInfoModalDialogForm'));
    };
};
export const setDefaultContactInfoData = createAction(MiscTypes.SET_CONTACT_INFO_DEFAULT_DATA);
export const setContactInfoModalLoading = createAction(MiscTypes.SET_CONTACT_INFO_MODAL_LOADING);
export const setContactInfoModalLoaded = createAction(MiscTypes.SET_CONTACT_INFO_MODAL_LOADED);

export const setAdditionalOwnersModalOpened = createAction(MiscTypes.SET_ADDITIONAL_OWNERS_MODAL_OPENED);
export const closeAdditionalOwnersModal = createAction(MiscTypes.SET_ADDITIONAL_OWNERS_MODAL_CLOSED);
export const setAdditionalOwnersModalClosed = () => {
    return dispatch => {
        dispatch(closeAdditionalOwnersModal());
        dispatch(reset('AdditionalOwnersModalDialogForm'));
    };
};
export const setAdditionalOwnersDefaultData = createAction(MiscTypes.SET_ADDITIONAL_OWNERS_DEFAULT_DATA);

export const setEmergencyContactModalOpened = createAction(MiscTypes.SET_EMERGENCY_CONTACT_MODAL_OPENED);
export const closeEmergencyContactModal = createAction(MiscTypes.SET_EMERGENCY_CONTACT_MODAL_CLOSED);
export const setEmergencyContactModalClosed = () => {
    return dispatch => {
        dispatch(closeEmergencyContactModal());
        dispatch(reset('EmergencyContactModalDialogForm'));
    };
};
export const setEmergencyContactDefaultData = createAction(MiscTypes.SET_EMERGENCY_CONTACT_DEFAULT_DATA);

export const setDogCreationModalOpened = createAction(MiscTypes.SET_DOG_CREATION_MODAL_OPENED);
export const closeDogCreationModal = createAction(MiscTypes.SET_DOG_CREATION_MODAL_CLOSED);
export const setDefaultDogCreationData = createAction(MiscTypes.SET_DOG_CREATION_DEFAULT_DATA);
export const setDogCreationLoading = createAction(MiscTypes.SET_DOG_CREATION_LOADING);
export const setDogCreationLoaded = createAction(MiscTypes.SET_DOG_CREATION_LOADED);
export const setDogCreationModalClosed = () => {
    return dispatch => {
        dispatch(closeDogCreationModal());
        dispatch(reset('DogCreationModalDialogForm'));
    };
};

export const setPDLocationsEditModalOpened = createAction(MiscTypes.SET_PD_LOCATIONS_EDIT_MODAL_OPENED);
export const closePDLocationsEditModal = createAction(MiscTypes.SET_PD_LOCATIONS_EDIT_MODAL_CLOSED);
export const setPDLocationsEditModalClosed = () => {
    return dispatch => {
        dispatch(closePDLocationsEditModal());
        dispatch(reset('PickupDropoffEditModalDialogForm'));
    };
};
export const setPDLocationsEditDefaultData = createAction(MiscTypes.SET_PD_LOCATIONS_EDIT_DEFAULT_DATA);

export const setPDLocationsAddModalOpened = createAction(MiscTypes.SET_PD_LOCATIONS_ADD_MODAL_OPENED);
export const closePDLocationsAddModal = createAction(MiscTypes.SET_PD_LOCATIONS_ADD_MODAL_CLOSED);
export const setPDLocationsAddModalClosed = () => {
    return dispatch => {
        dispatch(closePDLocationsAddModal());
        dispatch(reset('PickupDropoffAddModalDialogForm'));
    };
};

export const setAddCreditCardModalOpened = createAction(MiscTypes.SET_ADD_CREDIT_CARD_MODAL_OPENED);
export const closeAddCreditCard = createAction(MiscTypes.SET_ADD_CREDIT_CARD_MODAL_CLOSED);
export const setAddCreditCardModalClosed = () => {
    return dispatch => {
        dispatch(closeAddCreditCard());
        dispatch(reset('AddCreditCardModalDialogForm'));
    };
};
export const setAddCreditCardModalLoading = createAction(MiscTypes.SET_ADD_CREDIT_CARD_MODAL_LOADING);
export const setAddCreditCardModalLoaded = createAction(MiscTypes.SET_ADD_CREDIT_CARD_MODAL_LOADED);

export const setEditUpcomingClassModalOpened = createAction(MiscTypes.SET_EDIT_UPCOMING_CLASS_MODAL_OPENED);
export const setEditDogsUpcomingClassModalOpened = data => {
    console.log('setEditDogsUpcomingClassModalOpened data: ', data);
    return dispatch => {
        if (!Map.isMap(data)) {
            data = fromJS(data);
        }
        dispatch(getCustomerById(data.getIn(['owner_detail', 'id'])));
        dispatch(setEditUpcomingClassModalOpened(data));
    };
};
export const closeEditUpcomingClassModal = createAction(MiscTypes.SET_EDIT_UPCOMING_CLASS_MODAL_CLOSED);
export const setEditUpcomingClassModalClosed = () => {
    return dispatch => {
        dispatch(closeEditUpcomingClassModal());
        dispatch(reset('EditUpcomingClassModalDialogForm'));
    };
};
export const setEditUpcomingClassDefaultData = createAction(MiscTypes.SET_EDIT_UPCOMING_CLASS_DEFAULT_DATA);

export const collapseCustomersUpcomingClasses = createAction(MiscTypes.COLLAPSE_CUSTOMERS_UPCOMING_CLASSES);
export const collapseCustomersPreviousClasses = createAction(MiscTypes.COLLAPSE_CUSTOMERS_PREVIOUS_CLASSES);

export const collapseDogsUpcomingClasses = createAction(MiscTypes.COLLAPSE_DOGS_UPCOMING_CLASSES);
export const collapseDogsPreviousClasses = createAction(MiscTypes.COLLAPSE_DOGS_PREVIOUS_CLASSES);

export const collapseCustomersUpcomingTrainings = createAction(MiscTypes.COLLAPSE_CUSTOMERS_UPCOMING_TRAININGS);
export const collapseCustomersPreviousTrainings = createAction(MiscTypes.COLLAPSE_CUSTOMERS_PREVIOUS_TRAININGS);

export const collapseDogsUpcomingTrainings = createAction(MiscTypes.COLLAPSE_DOGS_UPCOMING_TRAININGS);
export const collapseDogsPreviousTrainings = createAction(MiscTypes.COLLAPSE_DOGS_PREVIOUS_TRAININGS);

export const setVeterinarianOptions = createAction(MiscTypes.SET_VETERINARIAN_OPTIONS);
export const getVeterinarianOptions = (fieldCB) => {
    return getOptions(
        {
            url: `/v1/${getUserNameSpace('veterinarians')}/veterinarians/?page_size=0`,
            optionContext: 'veterinarian',
            actionCB: setVeterinarianOptions,
            fieldCB,
        },
    );
};

export const setImmunizationOptions = createAction(MiscTypes.SET_IMMUNIZATION_OPTIONS);
export const getImmunizationOptions = () => getOptions(
    {
        url: '/v1/dogs/immunization-types-values/',
        optionContext: 'immunization',
        actionCB: setImmunizationOptions
    }
);

export const getClassOptions = (fieldCB, query) => getOptions(
    {
        url: `/v1/${getUserNameSpace('classes')}/classes/?is_archived=false&page_size=0&name=${query}`,
        optionContext: 'event',
        fieldCB,
    },
);

export const getUniqueClassOptions = (fieldCB, query) => getOptions(
    {
        url: `/v1/${getUserNameSpace('classes')}/classes/?is_archived=false&page_size=0&name=${query}`,
        optionContext: 'uniqueEvent',
        fieldCB,
    },
);

export const getCalendarClassOptions = (fieldCB, query) => {
    let extraQuery = '';

    if (isTrainer()) {
        extraQuery = 'is_archived=false&is_scheduled=true&';
    }

    return getOptions(
        {
            url: `/v1/${getUserNameSpace('classes')}/classes/?${extraQuery}name=${query}`,
            optionContext: 'calendarEvent',
            fieldCB,
        },
    );
};

export const setClassOptions = createAction(MiscTypes.SET_CLASS_OPTIONS);
export const getPackagesClassOptions = (query) => getOptions(
    {
        url: `/v1/${getUserNameSpace('classes')}/classes/?is_archived=false&page_size=0`,
        optionContext: 'packagesClasses',
        actionCB: setClassOptions,
    },
);

export const getPurchasedTrainingProgramOptions = (fieldCB, query) => getOptions(
    {
        url: `/v1/${getUserNameSpace(
            'training-programs')}/training-programs/?name__icontains=${query}&is_purchased=true&exclude_expired=true`,
        optionContext: 'event',
        fieldCB,
    },
);

export const getTrainerOptions = (fieldCB, query) => getOptions(
    {
        url: `/v1/${getUserNameSpace('directory')}/directory/?page_size=0&type=trainer&name=${query}`,
        optionContext: 'user',
        fieldCB,
    },
);

export const getDiscountOptions = (fieldCB) => getOptions(
    {
        url: `/v1/${getUserNameSpace(getRole())}/discount-values/`,
        optionContext: 'discount',
        fieldCB,
    },
);

export const getCustomerOptions = (fieldCB, query) => getOptions(
    {
        url: `/v1/${getUserNameSpace('directory')}/directory/?page_size=0&type=trainer&name=${query}`,
        optionContext: 'user',
        fieldCB,
    },
);

export const getCalendarTrainerOptions = (fieldCB, query) => getOptions(
    {
        url: `/v1/${getUserNameSpace('directory')}/directory/?type=trainer&name=${query}`,
        optionContext: 'calendarTrainer',
        fieldCB,
    },
);

export const getCalendarLocationOptions = (fieldCB) => getOptions(
    {
        url: '/v1/classes/location-values/',
        optionContext: 'calendarLocation',
        fieldCB,
    },
);

export const getTrainerByProgramOptions = (fieldCB, query, programId) => getOptions(
    {
        url: `/v1/${getUserNameSpace(
            'training-programs.trainers')}/training-programs/${programId}/trainers/?name=${query}`,
        optionContext: 'user',
        fieldCB,
    },
);

export const getClassEventDogOptions = (fieldCB, query, currentEvent) => getOptions(
    {
        url: `/v1/${getUserNameSpace(
            'class-occurrences.dogs')}/class-occurrences/${currentEvent.event.id}/dogs/?name=${query}`,
        optionContext: 'classDog',
        extra: currentEvent,
        fieldCB,
    },
);

const buildTrainingEventDogOptionsURL = (query, currentEventWithFormValuesJS) => {
    const programId = currentEventWithFormValuesJS.formValues.program.value;
    const trainerId = currentEventWithFormValuesJS.formValues.trainer.value;
    const expiresAt = moment().format(DEFAULT_DATE_FORMAT);
    return `/v1/${getUserNameSpace(
        'purchased-training-programs')}/purchased-training-programs/?program=${programId}&trainer=${trainerId}&dog_name=${query}&expires_at__gte=${expiresAt}`; // eslint-disable-line max-len
};

export const getTrainingEventDogOptions = (fieldCB, query = '', currentEventWithFormValuesJS) => getOptions(
    {
        url: buildTrainingEventDogOptionsURL(query, currentEventWithFormValuesJS),
        optionContext: 'trainingDog',
        extra: currentEventWithFormValuesJS,
        fieldCB,
    },
);

export const setLocationOptions = createAction(MiscTypes.SET_LOCATION_OPTIONS);
export const getLocationOptions = (fieldCB) => getOptions(
    {
        url: '/v1/classes/location-values/',
        optionContext: 'location',
        fieldCB,
        actionCB: setLocationOptions,
    },
);

export const setIconOptions = createAction(MiscTypes.SET_ICON_OPTIONS);
export const getIconOptions = (iconType, fieldCB) => getOptions(
    {
        url: '/v1/dogs/report-card-icon-labels-values/',
        optionContext: 'icons',
        actionCB: setIconOptions,
        fieldCB,
        iconType,
    },
);
export const addNewVeterinarianOption = createAction(MiscTypes.ADD_NEW_VETERINARIAN_OPTION);
export const setAccessLevelOptions = createAction(MiscTypes.SET_ACCESS_LEVEL_OPTIONS);
export const getAccessLevelOptions = (fieldCB) => getOptions(
    {
        url: '/v1/dogs/access-level-values/',
        optionContext: 'accessLevel',
        actionCB: setAccessLevelOptions,
        fieldCB,
    },
);
export const setSexOptions = createAction(MiscTypes.SET_SEX_OPTIONS);
export const getSexOptions = fieldCB => getOptions(
    {
        url: '/v1/dogs/gender-values/',
        optionContext: 'sex',
        actionCB: setSexOptions,
        fieldCB,
    },
);
export const setBreedsOptions = createAction(MiscTypes.SET_BREED_OPTIONS);
export const getBreedOptions = fieldCB => getOptions(
    {
        url: '/v1/dogs/breeds/',
        optionContext: 'breed',
        actionCB: setBreedsOptions,
        fieldCB,
    },
);
export const setWeightsOptions = createAction(MiscTypes.SET_WEIGHT_OPTIONS);
export const getWeightOptions = fieldCB => getOptions(
    {
        url: '/v1/dogs/weight-values/',
        optionContext: 'weight',
        actionCB: setWeightsOptions,
        fieldCB,
    },
);

export const setUserGroupingOptions = createAction(MiscTypes.SET_USER_GROUPING_OPTIONS);

export const setDogEvaluationModalOpened = createAction(MiscTypes.SET_DOG_EVALUATION_MODAL_OPENED);
export const setDogEvaluationModalClosed = createAction(MiscTypes.SET_DOG_EVALUATION_MODAL_CLOSED);

export const setAddCustomerCardModalOpened = createAction(MiscTypes.SET_ADD_CUSTOMER_CARD_MODAL_OPENED);
export const setAddCustomerCardModalClosed = createAction(MiscTypes.SET_ADD_CUSTOMER_CARD_MODAL_CLOSED);

export const closeAddCustomerCardModal = () => {
    return dispatch => {
        dispatch(setAddCustomerCardModalClosed());
        dispatch(reset('AddCustomerCardModalDialogForm'));
    };
};
export const setChangePaymentCardModalOpened = createAction(MiscTypes.SET_CHANGE_PAYMENT_CARD_MODAL_OPENED);
export const setChangePaymentCardModalClosed = createAction(MiscTypes.SET_CHANGE_PAYMENT_CARD_MODAL_CLOSED);

export const setAddAddressModalOpened = createAction(MiscTypes.SET_ADD_ADDRESS_MODAL_OPENED);
export const setModalValues = (formName, values) => {
    return dispatch => {
        Object.keys(values).forEach((chunk) => {
            const value = (values[chunk] && values[chunk].value) ? values[chunk].value : values[chunk];
            dispatch(change(formName, chunk, value));
        });
    };
};
export const closeAddAddressModal = createAction(MiscTypes.SET_ADD_ADDRESS_MODAL_CLOSED);
export const setAddAddressModalClosed = () => {
    return dispatch => {
        dispatch(closeAddAddressModal());
        dispatch(reset('AddModalDialogForm'));
    };
};

export const setAddInstructorEmployeeModalOpened = createAction(MiscTypes.SET_ADD_INSTRUCTOR_EMPLOYEE_MODAL_OPENED);
export const setEditInstructorEmployeeModalOpened = createAction(MiscTypes.SET_EDIT_INSTRUCTOR_EMPLOYEE_MODAL_OPENED);
export const closeInstructorEmployeeModal = createAction(MiscTypes.SET_INSTRUCTOR_EMPLOYEE_MODAL_CLOSED);
export const setDefaultInstructorEmployeeData = createAction(MiscTypes.SET_INSTRUCTOR_EMPLOYEE_DEFAULT_DATA);
export const openAddInstructorEmployeeModal = () => {
    return dispatch => {
        dispatch(setAddInstructorEmployeeModalOpened());
        dispatch(setCurrentInstructorEmployee());
    };
};
export const setInstructorEmployeeModalClosed = () => {
    return dispatch => {
        dispatch(closeInstructorEmployeeModal());
        dispatch(setDefaultInstructorEmployeeData());
        dispatch(reset('InstructorEmployeeModalDialogForm'));
    };
};
export const setInstructorEmployeeLoading = createAction(MiscTypes.SET_INSTRUCTOR_EMPLOYEE_MODAL_LOADING);
export const setInstructorEmployeeLoaded = createAction(MiscTypes.SET_INSTRUCTOR_EMPLOYEE_MODAL_LOADED);

export const setImmunizationExpirationDatesModalOpened = createAction(
    MiscTypes.SET_IMMUNIZATION_EXPIRATION_DATES_MODAL_OPENED,
);
export const closeImmunizationExpirationDatesModal = createAction(
    MiscTypes.SET_IMMUNIZATION_EXPIRATION_DATES_MODAL_CLOSED,
);
export const setDefaultImmunizationExpirationDatesData = createAction(
    MiscTypes.SET_IMMUNIZATION_EXPIRATION_DATES_DEFAULT_DATA,
);
export const openImmunizationExpirationDatesModal = immunizationData => {
    return dispatch => {
        dispatch(setImmunizationExpirationDatesModalOpened());
        dispatch(setCurrentImmunizationExpirationDates(immunizationData));
    };
};
export const setImmunizationExpirationDatesModalClosed = () => {
    return dispatch => {
        dispatch(closeImmunizationExpirationDatesModal());
        dispatch(setDefaultImmunizationExpirationDatesData());
        dispatch(reset('ImmunizationExpirationDatesModalDialogForm'));
    };
};
export const setImmunizationExpirationDatesLoading = createAction(
    MiscTypes.SET_IMMUNIZATION_EXPIRATION_DATES_MODAL_LOADING,
);
export const setImmunizationExpirationDatesLoaded = createAction(
    MiscTypes.SET_IMMUNIZATION_EXPIRATION_DATES_MODAL_LOADED,
);

//
export const getTrainingProgramsOptions = (fieldCB, query, isAllOptionsEnable) => {
    let extraQuery = '';

    if (isTrainer()) {
        extraQuery = '&is_archived=false&is_scheduled=true';
    }

    return getOptions(
        {
            url: `/v1/${getUserNameSpace('training-programs')}/training-programs/?page_size=0${extraQuery}`,
            optionContext: 'trainingPrograms',
            fieldCB,
            isAllOptionsEnable,
        },
    );
};

export const getAvailableTrainingProgramsOptions = (fieldCB) => getOptions({
    url: `/v1/${getUserNameSpace(
        'training-programs')}/training-programs/?is_paused=false&is_archived=false&page_size=0`,
    optionContext: 'availableTrainingPrograms',
    fieldCB,
});

export const getAvailablePackagesOptions = (fieldCB) => getOptions({
    url: `/v1/${getUserNameSpace('packages')}/packages/?is_archived=false&is_paused=false&page_size=0`,
    optionContext: 'availablePackages',
    fieldCB,
});

export const getRemovalReasonOptions = (fieldCB) => {
    return getOptions({
        url: '/v1/dogs/removal-reason-values/',
        optionContext: 'removalReason',
        fieldCB,
    });
};

export const getFormRecipientsByName = (fieldCB, query, notifyUsers) => getOptions(
    {
        url: `/v1/${getUserNameSpace('users-groupings')}/users-groupings/`,
        optionContext: 'formRecipients',
        method: 'post',
        extra: notifyUsers,
        requestData: {
            name: query,
        },
        fieldCB,
    },
);

export const setMarketingAttendanceByZoneFilterValues = createAction(
    MiscTypes.SET_MARKETING_ATTENDANCE_BY_ZONE_FILTER_VALUES);
export const getMarketingAttendanceByZoneFilterValues = (fieldCB) => {
    return getOptions({
        url: `/v1/${getUserNameSpace('reports.marketing')}/reports/marketing-attendance-by-zone-filter-values/`,
        optionContext: 'attendance-by-zone-filter',
        actionCB: setMarketingAttendanceByZoneFilterValues,
        fieldCB,
    });
};

export const setMobileSidebarOpened = createAction(MiscTypes.SET_MOBILE_SIDEBAR_OPENED);
export const setMobileSidebarClosed = createAction(MiscTypes.SET_MOBILE_SIDEBAR_CLOSED);

export const openEditCustomerInfoModalDialog = createAction(MiscTypes.SET_CUSTOMER_CONTACTS_INFO_OPENED);
export const setCustomerContactsInfoClosed = createAction(MiscTypes.SET_CUSTOMER_CONTACTS_INFO_CLOSED);
export const closeEditCustomerContactsInfoModalDialog = () => {
    return dispatch => {
        dispatch(setCustomerContactsInfoClosed());
        dispatch(reset('EditCustomerInfoForm'));
    };
};

export const setSmsVerificationModalOpened = createAction(MiscTypes.SET_SMS_VERIFICATION_MODAL_OPENED);
export const setSmsVerificationModalClosed = createAction(MiscTypes.SET_SMS_VERIFICATION_MODAL_CLOSED);

export const setPageBlocked = createAction(MiscTypes.SET_PAGE_BLOCKED);
export const setPageUnblocked = createAction(MiscTypes.SET_PAGE_UNBLOCKED);

export const dispatchPush = (link) => {
    return dispatch => dispatch(push(link));
};

export const setCustomersOptions = createAction(MiscTypes.SET_CUSTOMERS_OPTIONS);
export const getCustomersOptions = (fieldCB) => {
    return getOptions({
        // url: `/v1/${getUserNameSpace('customers')}/customers/?page_size=0`,
        // there is no ?page_size=0 for customers
        url: `/v1/${getUserNameSpace('customers')}/customers/`,
        optionContext: 'customers',
        actionCB: setCustomersOptions,
        fieldCB,
    });
};
