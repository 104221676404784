import PropTypes from 'prop-types';
import React from 'react';
import PageHeader from '../../common/PageHeader';


const FormUpdateContainer = ({ children }) => {
    return (
        <div className='form-update'>
            <PageHeader isSimpleHeader/>
            <div className='form-update__container'>
                {children}
            </div>
        </div>
    );
};

FormUpdateContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
};

export default FormUpdateContainer;
