import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';
import CustomToolbar from '../../Calendar/CustomToolbar';
import { DEFAULT_DATE_FORMAT } from '../../../../../reducers/dashboardPageReducer';
import { convertToNativeDate, convertNativeDateToDateString } from '../../../../../helpers/date';

const DateSwitcher = ({ currentDate, onDateChange }) => {
    const handleNavigation = (destination, selectedDate) => {
        if (destination === 'NEXT') {
            onDateChange(moment(currentDate).add(1, 'day').format(DEFAULT_DATE_FORMAT));
        }
        
        if (destination === 'PREV') {
            onDateChange(moment(currentDate).subtract(1, 'day').format(DEFAULT_DATE_FORMAT));
        }
        
        if (destination === 'DATE' && selectedDate) {
            onDateChange(convertNativeDateToDateString({ dateObj: selectedDate }));
        }
        
        return undefined;
    };
    
    return (
        <CustomToolbar
            date={convertToNativeDate(currentDate)}
            onNavigate={handleNavigation}
            shouldNotDisplayViewControls
            label={moment(currentDate).format('MM/DD/YYYY') + ' ' + moment(currentDate).format('dddd')}/>
    );
};

DateSwitcher.propTypes = {
    currentDate: PropTypes.string.isRequired,
    onDateChange: PropTypes.func.isRequired
};

export default DateSwitcher;
