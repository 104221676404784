    import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as Types from './types';
import { steps } from './steps';

const INITIAL_STEP = 1;

const INITIAL_STATE = fromJS({
    detail: {
        id: null,
        isLoading: false,
        uploadProgress: null,
        variables: [],
        targets: [],
        arrayOrder: [],
        submitType: 0,
        initialValues: {}
    },
    stepsTotal: Object.keys(steps).length,
    stepsCurrent: {
        id: INITIAL_STEP,
        name: steps[INITIAL_STEP]
    },
    templates: [
        {
            id: 1,
            name: "Events/What\'s Happening", // eslint-disable-line
            multipleBlocks: [{  // Multiple blocks with certain IDs allowed in this template.
                id: 3,
                max: 8
            }],

        },
        {
            id: 2,
            name: 'Promotion/Class or Training Highlight',
        },
        {
            id: 3,
            name: 'Newsletter',
            multipleBlocks: [{
                id: 1,
                max: 8
            }],
        },
        {
            id: 4,
            name: 'Blank'
        },
    ]
});

const marketingEditorPageReducer = handleActions({
    [Types.RESET_REDUCER]: () => {
        return INITIAL_STATE;
    },
    [Types.UPDATE_DETAIL]: (state, action) => {
        const { initialValues, ...rest } = action.payload;
        let newState = state;

        if (initialValues && Object.keys(initialValues).length) {
            newState = newState.update('detail', detail => detail.set('initialValues', fromJS(initialValues)));
        }

        if (rest && Object.keys(rest).length) {
            newState = newState.update('detail', detail => detail.merge(fromJS(rest)));
        }

        return newState;
    },
    [Types.UPDATE_UPLOAD_PROGRESS]: (state, action) => {
        return state.setIn(['detail', 'uploadProgress'], action.payload);
    },
    [Types.START_DETAIL_REQUEST]: (state) => {
        return state.setIn(['detail', 'isLoading'], true);
    },
    [Types.FINISH_DETAIL_REQUEST]: (state, action) => {
        let newState = { isLoading: false, uploadProgress: null };
        let templateID;

        if (action.payload) {
            newState = Object.assign(newState, action.payload);
            const { initialValues } = newState;
            if (initialValues && initialValues.template_id) {
                templateID = initialValues.template_id;
            }
        }

        newState = state.update('detail', detail => detail.mergeDeep(fromJS(newState)));

        if (templateID) {
            newState = newState.update('templates', templates => {
                return templates.update((templateID - 1), template => {
                    return template.set('isActive', true);
                });
            });
        }

        return newState;
    },
    [Types.SELECT_STEP]: (state, action) => {
        const nextStepId = action.payload;

        if (nextStepId > INITIAL_STATE.get('stepsTotal')) {
            return state;
        }

        return state.set('stepsCurrent', fromJS({
            id: nextStepId,
            name: steps[nextStepId]
        }));
    },
    [Types.SELECT_TEMPLATE]: (state, action) => {
        const nextTemplateId = action.payload;
        if (nextTemplateId > INITIAL_STATE.get('templates').size) {
            return state;
        }

        const currentTemplateIndex = state.get('templates').findIndex(t => t.get('isActive'));
        const nextTemplateIndex = state.get('templates').findIndex(t => t.get('id') === nextTemplateId);

        state = currentTemplateIndex >= 0 ? state.setIn(['templates', currentTemplateIndex],
            state.getIn(['templates', currentTemplateIndex]).set('isActive', false)) :
            state;

        return state.setIn(['templates', nextTemplateIndex], state.getIn(['templates', nextTemplateIndex])
            .set('isActive', true));
    }
}, INITIAL_STATE);

marketingEditorPageReducer.__name = 'marketingEditorPage';
export default marketingEditorPageReducer;
