import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { handleAutocompleteSelectOrEnter } from '../../../../../actions/autocompleteActions';
import { usStates } from '../../../../../helpers/options';
import { saveCustomerPickupLocation } from '../../../../../services/availableAddresses';
import renderCheckboxControl from '../../../../common/Form/CheckboxControl';
import renderInputControl from '../../../../common/Form/InputControl';
import renderPlacesAutocompleteControl from '../../../../common/Form/PlacesAutocompleteControl/PlacesAutocompleteControl';
import renderSelectControl from '../../../../common/Form/SelectControl';
import validate from './validate';

const formatTime = (dateString, timeString) => {
    const time = moment(`${dateString} ${timeString}`);
    const hours = time.format('h');
    const minutes = time.minutes() ? time.format(':mm') : '';
    const ampm = time.format('a');
    return hours + minutes + ampm;
};

const formatDate = (dateValue) => {
    const date = moment(dateValue, ['YYYY-MM-DD']);
    return date.format('DD/MM/YY');
};

class DropoffLocationSelect extends PureComponent {

    componentWillMount() {
        const { formData: { user, addresses }, addSubmitData, goToStep } = this.props;
        if (user && user.drop_off_location !== null && addresses.length) {
            let currentAddress = null;
            addresses.map(({ id }) => { // eslint-disable-line
                if (id === user.drop_off_location) {
                    currentAddress = id;
                }
            });
            if (currentAddress !== null) {
                addSubmitData({ name: 'dropoff', data: currentAddress });
                goToStep(3);
            }
        }
    }

    submitStep = (data) => {
        const {
            saveCustomerPickupLocation, addSubmitData, goToStep, addStepData, formData: { addresses }
        } = this.props;
        const locationData = data.toJS();
        locationData.state = locationData.state.value;
        return saveCustomerPickupLocation(locationData)
            .then((response) => {
                if ('default_pickup' in locationData || 'pickup_dropoff_same' in locationData) {
                    addSubmitData({ name: 'pickup', data: response.id });
                }
                addresses.push(response);
                addStepData({ name: 'addresses', data: addresses });
                addSubmitData({ name: 'dropoff', data: response.id });
                goToStep(3);
            });
    };

    render() {
        const {
            invalid, submitting, onCancelHandler, submitText, handleSubmit, formData,
            validationErrors: { zipCodeInvalid }, handleAutocompleteSelectOrEnter
        } = this.props;
        const submitButtonClassNames = classNames({
            'button modal-dialog__button': true,
            'button_disabled': (invalid || submitting)
        });
        const {
            classInfo: {
                title, date, dropoff_start_time, dropoff_end_time, valid_zip_codes_array
            },
            pickupSelection
        } = formData;
        const dropoffStart = formatTime(date, dropoff_start_time);
        const dropoffEnd = formatTime(date, dropoff_end_time);
        const classDate = formatDate(date);
        return (
            <form
                className='dropoff-location'
                onSubmit={handleSubmit((data) => this.submitStep(data))}>
                <div className='form-step__form-content pickup-location__form-content'>
                    <div className='pickup-location__head'>
                        <p className='pickup-location__head-title'>You are booking <span>{title}</span>, scheduled on
                            <span>{classDate}</span> with a pickup time between <span>{dropoffStart}</span> and
                            <span>{dropoffEnd}</span>.</p>
                        <div className='pickup-location__head-subline'>
                            Please tell us where to pick up and dropoff your dog.
                            <div className='tooltip'>
                                Available Zip Codes for this Class
                                <div className='tooltip__content'>{valid_zip_codes_array.join(', ')}</div>
                            </div>
                        </div>
                    </div>

                    {/* Form Content */}
                    <Field
                        name='name'
                        type='text'
                        component={renderInputControl}
                        label='Nickname'/>
                    {zipCodeInvalid && <div className='input-control__error pickup-location__invalid-zip'>
                        {zipCodeInvalid}
                        <div className='tooltip'>
                            Available Zip Codes for this Class
                            <div className='tooltip__content'>{valid_zip_codes_array.join(', ')}</div>
                        </div>
                    </div>}
                    <Field
                        name='address1'
                        type='text'
                        component={renderPlacesAutocompleteControl}
                        handleSelectOrEnter={address => {
                            handleAutocompleteSelectOrEnter(address, 'DropoffLocationSelectForm');
                        }}
                        label='Location'/>
                    <Field
                        name='address2'
                        type='text'
                        component={renderInputControl}
                        label='Location 2 (Apt or Unit number)'/>
                    <div className='form__aligner form__aligner_39-28-29'>
                        <Field
                            name='city'
                            type='text'
                            component={renderInputControl}
                            label='City'/>
                        <Field
                            name='state'
                            type='text'
                            component={renderSelectControl}
                            options={usStates}
                            className='select-control__is-state'
                            searchable
                            returnSelectValue
                            label='State'/>
                        <Field
                            name='zip_code'
                            type='text'
                            component={renderInputControl}
                            formError={zipCodeInvalid}
                            label='ZIP Code'/>
                    </div>

                    <div className='pickup-location__checkbox-group'>
                        {pickupSelection && !pickupSelection.homeAddress &&
                        <Field
                            name='home_address'
                            type='checkbox'
                            label='This is also my home address.'
                            className='modal-dialog__purchase-checkbox'
                            parse={value => !!value}
                            component={renderCheckboxControl}/>
                        }

                        {pickupSelection && !pickupSelection.defaultPickup &&
                        <Field
                            name='default_pickup'
                            type='checkbox'
                            label='Make this my default pick up address.'
                            className='modal-dialog__purchase-checkbox'
                            parse={value => !!value}
                            component={renderCheckboxControl}/>
                        }

                        <Field
                            name='default_dropoff'
                            type='checkbox'
                            label='Make this my default drop off address.'
                            className='modal-dialog__purchase-checkbox'
                            parse={value => !!value}
                            component={renderCheckboxControl}/>
                    </div>
                </div>
                <div
                    className='modal-dialog__form-footer modal-dialog__form-footer_column modal-dialog__form-footer_booking'>
                    <button
                        className={submitButtonClassNames}
                        type='submit'
                        disabled={submitting}>
                        {(submitting)
                            ? 'Submitting...'
                            : submitText || 'Next'
                        }
                    </button>
                    <span className='modal-dialog__cancel-button' onClick={onCancelHandler}>Cancel</span>
                </div>
            </form>
        );
    }
}

DropoffLocationSelect.propTypes = {
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    onCancelHandler: PropTypes.func,
    submitText: PropTypes.string,
    handleSubmit: PropTypes.func,
    formData: PropTypes.shape({}),
    saveCustomerDropoffLocation: PropTypes.func,
    validationErrors: PropTypes.shape({}),
    addSubmitData: PropTypes.func,
    goToStep: PropTypes.func,
    saveCustomerPickupLocation: PropTypes.func,
    addStepData: PropTypes.func,
    handleAutocompleteSelectOrEnter: PropTypes.func,
};

const form = reduxForm({
    form: 'DropoffLocationSelectForm',
    fields: ['zip_code_values'],
    enableReinitialize: true,
    validate
});

const mapStateToProps = (state, ownProps) => {
    const { formData: { classInfo } } = ownProps;
    let validZipCodes = [];
    if (classInfo) {
        validZipCodes = classInfo.valid_zip_codes_array;
    }
    return {
        validationErrors: state.getIn(['form', 'DropoffLocationSelectForm', 'syncErrors']) || {},
        initialValues: {
            'default_dropoff': true,
            'zip_code_values': validZipCodes.toString()
        }
    };
};

const mapDispatchToProps = {
    saveCustomerPickupLocation,
    handleAutocompleteSelectOrEnter
};

export default connect(mapStateToProps, mapDispatchToProps)(form(DropoffLocationSelect));
