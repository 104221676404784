import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { openClassBookingModalDialog, openTrainingBookingModalDialog } from '../../../../actions/modal/openActions';
import Async from '../../Select/Async';
import FilterTags from '../../../common/FilterTags';
import Icon from '../../../common/Icon';
import { ReactComponent as IconAdd } from '../../../../assets/icons/icon-add.svg';

const produceNewTag = selectValue => {
    const { value, label, extra: { groupingType } } = selectValue;
    return {
        id: value,
        grouping_type: groupingType,
        name: label
    };
};

class renderSearchAddTagControl extends React.PureComponent {
    static propTypes = {
        input: PropTypes.shape({}),
        autoload: PropTypes.bool,
        meta: PropTypes.shape({}),
        label: PropTypes.string,
        getOptions: PropTypes.func,
        optionRenderer: PropTypes.func,
        header: PropTypes.arrayOf(PropTypes.string),
        noResultsText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        formProps: PropTypes.shape({}),
        handleClassBookingModalDialog: PropTypes.func,
        handleTrainingBookingModalDialog: PropTypes.func,
        produceNewTag: PropTypes.func,
        emptyValue: PropTypes.string,
        shouldUseWarning: PropTypes.bool
    };
    
    static defaultProps = {
        produceNewTag
    };
    
    state = {
        selectValue: '',
        // Need this to clear the downloaded options once a recipient is selected.
        selectKey: 0
    };
    
    getInputValue = () => {
        const { input, emptyValue } = this.props;
        // Return a copy of the array. If value === '', an empty array will be returned.
        let value = [...input.value && input.value.toJS ? input.value.toJS() : input.value];
        
        if (!value.length && emptyValue != null) {
            value = emptyValue;
        }
        
        return value;
    };
    
    MAX_TAGS_NUM = 50;
    selectRef = null;
    
    isFormDisabled = () => {
        const { formProps } = this.props;
        return (formProps && formProps.disabled) || false;
    };
    
    isButtonDisabled = () => {
        return !this.state.selectValue;
    };

    handleAddButtonClick = e => {
        if (e) {
            e.preventDefault();
        }

        if (this.isFormDisabled() || this.isButtonDisabled()) {
            return;
        }
    
        const { input: { onChange }, produceNewTag, emptyValue } = this.props;
        let inputValue = this.getInputValue();
        
        if (inputValue === emptyValue && !Array.isArray(inputValue)) {
            inputValue = [];
        }
        
        if (inputValue.length > this.MAX_TAGS_NUM) {
            return;
        }
        
        const newTag = produceNewTag(this.state.selectValue);
        this.selectRef.clearValue();
        inputValue.push(newTag);
        
        this.setState({
            selectValue: '',
            // Need this to clear the downloaded options once a recipient is selected.
            selectKey: this.state.selectKey + 1
        }, () => {
            onChange(inputValue);
            this.selectRef.focus();
        });
    };
    
    handleSelectInputChange = value => {
        this.setState({
            selectValue: value
        });
    };
    
    handleSelectInputKeyDown = event => {
        if (event.keyCode === 13 && this.state.selectValue) {
            this.handleAddButtonClick(event);
        }
    };
    
    handleBlur = () => {
        const { input } = this.props;
        const inputValue = this.getInputValue();
        input.onBlur(inputValue);
    };
    
    handleTagRemove = tagIndex => {
        const { input: { onChange } } = this.props;
        let inputValue = this.getInputValue();
        
        inputValue.splice(tagIndex, 1);
        
        if (!inputValue.length) {
            inputValue = '';
        }
        
        onChange(inputValue);
    };
    
    renderError = () => {
        const { meta: { touched, error: err, warning }, shouldUseWarning } = this.props;
        const error = shouldUseWarning ? warning : err;
        
        if (touched && error) {
            return <span className='input-control__error'>{error}</span>;
        }

        return null;
    };
    
    renderTags = () => {
        const inputValue = this.getInputValue();
        
        if (!inputValue.length) {
            return null;
        }

        const tags = inputValue.map(tag => {
            return tag.name;
        });

        return (
            <FilterTags
                tags={tags}
                tagsRemoveHandler={this.handleTagRemove}
                className='form-edit-create-page__recipient-tags'/>
        );
    };

    render() {
        const {
            autoload = false,
            label,
            getOptions,
            optionRenderer,
            meta: { touched, error: err, warning },
            header,
            noResultsText,
            shouldUseWarning
        } = this.props;
        const error = shouldUseWarning ? warning : err;
        
        const selectClassNames = classNames({
            'select-control__select': true,
            'select-control__select_touched': touched,
            'select-control__select_failed': touched && error,
        });
    
        const placeholderClassNames = classNames({
            'select-control__placeholder': true,
            'select-control__placeholder_touched': touched,
            'select-control__placeholder_failed': touched && error,
            'select-control__placeholder_empty': !this.state.selectValue && !(touched && error),
        });
    
        const addButtonClassNames = classNames({
            'Select-add': true,
            'Select-add_disabled': this.isFormDisabled() || this.isButtonDisabled()
        });
    
        const addIconClassNames = classNames({
            'icon_add-search': true,
            'icon_disabled': this.isFormDisabled() || this.isButtonDisabled()
        });
        
        return (
            <div className='select-control'>
                <Async
                    key={this.state.selectKey}
                    ref={ref => {
                        this.selectRef = ref;
                    }}
                    placeholder={false}
                    loadingPlaceholder=''
                    autoload={autoload}
                    clearable={false}
                    disabled={this.isFormDisabled()}
                    searchable
                    cache={false}
                    optionRenderer={optionRenderer}
                    header={header}
                    loadOptions={getOptions}
                    noResultsText={noResultsText}
                    className={selectClassNames}
                    addButton={
                        <div onClick={this.handleAddButtonClick} className={addButtonClassNames}>
                            <Icon glyph={IconAdd} className={addIconClassNames}/>
                        </div>
                    }
                    value={this.state.selectValue}
                    onInputKeyDown={this.handleSelectInputKeyDown}
                    onBlur={this.handleBlur}
                    onChange={value => this.handleSelectInputChange(value)}/>
                <span className={placeholderClassNames}>{label}</span>
                {this.renderError()}
                {this.renderTags()}
            </div>
        );
    }
}

const mapDispatchToProps = {
    handleClassBookingModalDialog: openClassBookingModalDialog,
    handleTrainingBookingModalDialog: openTrainingBookingModalDialog
};

export default connect(null, mapDispatchToProps)(renderSearchAddTagControl);
