import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as UploadIcon } from '../../../../assets/icons/upload.svg';
import Icon from '../../../common/Icon/Icon';

const Preview = ({ descriptionTop, descriptionBottom, disclaimer }) => {
    return (
        <div className='zone-preview'>
            <Icon className='zone-preview__icon' glyph={UploadIcon} size='xl'/>
            <div className='zone-preview__text'>
                <div className='zone-preview__text-top'>{descriptionTop}</div>
                <div className='zone-preview__text-bottom'>{descriptionBottom}</div>
            </div>
            {disclaimer && <div className='zone-preview__disclaimer'>{disclaimer}</div>}
        </div>
    );
};

Preview.propTypes = {
    descriptionTop: PropTypes.string.isRequired,
    descriptionBottom: PropTypes.string,
    disclaimer: PropTypes.string
};

export default Preview;
