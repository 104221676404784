export const isMobileDevice = () => {
    return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

export const isPortraitOrientation = () => {
    return window.innerHeight > window.innerWidth;
};

export const isLandscapeOrientation = () => {
    return window.innerHeight < window.innerWidth;
};

export const isDesktop = window.innerWidth > 1024;

export const isMobile = window.innerWidth <= 736;
