import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as IconAmericanExpress } from '../../../../assets/icons/icon-american-express.svg';
import { ReactComponent as IconMasterCard } from '../../../../assets/icons/icon-master-card.svg';
import { ReactComponent as IconNeutralCard } from '../../../../assets/icons/icon-neutral-card.svg';
import { ReactComponent as IconPackages } from '../../../../assets/icons/icon-all-packages.svg';
import { ReactComponent as IconVisa } from '../../../../assets/icons/icon-visa.svg';
import Icon from '../../../common/Icon';

const cardIcons = {
    'Visa': {
        'icon': IconVisa,
        'class_name': 'card-detection__item_visa'
    },
    'MasterCard': {
        'icon': IconMasterCard,
        'class_name': 'card-detection__item_master'
    },
    'American Express': {
        'icon': IconAmericanExpress,
        'class_name': 'card-detection__item_express'
    },
    'default': {
        'icon': IconNeutralCard,
        'class_name': 'card-detection__item_noname'
    }
};
const PaymentField = ({ data: { card, packageCredit, packagesAvailable }, label, className, handleChange, meta: { error } }) => {
    let currentCard = {
        'icon': IconNeutralCard,
        'class_name': 'card-detection__item_noname'
    };

    if (card && card.type in cardIcons) {
        currentCard = cardIcons[card.type || 'default'];
    }

    const cardClasses = classNames([
        'card-detection__item',
        currentCard.class_name,
        'card-detection__item_detected'
    ]);

    const blockClasses = classNames({
        [className]: !!className,
        'pre-confirmation__block': true,
        'pre-confirmation__block_error': error,
    });
    return (
        <div className={blockClasses}>
            <strong className='pre-confirmation__block__title'>{label}</strong>
            <div>
                {card && <div className='card-selector__item'>
                    <span className='card-selector__icon'>
                        <Icon glyph={currentCard.icon} className={cardClasses} />
                    </span>
                    <div className='card-selector__content'>
                        <span className='card-selector__text'>{card.type} Card Ending in {card.last4}</span>
                        <span className='card-selector__button' onClick={handleChange}>Change</span>
                    </div>
                </div>}
                {packageCredit && <div className='card-selector__item'>
                    <span className='card-selector__icon'>
                        <Icon glyph={IconPackages} className='card-detection__item card-detection__item_package' />
                    </span>
                    <div className='card-selector__content'>
                        {packagesAvailable
                            ?
                            packagesAvailable.map((i) => {
                                return i.id === packageCredit.id ? <span className='card-selector__text'>{i.package_detail.name}  Applied - {i.available_quantity} remaining</span> : null;
                            })
                            :
                            <span className='card-selector__text'>Package Credit Applied</span>}
                        <span className='card-selector__button' onClick={handleChange}>Change</span>
                    </div>
                </div>}
            </div>
        </div>
    );
};

PaymentField.propTypes = {
    data: PropTypes.shape({}),
    label: PropTypes.string,
    meta: PropTypes.shape({}),
    handleChange: PropTypes.func,
    className: PropTypes.string
};

export default PaymentField;
