import React from 'react';
import PropTypes from 'prop-types';

const StepFormWrapper = ({ title, form, ...otherProps }) => {
    const Form = form || null;
    return (
        <div className='form-step'>
            {title && <p className='form-step__title'>{title}</p>}
            <div className='form-step__container'>
                <Form {...otherProps} />
            </div>
        </div>
    );
};

StepFormWrapper.propTypes = {
    title: PropTypes.string,
    form: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

export default StepFormWrapper;
