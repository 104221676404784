
import { createAction } from 'redux-actions';
import { fromJS } from 'immutable';
import * as ClassesScheduleTypes from '../../types/dashboard/classesScheduleTypes';
import { updateClassFeatured } from '../../services/classesSchedule';

export const setClasses = createAction(ClassesScheduleTypes.SET_CLASSES);
export const setClassesOptions = createAction(ClassesScheduleTypes.SET_CLASSES_OPTIONS);
export const setNextClasses = createAction(ClassesScheduleTypes.SET_NEXT_CLASSES);
export const updateClassesDescriptionState = createAction(ClassesScheduleTypes.UPDATE_CLASSES_DESCRIPTION_STATE);
export const updateClassFeaturedInStore = createAction(ClassesScheduleTypes.UPDATE_CLASS_FEATURED_OPTION);
export const updateClassFeaturedOption = (elementIndex, data) => {
    const dataJS = data.toJS();
    const { id, featured } = dataJS;
    return (dispatch) => {
        dispatch(updateClassFeatured({
            id,
            featured,
        })).then((response) => {
            dispatch(updateClassFeaturedInStore({
                index: elementIndex,
                featured: response.data.is_featured,
            }));
        })
        .catch(err => {
            console.log(err);
            console.log(err.response);
        });
    };
};
export const startLoadingClasses = createAction(ClassesScheduleTypes.START_CLASSES_LOADING);
export const finishLoadingClasses = createAction(ClassesScheduleTypes.FINISH_CLASSES_LOADING);

export const setClassesScheduleFilterOptions = createAction(ClassesScheduleTypes.SET_CLASSES_FILTER_OPTIONS);
export const updateClassesScheduleFilterTags = createAction(ClassesScheduleTypes.UPDATE_CLASSES_FILTER_TAGS);
export const updateClassesScheduleFilterObject = createAction(ClassesScheduleTypes.UPDATE_CLASSES_FILTER_OBJECT);

export const updateClassesScheduleList = (filterObject) => {
    // const filterObjectNormalized = filterObject.size ? filterObject.toJS() : filterObject;
    return (dispatch) => {
        dispatch(updateClassesScheduleFilterObject(filterObject || fromJS({})));
    };
};

// export const setNewClassToClassesSchedule = createAction(ClassesScheduleTypes.ADD_NEW_CLASS_TO_CLASSES);
export const updateClassForClassesSchedule = createAction(ClassesScheduleTypes.UPDATE_CLASS_TO_CLASSES);
