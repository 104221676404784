import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCurrentInstructorEmployee } from '../../../actions/dashboard/instructorEmployeeActions';
import { setEditInstructorEmployeeModalOpened, setResetPasswordModalOpened } from '../../../actions/miscActions';
import { openEmployeeDeleteModalDialog } from '../../../actions/modal/openActions';
import { ReactComponent as IconDots } from '../../../assets/icons/icon-dots.svg';
import { ReactComponent as IconEdit } from '../../../assets/icons/icon-edit.svg';
import { ReactComponent as IconPassword } from '../../../assets/icons/icon-password.svg';
import { ReactComponent as IconTrash } from '../../../assets/icons/icon-trash.svg';
import { capitalizeEachWord } from '../../../helpers/text';
import AvatarPlaceholder from '../AvatarPlaceholder';
import Dropdown from '../Dropdown';
import { isEmployee, isManager } from '../../../helpers/userRoles';
import { prettifyPhone } from '../../../helpers/normalize';

const EmployeeCard = ({
    data,
    handleInstructorEmployeeModalOpened,
    setInstructorEmployeeData,
    handleResetPasswordModalOpened,
    handleDeleteEmployeeModalOpened,
    readOnly
}) => {
    const renderAvatar = () => {
        const photo = data.get('photo_processed') || data.get('photo');

        if (!photo) {
            return <div className='avatar__default-placeholder-container'><AvatarPlaceholder/></div>;
        }

        return (
            <div className='employee-card__img-container'>
                <img
                    className='employee-card__img'
                    src={photo}
                    alt='alt'/>
            </div>
        );
    };

    const getDropdownItems = () => {
        const itemsList = [];
        if (!isEmployee() && !isManager()) {
            itemsList.push({
                iconClass: 'icon_edit',
                icon: IconEdit,
                text: 'Edit',
                onClickCB: () => {
                    handleInstructorEmployeeModalOpened();
                    setInstructorEmployeeData(data);
                }
            });
        }
        itemsList.push({
            iconClass: 'icon_password',
            icon: IconPassword,
            text: 'Reset Password',
            onClickCB: () => {
                handleResetPasswordModalOpened({
                    fullName: data.get('full_name'),
                    email: data.get('email')
                });
            }
        });
        if (!isEmployee() && !isManager()) {
            itemsList.push({
                iconClass: 'icon_trash',
                icon: IconTrash,
                text: 'Delete',
                onClickCB: () => {
                    handleDeleteEmployeeModalOpened(data.toJS());
                }
            });
        }
        return itemsList;
    };

    return (
        <div className='employee-card-container'>
            <div className='employee-card'>
                {!readOnly && <Dropdown
                    classNames='dropdown_employee-card'
                    icon={IconDots}
                    iconClass='icon_chevron_down'
                    items={getDropdownItems()}/>}
                <div className='employee-card__header'>
                    {renderAvatar()}
                    <div className='employee-card__header-title'>{data.get('full_name') || 'Name not set'}</div>
                    <div className='employee-card__header-text'>
                        {data.get('available_phones').size
                            ? prettifyPhone(data.getIn(['available_phones', '0', 'phone_number']))
                            : 'Phone not set'
                        }
                    </div>
                    <div className='employee-card__header-text employee-card__header-text_email'>{data.get(
                        'email') || 'Email not set'}</div>
                </div>
                <div className='employee-card__content'>
                    <div className='employee-card__item'>
                        <div className='employee-card__item-header'>Position</div>
                        <div className='employee-card__item-content'>{capitalizeEachWord(data.get('position')) ||
                        'Position not set'}</div>
                    </div>
                    <div className='employee-card__item'>
                        <div className='employee-card__item-header'>Permissions</div>
                        <div className='employee-card__item-content'>{data.get('permission_level_detail') ||
                        'Permissions not set'}</div>
                    </div>
                    <div className='employee-card__item'>
                        <div className='employee-card__item-header'>Emergency Contact</div>
                        <div className='employee-card__item-content'>{data.getIn(['emergency_contact', 'name']) ||
                        'Name not set'}</div>
                        <div className='employee-card__item-content'>{prettifyPhone(data.getIn(['emergency_contact', 'phone_number'])) ||
                        'Phone not set'}</div>
                    </div>
                    <div className='employee-card__item'>
                        <div className='employee-card__item-header'>Qualified for</div>
                        <div className='employee-card__item-content'>
                            {data.get('qualified_for') || 'Not set'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

EmployeeCard.propTypes = {
    data: PropTypes.shape({}).isRequired,
    handleInstructorEmployeeModalOpened: PropTypes.func.isRequired,
    setInstructorEmployeeData: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    handleResetPasswordModalOpened: PropTypes.func.isRequired,
    handleDeleteEmployeeModalOpened: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    handleInstructorEmployeeModalOpened: bindActionCreators(setEditInstructorEmployeeModalOpened, dispatch),
    setInstructorEmployeeData: bindActionCreators(setCurrentInstructorEmployee, dispatch),
    handleResetPasswordModalOpened: bindActionCreators(setResetPasswordModalOpened, dispatch),
    handleDeleteEmployeeModalOpened: bindActionCreators(openEmployeeDeleteModalDialog, dispatch),
});

export default connect(null, mapDispatchToProps)(EmployeeCard);
