import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onModalClose } from '../../../../actions/modal/modalActions';
import { ReactComponent as CheckmarkIcon } from '../../../../assets/icons/forgot-password-checkmark.svg';
import { ReactComponent as AllClassesIcon } from '../../../../assets/icons/icon-all-classes.svg';
import { ReactComponent as AllPackagesIcon } from '../../../../assets/icons/icon-all-packages.svg';

const TrainingSuccessModalDialog = ({ handleClose, content, links = ['classes', 'packages'] }) => {
    return (
        <div className='modal-dialog__success'>
            <CheckmarkIcon className='modal-dialog__success-logo'/>
            <h5 className='modal-dialog__success-title'>You&#39;re All Set</h5>
            <p className='modal-dialog__success-text'>{content}</p>
            <div className='modal-dialog__success-nav'>
                {links.indexOf('classes') !== -1 && <Link to='/classes' onClick={handleClose} className='modal-dialog__success-nav__item'>
                    <AllClassesIcon className='modal-dialog__success-nav__icon'/>
                    <span>View Classes</span>
                </Link>}
                {links.indexOf('packages') !== -1 && <Link to='/packages' onClick={handleClose} className='modal-dialog__success-nav__item'>
                    <AllPackagesIcon className='modal-dialog__success-nav__icon'/>
                    <span>View Packages</span>
                </Link>}
            </div>
        </div>
    );
};

TrainingSuccessModalDialog.propTypes = {
    handleClose: PropTypes.func,
    links: PropTypes.arrayOf(PropTypes.string),
    content: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    handleClose: bindActionCreators(onModalClose, dispatch)
});

export default connect(null, mapDispatchToProps)(TrainingSuccessModalDialog);
