import PropTypes from 'prop-types';
import React from 'react';

const OnBoardingContainer = ({ children }) => {
    return (
        <div className='onboarding'>
            {children}
        </div>
    );
};

OnBoardingContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default OnBoardingContainer;
