import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as ModaSidebarlTypes from '../types/modalSidebarTypes';

const INITIAL_STATE = fromJS({
    isOpened: false,
    isSubmitting: false,
    isCanceling: false,
    modalType: null,
    data: null,
    error: null,
});

const modalSidebarReducer = handleActions({
    [ModaSidebarlTypes.SET_MODAL_SIDEBAR_OPENED]: (state, action) => {
        return state.set('isOpened', true).set('modalType', action.payload);
    },
    [ModaSidebarlTypes.SET_MODAL_SIDEBAR_CLOSED]: (state) => {
        return state.set('isOpened', false);
    },
    [ModaSidebarlTypes.SET_MODAL_SIDEBAR_SUBMIT_REQUEST_STARTED]: (state) => {
        return state.set('isSubmitting', true);
    },
    [ModaSidebarlTypes.SET_MODAL_SIDEBAR_SUBMIT_REQUEST_FINISHED]: (state) => {
        return state.set('isSubmitting', false);
    },
    [ModaSidebarlTypes.SET_MODAL_SIDEBAR_CANCEL_REQUEST_STARTED]: (state) => {
        return state.set('isCanceling', true);
    },
    [ModaSidebarlTypes.SET_MODAL_SIDEBAR_CANCEL_REQUEST_FINISHED]: (state) => {
        return state.set('isCanceling', false);
    },
    [ModaSidebarlTypes.SET_MODAL_SIDEBAR_DATA]: (state, action) => {
        return state.set('data', action.payload);
    },
    [ModaSidebarlTypes.SET_MODAL_SIDEBAR_ACTIVE_FORM]: (state, action) => {
        const newData = Object.assign({}, state.get('data'));
        newData.activeForm = action.payload;
        return state.set('data', newData);
    },
    [ModaSidebarlTypes.SET_MODAL_SIDEBAR_SUBMIT_ERROR]: (state, action) => state.set('error', action.payload),
    [ModaSidebarlTypes.CLEAR_MODAL_SIDEBAR_SUBMIT_ERROR]: (state, action) => state.set('error', null),
}, INITIAL_STATE);

export default modalSidebarReducer;
