import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import LinkButton from '../../../common/LinkButton';
import FormContainer from '../../FormContainer';
import Loader from '../../../common/Loader/Loader';

let refForm = null;

const FormEditCreatePage = (
    {
        currentForm,
        formState,
        submit,
        openSaveCreateModalDialog,
        updateCurrentForm,
        openDiscardChangesModalDialog,
        goBack
    }) => {
    const handleCancelClick = () => {
        const { isPristine } = formState;

        if (isPristine) {
            goBack();
        } else {
            openDiscardChangesModalDialog();
        }
    };
    const shouldDisableSaveLaterButton = () => {
        const { isSubmitting, isInvalid, isPristine } = formState;
        const isPublished = currentForm.get('isPublished');
        const isLoading = currentForm.get('isLoading');
        // Always disable if the form has been published already.
        return isPublished || isLoading || isInvalid || isSubmitting || isPristine;
    };

    const shouldDisableSavePublishButton = () => {
        const { isSubmitting, isInvalid, isPristine } = formState;
        const isLoading = currentForm.get('isLoading');
        const isPublished = currentForm.get('isPublished');

        if (isLoading || isInvalid || isSubmitting) {
            return true;
        }

        // Do not disable event if the form is pristine, because the user might want just to publish
        // an unpublished form without editing it.
        if (!isPublished && (formState.values && formState.values.text)) {
            return false;
        }

        return isPristine;
    };

    const isNewForm = () => {
        return !currentForm.getIn(['formConfig', 'itemId']);
    };

    const handleSavePublishClick = async () => {
        if (shouldDisableSavePublishButton()) {
            refForm.getWrappedInstance().doValidation();
            return;
        }

        const formConfigJS = currentForm.get('formConfig').toJS();
        await updateCurrentForm({ formConfig: { shouldPublish: true } });
        openSaveCreateModalDialog(formConfigJS);
    };

    const handleSaveLaterClick = async () => {
        if (shouldDisableSaveLaterButton()) {
            refForm.getWrappedInstance().doValidation();
            return;
        }

        const form = currentForm.getIn(['formConfig', 'form']);
        await updateCurrentForm({ formConfig: { shouldPublish: false } });
        submit(form);
    };

    const renderControls = () => {
        const { isSubmitting } = formState;
        const shouldPublish = currentForm.getIn(['formConfig', 'shouldPublish']);
        const saveLaterButtonClassNames = classNames({
            'button form-edit-create-page__control-button button_dark': true,
            'button_disabled': shouldDisableSaveLaterButton()
        });
        const savePublishButtonClassNames = classNames({
            'button form-edit-create-page__control-button button_dark': true,
            'button_disabled': shouldDisableSavePublishButton()
        });

        return (
            <div className='form-edit-create-page__controls'>
                <LinkButton
                    className='form-edit-create-page__cancel-button'
                    isDisabled={isSubmitting}
                    text='Cancel'
                    onClickHandler={handleCancelClick}/>
                <button
                    className={saveLaterButtonClassNames}
                    onClick={handleSaveLaterClick}
                    disabled={false}>
                    {isSubmitting && !shouldPublish ?
                        'Submitting' :
                        'Save for Later'}
                </button>
                <button
                    className={savePublishButtonClassNames}
                    disabled={false}
                    onClick={handleSavePublishClick}>
                    {isSubmitting && shouldPublish ?
                        'Submitting' :
                        isNewForm() ? 'Save and Create' : 'Save and Publish'}
                </button>
            </div>
        );
    };

    const renderEditorForm = () => {
        if (currentForm.get('isLoading') || typeof currentForm.get('formConfig') === 'undefined') {
            return (
                <div className='form-edit-create-page__empty-content-content'>
                    <Loader
                        isVisible
                        loadingText='Loading Form Data...'
                        colorClassName='icon_loader-black'
                        modifierClassName='calendar__loader'/>
                </div>
            );
        }

        return (
            <FormContainer
                ref={ref => {
                    refForm = ref;
                }}
                className='form-edit-create-page__form'
                form={currentForm.get('formConfig').toJS()}
                onGetOptions={() => []}/>
        );
    };

    return (
        <div className='dashboard__main'>
            <div className='form-edit-create-page'>
                {renderControls()}
                <div className='form-edit-create-page__header'>
                    {currentForm.get('isLoading') ? '...' : currentForm.getIn(['data', 'name'])}
                </div>
                <div className='form-edit-create-page__content'>
                    {renderEditorForm()}
                </div>
            </div>
        </div>
    );
};

FormEditCreatePage.propTypes = {
    currentForm: PropTypes.shape({}),
    formState: PropTypes.shape({}),
    submit: PropTypes.func,
    goBack: PropTypes.func,
    openSaveCreateModalDialog: PropTypes.func,
    openDiscardChangesModalDialog: PropTypes.func,
    updateCurrentForm: PropTypes.func
};

export default FormEditCreatePage;
