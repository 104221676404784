import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';

const DropdownItem = (props) => {
    const { itemClass,
        iconClass,
        icon: Icon,
        text,
        onClickCB,
        isDisabled = false,
        link,
        extraTextUpper,
        extraTextLower } = props;

    const itemClassNames = classNames(
        'dropdown__item',
        itemClass,
        isDisabled && 'dropdown__item_disabled'
    );
    if (link) {
        return (
            <Link
                className={itemClassNames}
                to={link}>
                <span className='dropdown__image'>
                    <Icon className={iconClass}/>
                </span>
                <span className='dropdown__text'>{text}</span>
            </Link>
        );
    } else { // eslint-disable-line no-else-return
        return (
            <button
                className={itemClassNames}
                onClick={
                    isDisabled ? null : () => { onClickCB(); }
                }>
                <span className='dropdown__image'>
                    <Icon className={iconClass}/>
                </span>
                <span className='dropdown__text'>
                    {text}
                    {extraTextUpper && <span className='dropdown__extra-text'>{extraTextUpper}</span>}
                    {extraTextLower && <span className='dropdown__extra-text'>{extraTextLower}</span>}
                </span>
            </button>
        );
    }
};

DropdownItem.propTypes = {
    itemClass: PropTypes.string,
    iconClass: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    extraTextUpper: PropTypes.string,
    extraTextLower: PropTypes.string,
    onClickCB: PropTypes.func,
    isDisabled: PropTypes.bool,
    link: PropTypes.string
};

export default DropdownItem;
