import React from 'react';
import PropTypes from 'prop-types';

const renderCheckboxControl = ({ input, label, className }) => {
    return (
        <label className={`checkbox-control__label ${className || ''}`}>
            <input
                type={input.type || 'checkbox'}
                className='checkbox-control__input'
                {...input} />
            <span className='checkbox-control__text'>{label}</span>
        </label>
    );
};

renderCheckboxControl.propTypes = {
    input: PropTypes.shape({}),
    label: PropTypes.string,
    className: PropTypes.string
};

export default renderCheckboxControl;
