import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import renderPasswordControl from '../../../../../common/Form/PasswordControl/PasswordControl';
import validate from './validate';

const ChangePasswordForm = ({ handleSubmit, submitting, invalid }) => {
    const SubmitButtonClassNames = classNames({
        'button change-password__submit-button': true,
        'button_disabled': (invalid || submitting)
    });
    return (
        <form onSubmit={handleSubmit} className='change-password__form'>
            <Field name='old_password' type='password' component={renderPasswordControl} label={'Current password'} />
            <Field name='password' type='password' component={renderPasswordControl} label={'New password'} />
            <Field name='confirm_password' type='password' component={renderPasswordControl} label={'Confirm new password'} />
            <button className={SubmitButtonClassNames} type='submit' disabled={submitting}>
                {submitting ? 'Submitting...' : 'Update password'}
            </button>
        </form>
    );
};

ChangePasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
};

const form = reduxForm({
    form: 'ChangeCustomersPasswordForm',
    validate
});

export default form(ChangePasswordForm);
