/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class Tab extends PureComponent {
    handleClick = () => {
        this.props.handleClick(this.props.tabIndex);
    };

    render() {
        const tabClass = classNames({
            'tabs__item': true,
            'tabs__item_selected': this.props.selected
        });
        return (
            <li className={tabClass} onClick={this.handleClick} >
                {this.props.title}
            </li>
        );
    }
}

Tab.propTypes = {
    handleClick: PropTypes.func,
    tabIndex: PropTypes.number,
    title: PropTypes.string,
    selected: PropTypes.bool,
};

export default Tab;
