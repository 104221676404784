import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SmsAddPhoneStepForm from './SmsAddPhoneStepForm';
import { addCustomerPrimaryPhone } from '../../../../../services/customers';

const SmsAddPhoneStep = ({ goToStep, addCustomerPrimaryPhone }) => {
    return (
        <div className='sms-modal__verification'>
            <div className='sms-modal__content'>
                <h5 className='sms-modal__title'>Add Phone Number</h5>
                <p className='sms-modal__text sms-modal__text_phone'>Add the phone number below where you would like to receive SMS updates.</p>
            </div>
            <SmsAddPhoneStepForm
                onSubmit={(values) => {
                    const data = {
                        phone_number: values.get('phone').replace(/[^\w\s]/gi, '')
                    };
                    return addCustomerPrimaryPhone(data).then(() => goToStep(1));
                }}
                onCancelHandler={() => goToStep(3)} />
        </div>
    );
};

SmsAddPhoneStep.propTypes = {
    goToStep: PropTypes.func.isRequired,
    addCustomerPrimaryPhone: PropTypes.func
};
const mapDispatchToProps = {
    addCustomerPrimaryPhone
};

export default connect(null, mapDispatchToProps)(SmsAddPhoneStep);
