const validate = values => {
    const errors = {};
    if (!values.get('model_type') || Array.isArray(values.get('type'))) {
        errors.model_type = 'Employee Type is Required.';
    }

    if (!values.get('name')) {
        errors.name = 'Please enter employee name';
    } else if (values.get('name').length > 100) {
        errors.name = 'It\'s not allowed to enter more than 100 symbols into this field';
    }

    if ((!values.get('permissions') || Array.isArray(values.get('permissions')))) {
        if (values.get('model_type')) {
            if (values.get('model_type').value !== 'trainer') {
                errors.permissions = 'Permissions is Required.';
            }
        } else {
            errors.permissions = 'Permissions is Required.';
        }
    }

    if (!values.get('position')) {
        errors.position = 'Please enter employee position';
    } else if (values.get('position').length > 255) {
        errors.position = 'It\'s not allowed to enter more than 255 symbols into this field';
    }

    if (!/^[0-9()+-/,.]*$/i.test(values.get('primary_phone'))) {
        errors.primary_phone = 'Please enter a valid phone number';
    } else if (values.get('primary_phone').replace(/[^\w\s]/gi, '').length < 5
        && values.get('primary_phone').replace(/[^\w\s]/gi, '').length > 0) {
        errors.primary_phone = 'Phone Number is too short.';
    } else if (values.get('primary_phone').replace(/[^\w\s]/gi, '').length > 15) {
        errors.primary_phone = 'Phone Number is too large.';
    }

    if (values.get('emergency_phone') && !values.get('emergency_name')) {
        errors.emergency_name = 'Please enter Emergency Contact';
    } else if (values.get('emergency_name') && values.get('emergency_name').length > 255) {
        errors.emergency_name = 'It\'s not allowed to enter more than 255 symbols into this field';
    }

    if (values.get('emergency_phone') || values.get('emergency_name')) {
        if (!/^[0-9()+-/,.]*$/i.test(values.get('emergency_phone'))) {
            errors.emergency_phone = 'Please enter a valid phone number';
        } else if (values.get('emergency_phone').replace(/[^\w\s]/gi, '').length < 5
            && values.get('emergency_phone').replace(/[^\w\s]/gi, '').length > 0) {
            errors.emergency_phone = 'Emergency Phone Number is too short.';
        } else if (values.get('emergency_phone').replace(/[^\w\s]/gi, '').length > 15) {
            errors.emergency_phone = 'Emergency Phone Number is too large.';
        }
    }

    if (!values.get('email')) {
        errors.email = 'Email is Required.';
    } else if (values.get('email').length < 5) {
        errors.email = 'It\'s not allowed to enter less than 5 symbols into this field';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,9}$/i.test(values.get('email'))) {
        errors.email = 'Please enter a valid e-mail address.';
    } else if (values.get('email').length > 254) {
        errors.email = 'It\'s not allowed to enter more than 254 symbols into this field';
    }

    if (!values.get('qualification')) {
        errors.qualification = 'Qualified for is Required.';
    } else if (values.get('qualification').length > 1000) {
        errors.qualification = 'It\'s not allowed to enter more than 1000 symbols into this field';
    }

    return errors;
};

export default validate;
