import classNames from 'classnames';
import { fromJS } from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { setDefaultIntroductionData } from '../../../../../actions/onboarding/dogCreationActions';
import { setCurrentRegistrationStep } from '../../../../../actions/onboarding/onBoardingStepsActions';
import { getBreedOptions, getGenderOptions, getWeightOptions } from '../../../../../actions/modal/getOptionsActions';
import * as options from '../../../../../helpers/options';
import renderInputControl from '../../../../common/Form/InputControl';
import { renderError, renderWarning } from '../../../../common/Form/RadioControl/RadioControl';
import renderSelectControl from '../../../../common/Form/SelectControl';
import validate from './validate';
import warn from './warn';

class IntroductionForm extends React.PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        onSubmit: PropTypes.func,
        change: PropTypes.func,
        submitting: PropTypes.bool,
        invalid: PropTypes.bool,
        handleSexOptions: PropTypes.func,
        handleBreedOptions: PropTypes.func,
        handleWeightOptions: PropTypes.func,
        handleDefaultValues: PropTypes.func,
        registrationStep: PropTypes.number,
        updateRegistrationStep: PropTypes.func,
        sexOptions: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.shape({})),
            PropTypes.shape({})
        ]),
        breedOptions: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.shape({})),
            PropTypes.shape({})
        ]),
        weightOptions: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.shape({})),
            PropTypes.shape({})
        ]),
        birthdayMonth: PropTypes.shape({}),
    };
    state = {
        filterMonths: false
    };

    componentDidMount() {
        const { handleDefaultValues, registrationStep, updateRegistrationStep } = this.props;
        handleDefaultValues();
        const savedRegistrationStep = +localStorage.getItem('registration_step');
        if (savedRegistrationStep && !registrationStep) {
            console.log('update registration step >>> ');
            updateRegistrationStep(savedRegistrationStep);
        }
    }

    componentWillUpdate(nextProps, nextState) {
        const { birthdayMonth, change } = nextProps;
        if (birthdayMonth) {
            const date = moment().toDate();
            const currMonth = date.getMonth() + 1;
            // if selected future month - clear selection
            if (birthdayMonth.value > currMonth && nextState.filterMonths) {
                change('month', null);
            }
        }
    }

    getMonthsList = () => {
        const date = moment().toDate();
        const currMonth = date.getMonth() + 1;
        return options.month.map((month) => {
            if (month.value > currMonth) {
                month.disabled = this.state.filterMonths;
            }
            return month;
        });
    };

    getBreedOptions = (value, callback) => {
        const { handleBreedOptions } = this.props;
        return handleBreedOptions()(value, callback);
    };

    getGenderOptions = (value, callback) => {
        const { handleSexOptions } = this.props;
        return handleSexOptions()(value, callback);
    };

    getWeightOptions = (value, callback) => {
        const { handleWeightOptions } = this.props;
        return handleWeightOptions()(value, callback);
    };

    shouldFilterMonths = (selectedOption) => {
        const currYear = options.year[0];
        if (selectedOption.value === currYear.value) {
            this.setState({
                filterMonths: true
            });
        } else if (selectedOption.value !== currYear.value && this.state.filterMonths) {
            this.setState({
                filterMonths: false
            });
        }
    };

    render() {
        // eslint-disable-next-line max-len
        const { handleSubmit, submitting, invalid } = this.props;
        const SubmitButtonClassNames = classNames({
            'introduction__submit-button': true,
            'introduction__submit-button_disabled': (invalid || submitting)
        });
        return (
            <form onSubmit={handleSubmit} className='introduction__form'>
                <div className='introduction__form-content'>
                    <div className='form__aligner form__aligner_60-40'>
                        <Field
                            name='name'
                            type='text'
                            component={renderInputControl}
                            label={'What\'s your dog\'s name?'}/>
                        <Field
                            name='gender'
                            type='text'
                            async
                            autoload
                            component={renderSelectControl}
                            getOptions={this.getGenderOptions}
                            label='Sex'/>
                    </div>
                    <div className='form__aligner form__aligner_60-40'>
                        <Field
                            name='breed'
                            async
                            autoload
                            type='text'
                            component={renderSelectControl}
                            getOptions={this.getBreedOptions}
                            label='Select breed'
                            withInput/>
                        <Field
                            name='weight'
                            type='text'
                            async
                            autoload
                            component={renderSelectControl}
                            getOptions={this.getWeightOptions}
                            label='Weight'/>
                    </div>
                    <div className='form__aligner form__aligner_60-40'>
                        <Field
                            name='month'
                            type='text'
                            component={renderSelectControl}
                            options={this.getMonthsList()}
                            label='Birthday month'/>
                        <Field
                            name='year'
                            type='text'
                            component={renderSelectControl}
                            onChange={this.shouldFilterMonths}
                            options={options.year}
                            label='Birthday year'/>
                    </div>
                    <div className='select-control__hint'>{'We’d love to send your dog a birthday treat!'}</div>
                    <div className='form__select-aligner'>
                        <div className='radio-control'>
                            <div className='radio-control__description radio-control__description_short'>
                                <label className='radio-control__description-label'>
                                    Is your dog spay or neutered?
                                </label>
                                <Field name='is_altered' component={renderError}/>
                                <Field name='is_altered' component={renderWarning}/>
                            </div>
                            <div className='radio-control__controls'>
                                <label className='radio-control__controls-label'>
                                    <Field
                                        name='is_altered'
                                        component='input'
                                        type='radio'
                                        value='Yes'
                                        className='radio-control__controls-input'/>
                                    <span className='radio-control__controls-text'>Yes</span>
                                </label>
                                <label className='radio-control__controls-label'>
                                    <Field
                                        name='is_altered'
                                        component='input'
                                        type='radio'
                                        value='No'
                                        className='radio-control__controls-input'/>
                                    <span className='radio-control__controls-text'>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <button className={SubmitButtonClassNames} type='submit' disabled={submitting}>
                    Next - Health Information
                </button>
            </form>
        );
    }
}

const form = reduxForm({
    form: 'dogForm',
    destroyOnUnmount: true,
    enableReinitialize: true,
    validate,
    warn
});

// IntroductionForm = reduxForm({
//     form: 'dogForm',
//     destroyOnUnmount: false,
//     validate
// })(IntroductionForm);

const mapStateToProps = state => {
    return {
        initialValues: localStorage.getItem('introductionForm')
            ? JSON.parse(localStorage.getItem('introductionForm'))
            : fromJS({ is_altered: 'No' }),
        birthdayMonth: state.getIn(['form', 'dogForm', 'values', 'month']),
        registrationStep: state.getIn(['steps', 'registration_step'])
    };
};

const mapDispatchToProps = dispatch => ({
    handleDefaultValues: bindActionCreators(setDefaultIntroductionData, dispatch),
    handleSexOptions: bindActionCreators(getGenderOptions, dispatch),
    handleBreedOptions: bindActionCreators(getBreedOptions, dispatch),
    handleWeightOptions: bindActionCreators(getWeightOptions, dispatch),
    updateRegistrationStep: bindActionCreators(setCurrentRegistrationStep, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(form(IntroductionForm));
