import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCurrentCustomerFromDogsList, setCurrentDogFromDogsList } from '../../../actions/dashboard/employeeActions';
import AvatarPlaceholder from '../AvatarPlaceholder';
import { prettifyPhone } from '../../../helpers/normalize';

const SearchCard = ({ data, handleSettingCurrentCustomer, handleSettingCurrentDog }) => {
    const renderAvatar = () => {
        const photo = data.get('photo_processed') || data.get('photo');

        if (!photo) {
            return <div className='avatar__default-placeholder-container'><AvatarPlaceholder/></div>;
        }

        return (
            <div className='search-result-card__header-photo-container'>
                <div
                    className='search-result-card__header-photo'
                    style={{ background: `url(${photo}) center center / cover no-repeat`,
                    }}/>
            </div>
        );
    };

    const renderDogLink = (dogData) => {
        return (
            <span 
                className='search-result-card__item-content-link'
                title={dogData.get('name')}
                onClick={() => {
                    handleSettingCurrentDog(dogData);
                }}>
                {dogData.get('name')}
            </span>
        );
    };

    const renderDogsList = (dogsArray) => {
        if ((dogsArray === undefined) || (dogsArray.size === 0)) {
            return (
                <div className='search-result-card__item-header'>
                    No Dogs
                </div>
            );
        }

        const dogLinks = [];
        dogsArray.forEach(dogData => {
            dogLinks.push(renderDogLink(dogData));
            dogLinks.push(<span style={{ display: 'inline' }}>, </span>);
        });
        dogLinks.pop();

        return (
            <div className='search-result-card__item'>
                <div className='search-result-card__item-header'>Dogs</div>
                <p/>
                {dogLinks}
            </div>
        );
    };

    return (
        <div className='search-result-card-container'>
            <div className='search-result-card'>
                <div className='search-result-card__header'>
                    {renderAvatar()}
                    <div className='search-result-card__header-info'>
                        <div
                            className='search-result-card__class-name'
                            title={data.get('name') || 'Name not set'}
                            onClick={() => {
                                if (data.get('type') === 'customer') {
                                    handleSettingCurrentCustomer(data.getIn(['ownerData', 'id']));
                                } else if (data.get('type') === 'dog') {
                                    handleSettingCurrentDog(data.get('dogData'));
                                }
                            }}>
                            {data.get('name') || 'Name not set'}
                        </div>
                        {data.get('phone_number')
                            ? (<div className='search-result-card__phone-number' title={data.get('phone_number')}>
                                {prettifyPhone(data.get('phone_number'))}
                            </div>)
                            : (<div className='search-result-card__phone-number' title={'Phone number not set'}>
                                {'Phone number not set'}
                            </div>)}
                        {data.get('email')
                            ? (<div className='search-result-card__email' title={data.get('email')}>
                                {data.get('email')}
                            </div>)
                            : (<div className='search-result-card__email' title={'Email not set'}>
                                {'Email not set'}
                            </div>)}
                    </div>
                </div>
                <div className='search-result-card__content'>
                    {data.get('type') === 'customer' && renderDogsList(data.get('dogsArray'))}
                    {data.get('type') === 'dog' &&
                    <div className='search-result-card__item'>
                        <div className='search-result-card__item-header'>Owner</div>
                        <div className='search-result-card__item-content'>
                            {data.get('ownerName')}
                        </div>
                    </div>}
                    {data.get('type') === 'dog' &&
                    <div className='search-result-card__item'>
                        <div className='search-result-card__item-header'>Breed</div>
                        <div className='search-result-card__item-content'>
                            {data.get('breed')}
                        </div>
                    </div>}
                </div>
                <div className='search-result-card__footer'>
                    <div className='search-result-card__next-class'>
                        {data.get('next_class_start') !== null
                            ? `Next Class: ${data.get('next_class_start')}`
                            : 'No Upcoming Classes'}
                    </div>
                </div>
            </div>
        </div>
    );
};

SearchCard.propTypes = {
    data: PropTypes.shape({}).isRequired,
    index: PropTypes.number.isRequired,
    handleSettingCurrentDog: PropTypes.func,
    handleSettingCurrentCustomer: PropTypes.func,
    className: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
    handleSettingCurrentDog: bindActionCreators(setCurrentDogFromDogsList, dispatch),
    handleSettingCurrentCustomer: bindActionCreators(setCurrentCustomerFromDogsList, dispatch),
});

export default connect(null, mapDispatchToProps)(SearchCard);
