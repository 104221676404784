import PropTypes from 'prop-types';
import React from 'react';

const StepDescription = ({
    className,
    text,
    disclaimer,
    imageClassName = 'step-description__image',
}) => {
    return (
        <section className={className}>
            <div className='step-description__text'>{text}</div>
            {disclaimer && <div className='step-description__disclaimer'>{disclaimer}</div>}
            <div className='step-description__arrow'/>
            <div className={imageClassName}/>
        </section>
    );
};

StepDescription.propTypes = {
    className: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    disclaimer: PropTypes.string
};

export default StepDescription;
