import moment from 'moment-timezone';

export const CLASS_CREATE = ({ responseData }) => {
    return `Success! The ${responseData.name} was created.`;
};
export const CLASS_DELETE = ({ alertData }) => {
    return `${alertData.name} was deleted.`;
};
export const CLASS_EDIT = ({ responseData }) => {
    return `${responseData.name} was edited.`;
};

export const PROGRAM_PACKAGE_CREATE = ({ responseData }) => {
    return `Success! The ${responseData.name} was created.`;
};
export const PROGRAM_PACKAGE_DELETE = ({ alertData }) => {
    return `${alertData.name} was deleted.`;
};
export const PROGRAM_PACKAGE_EDIT = ({ responseData }) => {
    return `${responseData.name} was edited.`;
};

export const PROGRAM_SCHEDULE = ({ responseData }) => {
    const name = responseData.program_name;
    const weekDay = moment(responseData.start).format('dddd');
    const startDate = moment(responseData.start).format('MMMM D');
    const endDate = moment(responseData.end).format('MMMM D');
    const startTime = moment(responseData.start).format('h:mmA');
    const endTime = moment(responseData.end).format('h:mmA');
    if (responseData.type === 'new_training_events') {
        return `${name} was scheduled on each date from ${startDate} to ${endDate} with start at ${startTime} and end at ${endTime}`;
    } else {
        return `${name} was scheduled for ${weekDay} ${startDate} from ${startTime} to ${endTime}.`;
    }
};

export const EMPLOYEE_CREATE = ({ responseData }) => {
    return `${responseData.name} was added to the Directory.`;
};
export const EMPLOYEE_DELETE = ({ alertData }) => {
    return `${alertData.full_name} was deleted from the Directory.`;
};
export const EMPLOYEE_EDIT = ({ responseData }) => {
    return `${responseData.name} was edited.`;
};

export const FORM_CREATE = ({ responseData }) => {
    return `${responseData.name} was successfully created.`;
};
export const FORM_DELETE = ({ alertData }) => {
    return `${alertData.name} was successfully removed.`;
};
export const FORM_EDIT = ({ responseData }) => {
    return `${responseData.name} was successfully changed.`;
};

export const DOCUMENT_UPLOAD = ({ alertData }) => {
    return `${alertData.fileName} was successfully uploaded.`;
};
export const DOCUMENT_DELETE = ({ alertData }) => {
    return `${alertData.fileName} was deleted.`;
};

export const FILE_UPLOAD = '{fileName} was uploaded.';

export const CUSTOMER_CREATE = ({ responseData }) => {
    return `${responseData.full_name} was successfully created.`;
};
export const CUSTOMER_DELETE = ({ responseData }) => {
    return `${responseData.name} was deleted.`;
};
export const CUSTOMER_EDIT = ({ responseData }) => {
    return `Success! ${responseData.name} was updated.`;
};
export const USER_PASSWORD_RESET = ({ alertData }) => {
    return `${alertData.name} will receive an email to help reset their password.`;
};
export const CUSTOMER_DOG_ACTIVE = ({ responseData }) => {
    return `${responseData.name} was changed from Inactive to Active.`;
};
export const CUSTOMER_DOG_NOT_ACTIVE = ({ responseData }) => {
    return `${responseData.name} was changed from Active to Inactive.`;
};
export const CUSTOMER_VIP = ({ responseData }) => {
    return `${responseData.name} was successfully set as a VIP.`;
};
export const CUSTOMER_NOT_VIP = ({ responseData }) => {
    return `${responseData.name} was removed as a VIP.`;
};

export const DOG_CREATE = ({ responseData }) => {
    return `${responseData.name} was successfully created.`;
};
export const DOG_DELETE = ({ alertData }) => {
    return `${alertData.name} was deleted.`;
};
export const DOG_EDIT = ({ responseData }) => {
    return `Success! ${responseData.name} was updated.`;
};
export const DOG_ACCESS_LEVEL = ({ responseData, alertData }) => {
    const prev = alertData.prev;
    const next = responseData.access_level_detail;
    return `${responseData.name} Access Level was changed from ${prev} to ${next}.`;
};
export const DOG_DECEASED = ({ responseData }) => {
    return `${responseData.name} was marked as deceased.`;
};
export const DOG_NOT_DECEASED = ({ responseData }) => {
    return `${responseData.name} was marked as Not Deceased.`;
};

export const CAMPAIGN_CREATE_PUBLISH = ({ responseData }) => {
    return `${responseData.name} was successfully created and sent.`;
};
export const CAMPAIGN_CREATE_SAVE = ({ responseData }) => {
    return `${responseData.name} was successfully saved for later. To Send, edit and finish the campaign.`;
};
export const CAMPAIGN_DELETE = ({ alertData }) => {
    return `${alertData.name} was successfully deleted.`;
};

export const PROFILE_EDIT = () => 'Your Profile Information was successfully updated.';
export const PROFILE_PASSWORD_CHANGE = () => 'Your Password was successfully changed.';

export const MAX_FILE_SIZE_ERROR = ({ alertData }) => {
    return `Error. Maximum file size is ${alertData.maxSize}`;
};
export const MAX_RESOLUTION_ERROR = ({ alertData }) => {
    return `Error. Maximum file resolution is ${alertData.maxWidth}px by ${alertData.maxHeight}px`;
};

export const CHANGE_SCHEDULE_CLASS_ADDRESS = ({ responseData }) => {
    if (responseData.addressType === 'pickup_location') {
        return `Pick up address was changed.`;
    }
    if (responseData.addressType === 'drop_off_location') {
        return `Drop off address was changed.`;
    }
    return `Address was changed.`;
}