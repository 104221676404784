import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';

const Button = ({
    text = '',
    link = '',
    tooltip = '',
    icon: Icon,
    iconClassName = '',
    modifierClassName = '',
    onClickHandler,
    dispatchPush
}) => {
    const buttonClass = classNames({
        'button': true,
        'tooltip': tooltip,
        [modifierClassName]: modifierClassName
    });
    const click = () => {
        if (onClickHandler) onClickHandler();
        if (link) dispatchPush(link);
    };
    return (
        <div className={buttonClass} onClick={click}>
            {Icon &&
                <Icon className={`icon ${iconClassName}`}/>
            }
            { tooltip && <div className='tooltip__content'>{tooltip}</div> }
            {text && text}
        </div>
    );
};

Button.propTypes = {
    text: PropTypes.string,
    link: PropTypes.string,
    tooltip: PropTypes.string,
    icon: PropTypes.string,
    iconClassName: PropTypes.string,
    modifierClassName: PropTypes.string,
    onClickHandler: PropTypes.func,
    dispatchPush: PropTypes.func,
};


const mapDispatchToProps = dispatch => ({
    dispatchPush: bindActionCreators(push, dispatch),
});

export default connect(null, mapDispatchToProps)(Button);
