import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as PackagesTypes from '../types/dashboard/packagesTypes';

const INITIAL_STATE = fromJS({
    items: [],
    choices: [],
    count: 0,
    totalCount: 0,
    pageSize: 0,
    pages: 0,
    nextUrl: null,
    previousUrl: null,
    isLoading: false
});

export const packageNormalizer = (formData, state) => {
    formData = formData.toJS();

    const packageClasses = formData.package_classes.map((item) => {
        return item.value + '';
    }, this);

    const request = {
        name: formData.package_name,
        photo: formData.package_image ? formData.package_image[0] : undefined,
        description: formData.package_description,
        price: parseFloat(formData.package_price.replace(new RegExp('\\$', 'gi'), '')),
        expires_after: formData.package_expires_after + ' 00:00:00',
        training_classes: packageClasses,
        quantity: formData.package_size
    };

    return request;
};

export const trainingNormalizer = (formData, state) => {
    formData = formData.toJS();

    const linkToWebsite = formData.program_link_to_website.match(/^[a-zA-Z]+:\/\//) ? formData.program_link_to_website : 'http://' + formData.program_link_to_website;

    const request = {
        commission: formData.program_comission,
        photo: formData.program_image ? formData.program_image[0] : undefined,
        expires_after: formData.program_expires_after + ' 00:00:00',
        name: formData.program_name,
        price: parseFloat(formData.program_price.replace(new RegExp('\\$', 'gi'), '')),
        quantity: formData.program_sessions_size,
        description: formData.program_description,
        link_to_website: linkToWebsite,
    };

    return request;
};

const packageListReducer = handleActions({

    [PackagesTypes.INIT_PACKAGES_FILTER_OPTIONS]: (state, action) => {
        return state.update((state) => {
            if (!state.getIn(['choices']).size) {
                return state.setIn(['choices'], fromJS(action.payload));
            }
            return state;
        });
    },

    [PackagesTypes.SET_PACKAGE_LIST]: (state, action) => {
        const { results, count, next, previous } = action.payload;
        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);
        const pagesCount = Math.ceil(count / results.length);
        return state.update((state) => {
            return state.setIn(['items'], fromJS(results))
                        .setIn(['count'], count)
                        .setIn(['pageSize'], results.length)
                        .setIn(['pages'], pagesCount)
                        .setIn(['nextUrl'], nextUrl)
                        .setIn(['previousUrl'], previousUrl);
        });
    },
    [PackagesTypes.SET_NEXT_PACKAGE_LIST]: (state, action) => {
        const { results, count, next, previous } = action.payload;
        const pagesCount = Math.ceil(count / results.length);
        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);

        return state.update((state) => {
            return state
                .setIn(['items'], state.get('items').concat(fromJS(results)))
                .setIn(['count'], count)
                .setIn(['pageSize'], results.length)
                .setIn(['pages'], pagesCount)
                .setIn(['nextUrl'], nextUrl)
                .setIn(['previousUrl'], previousUrl);
        });
    },
    [PackagesTypes.START_PACKAGE_LIST_LOADING]: (state, action) => (
        state.setIn(['isLoading'], true)
    ),
    [PackagesTypes.FINISH_PACKAGE_LIST_LOADING]: (state, action) => (
        state.setIn(['isLoading'], false)
    ),
    [PackagesTypes.ADD_NEW_PACKAGE_TO_PACKAGES]: (state, action) => {
        const count = state.getIn(['count']);
        return state.set('items', state.get('items')
                    .insert(0, action.payload))
                    .setIn(['count'], count + 1);
    },
    [PackagesTypes.UPDATE_PACKAGE_TO_PACKAGES]: (state, action) => {
        const { payload } = action;
        const currentPackageKey = state.getIn(['items']).findKey(item => item.get('id') === payload.get('id'));
        return state.setIn(['items', currentPackageKey], fromJS(payload));
    },
    [PackagesTypes.UPDATE_PACKAGE_LIST]: (state, action) => {
        const { payload } = action;
        const currentId = payload.formConfig.itemId;
        return state.update((state) => {
            return state.updateIn(['items'],
                items => items.filter(item => item.get('id') !== currentId));
        });
    },
    [PackagesTypes.TOGGLE_PACKAGE_STATUS]: (state, action) => {
        const { id, 'is_paused': isPaused } = action.payload.data;
        let currentIndex;
        let _found = false;
        return state.update((state) => {
            state.get('items').every((item, index) => {
                if (item.get('id') === id) {
                    currentIndex = index;
                    _found = true;
                }
                return !_found;
            });
            return state.setIn(['items', currentIndex, 'is_paused'], isPaused);
        });
    }
}, INITIAL_STATE);

export default packageListReducer;
