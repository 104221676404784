import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const ChargeInfoControl = ({ currentPurchase, currentDiscount }) => {
  const purchase = currentPurchase ? currentPurchase.price : 0;
  const discount = currentDiscount ? currentDiscount.value : 0;

  const discountValue = discount === 0 ? 0 : (purchase * discount / 100).toFixed(2);
  const chargeValue = purchase === 0 ? 0 : (purchase - (purchase * discount / 100)).toFixed(2);

  return (
    <div className='form__flex-container'>
      <div className='form__middle-text'>
        <div className='form__left-text form__bold-text'>
          Program total: {'$' + purchase}
        </div>
        <div className='form__left-text form__bold-text'>
          Discount: {'$' + discountValue}
        </div>
        <div className='form__left-text form__margin-top-15'>
          Total to charge: {'$' + chargeValue}
        </div>
      </div>
    </div>
  );
};

ChargeInfoControl.propTypes = {
  currentPurchase: PropTypes.shape({}),
  currentDiscount: PropTypes.shape({})
};

const mapStateToProps = (state, { formName, purchaseType }) => ({
  currentPurchase: state.getIn(['form', formName, 'values', purchaseType]) || null,
  currentDiscount: state.getIn(['form', formName, 'values', 'discount']) || null
});

export default connect(mapStateToProps)(ChargeInfoControl);
