import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from '../reducers/dashboardPageReducer';

const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November',
    'December',
];

export const weekDaysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const getMonthFromString = (month) => {
    const monthNumber = `${0}${new Date(Date.parse(month + ' 1, 2012')).getMonth() + 1}`;
    if (month === 'October' || month === 'November' || month === 'December') {
        return monthNumber.substr(1);
    }
    return monthNumber;
};

export const addZero = (int) => {
    return int > 9 ? int : `${0}${int}`;
};

export const addZeroToLessThanTen = (int) => {
    return (int >= 0 && int < 10) ? `${0}${int}` : int;
};

export const dashesToSlashes = (string) => {
    return string.replace(/-/g, '/');
};

export const formatDateTimeToAMPM = (date, time) => {
    return new Date(`${date} ${time}`).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3');
};

export const formatTrainingDateTimeToAMPM = (date) => {
    return new Date(`${normalizeTimezoneDateToDateFormat(date)}`).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3');
};

export const formatDateToSlashes = (ISODateString, shortYear = false) => {
    const date = moment(ISODateString);
    const year = shortYear ? 'YY' : 'YYYY';
    return date.format(`MM/DD/${year}`);
};

export const formatDateToDashes = (dateString, shortYear = false) => {
    const date = moment.utc(dateString);
    const year = shortYear ? 'YY' : 'YYYY';
    return date.format(`${year}-MM-DD`);
};

export const getAgeBirthdayString = (dateString) => {
    if (!dateString) {
        return 'N/A';
    }

    const m = moment.utc(dateString);
    const age = moment().diff(m, 'years');
    return `${age}, ${monthNames[m.get('month')]} ${m.get('year')}`;
};

export const getMonthFromDate = (dateString) => {
    return monthNames[moment.utc(dateString).get('month')];
};

export const timePrettify = (time, format) => {
    let momentTime;
    if (time !== undefined) {
        momentTime = moment(time, 'HH:mm:ss');
    } else {
        return time;
    }

    if (format) {
        return momentTime.format(format);
    }

    if (momentTime.minute() === 0) {
        return momentTime.format('hA');
    }
    return momentTime.format('h:mmA');
};

export const timeToServerFormat = (time) => {
    const momentTime = moment(time, 'hh:mmA');

    if (!momentTime.isValid()) {
        return undefined;
    }

    return momentTime.format('HH:mm:ss');
};

export const dateToServerFormat = (dateValue, isISO = true) => {
    const date = moment(dateValue, ['MM/DD/YY']);
    if (isISO) {
        return moment(date.format('YYYY-MM-DD')).toISOString();
    }
    return date.format('YYYY-MM-DD');
};

export const time12to24 = (time) => {
    return moment(time, ['h:mmA']).format('HH:mm');
};

export const combineTrainingDateTime = (dateFieldValue, timeFieldValue) => {
    const date = moment(dateFieldValue, ['MM/DD/YY']);
    const time = moment(timeFieldValue, ['hh:mmA']);
    return moment(date.format('YYYY-MM-DD') + ' ' + time.format('HH:mm')).toISOString();
};

export const combineEventDateTime = (start, pickupStartTime) => {
    if (!pickupStartTime) {
        return moment(start);
    }

    const pickupStartTimeArr = pickupStartTime.split(':');
    const pickupStartTimeHours = pickupStartTimeArr[0];
    const pickupStartTimeMinutes = pickupStartTimeArr[1];

    const actualStartMoment = moment(start);
    actualStartMoment.hours(pickupStartTimeHours);
    actualStartMoment.minutes(pickupStartTimeMinutes);

    return actualStartMoment;
};

export const isAfterDropoffEnd = (end, dropoffEndTime) => {
    const dropoffEndTimeArr = dropoffEndTime.split(':');
    const dropoffEndTimeHours = dropoffEndTimeArr[0];
    const dropoffEndTimeMinutes = dropoffEndTimeArr[1];

    const endMoment = moment(end);
    endMoment.hours(dropoffEndTimeHours);
    endMoment.minutes(dropoffEndTimeMinutes);

    const now = moment();

    return now.unix() > endMoment.unix();
}

export const getDaysInt = (dateString) => { // 3 02:30:50 => 3
    const res = dateString.split(' ');
    if (res.length > 1) {
        const days = parseInt(res[0], 10);
        return days;
    }
    return 0;
};

export const getDays = (dateString) => { // 3 02:30:50 => 3 days
    const res = dateString.split(' ');
    if (res.length > 1) {
        const days = parseInt(res[0], 10);
        return days === 1 ? days + ' day' : days + ' days';
    }
    return 'Less than day';
};

export const isInPast = dateString => {
    return moment() > moment(dateString);
};

export const isInFutureOrToday = dateString => {
    return moment(dateString).isSameOrAfter(moment(), 'day');
};

export const isInPastFormatted = dateString => {
    return moment() > dateString;
};

export const isToday = dateString => {
    return moment().isSame(dateString, 'day');
};

export const isNowWithin2HoursBefore = dateString => {
    const secondsToStart = moment().diff(moment(dateString), 'seconds');
    return secondsToStart < 0 && Math.abs(secondsToStart) <= 60 * 60 * 2;
};
// get diff from two dates in HH:mm:ss format
export const calculateTimeDiff = ({ firstDate, secondDate }) => {
    const parsedFirstDate = firstDate.split(':');
    const parsedSecondDate = secondDate.split(':');

    const parsedTimeDiff = parsedFirstDate.map((item, i) => {
        return Math.abs(item - parsedSecondDate[i]);
    });

    return parsedTimeDiff.join(':');
};

export const addTimeDiff = ({ initialTime, changedTime, differedTime, diff }) => {
    const parsedDifferedTime = differedTime.split(':');
    const parsedDiff = diff.split(':');

    let parsedDiffedDate;

    // event starts earlier than it was before changed
    if (changedTime - initialTime < 0) {
        parsedDiffedDate = parsedDifferedTime.map((item, i) => {
            return addZeroToLessThanTen(Math.abs(item - parsedDiff[i]));
        });
    }

    // event starts latter than it was before changed
    if (changedTime - initialTime > 0) {
        parsedDiffedDate = parsedDifferedTime.map((item, i) => {
            return addZeroToLessThanTen(item + parsedDiff[i]);
        });
    }

    return parsedDiffedDate.join(':');
};

export const getWeekIntervalFromDate = ({ date, isAccurate }) => {
    const startDate = new Date(date);
    const endDate = new Date(date);
    if (isAccurate) {
        startDate.setDate(startDate.getDate() - startDate.getDay());
        endDate.setDate(endDate.getDate() - endDate.getDay() + 6);
    } else {
        startDate.setDate(startDate.getDate() - startDate.getDay() - 1);
        endDate.setDate(endDate.getDate() - endDate.getDay() + 7);
    }

    return { startDate, endDate };
};

export const getMonthIntervalFromDate = (date) => {
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return { startDate, endDate };
};

// date - 2017-10-25T17:00:00Z
export const normalizeTimezoneDateToDateFormat = (date) => {
    const dateWithoutTz = moment(date).format().slice(0, -6);
    const a = dateWithoutTz.split(/[^0-9]/);
    return new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
};

export const formatISODateToDateWithTimezone = (ISODateString) => {
    const d = new Date(ISODateString);
    return new Date(d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000));
};

export const formatTo12HourFormat = (date) => {
    return moment(date).format('h:mm A');
};

export const formatTo12HourFormatWithDate = (date) => {
    return moment(date).format('MM/DD/YY h:mm A');
};

export const formatNoDateTimeTo12HourFormat = time => {
    return moment(time, ['h:mm A']).format('h:mm A');
};

export const convertToNativeDate = (date, alreadyZoned = false) => {
    // DD is between 1 and 9 so we need to add 0 for proper .utc in safari...
    if (date && date.length === 9) {
        const dateArray = date.split('-');
        dateArray[2] = addZero(dateArray[2]);
        date = dateArray.join('-');
    }
    const normalizedData = alreadyZoned ? moment.utc(date).format().slice(0, -1) : moment(date).format().slice(0, -6);
    return new Date(normalizedData);
};

export const convertNativeDateToDateString = ({ dateStringFormat = DEFAULT_DATE_FORMAT, dateObj }) => {
    const dateString = `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
    return moment(dateString, 'YYYY-M-D').format(dateStringFormat);
};
