/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { createStore } from 'redux';
import { reducer as formReducer } from 'redux-form';
import Form from './Form';
import * as GetOptionsActions from '../../actions/modal/getOptionsActions';

export const onGetOptions = (field, extra) => {
    return (dispatch) => {
        let getOptionsFunction;
        
        if (typeof field.options === 'function') {
            getOptionsFunction = field.options;
        } else {
            // Must be string then.
            getOptionsFunction = GetOptionsActions[field.options];
        }
        
        return dispatch(getOptionsFunction(extra));
    };
};

export const generateField = (props) => {
    const {
        key,
        fieldConfig,
        formProps,
        isOpened,
        isDisabled
    } = props;
    fieldConfig.disabled = isDisabled;
    const { component: FieldComponent, ...other } = fieldConfig;
    return (
        <FieldComponent
            key={key}
            name={fieldConfig.name}
            className={fieldConfig.className}
            formProps={formProps}
            isOpened={isOpened}
            fieldConfig={other}/>
    );
};

export const generateFields = ({ formConfig, formProps, initialValues, isOpened }) => {
    const { fields = [], isFieldDisabled } = formConfig;
    const renderFields = (fields) => {
        return fields.map((fieldConfig, i) => {
            // If group of fields.
            if (fieldConfig.fields) {
                return (
                    <div className={fieldConfig.className} key={'group_' + i}>
                        {fieldConfig.title && <div className='form__field-description'>{fieldConfig.title}</div>}
                        {renderFields(fieldConfig.fields)}
                    </div>
                );
            }
            // if (field === 'separator') {
            //     return (
            //         <div key={'separator_' + i}>
            //             {generateField({ fieldName: field, initialValues, formProps, isOpened })}
            //         </div>
            //     );
            // }
            return generateField({
                key: i,
                initialValues,
                formProps,
                isOpened,
                // todo HANDLE IS_DISABLED
                // isDisabled: isFieldDisabled ? isFieldDisabled(formProps, field) : undefined,
                fieldConfig
            });
        });
    };
    
    return renderFields(fields);
};

export const mockFormState = (initialState = fromJS({ form: {} })) => createStore((state = initialState, action) => {
    if (action.payload && action.payload.toJS) {
        action.payload = action.payload.toJS();
    }
    return state.set('form', formReducer(state.get('form'), action));
});

export const fieldDefaultPropTypes = {
    fieldConfig: PropTypes.shape({}),
    formProps: PropTypes.shape({}),
    isOpened: PropTypes.bool
};

export default Form;
