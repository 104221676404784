import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { find } from 'lodash';
import { Field, reduxForm, SubmissionError } from 'redux-form/immutable';
import LocationField from './LocationField';
import PaymentField from '../../../../common/Form/PaymentField';
import { bookClass } from '../../../../../services/classOccurrences';
import { getSteppedFormValues } from '../../../../../reducers/steppedFormReducer';

const formatTime = (dateString, timeString) => {
    const time = moment(`${dateString} ${timeString}`);
    const hours = time.format('h');
    const minutes = time.minutes() ? time.format(':mm') : '';
    const ampm = time.format('a');
    return hours + minutes + ampm;
};

const formatDate = (dateValue) => {
    const date = moment(dateValue, ['YYYY-MM-DD']);
    return date.format('MM/DD/YY');
};

class PreConfirmationStep extends PureComponent {

    getPickupLocationData = () => {
        const { submitData: { pickup }, formData: { addresses } } = this.props;
        return find(addresses, address => address.id === pickup) || null;
    }

    getDropoffLocationData = () => {
        const { submitData: { dropoff }, formData: { addresses } } = this.props;
        return find(addresses, address => address.id === dropoff) || null;
    }

    getPaymentData = () => {
        const { formData: { classInfo, cards }, submitData: { card, packageCredit, dogs } } = this.props;
        const { packagesAvailable } = classInfo;
        const selectedDogs = typeof dogs === 'string' ? dogs.split(',') : dogs;
        const currentCard = find(cards, paymentCard => paymentCard.id === card);
        const paymentData = {};
        const { available_quantity: availableQuantity } = find(packagesAvailable, ({ id }) => id === packageCredit) || {}; // eslint-disable-line
        if (availableQuantity && availableQuantity >= selectedDogs.length) { // package credits enough for payment
            paymentData.packageCredit = {
                id: packageCredit,
                count: selectedDogs.length
            };
        } else if (availableQuantity && availableQuantity < selectedDogs.length) {
            paymentData.packageCredit = {
                id: packageCredit,
                count: availableQuantity
            };
            paymentData.card = currentCard;
        } else {
            paymentData.card = currentCard;
        }
        paymentData.packagesAvailable = classInfo.packagesAvailable;
        return paymentData;
    }
    submitStep = () => {
        const {
            userEmail,
            bookClass,
            goToStep,
            formData: {
                classInfo: {
                    id,
                    title,
                },
            },
            submitData: {
                dropoff,
                pickup,
                card,
                dogs,
                packageCredit,
            },
            currentClass,
        } = this.props;
        const submitData = {
            classId: id,
            dogs: dogs.split(','),
            card,
            pickup_location: pickup,
            drop_off_location: dropoff,
        };
        if (packageCredit) {
            submitData.purchased_package = packageCredit;
        }
        return bookClass(submitData)
            .then(() => {
                if (/^sports assessment$/i.test(title)) {
                    window.gtag('event', 'assessment_sign_up', {
                        'email': userEmail
                    });
                }
                for (let i = 0; i < submitData.dogs.length; i++) {
                    window._dcq.push(['track', `Booked ${title}`]);
                }
                const selectedDogs = typeof dogs === 'string' ? dogs.split(',') : dogs;
                const { packagesAvailable } = currentClass;
                if (packagesAvailable && packagesAvailable.length && packageCredit) {
                    const {
                        available_quantity: availableQuantity,
                        package_detail: packageDetail,
                    } = find(packagesAvailable, ({ id }) => id === packageCredit); // eslint-disable-line
                    if (availableQuantity) {
                        const count = availableQuantity < selectedDogs.length ? availableQuantity : selectedDogs.length;
                        for (let i = 0; i < count; i++) {
                            window._dcq.push(['track', `Used package ${packageDetail.name}`]);
                        }
                    }
                }
                goToStep(5);
            })
            .catch(({ response: { data } }) => {
                const {
                    drop_off_location: dropoffLocation,
                    pickup_location: pickupLocation
                } = data;
                throw new SubmissionError({
                    dropoff: dropoffLocation,
                    pickup: pickupLocation,
                    _error: data
                });
            });
    }

    renderErrors = (errorList) => {
        const { classInfo: { valid_zip_codes: validZipCodes } } = this.props.formData; // eslint-disable-line
        const result = [];
        const getErrorMessage = (error, errorList) => {
            const isLocationError = (error === 'pickup_location' || error === 'drop_off_location');
            if (error === 'card' && errorList[error][0] === 'This field is required.') {
                errorList[error] = 'A default credit card is not associated with this account.  Please add a default credit card to your profile and try again.';
            }

            result.push(
                <div className='form-step__error' key={error}>
                    {errorList ? errorList[error].toString() : error}
                    {isLocationError &&
                        <div className='tooltip'>
                            Available Zip Codes for this Class
                            <div className='tooltip__content'>{validZipCodes.join(', ')}</div>
                        </div>
                    }
                </div>
            );
        };

        // FIT-2116
        // new stripe server errors case
        if (errorList.error_message) {
            getErrorMessage('error_message', errorList);
        } else if (Array.isArray(errorList)) {
            errorList.forEach((item, index) => {
                if (typeof item === 'object') {
                    Object.keys(item).forEach((err) => {
                        getErrorMessage(err, errorList[index]);
                    });
                } else {
                    getErrorMessage(item);
                }
            });
        } else {
            Object.keys(errorList).forEach((err) => {
                getErrorMessage(err, errorList);
            });
        }
        return result;
    }

    render() {
        const { invalid, submitting, onCancelHandler,
            submitText, handleSubmit, formData, goToStep, error } = this.props;
        const submitButtonClassNames = classNames({
            'button modal-dialog__button': true,
            'button_disabled': (invalid || submitting)
        });
        const headClasses = classNames({
            'pickup-location__head': true,
            'pre-confirmation__head': true,
            'pre-confirmation__head_small-margin': error
        });

        const { classInfo: { title, date, pickup_start_time, pickup_end_time } } = formData;
        const pickupStart = formatTime(date, pickup_start_time);
        const pickupEnd = formatTime(date, pickup_end_time);
        const classDate = formatDate(date);
        const pickupLocation = this.getPickupLocationData();
        const dropoffLocation = this.getDropoffLocationData();
        const paymentInfo = this.getPaymentData();
        return (
            <form
                className='pre-confirmation'
                onSubmit={handleSubmit(this.submitStep)}>
                <div className='form-step__form-content pickup-location__form-content'>
                    <div className={headClasses}>
                        <p className='pickup-location__head-title'>You are booking <span>{title}</span>, scheduled on <span>{classDate}</span> with a pickup time between <span>{pickupStart}</span> and <span>{pickupEnd}</span>.</p>
                    </div>
                    {!!error &&
                        <div className='form-step__errors-list'>
                            {this.renderErrors(error)}
                        </div>
                    }
                    <div>
                        <Field
                            name='pickup'
                            label='Pickup Location'
                            data={pickupLocation}
                            handleChange={() => goToStep(6)}
                            component={LocationField} />
                        <Field
                            name='dropoff'
                            label='Dropoff Location'
                            data={dropoffLocation}
                            handleChange={() => goToStep(7)}
                            component={LocationField} />

                        <Field
                            name='card'
                            label='Payment method'
                            data={paymentInfo}
                            handleChange={() => goToStep(8)}
                            component={PaymentField} />
                    </div>
                </div>
                <div className='modal-dialog__form-footer modal-dialog__form-footer_column'>
                    <button
                        className={submitButtonClassNames}
                        type='submit'
                        disabled={submitting}>
                        {(submitting)
                            ? 'Submitting...'
                            : submitText || 'Next'
                        }
                    </button>
                    <span className='modal-dialog__cancel-button' onClick={onCancelHandler}>Cancel</span>
                </div>
            </form>
        );
    }
}

PreConfirmationStep.propTypes = {
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    onCancelHandler: PropTypes.func,
    submitText: PropTypes.string,
    handleSubmit: PropTypes.func,
    bookClass: PropTypes.func,
    formData: PropTypes.shape({}),
    submitData: PropTypes.shape({}),
    goToStep: PropTypes.func,
    error: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.arrayOf(PropTypes.shape({}))
    ]),
    userEmail: PropTypes.string,
};

const form = reduxForm({
    form: 'PreConfirmationStepForm',
    enableReinitialize: true,
});

const mapStateToProps = state => {
    const { data } = getSteppedFormValues('booking')(state);
    return {
        currentClass: data && data.classInfo ? data.classInfo : {},
        userEmail: state.getIn(['user', 'email']),
    };
};

const mapDispatchToProps = {
    bookClass
};

export default connect(mapStateToProps, mapDispatchToProps)(form(PreConfirmationStep));
