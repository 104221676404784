import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as DashboardPageTypes from '../types/dashboard/dashboardPageTypes';
import { SET_DASHBOARD_ACTIVE_PAGE } from '../types/tabTypes';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

const INITIAL_STATE = fromJS({
    activePage: 'glance',
    glanceView: 'grid',
    currentDate: undefined,
    currentNotesDate: undefined,
    currentFilter: undefined,
    selectedZoneCard: undefined,
    selectedTrainingZoneCard: undefined,
    glanceDataAll: {
        isLoading: false,
        total: 0,
        items: undefined
    },
    glanceDataLocation: {
        location: undefined,
        isLoading: false,
        items: undefined
    },
    glanceTrainingDataAll: {
        isLoading: false,
        total: 0,
        items: undefined
    },
    glanceTrainingDataLocation: {
        location: undefined,
        isLoading: false,
        items: undefined
    },
    dailyNotes: {
        isLoading: false,
        items: undefined,
        count: 0,
        next: null
    },
    glanceClassesProductsAll: {
        isLoading: false,
        items: undefined,
    },
    glanceTrainingsProductsAll: {
        isLoading: false,
        items: undefined,
    },
    glanceClassesProducts: {
        isLoading: false,
        items: undefined,
    },
    glanceTrainingsProducts: {
        isLoading: false,
        items: undefined,
    },
});

const dashboardPageReducer = handleActions({
    [DashboardPageTypes.RESET_DASHBOARD_PAGE_REDUCER]: () => {
        return INITIAL_STATE;
    },
    [DashboardPageTypes.START_GLANCE_DATA_ALL_LOADING]: state => {
        return state.setIn(['glanceDataAll', 'isLoading'], true);
    },
    [DashboardPageTypes.START_GLANCE_TRAINING_DATA_ALL_LOADING]: state => {
        return state.setIn(['glanceTrainingDataAll', 'isLoading'], true);
    },
    [DashboardPageTypes.FINISH_GLANCE_DATA_ALL_LOADING]: state => {
        return state.setIn(['glanceDataAll', 'isLoading'], false);
    },
    [DashboardPageTypes.FINISH_GLANCE_TRAINING_DATA_ALL_LOADING]: state => {
        return state.setIn(['glanceTrainingDataAll', 'isLoading'], false);
    },
    [DashboardPageTypes.START_GLANCE_DATA_LOCATION_LOADING]: state => {
        return state.setIn(['glanceDataLocation', 'isLoading'], true);
    },
    [DashboardPageTypes.START_GLANCE_TRAINING_DATA_LOCATION_LOADING]: state => {
        return state.setIn(['glanceTrainingDataLocation', 'isLoading'], true);
    },
    [DashboardPageTypes.FINISH_GLANCE_DATA_LOCATION_LOADING]: state => {
        return state.setIn(['glanceDataLocation', 'isLoading'], false);
    },
    [DashboardPageTypes.FINISH_GLANCE_TRAINING_DATA_LOCATION_LOADING]: state => {
        return state.setIn(['glanceTrainingDataLocation', 'isLoading'], false);
    },
    [DashboardPageTypes.START_GLANCE_CLASSES_PRODUCTS_DATA_ALL_LOADING]: state => {
        return state.setIn(['glanceClassesProducts', 'isLoading'], true)
                    .setIn(['glanceClassesProductsAll', 'isLoading'], true);
    },
    [DashboardPageTypes.FINISH_GLANCE_CLASSES_PRODUCTS_DATA_ALL_LOADING]: state => {
        return state.setIn(['glanceClassesProducts', 'isLoading'], false)
                    .setIn(['glanceClassesProductsAll', 'isLoading'], false);
    },
    [DashboardPageTypes.START_GLANCE_TRAININGS_PRODUCTS_DATA_ALL_LOADING]: state => {
        return state.setIn(['glanceTrainingsProducts', 'isLoading'], true)
                    .setIn(['glanceTrainingsProductsAll', 'isLoading'], true);
    },
    [DashboardPageTypes.FINISH_GLANCE_TRAININGS_PRODUCTS_DATA_ALL_LOADING]: state => {
        return state.setIn(['glanceTrainingsProducts', 'isLoading'], false)
                    .setIn(['glanceTrainingsProductsAll', 'isLoading'], false);
    },
    [DashboardPageTypes.START_DAILY_NOTES_LOADING]: state => {
        return state.setIn(['dailyNotes', 'isLoading'], true);
    },
    [DashboardPageTypes.FINISH_DAILY_NOTES_LOADING]: state => {
        return state.setIn(['dailyNotes', 'isLoading'], false);
    },
    [DashboardPageTypes.SET_CURRENT_DATE]: (state, action) => {
        return state.set('currentDate', action.payload)
            // Clear glance data when we change the current date.
            .set('glanceDataAll', fromJS({
                isLoading: false,
                total: 0,
                items: []
            }))
            .set('glanceDataLocation', fromJS({
                location: undefined,
                isLoading: false,
                items: []
            }))
            .set('glanceTrainingDataAll', fromJS({
                isLoading: false,
                total: 0,
                items: []
            }))
            .set('glanceTrainingDataLocation', fromJS({
                location: undefined,
                isLoading: false,
                items: []
            }))
            .set('glanceClassesProducts', fromJS({
                isLoading: false,
                items: []
            }))
            .set('glanceTrainingsProducts', fromJS({
                isLoading: false,
                items: []
            }))
            .set('glanceClassesProductsAll', fromJS({
                isLoading: false,
                items: []
            }))
            .set('glanceTrainingsProductsAll', fromJS({
                isLoading: false,
                items: []
            }));
    },
    [DashboardPageTypes.FILTER_LINEAR_DASHBOARD_DATA]: (state, action) => {
        if (action.payload === ""){
            return state.setIn(['glanceClassesProducts', 'items'], state.getIn(['glanceClassesProductsAll', 'items']))
                    .setIn(['glanceTrainingsProducts', 'items'], state.getIn(['glanceTrainingsProductsAll', 'items']))
        } else {
            const name = action.payload.toLowerCase()
            const glanceClassesProductsItems = state.getIn(['glanceClassesProductsAll', 'items']).toJS()
            const glanceTrainingsProductsItems = state.getIn(['glanceTrainingsProductsAll', 'items']).toJS()
            const filteredGlanceClassesProductsItems = glanceClassesProductsItems.filter(item =>
                                                                        item.dog_detail.name.toLowerCase().startsWith(name) ||
                                                                        item.owner_detail.full_name.toLowerCase().startsWith(name) ||
                                                                        item.class_occurrence_detail.program_name.toLowerCase().startsWith(name))

            const filteredGlanceTrainingsProductsItems = glanceTrainingsProductsItems.filter(item =>
                                                                        item.dog_detail.name.toLowerCase().startsWith(name) ||
                                                                        item.owner_detail.full_name.toLowerCase().startsWith(name) ||
                                                                        item.event.program_name.toLowerCase().startsWith(name))
            return state.setIn(['glanceClassesProducts', 'items'], fromJS(filteredGlanceClassesProductsItems))
                        .setIn(['glanceTrainingsProducts', 'items'], fromJS(filteredGlanceTrainingsProductsItems))
            }
    },
    [DashboardPageTypes.SET_CURRENT_NOTES_DATE]: (state, action) => {
        return state.set('currentNotesDate', action.payload)
            // Clear glance data when we change the current date.
            .set('dailyNotes', fromJS({
                isLoading: false,
                items: undefined,
                count: 0,
                next: null
            }))
    },
    [DashboardPageTypes.SET_CURRENT_FILTER]: (state, action) => {
        return state.set('currentFilter', fromJS(action.payload))
            // Clear glance data when we change the current filter.
            .set('glanceDataAll', fromJS({
                isLoading: false,
                total: 0,
                items: []
            }))
            .set('glanceDataLocation', fromJS({
                location: undefined,
                isLoading: false,
                items: []
            }))
            .set('glanceTrainingDataAll', fromJS({
                isLoading: false,
                total: 0,
                items: []
            }))
            .set('glanceTrainingDataLocation', fromJS({
                location: undefined,
                isLoading: false,
                items: []
            }))
            .set('glanceClassesProducts', fromJS({
                isLoading: false,
                items: []
            }))
            .set('glanceTrainingsProducts', fromJS({
                isLoading: false,
                items: []
            }))
            .set('glanceClassesProductsAll', fromJS({
                isLoading: false,
                items: []
            }))
            .set('glanceTrainingsProductsAll', fromJS({
                isLoading: false,
                items: []
            }));
    },
    [SET_DASHBOARD_ACTIVE_PAGE]: (state, action) => {
        return state.set('activePage', action.payload);
    },
    [DashboardPageTypes.SET_SELECTED_ZONE_CARD]: (state, action) => {
        return state.set('selectedZoneCard', fromJS(action.payload));
    },
    [DashboardPageTypes.SET_SELECTED_TRAINING_ZONE_CARD]: (state, action) => {
        return state.set('selectedTrainingZoneCard', fromJS(action.payload));
    },
    [DashboardPageTypes.SET_GLANCE_ALL_ITEMS]: (state, action) => {
        return state.set('glanceDataAll', fromJS({
            isLoading: false,
            total: action.payload.total,
            items: action.payload.results
        }));
    },
    [DashboardPageTypes.SET_GLANCE_TRAINING_ALL_ITEMS]: (state, action) => {
        return state.set('glanceTrainingDataAll', fromJS({
            isLoading: false,
            total: action.payload.total,
            items: action.payload.results
        }));
    },
    [DashboardPageTypes.SET_GLANCE_LOCATION_ITEMS]: (state, action) => {
        return state.set('glanceDataLocation', fromJS({
            trainingClassName: action.payload.trainingClassName,
            isLoading: false,
            items: action.payload.data
        }));
    },
    [DashboardPageTypes.SET_GLANCE_TRAINING_LOCATION_ITEMS]: (state, action) => {
        return state.set('glanceTrainingDataLocation', fromJS({
            trainingName: action.payload.trainingName,
            isLoading: false,
            items: action.payload.data
        }));
    },
    [DashboardPageTypes.SET_GLANCE_CLASSES_PRODUCTS_DATA]: (state, action) => {
        return state.set('glanceClassesProducts', fromJS({
            isLoading: false,
            items: action.payload.data
        }))
        .set('glanceClassesProductsAll', fromJS({
            isLoading: false,
            items: action.payload.data
        }));
    },
    [DashboardPageTypes.SET_GLANCE_TRAININGS_PRODUCTS_DATA]: (state, action) => {
        return state.set('glanceTrainingsProducts', fromJS({
            isLoading: false,
            items: action.payload.data
        }))
        .set('glanceTrainingsProductsAll', fromJS({
            isLoading: false,
            items: action.payload.data
        }));
    },
    [DashboardPageTypes.SET_GLANCE_VIEW]: (state, action) => {
        return state.set('glanceView', action.payload)
    },
    [DashboardPageTypes.SET_DAILY_NOTES_ITEMS]: (state, action) => {
        const { count, next, results } = action.payload;
        return state.set('dailyNotes', fromJS({
            isLoading: false,
            items: results,
            count,
            next
        }));
    },
    [DashboardPageTypes.PREPEND_DAILY_NOTES_ITEMS]: (state, action) => {
        return state.update((state) => {
            return state.setIn(['dailyNotes', 'items'], state.getIn(['dailyNotes', 'items'])
                .insert(0, fromJS(action.payload)));
        });
    },
    [DashboardPageTypes.APPEND_DAILY_NOTES_ITEMS]: (state, action) => {
        const { count, next, results } = action.payload;
        return state.update(state => {
            return state.setIn(['dailyNotes', 'next'], next)
                .setIn(['dailyNotes', 'isLoading'], false)
                .setIn(['dailyNotes', 'count'], count)
                .setIn(['dailyNotes', 'items'], state.getIn(['dailyNotes', 'items']).concat(fromJS(results)));
        });
    },
    [DashboardPageTypes.EDIT_CLASS_PRODUCT_LOCATIONS] : (state, action) => {
        const pickupLocationValue = action.payload.get('pickup_location');
        const dropoffLocationValue = action.payload.get('drop_off_location');
        const pickupLocationDetail = action.payload.get('pickup_location_detail');
        const dropoffLocationDetail = action.payload.get('drop_off_location_detail');

        const isPickupLocationInitial = pickupLocationValue === undefined;
        const isDropOffLocationInitial = dropoffLocationValue === undefined;

        if (isPickupLocationInitial && isDropOffLocationInitial) {
            return state;
        }

        const currentKey = state.getIn(['glanceClassesProducts', 'items'])
                                .findKey(product => {
                                    return product.getIn(['id']) === action.payload.getIn(['id']);
                                });

        if (isPickupLocationInitial) {
            return state.setIn(['glanceClassesProducts', 'items', currentKey, 'drop_off_location'], dropoffLocationValue)
                        .setIn(['glanceClassesProducts', 'items', currentKey, 'drop_off_location_detail'], dropoffLocationDetail.toJS());
        }

        if (isDropOffLocationInitial) {
            return state.setIn(['glanceClassesProducts', 'items', currentKey, 'pickup_location'], pickupLocationValue)
                        .setIn(['glanceClassesProducts', 'items', currentKey, 'pickup_location_detail'], pickupLocationDetail.toJS());
        }

        return state.setIn(['glanceClassesProducts', 'items', currentKey, 'pickup_location'], pickupLocationValue)
                    .setIn(['glanceClassesProducts', 'items', currentKey, 'pickup_location_detail'], pickupLocationDetail.toJS())
                    .setIn(['glanceClassesProducts', 'items', currentKey, 'drop_off_location'], dropoffLocationValue)
                    .setIn(['glanceClassesProducts', 'items', currentKey, 'drop_off_location_detail'], dropoffLocationDetail.toJS());
    },
    [DashboardPageTypes.EDIT_TRAINING_PRODUCT_LOCATIONS] : (state, action) => {
        const pickupLocationValue = action.payload.get('pickup_location');
        const dropoffLocationValue = action.payload.get('drop_off_location');
        const pickupLocationDetail = action.payload.get('pickup_location_detail');
        const dropoffLocationDetail = action.payload.get('drop_off_location_detail');

        const isPickupLocationInitial = pickupLocationValue === undefined;
        const isDropOffLocationInitial = dropoffLocationValue === undefined;

        if (isPickupLocationInitial && isDropOffLocationInitial) {
            return state;
        }

        const currentKey = state.getIn(['glanceTrainingsProducts', 'items'])
                                .findKey(product => {
                                    return product.getIn(['id']) === action.payload.getIn(['id']);
                                });

        if (isPickupLocationInitial) {
            return state.setIn(['glanceTrainingsProducts', 'items', currentKey, 'drop_off_location'], dropoffLocationValue)
                        .setIn(['glanceTrainingsProducts', 'items', currentKey, 'drop_off_location_detail'], dropoffLocationDetail.toJS());
        }

        if (isDropOffLocationInitial) {
            return state.setIn(['glanceTrainingsProducts', 'items', currentKey, 'pickup_location'], pickupLocationValue)
                        .setIn(['glanceTrainingsProducts', 'items', currentKey, 'pickup_location_detail'], pickupLocationDetail.toJS());
        }

        return state.setIn(['glanceTrainingsProducts', 'items', currentKey, 'pickup_location'], pickupLocationValue)
                    .setIn(['glanceTrainingsProducts', 'items', currentKey, 'pickup_location_detail'], pickupLocationDetail.toJS())
                    .setIn(['glanceTrainingsProducts', 'items', currentKey, 'drop_off_location'], dropoffLocationValue)
                    .setIn(['glanceTrainingsProducts', 'items', currentKey, 'drop_off_location_detail'], dropoffLocationDetail.toJS());
    },
    [DashboardPageTypes.CHANGE_CLASS_PRODUCT_STATUS] : (state, action) => {
        const { id, status, status_detail } = action.payload;
        const currentKey = state.getIn(['glanceClassesProducts', 'items'])
            .findKey(product => product.get('id') === id);
        return state
            .setIn(['glanceClassesProducts', 'items', currentKey, 'status'], status)
            .setIn(['glanceClassesProducts', 'items', currentKey, 'status_detail'], status_detail)
    },
    [DashboardPageTypes.CHANGE_TRAINING_PRODUCT_STATUS] : (state, action) => {
        const { id, status, status_detail } = action.payload;
        const currentKey = state.getIn(['glanceTrainingsProducts', 'items'])
            .findKey(product => product.get('id') === id);
        return state
            .setIn(['glanceTrainingsProducts', 'items', currentKey, 'status'], status)
            .setIn(['glanceTrainingsProducts', 'items', currentKey, 'status_detail'], status_detail)
    },
    [DashboardPageTypes.UPDATE_DASHBOARD_PRODUCT_REPORTS] : (state, action) => {
        const currentKey = state.getIn(['glanceClassesProducts', 'items'])
            .findKey(product => product.get('id') === action.payload.get('class_product'));
        if (currentKey !== undefined) {
            return state.setIn(['glanceClassesProducts', 'items', currentKey, 'reports'],
                state.getIn(['glanceClassesProducts', 'items', currentKey, 'reports'])
                    .insert(0, fromJS({ id: action.payload.get('id') })));
        }
        return state;
    },
    [DashboardPageTypes.REMOVE_DASHBOARD_PRODUCT_REPORT_CARD] : (state, action) => {
        const currentKey = state.getIn(['glanceClassesProducts', 'items'])
            .findKey(product => product.get('id') === action.payload);
        if (currentKey !== undefined) {
            return state.setIn(['glanceClassesProducts', 'items', currentKey, 'reports'], fromJS([]));
        }
        return state
    }

}, INITIAL_STATE);

export default dashboardPageReducer;
