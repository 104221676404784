import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import AjaxLoader from '../../../../../common/AjaxLoader/';
import ReportCard from '../../../../Dashboard/Dogs/ReportPage/ReportCard/ReportCard';
import NoData from '../../../../../common/NoData/';
import Button from '../../../../../common/Button/';
import { capitalizeFirstLetter } from '../../../../../../helpers/text';

const DogReportCardsList = ({ dogName, dogReports, getDogReports }) => {
    const reports = dogReports.items;
    let cards;
    dogName = capitalizeFirstLetter(dogName);

    if (dogReports.count) {
        cards = reports.map((report, index) => {
            return (
                <ReportCard key={`report_${index}`} data={report}/>
            );
        });
    }

    return (
        <div className='content-block'>
            <div className='content-block__header'>
                <div className='content-block__header-text'>Snapshots</div>
            </div>
            {
                dogReports.count ? 
                    <InfiniteScroll
                        className='dog-details__cards'
                        pageStart={0}
                        loadMore={() => getDogReports(false, dogReports.nextUrl)}
                        hasMore={dogReports.nextUrl !== null}
                        loader={<AjaxLoader />}>
                        { cards }
                    </InfiniteScroll>
                    : <NoData
                        title={`${dogName} doesn’t have any Snapshots.`}
                        subtitle='Book a class to see your dog in action.'
                        iconType='happy'
                        className='dog-details__no-data'
                        borders={false}
                        action={<Button text='View classes' link='/classes' />}/>
            }
        </div>
    );
};

DogReportCardsList.propTypes = {
    dogName: PropTypes.string.isRequired,
    getDogReports: PropTypes.func.isRequired,
    dogReports: PropTypes.shape({}).isRequired,
};

export default DogReportCardsList;
