import { createAction } from 'redux-actions';
import * as DirectoryTypes from '../../types/dashboard/directoryTypes';

export const setDirectoryList = createAction(DirectoryTypes.SET_DIRECTORY_LIST);
export const setNextDirectoryList = createAction(DirectoryTypes.SET_NEXT_DIRECTORY_LIST);
export const initDirectoryFilterOptions = createAction(DirectoryTypes.INIT_DIRECTORY_FILTER_OPTIONS);
export const setDirectoryFilterOptions = createAction(DirectoryTypes.SET_DIRECTORY_FILTER_OPTIONS);

export const prependDirectories = createAction(DirectoryTypes.PREPEND_DIRECTORIES);

export const startDirectoryListLoading = createAction(DirectoryTypes.START_DIRECTORY_LIST_LOADING);
export const finishDirectoryListLoading = createAction(DirectoryTypes.FINISH_DIRECTORY_LIST_LOADING);
export const removeDirectoryEmployeeById = createAction(DirectoryTypes.REMOVE_DIRECTORY_EMPLOYEE_BY_ID);
