const validate = values => {
    const errors = {};
    Object.keys(values.toJS()).forEach((value) => {
        errors[value] = {};

        if (!values.getIn([`${value}`, 'name'])) {
            errors[value].name = 'Name is Required.';
        }

        if (!values.getIn([`${value}`, 'relationship'])) {
            errors[value].relationship = 'Relationship is Required.';
        }

        if (!values.getIn([`${value}`, 'phone_number'])) {
            errors[value].phone_number = 'Phone Number is Required.';
        } else if (!/^[0-9()+-/,.]*$/i.test(values.getIn([`${value}`, 'phone_number']))) {
            errors[value].phone_number = 'Please enter a valid phone number';
        } else if (values.getIn([`${value}`, 'phone_number']).replace(/[^\w\s]/gi, '').length < 5) {
            errors[value].phone_number = 'Phone Number is too short.';
        } else if (values.getIn([`${value}`, 'phone_number']).replace(/[^\w\s]/gi, '').length > 15) {
            errors[value].phone_number = 'Phone Number is too large.';
        }
    });
    return errors;
};

export default validate;
