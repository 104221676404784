import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import FormEditCreatePage from '../FormEditCreatePage';
import { mapFormToState } from '../../FormContainer';
import * as FormActions from '../../../../actions/form/formActions';
import * as FormsPageActions from '../../../../actions/dashboard/formsPageActions';
import {
    openFormEditCreateModalDialog,
    openFormEditCreateDiscardChangesModalDialog
} from '../../../../actions/modal/openActions';


class FormEditCreatePageContainer extends PureComponent {
    static propTypes = {
        routeParams: PropTypes.shape({}),
        currentForm: PropTypes.shape({}),
        formState: PropTypes.shape({}),
        resetState: PropTypes.func,
        getCurrentFormData: PropTypes.func,
        getNewFormData: PropTypes.func,
        updateCurrentForm: PropTypes.func,
        openSaveCreateModalDialog: PropTypes.func,
        openDiscardChangesModalDialog: PropTypes.func,
        pushRouter: PropTypes.func,
        submit: PropTypes.func,
        reset: PropTypes.func
    };
    
    componentDidMount() {
        const { routeParams, getCurrentFormData, getNewFormData } = this.props;
        
        if (routeParams.id !== 'new') {
            getCurrentFormData(routeParams.id);
        } else {
            getNewFormData();
        }
    }
    
    componentWillUnmount() {
        const { resetState, currentForm, reset, getNewFormData } = this.props;
        
        resetState();
        reset(currentForm.getIn(['formConfig', 'form']));
        getNewFormData();
    }
    
    goBack = () => {
        const { pushRouter } = this.props;
        pushRouter('/dashboard/forms/');
    };
    
    render() {
        const {
            currentForm,
            formState,
            submit,
            updateCurrentForm,
            openSaveCreateModalDialog,
            openDiscardChangesModalDialog
        } = this.props;
        return (
            <FormEditCreatePage
                currentForm={currentForm}
                formState={formState[currentForm.getIn(['formConfig', 'form'])]}
                updateCurrentForm={updateCurrentForm}
                openSaveCreateModalDialog={openSaveCreateModalDialog}
                openDiscardChangesModalDialog={openDiscardChangesModalDialog}
                submit={submit}
                goBack={this.goBack}/>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentForm: state.getIn(['formsPage', 'currentForm']),
        formState: mapFormToState([state.getIn(['formsPage', 'currentForm', 'formConfig', 'form'])], state)
    };
};

const mapDispatchToProps = {
    resetState: FormsPageActions.resetFormsPageReducer,
    getCurrentFormData: FormsPageActions.getCurrentFormData,
    getNewFormData: FormsPageActions.getNewFormData,
    updateCurrentForm: FormsPageActions.updateCurrentForm,
    submit: FormActions.dispatchSubmit,
    reset: FormActions.dispatchReset,
    pushRouter: push,
    openSaveCreateModalDialog: openFormEditCreateModalDialog,
    openDiscardChangesModalDialog: openFormEditCreateDiscardChangesModalDialog
};

export default connect(mapStateToProps, mapDispatchToProps)(FormEditCreatePageContainer);
