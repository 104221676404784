export const RESET_OVERVIEW = 'RESET_OVERVIEW';

export const SET_OVERVIEW_NEW_OBJECTS_DATA = 'SET_OVERVIEW_NEW_OBJECTS_DATA';
export const SET_OVERVIEW_NEW_OBJECTS_LOADING = 'SET_OVERVIEW_NEW_OBJECTS_LOADING';
export const SET_OVERVIEW_NEW_OBJECTS_LOADED = 'SET_OVERVIEW_NEW_OBJECTS_LOADED';
export const SET_OVERVIEW_NEW_OBJECTS_FAILED = 'SET_OVERVIEW_NEW_OBJECTS_FAILED';

export const SET_OVERVIEW_CLASS_RESERVATIONS_DATA = 'SET_OVERVIEW_CLASS_RESERVATIONS_DATA';
export const SET_OVERVIEW_CLASS_RESERVATIONS_LOADING = 'SET_OVERVIEW_CLASS_RESERVATIONS_LOADING';
export const SET_OVERVIEW_CLASS_RESERVATIONS_LOADED = 'SET_OVERVIEW_CLASS_RESERVATIONS_LOADED';
export const SET_OVERVIEW_CLASS_RESERVATIONS_FAILED = 'SET_OVERVIEW_CLASS_RESERVATIONS_FAILED';

export const SET_OVERVIEW_REPORT_CARDS_DATA = 'SET_OVERVIEW_REPORT_CARDS_DATA';
export const SET_OVERVIEW_REPORT_CARDS_LOADING = 'SET_OVERVIEW_REPORT_CARDS_LOADING';
export const SET_OVERVIEW_REPORT_CARDS_LOADED = 'SET_OVERVIEW_REPORT_CARDS_LOADED';
export const SET_OVERVIEW_REPORT_CARDS_FAILED = 'SET_OVERVIEW_REPORT_CARDS_FAILED';

export const SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_DATA = 'SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_DATA';
export const SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_LOADING = 'SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_LOADING';
export const SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_LOADED = 'SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_LOADED';
export const SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_FAILED = 'SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_FAILED';
export const SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_COLOR_AND_NAME = 'SET_OVERVIEW_CLASS_RESERVATIONS_BY_ZONE_COLOR_AND_NAME';

export const SET_OVERVIEW_BOOKINGS_BY_TRAINER_DATA = 'SET_OVERVIEW_BOOKINGS_BY_TRAINER_DATA';
export const SET_OVERVIEW_BOOKINGS_BY_TRAINER_LOADING = 'SET_OVERVIEW_BOOKINGS_BY_TRAINER_LOADING';
export const SET_OVERVIEW_BOOKINGS_BY_TRAINER_LOADED = 'SET_OVERVIEW_BOOKINGS_BY_TRAINER_LOADED';
export const SET_OVERVIEW_BOOKINGS_BY_TRAINER_FAILED = 'SET_OVERVIEW_BOOKINGS_BY_TRAINER_FAILED';
export const SET_OVERVIEW_BOOKINGS_BY_TRAINER_COLOR = 'SET_OVERVIEW_BOOKINGS_BY_TRAINER_COLOR';

export const SET_OVERVIEW_TOTAL_REVENUE_DATA = 'SET_OVERVIEW_TOTAL_REVENUE_DATA';
export const SET_OVERVIEW_TOTAL_REVENUE_LOADING = 'SET_OVERVIEW_TOTAL_REVENUE_LOADING';
export const SET_OVERVIEW_TOTAL_REVENUE_LOADED = 'SET_OVERVIEW_TOTAL_REVENUE_LOADED';
export const SET_OVERVIEW_TOTAL_REVENUE_FAILED = 'SET_OVERVIEW_TOTAL_REVENUE_FAILED';
