import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

class TextAccordeon extends React.PureComponent {
    state = {
        isOpened: false
    }

    handleClick() {
        const { isOpened } = this.state;

        if (isOpened) {
            this.body.style.height = this.preview.offsetHeight + 'px';
        } else {
            this.body.style.height = this.content.offsetHeight + 'px';
        }

        this.setState({
            isOpened: !isOpened
        });
    }

    render() {
        const { children, maxLength } = this.props;
        const { isOpened } = this.state;
        const preview = children.slice(0, maxLength) + '...';
        const accordeonClassName = classNames({
            'text-accordion': true,
            'text-accordion_opened': isOpened
        });

        if (children.length < maxLength) return <div>{children}</div>;
        return (
            <div className={accordeonClassName}>
                <div className='text-accordion__body' ref={body => { this.body = body; }}>
                    <div className='text-accordion__hidden'>{preview}</div>
                    <div className='text-accordion__preview' ref={preview => { this.preview = preview; }}>{preview}</div>
                    <div className='text-accordion__content' ref={content => { this.content = content; }}>{children}</div>
                </div>
                <div className='text-accordion__toggle' onClick={() => { this.handleClick(); }}>
                    {isOpened ? 'Show Less' : 'Show More'}
                </div>
            </div>
        );
    }
}

TextAccordeon.propTypes = {
    children: PropTypes.string,
    maxLength: PropTypes.number,
};

export default TextAccordeon;
