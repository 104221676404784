import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as MiscTypes from '../types/miscTypes';

const INITIAL_STATE = fromJS({
    options: {
        veterinarian: [],
        class: [],
        trainer: [],
        training: [],
        discount: [],
        location: [],
        accessLevel: [],
        sex: [],
        breed: [],
        weight: [],
        dogReportIcons: {},
        immunization: [],
        userGrouping: [],
        attendance_by_zone_filter: [],
        customers: [],
    },
    mobileSidebar: {
        isOpened: false
    },
    ui: {
        pageBlocked: false
    },
    modals: {
        any: false,
        vetInfo: false,
        changeName: false,
        resetPassword: false,
        resetPasswordData: {
            fullName: '',
            email: ''
        },
        deleteCustomer: false,
        contactInfo: false,
        isContactInfoLoading: false,
        additionalOwners: false,
        deleteCustomerAgreement: false,
        deleteCustomerAgreementId: null,
        emergencyContact: false,
        dogCreation: false,
        isDogCreationOwnerPreselected: true,
        isDogCreationLoading: false,
        pDLocationsEdit: false,
        pDLocationsAdd: false,
        addCreditCard: false,
        upload: {
            isOpened: false,
            files: [],
            progress: 0,
            status: ''
        },
        isAddCreditCardLoading: false,
        deleteCustomerCreditCard: false,
        isCustomerCreditCardDeleting: false,
        addInstructorEmployee: false,
        editInstructorEmployee: false,
        isInstructorEmployeeLoading: false,
        editUpcomingClass: false,
        editingUpcomingClassInfo: {},
        dogEvaluation: false,
        changePaymentCard: {
            isOpened: false,
            cardId: null
        },
        addCustomerCard: {
            isOpened: false,
            data: {}
        },
        addAddress: {
            isOpened: false,
            data: {}
        },
        immunizationExpirationDates: false,
        isImmunizationExpirationDateLoading: false,
        editCustomerContactInfo: false,
        smsVerification: false
    },
    tablesCollapsed: {
        customers: {
            upcomingClasses: false,
            previousClasses: true,
            upcomingTrainings: false,
            previousTrainings: true,
        },
        dogs: {
            upcomingClasses: false,
            previousClasses: true
        }
    }
});

const miscReducer = handleActions({
    [MiscTypes.SET_VETINFO_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'vetInfo'], true).setIn(['modals', 'any'], true)
    ),
    [MiscTypes.SET_VETINFO_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'vetInfo'], false).setIn(['modals', 'any'], false)
    ),

    [MiscTypes.SET_DELETE_CUSTOMER_AGREEMENT_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'deleteCustomerAgreement'], true).setIn(['modals', 'any'], true).setIn([
            'modals',
            'deleteCustomerAgreementId'
        ], action.payload)
    ),
    [MiscTypes.SET_DELETE_CUSTOMER_AGREEMENT_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'deleteCustomerAgreement'], false).setIn(['modals', 'any'], false)
    ),

    [MiscTypes.SET_CHANGE_NAME_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'changeName'], true).setIn(['modals', 'any'], true)
    ),
    [MiscTypes.SET_CHANGE_NAME_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'changeName'], false).setIn(['modals', 'any'], false)
    ),
    [MiscTypes.SET_CHANGE_NAME_DEFAULT_DATA]: (state, action) => {
        return state;
    },

    [MiscTypes.SET_RESET_PASSWORD_MODAL_OPENED]: (state, action) => {
        return state.setIn(['modals', 'resetPassword'], true).setIn(['modals', 'any'], true)
            .setIn(['modals', 'resetPasswordData'], fromJS(action.payload));
    },
    [MiscTypes.SET_RESET_PASSWORD_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'resetPassword'], false).setIn(['modals', 'any'], false)
    ),

    [MiscTypes.SET_DELETE_CUSTOMER_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'deleteCustomer'], true).setIn(['modals', 'any'], true)
    ),
    [MiscTypes.SET_DELETE_CUSTOMER_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'deleteCustomer'], false).setIn(['modals', 'any'], false)
    ),

    [MiscTypes.SET_CONTACT_INFO_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'contactInfo'], true).setIn(['modals', 'any'], true)
    ),
    [MiscTypes.SET_CONTACT_INFO_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'contactInfo'], false).setIn(['modals', 'any'], false)
    ),
    [MiscTypes.SET_CONTACT_INFO_MODAL_LOADING]: (state, action) => (
        state.setIn(['modals', 'isContactInfoLoading'], true)
    ),
    [MiscTypes.SET_CONTACT_INFO_MODAL_LOADED]: (state, action) => (
        state.setIn(['modals', 'isContactInfoLoading'], false)
    ),
    [MiscTypes.SET_CONTACT_INFO_DEFAULT_DATA]: (state, action) => {
        return state;
    },

    [MiscTypes.SET_ADDITIONAL_OWNERS_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'additionalOwners'], true).setIn(['modals', 'any'], true)
    ),
    [MiscTypes.SET_ADDITIONAL_OWNERS_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'additionalOwners'], false).setIn(['modals', 'any'], false)
    ),
    [MiscTypes.SET_ADDITIONAL_OWNERS_DEFAULT_DATA]: (state, action) => {
        return state;
    },

    [MiscTypes.SET_EMERGENCY_CONTACT_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'emergencyContact'], true).setIn(['modals', 'any'], true)
    ),
    [MiscTypes.SET_EMERGENCY_CONTACT_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'emergencyContact'], false).setIn(['modals', 'any'], false)
    ),
    [MiscTypes.SET_EMERGENCY_CONTACT_DEFAULT_DATA]: (state, action) => {
        return state;
    },

    [MiscTypes.SET_DOG_CREATION_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'dogCreation'], true).setIn(['modals', 'any'], true).setIn([
            'modals',
            'isDogCreationOwnerPreselected'
        ], action.payload.isDogCreationOwnerPreselected)
    ),
    [MiscTypes.SET_DOG_CREATION_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'dogCreation'], false).setIn(['modals', 'any'], false)
    ),
    [MiscTypes.SET_DOG_CREATION_DEFAULT_DATA]: (state, action) => {
        return state;
    },
    [MiscTypes.SET_DOG_CREATION_LOADING]: (state, action) => {
        return state.setIn(['modals', 'isDogCreationLoading'], true);
    },
    [MiscTypes.SET_DOG_CREATION_LOADED]: (state, action) => {
        return state.setIn(['modals', 'isDogCreationLoading'], false);
    },

    [MiscTypes.SET_PD_LOCATIONS_EDIT_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'pDLocationsEdit'], true).setIn(['modals', 'any'], true)
    ),
    [MiscTypes.SET_PD_LOCATIONS_EDIT_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'pDLocationsEdit'], false).setIn(['modals', 'any'], false)
    ),
    [MiscTypes.SET_PD_LOCATIONS_EDIT_DEFAULT_DATA]: (state, action) => {
        return state;
    },

    [MiscTypes.SET_PD_LOCATIONS_ADD_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'pDLocationsAdd'], true).setIn(['modals', 'any'], true)
    ),
    [MiscTypes.SET_PD_LOCATIONS_ADD_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'pDLocationsAdd'], false).setIn(['modals', 'any'], false)
    ),

    [MiscTypes.SET_ADD_CREDIT_CARD_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'addCreditCard'], true).setIn(['modals', 'any'], true)
    ),
    [MiscTypes.SET_ADD_CREDIT_CARD_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'addCreditCard'], false).setIn(['modals', 'any'], false)
    ),
    [MiscTypes.SET_ADD_CREDIT_CARD_MODAL_LOADING]: (state, action) => (
        state.setIn(['modals', 'isAddCreditCardLoading'], true)
    ),
    [MiscTypes.SET_ADD_CREDIT_CARD_MODAL_LOADED]: (state, action) => (
        state.setIn(['modals', 'isAddCreditCardLoading'], false)
    ),

    [MiscTypes.SET_EDIT_UPCOMING_CLASS_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'editUpcomingClass'], true)
            .setIn(['modals', 'any'], true)
            .setIn(['modals', 'editingUpcomingClassInfo'], fromJS(action.payload))
    ),
    [MiscTypes.SET_EDIT_UPCOMING_CLASS_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'editUpcomingClass'], false)
            .setIn(['modals', 'any'], false)
            .setIn(['modals', 'editingUpcomingClassInfo'], fromJS({}))
    ),
    [MiscTypes.SET_EDIT_UPCOMING_CLASS_DEFAULT_DATA]: (state, action) => {
        return state;
    },

    [MiscTypes.COLLAPSE_CUSTOMERS_UPCOMING_CLASSES]: (state, action) => (
        state.setIn(['tablesCollapsed', 'customers', 'upcomingClasses'],
            !state.getIn(['tablesCollapsed', 'customers', 'upcomingClasses']))
    ),
    [MiscTypes.COLLAPSE_CUSTOMERS_PREVIOUS_CLASSES]: (state, action) => (
        state.setIn(['tablesCollapsed', 'customers', 'previousClasses'],
            !state.getIn(['tablesCollapsed', 'customers', 'previousClasses']))
    ),

    [MiscTypes.COLLAPSE_DOGS_UPCOMING_CLASSES]: (state, action) => (
        state.setIn(['tablesCollapsed', 'dogs', 'upcomingClasses'],
            !state.getIn(['tablesCollapsed', 'dogs', 'upcomingClasses']))
    ),
    [MiscTypes.COLLAPSE_DOGS_PREVIOUS_CLASSES]: (state, action) => (
        state.setIn(['tablesCollapsed', 'dogs', 'previousClasses'],
            !state.getIn(['tablesCollapsed', 'dogs', 'previousClasses']))
    ),

    [MiscTypes.COLLAPSE_CUSTOMERS_UPCOMING_TRAININGS]: (state, action) => (
        state.setIn(['tablesCollapsed', 'customers', 'upcomingTrainings'],
            !state.getIn(['tablesCollapsed', 'customers', 'upcomingTrainings']))
    ),
    [MiscTypes.COLLAPSE_CUSTOMERS_PREVIOUS_TRAININGS]: (state, action) => (
        state.setIn(['tablesCollapsed', 'customers', 'previousTrainings'],
            !state.getIn(['tablesCollapsed', 'customers', 'previousTrainings']))
    ),

    [MiscTypes.COLLAPSE_DOGS_UPCOMING_TRAININGS]: (state, action) => (
        state.setIn(['tablesCollapsed', 'dogs', 'upcomingTrainings'],
            !state.getIn(['tablesCollapsed', 'dogs', 'upcomingTrainings']))
    ),
    [MiscTypes.COLLAPSE_DOGS_PREVIOUS_TRAININGS]: (state, action) => (
        state.setIn(['tablesCollapsed', 'dogs', 'previousTrainings'],
            !state.getIn(['tablesCollapsed', 'dogs', 'previousTrainings']))
    ),

    [MiscTypes.SET_VETERINARIAN_OPTIONS]: (state, action) => {
        let veterinarianOptions;
        if (action.payload) {
            veterinarianOptions = action.payload.map((item) => {
                return item;
            });
        } else {
            veterinarianOptions = [];
        }

        const updatedState = state.update((state) => {
            return state
                .setIn(['options', 'veterinarian'],
                    state.getIn(['options', 'veterinarian']).merge(veterinarianOptions));
        });

        return updatedState;
    },
    [MiscTypes.ADD_NEW_VETERINARIAN_OPTION]: (state, action) => (
        state.setIn(['options', 'veterinarian'], state.getIn(['options', 'veterinarian']).insert(0, action.payload))
    ),
    [MiscTypes.SET_ACCESS_LEVEL_OPTIONS]: (state, action) => {
        return state.setIn(['options', 'accessLevel'], action.payload);
    },
    [MiscTypes.SET_SEX_OPTIONS]: (state, action) => {
        return state.setIn(['options', 'sex'], action.payload);
    },
    [MiscTypes.SET_BREED_OPTIONS]: (state, action) => {
        return state.setIn(['options', 'breed'], action.payload);
    },
    [MiscTypes.SET_WEIGHT_OPTIONS]: (state, action) => {
        return state.setIn(['options', 'weight'], action.payload);
    },
    [MiscTypes.SET_ICON_OPTIONS]: (state, action) => {
        return state.setIn(['options', 'dogReportIcons'], action.payload);
    },
    [MiscTypes.SET_IMMUNIZATION_OPTIONS]: (state, action) => {
        return state.setIn(['options', 'immunization'], action.payload);
    },
    [MiscTypes.SET_USER_GROUPING_OPTIONS]: (state, action) => {
        return state.setIn(['options', 'userGrouping'], fromJS(action.payload));
    },

    [MiscTypes.SET_DOG_EVALUATION_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'dogEvaluation'], true).setIn(['modals', 'any'], true)
    ),
    [MiscTypes.SET_DOG_EVALUATION_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'dogEvaluation'], false).setIn(['modals', 'any'], false)
    ),

    [MiscTypes.SET_ADD_CUSTOMER_CARD_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'addCustomerCard', 'isOpened'], true)
            .setIn(['modals', 'addCustomerCard', 'data'], action.payload || {})
            .setIn(['modals', 'any'], true)
    ),
    [MiscTypes.SET_ADD_CUSTOMER_CARD_MODAL_CLOSED]: (state) => (
        state.setIn(['modals', 'addCustomerCard', 'isOpened'], false)
            .setIn(['modals', 'any'], false)
            .setIn(['modals', 'addCustomerCard', 'data'], {})
    ),

    [MiscTypes.SET_CHANGE_PAYMENT_CARD_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'changePaymentCard', 'isOpened'], true)
            .setIn(['modals', 'changePaymentCard', 'cardId'], action.payload || null)
            .setIn(['modals', 'any'], true)
    ),
    [MiscTypes.SET_CHANGE_PAYMENT_CARD_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'changePaymentCard', 'isOpened'], false)
            .setIn(['modals', 'changePaymentCard', 'cardId'], null)
            .setIn(['modals', 'any'], false)
    ),

    [MiscTypes.SET_ADD_ADDRESS_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'addAddress', 'isOpened'], true)
            .setIn(['modals', 'addAddress', 'data'], action.payload || {})
            .setIn(['modals', 'any'], true)
    ),
    [MiscTypes.SET_ADD_ADDRESS_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'addAddress', 'isOpened'], false).setIn(['modals', 'any'], false)
    ),

    [MiscTypes.SET_ADD_INSTRUCTOR_EMPLOYEE_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'addInstructorEmployee'], true).setIn(['modals', 'any'], true)
    ),
    [MiscTypes.SET_EDIT_INSTRUCTOR_EMPLOYEE_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'editInstructorEmployee'], true).setIn(['modals', 'any'], true)
    ),
    [MiscTypes.SET_INSTRUCTOR_EMPLOYEE_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'addInstructorEmployee'], false)
            .setIn(['modals', 'editInstructorEmployee'], false)
            .setIn(['modals', 'any'], false)
    ),
    [MiscTypes.SET_INSTRUCTOR_EMPLOYEE_MODAL_LOADING]: (state, action) => (
        state.setIn(['modals', 'isInstructorEmployeeLoading'], true)
    ),
    [MiscTypes.SET_INSTRUCTOR_EMPLOYEE_MODAL_LOADED]: (state, action) => (
        state.setIn(['modals', 'isInstructorEmployeeLoading'], false)
    ),
    [MiscTypes.SET_INSTRUCTOR_EMPLOYEE_DEFAULT_DATA]: (state, action) => {
        return state;
    },

    [MiscTypes.SET_IMMUNIZATION_EXPIRATION_DATES_MODAL_OPENED]: (state, action) => (
        state.setIn(['modals', 'immunizationExpirationDates'], true).setIn(['modals', 'any'], true)
    ),
    [MiscTypes.SET_IMMUNIZATION_EXPIRATION_DATES_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'immunizationExpirationDates'], false).setIn(['modals', 'any'], false)
    ),
    [MiscTypes.SET_IMMUNIZATION_EXPIRATION_DATES_MODAL_LOADING]: (state, action) => (
        state.setIn(['modals', 'isImmunizationExpirationDateLoading'], true)
    ),
    [MiscTypes.SET_IMMUNIZATION_EXPIRATION_DATES_MODAL_LOADED]: (state, action) => (
        state.setIn(['modals', 'isImmunizationExpirationDateLoading'], false)
    ),
    [MiscTypes.SET_IMMUNIZATION_EXPIRATION_DATES_DEFAULT_DATA]: (state, action) => {
        return state;
    },
    [MiscTypes.SET_CLASS_OPTIONS]: (state, action) => {
        return state.setIn(['options', 'class'], action.payload);
    },
    [MiscTypes.SET_LOCATION_OPTIONS]: (state, action) => {
        return state.setIn(['options', 'location'], action.payload);
    },

    [MiscTypes.SET_UPLOAD_MODAL_OPENED]: (state, action) => {
        return state.setIn(['modals', 'upload', 'isOpened'], true)
            .setIn(['modals', 'upload', 'files'], action.payload)
            .setIn(['modals', 'any'], true);
    },
    [MiscTypes.SET_UPLOAD_MODAL_CLOSED]: (state, action) => (
        state.setIn(['modals', 'upload', 'isOpened'], false)
            .setIn(['modals', 'upload', 'files'], [])
            .setIn(['modals', 'upload', 'progress'], 0)
            .setIn(['modals', 'upload', 'status'], '')
            .setIn(['modals', 'any'], false)
    ),
    [MiscTypes.SET_FILE_PROGRESS]: (state, action) => {
        const { fileIndex, progress } = action.payload;
        const files = state.getIn(['modals', 'upload', 'files']);
        if (files.length) {
            files[fileIndex].progress = progress;
            return state.setIn(['modals', 'upload', 'files'], files)
                .setIn(['modals', 'upload', 'progress'], progress);
        }
        return state;
    },
    [MiscTypes.SET_UPLOAD_FILE_STATUS]: (state, action) => {
        const { fileIndex, status } = action.payload;
        const files = state.getIn(['modals', 'upload', 'files']);
        if (files.length) {
            files[fileIndex].status = status;
            if (status === 'success') {
                window._dcq.push(['track', 'Added vaccinations']);
            }
            return state.setIn(['modals', 'upload', 'files'], files)
                .setIn(['modals', 'upload', 'status'], fileIndex + '_' + status);
        }
        return state;
    },
    [MiscTypes.SET_MOBILE_SIDEBAR_OPENED]: (state, action) => {
        return state.setIn(['mobileSidebar', 'isOpened'], true);
    },
    [MiscTypes.SET_MOBILE_SIDEBAR_CLOSED]: (state, action) => {
        return state.setIn(['mobileSidebar', 'isOpened'], false);
    },
    [MiscTypes.SET_PAGE_BLOCKED]: (state, action) => {
        return state.setIn(['ui', 'pageBlocked'], true);
    },
    [MiscTypes.SET_PAGE_UNBLOCKED]: (state, action) => {
        return state.setIn(['ui', 'pageBlocked'], false);
    },
    [MiscTypes.SET_CUSTOMER_CONTACTS_INFO_OPENED]: (state, action) => {
        return state.setIn(['modals', 'editCustomerContactInfo'], true)
            .setIn(['modals', 'any'], true);
    },
    [MiscTypes.SET_CUSTOMER_CONTACTS_INFO_CLOSED]: (state, action) => {
        return state.setIn(['modals', 'editCustomerContactInfo'], false)
            .setIn(['modals', 'any'], false);
    },
    [MiscTypes.SET_SMS_VERIFICATION_MODAL_OPENED]: (state, action) => {
        return state.setIn(['modals', 'smsVerification'], true);
    },
    [MiscTypes.SET_SMS_VERIFICATION_MODAL_CLOSED]: (state) => {
        return state.setIn(['modals', 'smsVerification'], false);
    },
    [MiscTypes.SET_MARKETING_ATTENDANCE_BY_ZONE_FILTER_VALUES]: (state, action) => {
        return state.setIn(['options', 'attendance_by_zone_filter'], action.payload);
    },
    [MiscTypes.SET_CUSTOMERS_OPTIONS]: (state, action) => {
        return state.setIn(['options', 'customers'], action.payload);
    },
}, INITIAL_STATE);

export default miscReducer;
