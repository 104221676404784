import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as IconCamera } from '../../../../../assets/icons/icon-camera.svg';
import Icon from '../../../Icon/index';

const ClassImageUpload = ({ image, clickHandler }) => {
    const getImage = () => {
        if (image) {
            return (
                <div className='class-schedule-modal-form__image-preview'>
                    <div
                        className='class-schedule-modal-form__profile-image'
                        style={{
                            background: `url(${image})
                            no-repeat center center / cover`
                        }}/>
                </div>
            );
        }
        
        return (
            <div className='class-schedule-modal-form__image-empty'>
                <Icon className='avatar__icon icon_camera-small' glyph={IconCamera}/>
            </div>
        );
    };
    
    return (
        <div className='class-schedule-modal-form__image-panel'>
            <div className='class-schedule-modal-form__image-container'>
                {getImage()}
            </div>
            <div className='class-schedule-modal-form__update-image-button' style={{ textAlign: 'center' }} onClick={clickHandler}>
                Upload Image
            </div>
        </div>
    );
};

ClassImageUpload.propTypes = {
    image: PropTypes.string,
    clickHandler: PropTypes.func
};

export default ClassImageUpload;
