/* eslint-disable camelcase */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { ReactComponent as IconChevron } from '../../../../../../assets/icons/icon-chevron-table.svg';
import { ReactComponent as IconDots } from '../../../../../../assets/icons/icon-dots.svg';
import { formatTrainingDateTimeToAMPM, formatDateToSlashes } from '../../../../../../helpers/date';
import { createTrainingsDropdownItems, shouldDisplayEventProductDropdown } from '../../../../../../helpers/dropdownItems';
import TrainingCard from '../../../../../common/TrainingCard';
import Dropdown from '../../../../../common/Dropdown';
import AttendedIcon from '../../../../../common/AttendedIcon';
import Icon from '../../../../../common/Icon/Icon';

const TrainingsContent = (props) => {
    const { data, isLoading, isPrevious, trainingNameModifier = '', isCollapsed, collapseAction, dataName, trainingsActions } = props;

    const columns = [
        {
            id: 'trainingDate',
            Header: 'Date',
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            accessor: d => d.getIn(['event_detail', 'start']),
            Cell: row => formatDateToSlashes(row.value, true),
        },
        {
            id: 'trainingName',
            Header: 'Name',
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            accessor: d => d.getIn(['event_detail', 'program_name']),
        },
        {
            id: 'trainingTrainer',
            Header: 'Trainer',
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            accessor: d => d.getIn(['event_detail', 'trainer_name']),
        },
        {
            id: 'trainingDog',
            Header: 'Dog',
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            accessor: d => d.getIn(['dog_detail', 'name']),
        },
        {
            id: 'trainingPickupDropoff',
            Header: props => (
                <div>
                    <div>Pickup /</div>
                    <div>Dropoff</div>
                </div>
            ),
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            width: 180,
            accessor: d => d,
            Cell: row => (
                <div className='td__pickup-dropoff'>
                    <div className='pickup__location'>{row.value.getIn(['pickup_location_detail', 'name'])}</div>
                    <div className='pickup__time'>
                        {`${formatTrainingDateTimeToAMPM(
                            row.value.getIn(['event_detail', 'start']))} `}
                    </div>
                    <div className='dropoff__location'>{row.value.getIn(['drop_off_location_detail', 'name'])}</div>
                    <div className='dropoff__time'>
                        {`${formatTrainingDateTimeToAMPM(
                            row.value.getIn(['event_detail', 'end']))} `}
                    </div>
                </div>
            ),
        },
        {
            id: 'trainingAttendedOrCancelled',
            Header: props => (
                <div>
                    <div>Attended /</div>
                    <div>Cancelled</div>
                </div>
            ),
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            width: 110,
            accessor: d => d.get('status'),
            Cell: row => (
                <AttendedIcon status={row.value}/>
            ),
        },
        {
            id: 'trainingEdit',
            Header: '',
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            width: 50,
            accessor: d => d,
            Cell: row => {
                if (isPrevious && !shouldDisplayEventProductDropdown(row.value.toJS())) {
                    return null;
                }

                return (
                    <Dropdown
                        data={row.value}
                        classNames='dropdown_table'
                        icon={IconDots}
                        iconClass='icon_chevron_down'
                        items={createTrainingsDropdownItems({
                            trainingsType: dataName, data: row.value, actions: trainingsActions, context: 'customers',
                        })}/>
                );
            },
        },

    ];
    const TrainingsTableClassNames = classNames({
        'trainings-table__table': true,
        '-collapsed': isCollapsed,
    });
    const TrainingsGridClassNames = classNames({
        'trainings-table__grid': true,
        '-collapsed': isCollapsed,
    });
    const collapseIconClassNames = classNames({
        'icon_chevron-table': true,
        'icon_chevron-table_collapsed': isCollapsed,
    });

    return (
        <div className={`trainings-table ${trainingNameModifier}`}>
            {data.size
                ? (<div className='trainings-table__header' onClick={collapseAction}>
                    <div className='trainings-table__header-content'>
                        {dataName}
                        <Icon glyph={IconChevron} className={collapseIconClassNames}/>
                    </div>
                </div>)
                : (<div className='trainings-table__header'>
                    <div className='trainings-table__header-content'>
                        {dataName}
                    </div>
                </div>)
            }
            {isLoading
                ? (<div className='trainings-table__empty-content'>
                    {`Loading data for ${dataName}`}
                </div>)
                : data.size
                    ? (<div className='trainings-table__content'>
                        <ReactTable
                            className={TrainingsTableClassNames}
                            showPagination={false}
                            defaultPageSize={data.size}
                            sortable={false}
                            data={data}
                            columns={columns}/>
                        <div className={TrainingsGridClassNames}>
                            {data.map(trainingData => {
                                return (<TrainingCard
                                    key={`class_${trainingData.get('id')}`}
                                    trainingData={trainingData.toJS()}
                                    isPrevious={isPrevious}
                                    trainingActions={createTrainingsDropdownItems({
                                        trainingType: dataName,
                                        data: trainingData,
                                        actions: trainingsActions,
                                        context: 'customers',
                                    })}/>);
                            })}
                        </div>
                    </div>)
                    : (<div className='trainings-table__empty-content'>
                        {`No data for ${dataName}`}
                    </div>)
            }
        </div>
    );


};

TrainingsContent.propTypes = {
    data: PropTypes.shape({}).isRequired,
    isLoading: PropTypes.bool,
    isPrevious: PropTypes.bool,
    trainingNameModifier: PropTypes.string,
    isCollapsed: PropTypes.bool,
    collapseAction: PropTypes.func,
    dataName: PropTypes.string.isRequired,
    trainingsActions: PropTypes.shape({}),
};

export default TrainingsContent;
