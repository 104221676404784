/* eslint-disable react/no-multi-comp */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import * as FormActions from '../../actions/form/formActions';
import { generateFields } from '.';

class FormComponent extends PureComponent {
    renderForm = () => {
        const { formConfig } = this.props;
        return generateFields({
            formProps: this.props,
            initialValues: formConfig.initialValues,
            formConfig
        });
    };

    render() {
        const { className } = this.props;
        return (
            <form className={className}>
                {this.renderForm()}
            </form>
        );
    }
}

FormComponent.propTypes = {
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    onGetOptions: PropTypes.func,
    setFieldError: PropTypes.func,
    isSubmitting: PropTypes.bool,
    invalid: PropTypes.bool,
    formConfig: PropTypes.shape({}),
    className: PropTypes.string,
    currentTrainer: PropTypes.shape({})

};

const FormComponentDecorated = reduxForm({ pure: true, enableReinitialize: true })(FormComponent);

class FormContainer extends PureComponent {
    setFieldError = ({ field, message }) => {
        const { setError, formConfig } = this.props;
        setError({ form: formConfig.form, field, message });
    };

    refFormComponent = null;

    doValidation = () => {
        this.refFormComponent.wrappedInstance.props.handleSubmit(() => {})();
    };

    normalizeFormData = (formConfig, formData) => {
        const { onSubmit } = this.props;
        const { submitNormalizer, updateNormalizer } = formConfig;
        onSubmit({
            formData,
            submitNormalizer,
            updateNormalizer,
            formConfig
        });
    };

    render() {
        const { formConfig } = this.props;

        if (!formConfig) {
            return null;
        }

        return (
            <FormComponentDecorated
                {...this.props}
                ref={ref => {
                    this.refFormComponent = ref;
                }}
                // shouldValidate={(params) => {
                //     console.log('SHOULD VALIDATE', params);
                // }}
                key={formConfig.form}
                form={formConfig.form}
                className={formConfig.className || ''}
                data={formConfig}
                onSubmit={(formData) => { this.normalizeFormData(formConfig, formData); }}
                setFieldError={this.setFieldError}
                initialValues={formConfig.initialValues}/>
        );
    }
}


FormContainer.propTypes = {
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    onGetOptions: PropTypes.func,
    setError: PropTypes.func,
    isSubmitting: PropTypes.bool,
    invalid: PropTypes.bool,
    forms: PropTypes.arrayOf(PropTypes.shape({})),
    formConfig: PropTypes.shape({}),
    formState: PropTypes.shape({}),
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])

};

const getFormValues = (form, state) => {
    const formState = state.getIn(['form', form]);

    if (!formState) {
        return undefined;
    }
    return formState.get('values');
};

const mapStateToProps = (state, props) => {
    const { formConfig } = props;
    if (!formConfig || !formConfig.form) {
        return {};
    }

    return {
        formValues: getFormValues(props.formConfig.form, state)
    };
};

const mapDispatchToProps = {
    onSubmit: FormActions.onFormSubmit,
    onGetOptions: FormActions.onGetOptions,
    setError: FormActions.setError
};

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(FormContainer);
