import classNames from 'classnames';
import { arrayField } from '../TemplateEditor/form';
import getInitialValuesForTemplate from './formInitialValues';

export const getFormConfig = templateID => {
    return {
        initialValues: getInitialValuesForTemplate(templateID),
        form: `templatePreview${templateID}`,
        className: classNames(
            'template-selector-preview',
            `template${templateID}-selector-preview`
        ),
        fields: arrayField,
        submitAction: () => {}
    };
};
