import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ScheduleCardDogs extends PureComponent {
    static propTypes = {
        dogs: PropTypes.arrayOf(PropTypes.shape({})),
    }

    state = {
        opened: false
    }

    getDogs(dogs) {
        const count = dogs.length;
        return dogs.map((dog, index) => {
            const cardClassName = classNames({
                'schedule-card__dog': true,
                'schedule-card__dog_mid': count > 3 && count < 5,
                'schedule-card__dog_min': count > 4,
            });
            const style = {
                backgroundImage: `url(${dog.photo})`
            };
            return (
                <div className={cardClassName} style={style} key={index} />
            );
        });
    }

    toggle = () => {
        const { opened } = this.state;
        if (opened) {
            this.last.style.height = '0px';
            this.setState({
                opened: false
            });
        } else {
            this.setState({
                opened: true
            });
            this.last.style.height = this.hidden.offsetHeight + 'px';
        }
    }

    renderDogs(dogs) {
        return (
            <div className='schedule-card__dogs-list'>
                {dogs.map((dog, index) => index >= 5 ? null : dog)}
                <div className='schedule-card__dogs-last' ref={(last) => { this.last = last; }}>
                    {dogs.map((dog, index) => index < 5 ? null : dog)}
                </div>
                <div className='schedule-card__dogs-hidden' ref={(hidden) => { this.hidden = hidden; }}>
                    {dogs.map((dog) => dog)}
                </div>
            </div>
        );
    }

    render() {
        const { dogs } = this.props;
        const { opened } = this.state;
        const dogsList = this.getDogs(dogs);
        const count = dogs.length;
        if (count > 5) {
            const bodyClassName = classNames({
                'schedule-card__dogs-body': true,
                'schedule-card__dogs-body_opened': opened,
            });
            return (
                <div className='schedule-card__dogs'>
                    <div className={bodyClassName}>
                        {this.renderDogs(dogsList)}
                        <div className='schedule-card__dogs-toggle' onClick={this.toggle}>
                            {opened ? 'See Less' : `See More (${count - 5})`}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className='schedule-card__dogs'> { dogsList.map((dog) => dog) } </div>
        );
    }
}

export default ScheduleCardDogs;
