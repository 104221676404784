import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field, reduxForm } from 'redux-form/immutable';
import validate from './validate';
import renderPasswordControl from '../../../../common/Form/PasswordControl/PasswordControl';

const ChangePasswordForm = (props) => {
    const { handleSubmit, submitting, invalid, error } = props;
    console.log('props: ', props);
    const SubmitButtonClassNames = classNames({
        'profile-edit-form__submit': true,
        'profile-edit-form__submit--disabled': invalid || submitting
    });
    return (
        <form onSubmit={handleSubmit} className='profile-edit__change-pass'>
            <div className='profile-edit__change-pass__content'>
                <div className='profile-edit__change-pass__input'>
                    <Field
                        name='old_password'
                        type='password'
                        component={renderPasswordControl}
                        formError={error}
                        label='Current password' />
                </div>
                <div className='profile-edit__change-pass__input'>
                    <Field
                        name='password'
                        type='password'
                        component={renderPasswordControl}
                        formError={error}
                        label='New password' />
                </div>
            </div>
            <button
                className={SubmitButtonClassNames}
                type='submit'
                disabled={submitting}
                onClick={(values) => {
                    console.log('form submit', values);
                }}>
                Save changes
            </button>
        </form>
    );
};
ChangePasswordForm.propTypes = {
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    handleSubmit: PropTypes.func,
    error: PropTypes.string
};

const form = reduxForm({
    form: 'profileChangePasswordForm',
    destroyOnUnmount: false,
    validate
});

export default form(ChangePasswordForm);
