import * as Api from './api';

export const convertCampaignTargetOptions = (responseData) => {
    return responseData.map(item => {
        const groupingType = item.grouping_type;
        return {
            label: item.name,
            value: item.id,
            extra: {
                groupingType
            }
        };
    });
};

export const getCampaignTargetOptions = () => {
    return (dispatch, getState) => {
        return (value, callback) => {
            const options = getState().getIn(['marketingEditorPage', 'detail', 'targets']);
            
            if (options && options.size) {
                callback(null, { options: options.toJS() });
                return;
            }
            
            dispatch(Api.getCampaignTargets())
                .then((response) => {
                    callback(null, { options: convertCampaignTargetOptions(response.data) });
                })
                .catch((error) => {
                    console.log(error);
                });
        };
    };
};
