/* eslint-disable camelcase */
import { fromJS } from 'immutable';
import moment from 'moment';
import { handleActions } from 'redux-actions';
import { convertBoolean } from '../helpers/data';
import * as ClassesTypes from '../types/dashboard/classesTypes';
import * as TrainingsTypes from '../types/dashboard/trainingsTypes';
import * as DogReportsTypes from '../types/dashboard/dogReportsTypes';
import * as EmployeeTypes from '../types/dashboard/employeeTypes';
import * as NotesIncidentsTypes from '../types/dashboard/notesIncidentsTypes';
import * as VaccinationRecordsTypes from '../types/dashboard/vaccinationRecordsTypes';

const INITIAL_STATE = fromJS({
    isLoading: false,
    instance: {},
    notes_incidents: {
        items: [],
        areLoaded: false
    },
    vaccination_records: {
        items: [],
        choices: [],
        areLoading: false
    },
    previous_classes: {
        classes: [],
        nextUrl: null,
        previousUrl: null,
        areLoaded: false,
        count: 0,
        pageSize: 0,
        pages: 0,
        isLoading: false
    },
    upcoming_classes: {
        classes: [],
        nextUrl: null,
        previousUrl: null,
        areLoaded: false,
        count: 0,
        pageSize: 0,
        pages: 0,
        isLoading: false
    },
    previous_trainings: {
        trainings: [],
        areLoaded: false,
        count: false,
    },
    upcoming_trainings: {
        trainings: [],
        areLoaded: false
    },
    reports: {
        items: null,
        count: null,
        pageSize: null,
        pages: null,
        nextUrl: null,
        previousUrl: null
    },
    errorSubmittingDogEvaluation: false,
});

export const CustomerDogDataNormalizer = (formData, state) => {
    const breedList = state.getIn(['misc', 'options', 'breed']).toJS();
    const data = formData.toJS();
    const { name, gender, weight, breed, markings } = data;
    let { year, month } = data;
    let breedDetail;
    const breedId = typeof breed !== 'object' ? breed : breed.value;
    month = typeof month !== 'object' ? month : month.value;
    year = typeof year !== 'object' ? year : year.value;
    for (let i = 0; i < breedList.length; i += 1) {
        const element = breedList[i];
        if (element.value === breedId) {
            breedDetail = breedList[i].label;
        }
    }
    return {
        name,
        gender: typeof gender !== 'object' ? gender : gender.value,
        weight: typeof weight !== 'object' ? weight : weight.value,
        breed: breedId,
        breed_detail: breedDetail,
        is_altered: data.is_altered === 'Yes' ? 'true' : 'false',
        birthday: `${year}-${month < 10 ? '0' + month : month}-01`,
        markings,
    };
};

export const CustomerDogMedicalDataNormalizer = (formData) => {
    const data = formData.toJS();
    const intoleranceList = data.intolerance_list;
    return {
        intolerance_list: intoleranceList && intoleranceList.map(item => item.value),
        medical_notes: data.medical_notes,
        veterinarian: data.veterinarian_with_create.value || data.veterinarian_with_create,
        can_contact_veterinarian: 'true',
    };
};

export const DogDataNormalizer = (formData, state) => {
    formData = formData.toJS();
    const currentInstance = state.getIn(['currentDog', 'instance']).toJS();
    const newInstance = {};

    const updateBirthdayString = ({ month, year, currentBirthdayString }) => {
        const bdate = currentBirthdayString.split('-');
        if (year !== undefined) {
            bdate[0] = year;
        }

        if (month !== undefined) {
            bdate[1] = month;
        }

        return bdate.join('-');
    };

    if ('month' in formData || 'year' in formData) {
        newInstance.birthday = currentInstance.birthday;
    }

    Object.keys(formData).forEach(key => {
        if (formData[key] && formData[key].label !== undefined && formData[key].value !== undefined) {
            if (key === 'month' || key === 'year') {
                const args = {
                    currentBirthdayString: newInstance.birthday
                };
                args[key] = formData[key].value;
                newInstance.birthday = updateBirthdayString(args);
                return;
            }

            newInstance[key] = formData[key].value;

            if (`${key}_detail` in currentInstance) {
                newInstance[`${key}_detail`] = formData[key].label;
            }

            return;
        }

        if (key === 'intolerance_list') {
            newInstance[key] = formData[key].map(item => {
                return item.value;
            });
            return;
        }

        if (key.startsWith('is_')) {
            newInstance[key] = convertBoolean(formData[key]);
            return;
        }

        newInstance[key] = formData[key];
    });
    return newInstance;
};

export const dogReportNormalizer = (formData, state) => {
    formData = formData.toJS();

    const request = new FormData();
    const {
        title,
        date,
        snapshots,
        snapshot_video_urls,
        dog,
        class_product,
    } = formData;
    const dateObj = moment(date).toDate();
    const icons = formData.icon_select.map(iconName => {
        return {
            type: iconName,
            label: formData[iconName].label
        };
    });

    request.append('title', title);
    request.append('icons', JSON.stringify(icons));
    if (class_product !== undefined && class_product !== null) {
        request.append('class_product', class_product);
    } else {
        request.append('date', `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`);
        if (dog === undefined) {
            request.append('dog', state.getIn(['currentDog', 'instance', 'id']));
        } else {
            request.append('dog', dog);
        }
    }

    const photoKeyIds = {};
    const updateIds = {};
    const deleteIds = [];
    if (snapshots) {
        snapshots.forEach((initialSnapshot, index) => {
            photoKeyIds['photo_' + index] = initialSnapshot.id;
        });
    }

    const videoUrlsToCreate = [];
    const videoUrlDeleteIds = [];
    const videoUrlDeleteCandidates = {};
    if (snapshot_video_urls) {
        snapshot_video_urls.forEach((videoUrlObj, index) => {
            const { id, snapshot_video_url } = videoUrlObj;
            videoUrlDeleteCandidates[snapshot_video_url] = id;
        });
    }

    // Process photo_* fields
    Object.keys(formData).filter(e => e.startsWith('photo_')).forEach(photo_key => {
        const photoField = formData[photo_key];
        const isUpdate = photoKeyIds.hasOwnProperty(photo_key); //eslint-disable-line

        if (Array.isArray(photoField)) { // Field was set to a new image
            request.append('snapshots[]', photoField[0]);
            if (isUpdate) {
                updateIds[photoField[0].name] = photoKeyIds[photo_key];
            }
        } else if (isUpdate && (photoField === null)) { // Field was deleted
            deleteIds.push(photoKeyIds[photo_key]);
        }
    });

    // Process video_url_* fields
    Object.keys(formData)
        .filter((key) => key.startsWith('video_url_'))
        .forEach((videoUrlKey) => {
            const videoUrl = formData[videoUrlKey];
            const shouldAddVideoUrl = 
                (videoUrl !== null) &&
                !videoUrlDeleteCandidates.hasOwnProperty(videoUrl);

            if (shouldAddVideoUrl) {
                videoUrlsToCreate.push(videoUrl);
            } else {
                videoUrlDeleteCandidates[videoUrl] = null;
            }
        });

    // select video url ids to delete
    for (const videoUrl in videoUrlDeleteCandidates) {
        const videoUrlId = videoUrlDeleteCandidates[videoUrl];
        if (videoUrlId !== null) videoUrlDeleteIds.push(videoUrlId);
    }
    
    request.append('updateIds', JSON.stringify(updateIds));
    request.append('deleteIds', JSON.stringify(deleteIds));
    request.append('video_urls_to_create', JSON.stringify(videoUrlsToCreate));
    request.append('video_url_delete_ids', JSON.stringify(videoUrlDeleteIds));
    return request;
};

const currentDogReducer = handleActions({
    [EmployeeTypes.SET_CURRENT_DOG_DATA]: (state, action) => {
        return state.set('instance', fromJS(action.payload));
    },
    [EmployeeTypes.UPDATE_CURRENT_DOG_DATA]: (state, action) => {
        return state.set('instance', state.get('instance').merge(fromJS(action.payload)));
    },
    [EmployeeTypes.CLEAR_CURRENT_DOG_DATA]: (state) => {
        return state
            .set('instance', fromJS(null))
            .setIn(['notes_incidents', 'items'], fromJS([]))
            .setIn(['vaccination_records', 'items'], fromJS([]))
            .setIn(['previous_trainings', 'trainings'], fromJS([]))
            .setIn(['upcoming_trainings', 'trainings'], fromJS([]))
            .setIn(['previous_classes', 'classes'], fromJS([]))
            .setIn(['upcoming_classes', 'classes'], fromJS([]))
            .set('reports', fromJS({
                items: null,
                count: null,
                pageSize: null,
                pages: null,
                nextUrl: null,
                previousUrl: null
            }));
    },
    [EmployeeTypes.SET_ERROR_SUBMITTING_DOG_EVALUATION]: (state, action) => {
        return state.set('errorSubmittingDogEvaluation', action.payload);
    },
    [EmployeeTypes.START_CURRENT_DOG_LOADING]: (state) => {
        return state.set('isLoading', true);
    },
    [EmployeeTypes.FINISH_CURRENT_DOG_LOADING]: (state) => {
        return state.set('isLoading', false);
    },
    [NotesIncidentsTypes.MARK_DOG_NOTES_INCIDENTS_LOADED]: (state) => {
        return state.setIn(['notes_incidents', 'areLoaded'], true);
    },
    [NotesIncidentsTypes.UNMARK_DOG_NOTES_INCIDENTS_LOADED]: (state) => {
        return state.setIn(['notes_incidents', 'areLoaded'], false);
    },
    [NotesIncidentsTypes.SET_CURRENT_DOG_NOTES_INCIDENTS]: (state, action) => {
        return state.update((state) => {
            return state
                .setIn(['notes_incidents', 'items'], fromJS(action.payload));
        });
    },
    [NotesIncidentsTypes.ADD_CURRENT_DOG_NOTES_INCIDENTS_ITEM]: (state, action) => {
        return state.update((state) => {
            return state.setIn(['notes_incidents', 'items'],
                state.getIn(['notes_incidents', 'items']).insert(0, action.payload));
        });
    },
    [VaccinationRecordsTypes.MARK_DOG_VACCINATION_RECORDS_LOADED]: (state) => {
        return state.setIn(['vaccination_records', 'areLoaded'], true);
    },
    [VaccinationRecordsTypes.UNMARK_DOG_VACCINATION_RECORDS_LOADED]: (state) => {
        return state.setIn(['vaccination_records', 'areLoaded'], false);
    },
    [VaccinationRecordsTypes.START_DOG_VACCINATION_RECORDS_LOADING]: (state) => {
        return state.setIn(['vaccination_records', 'areLoading'], true);
    },
    [VaccinationRecordsTypes.FINISH_DOG_VACCINATION_RECORDS_LOADING]: (state) => {
        return state.setIn(['vaccination_records', 'areLoading'], false);
    },
    [VaccinationRecordsTypes.SET_CURRENT_DOG_VACCINATION_RECORDS]: (state, action) => {
        return state.update((state) => {
            return state
                .setIn(['vaccination_records', 'items'], fromJS(action.payload));
        });
    },
    [VaccinationRecordsTypes.UPDATE_CURRENT_DOG_VACCINATION_RECORDS]: (state, action) => {
        return state.update((state) => {
            const newVaccinations = action.payload.reduce((vaccinationsArray, newVaccine) => {
                const _found = vaccinationsArray.findIndex((item) => {
                    return newVaccine.id === item.id;
                });

                if (_found > -1) {
                    vaccinationsArray[_found] = Object.assign({}, newVaccine);
                } else {
                    vaccinationsArray.push(newVaccine);
                }

                return vaccinationsArray;
            }, state.getIn(['vaccination_records', 'items']).toJS());

            return state
                .setIn(['vaccination_records', 'items'], fromJS(newVaccinations));
        });
    },
    [VaccinationRecordsTypes.SET_CURRENT_DOG_VACCINATION_RECORDS_CHOICES]: (state, action) => {
        return state.update((state) => {
            return state
                .setIn(['vaccination_records', 'choices'], fromJS(action.payload));
        });
    },
    [ClassesTypes.MARK_DOG_PREVIOUS_CLASSES_LOADED]: (state) => {
        return state.setIn(['previous_classes', 'areLoaded'], true);
    },
    [ClassesTypes.UNMARK_DOG_PREVIOUS_CLASSES_LOADED]: (state) => {
        return state.setIn(['previous_classes', 'areLoaded'], false);
    },
    [ClassesTypes.START_ISLOADING_DOG_PREVIOUS_CLASSES]: (state) => {
        return state.setIn(['previous_classes', 'isLoading'], true);
    },
    [ClassesTypes.FINISH_ISLOADING_DOG_PREVIOUS_CLASSES]: (state) => {
        return state.setIn(['previous_classes', 'isLoading'], false);
    },
    [ClassesTypes.SET_DOG_PREVIOUS_CLASSES]: (state, action) => {
        const nextUrl = action.payload.next === null ? action.payload.next : action.payload.next.slice(4);
        const previousUrl = action.payload.previous === null ? action.payload.previous : action.payload.previous.slice(4);
        const pagesCount = Math.ceil(action.payload.count / action.payload.results.length);
        return state
            .setIn(['previous_classes', 'count'], action.payload.count)
            .setIn(['previous_classes', 'pageSize'], action.payload.results.length)
            .setIn(['previous_classes', 'pages'], pagesCount)
            .setIn(['previous_classes', 'previousUrl'], previousUrl)
            .setIn(['previous_classes', 'nextUrl'], nextUrl)
            .setIn(['previous_classes', 'classes'],
                state.getIn(['previous_classes', 'classes']).concat(fromJS(action.payload.results)))
            .setIn(['previous_classes', 'isLoading'], false);
    },
    [ClassesTypes.SET_DOG_PREVIOUS_CLASS_DETAILS]: (state, action) => {
        const idOfClassToUpdate = state.getIn(['previous_classes', 'classes'])
                                       .findIndex((previousClass) => {
                                           return previousClass.get('id') === action.payload.id;
                                       });

        return state.setIn(['previous_classes', 'classes', idOfClassToUpdate, 'details'], fromJS(action.payload));
    },
    [ClassesTypes.UPDATE_DOG_PREVIOUS_CLASS]: (state, action) => {
        const currentKey = state.getIn(['previous_classes', 'classes'])
            .findKey(product => product.get('id') === action.payload.id);
        return state.setIn(['previous_classes', 'classes', currentKey], fromJS(action.payload));
    },
    [ClassesTypes.MARK_DOG_UPCOMING_CLASSES_LOADED]: (state) => {
        return state.setIn(['upcoming_classes', 'areLoaded'], true);
    },
    [ClassesTypes.UNMARK_DOG_UPCOMING_CLASSES_LOADED]: (state) => {
        return state.setIn(['upcoming_classes', 'areLoaded'], false);
    },
    [ClassesTypes.START_ISLOADING_DOG_UPCOMING_CLASSES]: (state) => {
        return state.setIn(['upcoming_classes', 'isLoading'], true);
    },
    [ClassesTypes.FINISH_ISLOADING_DOG_UPCOMING_CLASSES]: (state) => {
        return state.setIn(['upcoming_classes', 'isLoading'], false);
    },
    [ClassesTypes.SET_DOG_UPCOMING_CLASSES]: (state, action) => {
        const nextUrl = action.payload.next === null ? action.payload.next : action.payload.next.slice(4);
        const previousUrl = action.payload.previous === null ? action.payload.previous : action.payload.previous.slice(4);
        const pagesCount = Math.ceil(action.payload.count / action.payload.results.length);
        return state
            .setIn(['upcoming_classes', 'count'], action.payload.count)
            .setIn(['upcoming_classes', 'pageSize'], action.payload.results.length)
            .setIn(['upcoming_classes', 'pages'], pagesCount)
            .setIn(['upcoming_classes', 'previousUrl'], previousUrl)
            .setIn(['upcoming_classes', 'nextUrl'], nextUrl)
            .setIn(['upcoming_classes', 'classes'],
                state.getIn(['upcoming_classes', 'classes']).concat(fromJS(action.payload.results)))
            .setIn(['upcoming_classes', 'isLoading'], false);
    },
    [ClassesTypes.SET_DOG_UPCOMING_CLASS_DETAILS]: (state, action) => {
        const idOfClassToUpdate = state.getIn(['upcoming_classes', 'classes'])
                                       .findIndex((upcomingClass) => {
                                           return upcomingClass.get('id') === action.payload.id;
                                       });
        return state.setIn(['upcoming_classes', 'classes', idOfClassToUpdate], fromJS(action.payload));
    },
    [ClassesTypes.REMOVE_DOGS_UPCOMING_CLASS]: (state, action) => {
        return state.update((state) => {
            return state.updateIn(['upcoming_classes', 'classes'],
                items => items.filter(item => item.get('id') !== action.payload));
        });
    },
    [DogReportsTypes.SET_CURRENT_DOG_REPORTS]: (state, action) => {
        const { results, count, next, previous } = action.payload.data;
        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);
        const pagesCount = Math.ceil(count / results.length);
        const reports = {
            items: action.payload.shouldAppend ? state.getIn(['reports', 'items']).toJS().concat(results) : results,
            pageSize: results.length,
            pages: pagesCount,
            nextUrl,
            previousUrl,
            count
        };
        return state.update((state) => {
            return state
                .setIn(['reports'], fromJS(reports));
        });
    },
    [DogReportsTypes.PREPEND_CURRENT_DOG_REPORTS]: (state, action) => {
        return state.update((state) => {
            return state.setIn(['reports', 'items'], state.getIn(['reports', 'items'])
                                                          .insert(0, fromJS(action.payload)));
        });
    },
    [DogReportsTypes.UPDATE_CURRENT_DOG_REPORT_DATA]: (state, action) => { // eslint-disable-line consistent-return
        const { payload } = action;
        const currentReportKey = state.getIn(['reports', 'items'])
                                      .findKey(report => report.get('id') === payload.get('id'));
        if (typeof currentReportKey !== 'undefined') {
            return state.setIn(['reports', 'items', currentReportKey], fromJS(payload));
        }
    },
    [DogReportsTypes.REMOVE_CURRENT_DOG_REPORT_CARD]: (state, action) => {
        return state.updateIn(['reports', 'items'], items => items.filter(item => item.get('id') !== action.payload))
            .set('count', state.get('count') - 1);
    },
    [DogReportsTypes.DELETE_CURRENT_DOG_REPORT_CARD]: (state, action) => {
        return state.updateIn(['reports', 'items'], items => items.filter(item => item.get('id') !== action.payload))
            .set('count', state.get('count') - 1);
    },
    [ClassesTypes.DELETE_PREVIOUS_CLASS_REPORTS]: (state, action) => {
        const currentKey = state.getIn(['previous_classes', 'classes'])
                                .findKey(item => item.get('id') === action.payload);
        return state.setIn(['previous_classes', 'classes', currentKey, 'reports'], fromJS([]));
    },
    [ClassesTypes.UPDATE_PREVIOUS_CLASS_REPORTS]: (state, action) => {
        const currentKey = state.getIn(['previous_classes', 'classes'])
                                .findKey(item => item.get('id') === action.payload.get('class_product'));
        if (currentKey !== undefined) {
            return state.setIn(['previous_classes', 'classes', currentKey, 'reports'],
                state.getIn(['previous_classes', 'classes', currentKey, 'reports'])
                     .insert(0, fromJS({ id: action.payload.get('id') })))
                     .setIn(['reports', 'items'], state.getIn(['reports', 'items']).insert(0, fromJS(action.payload)))
                     .set('count', state.get('count') + 1);
        }
        return state;
    },
    [TrainingsTypes.MARK_DOG_PREVIOUS_TRAININGS_LOADED]: (state) => {
        return state.setIn(['previous_trainings', 'areLoaded'], true);
    },
    [TrainingsTypes.UNMARK_DOG_PREVIOUS_TRAININGS_LOADED]: (state) => {
        return state.setIn(['previous_trainings', 'areLoaded'], false);
    },
    [TrainingsTypes.SET_DOG_PREVIOUS_TRAININGS]: (state, action) => {
        return state
            .setIn(['previous_trainings', 'count'], action.payload.count)
            .setIn(['previous_trainings', 'trainings'],
                state.getIn(['previous_trainings', 'trainings']).merge(action.payload.results));
    },
    [TrainingsTypes.SET_DOG_PREVIOUS_TRAINING_DETAILS]: (state, action) => {
        const idOfTrainingToUpdate = state.getIn(['previous_trainings', 'trainings'])
                                       .findIndex((previousTraining) => {
                                           return previousTraining.get('id') === action.payload.id;
                                       });

        return state.setIn(['previous_trainings', 'trainings', idOfTrainingToUpdate, 'details'], fromJS(action.payload));
    },
    [TrainingsTypes.MARK_DOG_UPCOMING_TRAININGS_LOADED]: (state) => {
        return state.setIn(['upcoming_trainings', 'areLoaded'], true);
    },
    [TrainingsTypes.UNMARK_DOG_UPCOMING_TRAININGS_LOADED]: (state) => {
        return state.setIn(['upcoming_trainings', 'areLoaded'], false);
    },
    [TrainingsTypes.SET_DOG_UPCOMING_TRAININGS]: (state, action) => {
        return state.setIn(['upcoming_trainings', 'trainings'],
            state.getIn(['upcoming_trainings', 'trainings']).merge(action.payload));
    },
    [TrainingsTypes.SET_DOG_UPCOMING_TRAINING_DETAILS]: (state, action) => {
        const pickupLocationDetail = action.payload.get('pickup_location').detail;
        const dropoffLocationDetail = action.payload.get('drop_off_location').detail;

        const isPickupLocationInitial = pickupLocationDetail === undefined;
        const isDropOffLocationInitial = dropoffLocationDetail === undefined;

        if (isPickupLocationInitial && isDropOffLocationInitial) {
            return state;
        }

        const currentKey = state.getIn(['upcoming_trainings', 'trainings'])
                                .findKey(product => {
                                    return product.getIn(['dog_detail', 'id']) === action.payload.getIn(['dogId']);
                                });

        if (isPickupLocationInitial) {
            return state.setIn(['upcoming_trainings', 'trainings', currentKey, 'drop_off_location_detail', 'name'], dropoffLocationDetail.name);
        };

        if (isDropOffLocationInitial) {
            return state.setIn(['upcoming_trainings', 'trainings', currentKey, 'pickup_location_detail', 'name'], pickupLocationDetail.name);
        };

        return state.setIn(['upcoming_trainings', 'trainings', currentKey, 'pickup_location_detail', 'name'], pickupLocationDetail.name)
                    .setIn(['upcoming_trainings', 'trainings', currentKey, 'drop_off_location_detail', 'name'], dropoffLocationDetail.name);
    },
    [TrainingsTypes.REMOVE_DOGS_UPCOMING_TRAINING]: (state, action) => {
        return state.update((state) => {
            return state.updateIn(['upcoming_trainings', 'trainings'],
                items => items.filter(item => item.get('id') !== action.payload));
        });
    },
}, INITIAL_STATE);

export default currentDogReducer;
