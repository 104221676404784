import PropTypes from 'prop-types';
import React from 'react';
import { fromJS } from 'immutable';
import FormsTable from '../FormsTable';
import AddNewItem from '../../../../components/common/AddNewItem';
import { isEmployee, isManager } from '../../../../helpers/userRoles';

const FormsPage = ({ data, getData, getNext, gotoFormEdit, openFormDeleteModalDialog }) => {
    const items = data.get('items') || fromJS([]);

    return (
        <div className='dashboard__main'>
            <div className='forms-table-page'>
                <div className='forms-table-page__header'>
                    {!(isEmployee() || isManager()) && <AddNewItem
                        text='Create New Form'
                        onClickHandler={() => gotoFormEdit('new')}/>}
                </div>
                <FormsTable
                    isManual
                    defaultPageSize={data.get('pageSize')}
                    loading={data.get('isLoading')}
                    nextUrl={data.get('nextUrlQuery')}
                    data={items.toJS()}
                    getNext={getNext}
                    gotoFormEdit={gotoFormEdit}
                    readOnly={isEmployee() || isManager()}
                    onSorting={value => getData(`o=${value}`)}
                    openFormDeleteModalDialog={openFormDeleteModalDialog}/>
            </div>
        </div>
    );
};

FormsPage.propTypes = {
    data: PropTypes.shape({}),
    getData: PropTypes.func,
    getNext: PropTypes.func,
    gotoFormEdit: PropTypes.func,
    openFormDeleteModalDialog: PropTypes.func
};

export default FormsPage;
