export const convertBoolean = value => {
    const values = {
        'Yes': true,
        'yes': true,
        'No': false,
        'no': false
    };

    if (value in values) {
        return values[value];
    }

    return Boolean(value);
};

export const bool2Char = value => {
    return (value === true) ? 'Yes' : 'No';
};

export const flattenValueLabel = (formData, normalizer = (key, value) => { return { key, value }; }) => {
    const makeFlatCopy = (objOrArray) => {
        const newInstance = new objOrArray.constructor();
        Object.keys(objOrArray).forEach(key => {
            let value;
            try {
                if (objOrArray[key].label !== undefined && objOrArray[key].value !== undefined) {
                    value = objOrArray[key].value;
                } else {
                    value = objOrArray[key];

                    if (Array.isArray(value)) {
                        value = makeFlatCopy(value);
                    }
                }
            } catch (e) {
                console.log('ERROR: ', objOrArray, key);
            }

            const newKeyValue = normalizer(key, value);
            newInstance[newKeyValue.key] = newKeyValue.value;
        });

        return newInstance;
    };

    return makeFlatCopy(formData);
};

export const formatBytes = (bytes, decimals) => {
    // Compare both to string and to number.
    if (bytes == 0) { // eslint-disable-line eqeqeq
        return '0 Bytes';
    }

    decimals = decimals || 2;
    const kilo = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const power = Math.floor(Math.log(bytes) / Math.log(kilo));
    return parseFloat((bytes / kilo ** power).toFixed(decimals)) + sizes[power];
};


export const sortArrayByPattern = (array, patternArray) => {
    let sortedArr = [];
    let notSortedArr = [];
    for (let i = 0; i < array.length; i++) {
        const str = Object.keys(array[i])[0];
        const index = patternArray.indexOf(str);
        if (index === -1) {
            notSortedArr.push(array[i]);
        } else {
            sortedArr[index] = array[i];
        }
    }
    sortedArr = sortedArr.filter(i => i !== undefined);
    const newArray = [...sortedArr, ...notSortedArr];
    return newArray;
};