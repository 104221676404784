import { getUserNameSpace } from '../../helpers/userRoles';
import * as ModalActions from '../../actions/modal/modalActions';

export const openCampaignDeleteModal = campaignData => {
    return dispatch => {
        dispatch(
            ModalActions.onModalOpen({
                type: 'yesNoModal',
                submitAction: 'deleteItem',
                updateAction: 'updateMarketingTable',
                errorAction: 'handleModalSubmitError',
                alertEvent: 'CAMPAIGN_DELETE',
                alertData: { name: campaignData.name },
                itemId: campaignData.id,
                url: `/v1/${getUserNameSpace('campaigns')}/campaigns/${campaignData.id}/`,
                title: 'Delete Campaign',
                text: `Are you sure you would like to delete the ${campaignData.name} campaign?`,
            }),
        );
    };
};
