import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormsTable from './FormsTable/FormsTable';
import * as ModalOpenActions from '../../../../../actions/modal/openActions';

const FormsPage = ({ currentCustomer, openUserAgreementDeleteModalDialog }) => {
    return (
        <div className='customers-forms'>
            {currentCustomer.getIn(['instance', 'id']) === undefined
                ? 'Loading...'
                : (<FormsTable
                    data={currentCustomer.getIn(['forms', 'items'])}
                    currentCustomer={currentCustomer.get('instance')}
                    dataName='Forms'
                    openUserAgreementDeleteModalDialog={openUserAgreementDeleteModalDialog}
                    isLoading={currentCustomer.getIn(['isLoading'])}/>)
            }
        </div>
    );
};

FormsPage.propTypes = {
    currentCustomer: PropTypes.shape({}),
    handleGettingCustomersAgreements: PropTypes.func,
    openUserAgreementDeleteModalDialog: PropTypes.func
};

const mapStateToProps = state => {
    return {
        currentCustomer: state.getIn(['currentCustomer'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    openUserAgreementDeleteModalDialog: bindActionCreators(ModalOpenActions.openUserAgreementDeleteModalDialog,
        dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FormsPage);
