import React from "react";
import Select from "react-select";

const options = [];

class DogSelector extends React.Component {
    state = {
        selectedOption: null,
    };
    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
    };
    render() {
        const { dogs } = this.props;
        const { selectedOption } = this.state;
        return (
            <Select
                value={selectedOption}
                onChange={this.handleChange}
                options={dogs ? dogs : options}
                placeholder="Select the dog after choosing Customer"
            />
        );
    }
}

export default DogSelector;
