import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { dataStates } from '../../../../../../helpers/state';
import { ReactComponent as IconChevronDown } from '../../../../../../assets/icons/icon-chevron-right.svg';
import { capitalizeEachWord } from '../../../../../../helpers/text';
import { getNormalizedData, getComponentColumns } from './helpers';

const DogAttendance = ({ items, dataState }) => {
    const renderTable = () => {
        if (dataState === dataStates.loaded && items) {
            // {k1:v1, k2:v2, ...} => [ {k1:v1}, {k2:v2}, ... ]
            const itemsArray = Object.keys(items).map(item => ({ [item]: items[item] })); 

            console.log('DogAttendance itemsArray', itemsArray);
            const columns = [
                {
                    id: 'avgAttendance',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => capitalizeEachWord(Object.keys(d)[0].replace(/_/g, ' ')),
                },
                {
                    expander: true,
                    width: 65,
                    Expander: ({ isExpanded, ...rest }) => { // eslint-disable-line
                        return (<span className={`custom-reports__expand-btn ${isExpanded ? 'custom-reports__expand-btn_expanded' : ''}`}>
                            <IconChevronDown className='custom-reports__expand-btn__icon'/>
                        </span>);
                    },
                    style: {
                        cursor: 'pointer',
                        fontSize: 25,
                        padding: 0,
                        textAlign: 'center',
                        userSelect: 'none'
                    },
                }
            ];
            return (
                <ReactTable
                    showPagination={false}
                    defaultPageSize={itemsArray.length}
                    data={itemsArray}
                    columns={columns}
                    SubComponent={({ original: row }) => {
                        const normalizedData = getNormalizedData(row);
                        const subComponentColumns = getComponentColumns(row);
                        return (
                            <div className='custom-reports__inner-table'>
                                <ReactTable
                                    showPagination={false}
                                    defaultPageSize={normalizedData.length}
                                    data={normalizedData}
                                    columns={subComponentColumns} />
                            </div>
                        );
                    }}/>);
        }
        return null;
    };

    return (
        <div className='custom-reports__report'>
            {items !== undefined && renderTable()}
        </div>
    );
};

DogAttendance.propTypes = {
    items: PropTypes.shape({}),
    dataState: PropTypes.string.isRequired,
};

export default DogAttendance;
