import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    goToHealthStep,
    goToIntroductionStep,
    goToVetInfoStep,
    goToWaiverStep
} from '../../../actions/onboarding/onBoardingStepsActions';

// eslint-disable-next-line max-len
const Stepper = ({ steps, handleGoToIntroductionStep, handleGoToHealthStep, handleGoToVetInfoStep, handleGoToWaiverStep }) => {
    console.log('steps: ', steps);
    const registrationStep = steps.get('registration_step');
    const isHealthComplete = steps.get('isHealthComplete');
    const isVetInfoComplete = steps.get('isVetInfoComplete');
    const isFinishingLater = registrationStep === 3 && (!isHealthComplete || !isVetInfoComplete);
    const FirstPawClassNames = classNames({
        'stepper__paw': true,
        'stepper__paw_filled': steps.get('isIntroductionComplete'),
    });
    const SecondPawClassNames = classNames({
        'stepper__paw': true,
        'stepper__paw_filled': isHealthComplete,
        'stepper__paw_disabled': (registrationStep < 1 || isFinishingLater),
    });
    const ThirdPawClassNames = classNames({
        'stepper__paw': true,
        'stepper__paw_filled': isVetInfoComplete,
        'stepper__paw_disabled': (registrationStep < 2 || isFinishingLater),
    });
    const FourthPawClassNames = classNames({
        'stepper__paw': true,
        'stepper__paw_filled': steps.get('isWaiverComplete'),
    });
    return (
        <div className='stepper'>
            <div onClick={handleGoToIntroductionStep} className={FirstPawClassNames}/>
            <div
                onClick={() => {
                    if (registrationStep < 1 || isFinishingLater) {
                        return null;
                    }
                    return handleGoToHealthStep();
                }}
                className={SecondPawClassNames}/>
            <div
                onClick={() => {
                    if (registrationStep < 2 || isFinishingLater) {
                        return null;
                    }
                    return handleGoToVetInfoStep();
                }}
                className={ThirdPawClassNames}/>
            <div onClick={handleGoToWaiverStep} className={FourthPawClassNames}/>
        </div>
    );
};

Stepper.propTypes = {
    steps: PropTypes.shape({}),
    handleGoToIntroductionStep: PropTypes.func,
    handleGoToHealthStep: PropTypes.func,
    handleGoToVetInfoStep: PropTypes.func,
    handleGoToWaiverStep: PropTypes.func
};

const mapStateToProps = state => {
    return {
        steps: state.get('steps')
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleGoToIntroductionStep: bindActionCreators(goToIntroductionStep, dispatch),
    handleGoToHealthStep: bindActionCreators(goToHealthStep, dispatch),
    handleGoToVetInfoStep: bindActionCreators(goToVetInfoStep, dispatch),
    handleGoToWaiverStep: bindActionCreators(goToWaiverStep, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Stepper);
