import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as ClosedEnvelope } from '../../../../../assets/icons/closed-envelope.svg';
import Icon from '../../../../common/Icon';

const SaveAsCampaignTarget = ({ openSaveAsCampaignModalDialog, params }) => {
    return (
        <div
            className='custom-reports__save-as-campaign-target'
            onClick={() => openSaveAsCampaignModalDialog({ params })}>
            <div className='save-as-campaign-target__text'>Save as a Campaign Target:</div>
            <Icon glyph={ClosedEnvelope} className='icon_closed-envelope'/>
        </div>
    );
};

SaveAsCampaignTarget.propTypes = {
    openSaveAsCampaignModalDialog: PropTypes.func.isRequired,
    params: PropTypes.shape({}).isRequired,
};

export default SaveAsCampaignTarget;
