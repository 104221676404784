import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import PageHeader from '../../common/PageHeader';
import { getCustomerData } from '../../../actions/customer/CustomerActions';
import CustomerAlert from '../../../components/CustomerAlert';

class CustomerContainer extends PureComponent {
    componentWillMount() {
        const { getCustomerData } = this.props;
        getCustomerData();
    }

    renderAlert() {
        const { alert } = this.props;
        const alertData = alert && alert.toJS && alert.toJS();
        if (alertData) {
            return <CustomerAlert data={alertData}/>;
        }
        return null;
    }

    render() {
        const { children, agreementsAreLoaded, agreementsCount, location: { pathname } } = this.props;
        const isSimpleHeader = pathname === '/welcome' || pathname === '/welcome/';
        if (agreementsAreLoaded && agreementsCount === 0) {
            return (
                <div className='customer'>
                    <PageHeader isSimpleHeader={isSimpleHeader}/>
                    {!isSimpleHeader && this.renderAlert()}
                    <div className='customer__container'>
                        {children}
                    </div>
                </div>
            );
        }
        return null;
    }
}

CustomerContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    location: PropTypes.shape({}),
    alert: PropTypes.shape({}),
    getCustomerData: PropTypes.func,
    agreementsAreLoaded: PropTypes.bool,
    agreementsCount: PropTypes.number,
};

const mapStateToProps = state => {
    return {
        agreementsCount: state.getIn(['user', 'agreements', 'items']).size,
        agreementsAreLoaded: state.getIn(['user', 'agreements', 'areLoaded']),
        alert: state.getIn(['currentCustomer', 'instance', 'alert']),
    };
};

const mapDispatchToProps = {
    getCustomerData,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerContainer);
