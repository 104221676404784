export const SET_CURRENT_REGISTRATION_STEP = 'SET_CURRENT_REGISTRATION_STEP';
export const SET_INTRODUCTION_REGISTRATION_STEP_COMPLETE = 'SET_INTRODUCTION_REGISTRATION_STEP_COMPLETE';
export const SET_INTRODUCTION_COMPLETE = 'SET_INTRODUCTION_COMPLETE';
export const SET_INTRODUCTION_INCOMPLETE = 'SET_INTRODUCTION_INCOMPLETE';
export const SET_HEALTH_COMPLETE = 'SET_HEALTH_COMPLETE';
export const SET_HEALTH_INCOMPLETE = 'SET_HEALTH_INCOMPLETE';
export const SET_VET_INFO_COMPLETE = 'SET_VET_INFO_COMPLETE';
export const SET_VET_INFO_INCOMPLETE = 'SET_VET_INFO_INCOMPLETE';
export const SET_WAIVER_COMPLETE = 'SET_WAIVER_COMPLETE';
export const SET_WAIVER_INCOMPLETE = 'SET_WAIVER_INCOMPLETE';
export const SET_FINISHING_LATER = 'SET_FINISHING_LATER';
