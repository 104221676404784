import React from 'react';
import PropTypes from 'prop-types';

const AvatarList = ({ dogs, handleRemoveDog }) => {
    return (
        <div className='avatar-list'>
            {
                dogs.map((dog, index) => {
                    const { photo } = dog;
                    return (
                        <div className='avatar-list__item' key={index}>
                            <div className='avatar avatar-list__avatar'>
                                <div className='avatar__container'>
                                    <div
                                        style={{
                                            background: 'url(' + photo + ') no-repeat center center / cover'
                                        }}
                                        className='avatar__img' />
                                </div>
                            </div>
                            <div className='avatar-list__name'>{dog.name}</div>
                            <div
                                className='avatar-list__action'
                                onClick={() => {
                                    handleRemoveDog(dog);
                                }
                            }>Remove</div>
                        </div>
                    );
                })
            }
        </div>
    );
};

AvatarList.propTypes = {
    dogs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleRemoveDog: PropTypes.func,
};

export default AvatarList;
