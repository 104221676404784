import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetPassword } from '../../../../services/resetPassword';
import SkipStep from '../../../common/SkipStep/SkipStep';
import Step from '../../../common/Step';
import StepDescription from '../../../common/StepDescription';
import ResetPasswordFormContainer from './ResetPasswordForm';

const EmployeeResetPasswordPage = ({ stepName = 'forgot-password', handleResetPassword, params }) => {
    const code = params.splat ? params.code.slice(5) + params.splat : params.code.slice(5);
    return (
        <Step
            logoClassName='forgot-password__logo'
            contentClassName='forgot-password__content'
            contentsContainerClassName='forgot-password__container'
            stepName={stepName}
            componentsImage={<div className='forgot-password__img forgot-password__img_employee'/>}
            descriptionComponent={
                <StepDescription
                    className='forgot-password__step-description forgot-password__step-description_reset'
                    text='Let’s reset your password'/>
            }
            formComponent={
                <ResetPasswordFormContainer
                    onSubmit={values => handleResetPassword(values.set('code', code))}/>
            }
            skipComponent={
                <SkipStep
                    className='forgot-password__skip-step forgot-password__skip-step_reset'
                    skipLinkText='Cancel'
                    skipLinkTo='/employee'/>
            }/>
    );
};

EmployeeResetPasswordPage.propTypes = {
    stepName: PropTypes.string,
    handleResetPassword: PropTypes.func,
    params: PropTypes.shape({})
};

const mapDispatchToProps = (dispatch) => ({
    handleResetPassword: bindActionCreators(resetPassword, dispatch)
});

export default connect(null, mapDispatchToProps)(EmployeeResetPasswordPage);
