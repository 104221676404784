import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form/immutable';
import renderInputControl from '../../../common/Form/InputControl';
import Icon from '../../../common/Icon';
import { ReactComponent as IconVisa } from '../../../../assets/icons/icon-visa.svg';
import { ReactComponent as IconMasterCard } from '../../../../assets/icons/icon-master-card.svg';
import { ReactComponent as IconAmericanExpress } from '../../../../assets/icons/icon-american-express.svg';
import { ReactComponent as IconNeutralCard } from '../../../../assets/icons/icon-neutral-card.svg';
import * as options from '../../../../helpers/options';
import renderSelectControl from '../../../common/Form/SelectControl/SelectControl';
import * as ValidatorFactory from '../../../../helpers/validate';

const creditCardType = require('credit-card-type');

const NewPaymentCard = ({
    newCardType,
    cardNumberValue,
    changeNewCardValue,
    stripeError,
}) => {
    let cardType;
    let cardCodeSize;
    if (cardNumberValue !== undefined && creditCardType(cardNumberValue.substr(0, 2))[0] !== undefined) {
        const cardInfo = creditCardType(cardNumberValue.substr(0, 2))[0];
        cardType = cardInfo.type;
        cardCodeSize = cardInfo.code.size;
    }
    const visaClassNames = classNames({
        'card-detection__item card-detection__item_visa': true,
        'card-detection__item_detected': cardType === 'visa',
    });
    const masterClassNames = classNames({
        'card-detection__item card-detection__item_master': true,
        'card-detection__item_detected': cardType === 'master-card',
    });
    const expressClassNames = classNames({
        'card-detection__item card-detection__item_express': true,
        'card-detection__item_detected': cardType === 'american-express',
    });
    const nonameClassNames = classNames({
        'card-detection__item card-detection__item_noname': true,
        'card-detection__item_detected':
            cardType !== 'american-express' &&
            cardType !== 'master-card' &&
            cardType !== 'visa' &&
            (cardType !== undefined || (cardNumberValue !== undefined && cardNumberValue.length >= 2)),
    });
    return (
        <div className='modal-dialog__new-item'>
            <div className='modal-dialog__form-divider' />
            <div className='modal-dialog__card-detection'>
                <Icon glyph={IconVisa} className={visaClassNames} />
                <Icon glyph={IconMasterCard} className={masterClassNames} />
                <Icon glyph={IconAmericanExpress} className={expressClassNames} />
                <Icon glyph={IconNeutralCard} className={nonameClassNames} />
            </div>
            <Field
                name={`${newCardType}_number`}
                type='text'
                isMasked
                mask='9999 9999 9999 9999'
                component={renderInputControl}
                onChangeCallback={changeNewCardValue}
                label='Credit Card Number'
                validate={[
                    ValidatorFactory.requiredValidator('Credit Card Number'),
                    ValidatorFactory.creditCardValidator(),
                ]}/>
            <div className='form__aligner form__aligner_50-50'>
                <Field
                    name={`${newCardType}_expiration_month`}
                    type='text'
                    component={renderSelectControl}
                    options={options.expirationMonth}
                    label='Exp Date (MM)'
                    validate={[ValidatorFactory.requiredValidatorSelect('Exp Date (MM)')]}/>
                <Field
                    name={`${newCardType}_expiration_year`}
                    type='text'
                    component={renderSelectControl}
                    options={options.expirationYear}
                    label='Exp Date (YY)'
                    validate={[ValidatorFactory.requiredValidatorSelect('Exp Date (YY)')]}/>
            </div>
            <Field
                name={`${newCardType}_cvc`}
                type='text'
                isMasked
                mask={cardCodeSize === 4 ? '9999' : '999'}
                validate={[
                    ValidatorFactory.requiredValidator('Security Code'),
                    ValidatorFactory.exactTextValueValidator(
                        'Security Code',
                        cardCodeSize !== undefined ? cardCodeSize : 3,
                    ),
                ]}
                component={renderInputControl}
                label='Security code'/>
            {stripeError ?
                <div className='form__error'>{stripeError}</div>
            : null}
        </div>
    );
};

NewPaymentCard.propTypes = {
    newCardType: PropTypes.string.isRequired,
    cardNumberValue: PropTypes.string,
    changeNewCardValue: PropTypes.func,
};

export default NewPaymentCard;
