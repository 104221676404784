import PropTypes from 'prop-types';
import React from 'react';
import Logo from '../../common/Logo';
import Container from '../Container';

const Step = ({
                  stepName,
                  componentsImage,
                  contentClassName,
                  contentsContainerClassName,
                  logoClassName,
                  nameComponent,
                  descriptionComponent,
                  nameAndDescriptionTopped = false,
                  socialLoginComponent,
                  dividerComponent,
                  formComponent,
                  skipComponent,
                  modalComponent,
                  modalOverlayComponent
              }) => {
    return (
        <main className={stepName}>
            {componentsImage}
            <div className={contentClassName}>
                <Logo logoTo='/' logoClassName={logoClassName}/>
                {nameAndDescriptionTopped && nameComponent}
                {nameAndDescriptionTopped && descriptionComponent}
                <Container className={contentsContainerClassName}>
                    {!nameAndDescriptionTopped && nameComponent}
                    {!nameAndDescriptionTopped && descriptionComponent}
                    {socialLoginComponent}
                    {dividerComponent}
                    {formComponent}
                    {modalComponent}
                    {modalOverlayComponent}
                </Container>
                {skipComponent}
            </div>
        </main>
    );
};

Step.propTypes = {
    stepName: PropTypes.oneOf(
        ['signup', 'signin', 'introduction', 'health', 'vet-info', 'waiver', 'forgot-password', 'welcome-back', 'create-password']
    ).isRequired,
    componentsImage: PropTypes.node,
    contentClassName: PropTypes.string.isRequired,
    contentsContainerClassName: PropTypes.string.isRequired,
    logoClassName: PropTypes.string.isRequired,
    nameComponent: PropTypes.node,
    descriptionComponent: PropTypes.node,
    nameAndDescriptionTopped: PropTypes.bool,
    socialLoginComponent: PropTypes.node,
    dividerComponent: PropTypes.node,
    formComponent: PropTypes.node,
    skipComponent: PropTypes.node,
    modalComponent: PropTypes.node,
    modalOverlayComponent: PropTypes.node
};

export default Step;
