import { createAction } from 'redux-actions';
import { getPurchasePackages, getNextPurchasePackages } from '../../services/purchasePackages';
import * as PurchaseHistoryTypes from '../../types/dashboard/purchaseHistoryTypes';
import { setGlobalError } from '../errorHandlerActions';

export const setCustomerPurchaseHistory = createAction(PurchaseHistoryTypes.SET_CUSTOMER_PURCHASE_HISTORY);
export const setNextCustomerPurchaseHistory = createAction(PurchaseHistoryTypes.SET_NEXT_CUSTOMER_PURCHASE_HISTORY);
export const markCustomerPurchaseHistoryLoaded = createAction(
    PurchaseHistoryTypes.MARK_CUSTOMER_PURCHASE_HISTORY_LOADED);
export const unmarkCustomerPurchaseHistoryLoaded = createAction(
    PurchaseHistoryTypes.UNMARK_CUSTOMER_PURCHASE_HISTORY_LOADED);
export const getCustomerPurchasePackages = (queryString) => {
    return dispatch => {
        dispatch(unmarkCustomerPurchaseHistoryLoaded());
        dispatch(getPurchasePackages({
            queryString
        })).then(response => {
            console.log('getCustomerPurchasePackages: ', response);
            dispatch(setCustomerPurchaseHistory(response.data));
            dispatch(markCustomerPurchaseHistoryLoaded());
        }).catch((error) => {
            console.log(error);
            console.log(error.response);
            dispatch(setGlobalError(error));
            dispatch(markCustomerPurchaseHistoryLoaded());
        });
    };
};

export const getCustomerNextPurchasePackages = () => {
    return dispatch => {
        dispatch(unmarkCustomerPurchaseHistoryLoaded());
        dispatch(getNextPurchasePackages()).then(response => {
            console.log('getCustomersNextPurchaseHistory: ', response);
            dispatch(setNextCustomerPurchaseHistory(response.data));
            dispatch(markCustomerPurchaseHistoryLoaded());
        }).catch((error) => {
            console.log(error);
            console.log(error.response);
            dispatch(setGlobalError(error));
            dispatch(markCustomerPurchaseHistoryLoaded());
        });
    };
};
