import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    signUp,
    signUpFacebook,
    signUpFacebookScratch,
    signUpGoogle,
    signUpGoogleScratch,
    signUpScratch,
} from '../../../../actions/onboarding/userActions';
import { sendForgottenPassword } from '../../../../services/resetPassword';
import Step from '../../../common/Step';
import StepDescription from '../../../common/StepDescription';
import WelcomeBackForm from './WelcomeBackForm';

const WelcomeBackPage = (props) => {
    const {
        stepName = 'welcome-back', handleSignUpGingr, handleSignUpScratch, socialName, handleFacebookSignUpGingr,
        handleGoogleSignUpGingr, handleSignUpFacebookScratch, handleSignUpGoogleScratch,
    } = props;
    const gingrSignUpCB = socialName
        ? socialName === 'fb'
                              ? handleFacebookSignUpGingr
                              : handleGoogleSignUpGingr
        : handleSignUpGingr;
    const scratchSignUpCB = socialName
        ? socialName === 'fb'
                                ? handleSignUpFacebookScratch
                                : handleSignUpGoogleScratch
        : handleSignUpScratch;
    return (
        <Step
            logoClassName='welcome-back__logo'
            contentClassName='welcome-back__content'
            contentsContainerClassName='welcome-back__container'
            stepName={stepName}
            componentsImage={<div className='welcome-back__img'/>}
            descriptionComponent={
                <StepDescription
                    className='welcome-back__step-description'
                    text='Welcome back'
                    disclaimer='We noticed that you are already a member of our club.'/>
            }
            formComponent={
                <WelcomeBackForm
                    scratchSignUp={scratchSignUpCB}
                    gingrSignUp={gingrSignUpCB}/>
            }/>
    );
};

WelcomeBackPage.propTypes = {
    stepName: PropTypes.string,
    handleSendingForgottenPassword: PropTypes.func,
    handleSignUpGingr: PropTypes.func,
    handleSignUpScratch: PropTypes.func,
    socialName: PropTypes.string,
    handleFacebookSignUpGingr: PropTypes.func,
    handleGoogleSignUpGingr: PropTypes.func,
    handleSignUpFacebookScratch: PropTypes.func,
    handleSignUpGoogleScratch: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        socialName: state.getIn(['user', 'signUpData', 'socialName']),
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleSendingForgottenPassword: bindActionCreators(sendForgottenPassword, dispatch),
    handleSignUpGingr: bindActionCreators(signUp, dispatch),
    handleFacebookSignUpGingr: bindActionCreators(signUpFacebook, dispatch),
    handleGoogleSignUpGingr: bindActionCreators(signUpGoogle, dispatch),
    handleSignUpScratch: bindActionCreators(signUpScratch, dispatch),
    handleSignUpFacebookScratch: bindActionCreators(signUpFacebookScratch, dispatch),
    handleSignUpGoogleScratch: bindActionCreators(signUpGoogleScratch, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeBackPage);
