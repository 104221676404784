import * as axios from 'axios';

const $http = axios.create();

export const getUSDBase = () => $http.get('http://api.fixer.io/latest?base=USD');
export const getEURBase = () => $http.get('http://api.fixer.io/latest?base=EUR');
export const getCustomBase = (base = '') => $http.get(`http://api.fixer.io/latest?base=${base}`);

export const formatMoney = (value) => { // 1001.25 => $1,001.25
    const newValue = '$' + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    return newValue;
};
