import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ReactComponent as IconBell } from '../../../../assets/icons/icon-notification-bell.svg';
import DailyNotification from './DailyNotification';
import { setPageBlocked, setPageUnblocked } from '../../../../actions/miscActions';
import { ReactComponent as IconClose } from '../../../../assets/icons/icon-remove.svg';
import * as Actions from './actions';
import Loader from '../../../common/Loader/';

const pollingTimeout = 5 * 60 * 1000;

class DailyNotifications extends PureComponent {
    state = {
        isOpened: false
    };

    componentDidMount() {
        const { getUnreadCount, modal, checkScroll } = this;
        getUnreadCount();
        this.scrollListenerThrottled = _.throttle(checkScroll, 300);
        modal.addEventListener('scroll', this.scrollListenerThrottled);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollListenerThrottled);
        if (this.getUnreadTimeout) {
            clearTimeout(this.getUnreadTimeout);
        }
    }

    getUnreadCount = () => {
        const { startGetUnreadCountTimeout } = this;
        const {
            getDailyNotificationsUnreadCount,
            getDailyNotificationsItems
        } = this.props;

        getDailyNotificationsUnreadCount()
            .then((count) => {
                const { isOpened } = this.state;
                if (count && isOpened) {
                    getDailyNotificationsItems()
                        .then(startGetUnreadCountTimeout);
                } else {
                    startGetUnreadCountTimeout();
                }
            });
    };

    startGetUnreadCountTimeout = () => {
        const { getUnreadCount } = this;
        this.getUnreadTimeout = setTimeout(getUnreadCount, pollingTimeout);
    };

    checkScroll = () => {
        const { modal, list } = this;
        const {
            nextItemsAreLoading,
            getNextDailyNotificationsItems,
            nextUrl
        } = this.props;
        const canLoad = () => {
            const modalHeight = modal.offsetHeight;
            const modalScrollTop = modal.scrollTop;
            const listHeight = list.offsetHeight;
            const loaderHeight = 50;
            return modalHeight + modalScrollTop >= listHeight - loaderHeight;
        };
        if (!nextItemsAreLoading && nextUrl && canLoad()) {
            getNextDailyNotificationsItems();
            modal.scrollTop = list.offsetHeight;
        }
    };

    handleClick = () => {
        const {
            setPageBlocked,
            setPageUnblocked,
            getDailyNotificationsItems
        } = this.props;

        this.setState((prevState) => {
            if (prevState.isOpened) {
                setPageUnblocked();
            } else {
                setPageBlocked();
                getDailyNotificationsItems();
            }
            return {
                isOpened: !prevState.isOpened
            };
        });
    };

    renderItems = () => {
        const { handleClick } = this;
        const { itemsAreLoading } = this.props;
        const items = this.props.items.toJS();
        if (items.length > 0) {
            return (
                <div className='daily-notifications__list' ref={ref => { this.list = ref; }}>
                    { items.map((item, index) => {
                        return (
                            <DailyNotification
                                key={`daily-notification_${item.id}`}
                                data={item}
                                handleClick={handleClick} />
                        );
                    }) }
                </div>
            );
        }

        if (items.length === 0 && !itemsAreLoading) {
            return (
                <div className='daily-notifications__no-data'>
                    There are no notifications
                </div>
            );
        }

        return null;
    };

    renderLoader = () => {
        return (
            <Loader
                isVisible
                colorClassName='icon_loader-black'
                modifierClassName='daily-notifications__loader' />
        );
    };

    render() {
        const {
            handleClick,
            renderItems,
            renderLoader
        } = this;
        const {
            isOpened
        } = this.state;
        const {
            className,
            unreadCount,
            itemsAreLoading,
            nextUrl
        } = this.props;

        const buttonClassName = classNames({
            'daily-notifications__button': true,
            'daily-notifications__button_active': unreadCount > 0
        });
        const notificationsClassName = classNames({
            'daily-notifications': true,
            'daily-notifications_opened': isOpened,
            [className]: className
        });

        return (
            <div className={notificationsClassName}>
                <button className={buttonClassName} onClick={handleClick}>
                    <IconBell className='icon icon_bell'/>
                    <div className='daily-notifications__button__counter'>{unreadCount > 99 ? '99+' : unreadCount}</div>
                </button>
                <div className='daily-notifications__list-head'>
                    <div className='daily-notifications__title'>Notifications</div>
                    <div className='daily-notifications__close' onClick={handleClick}>
                        <IconClose className='daily-notifications__close-icon'/>
                    </div>
                </div>
                <div className='daily-notifications__modal' ref={ref => { this.modal = ref; }}>
                    {itemsAreLoading && renderLoader()}
                    {renderItems()}
                    {nextUrl && renderLoader()}
                </div>
            </div>
        );
    }
}

DailyNotifications.propTypes = {
    className: PropTypes.string,
    nextUrl: PropTypes.string,
    setPageBlocked: PropTypes.func,
    setPageUnblocked: PropTypes.func,
    unreadCount: PropTypes.number,
    getDailyNotificationsUnreadCount: PropTypes.func,
    getDailyNotificationsItems: PropTypes.func,
    getNextDailyNotificationsItems: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.shape()),
    itemsAreLoading: PropTypes.bool,
    nextItemsAreLoading: PropTypes.bool,
};


const mapDispatchToProps = {
    setPageBlocked,
    setPageUnblocked,
    getDailyNotificationsUnreadCount: Actions.getDailyNotificationsUnreadCount,
    getDailyNotificationsItems: Actions.getDailyNotificationsItems,
    getNextDailyNotificationsItems: Actions.getNextDailyNotificationsItems,
};

const mapStateToProps = state => {
    return {
        unreadCount: state.getIn(['dailyNotifications', 'unreadCount']),
        items: state.getIn(['dailyNotifications', 'items']),
        itemsAreLoading: state.getIn(['dailyNotifications', 'itemsAreLoading']),
        nextItemsAreLoading: state.getIn(['dailyNotifications', 'nextItemsAreLoading']),
        nextUrl: state.getIn(['dailyNotifications', 'nextUrl']),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyNotifications);
