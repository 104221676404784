import classNames from 'classnames';
import { fromJS } from 'immutable';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { setDefaultDogCreationData } from '../../../../actions/miscActions';
import { getBreedOptions, getGenderOptions, getWeightOptions } from '../../../../actions/modal/getOptionsActions';
import * as options from '../../../../helpers/options';
import { searchCustomersByName } from '../../../../services/customers';
import renderInputControl from '../../../common/Form/InputControl';
import { dogCreationOptionRenderer } from '../../../common/Form/optionRenderers';
import { renderError, renderWarning } from '../../../common/Form/RadioControl/RadioControl';
import renderSelectControl from '../../../common/Form/SelectControl';
import validate from './validate';

const inputChangeDebounceTime = 750;

class DogCreationModalDialogForm extends React.PureComponent {
    static propTypes = {
        initialValues: PropTypes.shape({}),
        customerName: PropTypes.string,
        handleSubmit: PropTypes.func,
        handleDefaultValues: PropTypes.func,
        handleSexOptions: PropTypes.func,
        handleBreedOptions: PropTypes.func,
        handleWeightOptions: PropTypes.func,
        searchCustomers: PropTypes.func,
        sexOptions: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.shape({})),
            PropTypes.shape({})
        ]),
        breedOptions: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.shape({})),
            PropTypes.shape({})
        ]),
        weightOptions: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.shape({})),
            PropTypes.shape({})
        ]),
        isInjured: PropTypes.string,
        isIntoleranced: PropTypes.string,
        invalid: PropTypes.bool,
        submitting: PropTypes.bool,
        error: PropTypes.string,
        isOwnerDisabled: PropTypes.bool,
        isLoading: PropTypes.bool,
    };

    componentDidMount() {
        this.props.handleDefaultValues();
    }

    getOptions = (value, callback) => {
        const { searchCustomers } = this.props;

        searchCustomers(value)
            .then((response) => {
                callback(null, {
                    options: this.convertSelectOptions(response.data.results)
                });
            })
            .catch((error) => {
                console.log(error);
                console.log(error.response);
                callback(error);
            });
    };

    getBreedOptions = (value, callback) => {
        const { handleBreedOptions } = this.props;
        return handleBreedOptions()(value, callback);
    };

    getGenderOptions = (value, callback) => {
        const { handleSexOptions } = this.props;
        return handleSexOptions()(value, callback);
    };

    getWeightOptions = (value, callback) => {
        const { handleWeightOptions } = this.props;
        return handleWeightOptions()(value, callback);
    };

    getOwnerField = () => {
        const { isOwnerDisabled, customerName } = this.props;
        if (isOwnerDisabled) {
            return (
                <Field
                    name='owner'
                    type='text'
                    disabled
                    component={renderInputControl}
                    value={customerName}
                    label='Customer’s full name (Customer that the dog belongs to)'/>
            );
        }

        return (
            <Field
                name='owner'
                type='text'
                autocomplete
                optionRenderer={dogCreationOptionRenderer}
                getOptions={_.debounce(this.getOptions, inputChangeDebounceTime)}
                noResultsText={<span className='no-results-message'>Please choose an existing user</span>}
                autofocus
                component={renderSelectControl}
                label='Customer’s full name (Customer that the dog belongs to)'/>
        );
    };

    convertSelectOptions = results => {
        return results.map(item => {
            return {
                label: item.full_name,
                value: item.id,
                extra: item.email
            };
        });
    };

    render() {
        const {
            handleSubmit, invalid, submitting, isInjured, isIntoleranced, error, isLoading,
        } = this.props;
        const submitButtonClassNames = classNames({
            'button modal-dialog__button': true,
            'button_disabled': (invalid || submitting || isLoading)
        });
        return (
            <form className='modal-dialog__form modal-dialog__form_dog-creation' onSubmit={handleSubmit}>
                <div className='modal-dialog__form-content'>

                    {this.getOwnerField()}

                    <div className='form__aligner form__aligner_50-50'>
                        <Field
                            name='name'
                            type='text'
                            component={renderInputControl}
                            label='Dog Name'/>
                        <Field
                            name='gender'
                            type='text'
                            async
                            autoload
                            component={renderSelectControl}
                            getOptions={this.getGenderOptions}
                            label='Sex'/>
                    </div>
                    <div className='form__aligner form__aligner_50-50'>
                        <Field
                            name='month'
                            type='text'
                            component={renderSelectControl}
                            options={options.month}
                            label='Birthday Month'/>
                        <Field
                            name='year'
                            type='text'
                            component={renderSelectControl}
                            options={options.year}
                            label='Birthday Year'/>
                    </div>
                    <div className='form__aligner form__aligner_50-50'>
                        <Field
                            name='breed'
                            async
                            autoload
                            type='text'
                            component={renderSelectControl}
                            getOptions={this.getBreedOptions}
                            label='Breed'
                            withInput/>
                        <Field
                            name='weight'
                            type='text'
                            async
                            autoload
                            component={renderSelectControl}
                            getOptions={this.getWeightOptions}
                            label='Weight'/>
                    </div>
                    <div className='form__select-aligner'>
                        <div className='radio-control'>
                            <div className='radio-control__description'>
                                <label className='radio-control__description-label'>
                                    Has your dog been spayed/neutered?
                                </label>
                                <Field name='is_altered' component={renderError}/>
                                <Field name='is_altered' component={renderWarning}/>
                            </div>
                            <div className='radio-control__controls'>
                                <label className='radio-control__controls-label'>
                                    <Field
                                        name='is_altered'
                                        component='input'
                                        type='radio'
                                        value='Yes'
                                        className='radio-control__controls-input'/>
                                    <span className='radio-control__controls-text'>Yes</span>
                                </label>
                                <label className='radio-control__controls-label'>
                                    <Field
                                        name='is_altered'
                                        component='input'
                                        type='radio'
                                        value='No'
                                        className='radio-control__controls-input'/>
                                    <span className='radio-control__controls-text'>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='form__select-aligner'>
                        <div className='radio-control'>
                            <div className='radio-control__description'>
                                <label className='radio-control__description-label'>
                                    Does your dog have any allergies?
                                </label>
                                <Field name='is_intoleranced' component={renderWarning}/>
                            </div>
                            <div className='radio-control__controls'>
                                <label className='radio-control__controls-label'>
                                    <Field
                                        name='is_intoleranced'
                                        component='input'
                                        type='radio'
                                        value='Yes'
                                        className='radio-control__controls-input'/>
                                    <span className='radio-control__controls-text'>Yes</span>
                                </label>
                                <label className='radio-control__controls-label'>
                                    <Field
                                        name='is_intoleranced'
                                        component='input'
                                        type='radio'
                                        value='No'
                                        className='radio-control__controls-input'/>
                                    <span className='radio-control__controls-text'>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    {
                        isIntoleranced === 'Yes' &&
                        <Field
                            name='intolerance_list'
                            type='text'
                            className='select-control__is_intoleranced'
                            component={renderSelectControl}
                            multi
                            creatable
                            label='Please list all allergies or intolerances.'/>
                    }
                    <div className='form__select-aligner'>
                        <div className='radio-control'>
                            <div className='radio-control__description'>
                                <label className='radio-control__description-label'>
                                    Does your dog have any injuries or medical conditions?
                                </label>
                                <Field name='is_injured' component={renderWarning}/>
                            </div>
                            <div className='radio-control__controls'>
                                <label className='radio-control__controls-label'>
                                    <Field
                                        name='is_injured'
                                        component='input'
                                        type='radio'
                                        value='Yes'
                                        className='radio-control__controls-input'/>
                                    <span className='radio-control__controls-text'>Yes</span>
                                </label>
                                <label className='radio-control__controls-label'>
                                    <Field
                                        name='is_injured'
                                        component='input'
                                        type='radio'
                                        value='No'
                                        className='radio-control__controls-input'/>
                                    <span className='radio-control__controls-text'>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    {
                        isInjured === 'Yes' &&
                        <Field
                            name='medical_notes'
                            type='text'
                            className='input-control__is_injured'
                            isTextarea
                            component={renderInputControl}
                            label='Please tell us about any other medical conditions.'/>
                    }
                    {error && <div className='form__error'>{error}</div>}
                </div>
                <div className='modal-dialog__form-footer modal-dialog__form-footer_row'>
                    <button
                        className={submitButtonClassNames}
                        type='submit'
                        disabled={submitting || isLoading}>
                        {(submitting || isLoading)
                            ? 'Creating...'
                            : 'Create New Dog'
                        }
                    </button>
                </div>
            </form>
        );
    }
}

const form = reduxForm({
    form: 'DogCreationModalDialogForm',
    enableReinitialize: true,
    validate
});

const mapStateToProps = state => {
    const instance = state.getIn(['currentCustomer', 'instance']);
    return {
        initialValues: fromJS({
            owner: instance.get('full_name'),
            is_intoleranced: 'No',
            is_injured: 'No',
            is_altered: 'No'
        }),
        isInjured: state.getIn(['form', 'DogCreationModalDialogForm', 'values', 'is_injured']),
        isIntoleranced: state.getIn(['form', 'DogCreationModalDialogForm', 'values', 'is_intoleranced']),
        isLoading: state.getIn(['misc', 'modals', 'isDogCreationLoading']),
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleDefaultValues: bindActionCreators(setDefaultDogCreationData, dispatch),
    handleSexOptions: bindActionCreators(getGenderOptions, dispatch),
    handleBreedOptions: bindActionCreators(getBreedOptions, dispatch),
    handleWeightOptions: bindActionCreators(getWeightOptions, dispatch),
    searchCustomers: bindActionCreators(searchCustomersByName, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(form(DogCreationModalDialogForm));
