import PropTypes from 'prop-types';
import React from 'react';
import Grid from '../../../../../common/Grid';

const PackagesGrid = ({ data, getNext, readOnly, isLoading }) => {
    return (
        <Grid
            items={data.get('items')}
            className='packages__grid'
            cardType='package'
            getNext={getNext}
            nextUrl={data.get('nextUrl')}
            isLoading={isLoading}
            readOnly={readOnly}/>
    );
};

PackagesGrid.propTypes = {
    data: PropTypes.shape({}).isRequired,
    getNext: PropTypes.func,
    readOnly: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
};

export default PackagesGrid;
