import axios from 'axios';
import { getUserNameSpace } from '../../../../helpers/userRoles';

export const getActivityStreamUnreadCount = () => {
    return () => {
        return axios.get(`/v1/${getUserNameSpace('activity-stream')}/activity-stream/unread-count/`);
    };
};

export const getActivityStream = (nextUrl) => {
    const url = nextUrl || `/v1/${getUserNameSpace('activity-stream')}/activity-stream/`;
    return () => {
        return axios.get(url);
    };
};
