import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import * as OverviewActions from '../../../../actions/dashboard/overviewActions';
import * as TabActions from '../../../../actions/tabActions';
import { mapFormToState } from '../../FormContainer/index';
import TabSwitcher from '../DashboardPage/TabSwitcher';
import OverviewPage from './OverviewPage';
import CustomReportsPage from './CustomReportsPage';
import * as OpenActions from '../../../../actions/modal/openActions';
import { isManager, isSuperadmin } from '../../../../helpers/userRoles';
import { exportReportTo } from '../../../../actions/dashboard/customReportsActions';
import { openCustomReports } from '../../../../actions/dashboard/reportsActions';

class ReportsContainer extends React.PureComponent {
    static propTypes = {
        reports: PropTypes.shape({}).isRequired,
        overview: PropTypes.shape({}).isRequired,
        formState: PropTypes.shape({}).isRequired,
        getAllOverviewData: PropTypes.func.isRequired,
        handlePageChange: PropTypes.func.isRequired,
        customReports: PropTypes.shape({}).isRequired,
        openSaveAsCampaignModalDialog: PropTypes.func.isRequired,
        openCustomReports: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { handlePageChange, reports } = this.props;
        handlePageChange({
            nextPageName: reports.get('activePage'),
            params: { start: reports.get('start'), end: reports.get('end') },
        });
    }

    tabSwitcherItems = [
        {
            page: 'overview',
            title: 'Overview',
        },
        {
            page: 'customReports',
            title: 'Custom Reports',
        },
    ];

    handleNavbarClick = pageName => {
        const { reports, handlePageChange } = this.props;

        if (pageName === reports.get('activePage')) {
            return;
        }

        handlePageChange({
            nextPageName: pageName,
            params: { start: reports.get('start'), end: reports.get('end') },
        });
    };

    render() {
        const { reports } = this.props;
        const activePage = reports.get('activePage');
        if (!isManager() && !isSuperadmin()) {
            return (
                <div className='reports'>
                    <div className='reports__pages'>
                        <CustomReportsPage {...this.props}/>
                    </div>
                </div>
            );
        }
        return (
            <div className='reports'>
                <TabSwitcher
                    blockClassName='navbar'
                    className='reports__navbar'
                    items={this.tabSwitcherItems}
                    activePage={activePage}
                    onNavbarClick={this.handleNavbarClick}/>
                <div className='reports__pages'>
                    {activePage === 'overview'
                        ? <OverviewPage {...this.props}/>
                        : <CustomReportsPage {...this.props}/>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        overview: state.get('overview'),
        reports: state.get('reports'),
        customReports: state.get('customReports'),
        formState: mapFormToState(['OverviewFilterForm'], state),
        customReportsFilter: mapFormToState(['customReportFilterForm'], state),
    };
};

const mapDispatchToProps = {
    getAllOverviewData: OverviewActions.getAllOverviewData,
    handlePageChange: TabActions.handlePageChange,
    openSaveAsCampaignModalDialog: OpenActions.openSaveAsCampaignModalDialog,
    exportReportTo,
    openCustomReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsContainer);
