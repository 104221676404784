import * as axios from 'axios';
import { fromJS } from 'immutable';
import { SubmissionError } from 'redux-form/immutable';
import { addCustomersAvailableAddress } from '../actions/dashboard//availableAddressesActions';
import { setAddAddressModalClosed } from '../actions/miscActions';
import { getUserNameSpace, isCustomer } from '../helpers/userRoles';
import { updateCustomerLocationData } from './customers';
import { addStepData } from '../actions/steppedFormActions';
import { getScope } from './oauth';

export const patchAvailableAddress = (customerId, address) => {
    return dispatch => {
        console.log('patchAvailableAddress fired');
        console.log(address);
        const request = new FormData();
        Object.keys(address).forEach((chunk) => {
            if (chunk === 'id') {
                return;
            }
            request.append(chunk, address[chunk]);
        });
        request.append('scope', getScope());

        return axios.patch(`/v1/${getUserNameSpace('customers.available-addresses')}/customers/${customerId}/available-addresses/${address.id}/`, request)
                    .then(response => {
                        console.log('Server response: ', response);
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                        if (err.response) {
                            if (err.response.status !== 200) {
                                console.log('Unexpected error code from the API server: ', err.response.status);
                                if (err.response.data.non_field_errors) {
                                    throw new SubmissionError({
                                        _error: err.response.data.non_field_errors,
                                    });
                                }
                            }
                        }
                    });
    };
};

export const postAvailableAddress = (customerId, address) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            console.log('postAvailableAddress fired');
            console.log(address);
            if (address.state.value !== undefined) {
                address.state = address.state.value;
            }
            const request = new FormData();
            Object.keys(address).forEach((chunk) => {
                request.append(chunk, address[chunk]);
            });
            request.append('scope', getScope());

            axios.post(
                `/v1/${getUserNameSpace('customers.available-addresses')}/customers/${customerId}/available-addresses/`,
                request)
                 .then(response => {
                     console.log('Server response: ', response);
                     resolve(response);
                 })
                 .catch(err => {
                     console.log(err);
                     console.log(err.response);
                     if (err.response) {
                         if (err.response.status !== 200) {
                             console.log('Unexpected error code from the API server: ', err.response.status);
                             reject(err);
                             // if (err.response.data.error_description) {
                             //     throw new SubmissionError({
                             //         email: err.response.data.error_description,
                             //         password: err.response.data.error_description
                             //     });
                             // }
                         }
                     }
                 });
        });
    };
};

// The async keyword will automatically create a new Promise and return it.
export const postAvailableAddressAsync = (customerId, address) => {
    return async dispatch => {
        const request = new FormData();
        const url = isCustomer()
            ? '/v1/customers/me/available-addresses/'
            : `/v1/${getUserNameSpace('customers.available-addresses')}/customers/${customerId}/available-addresses/`;
        Object.keys(address).forEach((chunk) => {
            request.append(chunk, address[chunk]);
        });
        request.append('scope', getScope());

        const postedAddress = await axios.post(url, request);

        window._dcq.push(['track', 'Added location']);
        
        return postedAddress;
    };
};

export const deleteAvailableAddress = (customerId, address) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            console.log('deleteAvailableAddress fired');
            console.log(address);
            const url = isCustomer()
                ? `/v1/customers/me/available-addresses/${address.id}/`
                : `/v1/${getUserNameSpace(
                    'customers.available-addresses')}/customers/${customerId}/available-addresses/${address.id}/`;
            return axios.delete(url)
                        .then(response => {
                            console.log('Server response: ', response);
                            resolve(response);
                        })
                        .catch(err => {
                            console.log(err);
                            console.log(err.response);
                            if (err.response) {
                                if (err.response.status !== 200) {
                                    console.log('Unexpected error code from the API server: ', err.response.status);
                                    reject(err);
                                }
                            }
                        });
        });
    };
};

export const getAvailableAddress = (customerId) => {
    const url = isCustomer()
        ? '/v1/customers/me/available-addresses/'
        : `/v1/${getUserNameSpace('customers.available-addresses')}/customers/${customerId}/available-addresses/`;
    return axios.get(url);
};

export const saveCustomerPickupLocation = (locationData) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(postAvailableAddressAsync(null, locationData))
                .then(({ data }) => {
                    const addressData = {};
                    if (locationData.home_address) {
                        addressData.address = data.id;
                    }
                    if (locationData.default_pickup) {
                        addressData.pickup_location = data.id;
                    }
                    if (locationData.default_dropoff) {
                        addressData.drop_off_location = data.id;
                    }
                    if (Object.keys(addressData).length) {
                        dispatch(updateCustomerLocationData(addressData))
                            .then(res => resolve(data))
                            .catch(err => reject(err));
                    } else {
                        resolve(data);
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    };
};

export const loadBookingAddresses = () => {
    return dispatch => {
        return axios.get(`/v1/${getUserNameSpace('me')}/me/available-addresses/`)
             .then(({ data }) => {
                return data.results;
            })
            .catch(err => console.log(err));
    };
};

export const addNewBookingAddress = (addressData, formName) => {
    return (dispatch, getState) => {
        addressData.state = addressData.state.value;
        const result = dispatch(postAvailableAddressAsync(null, addressData))
            .then(({ data }) => {
                if (formName) {
                    const addresses = getState().getIn(['steppedForm', formName, 'data', 'addresses']);
                    if (addresses.size) {
                        const addressesList = addresses.toJS();
                        addressesList.push(data);
                        dispatch(addStepData(formName, { name: 'addresses', data: fromJS(addressesList) }));
                    }
                }
                dispatch(addCustomersAvailableAddress(data));
                dispatch(setAddAddressModalClosed());
                return data;
            })
            .catch(err => {
                console.log(err);
                return false;
            });

        return result;
    };
};

export const patchCustomerAvailableAddress = (addressData, id) => {
    return async dispatch => {
        const url = `/v1/${getUserNameSpace('me')}/me/available-addresses/${id}/`;
        const patchedAddress = await axios.patch(url, addressData);
        return patchedAddress;
    };
};
