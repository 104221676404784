const prefix = 'marketing-editor/';

export const RESET_REDUCER = prefix + 'RESET_REDUCER';
export const SELECT_STEP = prefix + 'SELECT_STEP';
export const SELECT_TEMPLATE = prefix + 'SELECT_TEMPLATE';

export const UPDATE_DETAIL = prefix + 'UPDATE_DETAIL';
export const UPDATE_UPLOAD_PROGRESS = prefix + 'UPDATE_UPLOAD_PROGRESS';
export const START_DETAIL_REQUEST = prefix + 'START_DETAIL_REQUEST';
export const FINISH_DETAIL_REQUEST = prefix + 'FINISH_DETAIL_REQUEST';
