const validate = values => {
    const errors = {};

    if (!values.get('email')) {
        errors.email = 'Email is Required.';
    } else if (values.get('email').length < 5) {
        errors.email = 'It\'s not allowed to enter less than 5 symbols into this field';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.get('email'))) {
        errors.email = 'Please enter a valid e-mail address.';
    } else if (values.get('email').length > 254) {
        errors.email = 'It\'s not allowed to enter more than 254 symbols into this field';
    }

    return errors;
};

export default validate;
