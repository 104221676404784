const validate = values => {
    const errors = {};
    if (!values.get('name')) {
        errors.name = 'Veterinarian name is Required.';
    } else if (values.get('name').length < 5) {
        errors.name = 'Veterinarian name must be 5 characters or more.';
    } else if (values.get('name').length > 100) {
        errors.name = 'Veterinarian name must be 100 characters or less.';
    }

    if (!values.get('phone')) {
        errors.phone = 'Phone Number is Required.';
    } else if (!/^[0-9()+-/,.]*$/i.test(values.get('phone'))) {
        errors.phone = 'Please enter a valid phone number';
    } else if (values.get('phone').replace(/[^\w\s]/gi, '').length < 5) {
        errors.phone = 'Phone Number is too short.';
    } else if (values.get('phone').replace(/[^\w\s]/gi, '').length > 15) {
        errors.phone = 'Phone Number is too large.';
    }
    return errors;
};

export default validate;
