export const MARK_CUSTOMER_PREVIOUS_CLASSES_LOADED = 'MARK_CUSTOMER_PREVIOUS_CLASSES_LOADED';
export const UNMARK_CUSTOMER_PREVIOUS_CLASSES_LOADED = 'UNMARK_CUSTOMER_PREVIOUS_CLASSES_LOADED';
export const START_ISLOADING_CUSTOMER_PREVIOUS_CLASSES = 'START_ISLOADING_CUSTOMER_PREVIOUS_CLASSES';
export const FINISH_ISLOADING_CUSTOMER_PREVIOUS_CLASSES = 'FINISH_ISLOADING_CUSTOMER_PREVIOUS_CLASSES';
export const SET_CUSTOMER_PREVIOUS_CLASSES = 'SET_CUSTOMER_PREVIOUS_CLASSES';
export const SET_CUSTOMER_PREVIOUS_CLASS_DETAILS = 'SET_CUSTOMER_PREVIOUS_CLASS_DETAILS';
export const UPDATE_CUSTOMER_PREVIOUS_CLASS = 'UPDATE_CUSTOMER_PREVIOUS_CLASS';

export const MARK_CUSTOMER_UPCOMING_CLASSES_LOADED = 'MARK_CUSTOMER_UPCOMING_CLASSES_LOADED';
export const UNMARK_CUSTOMER_UPCOMING_CLASSES_LOADED = 'UNMARK_CUSTOMADD_CUSTOMERS_AVAILABLE_ADDRESSER_UPCOMING_CLASSES_LOADED';
export const START_ISLOADING_CUSTOMER_UPCOMING_CLASSES = 'START_ISLOADING_CUSTOMER_UPCOMING_CLASSES';
export const FINISH_ISLOADING_CUSTOMER_UPCOMING_CLASSES = 'FINISH_ISLOADING_CUSTOMER_UPCOMING_CLASSES';
export const SET_CUSTOMER_UPCOMING_CLASSES = 'SET_CUSTOMER_UPCOMING_CLASSES';
export const SET_CUSTOMER_UPCOMING_CLASS_DETAILS = 'SET_CUSTOMER_UPCOMING_CLASS_DETAILS';
export const REMOVE_CUSTOMERS_UPCOMING_CLASS = 'REMOVE_CUSTOMERS_UPCOMING_CLASS';
export const CHARGE_FEE_AFTER_CANCELING_CLASS = 'CHARGE_FEE_AFTER_CANCELING_CLASS';
export const WAIVE_FEE_AFTER_CANCELING_CLASS = 'WAIVE_FEE_AFTER_CANCELING_CLASS';

export const MARK_DOG_PREVIOUS_CLASSES_LOADED = 'MARK_DOG_PREVIOUS_CLASSES_LOADED';
export const UNMARK_DOG_PREVIOUS_CLASSES_LOADED = 'UNMARK_DOG_PREVIOUS_CLASSES_LOADED';
export const START_ISLOADING_DOG_PREVIOUS_CLASSES = 'START_ISLOADING_DOG_PREVIOUS_CLASSES';
export const FINISH_ISLOADING_DOG_PREVIOUS_CLASSES = 'FINISH_ISLOADING_DOG_PREVIOUS_CLASSES';
export const SET_DOG_PREVIOUS_CLASSES = 'SET_DOG_PREVIOUS_CLASSES';
export const SET_DOG_PREVIOUS_CLASS_DETAILS = 'SET_DOG_PREVIOUS_CLASS_DETAILS';
export const UPDATE_DOG_PREVIOUS_CLASS = 'UPDATE_DOG_PREVIOUS_CLASS';

export const MARK_DOG_UPCOMING_CLASSES_LOADED = 'MARK_DOG_UPCOMING_CLASSES_LOADED';
export const UNMARK_DOG_UPCOMING_CLASSES_LOADED = 'UNMARK_DOG_UPCOMING_CLASSES_LOADED';
export const START_ISLOADING_DOG_UPCOMING_CLASSES = 'START_ISLOADING_DOG_UPCOMING_CLASSES';
export const FINISH_ISLOADING_DOG_UPCOMING_CLASSES = 'FINISH_ISLOADING_DOG_UPCOMING_CLASSES';
export const SET_DOG_UPCOMING_CLASSES = 'SET_DOG_UPCOMING_CLASSES';
export const SET_DOG_UPCOMING_CLASS_DETAILS = 'SET_DOG_UPCOMING_CLASS_DETAILS';
export const REMOVE_DOGS_UPCOMING_CLASS = 'REMOVE_DOGS_UPCOMING_CLASS';

export const DELETE_PREVIOUS_CLASS_REPORTS = 'DELETE_PREVIOUS_CLASS_REPORTS';
export const UPDATE_PREVIOUS_CLASS_REPORTS = 'UPDATE_PREVIOUS_CLASS_REPORTS';
