import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import { dataStates, displayStates } from '../helpers/state';
import * as MessageTypes from '../types/messagesTypes';

const INITIAL_STATE = fromJS({
    displayState: displayStates.closed,
    customersName: null,
    customersId: null,
    dataState: dataStates.notAsked,
    items: [],
    count: 0,
    nextUrl: null,
    previousUrl: null,
    error: null,
});

export const messageNormalizer = (formData) => {
    console.log('messageNormalizer formData: ', formData);
    const request = new FormData();
    if (formData.get('message_input') !== undefined) {
        request.append('body', formData.get('message_input'));
    }
    const messagePhoto = formData.get('message_photo');
    if (messagePhoto) {
        messagePhoto.forEach((photo, i) => {
            request.append(`photos_upload[${i}]`, photo);
        });
    }
    return request;
};

const messagesReducer = handleActions({
    [MessageTypes.SET_MESSAGES_WIDGET_OPENED]: (state, action) => {
        // eslint-disable-next-line no-extra-boolean-cast
        if (action.payload) {
            return state.set('displayState', displayStates.opened)
                        .set('customersName', action.payload.name)
                        .set('customersId', action.payload.id);
        }
        return state.set('displayState', displayStates.opened);
    },
    [MessageTypes.SET_MESSAGES_WIDGET_CLOSED]: () => {
        return INITIAL_STATE;
    },
    [MessageTypes.SET_MESSAGES_WIDGET_MINIMIZED]: (state) => {
        return state.set('displayState', displayStates.minimized);
    },
    [MessageTypes.SET_MESSAGES_LOADING]: (state) => {
        return state.set('dataState', dataStates.loading);
    },
    [MessageTypes.SET_MESSAGES_LOADING_MORE]: (state) => {
        return state.set('dataState', dataStates.loadingMore);
    },
    [MessageTypes.SET_MESSAGES_LOADED]: (state) => {
        return state.set('dataState', dataStates.loaded);
    },
    [MessageTypes.SET_MESSAGES_SUBMITTING]: (state) => {
        return state.set('dataState', dataStates.submitting);
    },
    [MessageTypes.SET_MESSAGES_FAILED]: (state) => {
        return state.set('dataState', dataStates.failed);
    },
    [MessageTypes.SET_MESSAGES_DATA]: (state, action) => {
        const { results, count, next, previous } = action.payload;
        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);
        return state.update((state) => {
            return state.setIn(['items'], fromJS(results.reverse()))
                        .setIn(['count'], count)
                        .setIn(['nextUrl'], nextUrl)
                        .setIn(['previousUrl'], previousUrl);
        });
    },
    [MessageTypes.ADD_MESSAGE_TO_MESSAGES_ITEMS]: (state, action) => {
        const items = state.get('items');
        return state.set('items', items.insert(items.size, action.payload));
    },
    [MessageTypes.SET_NEXT_MESSAGES_DATA]: (state, action) => {
        const { results, count, next, previous } = action.payload;
        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);

        const itemsJS = state.get('items').toJS();
        // results.forEach((result) => {
        //     itemsJS.unshift(result);
        // });
        // above commented for the history
        Array.prototype.unshift.apply(itemsJS, results.reverse());

        return state.update((state) => {
            return state
                .setIn(['items'], fromJS(itemsJS))
                .setIn(['count'], count)
                .setIn(['nextUrl'], nextUrl)
                .setIn(['previousUrl'], previousUrl);
        });
    },
    [MessageTypes.UPDATE_MESSAGE_DATA_BY_ID]: (state, action) => {
        const updateIndex = state.getIn(['items']).findKey(item => item.get('id') === action.payload.id);
        return state.setIn(['items', updateIndex], fromJS(action.payload));
    },
    [MessageTypes.UPDATE_MESSAGES_DATA]: (state, action) => {
        const items = state.getIn(['items']);
        const index = items.findKey(item => item.get('id') === action.payload.id);
        if (index === undefined) {
            return state.update((state) => {
                return state.setIn(['items'], items.insert(items.size, fromJS(action.payload)));
            });
        }
        return state;
    },
    [MessageTypes.SET_MESSAGES_POLLING]: (state) => {
        return state.set('dataState', dataStates.polling);
    },
}, INITIAL_STATE);

export default messagesReducer;
