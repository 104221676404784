const validate = values => {
    const errors = {};
    if (!values.get('name')) {
        errors.name = 'Name is Required.';
    }

    if (!values.get('address1')) {
        errors.address1 = 'Location is Required.';
    } else if (values.get('address1').length > 254) {
        errors.address1 = 'It\'s not allowed to enter more than 254 symbols into this field';
    }

    if (values.get('address2') && values.get('address2').length > 254) {
        errors.address2 = 'It\'s not allowed to enter more than 254 symbols into this field';
    }

    if (!values.get('city')) {
        errors.city = 'City is Required.';
    } else if (values.get('city').length > 254) {
        errors.city = 'It\'s not allowed to enter more than 254 symbols into this field';
    }

    if (!values.get('state') || Array.isArray(values.get('state'))) {
        errors.state = 'State is Required.';
    }

    if (!values.get('zip_code')) {
        errors.zip_code = 'Zip Code is Required.';
    } else if (values.get('zip_code').length > 10) {
        errors.zip_code = 'Zip Code must be 10 characters or less.';
    }

    if (values.get('location_notes') && values.get('location_notes').length > 254) {
        errors.location_notes = 'It\'s not allowed to enter more than 254 symbols into this field';
    }
    return errors;
};

export default validate;
