import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';

const SidebarTextLink = ({ activeClassName, item, className, onMouseEnterFunction, onMouseLeaveFunction }) => {
    return (
        <Link
            activeClassName={activeClassName}
            onlyActiveOnIndex
            className={'sidebar__text ' + className}
            to={item.to}
            onMouseEnter={() => { onMouseEnterFunction(); }}
            onMouseLeave={() => { onMouseLeaveFunction(); }}>
            {item.text}
        </Link>
    );
};

SidebarTextLink.propTypes = {
    activeClassName: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    item: PropTypes.shape({
        text: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired
    }),
    onMouseEnterFunction: PropTypes.func,
    onMouseLeaveFunction: PropTypes.func,
};

export default SidebarTextLink;
