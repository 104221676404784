import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form/immutable';
import classNames from 'classnames';
import renderInputControl from '../../../../common/Form/InputControl';
import validate from './validate';
import { phoneNumberNormalizer, PHONE_MASK, PHONE_MASK_CHAR } from '../../../../../helpers/normalize';

const SmsVerificationStepForm = ({ 
    invalid,
    submitting,
    onCancelHandler,
    submitButtonClasses,
    handleSubmit,
}) => {
    const submitButtonClassNames = classNames({
        'button modal-dialog__button modal-dialog__button_sms': true,
        'button_disabled': (invalid || submitting),
        [submitButtonClasses]: true,
    });
    return (
        <form onSubmit={handleSubmit}className='sms-modal__form'>
            <div className='sms-modal__form-content'>
                <Field
                    type='tel'
                    mask={PHONE_MASK}
                    maskChar={PHONE_MASK_CHAR}
                    isMasked
                    normalize={phoneNumberNormalizer}
                    name='phone'
                    label='Phone Number'
                    className='sms-modal__field'
                    component={renderInputControl}/>
            </div>
            <div className='sms-modal__form-footer'>
                <button
                    className={submitButtonClassNames}
                    type='submit'
                    disabled={submitting}>
                    {(submitting)
                        ? 'Submitting...'
                        : 'Add phone'
                    }
                </button>
                <span className='sms-modal__cancel-button' onClick={onCancelHandler}>Cancel</span>
            </div>
        </form>
    );
};

SmsVerificationStepForm.propTypes = {
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    onCancelHandler: PropTypes.func,
    submitText: PropTypes.string,
    handleSubmit: PropTypes.func,
    formData: PropTypes.shape({}),
    submitButtonClasses: PropTypes.string,
};

const form = reduxForm({
    form: 'SmsAddPhoneForm',
    validate
});

export default form(SmsVerificationStepForm);
