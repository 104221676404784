import { createAction } from 'redux-actions';
import { push } from 'react-router-redux';
import * as FormsPageTypes from '../../types/dashboard/formsPageTypes';
import * as FormsService from '../../services/formsPage';
import { setGlobalError } from '../errorHandlerActions';
import { setUserGroupingOptions } from '../miscActions';
import { generateFormCreateFormEmployeeConfig } from '../../reducers/formsPageReducer';
import { createUserGroupingOptions } from '../../services/options';

export const resetFormsPageReducer = createAction(FormsPageTypes.RESET_FORMS_PAGE_REDUCER);

export const startFormsDataLoading = createAction(FormsPageTypes.START_FORMS_DATA_LOADING);
export const finishFormsDataLoading = createAction(FormsPageTypes.FINISH_FORMS_DATA_LOADING);
export const setFormsItemsFinishLoading = createAction(FormsPageTypes.SET_FORMS_ITEMS_FINISH_LOADING);
export const appendFormsItemsFinishLoading = createAction(FormsPageTypes.APPEND_FORMS_ITEMS_FINISH_LOADING);
export const removeFormById = createAction(FormsPageTypes.REMOVE_FORM_BY_ID);

export const startCurrentFormDataLoading = createAction(FormsPageTypes.START_CURRENT_FORM_DATA_LOADING);
export const finishCurrentFormDataLoading = createAction(FormsPageTypes.FINISH_CURRENT_FORM_DATA_LOADING);
export const setCurrentFormFinishLoading = createAction(FormsPageTypes.SET_CURRENT_FORM_FINISH_LOADING);
export const updateCurrentForm = createAction(FormsPageTypes.UPDATE_CURRENT_FORM);

export const getFormsData = query => {
   return dispatch => {
       dispatch(startFormsDataLoading());
      dispatch(FormsService.getFormsData(query))
          .then(response => {
              dispatch(setFormsItemsFinishLoading(response.data));
          })
          .catch(err => {
              console.log(err);
              dispatch(finishFormsDataLoading());
              dispatch(setGlobalError(err));
          });
   };
};

export const getMoreFormsData = () => {
    return (dispatch, getState) => {
        const query = getState().getIn(['formsPage', 'forms', 'nextUrlQuery']);
        
        if (!query) {
            return;
        }
        
        dispatch(startFormsDataLoading());
        dispatch(FormsService.getFormsData(query))
            .then(response => {
                dispatch(appendFormsItemsFinishLoading(response.data));
            })
            .catch(err => {
                console.log(err);
                dispatch(finishFormsDataLoading());
                dispatch(setGlobalError(err));
            });
    };
};

export const gotoFormEdit = formId => {
    return dispatch => {
        dispatch(push(`/dashboard/forms/${formId}`));
    };
};

export const getCurrentFormData = formId => {
    return (dispatch, getState) => {
        const hasUserGroupingOptions = getState().getIn(['misc', 'options', 'userGrouping']).size > 0;
        const createUserGroupingOptions = data => {
            return data.map(item => {
                return {
                    value: item[0],
                    label: item[1].toLowerCase()
                };
            });
        };
        dispatch(startCurrentFormDataLoading());
        return Promise.all([
            dispatch(FormsService.getCurrentFormData(formId)),
            hasUserGroupingOptions ? null : dispatch(FormsService.getUserGroupingOptions())
        ])
            .then(([currentFormDataResponse, userGroupingOptionsResponse]) => {
                if (userGroupingOptionsResponse) {
                    dispatch(setUserGroupingOptions(createUserGroupingOptions(userGroupingOptionsResponse.data)));
                }
                dispatch(setCurrentFormFinishLoading(currentFormDataResponse.data));
            })
            .catch(err => {
                console.log(err);
                dispatch(finishCurrentFormDataLoading());
                dispatch(setGlobalError(err));
            });
    };
};

export const getNewFormData = () => {
    return (dispatch, getState) => {
        dispatch(updateCurrentForm({ formConfig: generateFormCreateFormEmployeeConfig() }));
        if (!getState().getIn(['misc', 'options', 'userGrouping']).size) {
            dispatch(startCurrentFormDataLoading());
            dispatch(FormsService.getUserGroupingOptions())
                .then(response => {
                    dispatch(setUserGroupingOptions(createUserGroupingOptions(response.data)));
                    dispatch(finishCurrentFormDataLoading());
                })
                .catch(err => {
                    console.log(err);
                    dispatch(finishCurrentFormDataLoading());
                    dispatch(setGlobalError(err));
                });
        }
    };
};

