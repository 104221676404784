import { createAction } from 'redux-actions';
import { sendFile } from '../../services/file';
import * as VeterinarianTypes from '../../types/onboarding/veterinarianTypes';
import { updateCustomerInfo } from './userActions';
import { updateDogsVeterinarian } from '../../actions/onboarding/dogCreationActions';

export const setDefaultVetData = createAction(VeterinarianTypes.SET_DEFAULT_VET_DATA);
export const setVetInfo = createAction(VeterinarianTypes.SET_VET_INFO);
export const startVetRecordsLoading = createAction(VeterinarianTypes.START_RECORDS_LOADING);
export const finishVetRecordsLoading = createAction(VeterinarianTypes.FINISH_RECORDS_LOADING);

export const sendVeterinarianRecord = (record) => sendFile(
    {
        currentFile: record,
        url: '/v1/customers/me/immunization-doc/upload/'
    }
);

export const finishVetInfoStep = (vetInfoData) => {
    return (dispatch, getState) => {
        console.log('VET INFO ', vetInfoData);
        dispatch(setVetInfo(vetInfoData));
        localStorage.setItem('vetInfoForm', JSON.stringify(vetInfoData));

        // if we get 'send_dogs_records_later' unmarked, same as atleast one file attached
        const isSendingDocsLater = vetInfoData.get('records') === null;
        const canContactVeterinarian = vetInfoData.get('can_contact_veterinarian') === 'Yes';
        const registrationStep = getState().getIn(['steps', 'registration_step']);
        if (!isSendingDocsLater && !canContactVeterinarian) {
            dispatch(startVetRecordsLoading());
            const records = getState().getIn(['form', 'vetInfoForm', 'values', 'records']);
            const recordsRequest = records.map((record) => {
                return sendVeterinarianRecord(record);
            });

            return Promise.all(recordsRequest).then(() => {
                dispatch(finishVetRecordsLoading());
                dispatch(updateCustomerInfo(getState().get('veterinarian'), registrationStep === 2));
                dispatch(updateDogsVeterinarian(vetInfoData.toJS()));
            });
        }
        // updating customer info with veterinarian info
        return dispatch(updateCustomerInfo(getState().get('veterinarian'), registrationStep === 2))
            .then(() => {
                dispatch(updateDogsVeterinarian(vetInfoData.toJS()));
                const vetObj = vetInfoData.get('veterinarian');
                window._dcq.push(['identify', {
                    veterinarian: vetObj.label,
                }]);
            });
    };
};
