import React from 'react';
import { Field } from 'redux-form/immutable';
import PropTypes from 'prop-types';

const propTypes = {
    input: PropTypes.shape({}),
    fieldConfig: PropTypes.shape({}),
};

const HiddenTextInput = ({ input }) => {
    return (
        <input
            {...input}
            type='text'
            hidden/>
    );
};

HiddenTextInput.propTypes = propTypes;

const HiddenTextInputField = ({ fieldConfig }) => {
    return (
        <Field
            {...fieldConfig}
            component={HiddenTextInput}/>
    );
};

HiddenTextInputField.propTypes = {
    fieldConfig: PropTypes.shape({}),
};

export default HiddenTextInputField;
