import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as DogsService from '../../../../../services/dogs';
import AddNewItem from '../../../../../components/common/AddNewItem';
import NoData from '../../../../../components/common/NoData';
import Button from '../../../../../components/common/Button';
import DogsCard from './DogsCard/';
import AjaxLoader from '../../../../common/AjaxLoader/';
import * as OpenActions from '../../../../../actions/modal/openActions';
import { getAccessLevelOptions } from '../../../../../actions/miscActions';

class DogsPage extends React.PureComponent {
    static propTypes = {
        getDogsList: PropTypes.func,
        dogs: PropTypes.arrayOf(PropTypes.shape({})),
        accessLevelList: PropTypes.arrayOf(PropTypes.shape({})),
        isLoading: PropTypes.bool,
        openCustomerAddNewDogModalDialog: PropTypes.func,
        handleGetAccessLevelOptions: PropTypes.func,
    }

    componentDidMount() {
        const { getDogsList, dogs, handleGetAccessLevelOptions, accessLevelList } = this.props;

        if (!dogs.length) {
            getDogsList('?is_deceased=false&is_banned=false');
        }

        if (!accessLevelList.length) {
            handleGetAccessLevelOptions();
        }
    }

    renderDogsCardList() {
        const { openCustomerAddNewDogModalDialog } = this.props;
        if (!this.props.dogs.length) {
            return (
                <NoData
                    iconType='thinking'
                    title='There are no pups on your account!'
                    subtitle='Create your dog’s profile so you can start booking classes.'
                    action={<Button text='Add dog' onClickHandler={openCustomerAddNewDogModalDialog}/>}/>
            );
        }
        const dogs = this.props.dogs.reverse();

        return (
            <div className='customer-dogs__list'>
                { dogs.map((dog, index) => {
                    return <DogsCard data={dog} key={`dog_${index}`}/>;
                }) }
            </div>
        );
    }

    render() {
        const { isLoading, openCustomerAddNewDogModalDialog, accessLevelList } = this.props;

        return (
            <div className='inner-page customer-dogs'>
                <div className='inner-page__container'>
                    <div className='inner-page__title'>My dogs</div>
                    <div className='customer-dogs__content'>
                        <div className='customer-dogs__add'>
                            <AddNewItem
                                className='customer-dogs__add-button'
                                text='Add Dog'
                                onClickHandler={() => {
                                    openCustomerAddNewDogModalDialog();
                                }}/>
                        </div>
                        { isLoading || !accessLevelList.length ? <AjaxLoader /> : this.renderDogsCardList()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        dogs: state.getIn(['dogsList', 'dogs']).toJS(),
        isLoading: state.getIn(['dogsList', 'isLoading']),
        accessLevelList: state.getIn(['misc', 'options', 'accessLevel']).toJS(),
    };
};

const mapDispatchToProps = dispatch => ({
    getDogsList: bindActionCreators(DogsService.getDogs, dispatch),
    openCustomerAddNewDogModalDialog: bindActionCreators(OpenActions.openCustomerAddNewDogModalDialog, dispatch),
    handleGetAccessLevelOptions: bindActionCreators(getAccessLevelOptions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DogsPage);
