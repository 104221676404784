import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import LocationChangeStep from '../LocationChangeStep';
import { updateCustomerLocationData } from '../../../../../services/customers';

const DropoffLocationsChange = ({ 
    addSubmitData, goToStep, currentDropoff, 
    updateCustomerLocationData, ...formProps 
}) => {
    return (
        <LocationChangeStep 
            {...formProps}
            initialLocation={currentDropoff}
            target='Dropoff'
            onSubmit={(data) => {
                const { location, default_dropoff: defaultDropoff } = data.toJS();
                if (defaultDropoff === true) {
                    updateCustomerLocationData({
                        drop_off_location: +location
                    });
                }
                addSubmitData({ name: 'dropoff', data: +location });
                goToStep(4);
        }} />
    );
};

DropoffLocationsChange.propTypes = {
    addSubmitData: PropTypes.func,
    updateCustomerLocationData: PropTypes.func,
    goToStep: PropTypes.func,
    currentDropoff: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
    const { formData, submitData } = ownProps;
    let currentDropoff = formData.addresses[0].id.toString();
    if (!isEmpty(submitData) && 'dropoff' in submitData) {
        currentDropoff = submitData.dropoff.toString();
    }
    return {
        currentDropoff
    };
};

const mapDispatchToProps = {
    updateCustomerLocationData
};

export default connect(mapStateToProps, mapDispatchToProps)(DropoffLocationsChange);
