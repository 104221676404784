import PropTypes from 'prop-types';
import React from 'react';
import Stepper from '../Stepper/Stepper';

const StepName = ({ className, numberText, nameText, passedSteps }) => {
    return (
        <section className={className}>
            <div className='step-name__text'>
                <span className='step-name__test-number'>{numberText}</span>
                <span className='step-name__test-name'>{nameText}</span>
            </div>
            <Stepper passedSteps={passedSteps}/>
        </section>
    );
};

StepName.propTypes = {
    className: PropTypes.string.isRequired,
    numberText: PropTypes.string.isRequired,
    nameText: PropTypes.string.isRequired,
    passedSteps: PropTypes.arrayOf(PropTypes.number)
};

export default StepName;
