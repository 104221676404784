/* eslint-disable react/prop-types */
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { dataStates } from '../../../../../helpers/state';
import Loader from '../../../../common/Loader';
import AttendanceByZone from '../CustomReports/AttendanceByZone';
import Commission from '../CustomReports/Commission';
import CustomerStatsAndDemographics from '../CustomReports/CustomerStatsAndDemographics';
import DetailedSales from '../CustomReports/DetailedSales/DetailedSales';
import DogAttendance from '../CustomReports/DogAttendance';
import EmailsDelivery from '../CustomReports/EmailsDelivery';
import ExpiredVaccination from '../CustomReports/ExpiredVaccination';
import Incident from '../CustomReports/Incident';
import NewCustomersStatistics from '../CustomReports/NewCustomerStatistics';
import PackagesPrograms from '../CustomReports/PackagesPrograms';
import PickupDropoffs from '../CustomReports/PickupDropoffs';
import RebookingPercentage from '../CustomReports/RebookingPercentage';
import TrainersCommission from '../CustomReports/TrainersCommission';
import TrainersInvoice from '../CustomReports/TrainersInvoice';
import VipCustomers from '../CustomReports/VipCustomers';
import VipDogs from '../CustomReports/VipDogs';
import ExportReportTo from '../ExportReportTo';
import SaveAsCampaignTarget from '../SaveAsCampaignTarget';
import ReportsFilter from './ReportsFilter';

const getContentRenderer = reportType => {
    const reportsList = {
        'pickup-drop-off': PickupDropoffs,
        'vip-customers': VipCustomers,
        'vip-dogs': VipDogs,
        'trainers-commission': TrainersCommission,
        'trainers-invoice': TrainersInvoice,
        'detailed-sales': DetailedSales,
        'new_customer_statistics': NewCustomersStatistics,
        'attendance-by-zone': AttendanceByZone,
        'rebooking-percentage': RebookingPercentage,
        'dog_attendance_statistics': DogAttendance,
        'incident': Incident,
        'expired-vaccination': ExpiredVaccination,
        'packages-programs': PackagesPrograms,
        'customer-stats-and-demographics': CustomerStatsAndDemographics,
        'emails-report': EmailsDelivery,
        'commission': Commission,
    };
    return reportsList[reportType] || null;
};
const renderLoader = (text) => {
    return (<div className='loader__content loader__content_custom-reports'>
        <Loader
            isVisible
            loadingText={text}
            colorClassName='icon_messages-loader-black'
            modifierClassName='icon_messages-loader'/>
    </div>);
};

class CustomReportsPage extends React.PureComponent {
    static propTypes = {
        customReports: PropTypes.shape({}).isRequired,
        exportReportTo: PropTypes.func.isRequired,
        customReportsFilter: PropTypes.shape({}),
    };

    shouldComponentUpdate(nextProps) {
        const { customReports } = this.props;
        return !isEqual(this.props, nextProps) ||
            (customReports.get('dataState') === dataStates.loading
                && customReports.get('dataState') !== nextProps.customReports.get('dataState'));
    }

    render() {
        const { customReports, openSaveAsCampaignModalDialog, exportReportTo, customReportsFilter } = this.props;
        const { items, dataState, filter } = customReports.toJS();
        const { report } = filter;
        const isLoading = dataState === dataStates.loading;
        const Renderer = report ? getContentRenderer(report) : null;
        const isSavableAsCampaign =
            report !== 'dog_attendance_statistics' &&
            report !== 'customer-stats-and-demographics' &&
            report !== 'attendance-by-zone' &&
            report !== 'detailed-sales' &&
            report !== 'trainers-commission' &&
            report !== 'trainers-invoice' &&
            report !== 'marketing-emails-report' &&
            report !== 'commission' &&
            report !== 'emails-report';
        const isNotEmpty = items &&
            (items.results
                ? !!items.results.length
                : items.details
                 ? !!items.details.length
                 : items.detail
                      ? !!items.detail.length
                      : items.columns
                       ? !!items.columns.length
                       : items.top_10_breeds_total
                            ? items.top_10_breeds_total.length
                            : (items.by_class_name && items.by_trainer && items.by_weekday)
                             ? true
                             : items.summary_by_value
                                  ? items.summary_by_value.length
                                  : !!items.length);
                                  
        return (
            <div className='custom-reports'>
                <ReportsFilter/>
                {isLoading && renderLoader('Loading Report Data')}
                {(!!Renderer && !isLoading && isNotEmpty) &&
                <div className='custom-reports__features'>
                    <ExportReportTo
                        exportReportTo={exportReportTo}
                        filterValues={customReportsFilter.customReportFilterForm.values}/>
                    {isSavableAsCampaign && <SaveAsCampaignTarget
                        openSaveAsCampaignModalDialog={openSaveAsCampaignModalDialog}
                        params={filter}/>
                    }
                </div>
                }
                {(!!Renderer && !isLoading) && <Renderer items={items} dataState={dataState}/>}
            </div>
        );
    }
}

export default CustomReportsPage;
