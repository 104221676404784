import classNames from 'classnames';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { finishHealthStep, setDefaultHealthData, setIntroductionInfo } from '../../../../../actions/onboarding/dogCreationActions';
import { completeHealthStep, skipAllButFirstSteps, setCurrentRegistrationStep } from '../../../../../actions/onboarding/onBoardingStepsActions';
import renderInputControl from '../../../../common/Form/InputControl';
import { renderWarning } from '../../../../common/Form/RadioControl/RadioControl';
import renderSelectControl from '../../../../common/Form/SelectControl';
import validate from './validate';
import warn from './warn';

class HealthForm extends React.PureComponent {
    static propTypes = {
        registrationStep: PropTypes.number,
        updateRegistrationStep: PropTypes.func,
        handleSubmit: PropTypes.func.isRequired,
        onSubmit: PropTypes.func,
        submitting: PropTypes.bool,
        invalid: PropTypes.bool,
        handleDefaultValues: PropTypes.func,
        isIntoleranced: PropTypes.string,
        isInjured: PropTypes.string,
        error: PropTypes.string,
        setIntroductionInfo: PropTypes.func
    };

    componentDidMount() {
        const { handleDefaultValues, registrationStep, updateRegistrationStep } = this.props;
        const savedRegistrationStep = +localStorage.getItem('registration_step');
        if (savedRegistrationStep && !registrationStep) {
            updateRegistrationStep(savedRegistrationStep);
        }
        handleDefaultValues();
    }

    render() {
        // eslint-disable-next-line max-len
        const { handleSubmit, submitting, invalid, onSubmit, isIntoleranced, isInjured, registrationStep, error, setIntroductionInfo } = this.props;
        const SubmitButtonClassNames = classNames({
            'health__submit-button': true,
            'health__submit-button_disabled': (invalid || submitting || !registrationStep)
        });
        return (
            <form onSubmit={handleSubmit} className='health__form'>
                <div className='health__form-content'>
                    <div className='form__select-aligner'>
                        <div className='radio-control'>
                            <div className='radio-control__description'>
                                <label className='radio-control__description-label'>
                                    Does your dog have any allergies?
                                </label>
                                <Field name='is_intoleranced' component={renderWarning}/>
                            </div>
                            <div className='radio-control__controls'>
                                <label className='radio-control__controls-label'>
                                    <Field
                                        name='is_intoleranced'
                                        component='input'
                                        type='radio'
                                        value='Yes'
                                        className='radio-control__controls-input'/>
                                    <span className='radio-control__controls-text'>Yes</span>
                                </label>
                                <label className='radio-control__controls-label'>
                                    <Field
                                        name='is_intoleranced'
                                        component='input'
                                        type='radio'
                                        value='No'
                                        className='radio-control__controls-input'/>
                                    <span className='radio-control__controls-text'>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    {
                        isIntoleranced === 'Yes' &&
                        <Field
                            name='intolerance_list'
                            type='text'
                            component={renderSelectControl}
                            multi
                            creatable
                            label='Please list all allergies.'/>
                    }
                    <div className='form__select-aligner'>
                        <div className='radio-control'>
                            <div className='radio-control__description'>
                                <label className='radio-control__description-label'>
                                    Does your dog have any injuries or medical conditions?
                                </label>
                                <Field name='is_injured' component={renderWarning}/>
                            </div>
                            <div className='radio-control__controls'>
                                <label className='radio-control__controls-label'>
                                    <Field
                                        name='is_injured'
                                        component='input'
                                        type='radio'
                                        value='Yes'
                                        className='radio-control__controls-input'/>
                                    <span className='radio-control__controls-text'>Yes</span>
                                </label>
                                <label className='radio-control__controls-label'>
                                    <Field
                                        name='is_injured'
                                        component='input'
                                        type='radio'
                                        value='No'
                                        className='radio-control__controls-input'/>
                                    <span className='radio-control__controls-text'>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    {
                        isInjured === 'Yes' &&
                        <Field
                            name='medical_notes'
                            type='text'
                            isTextarea
                            component={renderInputControl}
                            label='Please explain.'/>
                    }
                    {error && <div className='form__error'>{error}</div>}
                </div>
                <div className='health__form-buttons'>
                    <button
                        className='health__submit-button health__submit-button_add-dog'
                        type='submit'
                        disabled={submitting}
                        onClick={handleSubmit(values => {
                            onSubmit(
                                values
                                    .set('intolerance_list', values.get('intolerance_list'))
                                    .set('medical_notes', values.get('medical_notes'))
                                    .set('actionCreator', skipAllButFirstSteps)
                                    .set('redirectPath', '/introduction')
                            );
                            setIntroductionInfo(undefined);
                        })}>
                        <span className='add-dog__icon'/>
                        <span className='add-dog__text'>Add another Dog</span>
                    </button>
                    <button
                        className={SubmitButtonClassNames}
                        type='submit'
                        disabled={submitting || !registrationStep}
                        onClick={handleSubmit(values => {
                            values = values
                                .set('intolerance_list', values.get('intolerance_list'))
                                .set('medical_notes', values.get('medical_notes'))
                                .set('redirectPath', '/vet-info');

                            // dispatch next step only once
                            if (registrationStep === 1) {
                                values = values.set('actionCreator', completeHealthStep);
                            }
                            onSubmit(values);
                        })}>
                        Next - Health Information
                    </button>
                </div>
            </form>
        );
    }
}

const form = reduxForm({
    form: 'dogHealthForm',
    destroyOnUnmount: false,
    // enableReinitialize: true,
    onSubmit: finishHealthStep,
    validate,
    warn
});

const mapStateToProps = state => ({
    isInjured: state.getIn(['form', 'dogHealthForm', 'values', 'is_injured']),
    isIntoleranced: state.getIn(['form', 'dogHealthForm', 'values', 'is_intoleranced']),
    initialValues: localStorage.getItem('healthForm') ? JSON.parse(localStorage.getItem('healthForm')) : fromJS({ is_intoleranced: 'No', is_injured: 'No' }),
    registrationStep: state.getIn(['steps', 'registration_step'])
});

const mapDispatchToProps = (dispatch) => ({
    handleDefaultValues: bindActionCreators(setDefaultHealthData, dispatch),
    updateRegistrationStep: bindActionCreators(setCurrentRegistrationStep, dispatch),
    setIntroductionInfo: bindActionCreators(setIntroductionInfo, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(form(HealthForm));
