import { updateInfo } from '../../services/update';
import { fromJS } from '../../../node_modules/immutable/dist/immutable';
import { setCurrentCustomerData, setCurrentDogData } from './employeeActions';
import { getUserNameSpace } from '../../helpers/userRoles';

const updateCurrentContextData = ({ context, contextId, data }) => {
    return dispatch => {
        dispatch(updateInfo(
            {
                url: `/v1/${getUserNameSpace(context)}/${context}/${contextId}/`,
                infoChunks: data,
                context,
                actionCB: (dispatch, response) => {
                    if (context === 'customers') {
                        dispatch(setCurrentCustomerData(fromJS(response.data)));
                    } else {
                        dispatch(setCurrentDogData(fromJS(response.data)));
                    }
                }
            }
        ));
    };
};

export const toggleVipCurrentCustomer = () => {
    return (dispatch, getState) => {
        const customerId = getState().getIn(['currentCustomer', 'instance', 'id']);
        const isVip = getState().getIn(['currentCustomer', 'instance', 'is_vip']);
        const data = [
            {
                name: 'is_vip',
                content: !(isVip === 'Yes'),
            }
        ];
        dispatch(updateCurrentContextData({ context: 'customers', contextId: customerId, data }));
    };
};

export const toggleBannedCurrentCustomer = () => {
    return (dispatch, getState) => {
        const customerId = getState().getIn(['currentCustomer', 'instance', 'id']);
        const isBanned = getState().getIn(['currentCustomer', 'instance', 'is_banned']) === 'Yes';
        const data = [
            {
                name: 'is_banned',
                content: !isBanned,
            }
        ];
        dispatch(updateCurrentContextData({ context: 'customers', contextId: customerId, data }));
    };
};

export const toggleBannedCurrentDog = () => {
    return (dispatch, getState) => {
        const dogId = getState().getIn(['currentDog', 'instance', 'id']);
        const isBanned = getState().getIn(['currentDog', 'instance', 'is_banned']);
        const data = [
            {
                name: 'is_banned',
                content: !isBanned,
            }
        ];
        dispatch(updateCurrentContextData({ context: 'dogs', contextId: dogId, data }));
    };
};
