import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';

const SidebarIconLink = ({ activeClassName, item, className, onMouseEnterFunction, onMouseLeaveFunction }) => {
    const { icon: Icon } = item;
    return (
        <Link
            activeClassName={activeClassName}
            onlyActiveOnIndex
            className={'sidebar__icon ' + className}
            onMouseEnter={() => { onMouseEnterFunction(); }}
            onMouseLeave={() => { onMouseLeaveFunction(); }}
            to={item.to}>
            <Icon className={item.iconClass} />
        </Link>
    );
};

SidebarIconLink.propTypes = {
    activeClassName: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    item: PropTypes.shape({
        to: PropTypes.string.isRequired,
        iconClass: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired
    }),
    onMouseEnterFunction: PropTypes.func,
    onMouseLeaveFunction: PropTypes.func,
};

export default SidebarIconLink;
