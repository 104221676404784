import { push } from 'react-router-redux';
import { createAction } from 'redux-actions';
import { reset } from 'redux-form';
import { fromJS } from 'immutable';
import { SubmissionError } from 'redux-form/immutable';
import { getAgreements } from '../../actions/AgreementsActions';
import * as CustomersServices from '../../services/customers';
import * as ProfileServices from '../../services/profile';
import * as EmployeeTypes from '../../types/dashboard/employeeTypes';
import * as CustomerTypes from '../../types/customer/customerTypes';
import { setCurrentCustomerData } from '../dashboard/employeeActions';
import { postAvailableAddressAsync, patchCustomerAvailableAddress } from '../../services/availableAddresses';
import { setUserProfileInfo } from '../onboarding/userActions';
import { setCustomerContactsInfoClosed } from '../../actions/miscActions';
import { baseResetPassword } from '../../services/resetPassword';


export const updateAdditionalOwner = createAction(CustomerTypes.UPDATE_ADDITIONAL_OWNER);
export const updateEmergencyContact = createAction(CustomerTypes.UPDATE_EMERGENCY_CONTACT);
export const addAdditionalOwner = createAction(CustomerTypes.ADD_ADDITIONAL_OWNER);
export const addEmergencyContact = createAction(CustomerTypes.ADD_EMERGENCY_CONTACT);
export const deleteAdditionalOwner = createAction(CustomerTypes.DELETE_ADDITIONAL_OWNER);
export const deleteEmergencyContact = createAction(CustomerTypes.DELETE_EMERGENCY_CONTACT);
export const verifyPrimaryPhone = createAction(CustomerTypes.VERIFY_PRIMARY_PHONE);
export const saveSignatureOwner = createAction(CustomerTypes.SAVE_SIGNATURE_OWNER);
export const saveDataStamp = createAction(CustomerTypes.SAVE_DATA_STAMP);
export const changeSignatureStatus = createAction(CustomerTypes.SIGNATURE_ADDING_STATUS);


export const getCustomerData = () => {
    return async (dispatch) => {
        let hasAgreements = false;
        let shouldWelcomeShowed = false;
        let formId = false;
        const agreements = dispatch(getAgreements())
            .then(response => {
                if (response.data.count > 0) {
                    hasAgreements = true;
                    formId = response.data.results[0].id;
                }
            });
        const customer = dispatch(CustomersServices.getCurrentCustomer())
            .then(response => {
                if (!response.is_welcome_showed) shouldWelcomeShowed = true;
            });
        await Promise.all([agreements, customer])
            .then(() => {
                if (hasAgreements) {
                    dispatch(push(`/form-update/${formId}/`));
                } else if (shouldWelcomeShowed) {
                    dispatch(push('/welcome/'));
                }
            });
    };
};

export const setDefaultPickupAddress = createAction(EmployeeTypes.SET_DEFAULT_PICKUP_ADDRESS);
export const setDefaultDropoffAddress = createAction(EmployeeTypes.SET_DEFAULT_DROPOFF_ADDRESS);

export const setAsDefaultPickupAddress = (id) => {
    return dispatch => {
        dispatch(setDefaultPickupAddress(id));
        dispatch(CustomersServices.updateCustomerDefaultPickupAddress(id));
    };
};

export const setAsDefaultDropoffAddress = (id) => {
    return dispatch => {
        dispatch(setDefaultDropoffAddress(id));
        dispatch(CustomersServices.updateCustomerDefaultDropoffAddress(id));
    };
};

export const editCustomerInfo = (data) => {
    return (dispatch, getState) => {
        const customer = getState().getIn(['currentCustomer', 'instance']).toJS();
        let updatePrimaryPhone;
        let updateSecondaryPhone;
        let updateHomeAddress;

        // primary phone
        let primaryPhoneId = customer.primaryPhoneId;
        const currentPrimaryPhone = customer.primary_phone === 'None' ? '' : customer.primary_phone;
        const isNewPrimaryPhone = data.primary_phone !== currentPrimaryPhone;
        if ((!customer.primaryPhoneId || isNewPrimaryPhone) && data.primary_phone) {
            updatePrimaryPhone = CustomersServices.addCustomerPhone({
                phone_number: data.primary_phone
            }).then((response) => {
                primaryPhoneId = response.data.id;
            }).catch(error => {
                console.log(error.response);
            });
        } else if (!data.primary_phone) {
            primaryPhoneId = null;
        }

        // secondary phone
        let secondaryPhoneId = customer.secondaryPhoneId;
        const currentSecondaryPhone = customer.secondary_phone === 'None' ? '' : customer.secondary_phone;
        const isNewSecondaryPhone = data.secondary_phone !== currentSecondaryPhone;
        if (isNewSecondaryPhone && data.secondary_phone) {
            updateSecondaryPhone = CustomersServices.addCustomerPhone({
                phone_number: data.secondary_phone
            }).then((response) => {
                secondaryPhoneId = response.data.id;
            }).catch(err => {
                console.log(err.response);
            });
        } else if (!data.secondary_phone) {
            secondaryPhoneId = null;
        }

        // home address
        let homeAddresId = customer.addressId;
        if (data.address1) {
            const addressData = {
                'address1': data.address1,
                'address2': data.address2 || null,
                'city': data.city,
                'state': data.state.value || data.state,
                'zip_code': data.zip_code,
                'name': 'Home',
                'id': homeAddresId,
                'location_notes': data.location_notes || null
            };
            if (customer.addressId) {
                updateHomeAddress = dispatch(patchCustomerAvailableAddress(addressData, customer.addressId));
            } else {
                updateHomeAddress = dispatch(postAvailableAddressAsync(null, addressData)).then(response => {
                    homeAddresId = response.data.id;
                });
            }
        }

        return Promise.all([updatePrimaryPhone, updateSecondaryPhone, updateHomeAddress])
            .then(() => {
                const formData = {
                    full_name: data.full_name,
                    primary_phone: primaryPhoneId,
                    secondary_phone: secondaryPhoneId,
                };
                if (homeAddresId) {
                    formData.address = homeAddresId;
                }

                if (data.new_email && data.current_password) {
                    formData.email = data.new_email;
                    formData.current_password = data.current_password;
                }

                // if customer does not have usable password (social login)
                if (data.new_email && !customer.has_usable_password) {
                    formData.email = data.new_email;
                }

                return new Promise((resolve, reject) => {
                    dispatch(CustomersServices.changeCustomerData(formData))
                        .then(response => {
                            console.log(response);
                            dispatch(setCurrentCustomerData(fromJS(response.data)));
                            dispatch(setUserProfileInfo(response.data));
                            dispatch(setCustomerContactsInfoClosed());
                            resolve();
                        })
                        .catch(err => {
                            if (err.response.data.email) {
                                reject(new SubmissionError({
                                    new_email: err.response.data.email
                                }));
                            }
                            if (err.response.data.current_password) {
                                reject(new SubmissionError({
                                    current_password: err.response.data.current_password
                                }));
                            }
                        });
                });
            });
    };
};

export const changeCustomersPassword = (values) => {
    return async (dispatch) => {
        await dispatch(ProfileServices.changePassword(values))
            .then(() => {
                dispatch(reset('ChangeCustomersPasswordForm'));
            })
            .catch((error) => {
                console.log(error);

                if (error.response && error.response.data) {
                    if (error.response.data.old_password) {
                        throw new SubmissionError({
                            old_password: error.response.data.old_password[0]
                        });
                    }
                    if (error.response.data.password) {
                        throw new SubmissionError({
                            password: error.response.data.password[0]
                        });
                    }
                }
            });
    };
};


export const resetCustomerPassword = (request) => {
    return async (dispatch) => {
        await dispatch(baseResetPassword(request));
    };
};
