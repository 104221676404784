import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { closeEditCustomerContactsInfoModalDialog } from '../../../../actions/miscActions';
import { editCustomerInfo } from '../../../../actions/customer/CustomerActions';
import ModalDialog from '../../../common/ModalDialog';
import EditCustomerContactInfoForm from './EditCustomerContactInfoForm';
import { handleAutocompleteSelectOrEnter } from '../../../../actions/autocompleteActions';

const EditCustomerContactInfo = (props) => {
    const {
        isEditCustomerContactInfoModalOpened,
        closeEditCustomerContactsInfoModalDialog,
        handleSubmit,
        handleAutocompleteSelectOrEnter,
        isCurrentCustomerLoaded
    } = props;

    return (
        isCurrentCustomerLoaded !== undefined
            ? <ModalDialog
                form={
                    <EditCustomerContactInfoForm
                        onSubmit={data => handleSubmit(data.toJS())}
                        onClose={closeEditCustomerContactsInfoModalDialog}
                        handleAutocompleteSelectOrEnter={handleAutocompleteSelectOrEnter} />
                }
                title='Contact info'
                isModalOpened={isEditCustomerContactInfoModalOpened}
                className='modal-dialog_side-bar'
                handleModalClosed={closeEditCustomerContactsInfoModalDialog} />
            : null
    );
};

EditCustomerContactInfo.propTypes = {
    isEditCustomerContactInfoModalOpened: PropTypes.bool,
    isCurrentCustomerLoaded: PropTypes.number,
    closeEditCustomerContactsInfoModalDialog: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleAutocompleteSelectOrEnter: PropTypes.func,
};

const mapStateToProps = state => ({
    isEditCustomerContactInfoModalOpened: state.getIn(['misc', 'modals', 'editCustomerContactInfo']),
    isCurrentCustomerLoaded: state.getIn(['currentCustomer', 'instance', 'id'])
});

const mapDispatchToProps = {
    closeEditCustomerContactsInfoModalDialog,
    handleAutocompleteSelectOrEnter,
    handleSubmit: editCustomerInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomerContactInfo);
