import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import FeaturedSlider from '../FeaturedSlider';
import { getClasses } from '../../../../../services/classesSchedule';
import { getDogs} from '../../../../../services/dogs';

class MainPage extends React.PureComponent {

    static propTypes = {
        dogsList: PropTypes.shape({}),
        getDogsList: PropTypes.func,
        handleGettingClasses: PropTypes.func,
        classesList: PropTypes.shape({}),
    }

    componentDidMount() {
        const {
            classesList,
            getDogsList,
            handleGettingClasses,
        } = this.props;

        if (!classesList.get('classes').size) {
            handleGettingClasses('is_featured=true');
        }

        getDogsList('?is_deceased=false&is_banned=false');
    }

    render() {
        const { classesList, dogsList } = this.props;
        const items = classesList.toJS().classes.map((item) => {
            return {
                photo: item.photo || null,
                photo_processed_large: item.photo_processed_large,
                name: item.name
            };
        });

        let hasDogs = false;
        let snapshotUrl = null;
        if (dogsList.length > 0) {
            hasDogs = true;
            const firstDogId = dogsList.reverse()[0].id;
            snapshotUrl = `/dogs/${firstDogId}`
        }

        return (
            <div className='main-page'>
                <div className='main-page__featured-slider'>
                    {<FeaturedSlider items={items}/>}
                </div>
                <div className='main-page__info-list info-list'>
                    <div className='info-block main-page__info-block'>
                        <div className='info-block__content'>
                            <div className='info-block__title'>Classes</div>
                            <div className='info-block__description'>We pick up and drop off at home, work, or daycare. Classes are led by one of experienced leaders or certified trainers at vetted locations throughout Los Angeles, like Centinela Pet Feed.</div>
                        </div>
                        <Link to='/classes' className='info-block__button'>Book Classes</Link>
                    </div>
                    <div className='info-block main-page__info-block'>
                        <div className='info-block__content'>
                            <div className='info-block__title'>Snapshots</div>
                            <div className='info-block__description'>After every class, you will get a “snapshot” of your dog’s activity. Snapshots include funs stats about your dog as well as 2 to 5 photos of your dog in action.</div>
                        </div>
                        <Link
                            to={snapshotUrl}
                            className={classNames({
                                'info-block__button': hasDogs,
                                'info-block__button--disabled': !hasDogs,
                            })}
                        >
                            View Snapshots
                        </Link>
                    </div>
                    <div className='info-block main-page__info-block'>
                        <div className='info-block__content'>
                            <div className='info-block__title'>Packages</div>
                            <div className='info-block__description'>We offer a variety of packages so you can save money and mix and match classes. Packages can be used across multiple dogs. </div>
                        </div>
                        <Link to='/packages' className='info-block__button'>Buy Packages</Link>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        classesList: state.getIn(['classesList']),
        dogsList: state.getIn(['dogsList', 'dogs']).toJS(),
    };
};

const mapDispatchToProps = dispatch => ({
    getDogsList: bindActionCreators(getDogs, dispatch),
    handleGettingClasses: bindActionCreators(getClasses, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
