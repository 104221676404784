import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateCustomerName } from '../../../../actions/dashboard/changeNameActions';
import { setChangeNameModalClosed } from '../../../../actions/miscActions';
import ModalDialog from '../../../common/ModalDialog';
import ChangeNameModalDialogForm from './ChangeNameModalDialogForm';

// eslint-disable-next-line max-len
const ChangeNameModalDialog = ({ isModalOpened, handleModalClosed, handleSubmit, customersName }) => {
    return (
        <ModalDialog
            form={
                <ChangeNameModalDialogForm
                    onSubmit={data => handleSubmit(data.get('id'), fromJS({ full_name: data.get('full_name') }))}/>
            }
            title={`Edit ${customersName}'s Name`}
            isModalOpened={isModalOpened}
            handleModalClosed={handleModalClosed}/>
    );
};

ChangeNameModalDialog.propTypes = {
    isModalOpened: PropTypes.bool,
    handleModalClosed: PropTypes.func,
    handleSubmit: PropTypes.func,
    customersName: PropTypes.string
};

const mapStateToProps = state => {
    return {
        customersName: state.getIn(['currentCustomer', 'instance', 'full_name']),
        isModalOpened: state.getIn(['misc', 'modals', 'changeName'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleModalClosed: bindActionCreators(setChangeNameModalClosed, dispatch),
    handleSubmit: bindActionCreators(updateCustomerName, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeNameModalDialog);
