/* eslint-disable camelcase,prefer-const */
import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as AgreementsTypes from '../types/AgreementsTypes';
import * as ActionTypes from '../types/onboarding/userTypes';

const INITIAL_STATE = fromJS({
    id: null,
    full_name: '',
    email: '',
    emergency_contact: {},
    photo: '',
    signUpData: {},
    last_login: null,
    available_phones: [],
    shouldWelcomeShowed: false,
    agreements: {
        areLoaded: false,
        items: [],
    },
    permission_level: null,
});

const userReducer = handleActions({
    [ActionTypes.SET_USER_FORGOTTEN_INFO]: (state, action) => {
        const updatedState = state.update((state) => {
            return state
                .set('email', action.payload);
        });
        return updatedState;
    },
    [ActionTypes.SET_USER_INFO]: (state, action) => {
        let updatedState;
        if (action.payload.phone) {
            updatedState = state.update((state) => {
                return state
                    .set('full_name', action.payload.full_name)
                    .set('email', action.payload.email)
                    .set('phone', action.payload.phone.replace(/[^\w\s]/gi, ''));
            });
        } else {
            updatedState = state.update((state) => {
                return state
                    .set('full_name', action.payload.full_name)
                    .set('email', action.payload.email);
            });
        }
        return updatedState;
    },
    [ActionTypes.SET_USER_FB_INFO]: (state, action) => {
        // console.log('SET_USER_FB_INFO');
        // console.log('action.payload: ', action.payload);
        return state.update((state) => {
            if (!action.payload.email && !action.payload.phone) {
                return state
                    .set('full_name', action.payload.name)
                    .set('signUpData', fromJS({
                        token: action.payload.accessToken,
                        socialName: 'fb',
                    }));
            }
            if (!action.payload.email) {
                return state
                    .set('full_name', action.payload.name)
                    .set('phone', action.payload.phone.replace(/[^\w\s]/gi, ''))
                    .set('signUpData', fromJS({
                        token: action.payload.accessToken,
                        socialName: 'fb',
                    }));
            }
            if (!action.payload.phone) {
                return state
                    .set('full_name', action.payload.name)
                    .set('email', action.payload.email)
                    .set('signUpData', fromJS({
                        token: action.payload.accessToken,
                        socialName: 'fb',
                    }));
            }
            return state
                .set('signUpData', fromJS({}))
                .set('full_name', action.payload.name)
                .set('email', action.payload.email)
                .set('phone', action.payload.phone.replace(/[^\w\s]/gi, ''));
        });
    },
    [ActionTypes.SET_USER_GOOGLE_INFO]: (state, action) => {
        // console.log('SET_USER_GOOGLE_INFO');
        // console.log('action.payload: ', action.payload);
        return state.update((state) => {
            if (action.payload.profileObj !== undefined) {
                if (!action.payload.profileObj.email && !action.payload.profileObj.phone) {
                    return state
                        .set('full_name', action.payload.profileObj.name)
                        .set('signUpData', fromJS({
                            token: action.payload.accessToken,
                            socialName: 'google',
                        }));
                }
                if (!action.payload.profileObj.email) {
                    return state
                        .set('full_name', action.payload.profileObj.name)
                        .set('phone', action.payload.profileObj.phone.replace(/[^\w\s]/gi, ''))
                        .set('signUpData', fromJS({
                            token: action.payload.accessToken,
                            socialName: 'google',
                        }));
                }
                if (!action.payload.profileObj.phone) {
                    return state
                        .set('full_name', action.payload.profileObj.name)
                        .set('email', action.payload.profileObj.email)
                        .set('signUpData', fromJS({
                            token: action.payload.accessToken,
                            socialName: 'google',
                        }));
                }
                return state
                    .set('signUpData', fromJS({}))
                    .set('full_name', action.payload.profileObj.name)
                    .set('email', action.payload.profileObj.email)
                    .set('phone', action.payload.profileObj.phone.replace(/[^\w\s]/gi, ''));
            }
            if (!action.payload.email && !action.payload.phone) {
                return state
                    .set('full_name', action.payload.name)
                    .set('signUpData', fromJS({
                        token: action.payload.accessToken,
                        socialName: 'fb',
                    }));
            }
            if (!action.payload.email) {
                return state
                    .set('full_name', action.payload.name)
                    .set('phone', action.payload.phone.replace(/[^\w\s]/gi, ''))
                    .set('signUpData', fromJS({
                        token: action.payload.accessToken,
                        socialName: 'fb',
                    }));
            }
            if (!action.payload.phone) {
                return state
                    .set('full_name', action.payload.name)
                    .set('email', action.payload.email)
                    .set('signUpData', fromJS({
                        token: action.payload.accessToken,
                        socialName: 'fb',
                    }));
            }
            return state
                .set('signUpData', fromJS({}))
                .set('full_name', action.payload.name)
                .set('email', action.payload.email)
                .set('phone', action.payload.phone.replace(/[^\w\s]/gi, ''));
        });
    },
    [ActionTypes.SET_USER_PROFILE_INFO]: (state, action) => {
        let {
            id, full_name, email, primary_phone, secondary_phone,
            emergency_contact, photo_processed, last_login, is_welcome_showed,
            available_phones, permission_level,
        } = action.payload;
        // customer get primary_phone as id only, see loadProfileData
        if (typeof primary_phone === 'number') {
            primary_phone = available_phones.find((address) => {
                return address.id === primary_phone;
            });
        }
        // identify customer for Drip actions
        if (!permission_level) {
            window._dcq.push(["identify", {
                email,
                remove_tags: ['Lead'],
            }]);
        }
        return state
            .set('id', id)
            .set('full_name', full_name || '') // eslint-disable-line
            .set('email', email || '')
            .set('phoneId', fromJS(primary_phone) || null) // eslint-disable-line
            .set('available_phones', available_phones || []) // eslint-disable-line
            .set('secondary_phone', secondary_phone || null) // eslint-disable-line
            .set('emergency_contact', emergency_contact || null) // eslint-disable-line
            .set('photo', photo_processed || '') // eslint-disable-line
            .set('last_login', last_login || '') // eslint-disable-line
            .set('shouldWelcomeShowed', !is_welcome_showed)
            .set('permission_level', permission_level); // eslint-disable-line
    },
    [ActionTypes.SET_WELCOME_SHOWED]: (state, action) => {
        return state
            .set('shouldWelcomeShowed', false); // eslint-disable-line
    },
    [ActionTypes.SET_USER_PHOTO]: (state, action) => {
        const { photo } = action.payload;
        return state.set('photo', photo);
    },
    [AgreementsTypes.MARK_AGREEMENTS_LOADED]: (state) => {
        return state.setIn(['agreements', 'areLoaded'], true);
    },
    [AgreementsTypes.UNMARK_AGREEMENTS_LOADED]: (state) => {
        return state.setIn(['agreements', 'areLoaded'], false);
    },
    [AgreementsTypes.SET_AGREEMENTS]: (state, action) => {
        return state.update((state) => {
            return state.setIn(['agreements', 'items'], fromJS(action.payload));
        });
    },
    [AgreementsTypes.REMOVE_AGREEMENT]: (state, action) => {
        return state.update((state) => {
            return state.updateIn(['agreements', 'items'],
                items => items.filter(item => item.get('id') !== action.payload));
        });
    },
    [ActionTypes.VERIFY_PRIMARY_PHONE]: (state, action) => {
        const availablePhones = state.getIn(['available_phones']).map((phone) => {
            if (phone.id === action.payload) {
                phone.verified_at = true;
                return phone;
            }
            return phone;
        });

        return state.update((state) => {
            return state.setIn(['available_phones'], availablePhones);
        });
    },
}, INITIAL_STATE);

export default userReducer;
