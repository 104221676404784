import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import { connect } from 'react-redux';
import creditCardType from 'credit-card-type';
import { ReactComponent as IconTrash } from '../../../../../../assets/icons/icon-delete.svg';
import Icon from '../../../../../common/Icon';
import { ReactComponent as IconAmericanExpress } from '../../../../../../assets/icons/icon-american-express.svg';
import { ReactComponent as IconMasterCard } from '../../../../../../assets/icons/icon-master-card.svg';
import { ReactComponent as IconNeutralCard } from '../../../../../../assets/icons/icon-neutral-card.svg';
import { ReactComponent as IconVisa } from '../../../../../../assets/icons/icon-visa.svg';
import { openDeleteCustomersCreditCardModalDialog } from '../../../../../../actions/modal/openActions';
import { setDefaultCustomerPaymentCard } from '../../../../../../actions/dashboard/paymentCardsAction';

const PaymentItem = ({
    data,
    openDeleteCustomersCreditCardModalDialog,
    setDefaultCustomerPaymentCard
}) => {
    const statusClass = className({
        'payment-item__status': true,
        'payment-item__status_default': data.is_default
    });

    const CARD_ICONS = {
        'Visa': IconVisa,
        'MasterCard': IconMasterCard,
        'American Express': IconAmericanExpress,
    };

    const cardInfo = creditCardType(data.first6)[0];
    const cardType = cardInfo ? cardInfo.niceType : false;

    return (
        <div className='payment-methods__item payment-item'>
            <div className='payment-item__icon'>
                <Icon glyph={CARD_ICONS[cardType] || IconNeutralCard} className='payment-item__card-icon' />
            </div>
            <div className='payment-item__data'>
                <div className='payment-item__number'>XXXX XXXX XXXX {data.last4}</div>
                <div className='payment-item__date'>
                    {data.exp_month < 10 ? '0' + data.exp_month : data.exp_month}/{data.exp_year}
                </div>
                <div
                    className={statusClass}
                    onClick={() => {
                        if (!data.is_default) {
                            setDefaultCustomerPaymentCard(data.id);
                        }
                    }}>
                    { data.is_default ? 'Default Card' : 'Set as Default Card' }
                </div>
            </div>
            <div className='payment-item__actions change-actions'>
                <div
                    className='change-actions__item'
                    onClick={() => {
                        openDeleteCustomersCreditCardModalDialog(data.id);
                    }}>
                    <Icon glyph={IconTrash} className='change-actions__icon-delete' /> Delete
                </div>
            </div>
        </div>
    );
};

PaymentItem.propTypes = {
    data: PropTypes.shape({}),
    openDeleteCustomersCreditCardModalDialog: PropTypes.func,
    setDefaultCustomerPaymentCard: PropTypes.func,
};

const mapDispatchToProps = {
    openDeleteCustomersCreditCardModalDialog,
    setDefaultCustomerPaymentCard,
};

export default connect(null, mapDispatchToProps)(PaymentItem);
