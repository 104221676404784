import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as classesHistoryTypes from '../types/customer/classesHistoryTypes';
import { normalizeScheduleEvent } from '../helpers/normalizeScheduleEvent';

const INITIAL_STATE = fromJS({
    items: [],
    count: 0,
    pageSize: 0,
    pages: 0,
    nextUrl: null,
    previousUrl: null,
    isLoading: false,
    isNextLoading: false
});

const classesHistoryDataNormalizer = (data) => {
    return data.map((item) => {
        return normalizeScheduleEvent(item);
    });
};

const classesHistoryListReducer = handleActions({
    [classesHistoryTypes.SET_CLASSES_HISTORY]: (state, action) => {
        const { results, count, next, previous } = action.payload;
        const nextUrl = (next === null || next === undefined) ? next : next.slice(4);
        const previousUrl = (previous === null || previous === undefined) ? previous : previous.slice(4);
        const pagesCount = count === undefined ? count : Math.ceil(count / results.length);
        const data = results === undefined ? action.payload : results;

        return state.update((state) => {
            return state.setIn(['items'], fromJS(classesHistoryDataNormalizer(data)))
                .setIn(['count'], count)
                .setIn(['pageSize'], data.length)
                .setIn(['pages'], pagesCount)
                .setIn(['nextUrl'], nextUrl)
                .setIn(['previousUrl'], previousUrl);
        });
    },
    [classesHistoryTypes.SET_NEXT_CLASSES_HISTORY]: (state, action) => {
        const { results, count, next, previous } = action.payload;
        const nextUrl = (next === null || next === undefined) ? next : next.slice(4);
        const previousUrl = (previous === null || previous === undefined) ? previous : previous.slice(4);
        const pagesCount = count === undefined ? count : Math.ceil(count / results.length);
        const data = results === undefined ? action.payload : results;

        return state.update((state) => {
            return state.setIn(['items'], state.get('items').concat(fromJS(classesHistoryDataNormalizer(data))))
                .setIn(['count'], count)
                .setIn(['pageSize'], data.length)
                .setIn(['pages'], pagesCount)
                .setIn(['nextUrl'], nextUrl)
                .setIn(['previousUrl'], previousUrl);
        });
    },
    [classesHistoryTypes.START_CLASSES_HISTORY_LOADING]: (state, action) => (
        state.setIn(['isLoading'], true)
    ),
    [classesHistoryTypes.FINISH_CLASSES_HISTORY_LOADING]: (state, action) => (
        state.setIn(['isLoading'], false)
    ),
    [classesHistoryTypes.START_CLASSES_HISTORY_NEXT_LOADING]: (state, action) => (
        state.setIn(['isNextLoading'], true)
    ),
    [classesHistoryTypes.FINISH_CLASSES_HISTORY_NEXT_LOADING]: (state, action) => (
        state.setIn(['isNextLoading'], false)
    )
}, INITIAL_STATE);

export default classesHistoryListReducer;
