import PropTypes from 'prop-types';
import React from 'react';
import Divider from '../../../../common/Divider/Divider';

const WelcomeBackForm = ({ gingrSignUp, scratchSignUp }) => {
    return (
        <div className='welcome-back__form'>
            <buttom className='welcome-back__button' onClick={() => gingrSignUp({ isGingr: true })}>
                <div className='welcome-back__button-header'>Transfer account details</div>
                <div className='welcome-back__button-description'>
                    To help you set-up your sports account quicker,
                    we can use information from your existing club account.
                </div>
            </buttom>
            <Divider
                dividerLineClassName='welcome-back__divider'
                dividerTextClassName='welcome-back__divider-text'
                text='or'/>
            <buttom className='welcome-back__button' onClick={() => scratchSignUp({})}>
                <div className='welcome-back__button-header'>Create an account</div>
                <div className='welcome-back__button-description'>
                    Start fresh and set up a new account.
                </div>
            </buttom>
        </div>
    );
};

WelcomeBackForm.propTypes = {
    gingrSignUp: PropTypes.func.isRequired,
    scratchSignUp: PropTypes.func.isRequired,
};

export default WelcomeBackForm;
