import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDogs } from '../../../../services/dogs';
import DogsSlider from '../../DogsSlider';

class DogSelectorControl extends React.PureComponent {

    componentDidMount() {
        const { getDogs, dogsList } = this.props;
        if (!dogsList.length) {
            getDogs('?is_deceased=false');
        }
    }

    onDogClick = (id) => {
        const { input: { onChange, value } } = this.props;
        const values = value.length ? value.split(',') : [];
        const dogIndex = values.indexOf(id.toString());
        if (dogIndex === -1) {
            values.push(id);
        } else {
            values.splice(dogIndex, 1);
        }
        onChange(values.join(','));
    }

    render() {
        const { input, label, type, className, isLoading, dogsList, meta: { error, touched },
                title, subline, ...dogSliderProps } = this.props;
        return (
            <div className={`${className} dog-selector`}>
                <input {...input} type={type} />
                {label &&
                    <label
                        className='dog-selector__label'>
                        {label}
                    </label>
                }
                {!!(title || subline || error) &&
                    <div className='dog-selector__head'>
                        {title && <p className='dog-selector__title'>{title}</p>}
                        {subline && <p className='dog-selector__subline'>{subline}</p>}
                        {touched && error && <p className='dog-selector__error'>{error}</p>}
                    </div>
                }
                <div className='dog-selector__content'>
                    {!!dogsList.length &&
                        <DogsSlider
                            dogs={dogsList}
                            selectedDogs={input.value || ''}
                            onItemClick={this.onDogClick}
                            {...dogSliderProps} />
                    }

                    {!!isLoading &&
                        <div className='dog-selector__loading'>
                            Dogs Loading...
                        </div>
                    }
                </div>
            </div>
        );
    }
}

DogSelectorControl.propTypes = {
    label: PropTypes.string,
    title: PropTypes.string,
    subline: PropTypes.string,
    input: PropTypes.shape({}),
    type: PropTypes.string,
    getDogs: PropTypes.func,
    dogsList: PropTypes.arrayOf(PropTypes.shape({})),
    meta: PropTypes.shape({}),
    isLoading: PropTypes.bool,
    className: PropTypes.string
};

const mapStateToProps = state => ({
    dogsList: state.getIn(['dogsList', 'dogs']).toJS() || [],
    isLoading: state.getIn(['dogsList', 'isLoading']) || false
});

const mapDispatchToProps = (dispatch) => ({
    getDogs: bindActionCreators(getDogs, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DogSelectorControl);
