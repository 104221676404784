import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setVetInfoModalClosed } from '../../../../actions/miscActions';
import { skipOneStepAndFinishLater, goToVetInfoStep } from '../../../../actions/onboarding/onBoardingStepsActions';
import { finishVetInfoStep } from '../../../../actions/onboarding/veterinarianActions';
import { postVeterinarianOption } from '../../../../services/options';
import SkipStep from '../../../common/SkipStep/SkipStep';
import Step from '../../../common/Step';
import StepDescription from '../../../common/StepDescription';
import StepName from '../../../common/StepName';
import AddVeterinarianModalForm from './AddVeterinarianModalForm/AddVeterinarianModalForm';
import VetInfoFormContainer from './VetInfoForm';

// eslint-disable-next-line max-len
class VetInfoPage extends React.PureComponent {

    componentDidMount() {
        const { isVetInfoComplete, goToVetInfoStep } = this.props;
        if (!isVetInfoComplete) {
            goToVetInfoStep();
        }
    }
    render() {
        const {
            stepName = 'vet-info',
            handleVetInfo,
            handleOneStepSkip,
            isVetInfoModalOpened,
            handleVetInfoModalClosed,
            handleAddVeterinarian
        } = this.props;
        return (
            <Step
                logoClassName='vet-info__logo'
                contentClassName='vet-info__content'
                contentsContainerClassName='vet-info__container'
                stepName={stepName}
                componentsImage={<div className='vet-info__img'/>}
                nameAndDescriptionTopped
                nameComponent={
                    <StepName
                        className='vet-info__step-name'
                        numberText='Step 3'
                        nameText='Vaccination'/>
                }
                descriptionComponent={
                    <StepDescription
                        className='vet-info__step-description'
                        text='Almost done!'
                        disclaimer={'We\'ve made it easy to provide proof of vaccinations.'}
                        imageClassName='vet-info__step-description-image'/>
                }
                formComponent={
                    <VetInfoFormContainer
                        onSubmit={values => handleVetInfo(values)}/>
                }
                skipComponent={
                    <SkipStep
                        className='vet-info__skip-step'
                        skipLinkAction={handleOneStepSkip}
                        skipLinkText='Finish later'
                        skipLinkTo='waiver'/>
                }
                modalComponent={
                    <AddVeterinarianModalForm
                        showVeterinarianModal={isVetInfoModalOpened}
                        handleClose={handleVetInfoModalClosed}
                        onSubmit={values => handleAddVeterinarian(values)}/>
                }
                modalOverlayComponent={<div className='modal__overlay' onClick={handleVetInfoModalClosed}/>}/>
        );
    }
}

VetInfoPage.propTypes = {
    dog: PropTypes.shape({}),
    stepName: PropTypes.string,
    changeLocationOnVetInfoComplete: PropTypes.func,
    handleVetInfo: PropTypes.func,
    handleOneStepSkip: PropTypes.func,
    isVetInfoModalOpened: PropTypes.bool,
    handleVetInfoModalClosed: PropTypes.func,
    handleAddVeterinarian: PropTypes.func,
    isVetInfoComplete: PropTypes.bool,
    goToVetInfoStep: PropTypes.func
};

const mapStateToProps = state => ({
    isVetInfoModalOpened: state.getIn(['misc', 'modals', 'vetInfo']),
    isVetInfoComplete: state.getIn(['steps', 'isVetInfoComplete'])
});

const mapDispatchToProps = (dispatch) => ({
    handleOneStepSkip: bindActionCreators(skipOneStepAndFinishLater, dispatch),
    handleVetInfo: bindActionCreators(finishVetInfoStep, dispatch),
    handleVetInfoModalClosed: bindActionCreators(setVetInfoModalClosed, dispatch),
    handleAddVeterinarian: bindActionCreators(postVeterinarianOption, dispatch),
    goToVetInfoStep: bindActionCreators(goToVetInfoStep, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(VetInfoPage);
