import { fromJS } from 'immutable';
import { createAction } from 'redux-actions';
import moment from 'moment';
import { getUserNameSpace } from '../../helpers/userRoles';
import * as ImmunizationExpirationDatesTypes from '../../types/dashboard/immunizationExpirationDatesTypes';
import {
    postImmunizationExpirationDate,
    updateImmunizationExpirationDate,
} from '../../services/immunizationExpirationDates';
import { updateCurrentDogVaccinationRecords } from '../../actions/dashboard/vaccinationRecordsActions';
import {
    setImmunizationExpirationDatesLoaded,
    setImmunizationExpirationDatesLoading,
    setImmunizationExpirationDatesModalClosed,
} from '../miscActions';
import { generateAlertMeta } from '../../components/Alert/actions';

export const updateImmunizationExpirationDatesInfo = (dogId, newData, oldData, vaccinations) => {
    const sendData = vaccinations.reduce((resultArray, vaccination, vaccinationId) => {
        // new vaccination date not empty
        if (newData.get(vaccination) !== 'None') {
            const newExpirationDate = newData.get(vaccination);
            // new vaccination date is valid
            if (moment(newExpirationDate, 'MM/DD/YY').isValid()) {
                // previous vaccination date is exist
                if (oldData.get(vaccination) !== undefined) {
                    // previous vaccination date not equal to new vaccination date 
                    // and new vaccination date not before the current date
                    if (
                        oldData.getIn([vaccination, 'expiration_date']) !== newExpirationDate &&
                        !moment(moment().format('MM/DD/YY'), 'MM/DD/YY').isAfter(moment(newExpirationDate, 'MM/DD/YY'))
                    ) {
                        resultArray.push({
                            id: oldData.get(vaccination).get('id'),
                            data: {
                                expiration_date: moment(newExpirationDate, 'MM/DD/YY').format('YYYY-MM-DD'),
                                immunization_type: oldData.get(vaccination).get('immunization_type'),
                            },
                        });
                    }
                } else if (
                    !moment(moment().format('MM/DD/YY'), 'MM/DD/YY').isAfter(moment(newExpirationDate, 'MM/DD/YY'))
                ) {
                    resultArray.push({
                        data: {
                            expiration_date: moment(newExpirationDate, 'MM/DD/YY').format('YYYY-MM-DD'),
                            immunization_type: vaccinationId,
                        },
                    });
                }
            }
        }

        return resultArray;
    }, []);

    console.log('saving immunization expiration dates data', sendData);

    const sendPromises = sendData.reduce((promisesArray, vaccine) => {
        const requestPromise = new Promise(resolve => {
            if (vaccine.id === undefined) {
                postImmunizationExpirationDate({
                    url: `/v1/${getUserNameSpace('dogs.vaccination-records')}/dogs/${dogId}/vaccination-records/`,
                    dataChunks: fromJS(vaccine.data),
                }).then(response => {
                    resolve(response);
                });
            } else {
                updateImmunizationExpirationDate({
                    url: `/v1/${getUserNameSpace('dogs.vaccination-records')}/dogs/${dogId}/vaccination-records/${vaccine.id}/`,
                    dataChunks: fromJS(vaccine.data),
                }).then(response => {
                    resolve(response);
                });
            }
        });
        promisesArray.push(requestPromise);
        return promisesArray;
    }, []);

    return async dispatch => {
        await Promise.all(sendPromises).then(responses => {
            const savedData = responses.reduce((savedDataArray, savedItem) => {
                savedDataArray.push(savedItem.data);
                return savedDataArray;
            }, []);
            dispatch(updateCurrentDogVaccinationRecords(savedData));
        });
    };
};
// eslint-disable-next-line max-len
export const setCurrentImmunizationExpirationDatesData = createAction(
    ImmunizationExpirationDatesTypes.SET_CURRENT_IMMUNIZATION_EXPIRATION_DATES_DATA,
);
// eslint-disable-next-line max-len
export const clearCurrentImmunizationExpirationDatesData = createAction(
    ImmunizationExpirationDatesTypes.CLEAR_CURRENT_IMMUNIZATION_EXPIRATION_DATES_DATA,
);
export const setCurrentImmunizationExpirationDates = immunizationData => {
    return dispatch => {
        if (immunizationData === undefined) {
            dispatch(clearCurrentImmunizationExpirationDatesData());
        } else {
            dispatch(setCurrentImmunizationExpirationDatesData(immunizationData));
        }
    };
};

// eslint-disable-next-line max-len
export const closeImmunizationExpirationDatesModal = () => {
    return dispatch => {
        dispatch(setImmunizationExpirationDatesLoaded());
        dispatch(setImmunizationExpirationDatesModalClosed());
    };
};

// eslint-disable-next-line max-len
export const processImmunizationExpirationDatesInfo = (dogId, newData, oldData, vaccinations) => {
    return (dispatch, getState) => {
        dispatch(setImmunizationExpirationDatesLoading());
        dispatch(updateImmunizationExpirationDatesInfo(dogId, newData, oldData, vaccinations))
            .then(() => {
                dispatch(generateAlertMeta({
                    event: 'DOG_EDIT',
                    responseData: { name: getState().getIn(['currentDog', 'instance', 'name']) }
                }));
                dispatch(closeImmunizationExpirationDatesModal());
            })
            .catch(error => {
                console.error('updateImmunizationExpirationDatesInfo error', error);
                dispatch(closeImmunizationExpirationDatesModal());
            });
    };
};
