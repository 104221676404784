import { createAction } from 'redux-actions';
import * as PackagesTypes from '../../types/dashboard/packagesTypes';

export const setPackageList = createAction(PackagesTypes.SET_PACKAGE_LIST);
export const setNextPackageList = createAction(PackagesTypes.SET_NEXT_PACKAGE_LIST);

export const startPackageListLoading = createAction(PackagesTypes.START_PACKAGE_LIST_LOADING);
export const finishPackageListLoading = createAction(PackagesTypes.FINISH_PACKAGE_LIST_LOADING);

export const setNewPackageToPackageList = createAction(PackagesTypes.ADD_NEW_PACKAGE_TO_PACKAGES);
export const updatePackageForPackageList = createAction(PackagesTypes.UPDATE_PACKAGE_TO_PACKAGES);

export const initPackagesFilterOptions = createAction(PackagesTypes.INIT_PACKAGES_FILTER_OPTIONS);

export const updatePackageList = createAction(PackagesTypes.UPDATE_PACKAGE_LIST);

export const togglePackageStatus = createAction(PackagesTypes.TOGGLE_PACKAGE_STATUS);
