import * as axios from 'axios';
import { getUserNameSpace } from '../helpers/userRoles';

export const getGlanceLocationOptions = () => {
    return () => {
        return axios.get('/v1/classes/location-values/');
    };
};

export const getClassOptions = () => {
    return () => {
        return axios.get(`/v1/${getUserNameSpace('classes')}/classes/?page_size=0&is_archived=false`);
    };
};

export const getGlanceAll = ({ date }) => {
    return () => {
        return axios.get(`/v1/${getUserNameSpace('glance')}/glance/${date}/`);
    };
};

export const getGlanceTrainingAll = ({ date }) => {
    return () => {
        return axios.get(`/v1/${getUserNameSpace('glance')}/training-glance/${date}/`);
    };
};

export const getGlanceLocation = ({ date, trainingClassName }) => {
    return () => {
        return axios
            .get(`/v1/${getUserNameSpace('glance-with-location')}/glance-with-location/${date}/?training_class_name=` + encodeURIComponent(trainingClassName));
    };
};

export const getGlanceTrainingLocation = ({ date, trainingName }) => {
    return () => {
        return axios
            .get(`/v1/${getUserNameSpace('glance-with-location')}/training-glance-with-location/${date}/?training_name=` + encodeURIComponent(trainingName));
    };
};

export const getGlanceClassesProductsAll = ({ date }) => {
    return () => {
        return axios.get(`/v1/${getUserNameSpace('glance-classes-products')}/glance-classes-products/${date}/`);
    }
}

export const getGlanceTrainingsProductsAll = ({ date }) => {
    return () => {
        return axios.get(`/v1/${getUserNameSpace('glance-trainings-products')}/glance-trainings-products/${date}/`);
    }
}

export const getGlanceClassesProductsByName = ({ date, name }) => {
    return () => {
        return axios.get(`/v1/${getUserNameSpace('glance-classes-products')}/glance-classes-products/${date}/?name=` + encodeURIComponent(name));
    }
}

export const getGlanceTrainingsProductsByName = ({ date, name }) => {
    return () => {
        return axios.get(`/v1/${getUserNameSpace('glance-trainings-products')}/glance-trainings-products/${date}/?name=` + encodeURIComponent(name));
    }
}