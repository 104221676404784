import { fromJS } from 'immutable';

import * as miscTypes from '../types/miscTypes';
import * as modalTypes from '../types/modalTypes';
import * as paymentsTypes from '../types/paymentsTypes';

const initialState = fromJS({
  error: null,
});

const paymentsReducer  = (state = initialState, action) => {
  switch (action.type) {
    case paymentsTypes.SET_STRIPE_ERROR:
      return state.set('error', action.payload);
    case miscTypes.SET_ADD_CREDIT_CARD_MODAL_CLOSED:
    case modalTypes.RESET_MODAL_REDUCER:
    case modalTypes.SET_MODAL_SUBMIT_REQUEST_STARTED:
    case paymentsTypes.CLEAR_STRIPE_ERROR:
      return state.set('error', null);
    default:
      return state;
  }
}

export default paymentsReducer;
